<ng-template #modal>
    <div class="modal-content">
        <div class="modal-header">
            <span class="modal-title" id="exampleModalLabel">Status</span>
            <button type="button" class="btn close btn-close" data-bs-dismiss="modal" aria-label="close" (click)="closeModal()">&times;</button>
        </div>
        <div class="modal-body">
            <div class="chart-area">
                <canvas baseChart [data]="chartData" [type]="chartType" [options]="chartOptions" [plugins]="chartPlugins"></canvas>
            </div>
            <h3>{{nomeRpaApi}}</h3>
            <div class="table-multiheader-container">
                <table class="table-multiheader">
                    <tbody *ngFor="let detail of detailsList">
                        <tr>
                            <th>IdProcesso</th>
                            <th>Data/Hora Início</th>
                            <th>Data/Hora Fim</th>
                            <th>Status</th>
                        </tr>
                        <tr>
                            <td class="detail-title text-nowrap"><span (click)="openModalForRequestAndresponseEvents(detail)">{{detail.idProcesso}}</span></td>
                            <td>{{detail.dataInicio | date : 'dd/MM/yyyy HH:mm'}}</td>
                            <td>{{detail.dataTermino | date : 'dd/MM/yyyy HH:mm'}}</td>
                            <td>{{detail.status + '' | uppercase}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-4"></div>
                <div class="col-4 action-button-group">
                    <button class="action-primary-button" (click)="closeModal()">Fechar</button>
                </div>
                <div class="col-4"></div>
            </div>
        </div>
    </div>
</ng-template>
<app-modal-detalhes-eventos-request-response></app-modal-detalhes-eventos-request-response>
