import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SolicitacaoFaturamentoEstimativaCalculoService } from './../../../services/EstimativaCalculo/solicitacao-faturamento-estimativa-calculo.service';
import { FiltroPesquisaSolicitacaoFaturamento } from './../../../models/EstimativaCalculo/SolicitacaoFaturamento/FiltroPesquisaSolicitacaoFaturamento';
import { ModalExibirAnexosComponent } from '../../../shared/components/DocumentosAnexados/modal-exibir-anexos/modal-exibir-anexos.component';
import { ModalDocumentosAnexadosEstimativaCalculoComponent } from './../../../components/estimativa-calculo/modal-documentos-anexados-estimativa-calculo/modal-documentos-anexados-estimativa-calculo.component';
import { GridSolicitacaoFaturamento } from './../../../models/EstimativaCalculo/SolicitacaoFaturamento/GridSolicitacaoFaturamento';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ToastService } from 'src/app/services/toast.service';
import { VerificacaoDocumentosService } from './../../../shared/utils/verificacao-documentos.service';
import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { Permissoes } from 'src/app/contants/permissoes';
import { Telas } from 'src/app/contants/telas';
import { AuthRoles } from 'src/app/models/authRoles';
import { DocumentCustomPattern } from 'src/app/shared/utils/constantes-documentos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TipoDocumentoEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/TipoDocumentoEstimativaCalculo';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { Cliente } from 'src/app/models/Cliente';
import { DocumentoEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/DocumentoEstimativaCalculo';
import { StatusSolicitacaoFaturamento } from 'src/app/models/EstimativaCalculo/SolicitacaoFaturamento/StatusSolicitacaoFaturamento';

@Component({
  selector: 'app-solicitacao-faturamento-estimativa-calculo',
  templateUrl: './solicitacao-faturamento-estimativa-calculo.component.html',
  styleUrls: ['./solicitacao-faturamento-estimativa-calculo.component.scss']
})
export class SolicitacaoFaturamentoEstimativaCalculoComponent implements OnInit {

  isCollapsed = false;
  form!: UntypedFormGroup;
  public solicitations!: GridSolicitacaoFaturamento[];
  public usuarioInterno!: boolean;

  public title: string = 'Solicitações de Faturamento';
  public subtitle: string = 'Adicione abaixo os dados necessários para as solicitações de faturamento.';

  public readonly visualizacao = new AuthRoles(Telas.solicitacaoFaturamentoEstimativaCalculo, Permissoes.visualizacao)
  public readonly atualizacao = new AuthRoles(Telas.solicitacaoFaturamentoEstimativaCalculo, Permissoes.atualizacao)

  public readonly namePattern = DocumentCustomPattern.NAME_PATTERN;
  private readonly colorTheme = 'theme-dark-blue';
  public bsConfig?: Partial<BsDatepickerConfig>;
  private readonly locale = 'pt-br';

  public documentTypes!: TipoDocumentoEstimativaCalculo[];
  public maskForDocumentNumber!: string;
  public specialCharsForMask!: string[];
  public documentPattern?: any | null;

  public selectedGridRowIndex?: number;

  public listaStatus: string[] = Object.values(StatusSolicitacaoFaturamento);

  @Output() onExpandSolicitation: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild("modalDocumentosAnexados",{static: false}) modalDocumentosAnexados!: ModalDocumentosAnexadosEstimativaCalculoComponent;
  @ViewChild('modalExibirAnexos') public modalExibirAnexos!: ModalExibirAnexosComponent;

  constructor(
    private fb: UntypedFormBuilder,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private localeService: BsLocaleService,
    private solicitacaoFaturamentoEstimativaCalculoService: SolicitacaoFaturamentoEstimativaCalculoService,
    private toastService:ToastService,
    private spinner: NgxSpinnerService,
    private changeDetector: ChangeDetectorRef,
    private authenticationService: AuthenticationService
  ) { }

  public ngOnInit(): void{
    this.usuarioInterno = this.authenticationService.ehUsuarioInterno;
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.getDocumentTypes();
    this.validation();
    this.searchBillingSolicitations();
  }

  private getDocumentTypes(): void{
    this.documentTypes = [];
    this.solicitacaoFaturamentoEstimativaCalculoService.getDocumentTypesForBillingSolicitation()
      .subscribe({
        next: (result) => {
          this.documentTypes = result.data as TipoDocumentoEstimativaCalculo[] ?? [];
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  private validation(): void {
    this.form = this.fb.group({
      tipoSolicitacao: [""],
      numeroSolicitacao: [""],
      solicitante: [""],
      nomeCliente: [""],
      cpfCnpjCliente: ["", [this.isDocumentValid.bind(this)]],
      tipoCliente: [""],
      nomeClienteFaturar:[""],
      cpfCnpjClienteFaturar:["", [this.isDocumentValid.bind(this)]],
      tipoClienteFaturar:[""],
      clienteFaturarAPrazo: [""],
      temRestricao: [""],
      tipoDocumento: [""],
      numeroDocumento: [""],
      periodoSolicitacao: [""],
      periodoSaida: [""],
      status: [""]
    });
    this.form.get('tipoCliente')?.valueChanges
      .pipe(
        debounceTime(100)
      )
      .subscribe(
        () => {
          this.setDocumentMaskByClientType('Cliente');
        }
      );
    this.form.get('tipoClienteFaturar')?.valueChanges
      .pipe(
        debounceTime(100)
      )
      .subscribe(
        () => {
          this.setDocumentMaskByClientType('ClienteFaturar');
        }
      );
    this.form.get('tipoDocumento')?.valueChanges
      .subscribe(
        (documentTypeName: string) => {
          this.changeDocumentType(documentTypeName);
        }
      );
    this.form.get('status')?.setValue(StatusSolicitacaoFaturamento.PENDENTE);
  }

  public setClientTypeByDocumentNumber(clientType: string): void{
    let documentoCliente = this.form.get(`cpfCnpj${clientType}`)?.value;
    if(!documentoCliente){
      return;
    }
    let documentoClienteNumber = this.verificacaoDocumentosService.eraseMask(documentoCliente);
    if(documentoClienteNumber.length === 11){
      this.form.get(`cpfCnpj${clientType}`)?.setValue(this.verificacaoDocumentosService.setMaskForCPF(documentoClienteNumber), {emitEvent: false});
      this.form.get(`tipo${clientType}`)?.setValue('fisica', {emitEvent: false});
      return;
    }
    if(documentoClienteNumber.length === 14){
      this.form.get(`cpfCnpj${clientType}`)?.setValue(this.verificacaoDocumentosService.setMaskForCNPJ(documentoClienteNumber), {emitEvent: false});
      this.form.get(`tipo${clientType}`)?.setValue('juridica', {emitEvent: false});
      return;
    }
    this.form.get(`tipo${clientType}`)?.setValue('', {emitEvent: false});
  }

  public setDocumentMaskByClientType(clientType: string): void{
    let tipoPessoa: string = this.form.get(`tipo${clientType}`)?.value;
    let documento: string = this.form.get(`cpfCnpj${clientType}`)?.value;
    if(tipoPessoa === 'fisica'){
      this.form.get(`cpfCnpj${clientType}`)?.setValue(this.verificacaoDocumentosService.setMaskForCPF(this.verificacaoDocumentosService.eraseMask(documento)), {emitEvent: false});
      return;
    }
    if(tipoPessoa === 'juridica'){
      this.form.get(`cpfCnpj${clientType}`)?.setValue(this.verificacaoDocumentosService.setMaskForCNPJ(this.verificacaoDocumentosService.eraseMask(documento)), {emitEvent: false});
      return;
    }
  }

  private changeDocumentType(documentTypeName: string): void{
    this.form.get('numeroDocumento')?.setValue('');
    if(documentTypeName == 'DSIm'){
      this.maskForDocumentNumber = 'D*';
      this.specialCharsForMask = [];
      this.documentPattern = DocumentCustomPattern.DSIM_MASK_PATTERN;
    }
    else{
      this.maskForDocumentNumber = this.documentTypes.find(
        (documentType) => documentType.nome === documentTypeName
      )?.mascara ?? '';
      this.specialCharsForMask = this.verificacaoDocumentosService.getSpecialCharsFromMask(this.maskForDocumentNumber) ?? [];
      this.documentPattern = null;
    }
    this.changeDetector.detectChanges();
  }

  public isDocumentValid(control: AbstractControl): {invalidDocument: string} | null {
    if(!control){
      return null;
    }
    const documentNumber = control.value as string;
    if(!documentNumber || !documentNumber.length){
      return null;
    }
    let error = this.validateCpfOrCnpj(documentNumber);
    if(error && error.length){
      return {invalidDocument: error as string}
    }
    return null;
  }

  public validateCpfOrCnpj(document: string): string | null{
    let documentNumber = this.verificacaoDocumentosService.eraseMask(document);
    if(documentNumber.length == 0){
      return null;
    }
    if(documentNumber.length == 11){
      return this.verificacaoDocumentosService.calculateValidCPF(document);
    }
    if(documentNumber.length == 14){
      return this.verificacaoDocumentosService.calculateValidCNPJ(document);
    }
    return "O nº do CNPJ/CPF informado é inválido. Favor verificar!";
  }

  public unselectRadioForClientType(controlName: string, value: string): void{
    if(this.form?.get(controlName)?.value === value){
      this.form?.get(controlName)?.setValue('');
    }
  }

  public searchBillingSolicitations(): void{
    this.solicitations = [];
    this.selectedGridRowIndex = undefined;
    if(this.usuarioInterno){
      this.searchBillingSolicitationsForInnerUser();
    }
    else{
      this.searchBillingSolicitationsForInnerUser();
    }
  }

  public searchBillingSolicitationsForInnerUser(): void{
    this.spinner.show();
    this.solicitacaoFaturamentoEstimativaCalculoService.searchBillingSolicitationsForInnerUser(this.getSearchFilterFromForm())
      .subscribe({
        next: (result) => {
          this.solicitations = result.data as GridSolicitacaoFaturamento[] ?? [];
          this.spinner.hide();
          this.changeDetector.detectChanges();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public getMaskForDocumentNumberOnGrid(tipoDocumento: string): string{
    return this.documentTypes.find(
      (documentType) => documentType.nome == tipoDocumento
    )?.mascara ?? '';
  }

  public getDocumentTypeId(documentTypeName: string): number{
    return this.documentTypes.find(
      (documentType) => documentType.nome == documentTypeName
    )?.id!;
  }

  public expandSolicitation(solicitation: GridSolicitacaoFaturamento): void{
    this.spinner.show();
    let cadastroDocumentoId: number | undefined = solicitation?.cadastroDocumentoId;
    if(cadastroDocumentoId){
      this.onExpandSolicitation.emit(cadastroDocumentoId);
    }
  }

  public showAttachedDocuments(solicitation: GridSolicitacaoFaturamento): void{
    if(!solicitation?.documentosAnexados){
      return;
    }
    this.modalDocumentosAnexados.openModal(solicitation)
  }

  public setSolicitationAsFinalized(solicitation: GridSolicitacaoFaturamento): void{
    this.solicitations.find(
      (solicitacao) => solicitacao.numeroSolicitacao == solicitation.numeroSolicitacao
    )!.status = 'Finalizado';
  }

  private getSearchFilterFromForm(): FiltroPesquisaSolicitacaoFaturamento{
    return {
      tipoSolicitacao: this.form.value.tipoSolicitacao ?? '',
      numeroSolicitacao: this.form.value.numeroSolicitacao ?? '',
      solicitante: this.usuarioInterno  === false? this.authenticationService.fullName : this.form.value.solicitante ?? '',
      cliente: {
        nomeCliente: this.form.value.nomeCliente ?? '',
        cpfCnpjCliente: this.form.value.cpfCnpjCliente ?? '',
        tipoCliente: this.form.value.tipoCliente ?? '',
        nomeClienteFaturar: this.form.value.nomeClienteFaturar ?? '',
        cpfCnpjClienteFaturar: this.form.value.cpfCnpjClienteFaturar ?? '',
        tipoClienteFaturar: this.form.value.tipoClienteFaturar ?? '',
        clienteFaturarAPrazo: this.form.value.clienteFaturarAPrazo ?? false,
        temRestricao: this.form.value.temRestricao ?? false,
      },
      documento: {
        id: this.documentTypes.find(documento => documento.nome == this.form.value.tipoDocumento)?.id,
        tipoDocumento: this.form.value.tipoDocumento ?? '',
        numeroDocumento: this.verificacaoDocumentosService.eraseMask(this.form.value.numeroDocumento) ?? '',
      },
      dataSolicitacao: this.form.value.periodoSolicitacao,
      dataSaida: this.form.value.periodoSaida,
      status: this.form.value.status ?? '',
      usuarioInterno:this.usuarioInterno
    }
  }

  public selectGridRow(index: number): void{
    this.selectedGridRowIndex = index ?? undefined;
  }

  public clearFilter(): void{
    this.form?.reset();
  }

  public searchBillingSolicitationsAfterUpdate(): void{
    this.form.get("status")?.setValue("");
    this.solicitations = [];
    this.selectedGridRowIndex = undefined;
    if(this.usuarioInterno){
      this.searchBillingSolicitationsForInnerUser();
    }
    else{
      this.searchBillingSolicitationsForInnerUser();
    }
  }

  public updateGridAfterConclusion(): void{
    this.clearFilter();
    //HDE1742
    this.searchBillingSolicitationsAfterUpdate();
  }

}
