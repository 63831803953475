import { SolicitacaoFaturamentoEstimativaCalculoComponent } from './solicitacao-faturamento-estimativa-calculo/solicitacao-faturamento-estimativa-calculo.component';
import { CadastroDocumentoEstimativaCalculoComponent } from './cadastro-documento-estimativa-calculo/cadastro-documento-estimativa-calculo.component';
import { DocumentoEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/DocumentoEstimativaCalculo';
import { AbaSubpagina } from './../../models/AbaSubpagina';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';

@Component({
  selector: 'app-estimativa-calculo',
  templateUrl: './estimativa-calculo.component.html',
  styleUrls: ['./estimativa-calculo.component.scss']
})
export class EstimativaCalculoComponent implements OnInit {

  @ViewChild(CadastroDocumentoEstimativaCalculoComponent) cadastroDocumento!: CadastroDocumentoEstimativaCalculoComponent;
  @ViewChild(SolicitacaoFaturamentoEstimativaCalculoComponent) solicitacaoFaturamento!: SolicitacaoFaturamentoEstimativaCalculoComponent;

  public selectedPage!: string;
  public readonly subpageTabs: AbaSubpagina[] = [
    {
      titulo: 'Cadastro de Documento',
      path: AbasSubpaginaEstimativaCalculo.CADASTRO_DOCUMENTO,
      authRoles: new AuthRoles(Telas.cadastroDocumentoEstimativaCalculo, Permissoes.visualizacao)
    },
    {
      titulo: 'Solicitações de Faturamento',
      path: AbasSubpaginaEstimativaCalculo.SOLICITACAO_FATURAMENTO,
      authRoles: new AuthRoles(Telas.solicitacaoFaturamentoEstimativaCalculo, Permissoes.visualizacao)

    }
  ];

  public readonly visualizacaoCadastroDocumentoEstimativaCalculo = new AuthRoles(Telas.cadastroDocumentoEstimativaCalculo, Permissoes.visualizacao)
  public readonly visualizacaoSolicitacaoFaturamentoEstimativaCalculo = new AuthRoles(Telas.solicitacaoFaturamentoEstimativaCalculo, Permissoes.visualizacao)

  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.changeSelectedPage(AbasSubpaginaEstimativaCalculo.CADASTRO_DOCUMENTO);
  }

  public changeSelectedPage(pageName: AbasSubpaginaEstimativaCalculo | string): void{
    if(this.selectedPage == pageName){
      return;
    }
    this.selectedPage = pageName;
    this.changeDetector.detectChanges();
  }

  public openSolicitationFromGrid(cadastroDocumentoId: number): void{
    this.changeSelectedPage(AbasSubpaginaEstimativaCalculo.CADASTRO_DOCUMENTO);
    this.cadastroDocumento?.expandSolicitation(cadastroDocumentoId);
  }

}

enum AbasSubpaginaEstimativaCalculo{
  CADASTRO_DOCUMENTO = 'cadastro-documento-estimativa-calculo',
  SOLICITACAO_FATURAMENTO = 'solicitacao-faturamento-estimativa-calculo'
}
