<ng-template #modalMensagem>
  <div class="modal-content">
    <div class="modal-header">
      <span class="modal-title" id="exampleModalLabel">{{title}}</span>
      <button type="button" class="btn close btn-close" data-bs-dismiss="modal" aria-label="close"
      (click)="closeModal()">&times;</button>
    </div>
    <div class="modal-body text-center">
      <p>{{message}}</p>
    </div>
  </div>
</ng-template>
