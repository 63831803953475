import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BloqueioDesbloqueioConteinerEmbarque } from 'src/app/models/BloqueioDesbloqueioConteinerEmbarque/bloqueio-desbloqueio-conteiner-embarque';
import { Campo } from 'src/app/models/Campo';

@Component({
  selector: 'tr[app-linha-grid-bloqueio-desbloqueio-conteiner-embarque]',
  templateUrl: './linha-grid-bloqueio-desbloqueio-conteiner-embarque.component.html',
  styleUrls: ['./linha-grid-bloqueio-desbloqueio-conteiner-embarque.component.scss']
})
export class LinhaGridBloqueioDesbloqueioConteinerEmbarqueComponent implements OnInit {

  @Input() public bloqueioDesbloqueio!: BloqueioDesbloqueioConteinerEmbarque;
  @Input() fieldSettings!: Campo[];
  @Output() public onSendContainer: EventEmitter<BloqueioDesbloqueioConteinerEmbarque> = new EventEmitter<BloqueioDesbloqueioConteinerEmbarque>();

  public selecionado!: UntypedFormControl;
  public numeroDUE!: UntypedFormControl;
  public conteiner!: UntypedFormControl;
  public statusBloqueio!: UntypedFormControl;
  public numeroSolicitacao!: UntypedFormControl;
  public statusSolicitacao!: UntypedFormControl;
  public dataEntrega!: UntypedFormControl;
  public navio!: UntypedFormControl;
  public viagem!: UntypedFormControl;

  public form!: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder
    ) { }

  ngOnInit() {
    this.validation();
  }

  private validation(): void{
    this.selecionado = new UntypedFormControl(false, Validators.compose([]));
    this.numeroDUE = new UntypedFormControl(this.bloqueioDesbloqueio?.numeroDUE, Validators.compose([]));
    this.conteiner = new UntypedFormControl(this.bloqueioDesbloqueio?.conteiner, Validators.compose([]));
    this.statusBloqueio = new UntypedFormControl(this.bloqueioDesbloqueio?.statusBloqueio, Validators.compose([]));
    this.numeroSolicitacao = new UntypedFormControl(this.bloqueioDesbloqueio?.numeroSolicitacaoLiberacao, Validators.compose([]));
    this.statusSolicitacao = new UntypedFormControl(this.bloqueioDesbloqueio?.statusSolicitacao, Validators.compose([]));
    this.dataEntrega = new UntypedFormControl(this.bloqueioDesbloqueio?.dataEntrada, Validators.compose([]));
    this.navio = new UntypedFormControl(this.bloqueioDesbloqueio?.navio, Validators.compose([]));
    this.viagem = new UntypedFormControl(this.bloqueioDesbloqueio?.viagem, Validators.compose([]));

    this.form = this.fb.group({
      numeroDUE: this.numeroDUE,
      conteiner: this.conteiner,
      statusBloqueio: this.statusBloqueio,
      numeroSolicitacao: this.numeroSolicitacao,
      statusSolicitacao: this.statusSolicitacao,
      dataEntrega: this.dataEntrega,
      navio: this.navio,
      viagem:this.viagem
    });
    this.form.valueChanges.subscribe(
      () => {this.updateContainer();}
    );
    this.updateContainer();
  }

  public sendContainer(): BloqueioDesbloqueioConteinerEmbarque{
    return this.form.getRawValue() as BloqueioDesbloqueioConteinerEmbarque;
  }

  private updateContainer(): void{
    this.onSendContainer.emit();
  }

}
