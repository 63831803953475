import { EntregaDueConteiner } from 'src/app/models/EntregaDueContigencia/EntregaRelacaoConteiner/EnvioEntregaDueConteiner';

import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { EntregaDue, RelacaoConteinerEntregaConteiner } from '../models/EntregaDueContigencia/EntregaRelacaoConteiner/RelacaoConteinerEntregaConteiner';
import { DocumentoEntregaDocumentoTransporte } from '../models/EntregaDueContingencia/EntregaDocumentoTransporte/DocumentoEntregaDocumentoTransporte';
import { SuccessResponse } from '../models/HttpResponses/SuccessResponse';
import { DadosEntregaDocumentoTransporte } from '../models/EntregaDueContingencia/EntregaDocumentoTransporte/ConteinerEntregaDocumentoTransporte';

@Injectable({
  providedIn: 'root'
})
export class EntregaDueContigenciaService {

  constructor(private http: HttpClient) { }

  public searchForContainerOnForEntregaDocumentoTransporte(documento:DocumentoEntregaDocumentoTransporte): Observable<any> {
    return this.http.get<any>(`${environment.apiUrlForEntrega}/DocumentoTransporte/PesquisaPorTipoNumero/${documento.tipoDocumento}/${documento.numeroDocumento}`);
  }

  public getDocumentTypes(): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForEntrega}/DocumentoTransporte/tipoDocumento`);
  }

  public sendDataToCCTForEntregaDocumentoTransporte(dadosEntregaDocumentoTransporte: DadosEntregaDocumentoTransporte): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForEntrega}/DocumentoTransporte/enviarCCT`, dadosEntregaDocumentoTransporte);
  }

  public saveEntregaDocumentoTransporte(dadosEntregaDocumentoTransporte: DadosEntregaDocumentoTransporte): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForEntrega}/DocumentoTransporte/salvar`, dadosEntregaDocumentoTransporte);
  }

  public searchForContainerOnForEntregaDUE(navio: string, viagem: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrlForEntrega}/EntregaDue/Pesquisar/${navio}/${viagem}`);
  }

  public sendDataToCCTForEntregaDUE(entregaDUE: EntregaDueConteiner): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForEntrega}/EntregaDue/enviarCCT`, entregaDUE);
  }

  public saveEntregaDUE(entregaDUE: EntregaDueConteiner): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForEntrega}/EntregaDue/salvar`, entregaDUE);
  }

}

