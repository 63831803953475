import { RelacaoConteinerEmissaoTicketPesagemExportacao } from './../models/EmissaoTicketPesagemExportacao/RelacaoConteinerEmissaoTicketPesagemExportacao';
import { SuccessResponse } from './../models/HttpResponses/SuccessResponse';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PesquisaEmissaoTicketPesagemExportacao } from '../models/EmissaoTicketPesagemExportacao/PesquisaEmissaoTicketPesagemExportacao';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EmissaoTicketPesagemExportacaoService {

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe
  ) { }

  private getQueryStringForContainerRelationsByConteinerBookingOrDUE(numeroConteiner: string, booking: string, numeroDUE: string): string {
    let bookingQuerySearch: string[] = [];
    if (numeroConteiner && numeroConteiner.length) {
      bookingQuerySearch.push(`NumeroConteiner=${numeroConteiner}`);
    }
    if (booking && booking.length) {
      bookingQuerySearch.push(`Booking=${booking}`);
    }
    if (numeroDUE && numeroDUE.length) {
      bookingQuerySearch.push(`NumeroDUE=${numeroDUE}`);
    }
    return bookingQuerySearch.join('&');
  }

  public searchContainerRelationsByConteinerBookingOrDUE(pesquisaRelacao: PesquisaEmissaoTicketPesagemExportacao): Observable<SuccessResponse> {
    return this.http.get<any>(`${environment.apiUrlForRecepcaoDue}/api/EmissaoTicket/obterTickets?${this.getQueryStringForContainerRelationsByConteinerBookingOrDUE(pesquisaRelacao.conteiner,pesquisaRelacao.booking,pesquisaRelacao.numeroDUE)}`);
  }

  public searchContainerTicket(listGkey: number[]): Observable<SuccessResponse> {
    return this.http.post<any>(`${environment.apiUrlForRecepcaoDue}/api/EmissaoTicket/geradorTicket`, listGkey);
  }

  public printTickets(relacaoConteiner: RelacaoConteinerEmissaoTicketPesagemExportacao[]): void{
    var myWindow = window.open("print.html", "_blank");
    if (myWindow) {
      myWindow.document.write(this.generateTicketTemplate(relacaoConteiner));
      myWindow.document.title = 'Emissão de Ticket de Pesagem';
      setTimeout(() => {
        myWindow!.print();
      }, 250)
    }
  }

  private generateTicketTemplate(relacaoConteiner: RelacaoConteinerEmissaoTicketPesagemExportacao[]): string{
    let currentDate: Date = new Date();
    let element: string = ``;
    //style
    element += `
      <style>
        #pdf{
          font-size: 1rem;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 600;
          color: #000000;
          width: 100%;
        }

        .print-box{
          border: 2px #000000 solid;
        }

        .print-box .print-box{
          border-width: 3px;
          margin-top: 2rem;
        }

        .info-empresa > p{
          width: 100%;
          text-align: left;
        }

        .logo-documento{
          display: flex;
          align-items: baseline;
          justify-content: space-between;
        }

        .logo-documento > p{
          width: 40%;
        }

        .logo-empresa{
          width: 40%;
          display: flex;
          align-items: baseline;
          justify-content: start;
        }

        .logo-empresa img{
          width: 30%;
        }

        .logo-empresa p{
          margin-left: 1rem;
        }

        .info-conteiner .row-4-equal:nth-child(odd){
          font-weight: 500;
        }

        .item-conteiner{
          break-after: page;
        }

        .row-4-equal{
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          column-gap: 1rem;
        }

        .row-4-2-4{
          display: grid;
          grid-template-columns: 4fr 2fr 4fr;
          column-gap: 1rem;
        }

        .p-sm{
          padding: 1rem;
        }

        .p-m{
          padding: 2rem;
        }

        .p-l{
          padding: 3rem;
        }

        .text-left{
          text-align: left;
        }

        .text-right{
          text-align: right;
        }
      </style>
    `;
    //start of document
    element += `<div id="pdf">`;
    //iteration of tickets
    relacaoConteiner.forEach(
      (conteiner) => {
        element += `
          <div class="item-conteiner p-sm">
            <div class="print-box p-m">
              <div class="row-4-2-4">
                <div class="text-left">
                  <p>Ticket nº ${conteiner.ticket}</p>
                </div>
                <div class=""></div>
                <div class="text-right">
                  <p>Data/Hora: ${this.datePipe.transform(currentDate, "dd/MM/yyyy HH:mm")}</p>
                </div>
              </div>
              <div class="print-box p-m info-empresa">
                <div class="logo-documento">
                  <div class="logo-empresa">
                    <img src="assets/images/logoSeptibaTecon.png" alt="">
                    <p>Septiba Tecon S/A</p>
                  </div>
                  <p class="text-right">CNPJ: 02.394276/0001-27</p>
                </div>
                <p>Fone: (21) 2688-9246</p>
                <p>Estrada Prefeito Wilson Pedro Francisco, S/N - Porto de Itaguaí - Ilha da Madeira - Itaguaí - RJ</p>
              </div>
              <div class="print-box p-m text-left info-conteiner">
                <div class="row-4-equal">
                  <div class="">
                    <p>Cliente:</p>
                  </div>
                  <div class="">
                    <p>${conteiner.nomeCliente}</p>
                  </div>
                  <div class="">
                    <p>Documento Liberação:</p>
                  </div>
                  <div class="">
                    <p>${conteiner.numeroDUE}</p>
                  </div>
                </div>
                <div class="row-4-equal">
                  <div class="">
                    <p>Contêiner:</p>
                  </div>
                  <div class="">
                    <p>${conteiner.numeroConteiner}</p>
                  </div>
                  <div class="">
                    <p>Data da Entrada:</p>
                  </div>
                  <div class="">
                    <p>${this.datePipe.transform(conteiner.dataEntrada, "dd/MM/yyyy HH:mm")}</p>
                  </div>
                </div>
                <div class="row-4-equal">
                  <div class="">
                    <p>Dimensão:</p>
                  </div>
                  <div class="">
                    <p>${conteiner.dimensao ==='NOM40' ? "40'" : conteiner.dimensao ==='NOM20'? "20'":""}</p>
                  </div>
                  <div class="">
                    <p>Tara:</p>
                  </div>
                  <div class="">
                    <p>${conteiner.tara}</p>
                  </div>
                </div>
                <div class="row-4-equal">
                  <div class="">
                    <p>Peso Bruto Apurado:</p>
                  </div>
                  <div class="">
                    <p>${conteiner.pesoBruto}</p>
                  </div>
                  <div class="">
                    <p>Peso Líquido Apurado:</p>
                  </div>
                  <div class="">
                    <p>${conteiner.pesoLiquido}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `;
      }
    );
    //end of document
    element += `</div>`;
    return element;
  }
}
