<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Documentos Anexados <span class="text-danger" *ngIf="!areAllFilesValid">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <div class="table-container">
            <table class="grid-table">
                <tr *ngFor="let document of documentsList; let i = index" class="readonly-grid-line"
                  app-linha-grid-documentos-anexados
                  [receivedDocument]="document"
                  (onUpdateAttachedDocument)="sendAttachedfiles()"
                  (onDisplayFile)="displayUploadedFile($event, i)"
                  (onDownloadFile)="downloadAttachedFile($event, i)"
                  [isInsideModal]="isInsideModal" [visualizar]="visualizar"
                  (onDeleteFile)="deleteFile($event)"
                ></tr>
            </table>
        </div>
    </div>
</div>

<app-modal-exibir-anexos></app-modal-exibir-anexos>
