<form [formGroup]="form" class="p-5">
  <div class="row mb-3">
    <div class="col-6">
      <label>Contêiner:</label>
      <input class="form-control" type="text" placeholder="Digite aqui..."
      formControlName="conteiner">
    </div>
    <div class="col-6">
      <label>Booking:</label>
      <input class="form-control" type="text" placeholder="Digite aqui..."
      formControlName="booking">
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-6">
      <label>Nº da DUE:</label>
      <input [mask]="maskForDUE" [specialCharacters]="specialCharsForDUE" class="form-control" type="text" placeholder="Digite aqui..."
      formControlName="numeroDUE">
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-9 d-flex align-items-end">
      <p class="required-fields-paragraph">Preencher ao menos um campo para pesquisar</p>
    </div>
    <div class="col-3 action-button-group">
      <button type="button" class="action-primary-button" [disabled]="!isAnyFormControlFilled" (click)="search()">PESQUISAR</button>
    </div>
  </div>
</form>
