<div [auth]="this.visualizacao">

    <app-title [title]="title" [subtitle]='subtitle'></app-title>

    <div class="card mt-4">
        <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
            <form [formGroup]="form">
                <div class="p-3">
                    <div class="row mb-4">
                        <div class="col">
                            <label>Tipo de Solicitação:</label>
                            <select class="custom-select" formControlName="tipoSolicitacao">
              <option [ngValue]="''" selected>Selecione</option>
              <option [ngValue]="'Memória de Cálculo'">Memória de Cálculo</option>
              <option [ngValue]="'Confirmar Pagamento'">Confirmar Pagamento</option>
              <option [ngValue]="'Liberação Financeira'">Liberação Financeira</option>
            </select>
                        </div>
                        <div class="col">
                            <label>Nº Solicitação:</label>
                            <input formControlName="numeroSolicitacao" type="number" class="form-control" aria-describedby="basic-addon2" placeholder="Digite aqui...">
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-6">
                            <label>Solicitante:</label>
                            <input formControlName="solicitante" type="text" class="form-control" aria-describedby="basic-addon2" maxlength="200" placeholder="Digite aqui...">
                        </div>
                        <div class="col-6">
                            <label>Status:</label>
                            <select class="custom-select" formControlName="status">
                              <option [ngValue]="''">Selecione</option>
                              <option *ngFor="let status of listaStatus" [ngValue]="status">{{status}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <hr>
        <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
            <form [formGroup]="form">
                <div class="p-3">
                    <div class="row mb-4">
                        <div class="col">
                            <label>Nome do Cliente:</label>
                            <input formControlName="nomeCliente" type="text" class="form-control" aria-describedby="basic-addon2" maxlength="200" placeholder="Digite aqui..." >
                        </div>
                        <div class="col">
                            <label>CPF/CNPJ do Cliente:</label>
                            <input formControlName="cpfCnpjCliente" type="text" class="form-control" aria-describedby="basic-addon2" placeholder="Digite aqui..." [mask]="'000.000.000-00||00.000.000/0000-00'" (change)="setClientTypeByDocumentNumber('Cliente')" [ngClass]="{'border border-danger': form.get('cpfCnpjCliente')?.errors && !form.get('cpfCnpjCliente')?.pristine}">
                            <p class="text-danger" *ngIf="form.get('cpfCnpjCliente')?.errors && !form.get('cpfCnpjCliente')?.pristine">
                                {{validateCpfOrCnpj(form.get('cpfCnpjCliente')?.value)}}
                            </p>
                        </div>
                    </div>
                    <label>Tipo de Cliente:</label>
                    <div class="row mb-4">
                        <div class="col-3">
                            <div class="radio">
                                <input formControlName="tipoCliente" type="radio" value="fisica" (click)="unselectRadioForClientType('tipoCliente', 'fisica')">
                                <label>Pessoa Física</label>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="radio">
                                <input formControlName="tipoCliente" type="radio" value="juridica" (click)="unselectRadioForClientType('tipoCliente', 'juridica')">
                                <label>Pessoa Jurídica</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <hr>
        <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
            <form [formGroup]="form">
                <div class="p-3">
                    <div class="row mb-4">
                        <div class="col">
                            <label>Nome do Cliente a Faturar:</label>
                            <input formControlName="nomeClienteFaturar" type="text" class="form-control" aria-describedby="basic-addon2" maxlength="200" placeholder="Digite aqui..." >
                        </div>
                        <div class="col">
                            <label>CPF/CNPJ do Cliente a Faturar:</label>
                            <input formControlName="cpfCnpjClienteFaturar" type="text" class="form-control" aria-describedby="basic-addon2" placeholder="Digite aqui..." [mask]="'000.000.000-00||00.000.000/0000-00'" (change)="setClientTypeByDocumentNumber('ClienteFaturar')" [ngClass]="{'border border-danger': form.get('cpfCnpjClienteFaturar')?.errors && !form.get('cpfCnpjClienteFaturar')?.pristine}">
                            <p class="text-danger" *ngIf="form.get('cpfCnpjClienteFaturar')?.errors && !form.get('cpfCnpjClienteFaturar')?.pristine">
                                {{validateCpfOrCnpj(form.get('cpfCnpjClienteFaturar')?.value)}}
                            </p>
                        </div>
                    </div>
                    <label>Tipo de Cliente a Faturar:</label>
                    <div class="row mb-4">
                        <div class="col-3">
                            <div class="radio">
                                <input formControlName="tipoClienteFaturar" type="radio" value="fisica" (click)="unselectRadioForClientType('tipoClienteFaturar', 'fisica')">
                                <label>Pessoa Física</label>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="radio">
                                <input formControlName="tipoClienteFaturar" type="radio" value="juridica" (click)="unselectRadioForClientType('tipoClienteFaturar', 'juridica')">
                                <label>Pessoa Jurídica</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <hr>
        <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
            <form [formGroup]="form">
                <div class="p-3">
                    <div class="row mb-4">
                        <div class="col">
                            <label>Tipo de Documento:</label>
                            <select formControlName="tipoDocumento" class="custom-select" aria-describedby="basic-addon2">
              <option [ngValue]="''" selected>Selecione</option>
              <option *ngFor="let documentType of documentTypes" [ngValue]="documentType.nome">{{documentType.nome}}</option>
            </select>
                        </div>
                        <div class="col">
                            <label>Documento:</label>
                            <input *ngIf="!documentPattern; else inputForPatternUse" formControlName="numeroDocumento" type="text" class="form-control" aria-describedby="basic-addon2" maxlength="50" placeholder="Digite aqui..." [mask]="maskForDocumentNumber" [specialCharacters]="specialCharsForMask"
                                [ngClass]="{'border border-danger': !form.get('numeroDocumento')?.pristine && form.get('numeroDocumento')?.errors}">
                            <ng-template #inputForPatternUse>
                                <input formControlName="numeroDocumento" type="text" class="form-control" aria-describedby="basic-addon2" maxlength="50" placeholder="Digite aqui..." [mask]="maskForDocumentNumber" [specialCharacters]="specialCharsForMask" [patterns]="documentPattern"
                                    [ngClass]="{'border border-danger': !form.get('numeroDocumento')?.pristine && form.get('numeroDocumento')?.errors}">
                            </ng-template>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <label>Período Solicitação:</label>
                            <input type="text" formControlName="periodoSolicitacao" class="form-control" placeholder="dd/mm/aaaa" bsDatepicker #dp="bsDatepicker" [bsConfig]="bsConfig" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; ">
                        </div>
                        <div class="col">
                            <label>Período Saída:</label>
                            <input type="text" formControlName="periodoSaida" class="form-control" placeholder="dd/mm/aaaa" bsDatepicker #dp="bsDatepicker" [bsConfig]="bsConfig" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; ">
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="d-flex my-3 w-100">
        <div class="row w-100">
            <div class="col-9"></div>
            <div class="col-3 action-button-group">
                <button [auth]="this.atualizacao" class="action-primary-button" (click)="searchBillingSolicitations()">PESQUISAR</button>
            </div>
        </div>
    </div>

    <div class="table-container" *ngIf="solicitations?.length">

        <table class="grid-table" #gridUsuarioInterno *ngIf="usuarioInterno; else gridUsuarioExterno">
            <thead class="text-nowrap">
                <tr>
                    <th>Nº da Solic.</th>
                    <th>Solicitante</th>
                    <th>Cliente</th>
                    <th>Cliente a Faturar</th>
                    <th>Tipo Documento</th>
                    <th>Nº Documento</th>
                    <th>Data da Solicitação</th>
                    <th>Tipo de Solicitação</th>
                    <th>Status</th>
                    <th>Justificativa</th>
                    <th>Visualizar Solicitação</th>
                    <th>Documentos Anexos</th>
                    <th>Nº RPS</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr class="readonly-grid-line" *ngFor="let solicitation of solicitations; let i = index" app-linha-grid-solicitacoes-usuario-interno [solicitation]="solicitation" [mask]="getMaskForDocumentNumberOnGrid(solicitation.documento.tipoDocumento)" (onExpandSolicitation)="expandSolicitation($event)"
                    (onShowAttachedDocuments)="showAttachedDocuments($event)" (click)="selectGridRow(i)" [ngClass]="{'selected-row': selectedGridRowIndex === i}" (onFinishActionForUpdatingGrid)="searchBillingSolicitations()">
                </tr>
            </tbody>
        </table>

        <ng-template #gridUsuarioExterno>
            <table class="grid-table">
                <thead class="text-nowrap">
                    <tr>
                        <th>Nº da Solic.</th>
                        <th>Solicitante</th>
                        <th>Cliente</th>
                        <th>Cliente a Faturar</th>
                        <th>Tipo Documento</th>
                        <th>Nº Documento</th>
                        <th>Data da Solicitação</th>
                        <th>Tipo de Solicitação</th>
                        <th>Status</th>
                        <th>Justificativa</th>
                        <th>Visualizar Solicitação</th>
                        <th>Documentos Anexos</th>
                        <th>Nº RPS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="readonly-grid-line" *ngFor="let solicitation of solicitations; let i = index" app-linha-grid-solicitacoes-usuario-externo [solicitation]="solicitation" [mask]="getMaskForDocumentNumberOnGrid(solicitation.documento.tipoDocumento)" (onExpandSolicitation)="expandSolicitation($event)"
                        (onShowAttachedDocuments)="showAttachedDocuments($event)" (click)="selectGridRow(i)" [ngClass]="{'selected-row': selectedGridRowIndex === i}"></tr>
                </tbody>
            </table>
        </ng-template>

    </div>
</div>

<app-modal-documentos-anexados-estimativa-calculo
  #modalDocumentosAnexados class="modal-content"
  (onDisplayAttachedFile)="modalExibirAnexos.displayFile($event)"
  (onFinalizeSolicitation)="updateGridAfterConclusion()"
  [usuarioInterno]="usuarioInterno"
></app-modal-documentos-anexados-estimativa-calculo>

<app-modal-exibir-anexos #modalExibirAnexos></app-modal-exibir-anexos>
