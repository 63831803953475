import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RelacaoConteinerN4DocumentoTransporte } from 'src/app/models/documento-transporte/LacreConteinerRecepcaoDocumentoTransporte';

@Component({
  selector: 'tr[app-linha-grid-relacao-conteiner-n4-documento-transporte]',
  templateUrl: './linha-grid-relacao-conteiner-n4-documento-transporte.component.html',
  styleUrls: ['./linha-grid-relacao-conteiner-n4-documento-transporte.component.scss']
})
export class LinhaGridRelacaoConteinerN4DocumentoTransporteComponent implements AfterViewInit {

  @Input() public relacaoConteiner!: RelacaoConteinerN4DocumentoTransporte;
  public conteiner!: UntypedFormControl;
  public pesoApurado!: UntypedFormControl;
  public tara!: UntypedFormControl;
  public lacres!: UntypedFormControl;
  public formConteiner!: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder
  ) { }

  public ngAfterViewInit(): void {
    this.validation();
  }

  private validation(): void{
    this.conteiner = new UntypedFormControl(this.relacaoConteiner?.conteiner, Validators.compose([]));
    this.pesoApurado = new UntypedFormControl(this.relacaoConteiner?.pesoApurado, Validators.compose([]));
    this.tara = new UntypedFormControl(this.relacaoConteiner?.tara, Validators.compose([]));
    this.lacres = new UntypedFormControl(this.relacaoConteiner?.lacres, Validators.compose([]));
    this.formConteiner = this.fb.group({
      conteiner: this.conteiner,
      pesoApurado: this.pesoApurado,
      tara: this.tara,
      lacres: this.lacres
    });
  }

  public getContainer(): RelacaoConteinerN4DocumentoTransporte{
    return this.formConteiner.getRawValue() as RelacaoConteinerN4DocumentoTransporte;
  }

  public isContainerValid(): boolean{
    return !this.formConteiner.invalid;
  }

}
