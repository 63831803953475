import { RelacaoConteinerRCT } from './../../../../models/RecepcaoDueContingencia/RecepcaoConteinerTransship/RelacaoConteinerRCT';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { RecepcaoDueContingenciaService } from 'src/app/services/recepcao-due-contingencia.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'src/app/services/toast.service';
import { RecepcaoConteinerTransship } from 'src/app/models/RecepcaoDueContingencia/RecepcaoConteinerTransship/RecepcaoConteinerTransship';
import { RecepcaoConteinerTransshipComponent } from 'src/app/pages/recepcao-due-contigencia/recepcao-conteiner-transship/recepcao-conteiner-transship.component';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';

@Component({
  selector: 'app-relacao-conteineres-rct',
  templateUrl: './relacao-conteineres-rct.component.html',
  styleUrls: ['./relacao-conteineres-rct.component.scss']
})
export class RelacaoConteineresRCTComponent implements OnInit {

  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;
  public relacaoConteinerRCTDisplay!: RelacaoConteinerRCT[];
  public relacaoConteinerRCTForm!: RelacaoConteinerRCT[];
  private colorTheme = 'theme-dark-blue';
  public bsConfig?: Partial<BsDatepickerConfig>;
  private locale = 'pt-br';
  public blockSending: boolean = true;
  public identificador: UntypedFormControl = new UntypedFormControl('');

  constructor(
    private fb: UntypedFormBuilder,
    private localeService: BsLocaleService,
    private recepcaoDueContingenciaService: RecepcaoDueContingenciaService,
    private spinner: NgxSpinnerService,
    private toastService:ToastService,

  ) {

  }
  public ngOnInit(): void {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.validation();
  }

  private validation(): void{
    this.form = this.fb.group({
      usuario: [''],
      navio: ['', [Validators.required]],
      viagem: ['', [Validators.required]],
      conteineres: [null],
      qtdConteineres: [0],
      qtdConteineresEnviados: [0],
      qtdConteineresErrosEnviados: [0],
      identificador: ['']
    });
  }

  public changeGridFieldData(event: any, index: number, property: keyof RelacaoConteinerRCT): void{
    (this.relacaoConteinerRCTForm[index][property] as any) = event.target.value;
    this.form.get('relacaoConteineres')?.setValue(this.relacaoConteinerRCTForm);
    console.log(event.target.value)
  }

  public get areSearchFieldsEmpty(): boolean{
    return !!(!this.form.get('navio')?.value || !this.form.get('navio')?.value?.length)
      || !!(!this.form.get('viagem')?.value || !this.form.get('viagem')?.value?.length);
  }

  public searchContainerRelations(): void{
    this.blockSending = true;

    this.spinner.show();
    const [navio, viagem] = [this.form.get('navio')?.value, this.form.get('viagem')?.value]
    this.recepcaoDueContingenciaService.getConteinersForRecepcaoTransship(navio, viagem)
    .subscribe({
      next: (response) => {
        this.blockSending = false;
        const conteinerRecebido: RecepcaoConteinerTransship = response.data;
        this.setConteinerRelations(conteinerRecebido);
        this.spinner.hide();
        this.identificador.setValue(conteinerRecebido.identificador ?? '');
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      },
    })
  }

  private setConteinerRelations(recepcaoConteinerTransship: RecepcaoConteinerTransship): void{
    this.form.patchValue(recepcaoConteinerTransship);
    this.relacaoConteinerRCTDisplay = recepcaoConteinerTransship.conteineres?[...recepcaoConteinerTransship.conteineres]: [];
    this.relacaoConteinerRCTForm = recepcaoConteinerTransship.conteineres?[...recepcaoConteinerTransship.conteineres]: [];
  }

  public clear(): void{
    this.form.reset();
    this.relacaoConteinerRCTDisplay = [];
    this.relacaoConteinerRCTForm = [];
  }


  public sendDataToCCT(): void{
    this.spinner.show();
    this.recepcaoDueContingenciaService.sendDataToCCTForRecepcaoTransship(this.form.getRawValue())
      .subscribe({
        next: (response) => {
          response.message?.forEach(
            (message) => {
              if(message?.length){
                this.toastService.success(message);
              }
            }
          )
          this.blockSending = true;
          this.spinner.hide();
          this.searchContainerRelations();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }

        }
      });
  }

  public save(): void {
    this.spinner.show();
    this.recepcaoDueContingenciaService.saveDataToCCTForRecepcaoTransship(this.form.getRawValue())
      .subscribe({
        next: (response) => {
          response.message?.forEach(
            (message) => {
              if(message && message.length){
                this.toastService.success(message);
              }
            }
          )
          this.blockSending = false;
          // this.setConteinerRelations(response.data);
          this.searchContainerRelations();
          this.spinner.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }

        }
      });
    }
}
