import { Injectable, TemplateRef } from '@angular/core'
import { setToasts } from '../../assets/js/portal-tecon-utilities.js'

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor() { }
  private toasts: any[] = [];
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    var isRedirect = localStorage.getItem('redirectDocumental') == 'true' ? true : false;
    if (isRedirect) {
      setToasts(textOrTpl, "show");
      return;
    }
    this.toasts.push({ textOrTpl, ...options });
  }

  success(textOrTpl: string | TemplateRef<any>) {
    var isRedirect = localStorage.getItem('redirectDocumental') == 'true' ? true : false;
    if (isRedirect) {
      setToasts(textOrTpl, "success");
      return;
    }
    this.toasts.push({ textOrTpl, classname: 'bg-success text-light' });
  }

  error(textOrTpl: string | TemplateRef<any>) {
    var temToast = this.toasts.find(el => el.textOrTpl == textOrTpl)
    var isRedirect = localStorage.getItem('redirectDocumental') == 'true' ? true : false;

    if (temToast)
      return;
    
    if (isRedirect) {
      setToasts(textOrTpl, "error");
      return;
    }
    this.toasts.push({ textOrTpl, classname: 'bg-danger text-light' });

  }

  getToasts() {
    return this.toasts;
  }
  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
