<td *ngIf="conteiner">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="conteiner">
  </div>
</td>
<td *ngIf="lacres">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="lacres">
  </div>
</td>
