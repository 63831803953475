import { ChangeDetectorRef, Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { RelatorioConteineresLiberados } from 'src/app/models/relatorios/RelatorioConteineresLiberados/RelatorioConteineresLiberados';
import { TimezoneService } from 'src/app/shared/utils/timezone.service';

@Component({
  selector: 'app-impressao-conteineres-liberados',
  templateUrl: './impressao-conteineres-liberados.component.html',
  styleUrls: ['./impressao-conteineres-liberados.component.scss']
})
export class ImpressaoConteineresLiberadosComponent implements OnInit {

  protected dataInicio?: Date;
  protected dataFim?: Date;
  protected nomeRelatorio: string = '';
  protected dados: RelatorioConteineresLiberados[] = [];
  protected logo: string = '../../assets/images/logoSeptibaTecon.png';
  protected currentDate: Date = new Date();
  @ViewChildren('tableRelatorio') protected relatorios!: QueryList<TemplateRef<any>>;
  @ViewChild('templateImpressao') private templateImpressao!: TemplateRef<any>;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private timezoneService: TimezoneService
  ) { }

  public ngOnInit(): void {
  }

  public setPrintingData(data: {dataInicio: Date, dataFim: Date, nomeRelatorio: string, dados: RelatorioConteineresLiberados[]}): void{
    this.dataInicio = data.dataInicio;
    this.dataFim = data.dataFim;
    this.nomeRelatorio = data.nomeRelatorio;
    this.dados = data.dados;
    this.changeDetector.detectChanges();
  }

  public getTemplate(): TemplateRef<any>{
    return this.templateImpressao;
  }

  public getTimezone(datetimeString: string | undefined): string {
    return datetimeString?.length ? this.timezoneService.getTimezone(datetimeString) : "";
  }

}
