import { Observable, of } from 'rxjs';
import { FiltrosPesquisaRelatorioMovimentacaoClientes } from './../../models/relatorios/RelatorioMovimentacaoClientes/FiltrosPesquisaRelatorioMovimentacaoClientes';
import { RelatorioMovimentacaoClientes } from './../../models/relatorios/RelatorioMovimentacaoClientes/RelatorioMovimentacaoClientes';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SuccessResponse } from '../../models/HttpResponses/SuccessResponse';

@Injectable({
  providedIn: 'root'
})
export class RelatorioMovimentacaoClientesService {
  private logo = '../../assets/images/logoSeptibaTecon.png';

  constructor(private http: HttpClient) { }

  public getClientMovementReport(filtrosPesquisa: FiltrosPesquisaRelatorioMovimentacaoClientes): Observable<SuccessResponse> {
    return this.http.get<SuccessResponse>(`${environment.apiUrlForRelatorios}/carga/Cliente?${this.setQueryStringForSearchClientMovementReport(filtrosPesquisa)}`);
  }
  private setQueryStringForSearchClientMovementReport(filtrosPesquisa: FiltrosPesquisaRelatorioMovimentacaoClientes): string {
    let query: string[] = [];

    if (filtrosPesquisa.navio) {
      query.push(`Navio=${encodeURIComponent(filtrosPesquisa.navio)}`);
    }
    if (filtrosPesquisa.viagem) {
      query.push(`Viagem=${encodeURIComponent(filtrosPesquisa.viagem)}`);
    }
    if (filtrosPesquisa.servicoNavio) {
      query.push(`ServicoNavio=${encodeURIComponent(filtrosPesquisa.servicoNavio)}`);
    }
    if (filtrosPesquisa.cliente) {
      query.push(`Cliente=${encodeURIComponent(filtrosPesquisa.cliente)}`);
    }
    if (filtrosPesquisa.cnpjCliente) {
      query.push(`CnpjCliente=${encodeURIComponent(filtrosPesquisa.cnpjCliente)}`);
    }
    return query.join('&');
  }

}
