import { AbaSubpagina } from './../../models/AbaSubpagina';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EntregaConteinerComponent } from './entrega-conteiner/entrega-conteiner.component';
import { EntregaDocumentoTransporteComponent } from './entrega-documento-transporte/entrega-documento-transporte.component';

@Component({
  selector: 'app-entrega-due-contingencia',
  templateUrl: './entrega-due-contingencia.component.html',
  styleUrls: ['./entrega-due-contingencia.component.scss']
})
export class EntregaDueContingenciaComponent implements OnInit {

  @ViewChild(EntregaConteinerComponent) porConteiner!: EntregaConteinerComponent;
  @ViewChild(EntregaDocumentoTransporteComponent) porDocumentoTransporte!: EntregaDocumentoTransporteComponent;

  public selectedPage!: string;
  public readonly subpageTabs: AbaSubpagina[] = [
    {
      titulo: 'DUE por Contêiner',
      path: 'entrega-conteiner',
      authRoles: new AuthRoles(Telas.entregaDueConteiner, Permissoes.visualizacao)
    },
    {
      titulo: 'Entrega por Base Documento de Transporte',
      path: 'entrega-documento-transporte',
      authRoles: new AuthRoles(Telas.entregaDueDocumentoTransporte, Permissoes.visualizacao)
    }
  ];
  // public readonly visualizacao = new AuthRoles(Telas.entregaDueContingencia, Permissoes.visualizacao)
  // public readonly enviarCCT = new AuthRoles(Telas.entregaDueContingencia, Permissoes.enviarCCT)

  public readonly visualizacaoEntregaDueConteiner = new AuthRoles(Telas.entregaDueConteiner, Permissoes.visualizacao)
  public readonly visualizacaoEntregaDueDocumentoTransporte = new AuthRoles(Telas.entregaDueDocumentoTransporte, Permissoes.visualizacao)

  public form!: UntypedFormGroup;


  constructor(
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService) { }

  public ngOnInit(): void {
    this.selectedPage = 'entrega-conteiner';
    this.validation();
  }

  private validation(): void{
    this.form = this.fb.group({
      porConteiner: [null],
      porDocumentoTransporte: [null]
    });
  }

  public changeSelectedPage(pageName: string): void{
    if(this.selectedPage == pageName){
      return;
    }
    this.selectedPage = pageName;
  }

  public clear(): void{
    this.spinner.show('limpar');
    setTimeout(() => {
      this.spinner.hide('limpar');
    }, 1500);
    this.porConteiner.clear();
    this.porDocumentoTransporte.clear();
    this.form.reset();
    this.clear();
  }

}
