<td>{{solicitation.numeroSolicitacao}}</td>
<td>{{solicitation.solicitante}}</td>
<td>{{solicitation.cliente.nomeCliente == 'null' ? '' : solicitation.cliente.nomeCliente}}</td>
<td>{{solicitation.cliente.nomeClienteFaturar}}</td>
<td>{{solicitation.documento.tipoDocumento}}</td>
<td>{{(mask.length) ? (solicitation.documento.numeroDocumento | mask : [mask, maskPattern]) : (solicitation.documento.numeroDocumento)}}</td>
<td>{{solicitation.dataSolicitacao | date: 'dd/MM/yyyy HH:mm'}}</td>
<td>{{solicitation.tipoSolicitacao}}</td>
<td>{{solicitation.status}}</td>
<td *ngIf="solicitation.justificativaRecusa && solicitation.justificativaRecusa.length > 25; else justificativaCurta">
    {{shortenTextForJustificationDisplay()}} <a class="see-more-link" (click)="openModalForJustificationDisplay()">ver mais</a>
</td>
<ng-template #justificativaCurta>
    <td>{{solicitation.justificativaRecusa ?? ''}}</td>
</ng-template>
<td>
    <div class="grid-action-buttons">
        <button (click)="expandSolicitation()">
      <img src="assets/images/expand.svg" alt="">
    </button>
    </div>
</td>
<td>
    <div class="grid-action-buttons">
        <button class="attach-button" (click)="showAttachedDocuments()"
        >
          <img src="assets/images/blue-paperclip.svg" alt="">
          <span>Anexar</span>
        </button>
        <ng-template #finishedSolicitationAttachments>
          <button class="attach-button" (click)="showAttachedDocumentsOnFinished()">
            <img src="assets/images/blue-paperclip.svg" alt="">
            <span>Anexos</span>
          </button>
        </ng-template>
    </div>
</td>
<td>
    <div class="grid-action-buttons">
        <button class="attach-button" (click)="showRPSNumber()" [disabled]="!isVisualizarNumeroRPSEnabled">
      <span>Visualizar</span>
    </button>
    </div>
</td>
<app-modal-mensagem #modalMensagem></app-modal-mensagem>

<app-modal-mensagem #modalJustificativaRecusa></app-modal-mensagem>
