import { NgxSpinnerService } from 'ngx-spinner';
import { SolicitacaoLiberacaoDTO } from './../../models/LiberacaoDocumental/SolicitacaoLiberacaoDTO';
import { Router } from '@angular/router';
import { GridSolicitacoesLiberacaoDocumentalComponent } from './../../components/liberacao-documental/grid-solicitacoes-liberacao-documental/grid-solicitacoes-liberacao-documental.component';
import { FiltroLiberacaoDocumentalComponent } from './../../components/liberacao-documental/filtro-liberacao-documental/filtro-liberacao-documental.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from '../../models/HttpResponses/ErrorResponse';
import { FiltroLiberacaoDocumental } from './../../models/FiltroLiberacaoDocumental';
import { LiberacaoDocumentalService } from './../../services/liberacao-documental.service';
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { SolicitacaoLiberacaoDocumental } from 'src/app/models/SolicitacaoLiberacaoDocumental';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';
import { SolicitacaoDTO } from 'src/app/models/LiberacaoDocumental/SolicitacaoDTO';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-liberacao-documental',
  templateUrl: './liberacao-documental.component.html',
  styleUrls: ['./liberacao-documental.component.scss']
})
export class LiberacaoDocumentalComponent implements OnInit, AfterViewInit {

  public title: string = 'Liberação Documental';
  public subtitle: string = 'Adicione abaixo os dados necessários para o a liberação documental.';
  public displayDocumentalLiberationSolicitations!: SolicitacaoLiberacaoDocumental[];
  public receivedDocumentalLiberationSolicitations!: SolicitacaoDTO[];
  public regimeCirculacao!: string;
  public readonly visualizacaoImportacao = new AuthRoles(Telas.liberacaoDocumentalImportacao, Permissoes.visualizacao);
  public readonly visualizacaoExportacao = new AuthRoles(Telas.liberacaoDocumentalExportacao, Permissoes.visualizacao);
  public readonly visualizacaoCabotagem = new AuthRoles(Telas.liberacaoDocumentalCabotagem, Permissoes.visualizacao);
  public showLiberacaoImportacao: boolean = false;
  public showLiberacaoExportacao: boolean = false;
  public showLiberacaoCabotagem: boolean = false;
  public AuthRole!: AuthRoles;
  public search: boolean = false;

  @ViewChild(FiltroLiberacaoDocumentalComponent) filtro!: FiltroLiberacaoDocumentalComponent;
  @ViewChild(GridSolicitacoesLiberacaoDocumentalComponent) grid!: GridSolicitacoesLiberacaoDocumentalComponent;

  constructor(
    private liberacaoDocumentalService: LiberacaoDocumentalService,
    private toastService:ToastService,
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService,
    private route: Router,
    private changeDetector: ChangeDetectorRef
    ) {
    // let regimeRota: string | null = this.route.snapshot.paramMap.get('regimeCirculacao');
    // this.regimeCirculacao = regimeRota ?? '';
    this.showLiberacaoImportacao = this.verificaAcessoTela(this.visualizacaoImportacao);
    this.showLiberacaoExportacao = this.verificaAcessoTela(this.visualizacaoExportacao);
    this.showLiberacaoCabotagem = this.verificaAcessoTela(this.visualizacaoCabotagem);
    this.validaRouter(this.route.url);
  }

  public ngOnInit(): void {
    this.displayDocumentalLiberationSolicitations = [];
    // this.filtro.setStatusToPendente();
  }

  public ngAfterViewInit(): void {
    if(this.regimeCirculacao){
      this.filtro.setRegimeForInitialSearch(this.regimeCirculacao);
    }
    this.filtro.setStatusToPendente();
  }

  private validaRouter(route: string) {
    if (route == "liberacao-documental") {
      this.regimeCirculacao = ""
      this.showLiberacaoCabotagem = true;
      this.showLiberacaoExportacao= true;
      this.showLiberacaoImportacao = true;
      return;
    }
    if (route.includes("importacao")) {
      this.AuthRole = new AuthRoles(Telas.liberacaoDocumentalImportacao, "")
      this.regimeCirculacao = "Imp"
      return;
    }
    if (route.includes("exportacao")) {
      this.AuthRole = new AuthRoles(Telas.liberacaoDocumentalExportacao, "")
      this.regimeCirculacao = "Exp"
      return;
    }
    if (route.includes("cabotagem")) {
      this.AuthRole = new AuthRoles(Telas.liberacaoDocumentalCabotagem, "")
      this.regimeCirculacao = "Cabotagem"
      return;

    }
  }

  public verificaAcessoTela(authRole: AuthRoles) {
    if (!this.authService.verificarAcessoPermissao(authRole.tela, authRole?.permissao || ""))
      return false;
    else return true;
  }

  public searchFilteredSolicitations(filter: FiltroLiberacaoDocumental): void {
    this.search = true;
    this.spinner.show();
    this.liberacaoDocumentalService.searchLiberationSolicitation(filter)
      .subscribe({
        next: (result) => {
          console.log(result)
          this.spinner.hide();
          let solicitations: SolicitacaoLiberacaoDTO = result.data;
          solicitations.solicitacaoLiberacaoImpDTO = solicitations?.solicitacaoLiberacaoImpDTO?.length ? solicitations?.solicitacaoLiberacaoImpDTO : [];
          solicitations.solicitacaoLiberacaoExpDTO = solicitations?.solicitacaoLiberacaoExpDTO?.length ? solicitations?.solicitacaoLiberacaoExpDTO : [];
          solicitations.solicitacaoLiberacaoCabotagemDTO = solicitations?.solicitacaoLiberacaoCabotagemDTO?.length ? solicitations?.solicitacaoLiberacaoCabotagemDTO : [];
          this.receivedDocumentalLiberationSolicitations = [
            ...solicitations?.solicitacaoLiberacaoImpDTO,
            ...solicitations?.solicitacaoLiberacaoExpDTO,
            ...solicitations?.solicitacaoLiberacaoCabotagemDTO
          ];
          this.changeDetector.detectChanges();
          console.log(solicitations)
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          (err.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              this.toastService.error(error);
            }
          );
        }
      });
  }

  public searchSolicitationsAfterGridAction(): void{
    this.searchFilteredSolicitations(this.filtro?.getFilterFromFormFields());
  }

  public searchFilteredSolicitationsPendente(filter: FiltroLiberacaoDocumental): void {
    this.search = true;
    this.liberacaoDocumentalService.searchLiberationSolicitation(filter)
      .subscribe({
        next: (result) => {
          console.log(result)
          this.spinner.hide();
          let solicitations: SolicitacaoLiberacaoDTO = result.data;
          solicitations.solicitacaoLiberacaoImpDTO = solicitations?.solicitacaoLiberacaoImpDTO?.length ? solicitations?.solicitacaoLiberacaoImpDTO : [];
          solicitations.solicitacaoLiberacaoExpDTO = solicitations?.solicitacaoLiberacaoExpDTO?.length ? solicitations?.solicitacaoLiberacaoExpDTO : [];
          solicitations.solicitacaoLiberacaoCabotagemDTO = solicitations?.solicitacaoLiberacaoCabotagemDTO?.length ? solicitations?.solicitacaoLiberacaoCabotagemDTO : [];
          this.receivedDocumentalLiberationSolicitations = [
            ...solicitations?.solicitacaoLiberacaoImpDTO,
            ...solicitations?.solicitacaoLiberacaoExpDTO,
            ...solicitations?.solicitacaoLiberacaoCabotagemDTO
          ];
          this.changeDetector.detectChanges();
          console.log(solicitations)
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

}
