import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-ok',
  templateUrl: './modal-ok.component.html',
  styleUrls: ['./modal-ok.component.scss']
})
export class ModalOKComponent implements OnInit {

  public modalRef!: BsModalRef;

  @Input() public modalTitle!: string;

  @Output() public onOkAction: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('modalOK', {static: false}) modal!: TemplateRef<any>;

  constructor(
    private modalService: BsModalService
  ) { }

  public ngOnInit(): void {

  }
  // public openModalForConfirmOrRefuse(): void{
  //   this.modalRef = this.modalService.show(this.modal, {
  //     class: 'modal-lg',
  //     ignoreBackdropClick: true
  //   });
  // }
  public openModalForOk(): void{
    this.modalRef = this.modalService.show(this.modal, {
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }

  public closeModal(): void{
    this.onOkAction.emit();
    this.modalService.hide();
  }

  public okAction(): void{
    this.onOkAction.emit();
    this.closeModal();
  }

}
