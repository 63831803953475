<div class="modal-header">
  <h4>Visualizar solicitação</h4>
  <button class="btn-close close pull right"><span class="visualli-hidden"
      (click)="closeModal()">&times;</span></button>
</div>
<div class="modal-body">
  <div class="p-3">
    <app-documento #documento
      [isInsideModal]="true"
      [visualizar]="true" (onSendMask)="mascara = $event"></app-documento>
    <app-cliente #cliente
      [visualizar]="blockEdition"
      [camposFormulario]="camposFormulario"
    ></app-cliente>
    <app-dados-complementares-exportacao #dadosComplementares
      [visualizar]="blockEdition"
      [camposFormulario]="camposFormulario"
      [Canais]="canais" [Moedas]="moedas"
    ></app-dados-complementares-exportacao>
    <div [ngSwitch]="isDocumentoDAT">
      <div *ngSwitchCase="false">
        <app-relacao-conteiner-exportacao #relacaoConteineres
          [isInsideModal]="blockEdition" [visualizar]="blockEdition"
          [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
          [camposFormulario]="camposFormulario"
        ></app-relacao-conteiner-exportacao>
        <app-arvore-partes-liberacao-documental #arvoreDePartes
          [isExpDocument]="true" [mascara]="mascara"
          (onExpandPartDocument)="expandPartDocument($event)"></app-arvore-partes-liberacao-documental>
      </div>
      <app-grid-due-conteiner-exp #gridDueConteiner
        *ngSwitchCase="true"
        [blockEdition]="blockEdition"
        [canais]="canais"
        ></app-grid-due-conteiner-exp>
    </div>
    <app-documentos-anexados #documentosAnexados
      (onDownloadFileToDisplay)="
          documentosAnexados.downloadFileToDisplay(
            solicitacaoLiberacaoExportacaoService.downloadFileForAttachedDocumentGridOnModal($event.arquivoDocumentoAnexado),
            $event.index)
        " (onDownloadFileToSave)="
          documentosAnexados.downloadFileToSave(
            solicitacaoLiberacaoExportacaoService.downloadFileForAttachedDocumentGridOnModal($event.arquivoDocumentoAnexado),
            $event.index
          )
        " [isInsideModal]="blockEdition"
        [visualizar]="blockEdition"
        ></app-documentos-anexados>
  </div>
</div>
<div class="modal-footer">
  <div *ngIf="!blockEdition" class="action-button-group action-triple-buttons w-100 justify-content-end">
    <div class="span"></div>
    <div class="span"></div>
    <button class="action-primary-button"
      [disabled]="!areAllFormsValid"
      (click)="saveSolicitation()"
    >SALVAR</button>
  </div>
</div>
