import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Canal } from 'src/app/models/Canal';
import { GridDueConteinerExp } from 'src/app/models/SolicitacaoLiberacaoExportacao/GridDueConteinerExp';

@Component({
  selector: 'tr[app-linha-grid-due-conteiner-exp]',
  templateUrl: './linha-grid-due-conteiner-exp.component.html',
  styleUrls: ['./linha-grid-due-conteiner-exp.component.scss']
})
export class LinhaGridDueConteinerExpComponent implements OnInit, AfterViewInit {

  public form!: UntypedFormGroup;
  public conteinerId!: UntypedFormControl;
  public docAlfandegarioId!: UntypedFormControl;
  public numeroDUE!: UntypedFormControl;
  public numeroConteiner!: UntypedFormControl;
  public cnpj!: UntypedFormControl;
  public canal!: UntypedFormControl;
  @Input() dueConteiner!: GridDueConteinerExp;
  @Input() blockEdition!: boolean;
  @Input() canais!: Canal[];

  constructor(
    private fb: UntypedFormBuilder
  ) { }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.conteinerId = new UntypedFormControl(this.dueConteiner?.conteinerId ?? '', Validators.compose([Validators.required]));
    this.docAlfandegarioId = new UntypedFormControl(this.dueConteiner?.docAlfandegarioId ?? '', Validators.compose([Validators.required]));
    this.numeroDUE = new UntypedFormControl(this.dueConteiner?.numeroDUE ?? '', Validators.compose([Validators.required]));
    this.numeroConteiner = new UntypedFormControl(this.dueConteiner?.numeroConteiner ?? '', Validators.compose([Validators.required]));
    this.cnpj = new UntypedFormControl(this.dueConteiner?.cnpj ?? '', Validators.compose([Validators.required]));
    this.canal = new UntypedFormControl(this.dueConteiner?.canal ?? '', Validators.compose([Validators.required]));
    this.form = this.fb.group({
      conteinerId: this.conteinerId,
      docAlfandegarioId: this.docAlfandegarioId,
      numeroDUE: this.numeroDUE,
      numeroConteiner: this.numeroConteiner,
      cnpj: this.cnpj,
      canal: this.canal
    });
  }

  public getDueConteiner(): GridDueConteinerExp{
    return this.form?.getRawValue();
  }

  public get isDueConteinerValid(): boolean{
    return !this.form?.invalid;
  }

  public enableOrDisableCanal(): void{
    this.blockEdition ? this.canal?.disable({emitEvent: false}) : this.canal?.enable({emitEvent: false});
  }

}
