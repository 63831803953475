import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiltroRelatorioEntregaCargaNavio } from '../../models/relatorios/RelatorioEntregaCargaNavio/FiltroRelatorioEntregaCargaNavio';
import { RelatorioEntregaCargaNavio } from '../../models/relatorios/RelatorioEntregaCargaNavio/RelatorioEntregaCargaNavio';
import { DatePipe } from '@angular/common';
import { SuccessResponse } from '../../models/HttpResponses/SuccessResponse';


@Injectable({
    providedIn: 'root'
})

export class RelatorioEntregaCargaNavioService {
    private logo = '../../assets/images/logoSeptibaTecon.png';

    constructor(
      private http: HttpClient,
      private datePipe: DatePipe
    ) { }


    public searchEntregaCarga(solicitation: FiltroRelatorioEntregaCargaNavio): Observable<SuccessResponse> {
        return this.http.get<SuccessResponse>(`${environment.apiUrlForRelatorios}/Carga/Carga?${this.setQueryStringForSearchEntregaCargaSolicitation(solicitation)}`);
      }

      private setQueryStringForSearchEntregaCargaSolicitation(solicitation: FiltroRelatorioEntregaCargaNavio): string{
        let query: string[] = [];


        if(solicitation.navio){
          query.push(`Navio=${encodeURIComponent(solicitation.navio)}`);
        }
        if(solicitation.viagem){
          query.push(`Viagem=${encodeURIComponent(solicitation.viagem)}`);
        }
        if(solicitation.de){
          query.push(`PeriodoDe=${encodeURIComponent(new Date(solicitation.de).toLocaleDateString('PT-BR'))}`);
        }
        if(solicitation.ate){
          query.push(`PeriodoAte=${encodeURIComponent(new Date(solicitation.ate).toLocaleDateString('PT-BR'))}`);
        }

        return query.join('&');
      }

}




