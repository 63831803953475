<ng-template #modalRespostaPositivaNegativa>
    <div class="modal-header">
        <h4>{{modalTitle}}</h4>
        <button class="btn-close close pull right"><span class="visualli-hidden"
      (click)="closeModal()">&times;</span></button>
    </div>
    <div class="modal-body">
        <form>
            <div class="row my-2">
                <div class="col">
                    <label>Justificativa:</label>
                    <select class="custom-select" #justificativaCombobox>
            <option value="" disabled selected>Selecione</option>
            <option *ngFor="let justificativa of justificativas" [value]="justificativa">{{justificativa}}</option>
          </select>
                </div>
            </div>
            <div class="row my-2" [hidden]="!(justificativaCombobox?.value ?? '').toUpperCase().includes('OUTRO')">
                <div class="col">
                    <textarea maxlength="300" #justificativaTextarea class="form-control"></textarea>
                </div>
            </div>
            <ng-content></ng-content>
            <div class="my-2 action-button-group action-double-buttons">
                <button class="action-secondary-button" (click)="negativeAction()">{{negativeText}}</button>
                <button class="action-primary-button" [disabled]="
            (!justificativaCombobox?.value) || (justificativaCombobox?.value?.toUpperCase()?.includes('OUTRO') && !justificativaTextarea?.value)
          " (click)="positiveAction(
            justificativaCombobox?.value?.toUpperCase()?.includes('OUTRO') ? justificativaTextarea.value : justificativaCombobox.value
          )">
          {{positiveText}}
        </button>
            </div>
        </form>
    </div>
</ng-template>