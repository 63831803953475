import { LacreConteiner } from "../RecepcaoNotaFiscal/LacreConteiner";

export class RelacaoConteinerTransitoSimplificado{
  numeroConteiner?: string;
  peso?: number;
  tara?: number;
  id?: number;
  dataEnvio?: Date;
  usuario?: string;
  statusRecepcao?: string;
  cpfCondutor?: string;
  nomeCondutor?:string;
  tipoVeiculo?:string;
  placaCavalo?: string;
  placaReboque1?: string;
  placaReboque2?: string;
  placaReboque3?: string;
  recepcaoRealizadaComSucesso?: boolean;
  pesagem?: boolean;
  lacresN4?: LacreConteiner[];
  lacresCCT?: LacreConteiner[];
  identificador?: string;


}
