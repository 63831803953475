export enum StatusSolicitacaoLiberacao{
  RASCUNHO = 'RASCUNHO',
  PENDENTE = 'PENDENTE',
  LIBERADO = 'LIBERADO',
  RECUSADO = 'RECUSADO',
  CANCELADO = 'CANCELADO',
  AG_AUTOR_SUPERVISOR = 'AG_AUTOR_SUPERVISOR',
  AUTORIZADO_SUPERVISOR = 'AUTORIZADO_SUPERVISOR',
  AG_RECUSA_SUPERVISOR = 'AG_RECUSA_SUPERVISOR',
  RECUSADO_SUPERVISOR = 'RECUSADO_SUPERVISOR'
}
