import { Injectable } from '@angular/core';
import { PesquisaDocumento } from '../models/PesquisaDocumento';

@Injectable({
  providedIn: 'root'
})
export class PesquisaDocumentoService {

constructor() { }

public getConteiners(): PesquisaDocumento[]{
  return [
    {
      dataHora: '03/03/2000',
      tipoDocumento: 1,
      numeroDocumento: '33333333',
      matriculaUsuario:'1011029292',
      status : "1",
      cliente:'10'
    }
  ]
}
}
