<div class="mt-5">
  <app-subpage-tabs [activeTabPath]="selectedPage" [tabs]="subpageTabs" (onSelectTab)="changeSelectedPage($event)">
  </app-subpage-tabs>
  <div [ngSwitch]="selectedPage">
    <app-entrega-conteiner [auth]="this.visualizacaoEntregaDueConteiner" *ngSwitchCase="'entrega-conteiner'">
    </app-entrega-conteiner>
    <app-entrega-documento-transporte [auth]="this.visualizacaoEntregaDueDocumentoTransporte"
      *ngSwitchCase="'entrega-documento-transporte'"></app-entrega-documento-transporte>
  </div>
</div>

<!-- <div class="d-flex justify-content-center align-content-center mt-5">
    <div class="solicitation-action-buttons">
      <button class="btnLimpar" (click)="clear()">LIMPAR CAMPOS</button>
      <button [auth]="this.enviarCCT" class="btnEnviarCCT">ENVIAR AO CCT</button>
    </div>
  </div> -->