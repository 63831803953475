<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Informações de Carga</span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}"
        data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false"
        aria-label="Toggle navigation"></i>
      <!--fa fa-chevron-down-->
    </div>
  </div>

  <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <form *ngIf="form" [formGroup]="form">
      <div class="p-3">
        <div class="row">
          <div class="col">
            <label>Motivo Não Pesagem:</label>
            <input formControlName="motivoNaoPesagem" type="text" class="form-control"
              placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2" maxlength="100">
          </div>
          <div class="col">
            <label>Local do Armazenamento:</label>
            <input formControlName="localArmazenamento" type="text" class="form-control"
              placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2" maxlength="100">
          </div>
        </div><br>
        <div class="row">
          <div class="col-6">
            <label>Avarias Identificadas: </label>
            <textarea name="avarias" formControlName="avariasIdentificadas"
              class="form-control" maxlength="300" placeholder="Digite aqui..." aria-label="Digite aqui..."
              aria-describedby="basic-addon2" rows="8" cols="60"
              ></textarea>
          </div>
          <div class="col-6">
            <label>Divergencias Identificadas: </label>
            <textarea name="divergencias" formControlName="divergenciasIdentificadas"
              class="form-control" maxlength="300" placeholder="Digite aqui..." aria-label="Digite aqui..."
              aria-describedby="basic-addon2" rows="8" cols="60"
              ></textarea>
          </div>
        </div><br>
        <div class="row">
          <div class="col-6">
            <label>Observações Gerais: </label>
            <textarea name="observacoes" formControlName="observacoesGerais"
              class="form-control" maxlength="300" placeholder="Digite aqui..." aria-label="Digite aqui..."
              aria-describedby="basic-addon2" rows="8" cols="60"
              ></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
