<div class="card mt-4">
    <div>
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-3">
                    <div class="col">
                        <label>Navio: </label>
                        <input type="text" class="form-control" formControlName="navio" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2">
                    </div>
                    <div class="col">
                        <label for="">Viagem:</label>
                        <input type="text" class="form-control" formControlName="viagem" placeholder="Digite aqui...">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-3">
                        <label>Periodo de: </label>
                        <input type="text" class="form-control" formControlName="periodoDe" bsDatepicker #dpSolicitacao="bsDatepicker" aria-describedby="basic-addon2" placeholder="dd/mm/aaaa" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [maxDate]="form.get('periodoAte')?.value ? form.get('periodoAte')?.value : currentDate ? currentDate : undefined" [bsConfig]="bsConfig" [mask]="'00/00/0000'">
                    </div>
                    <div class="col-3">
                        <label>Até: </label>
                        <input type="text" class="form-control" formControlName="periodoAte" bsDatepicker #dpSolicitacao="bsDatepicker" aria-describedby="basic-addon2" placeholder="dd/mm/aaaa" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [minDate]="form.get('periodoDe')?.value ?? undefined" [maxDate]="currentDate ? currentDate : undefined" [bsConfig]="bsConfig" [mask]="'00/00/0000'">
                    </div>
                    <div class="col">
                        <label>Período a ser pesquisado: </label>
                        <div class="form-check">
                            <input class="form-check-input" id="flexRadioDefault2" type="radio" value="dataLiberacao" name="periodo" formControlName="periodo" (click)="unselectRadioButton('periodo', 'dataLiberacao')">
                            <label>
                          Data da Liberação
                      </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" id="flexRadioDefault2" type="radio" value="dataCadastro" name="periodo" formControlName="periodo" (click)="unselectRadioButton('periodo', 'dataCadastro')">
                            <label>
                          Data de Cadastro
                    </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" id="flexRadioDefault2" type="radio" value="dataRegistro" name="periodo" formControlName="periodo" (click)="unselectRadioButton('periodo', 'dataRegistro')">
                            <label>
                          Data de Registro
                  </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" id="flexRadioDefault2" type="radio" value="dataDesembaraco" name="periodo" formControlName="periodo" (click)="unselectRadioButton('periodo', 'dataDesembaraco')">
                            <label>
                          Data de Desembaraço
                        </label>
                        </div>
                    </div>
                    <div class="col">
                        <label>Regime de Circulação: </label>
                        <div class="form-check">
                            <input class="form-check-input" id="flexRadioDefault2" type="radio" value="IMP" name="regime" formControlName="regime" (click)="unselectRadioButton('regime', 'IMP')">
                            <label>
                        Importação
                    </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" id="flexRadioDefault2" type="radio" value="EXP" name="regime" formControlName="regime" (click)="unselectRadioButton('regime', 'EXP')">
                            <label>
                          Exportação
                  </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" id="flexRadioDefault2" type="radio" value="CABOTAGEM" name="regime" formControlName="regime" (click)="unselectRadioButton('regime', 'CABOTAGEM')">
                            <label>
                          Cabotagem
                </label>
                        </div>
                    </div>
                </div>


                <div class="row mb-3">
                    <div class="col-9 d-flex align-items-end">
                        <!-- <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p> -->
                    </div>
                    <div class="col-3 action-button-group">
                        <button class="action-primary-button" (click)="searchContainerRelations()" [disabled]="!form.valid">PESQUISAR</button>
                    </div>
                </div>

            </div>
        </form>
        <div class="overflow-auto my-3">
            <div class="table-container">
                <table class="grid-table table table-bordered text-nowrap" *ngIf="relatorioConteineresLiberadosGrid?.length">
                    <thead>
                        <tr>
                            <th>Dias armazenados</th>
                            <th>Contêiner</th>
                            <th>Navio</th>
                            <th>Viagem</th>
                            <th>Data de Entrada</th>
                            <th>Data de Saída</th>
                            <th>Canal</th>
                            <th>Nº do CE</th>
                            <th>Mercadoria</th>
                            <th>BL Master</th>
                            <th>Consignatário</th>
                            <th>Data de Cadastro</th>
                            <th>Data de Registro</th>
                            <th>Data de Liberação</th>
                            <th>Tipo de Documento</th>
                            <th>Documento</th>
                            <th>Matrícula de cadastro</th>
                            <th>Justificativa </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let relatorioConteineresLiberados of relatorioConteineresLiberadosGrid; let indexRecepcao = index">
                            <td>
                                <span>{{relatorioConteineresLiberados.diasArmazenados}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.conteiner}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.navio}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.viagem}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.dataEntrada | date: 'dd/MM/yyyy HH:mm:ss' : getTimezone(relatorioConteineresLiberados.dataEntrada?.toString())}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.dataSaida | date: 'dd/MM/yyyy HH:mm:ss' : getTimezone(relatorioConteineresLiberados.dataSaida?.toString())}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.canal}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.numCE}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.mercadoria}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.blMaster}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.consignatario}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.dataCadastro ? (relatorioConteineresLiberados.dataCadastro | date: 'dd/MM/yyyy HH:mm:ss' : getTimezone(relatorioConteineresLiberados.dataCadastro?.toString())) : ''}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.dataRegistro ? (relatorioConteineresLiberados.dataRegistro  | date: 'dd/MM/yyyy HH:mm:ss' : getTimezone(relatorioConteineresLiberados.dataRegistro?.toString())) : ''}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.dataLiberacao ?  (relatorioConteineresLiberados.dataLiberacao  | date: 'dd/MM/yyyy HH:mm:ss' : getTimezone(relatorioConteineresLiberados.dataLiberacao?.toString())) : ''}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.tipoDoc}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.numDoc}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.matriculaUsuario}}</span>
                            </td>
                            <td>
                                <span>{{relatorioConteineresLiberados.justificativa}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
<app-impressao-conteineres-liberados #impressao *ngIf="isPrinting"></app-impressao-conteineres-liberados>
