<div class="border boder-light">
    <div class="p-3">
        <form [formGroup]="form">
            <div class="row mb-3">
                <div class="col-6">
                    <label>Cliente:</label>
                    <input type="text" placeholder="Digite aqui..." class="form-control" formControlName="cliente">
                </div>
                <div class="col-6">
                    <label>CNPJ Cliente:</label>
                    <input type="text" placeholder="Digite aqui..." class="form-control" formControlName="cnpjCliente" [mask]="'00.000.000/0000-00'" [ngClass]="{'border border-danger': (!form.get('cnpjCliente')?.pristine && form.get('cnpjCliente')?.errors)}">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-6">
                    <label>Navio:</label>
                    <input type="text" placeholder="Digite aqui..." class="form-control" formControlName="navio">
                </div>
                <div class="col-6">
                    <label>Serviço do Navio:</label>
                    <select formControlName="servicoNavio" class="custom-select">
            <option [value]="''" selected>Selecione</option>
            <option *ngFor="let shipService of shipServices" [value]="shipService">{{shipService}}</option>
          </select>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-6">
                    <label>Viagem:</label>
                    <input type="text" placeholder="Digite aqui..." class="form-control" formControlName="viagem">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-9"></div>
                <div class="col-3 action-button-group">
                    <button class="action-primary-button" [disabled]="form.invalid" (click)="searchClientMovementReport()">PESQUISAR</button>
                </div>
            </div>
            <div class="my-3 overflow-auto">
                <div class="table-container">
                    <table class="grid-table table table-bordered text-nowrap" *ngIf="clientMovementReportGrid?.length">
                        <thead class="text-nowrap">
                            <th>Cliente</th>
                            <th>CNPJ</th>
                            <th>Navio</th>
                            <th>Viagem</th>
                            <th>Serviço</th>
                            <th>Qtde de Contêiner</th>
                            <th>Canal</th>
                            <th>Escritório</th>
                        </thead>
                        <tbody>
                            <tr class="readonly-grid-line" *ngFor="let relatorio of clientMovementReportGrid">
                                <td>{{relatorio.cliente}}</td>
                                <td>{{relatorio.cnpjCliente}}</td>
                                <td>{{relatorio.navio}}</td>
                                <td>{{relatorio.viagem}}</td>
                                <td>{{relatorio.servicoNavio}}</td>
                                <td>{{relatorio.quantidadeConteineres}}</td>
                                <td>{{relatorio.canal}}</td>
                                <td>{{relatorio.escritorio}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
    </div>
</div>
<app-impressao-relatorio-movimentacao-clientes *ngIf="isPrinting"></app-impressao-relatorio-movimentacao-clientes>
