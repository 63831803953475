<td *ngIf="selecionado" class="text-center">
  <input type="checkbox" [formControl]="selecionado">
</td>
<td *ngIf="numeroDUE">
  <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
  aria-describedby="basic-addon2" [value]="bloqueioDesbloqueio.numeroDUE">
</td>
<td *ngIf="conteiner">
  <div class="input-group">
    <input type="text" class="form-control" aria-label="Digite aqui..." aria-describedby="basic-addon2"
    [formControl]="conteiner">
  </div>
</td>
<td *ngIf="conteiner">
  <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
  aria-describedby="basic-addon2" [value]="bloqueioDesbloqueio.conteiner">
</td>
<td *ngIf="statusBloqueio">
  <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
  aria-describedby="basic-addon2" [value]="bloqueioDesbloqueio.statusBloqueio">
</td>
<td *ngIf="numeroSolicitacao">
  <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
  aria-describedby="basic-addon2" [value]="bloqueioDesbloqueio.numeroSolicitacaoLiberacao">
</td>
<td *ngIf="statusSolicitacao">
  <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
  aria-describedby="basic-addon2" [value]="bloqueioDesbloqueio.statusSolicitacao">
</td>
<td *ngIf="dataEntrega">
  <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
  aria-describedby="basic-addon2" [value]="bloqueioDesbloqueio.dataEntrada">
</td>
<td *ngIf="navio">
  <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
  aria-describedby="basic-addon2" [value]="bloqueioDesbloqueio.navio">
</td>
<td *ngIf="viagem">
  <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
  aria-describedby="basic-addon2" [value]="bloqueioDesbloqueio.viagem">
</td>
