<div class="card mt-4">
    <div>
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-3">
                    <div class="col-3">
                        <label>Periodo de: *</label>
                        <input type="text" class="form-control" formControlName="periodoDe" bsDatepicker #dpSolicitacao="bsDatepicker" aria-describedby="basic-addon2" placeholder="dd/mm/aaaa" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [bsConfig]="bsConfig" [maxDate]="form.get('periodoAte')?.value ? form.get('periodoAte')?.value : currentDate ? currentDate : undefined"
                            [mask]="'00/00/0000'"
                        >
                    </div>
                    <div class="col-3">
                        <label>Até: *</label>
                        <input type="text" class="form-control" formControlName="periodoAte" bsDatepicker #dpSolicitacao="bsDatepicker" aria-describedby="basic-addon2" placeholder="dd/mm/aaaa" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [bsConfig]="bsConfig" [minDate]="form.get('periodoDe')?.value ?? undefined" [maxDate]="currentDate ? currentDate : undefined"
                            [mask]="'00/00/0000'"
                        >
                    </div>
                    <div class="col-3">
                        <label>Status:</label>
                        <select formControlName="status" class="custom-select">
              <option [value]="''" selected>Selecione</option>
              <option *ngFor="let status of listaStatus" [value]="status.nomeStatus">{{status.nomeStatus}}</option>
            </select>
                    </div>
                    <div class="col-3 action-button-group">
                        <button class="action-primary-button" [disabled]="form.invalid" (click)="searchReprovacaoAprovacaoLiberacao()">PESQUISAR</button>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12 d-flex align-items-end">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="table-container">
                            <table class="table table-bordered text-nowrap" *ngIf="relatorioReprovacaoAprovacaoLiberacaoGrid?.length">
                                <thead class="thead-light text-nowrap">
                                    <tr>
                                        <th>Data/Hora</th>
                                        <th>Tipo de Documento</th>
                                        <th>Nº de Documento</th>
                                        <th>Matrícula Usuário</th>
                                        <th>Status</th>
                                        <th>Cliente</th>
                                        <th>CNPJ/CPF</th>
                                        <th>Motivo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let relatorio of relatorioReprovacaoAprovacaoLiberacaoGrid; let i = index">
                                        <td> {{ relatorio.dataHora | date:'dd/MM/YYYY HH:mm:ss' }} </td>
                                        <td> {{ relatorio.tipoDoc }} </td>
                                        <td> {{ relatorio.numDoc }}</td>
                                        <td> {{ relatorio.matricula }} </td>
                                        <td> {{ relatorio.status }} </td>
                                        <td> {{ relatorio.cliente }} </td>
                                        <td> {{ relatorio.cnpjCliente | mask: "00.000.000/0000-00||000.000.000-00" }} </td>
                                        <td> {{ relatorio.motivo }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<app-impressao-reprovacao-aprovacao-liberacao *ngIf="isPrinting"></app-impressao-reprovacao-aprovacao-liberacao>
