<ng-template #modal>
  <div class="modal-content">
    <div class="modal-header">
      <span class="modal-title" id="exampleModalLabel">Integrações em andamento</span>
      <button type="button" class="btn close btn-close" data-bs-dismiss="modal" aria-label="close"
        (click)="closeModal()" [disabled]="isCloseModalButtonDisabled">&times;
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="!isIntegrationTimedOut; else integrationTimeout">
        <div class="integration-item" *ngFor="let integration of integrationsList">
          <p>{{integration.descricao}}</p>
          <img src="assets/images/{{getImageNameByIntegrationStatus(integration)}}.svg" alt="">
        </div>
      </div>
      <ng-template #integrationTimeout>
        <div class="integration-item">
          <p>A integração de documentos parte está apresentando lentidão, tente realizar essa consulta dentro de alguns minutos</p>
          <img src="assets/images/cross.svg">
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
