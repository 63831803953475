import { CondutorRNF } from './../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/CondutorRNF';
import { TransportadorRNF } from './../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/TransportadorRNF';
import { AuthenticationService } from './../../../services/authentication/authentication.service';
import { InformacoesCargaRNF } from './../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/InformacoesCargaRNF';
import { RelacaoConteinerRNF } from './../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/RelacaoConteinerRNF';
import { LacreConteiner } from '../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/LacreConteiner';
import { DadosEnvioRNF } from './../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/DadosEnvioRNF';
import { HttpErrorResponse } from '@angular/common/http';
import * as uuid from 'uuid';
import { ConteinerEnvioCCT } from './../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/ConteinerEnvioCCT';
import { ConteinerRecepcaoNotaFiscal } from './../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/ConteinerRecepcaoNotaFiscal';
import { ErrorResponse } from '../../../models/HttpResponses/ErrorResponse';
import { NotaFiscalNFE } from './../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/NotaFiscalNFE';
import { ModalRespostaPositivaNegativaComponent } from './../../../shared/components/modais/modal-resposta-positiva-negativa/modal-resposta-positiva-negativa.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DadosEnvioRNFComponent } from './../../../components/recepcao-due-contigencia/recepcao-nota-fiscal-nfe/dados-envio-rnf/dados-envio-rnf.component';
import { DadosEnvioComponent } from './../../../components/dados-envio/dados-envio.component';
import { InformacoesCargasComponent } from './../../../components/recepcao-due-contigencia/recepcao-nota-fiscal-nfe/informacoes-cargas/informacoes-cargas.component';
import { CondutorComponent } from './../../../components/recepcao-due-contigencia/recepcao-nota-fiscal-nfe/condutor/condutor.component';
import { TransportadorComponent } from './../../../components/recepcao-due-contigencia/recepcao-nota-fiscal-nfe/transportador/transportador.component';
import { NotaFiscalNfeComponent } from './../../../components/recepcao-due-contigencia/recepcao-nota-fiscal-nfe/nota-fiscal-nfe/nota-fiscal-nfe.component';
import { RecepcaoDueContingenciaService } from './../../../services/recepcao-due-contingencia.service';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ComponenteConteinerComponent } from 'src/app/components/recepcao-due-contigencia/recepcao-nota-fiscal-nfe/componente-conteiner/componente-conteiner.component';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';
import { ToastService } from 'src/app/services/toast.service';
import { RetornoPesquisaRecepcaoNotaFiscal } from 'src/app/models/RecepcaoDueContingencia/RecepcaoNotaFiscal/RetornoPesquisaRecepcaoNotaFiscal';
import { RetornoPesquisaConteinerNFE } from 'src/app/models/RecepcaoDueContingencia/RecepcaoNotaFiscal/RetornoPesquisaConteinerNFE';
import { borderTopRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';

@Component({
  selector: 'app-recepcao-nota-fiscal',
  templateUrl: './recepcao-nota-fiscal.component.html',
  styleUrls: ['./recepcao-nota-fiscal.component.scss']
})
export class RecepcaoNotaFiscalComponent implements OnInit, AfterViewInit {

  public title: string = 'Nota Fiscal (NFe)';
  public subtitle: string = '';
  public formOut!: UntypedFormGroup;
  public containerNumber!: string;
  public pesagem!: boolean;
  public recepcaoRealizadaComSucesso!: boolean;
  public blockSending: boolean = true;
  public vinculoNF: boolean = false;
  public RetornoPesquisa!: UntypedFormGroup;
  public hasConteinerVinculado: boolean = false;

  @ViewChild(NotaFiscalNfeComponent) notafiscal!: NotaFiscalNfeComponent;
  @ViewChild(ComponenteConteinerComponent) conteiner!: ComponenteConteinerComponent;
  @ViewChild(TransportadorComponent) transportador!: TransportadorComponent;
  @ViewChild(CondutorComponent) condutor!: CondutorComponent;
  @ViewChild(InformacoesCargasComponent) informacoesCarga!: InformacoesCargasComponent;
  @ViewChild(DadosEnvioRNFComponent) dadosEnvioRNF!: DadosEnvioRNFComponent;
  @ViewChild(ModalRespostaPositivaNegativaComponent) modalDivergenciaLacreConteiner!: ModalRespostaPositivaNegativaComponent;
  public readonly visualizacao = new AuthRoles(Telas.recepcaoNotaFiscal, Permissoes.visualizacao)
  public readonly enviarCCT = new AuthRoles(Telas.recepcaoNotaFiscal, Permissoes.enviarCCT)
  public readonly salvar = new AuthRoles(Telas.recepcaoNotaFiscal, Permissoes.salvar)

  constructor(
    private fb: UntypedFormBuilder,
    private recepcaoDueContingenciaService: RecepcaoDueContingenciaService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private authenticationService: AuthenticationService
  ) { }

  public ngOnInit(): void {
    this.validation();
  }

  public ngAfterViewInit(): void {

  }

  private validation(): void {
    this.formOut = this.fb.group({
      notaFiscal: ['', [Validators.required]],
      conteiner: [''],
      transportador: [''],
      condutor: [''],
      informacoesCarga: [''],
      dadosEnvioRNF: ['']
    });
    this.RetornoPesquisa = this.fb.group({
      identificador: [''],
      dataEnvio: [null],
    })
  }

  public getDataFromSubforms(event: any): void {
    this.formOut.patchValue(event);
    let conteinerVinculadoNaoVinculado: RetornoPesquisaConteinerNFE | null = null;
    let conteinerNaoVinculado: RetornoPesquisaConteinerNFE | null = this.conteiner.sendUnlinkedContainer();
    let conteinerVinculado: RetornoPesquisaConteinerNFE | null = this.conteiner.sendLinkedContainer();
    if (conteinerNaoVinculado) {
      this.hasConteinerVinculado = !!conteinerVinculado
      this.vinculoNF = false;
      conteinerVinculadoNaoVinculado = conteinerNaoVinculado;
    }
    if (conteinerVinculado) {
      this.hasConteinerVinculado = !!conteinerVinculado
      this.vinculoNF = true;
      conteinerVinculadoNaoVinculado = conteinerVinculado;
    }
  }

  public searchForConteinerByInvoice(event: any): void {
    this.eraseSimpleData();
    this.getDataFromSubforms(event);
    let notaFiscal: NotaFiscalNFE = event.notaFiscal;
    if (!notaFiscal) {
      return;
    }
    this.spinner.show();
    this.recepcaoDueContingenciaService.getContainerForRecepcaoNotaFiscal(notaFiscal)
      .subscribe({
        next: (response) => {
          this.spinner.hide();
          if (!response) {
            return;
          }
          this.blockSending = false;
          const conteinerRecebido: RetornoPesquisaRecepcaoNotaFiscal = response.data;
          this.informacoesCarga.setFormValueFromReceivedCargoInfo({
            avariasIdentificadas: conteinerRecebido.avariasIdentificadas ?? '',
            divergenciasIdentificadas: conteinerRecebido.divergenciasIdentificadas ?? '',
            identificador: conteinerRecebido.identificador ?? '',
            localArmazenamento: conteinerRecebido.localArmazenamento ?? '',
            motivoNaoPesagem: conteinerRecebido.motivoNaoPesagem ?? '',
            observacoesGerais: conteinerRecebido.observacoesGerais ?? ''
          });

          this.recepcaoRealizadaComSucesso = conteinerRecebido.recepcaoRealizadaComSucesso;
          this.RetornoPesquisa.patchValue({ identificador: conteinerRecebido.identificador ?? '', dataEnvio: conteinerRecebido.dataEnvio ?? null })

          if (conteinerRecebido.recepcaoRealizadaComSucesso) {
            this.dadosEnvioRNF.setSendingDataFromReceivedConteinerData({
              dataEnvio: conteinerRecebido.dataEnvio,
              usuario: conteinerRecebido.usuario ?? '',
              status: conteinerRecebido.statusRecepcao ?? ''
            });
            this.blockSending = true;
          }
          this.pesagem = conteinerRecebido.conteinerNFE.some(conteiner => conteiner.pesagem);
          if (!this.pesagem) {
            this.toastService.error("Contêiner não possui o registro de TC_PESAGEM");
            this.blockSending = true;
          }
          else {
            this.conteiner.setSearchedContainerIntoGrid(conteinerRecebido);
            this.transportador.setTransporterDataFromReceivedTransporter({
              cpfCnpjTransportador: conteinerRecebido.cpfCnpjTransportador ?? '',
              nomeTransportador: conteinerRecebido.nomeTransportador ?? ''
            });
            this.condutor.setConductorDataFromReceivedConductor({
              cpfCondutor: conteinerRecebido.cpfCondutor ?? '',
              nomeCondutor: conteinerRecebido.nomeCondutor ?? ''
            });
            this.blockMotivoPesagem();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          (err.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              if (error && error.length) {
                this.toastService.error(error);
              }
            }
          );
        }
      });
  }

  private blockMotivoPesagem() {
    this.informacoesCarga.form.controls['motivoNaoPesagem'].disable();
  }

  public save(): void {
    if (this.blockSending || this.formOut.invalid) {
      return;
    }
    let conteinerEnvioCCT = this.getConteineresToSend();
    console.log(conteinerEnvioCCT)
    this.recepcaoDueContingenciaService.saveDataToCCTForRecepcaoNotaFiscal(conteinerEnvioCCT)
      .subscribe({
        next: (response) => {
          response.message?.forEach(
            (message) => {
              if (message && message.length) {
                this.toastService.success(message);
              }
            }
          )
          // this.dadosEnvioRNF.setSendingDataFromReceivedConteinerData(response.data as DadosEnvioRNF);
          this.notafiscal.searchInvoice();
          this.blockSending = false;
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide('carregando');
          console.log(err);
          (err.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              if (error && error.length) {
                this.toastService.error(error);
              }
            }
          );
        },
      });
  }

  public searchChaveNFE(notaFiscal: string): void {
    this.recepcaoDueContingenciaService.validaoNotaFiscal(notaFiscal)
      .subscribe({
        next: (response) => {
          response.message?.forEach(
            (message) => {
              if (message && message.length) {
                this.toastService.success(message);
              }
            }
          )
            this.notafiscal.setNotaFiscalValida();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide('carregando');
          console.log(err);
          (err.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              if (error && error.length) {
                this.toastService.error(error);
              }
            }
          );
          this.notafiscal.setNotaFiscalInvalida();
        },
      });
  }

  public clear(): void {
    this.spinner.show('limpar');
    setTimeout(() => {
      this.spinner.hide('limpar');
    }, 1500);
    this.formOut.reset();
    this.notafiscal.clear();
    this.eraseSimpleData();
    this.blockSending = true;
  }

  public eraseSimpleData(): void {
    this.conteiner.clear();
    this.transportador.clear();
    this.condutor.clear();
    this.informacoesCarga.clear();
    this.dadosEnvioRNF.clear();
  }

  public checkForSealDivergences(): void {
    if (!this.conteiner.checkIfSealNumbersAreEqual()) {
      this.modalDivergenciaLacreConteiner.openModalForConfirmOrRefuse();
    }
    else {
      this.sendDataToCCT();
    }
  }

  public sendDataToCCT(): void {
    if (this.blockSending || this.formOut.invalid) {
      return;
    }
    this.spinner.show();
    let conteinerEnvioCCT = this.getConteineresToSend();
    let qtdConteineres: number = conteinerEnvioCCT.conteinerVinculados?.length ?? 0;
    if (qtdConteineres > 50) {
      this.toastService.error(`Não é possível enviar mais de 50 contêineres de uma vez ao CCT!`);
      return;
    }
    this.recepcaoDueContingenciaService.sendDataToCCTForRecepcaoNotaFiscal(conteinerEnvioCCT)
      .subscribe({
        next: (response) => {
          this.spinner.hide();
          response.message?.forEach(
            (message) => {
              if (message && message.length) {
                this.toastService.success(message);
              }
            }
          )
          this.dadosEnvioRNF.setSendingDataFromReceivedConteinerData(response.data as DadosEnvioRNF);
          this.blockSending = true;
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          (err.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              if (error && error.length) {
                this.toastService.error(error);
              }
            }
          );
        },
      });
  }

  private getConteineresToSend(): ConteinerEnvioCCT {
    let informacoesCarga: InformacoesCargaRNF = this.informacoesCarga?.getCargoInfoFromForm();
    console.log(informacoesCarga);
    let transportador: TransportadorRNF = this.transportador.getTransporterDataFromForm();
    let condutor: CondutorRNF = this.condutor.getConductorDataFromForm();
    let conteinerEnvioCCT: ConteinerEnvioCCT = {
      conteinerVinculados: [],
      conteinerDesvinculados: [],
      motivoNaoPesagem: informacoesCarga.motivoNaoPesagem,
      localArmazenamento: informacoesCarga.localArmazenamento,
      avariasIdentificadas: informacoesCarga.avariasIdentificadas,
      divergenciasIdentificadas: informacoesCarga.divergenciasIdentificadas,
      observacoesGerais: informacoesCarga.observacoesGerais,
      identificador: this.RetornoPesquisa.value.identificador ?? '',
    }
    var conteinerVinculados = this.conteiner.getAllConteineresVinculados();
    conteinerEnvioCCT.conteinerVinculados = conteinerVinculados?.map(
      (conteiner) => {
        return {
          numeroConteiner: conteiner.numeroConteiner,
          dataChegada: conteiner.dataChegada,
          numeroBooking: conteiner.numeroBooking,
          lacresN4: conteiner.lacresN4,
          lacresPortalTecon: conteiner.lacresPortalTecon,
          peso: conteiner.peso,
          tara:conteiner.tara,
          chaveNFE: conteiner.chaveNFE,
          recepcaoRealizadaComSucesso: this.recepcaoRealizadaComSucesso,
          dataEnvio: this.RetornoPesquisa.value.dataEnvio ?? null,
          usuario: this.authenticationService.userName,
          pesagem: !!conteiner.peso,
          cpfCnpjTransportador: transportador.cpfCnpjTransportador,
          nomeTransportador: transportador.nomeTransportador,
          cpfCondutor: condutor.cpfCondutor,
          nomeCondutor: condutor.nomeCondutor,
          vinculadoNF: true,
        }
      }
    ) ?? [];
    var conteinerDesvinculados = this.conteiner.getAllConteineresDesvinculados();
    conteinerEnvioCCT.conteinerDesvinculados = conteinerDesvinculados?.map(
      (conteiner) => {
        return {
          numeroConteiner: conteiner.numeroConteiner,
          dataChegada: conteiner.dataChegada,
          numeroBooking: conteiner.numeroBooking,
          lacresN4: conteiner.lacresN4,
          lacresPortalTecon: conteiner.lacresPortalTecon,
          peso: conteiner.peso,
          tara: conteiner.tara,
          chaveNFE: conteiner.chaveNFE,
          recepcaoRealizadaComSucesso: this.recepcaoRealizadaComSucesso,
          dataEnvio: this.RetornoPesquisa.value.dataEnvio ?? null,
          usuario: this.authenticationService.userName,
          pesagem: !!conteiner.peso,
          cpfCnpjTransportador: transportador.cpfCnpjTransportador,
          nomeTransportador: transportador.nomeTransportador,
          cpfCondutor: condutor.cpfCondutor,
          nomeCondutor: condutor.nomeCondutor,
          vinculadoNF: conteiner.vinculado,
        }
      }
    ) ?? [];
    return conteinerEnvioCCT;
  }

}
