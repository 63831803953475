import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FiltrosPesquisaGridRelatorioMovimentacaoClientesComponent } from 'src/app/components/relatorios/relatorio-movimentacao-clientes/filtros-pesquisa-grid-relatorio-movimentacao-clientes/filtros-pesquisa-grid-relatorio-movimentacao-clientes.component';
import { Permissoes } from 'src/app/contants/permissoes';
import { Telas } from 'src/app/contants/telas';
import { AuthRoles } from 'src/app/models/authRoles';

@Component({
  selector: 'app-relatorio-movimentacao-clientes',
  templateUrl: './relatorio-movimentacao-clientes.component.html',
  styleUrls: ['./relatorio-movimentacao-clientes.component.scss']
})
export class RelatorioMovimentacaoClientesComponent implements OnInit {

  public title: string = 'Movimentação por Cliente no Terminal';
  public reportGridHasData: boolean = false;
  @ViewChild(FiltrosPesquisaGridRelatorioMovimentacaoClientesComponent) private filtrosPesquisaGridRelatorioMovimentacaoClientesComponent!: FiltrosPesquisaGridRelatorioMovimentacaoClientesComponent;
  public readonly visualizacao = new AuthRoles(Telas.relatorioMovimentacaoClientes, Permissoes.visualizacao)

  constructor(
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
  }

  async imprimirPdf() {
    await this.filtrosPesquisaGridRelatorioMovimentacaoClientesComponent.downloadPDF(this.title);
  }

  async salvarExcel() {
    await this.filtrosPesquisaGridRelatorioMovimentacaoClientesComponent.downloadExcel(this.title);
  }

  public clean() {
      this.filtrosPesquisaGridRelatorioMovimentacaoClientesComponent?.clearFields();
  }

}
