<div [auth]="this.visualizacao" class="mt-5">
    <app-title [title]='title' [subtitle]='subtitle'></app-title>
    <app-conteiner (onSearchForInvoice)="SearchForInvoiceByConteinerNumber($event)"></app-conteiner>
    <app-transporte (onSearchForInvoice)="SearchForInvoiceByConteinerNumber($event)"></app-transporte>
    <app-dados-envio (onSearchForInvoice)="SearchForInvoiceByConteinerNumber($event)"></app-dados-envio>

</div>

<div class="d-flex justify-content-center align-content-center mt-5">
    <div class="solicitation-action-buttons action-double-buttons">
        <button class="action-secondary-button" (click)="clean()">LIMPAR CAMPOS</button>
        <button [auth]="this.enviarCCT" (click)="sendDataToCCT()" class="action-primary-button" [disabled]="blockSending || formOut.invalid">ENVIAR AO CCT</button>
        <button [auth]="this.salvar" class="action-primary-button" (click)="save()" [disabled]="blockSending || formOut.invalid" style="display: none;">SALVAR</button>
    </div>
</div>