import { VerificacaoDocumentosService } from './../../../shared/utils/verificacao-documentos.service';
import { DocumentoParteCabotagem } from './../../../models/SolicitacaoLiberacaoCabotagem/DocumentoParteCabotagem';
import { Component, OnInit, AfterViewInit, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Mascara } from 'src/app/models/Mascara';
import { Campo } from 'src/app/models/Campo';
import { MaskPipe } from 'ngx-mask';
import { DefaultMaskPattern } from 'src/app/shared/utils/constantes-documentos';

@Component({
  selector: 'tr[app-linha-grid-arvore-partes-cabotagem]',
  templateUrl: './linha-grid-arvore-partes-cabotagem.component.html',
  styleUrls: ['./linha-grid-arvore-partes-cabotagem.component.scss']
})
export class LinhaGridArvorePartesCabotagemComponent implements AfterViewInit {

  @Input() documentoParte!: DocumentoParteCabotagem;
  @Input() mascara!: Mascara | undefined;
  @Input() camposFormulario!: Campo[];
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() shouldBlockPartQuantityEditionByDocumentType?: boolean;
  @Input() visualizar?: boolean = false;
  @Output() onSendDocumentoParte: EventEmitter<DocumentoParteCabotagem> = new EventEmitter<DocumentoParteCabotagem>();
  @Output() onFirstEdit: EventEmitter<void> = new EventEmitter<void>();
  public numeroDocumento!: UntypedFormControl;
  public numeroConteiner!: UntypedFormControl;
  public parteCadastrada!: UntypedFormControl;
  public numeroCE!: UntypedFormControl;
  public formDocumentoParte!: UntypedFormGroup;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private fb: FormBuilder,
    private maskPipe: MaskPipe
    ) { }

  public ngAfterViewInit(): void {
    this.validation();
    if(this.visualizar){
      this.disableAllFormFields();
      return;
    }
  }

  private validation(): void{
    this.numeroDocumento = new UntypedFormControl(
      (
        !this.mascara?.modelo?.length ?
        this.documentoParte?.numeroDocumento :
        this.maskPipe.transform(this.documentoParte?.numeroDocumento, [this.mascara?.modelo, DefaultMaskPattern.DEFAULT_MASK_CHARS])
      ),
      Validators.compose([this.canDocumentNumberBeEmptyBecauseOfType.bind(this)])
    );
    this.numeroConteiner = new UntypedFormControl(this.documentoParte.numeroConteiner, Validators.compose([Validators.required]));
    this.numeroCE = new UntypedFormControl(this.documentoParte.numeroCE, Validators.compose([Validators.required]));
    this.parteCadastrada = new UntypedFormControl(!!this.documentoParte.parteCadastrada, Validators.compose([Validators.required]));
    this.formDocumentoParte = this.fb.group({
      numeroDocumento: this.numeroDocumento,
      numeroConteiner: this.numeroConteiner,
      parteCadastrada: this.parteCadastrada,
      numeroCE: this.numeroCE
    });
    if(!!this.parteCadastrada?.value){
      this.numeroDocumento.disable();
    }
    this.formDocumentoParte.valueChanges.subscribe(
      () => {
        this.sendDocumentoParte();
      }
    );
    this.sendDocumentoParte();
    this.setSettingsfromFields();
  }

  private sendDocumentoParte(): void{
    this.onSendDocumentoParte.emit(this.getDocumentoParteFromFormFields());
    this.changeDetector.detectChanges();
  }

  public isDocumentoParteValid(): boolean{
    return !this.formDocumentoParte?.invalid;
  }

  public getDocumentoParteFromFormFields(): DocumentoParteCabotagem{
    return this.formDocumentoParte.getRawValue();
  }

  public setSettingsfromFields(): void{
    if(this.visualizar){
      this.disableAllFormFields();
      return;
    }
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      this.disableAllFormFields();
      return;
    }
    if(!this.camposFormulario?.length){
      this.enableAllFormFields();
      return;
    }
    for(let control in this.formDocumentoParte.controls){
      let fieldIndex = this.camposFormulario.findIndex(
        (field) => field.nome == control
      );
      if(fieldIndex >= 0){
        if(this.camposFormulario[fieldIndex].obrigatorio){
          this.formDocumentoParte.get(control)?.addValidators(Validators.required);
        }
        else{
          this.formDocumentoParte.get(control)?.removeValidators(Validators.required);
        }
        if(this.doesSolicitationHaveIntegration){
          if(this.camposFormulario[fieldIndex].leitura && !!this.formDocumentoParte.get(control)?.value){
            this.formDocumentoParte.get(control)?.disable();
          }
          else{
            this.formDocumentoParte.get(control)?.enable();
          }
        }
        else{
          if(this.camposFormulario[fieldIndex].leitura){
            this.formDocumentoParte.get(control)?.disable();
          }
          else{
            this.formDocumentoParte.get(control)?.enable();
          }
        }
        if(!!this.camposFormulario[fieldIndex]?.bloqueado){
          this.formDocumentoParte.get(control)?.disable();
        }
        if(!this.formDocumentoParte.get(control)?.value && this.camposFormulario[fieldIndex].obrigatorio){
          this.formDocumentoParte.get(control)?.enable();
        }
      }
      else{
        this.formDocumentoParte.get(control)?.enable();
        this.formDocumentoParte.get(control)?.removeValidators(Validators.required);
      }
      if(!!this.parteCadastrada?.value){
        this.numeroDocumento.disable();
      }
      this.changeDetector.detectChanges();
    }
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.formDocumentoParte.controls){
      this.formDocumentoParte.get(field)?.enable();
      this.formDocumentoParte.get(field)?.removeValidators(Validators.required);
      this.changeDetector.detectChanges();
    }
  }

  public disableAllFormFields(): void{
    this.formDocumentoParte.disable({emitEvent: false});
    this.changeDetector.detectChanges();
  }

  private canDocumentNumberBeEmptyBecauseOfType(control: AbstractControl): {invalid: boolean} | null{
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      return null;
    }
    if(!control?.value?.length){
      return {invalid: true};
    }
    return null;
  }

}
