<ng-template #templateImpressao>
  <div class="display-on-print">
    <table *ngFor="let relatorio of relatorios" class="relatorio">
      <thead>
        <tr class="header borderBottom">
          <td colspan="4">
            <img class="logo" src="{{this.logo}}" alt="logo" />
          </td>
          <td colspan="4" >
            <span class="header-nomeRelatorio"> {{nomeRelatorio}} </span>
          </td>
          <td colspan="12">
          <span class="header-now-date">
            {{currentDate.toLocaleString('pt-BR')}}
          </span>
        </td>
      </tr>
      <tr class="header borderBottom" *ngIf="dataInicio && dataFim">
        <td colspan="24">
          <span>
            Período De {{dataInicio ? dataInicio.toLocaleDateString('pt-BR') : ''}} Até {{dataFim ? dataFim.toLocaleDateString('pt-BR') : ''}}
          </span>
        </td>
      </tr>
      </thead>
      <tbody *ngTemplateOutlet="relatorio"></tbody>
      <tfoot>
        <tr class="foot-cont"></tr>
      </tfoot>
    </table>
  </div>
</ng-template>

<ng-template #tableRelatorio #tableRelatorio1>
    <thead>
      <tr>
        <th class="header-5-porcento" >Tipo de Documento</th>
        <th class="header-5-porcento" >Nº Documento</th>
        <th class="header-5-porcento" >Cliente</th>
        <th class="header-5-porcento" >CNPJ Cliente</th>
        <th class="header-5-porcento" >Transportador</th>
        <th class="header-5-porcento" >CNPJ Transportador</th>
        <th class="header-5-porcento" >Nome Motorista</th>
        <th class="header-5-porcento" >CPF Motorista</th>
        <th class="header-5-porcento" >Contêiner</th>
        <th class="header-5-porcento" >Dt/Hr da Recepção</th>
      </tr>
    </thead>
    <tr *ngFor="let relatorio of dados">
      <td class="header-5-porcento">{{relatorio?.tipoDoc ? relatorio.tipoDoc : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.numeroDoc ? relatorio.numeroDoc : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.cliente ? relatorio.cliente : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.cnpjCliente ? relatorio.cnpjCliente : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.nomeTransportador ? relatorio.nomeTransportador : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.cnpjTransportador ? relatorio.cnpjTransportador : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.nomeMotorista ? relatorio.nomeMotorista : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.cpfMotorista ? relatorio.cpfMotorista : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.conteiner ? relatorio.conteiner : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.dataHoraRecepcao ?  (relatorio.dataHoraRecepcao | date : 'dd/MM/YYYY HH:mm:ss') : ''}}</td>
    </tr>
</ng-template>

<ng-template #tableRelatorio #tableRelatorio2>
    <thead>
      <tr>
        <th class="header-5-porcento" >Dt/Hr da Pesagem</th>
        <th class="header-5-porcento">Retorno CCT</th>
        <th class="header-5-porcento">Tempo para Pesagem</th>
        <th class="header-5-porcento">Navio</th>
        <th class="header-5-porcento">Viagem</th>
        <th class="header-5-porcento">Booking</th>
        <th class="header-5-porcento">Dt/Hr da Chegada</th>
        <th class="header-5-porcento">Dt/Hr Envio CCT</th>
      </tr>
    </thead>
    <tr *ngFor="let relatorio of dados">
      <td class="header-5-porcento">{{relatorio?.dataHoraPesagem ? (relatorio.dataHoraPesagem | date : 'dd/MM/YYYY HH:mm:ss') : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.retornoCct ? relatorio.retornoCct : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.tempoPesagem ? relatorio.tempoPesagem : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.navio ? relatorio.navio : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.viagem ? relatorio.viagem : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.booking ? relatorio.booking : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.dataHoraChegada ? (relatorio.dataHoraChegada | date : 'dd/MM/YYYY HH:mm:ss') : ''}}</td>
      <td class="header-5-porcento">{{relatorio?.dataHoraEnvioCct ? (relatorio.dataHoraEnvioCct | date : 'dd/MM/YYYY HH:mm:ss') : ''}}</td>
    </tr>
</ng-template>

<ng-template #tableRelatorio #tableRelatorio3>
    <thead>
      <tr>
        <th class="header-5-porcento">Tempo para Recepção CCT</th>
      </tr>
    </thead>
    <tr *ngFor="let relatorio of dados">
      <td class="header-5-porcento">{{relatorio?.tempoRecepcaoCct ? relatorio.tempoRecepcaoCct : ''}}</td>
    </tr>
</ng-template>

