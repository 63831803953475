<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Relação de Contêiner <span class="text-danger" *ngIf="!isContainersRelationValid()">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <div class="p-3 d-flex action-button-group row">
            <div class="col-4">
                <button class="action-primary-button" [disabled]="doesSolicitationHaveIntegration || visualizar || relacaoConteinerReadonly" (click)="addManualContainer()">
          Incluir contêiner
        </button>
            </div>
        </div>
        <div class="p-3">
            <div class="table-container">
                <table class="grid-table">
                    <thead class="text-nowrap">
                        <tr>
                            <th>
                                <input class="form-chek-input" type="checkbox" [checked]="areAllItemsSelected" (click)="switchSelectionForAll()" [disabled]="visualizar || relacaoConteinerReadonly">
                            </th>
                            <th class="th-container">Contêiner</th>
                            <th>REEFER</th>
                            <th>IMO</th>
                            <th>OOG</th>
                            <th>Contêiner Transload</th>
                            <th *ngIf="shouldQuebraLoteAppear">Quebra de Lote</th>
                            <th>Liberado até</th>
                            <th *ngIf="shouldBlockPartQuantityEditionByDocumentType">Parte?</th>
                            <th *ngIf="shouldBlockPartQuantityEditionByDocumentType">Quantidade de Partes</th>
                            <th>...</th>
                        </tr>
                    </thead>
                    <tr *ngFor="let relacaoConteiner of containerRelationList; let indexRelacaoConteiner = index" app-linha-grid-relacao-conteiner-estimativa-calculo [conteinerReceived]="relacaoConteiner" (onSendContainer)="updateContainerRelation()" [fieldSettings]="fieldSettings"
                        [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration" (onEraseLine)="sendConteinerToErase(indexRelacaoConteiner)" (onManualSelection)="areAllItemsSelected = false" (onManualContainerNumberEdition)="sendContainerToSearchShipAndTrip($event, indexRelacaoConteiner)"
                        [visualizar]="visualizar || relacaoConteinerReadonly"
                        [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
                        [shouldQuebraLoteAppear]="shouldQuebraLoteAppear"
                    ></tr>
                </table>
            </div>
        </div>
    </div>
</div>
