<div class="card mt-4">
  <div class="card-header p-3">
      <div class="d-flex justify-content-between">
          <span>Árvore de Partes <span class="text-danger" *ngIf="listaDocumentosParte?.length && !areAllPartDocumentsValid">*</span></span>
          <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
      </div>
  </div>
  <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <div class="table-container">
      <table class="grid-table">
        <thead>
          <tr>
            <th>Nº do Contêiner</th>
            <th>Nº do Documento</th>
            <th>{{isExpDocument ? 'Qtd. de Partes Cadastradas' : 'Nº do CE'}}</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let arvorePartes of listaDocumentosParte; let indexArvorePartes = index"
            app-linha-parte-liberacao-documental
            [documentoParte]="arvorePartes" [mascara]="mascara" [isExpDocument]="isExpDocument"
            (onExpandPartDocument)="expandPartDocument($event)"
          >
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>
