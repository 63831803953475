import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from '../../../../models/HttpResponses/ErrorResponse';
import { DocumentosService } from 'src/app/services/documentos.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DocumentoEntregaDocumentoTransporte } from 'src/app/models/EntregaDueContingencia/EntregaDocumentoTransporte/DocumentoEntregaDocumentoTransporte';
import { EntregaDueContigenciaService } from 'src/app/services/entrega-due-contigencia.service';
import { TipoDocumentoTransporte } from 'src/app/models/RecepcaoDocumentoTransporte/TipoDocumentoTransporte';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-documento-edt',
  templateUrl: './documento-edt.component.html',
  styleUrls: ['./documento-edt.component.scss']
})
export class DocumentoEDTComponent implements OnInit {

  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;
  public documentTypes!: TipoDocumentoTransporte[];
  @Output() public onSearchDocument: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(
    private fb: UntypedFormBuilder,
    private entregaDueContingenciaService : EntregaDueContigenciaService,
    private toastService:ToastService,
    private spinner: NgxSpinnerService
  ) { }

  public ngOnInit(): void {
    this.validation();
    this.getDocumentTypes();
  }

  private validation(): void{
    this.form = this.fb.group({
      tipoDocumento: ['', [Validators.required]],
      numeroDocumento: ['', [Validators.required]]
    });
    this.form.get('tipoDocumento')?.valueChanges.subscribe(
      () => {
        this.form.get('numeroDocumento')?.reset();
      }
    )
  }
  private getDocumentTypes(): void{
    this.entregaDueContingenciaService.getDocumentTypes()
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          this.documentTypes = result.data as TipoDocumentoTransporte[];
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          (err?.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      });
  }



  public searchDocument(): void{
    if(this.form.invalid){
      return;
    }
    let documentToSearch: DocumentoEntregaDocumentoTransporte = {
      tipoDocumento: this.form.value.tipoDocumento,
      numeroDocumento: this.form.value.numeroDocumento
    };
    this.onSearchDocument.emit({'documento': documentToSearch});
  }

  public clear(): void{
    this.form.reset();
  }

  public getDocumentoFromForm(): DocumentoEntregaDocumentoTransporte{
    return this.form.getRawValue() as DocumentoEntregaDocumentoTransporte;
  }

  public isDocumentoValid(): boolean{
    return !this.form.invalid;
  }
}
