import { StatusSolicitacaoLiberacao } from './../models/LiberacaoDocumental/StatusSolicitacaoLiberacao.enum';
import { TipoVeiculoDocumentoTransporte } from 'src/app/models/TipoVeiculoDocumentoTransporte';
import { SuccessResponse } from '../models/HttpResponses/SuccessResponse';
import { DocumentoEntrega } from './../models/DocumentoEntrega';
import { ModalidadeDTA } from './../models/ModalidadeDTA';
import { Modalidade } from './../models/Modalidade';
import { Moeda } from 'src/app/models/Moeda';
import { Canal } from 'src/app/models/Canal';
import { Injectable } from '@angular/core';
import { StatusSolicitacaoDocumental } from '../models/StatusSolicitacaoDocumental';
import { RegimeCirculacao } from '../models/RegimeCirculacao';
import { HttpClient } from '@angular/common/http'
import { Observable, of, pluck, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DadosCliente } from '../models/DadosCliente';
import { ArquivoDocumentoAnexado } from '../models/DocumentosAnexados/ArquivoDocumentoAnexado';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {

  constructor(private http:HttpClient) { }

  public GetDocument(): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/ConfiguracaoCampoTela/TipoDocumento`);
  }

  public GetDocumentsForSolicitacaoLiberacaoImp(): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/ConfiguracaoCampoTela/TipoDocumento`);
  }

  public GetDocumentsForSolicitacaoLiberacaoExp(): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/ConfiguracaoCampoTela/TipoDocumento`);
  }

  public GetDocumentsForSolicitacaoLiberacaoCabotagem(): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/ConfiguracaoCampoTela/TipoDocumento`);
  }

  public getDocumentsForLiberacaoDocumental(): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForLiberacaoDocumental}/api/TipoDocumento`);
  }

  public GetClient(docCliente: string, nomeCliente: string): Observable<DadosCliente>{
    return this.http.get<any>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/Cliente/${docCliente}/${nomeCliente}`)
    .pipe(
      pluck('0')
    );
  }

  public getDocumentalSolicitationStatusForDocumentalLiberation(): Observable<StatusSolicitacaoDocumental[]>{
    return this.http.get<StatusSolicitacaoDocumental[]>(`${environment.apiUrlForLiberacaoDocumental}/LiberacaoDocumental/Status`);
  }

  public getDocumentoEntrega(): any[]{
    return [
      {
        id: 1,
        nome: "CE",
        descricao: "string"
      }
    ]
  }

  public getCirculationRegimes(): RegimeCirculacao[]{
    return [
      {
        idRegimeCirculacao: 1,
        nomeRegimeCirculacao: 'Imp'
      },
      {
        idRegimeCirculacao: 2,
        nomeRegimeCirculacao: 'Exp'
      },
      {
        idRegimeCirculacao: 3,
        nomeRegimeCirculacao: 'Cabotagem'
      }
    ];
  }

  public getCanais(): Observable<Canal[]>{
    return this.http.get<Canal[]>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/api/Canal`)
    .pipe(take(1));
  }

  public getMoedas(): Observable<Moeda[]>{
    return this.http.get<Moeda[]>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/api/Moeda`)
    .pipe(take(1));
  }

  public getModalidades(): Observable<Modalidade[]>{
    return this.http.get<Modalidade[]>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/Modalidade/Modalidades`)
    .pipe(take(1));
  }

  public getModalidadesDTA(): Observable<ModalidadeDTA[]>{
    return this.http.get<ModalidadeDTA[]>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/Modalidade/ModalidadesDTA`)
    .pipe(take(1));
  }

  // public getDocumentTypesByPageName(pageName: string): Observable<any>{
  //   return this.http.get<any>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/TipoDocumento?nomeTela=${pageName}`)
  //     .pipe(
  //       pluck('data')
  //     );
  // }

  public getFormFieldsByDocumentTypeInSolicitacaoLiberacaoImp(documentType: string): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/ConfiguracaoCampoTela?tipoDocumento=${documentType}`);
  }

  public getFormFieldsByDocumentTypeInSolicitacaoLiberacaoExp(documentType: string): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/ConfiguracaoCampoTela?tipoDocumento=${documentType}`);
  }

  public getFormFieldsByDocumentTypeInSolicitacaoLiberacaoCabotagem(documentType: string): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/ConfiguracaoCampoTela?tipoDocumento=${documentType}`);
  }

  public getDeliveryDocuments(): DocumentoEntrega[]{
    return [
      {
        id: 1,
        nome: 'CE',
        descricao: 'CE'
      },
      {
        id: 2,
        nome: 'DI',
        descricao: 'DI'
      }
    ];
  }

  public getVehicleTypesForDocumentoEntregaTransporte(): Observable<TipoVeiculoDocumentoTransporte[]>{
    return of([
      {
        id: 1,
        nome: 'Cavalo com 1 Reboque',
        descricao: 'Cavalo com 1 Reboque',
        quantidadeReboque: 1
      },
      {
        id: 2,
        nome: 'Cavalo com 2 Reboques',
        descricao: 'Cavalo com 2 Reboques',
        quantidadeReboque: 2
      },
      {
        id: 3,
        nome: 'Cavalo com 3 Reboques',
        descricao: 'Cavalo com 3 Reboques',
        quantidadeReboque: 3
      }
    ]);
  }

  public downloadFileFromAttachedFiles(file: ArquivoDocumentoAnexado): void{
    FileSaver.saveAs(file.arquivoDocumento as File, file.nomeArquivo);
  }
}
