import { RetornoStatusDocumentoParteDTO } from './../../../models/IntegracaoSignalR/RetornoStatusDocumentoParteDTO';
import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-integracao-documento-parte',
  templateUrl: './integracao-documento-parte.component.html',
  styleUrls: ['./integracao-documento-parte.component.scss']
})
export class IntegracaoDocumentoParteComponent implements OnInit {

  public integrationsList!: RetornoStatusDocumentoParteDTO[];
  public isIntegrationTimedOut!: boolean;
  @ViewChild('modal') modal!: TemplateRef<any>;
  @Output() onOpenModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
  ) { }

  public ngOnInit(): void {
  }

  public closeModal(): void{
    this.bsModalRef.hide();
    this.onCloseModal.emit();
  }

  public openModal(integrations: RetornoStatusDocumentoParteDTO[]): void{
    this.integrationsList = integrations;
    this.isIntegrationTimedOut = false;
    this.bsModalRef = this.bsModalService.show(this.modal, Object.assign({}, {
      class: 'modal-lg',
      ignoreBackdropClick: true
    }));
    this.onOpenModal.emit();

  }

  public getImageNameByIntegrationStatus(integration: RetornoStatusDocumentoParteDTO): string{
    if(!integration.concluido){
      return 'loading';
    }
    if(integration.sucesso){
      return 'check';
    }
    return 'cross';
  }

  public updateIntegrationStatus(integrationToUpdate: RetornoStatusDocumentoParteDTO): void{
    let integrationIndex: number = this.integrationsList.findIndex(integration =>
      integration.numeroDocumento == integrationToUpdate.numeroDocumento && integration.tipoDocumento == integrationToUpdate.tipoDocumento
    );
    this.integrationsList[integrationIndex].concluido = integrationToUpdate.concluido;
    this.integrationsList[integrationIndex].sucesso = integrationToUpdate.sucesso;
  }

  public areAllIntegrationsConcluded(): boolean{
    return this.integrationsList.every(
      (integration) => integration.concluido
    );
  }

  public setIntegrationsAsTimedOut(): void{
    this.isIntegrationTimedOut = true;
  }

  public getIntegrations(): RetornoStatusDocumentoParteDTO[]{
    return [...this.integrationsList];
  }

  public get isCloseModalButtonDisabled(): boolean{
    return !!this.integrationsList.length && !this.isIntegrationTimedOut && !this.areAllIntegrationsConcluded();
  }
}
