import { CondutorRNF } from './../../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/CondutorRNF';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DocumentCustomPattern, DocumentMaskEnum } from 'src/app/shared/utils/constantes-documentos';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';

@Component({
  selector: 'app-condutor',
  templateUrl: './condutor.component.html',
  styleUrls: ['./condutor.component.scss']
})
export class CondutorComponent implements OnInit {
  public form!: UntypedFormGroup;
  public isCollapsed = false;
  public maskForClientDocument: string = '000.000.000-00';
  public maskForNames: string = DocumentMaskEnum.NAME_MASK;
  public patternForNames = DocumentCustomPattern.NAME_PATTERN;

  @Output() onSendConductorData: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private fb: UntypedFormBuilder
    ) {
  }

  ngOnInit() {
    this.validation();
  }

  private validation(): void{
    this.form = this.fb.group({
      cpfCondutor: [''],
      nomeCondutor: ['']
    });
    this.form.valueChanges.subscribe(
      () => {
        this.onSendConductorData.emit({condutor: this.form.getRawValue()});
      }
    );
  }

  public validateClientDocument(clientDocument: string): string | null{
    if(!clientDocument){
      return null;
    }
    return this.verificacaoDocumentosService.calculateValidCPF(clientDocument);
  }

  public setConductorDataFromReceivedConductor(condutor: CondutorRNF): void{
    this.form.get('cpfCondutor')?.setValue(condutor.cpfCondutor);
    this.form.get('nomeCondutor')?.setValue(condutor.nomeCondutor);
  }

  public getConductorDataFromForm(): CondutorRNF{
    return this.form.getRawValue() as CondutorRNF;
  }

  public clear(): void{
    this.form.reset();
  }
}
