<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Dados Complementares <span class="text-danger" *ngIf="!areComplementaryDataValid()">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
            <!--fa fa-chevron-down-->
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row">
                    <div class="col">
                        <label>Navio: <span id="navio-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="navio" [ngClass]="{'border border-danger': form.get('navio')?.invalid && !form.get('navio')?.pristine}" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2" (change)="removeExtremitySpacesFromText('navio')"
                            [maxlength]="50">
                    </div>
                    <div class="col">
                        <label>Viagem: <span id="viagem-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="viagem" [ngClass]="{'border border-danger': form.get('viagem')?.invalid && !form.get('viagem')?.pristine}" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
                            (change)="removeExtremitySpacesFromText('viagem')" [maxlength]="50">
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-3">
                        <label>Valor CIF: <span id="valorCIF-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="valorCIF" [ngClass]="{'border border-danger': form.get('valorCIF')?.invalid && !form.get('valorCIF')?.pristine}" currencyMask [options]="{prefix: '', align:'left', thousands: '.', decimal: ','}" class="form-control"
                            placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2" [maxlength]="50">
                    </div>
                    <div class="col-3">
                        <label>Moeda: <span id="moeda-required-label" class="invisible">*</span></label>
                        <select class="custom-select" formControlName="moeda" [ngClass]="{'border border-danger': form.get('moeda')?.invalid && !form.get('moeda')?.pristine && form.get('moeda')?.value == null} ">
              <option [ngValue]="null" selected>Selecione</option>
              <option *ngFor="let moeda of Moedas" [value]="moeda.id">{{ moeda.nome }}</option>
            </select>
                    </div>

                    <div class="col">
                        <label>Valor da Mercadoria: <span id="valorMercadoria-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="valorMercadoria" [ngClass]="{'border border-danger': form.get('valorMercadoria')?.invalid && !form.get('valorMercadoria')?.pristine}" currencyMask [options]="{ prefix: '',align:'left',   thousands: '.', decimal: ',' }"
                            class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2" [maxlength]="50">
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-3">
                        <label>Data de Registro: <span id="dataRegistro-required-label" class="invisible">*</span></label><br>
                        <input type="text" formControlName="dataRegistro" [ngClass]="{'border border-danger': form.get('dataRegistro')?.invalid && !form.get('dataRegistro')?.pristine} " bsDatepicker #dp="bsDatepicker" class="form-control" aria-describedby="basic-addon2" [bsConfig]="bsConfig"
                            placeholder="dd/mm/aaaa" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; ">
                    </div>
                    <div class="col-3">
                        <label>Data do Desembaraço: <span id="dataDesembaraco-required-label" class="invisible">*</span></label><br>
                        <input type="text" formControlName="dataDesembaraco" [ngClass]="{'border border-danger': form.get('dataDesembaraco')?.invalid && !form.get('dataDesembaraco')?.pristine} " bsDatepicker #dp="bsDatepicker" placeholder="dd/mm/aaaa" class="form-control" [bsConfig]="bsConfig"
                            aria-describedby="basic-addon2" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%;">
                    </div>
                    <div class="col">
                        <label>N° do CE: <span id="numeroCE-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="numeroCE" [ngClass]="{'border border-danger': form.get('numeroCE')?.invalid && !form.get('numeroCE')?.pristine} " class="form-control" mask="000000000000000" placeholder="Digite aqui..." aria-label="Digite aqui..."
                            aria-describedby="basic-addon2">
                    </div>
                </div><br>
                <div class="row">
                    <div class="col">
                        <label>CPF do Recebedor: <span id="cpfRecebedor-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="cpfRecebedor" [ngClass]="{'border border-danger': !form.get('cpfRecebedor')?.pristine && form.get('cpfRecebedor')?.errors }" class="form-control" mask="000.000.000-00" placeholder="Digite aqui..." aria-label="Digite aqui..."
                            aria-describedby="basic-addon2">
                    </div>
                    <div class="col">
                        <label>Canal: <span id="canal-required-label" class="invisible">*</span></label>
                        <select formControlName="canal" [ngClass]="{'border border-danger': form.get('canal')?.invalid && !form.get('canal')?.pristine && form.get('canal')?.value == null} " class="custom-select" id="canal">
              <option [ngValue]="null" selected>Selecione</option>
              <option *ngFor="let canal of Canais" [value]="canal.id">{{ canal.nome }}</option>
            </select>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-3">
                        <label>DI Única Carga de Projeto? <span id="diUnica-required-label" class="invisible">*</span></label>
                        <select formControlName="diUnica" [ngClass]="{'border border-danger': form.get('diUnica')?.invalid && !form.get('diUnica')?.pristine && form.get('diUnica')?.value == null} " class="custom-select">
              <option [ngValue]="null" selected>Selecione</option>
              <option [ngValue]="true">Sim</option>
              <option [ngValue]="false">Não</option>
            </select>
                    </div>
                    <div class="col-3">
                        <label>Descarga Direta? <span id="descargaDireta-required-label" class="invisible">*</span></label>
                        <select formControlName="descargaDireta" [ngClass]="{'border border-danger': form.get('descargaDireta')?.invalid && !form.get('descargaDireta')?.pristine && form.get('descargaDireta')?.value == null} " class="custom-select">
              <option [ngValue]="null" selected>Selecione</option>
              <option [ngValue]="true">Sim</option>
              <option [ngValue]="false">Não</option>
            </select>
                    </div>
                    <div class="col-3">
                        <label>Modalidade: <span id="modalidade-required-label" class="invisible">*</span></label>
                        <select formControlName="modalidade" [ngClass]="{'border border-danger': form.get('modalidade')?.invalid && !form.get('modalidade')?.pristine && form.get('modalidade')?.value == null} " class="custom-select">
              <option [ngValue]="null" selected>Selecione</option>
              <option *ngFor="let modalidade of Modalidades" [value]="modalidade.id">{{ modalidade.descricao }}</option>
            </select>
                    </div>
                    <div class="col-3">
                        <label>Modalidade DTA: <span id="modalidadeDta-required-label" class="invisible">*</span></label>
                        <select formControlName="modalidadeDta" [ngClass]="{'border border-danger': form.get('modalidadeDta')?.invalid && !form.get('modalidadeDta')?.pristine && form.get('modalidadeDta')?.value == null} " class="custom-select">
              <option [ngValue]="null" selected>Selecione</option>
              <option *ngFor="let modalidadeDTA of ModalidadesDTA" [value]="modalidadeDTA.id">{{ modalidadeDTA.descricao }}
              </option>
            </select>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-3">
                        <label>Leilão: <span id="leilao-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="leilao" [ngClass]="{'border border-danger': !form.get('leilao')?.pristine && form.get('leilao')?.errors}" class="form-control" mask="0000000/000000/0000" placeholder="Digite aqui..." aria-describedby="basic-addon2">
                    </div>
                    <div class="col-3">
                        <label>Lote: <span id="lote-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="lote" [ngClass]="{'border border-danger': form.get('lote')?.invalid && !form.get('lote')?.pristine} " class="form-control" mask="999" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2">
                    </div><br>
                    <!-- <div class="col"><br>
                        <div class="form-check" [hidden]="areEntregaSiscomexAndDocumentoEntregaBlocked">
                            <input formControlName="entregaSiscomex" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">
                &nbsp;Entrega Siscomex até 30<br>&nbsp; dias após saída
              </label>
                        </div>
                    </div> -->
                    <!-- <div class="col">
                        <div [hidden]="areEntregaSiscomexAndDocumentoEntregaBlocked">
                            <label>Documento de Entrega: <span id="documentoEntrega-required-label" class="invisible">*</span></label><br>
                            <div *ngFor="let documentoEntrega of DocumentosEntrega" class="form-check">
                                <input class="form-check-input" type="radio" name="documentoEntrega" [value]="documentoEntrega.nome" id="flexRadioDefault2" formControlName="documentoEntrega">
                                <label class="form-check-label" for="flexRadioDefault2">
                  {{documentoEntrega.nome}}
                </label>
                            </div>
                        </div>
                    </div> -->
                </div><br>

                <div class="row">
                    <div class="col">
                        <label>Nome Despachante: <span id="nomeDespachante-required-label" class="invisible">*</span></label>
                        <select class="custom-select" formControlName="nomeDespachante" [ngClass]="{'border border-danger': form.get('nomeDespachante')?.invalid && !form.get('nomeDespachante')?.pristine}">
                          <option>Selecione</option>
                          <option *ngFor="let nomeDespachante of nomesDespachantes" [ngValue]="nomeDespachante.nomeDespachante" [selected]="nomeDespachante.nomeDespachante">
                            {{nomeDespachante.nomeDespachante}}
                          </option>
                        </select>
                    </div>
                    <div class="col">
                        <label>Telefone: <span id="telefoneContato-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="telefoneContato" [ngClass]="{'border border-danger': form.get('telefoneContato')?.invalid && !form.get('telefoneContato')?.pristine} " class="form-control" mask="(00) 0 0000-0000||(00) 0000-0000" placeholder="Digite aqui..."
                            aria-label="Digite aqui..." aria-describedby="basic-addon2">
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-6">
                        <label>E-mail: <span id="emailContato-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="emailContato" [ngClass]="{'border border-danger': form.get('emailContato')?.invalid && !form.get('emailContato')?.pristine} " class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
                            (change)="removeExtremitySpacesFromText('emailContato')" [maxlength]="50">
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-6">
                        <label>Comentário: <span id="comentario-required-label" class="invisible">*</span></label>
                        <textarea name="comentario" formControlName="comentario" [ngClass]="{'border border-danger': form.get('comentario')?.invalid && !form.get('comentario')?.pristine} " class="form-control" [maxlength]="maxlengthForTextareas" placeholder="Digite aqui..."
                            aria-label="Digite aqui..." aria-describedby="basic-addon2" rows="8" cols="60" (change)="removeExtremitySpacesFromText('comentario')"></textarea>
                    </div>
                    <div class="col-6">
                        <label>Situação do Despacho: <span id="situacaoDespacho-required-label" class="invisible">*</span></label>
                        <textarea name="situacaoDespacho" formControlName="situacaoDespacho" [ngClass]="{'border border-danger': form.get('situacaoDespacho')?.invalid && !form.get('situacaoDespacho')?.pristine} " class="form-control" [maxlength]="maxlengthForTextareas" placeholder="Digite aqui..."
                            aria-label="Digite aqui..." aria-describedby="basic-addon2" rows="8" cols="60" (change)="removeExtremitySpacesFromText('situacaoDespacho')"></textarea>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col d-flex">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
