<td *ngIf="conteiner">
  <div class="input-group">
    <input type="text" class="form-control" readonly
      [formControl]="conteiner"
    >
  </div>
</td>
<td>
 <ng-container [ngTemplateOutlet]="isImpDocument ? numeroCEField : (isExpDocument ? numeroDocumentoParteField : numeroCEField)"></ng-container>
</td>
<td>
  <ng-container [ngTemplateOutlet]="isImpDocument ? numeroDocumentoParteField : (isExpDocument ? quantidadePartesField : numeroDocumentoParteField)"></ng-container>
</td>

<ng-template #numeroCEField>
  <div class="input-group" *ngIf="numeroCE">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="numeroCE" [mask]="'000000000000000'"
      (change)="onFirstEdit.emit()" [ngClass]="{'border border-danger': numeroCE.touched && numeroCE.errors}"
    >
  </div>
</ng-template>

<ng-template #numeroDocumentoParteField>
  <div class="input-group" *ngIf="numeroDocumentoParte">
    <input *ngIf="mascara?.modelo; else numeroDocumentoParteFieldNoMask" type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="numeroDocumentoParte" [mask]="mascara?.modelo ?? ''" [specialCharacters]="mascara?.caracteresEspeciais ?? []"
      (change)="onFirstEdit.emit()" [ngClass]="{'border border-danger': numeroDocumentoParte.touched && numeroDocumentoParte.errors}"
    >
    <ng-template #numeroDocumentoParteFieldNoMask>
      <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
        [formControl]="numeroDocumentoParte"
        (change)="onFirstEdit.emit()" [ngClass]="{'border border-danger': numeroDocumentoParte.touched && numeroDocumentoParte.errors}"
      >
    </ng-template>
  </div>
</ng-template>

<ng-template #quantidadePartesField>
  <div class="input-group">
    <input type="text" class="form-control" readonly
      [formControl]="quantidadePartes"
    >
  </div>
</ng-template>
