<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Transportador</span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}"
        data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false"
        aria-label="Toggle navigation"></i>
      <!--fa fa-chevron-down-->
    </div>
  </div>

  <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <form [formGroup]="form">
      <div class="p-3">
        <div class="row">
          <div class="col">
            <label>CPF Condutor:</label>
            <input formControlName="cpfCondutor" type="text" class="form-control" placeholder="Digite aqui..."
              aria-label="Digite aqui..." aria-describedby="basic-addon2" [mask]="maskForClientDocument" maxlength="14"
              [ngClass]="{'border border-danger': !form.get('documento')?.pristine && (form.get('documento')?.errors || validateClientDocument(form.get('documento')?.value))}">
            <p *ngIf="!form.get('documento')?.pristine && (form.get('documento')?.errors || validateClientDocument(form.get('documento')?.value))?.length"
              class="text-danger">
              {{validateClientDocument(form.get('documento')?.value)}}
            </p>
          </div>
          <div class="col">
            <label>Nome: </label>
            <div class="input-group mb-3">
              <input formControlName="nome" type="text" class="form-control" placeholder="Digite aqui..."
                aria-label="Digite aqui..." aria-describedby="basic-addon2">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>Tipo de Veículo: </label>
            <div class="input-group mb-3">
              <select class="custom-select" formControlName="tipoVeiculo">
                <option value="" disabled selected>Selecione</option>
                <option [value]="'Cavalo com 1 Reboque'">Cavalo com 1 Reboque</option>
                <option [value]="'Cavalo com 2 Reboques'">Cavalo com 2 Reboques</option>
                <option [value]="'Cavalo com 3 Reboques'">Cavalo com 3 Reboques</option>
              </select>
            </div>
          </div>

          <div class="col">
            <label>Placa Cavalo: </label>
            <div class="input-group mb-3">
              <input formControlName="placaCavalo" type="text" class="form-control" placeholder="Digite aqui..."
                aria-label="Digite aqui..." aria-describedby="basic-addon2">
            </div>
          </div>

          <div class="col" *ngIf="quantidadeReboques >= 1">
            <label>Placa Reboque 1: </label>
            <div class="input-group mb-3">
              <input formControlName="placaReboque1" type="text" class="form-control" placeholder="Digite aqui..."
                aria-label="Digite aqui..." aria-describedby="basic-addon2">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3" *ngIf="quantidadeReboques >= 2">
            <label>Placa Reboque 2: </label>
            <div class="input-group mb-3">
              <input formControlName="placaReboque2" type="text" class="form-control" placeholder="Digite aqui..."
                aria-label="Digite aqui..." aria-describedby="basic-addon2">
            </div>
          </div>
          <div class="col-3" *ngIf="quantidadeReboques >= 3">
            <label>Placa Reboque 3: </label>
            <div class="input-group mb-3">
              <input formControlName="placaReboque3" type="text" class="form-control" placeholder="Digite aqui..."
                aria-label="Digite aqui..." aria-describedby="basic-addon2">
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
