import { Modalidade } from 'src/app/models/Modalidade';
import { DocumentosService } from 'src/app/services/documentos.service';
import { Observable } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { ModalExibirAnexosComponent } from '../modal-exibir-anexos/modal-exibir-anexos.component';
import { LinhaGridDocumentosAnexadosComponent } from '../linha-grid-documentos-anexados/linha-grid-documentos-anexados.component';
import { ArquivoDocumentoAnexado } from '../../../../models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { Component, OnInit, Output, EventEmitter, ViewChildren, QueryList, ViewChild, TemplateRef, ChangeDetectorRef, Input, AfterContentInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { SuccessResponse } from 'src/app/models/HttpResponses/SuccessResponse';
import { TipoDocumentoAnexado } from 'src/app/models/DocumentosAnexados/TipoDocumentoAnexado';

@Component({
  selector: 'app-documentos-anexados',
  templateUrl: './documentos-anexados.component.html',
  styleUrls: ['./documentos-anexados.component.scss']
})
export class DocumentosAnexadosComponent implements OnInit, AfterContentInit {

  public documentsList!: ArquivoDocumentoAnexado[];
  public isCollapsed: boolean = false;
  public storedFileConfigs: TipoDocumentoAnexado[] = [];
  @Input() public isInsideModal!: boolean;
  @Input() public visualizar?: boolean = false
  @Output() public onSendAttachedFiles: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() public onDownloadFileToDisplay: EventEmitter<{arquivoDocumentoAnexado: ArquivoDocumentoAnexado, index: number}> = new EventEmitter<{arquivoDocumentoAnexado: ArquivoDocumentoAnexado, index: number}>();
  @Output() public onDownloadFileToSave: EventEmitter<{arquivoDocumentoAnexado: ArquivoDocumentoAnexado, index: number}> = new EventEmitter<{arquivoDocumentoAnexado: ArquivoDocumentoAnexado, index: number}>();
  @Output() public onDocumentosAnexadosLoad: EventEmitter<void> = new EventEmitter<void>();
  @ViewChildren(LinhaGridDocumentosAnexadosComponent) private attachedFilesGrid!: QueryList<LinhaGridDocumentosAnexadosComponent>;
  @ViewChild(ModalExibirAnexosComponent) private modalExibirAnexos!: ModalExibirAnexosComponent;

  private readonly requiredDocumentsForModalidadePorta = ["CTE"];
  private readonly requiredDocumentsForModalidadePorto = ["CTE", "Nota Fiscal"];
  private readonly requiredDocumentsWithoutModalidade = ["CTE"];

  constructor(
    public bsModalRef: BsModalRef,
    private changeDetector: ChangeDetectorRef,
    private toastService:ToastService,
    private documentosService: DocumentosService
  ) { }

  public ngOnInit(): void{
  }

  public ngAfterContentInit(): void {
    this.onDocumentosAnexadosLoad.emit();
  }

  public setAttachedDocumentFiles(listaDocumentosArquivos: ArquivoDocumentoAnexado[] | undefined, listaDocumentosBase: ArquivoDocumentoAnexado[]): void{

    if(!listaDocumentosBase?.length){
      return;
    }
    this.storedFileConfigs = listaDocumentosBase.map(
      (documento) => documento.tipoDocumento
    ) ?? [];
    if(!listaDocumentosArquivos?.length){
      this.documentsList = [...listaDocumentosBase];
      this.changeDetector.detectChanges();
      this.sendAttachedfiles();
      return;
    }
    listaDocumentosArquivos.forEach(
      (arquivoAnexo) => {
        if(arquivoAnexo.tipoDocumento && arquivoAnexo.tipoDocumento.idDocumento){
          let index: number = listaDocumentosBase.findIndex(documento => documento.tipoDocumento.idDocumento == arquivoAnexo.tipoDocumento.idDocumento);
          if(index >= 0){
            listaDocumentosBase[index].nomeArquivo = arquivoAnexo.nomeArquivo;
            listaDocumentosBase[index].dataHoraInclusao = arquivoAnexo.dataHoraInclusao;
            listaDocumentosBase[index].filePath = arquivoAnexo.filePath;
            listaDocumentosBase[index].contentType = arquivoAnexo.contentType;
            listaDocumentosBase[index].idSolicitacao = arquivoAnexo.idSolicitacao;
            listaDocumentosBase[index].id = arquivoAnexo.id ?? 0;
          }
        }
      }
      );

    this.documentsList = [...listaDocumentosBase];
    this.changeDetector.detectChanges();
    this.sendAttachedfiles();
  }

  public sendAttachedfiles(): void{
    this.onSendAttachedFiles.emit({'documentosAnexados': this.attachedFilesGrid?.map(
      (attachedFile) => attachedFile.getAttachedDocument()
    )});
  }

  public get areAllFilesValid(): boolean{
    if(!this.attachedFilesGrid?.length){
      return true;
    }
    return !!this.attachedFilesGrid?.map(
      (attachedFile) => attachedFile.isAttachedFileValid
    ).every(fileValid => fileValid);
  }

  public getAllDocuments(): ArquivoDocumentoAnexado[]{
    return this.attachedFilesGrid?.map(
      (attachedFile) => attachedFile.getAttachedDocument()
    ) ?? [];
  }

  public getAllDocumentsWithAttachedFiles(): ArquivoDocumentoAnexado[]{
    return this.attachedFilesGrid?.filter(
      (document) => document.isFileUploaded()
    )?.map(
      (file) => file.getAttachedDocument()
    ) ?? [];
  }

  public isAnyFileUploaded(): boolean{
    return !!this.attachedFilesGrid?.filter(
      (attachedFile) => attachedFile.isFileUploaded()
    ).length;
  }

  public displayUploadedFile(file: ArquivoDocumentoAnexado, fileIndex: number): void{
    if(!file){
      return;
    }
    if(file.arquivoDocumento && file.arquivoDocumento.size){
      this.modalExibirAnexos.displayFile(file);
      return;
    }
    this.onDownloadFileToDisplay.emit({arquivoDocumentoAnexado: file, index: fileIndex});
  }

  public downloadFileToDisplay(fileToDisplay: Observable<SuccessResponse>, fileIndex: number): void{
    fileToDisplay
      .subscribe({
        next: (result) => {
          let documentoAnexado: LinhaGridDocumentosAnexadosComponent = this.attachedFilesGrid.get(fileIndex)!;
          let download = this.convertBase64ToFile(`data:${documentoAnexado?.getAttachedDocument().contentType};base64,${result.data as string}`, documentoAnexado?.getAttachedDocument().nomeArquivo!);
          documentoAnexado?.setFileDataFromDownloadedDocument(download);
          this.modalExibirAnexos.displayFile(documentoAnexado?.getAttachedDocument()!);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
          else if(typeof(err?.error) === 'string'){
            if(err?.error?.length){
              this.toastService.error(err?.error);
            }
          }
        }
      });
  }

  public clearFields(): void{
    this.documentsList = [];
  }

 public setAttachedDocumentTypes(documentTypeId: number, attachedFiles: ArquivoDocumentoAnexado[], getDocumentTypesMethod: Observable<TipoDocumentoAnexado[]>): void{
  getDocumentTypesMethod
      .subscribe({
        next: (tiposArquivo) => {
          let listaDocumentos: ArquivoDocumentoAnexado[] = tiposArquivo
            .filter(
              arquivo => arquivo.idTipoDocumento == documentTypeId
            )
            .map(
              arquivo => {
                return {
                  tipoDocumento: arquivo
                }
              }
            );
            this.setAttachedDocumentFiles(attachedFiles, listaDocumentos);
        },
        error: (err: HttpErrorResponse) => {
          this.documentsList = [];
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                this.toastService.error(error);
              }
            );
          }
        }
      });
  }

  private convertBase64ToFile(base64String: any, fileName: any) {
    let arr = base64String.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: mime });
    return file;

  }

  public downloadAttachedFile(arquivo: ArquivoDocumentoAnexado, fileIndex: number): void{
    console.log(arquivo)
    if(arquivo.arquivoDocumento && arquivo.arquivoDocumento.size){
      this.documentosService.downloadFileFromAttachedFiles(arquivo);
      return;
    }
    this.onDownloadFileToSave.emit({arquivoDocumentoAnexado: arquivo, index: fileIndex});
  }

  public downloadFileToSave(fileToSave: Observable<SuccessResponse>, fileIndex: number): void{
    fileToSave
    .subscribe({
      next: (result) => {
        let documentoAnexado: LinhaGridDocumentosAnexadosComponent = this.attachedFilesGrid.get(fileIndex)!;
        let download = this.convertBase64ToFile(`data:${documentoAnexado?.getAttachedDocument().contentType};base64,${result.data as string}`, documentoAnexado?.getAttachedDocument().nomeArquivo!);
        documentoAnexado?.setFileDataFromDownloadedDocument(download);
        console.log(documentoAnexado?.getAttachedDocument())
        this.documentosService.downloadFileFromAttachedFiles(documentoAnexado?.getAttachedDocument()!);
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        (err.error as ErrorResponse)?.errors?.forEach(
          (error) => {
            if(error && error.length){
              this.toastService.error(error);
            }
          }
        );
      }
    });
  }

  public updateDocumentsFromSavedFiles(savedFiles: ArquivoDocumentoAnexado[]): void{
    savedFiles.forEach(
      (arquivo) => {
        let index = this.documentsList?.findIndex(document => document.tipoDocumento?.idDocumento == arquivo.tipoDocumento?.idDocumento);
        if(index >= 0){
          this.documentsList[index].contentType = arquivo.contentType;
          this.documentsList[index].dataHoraInclusao = arquivo.dataHoraInclusao;
          this.documentsList[index].nomeArquivo = arquivo.nomeArquivo;
          this.documentsList[index].filePath = arquivo.filePath;
        }
      }
    );
  }

  public switchRequiredDocumentsByCabotageModalidade(modalidade?: Modalidade): void{
    if(modalidade?.nome?.toLowerCase() == 'porto'){
      this.setCabotageDocumentsAsrequiredByModalidadeValue(this.requiredDocumentsForModalidadePorto);
    }
    else if(modalidade?.nome?.toLowerCase() == 'porta'){
      this.setCabotageDocumentsAsrequiredByModalidadeValue(this.requiredDocumentsForModalidadePorta);
    }
    else{
      this.setCabotageDocumentsAsrequiredByModalidadeValue(this.requiredDocumentsWithoutModalidade);
    }
  }

  private setCabotageDocumentsAsrequiredByModalidadeValue(requiredDocuments: string[]): void{
    requiredDocuments = requiredDocuments.map(document => document.toUpperCase());
    this.attachedFilesGrid?.forEach(
      (documentoAnexado) => {
        let required = requiredDocuments.includes(
          documentoAnexado.getAttachedDocument()?.tipoDocumento?.nomeDocumento?.toUpperCase()
        )
        documentoAnexado.switchRequiredDocumentConfig(
          required
        );
      }
    );
    this.changeDetector.detectChanges();
  }

  public resetDefaultConfigsForFiles(): void{
    let currentDocumentsList = this.getAllDocuments() ?? [];
    currentDocumentsList.forEach(
      (documentoAnexo) => {
        let savedConfig = this.storedFileConfigs.find(config => config.idDocumento == documentoAnexo.tipoDocumento.idDocumento);
        if(savedConfig){
          documentoAnexo.tipoDocumento.obrigatorio = savedConfig.obrigatorio;
        }
      }
    );
    this.documentsList = [...currentDocumentsList];
    this.changeDetector.detectChanges();
  }

  public setValidatorForTermoAndCEAttachmentsInCadastroDocumento(divergentClientCpfCnpj: boolean, internalUser: boolean): void{
    let termoAnexo: LinhaGridDocumentosAnexadosComponent | undefined = this.attachedFilesGrid?.find(
      (anexo) => anexo.getAttachedDocument()?.tipoDocumento?.nomeDocumento?.toUpperCase()?.includes('TERMO')
    );
    if(termoAnexo){
      let tipoDocumento = termoAnexo.getAttachedDocument()?.tipoDocumento;
      tipoDocumento.obrigatorio = divergentClientCpfCnpj;
      termoAnexo.tipoDocumento.setValue(tipoDocumento);
    }
    let ceAnexo: LinhaGridDocumentosAnexadosComponent | undefined = this.attachedFilesGrid?.find(
      (anexo) => anexo.getAttachedDocument()?.tipoDocumento?.nomeDocumento?.toUpperCase()?.includes('CE')
    );
    if(ceAnexo){
      let tipoDocumento = ceAnexo.getAttachedDocument()?.tipoDocumento;
      tipoDocumento.obrigatorio = internalUser;
      ceAnexo.tipoDocumento.setValue(tipoDocumento);
      ceAnexo.setAttachmentAsBlockedOrNot(!internalUser);
    }
  }

  protected deleteFile(arquivo: ArquivoDocumentoAnexado): void{
    this.changeDetector.detectChanges();
  }

}
