import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  constructor() { }

  public getTimezone(datetimeString: string): string {
    const date = new Date(datetimeString);
    const offsetInMinutes = date.getTimezoneOffset();
    const offsetHours = Math.abs(Math.floor(offsetInMinutes / 60));
    const offsetMinutes = Math.abs(offsetInMinutes % 60);
    const offsetSign = offsetInMinutes > 0 ? '-' : '+';
    const timezone = `${offsetSign}${this.padZeroes(offsetHours)}:${this.padZeroes(offsetMinutes)}`;

    return timezone;
  }

  private padZeroes(value: number): string {
    return value.toString().padStart(2, '0');
  }
}
