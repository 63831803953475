import { RelacaoConteineresRCTComponent } from './../../../components/recepcao-due-contigencia/recepcao-conteiner-transship/relacao-conteineres-rct/relacao-conteineres-rct.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';

@Component({
  selector: 'app-recepcao-conteiner-transship',
  templateUrl: './recepcao-conteiner-transship.component.html',
  styleUrls: ['./recepcao-conteiner-transship.component.scss']
})
export class RecepcaoConteinerTransshipComponent implements OnInit {

  public title: string = 'Contêineres Transship';
  @ViewChild(RelacaoConteineresRCTComponent) relacaoConteineres!: RelacaoConteineresRCTComponent;
  public readonly visualizacao = new AuthRoles(Telas.recepcaoConteinerTransship, Permissoes.visualizacao)
  public readonly enviarCCT = new AuthRoles(Telas.recepcaoConteinerTransship, Permissoes.enviarCCT)
  public readonly salvar = new AuthRoles(Telas.recepcaoConteinerTransship, Permissoes.salvar)

  constructor() { }

  public ngOnInit(): void {
  }

  public clear(): void{
    this.relacaoConteineres.clear();
  }
}
