<div class="overflow-auto my-3 table-container">
  <table class="grid-table" *ngIf="listaBloqueioDesbloqueio?.length">
    <thead class="text-nowrap">
      <tr>
        <th>
          <input class="form-chek-input" type="checkbox" [checked]="areAllSelected"
            (change)="switchSelectedForAll()"
          >
        </th>
        <th>Nº da DUE</th>
        <th>Contêiner</th>
        <th>Status do Bloqueio</th>
        <th>Nº da Solicitação</th>
        <th>Status da Solicitação</th>
        <th>Data da Entrada</th>
        <th>Status da Entrada</th>
        <th>Navio</th>
        <th>Viagem</th>
      </tr>
    </thead>
    <tbody>
      <!-- <tr
        *ngFor="let boqueioDesbloqueio of BloqueioDesbloqueioConteinerEmbarqueGrid; let indexboqueioDesbloqueio = index"
        app-linha-grid-bloqueio-desbloqueio-conteiner-embarque [bloqueioDesbloqueio]="boqueioDesbloqueio"
        (onSendContainer)="updateContainerRelation()"
        class="readonly-grid-line"> -->
      <tr
        *ngFor="let bloqueioDesbloqueio of listaBloqueioDesbloqueio; let indexBloqueioDesbloqueio = index"
        class="readonly-grid-line"
      >
        <td>
          <input class="form-chek-input" type="checkbox" [checked]="selectedContainerChecks[indexBloqueioDesbloqueio]"
            (change)="switchSingleSelected(indexBloqueioDesbloqueio)"
          >
        </td>
        <td>
          {{bloqueioDesbloqueio.numeroDUE}}
        </td>
        <td>
          {{bloqueioDesbloqueio.conteiner}}
        </td>
        <td>
          {{bloqueioDesbloqueio.statusBloqueio}}
        </td>
        <td>
          {{bloqueioDesbloqueio.numeroSolicitacaoLiberacao}}
        </td>
        <td>
          {{bloqueioDesbloqueio.statusSolicitacao}}
        </td>
        <td>
          {{bloqueioDesbloqueio.dataEntrada | date : "dd/MM/yyyy"}}
        </td>
        <td>
          {{bloqueioDesbloqueio.statusEntrada}}
        </td>
        <td>
          {{bloqueioDesbloqueio.navio}}
        </td>
        <td>
          {{bloqueioDesbloqueio.viagem}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
