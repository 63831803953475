import { PrintService } from '../../../../services/relatorios/print.service';
import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RelatorioConteineresLiberados } from 'src/app/models/relatorios/RelatorioConteineresLiberados/RelatorioConteineresLiberados';
import { ExcelService } from 'src/app/services/excel.service';
import { RelatorioConteineresLiberadosService } from 'src/app/services/relatorios/relatorio-conteineres-liberados.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { TimezoneService } from 'src/app/shared/utils/timezone.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImpressaoConteineresLiberadosComponent } from '../impressao-conteineres-liberados/impressao-conteineres-liberados.component';

@Component({
  selector: 'app-conteineres-liberados',
  templateUrl: './conteineres-liberados.component.html',
  styleUrls: ['./conteineres-liberados.component.scss']
})
export class ConteineresLiberadosComponent implements OnInit {
  public form!: UntypedFormGroup;
  public relatorioConteineresLiberadosGrid!: RelatorioConteineresLiberados[];
  private locale = 'pt-br';
  public bsConfig?: Partial<BsDatepickerConfig>;
  private colorTheme = 'theme-dark-blue';
  public readonly currentDate: Date = new Date();
  protected isPrinting: boolean = false;
  @ViewChild(ImpressaoConteineresLiberadosComponent) protected impressao!: ImpressaoConteineresLiberadosComponent;

  // @Input() camposformulario!: Campo[];

  constructor(
    private fb: UntypedFormBuilder,
    private relatorioConteineresLiberadosService:RelatorioConteineresLiberadosService,
    private excelService:ExcelService,
    private toastService:ToastService,
    private localeService: BsLocaleService,
    private datePipe: DatePipe,
    private timezoneService: TimezoneService,
    private spinner: NgxSpinnerService,
    private printService: PrintService,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme});
    this.validation();
  }

  private validation(): void{
    this.form = this.fb.group(
      {
        navio: [''],
        viagem: [''],
        periodoDe: [null],
        periodoAte: [null],
        periodo:[''],
        // dataLiberacao: [''],
        // dataCadastro: [''],
        // dataRegistro: [''],
        // dataDesembaraco: [''],
        regime:[''],
        // importacao: [''],
        // exportacao: [''],
        // cabotagem: [''],
        relatorioConteineresLiberados: [null],
      },
      {
        validators: [this.checkIfPeriodIsRequired.bind(this), this.validateIfFormIsFilled.bind(this)]
      }
    );
    this.form.get('periodoDe')?.valueChanges.subscribe(
      () => {
        this.erasePeriodIfNoDateIsInformed();
      }
    );
    this.form.get('periodoAte')?.valueChanges.subscribe(
      () => {
        this.erasePeriodIfNoDateIsInformed();
      }
    );
    this.erasePeriodIfNoDateIsInformed();
  }

  public searchContainerRelations(): void{
    this.clearConteinerRelations();
    this.spinner.show();
    this.relatorioConteineresLiberadosService.searchConteineresLiberados(this.form.getRawValue())
    .subscribe({
      next:(response) => {
        this.spinner.hide();
        let relatorio = response.data as RelatorioConteineresLiberados[];
        if(relatorio?.length){
          this.setConteinerRelations(relatorio);
          console.log(relatorio)
        }
        response?.message?.forEach(
          (msg) => {
            if(msg?.length){
              this.toastService.error(msg);
            }
          }
        );
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      }
    })
  }

  public downloadPDF(title:string){

    this.isPrinting = true;
    this.changeDetector.detectChanges();
    this.impressao.setPrintingData({
      dataInicio: this.form.value.periodoDe,
      dataFim: this.form.value.periodoAte,
      nomeRelatorio: title,
      dados: this.relatorioConteineresLiberadosGrid,
    });
    this.printService.setPrinting(this.impressao.getTemplate());
    this.changeDetector.detectChanges();
    window.onafterprint = () => {
      this.isPrinting = false;
      this.printService.unsetPrinting();
    }
    setTimeout(() => {
      window.print();
    }, 500);
  }

  private setConteinerRelations(relatorioConteineresLiberadosGrid: RelatorioConteineresLiberados[]): void{
    this.relatorioConteineresLiberadosGrid = [...relatorioConteineresLiberadosGrid];
    this.form.get('relatorioConteineresLiberados')?.setValue([...relatorioConteineresLiberadosGrid]);
  }

  public clearFields(): void{
    this.validation();
    this.relatorioConteineresLiberadosGrid = [];
  }

  private clearConteinerRelations(): void{
    this.relatorioConteineresLiberadosGrid = [];
    this.form.get('relatorioConteineresLiberados')?.setValue([]);
  }

  public downloadExcel(title: string) {
    let header = [
      { nome: 'Dias armazenados', key: 'diasArmazenados', width: 25 },
      { nome: 'Contêiner', key: 'conteiner', width: 25 },
      { nome: 'Navio', key: 'navio', width: 25 },
      { nome: 'Viagem', key: 'viagem', width: 30 },
      { nome: 'Data de Entrada', key: 'dataEntrada', width: 30 },
      { nome: 'Data de Saída', key: 'dataSaida', width: 25 },
      { nome: 'Canal', key: 'canal', width: 25 },
      { nome: 'Nº do CE', key: 'numCE', width: 25 },
      { nome: 'Mercadoria', key: 'mercadoria', width: 25 },
      { nome: 'BL Master', key: 'blMaster', width: 25 },
      { nome: 'BL House', key: 'blHouse', width: 25 },
      { nome: 'Consignatário', key: 'consignatario', width: 25 },
      { nome: 'Data de Cadastro', key: 'dataCadastro', width: 25 },
      { nome: 'Data de Registro', key: 'dataRegistro', width: 25 },
      { nome: 'Data de Liberação', key: 'dataLiberacao', width: 25 },
      { nome: 'Tipo de Documento', key: 'tipoDoc', width: 25 },
      { nome: 'Documento', key: 'numDoc', width: 25 },
      { nome: 'Matrícula de cadastro', key: 'matriculaUsuario', width: 25 },
      { nome: 'Justificativa', key: 'justificativa', width: 25 }

    ];
    let data = this.relatorioConteineresLiberadosGrid?.map(
      (conteiner) => {
        return {
          ...conteiner,
          dataEntrada: this.datePipe.transform(conteiner.dataEntrada, 'dd/MM/YYYY HH:mm:ss', this.getTimezone(conteiner.dataEntrada?.toString())),
          dataSaida: this.datePipe.transform(conteiner.dataSaida, 'dd/MM/YYYY HH:mm:ss', this.getTimezone(conteiner.dataSaida?.toString())),
          dataCadastro: this.datePipe.transform(conteiner.dataCadastro, 'dd/MM/YYYY HH:mm:ss', this.getTimezone(conteiner.dataCadastro?.toString())),
          dataRegistro: this.datePipe.transform(conteiner.dataRegistro, 'dd/MM/YYYY HH:mm:ss', this.getTimezone(conteiner.dataRegistro?.toString())),
          dataLiberacao: this.datePipe.transform(conteiner.dataLiberacao, 'dd/MM/YYYY HH:mm:ss', this.getTimezone(conteiner.dataLiberacao?.toString()))
        }
      }
    ) ?? [];
   this.excelService.exportAsExcelFileCustom(data,'Contêineres-Liberados',header,title)
  }

  public isContainerListNotEmpty(): boolean{
    return !!this.relatorioConteineresLiberadosGrid?.length;
  }

  private checkIfPeriodIsRequired(group: UntypedFormGroup): {invalidForm: string[]} | null{
    if(!group){
      return null;
    }
    let periodoDe = group.get('periodoDe')?.value ?? null;
    let periodoAte = group.get('periodoAte')?.value ?? null;
    if(!periodoDe && !periodoAte){
      return null;
    }
    let error: string[] = [];
    if(!(periodoDe && periodoAte)){
      error.push('Ambas as datas de período são necessárias!');
    }
    let periodo = group.get('periodo')?.value ?? null;
    if(!periodo){
      error.push('Período a ser pesquisado é obrigatório!');
    }
    if(error?.length){
      return {invalidForm: error};
    }
    return null;
  }

  private validateIfFormIsFilled(group: UntypedFormGroup): {unfilledForm: string} | null{
    let periodoDe = group.get('periodoDe')?.value ?? null;
    let periodoAte = group.get('periodoAte')?.value ?? null;
    if(periodoDe && periodoAte){
      return null;
    }
    let navio = group.get('navio')?.value ?? null;
    let viagem = group.get('viagem')?.value ?? null;
    if(navio || viagem){
      return null;
    }
    return {unfilledForm: 'Campos obrigatórios'};
  }

  private erasePeriodIfNoDateIsInformed(): void{
    let periodoDe = this.form.get('periodoDe')?.value ?? null;
    let periodoAte = this.form.get('periodoAte')?.value ?? null;
    if(periodoDe && periodoAte){
      this.form.get('periodo')?.enable();
      return;
    }
    this.form.get('periodo')?.disable();
    this.form.get('periodo')?.reset();
  }

  public unselectRadioButton(controlName: string, buttonValue: string): void{
    if(!this.form){
      return;
    }
    if(this.form.get(controlName)?.value == buttonValue){
      this.form.get(controlName)?.reset();
    }
  }

  public getTimezone(datetimeString: string | undefined): string {
    return datetimeString?.length ? this.timezoneService.getTimezone(datetimeString) : "";
  }
}
