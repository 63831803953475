import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConteinerEnvioCCT } from './../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/ConteinerEnvioCCT';
import { SuccessResponse } from '../models/HttpResponses/SuccessResponse';
import { NotaFiscalNFE } from './../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/NotaFiscalNFE';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConteinerRecepcaoDocumentoTransporteDTO } from '../models/RecepcaoDocumentoTransporte/ConteinerRecepcaoDocumentoTransporteDTO';
import { DocumentoTransporte } from '../models/RecepcaoDocumentoTransporte/DocumentoTransporte';
import { RelacaoConteinerRCT } from '../models/RecepcaoDueContingencia/RecepcaoConteinerTransship/RelacaoConteinerRCT';

@Injectable({
  providedIn: 'root'
})
export class RecepcaoDueContingenciaService {

  constructor(
    private http: HttpClient
  ) { }

  public getContainerForRecepcaoNotaFiscal(notaFiscal: NotaFiscalNFE): Observable<any>{
    return this.http.get<any>(`${environment.apiUrlForRecepcaoDue}/api/NotaFiscal/conteiner/${notaFiscal.numeroConteiner}/${notaFiscal.chaveNFE}`);
  }

  public validaoNotaFiscal(notaFiscal: string): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForRecepcaoDue}/api/NotaFiscal/validarNotaFiscal/${notaFiscal}`, null);
  }

  public sendDataToCCTForRecepcaoNotaFiscal(conteinerEnvioCCT: ConteinerEnvioCCT): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForRecepcaoDue}/api/NotaFiscal/enviarCCT`, conteinerEnvioCCT);
  }
  public saveDataToCCTForRecepcaoNotaFiscal(conteinerEnvioCCT: ConteinerEnvioCCT): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForRecepcaoDue}/api/NotaFiscal/salvar`, conteinerEnvioCCT);
  }

  public getConteinersForRecepcaoTransship(navio: string,viagem:string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrlForRecepcaoDue}/api/Transship/conteiner/${navio}/${viagem}`);
  }
  public sendDataToCCTForRecepcaoTransship(conteinerEnvioCCT: RelacaoConteinerRCT[]): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForRecepcaoDue}/api/Transship/enviarCCT`, conteinerEnvioCCT);
  }

  public saveDataToCCTForRecepcaoTransship(conteinerEnvioCCT: RelacaoConteinerRCT[]): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForRecepcaoDue}/api/Transship/salvar`, conteinerEnvioCCT);
  }

  public getDocumentTypes(): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForRecepcaoDue}/api/DocumentoTransporte/tipoDocumento`);
  }

  public searchForContainerOnForRecepcaoDocumentoTransporte(documentoTransporte: DocumentoTransporte): Observable<SuccessResponse>{
    return this.http.get<any>(`${environment.apiUrlForRecepcaoDue}/api/DocumentoTransporte/PesquisaPorTipoNumero/${documentoTransporte.tipoDocumento}/${documentoTransporte.numeroDocumento}`);
  }
  public sendDataToCCTForRecepcaoDocumentoTransporte(conteinerEnvioCCT: ConteinerRecepcaoDocumentoTransporteDTO): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForRecepcaoDue}/api/DocumentoTransporte/enviarCCT`, conteinerEnvioCCT);
  }

  public saveDataToCCTForRecepcaoDocumentoTransporte(conteinerEnvioCCT: ConteinerRecepcaoDocumentoTransporteDTO): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForRecepcaoDue}/api/DocumentoTransporte/salvar`, conteinerEnvioCCT);
  }

  // public searchForContainerOnCCTForRecepcaoDocumentoTransporte(documentoTransporte: DocumentoTransporte): Observable<SuccessResponse>{
  //   return of({
  //     success: true,
  //     message: [],
  //     data: {
  //       numeroConteiner: 'MVKU1234',
  //       datachegada: new Date(),
  //       numeroBooking: '12345678',
  //       lacresN4: [
  //         {
  //           id: 1,
  //           numero: 1111
  //         },
  //         {
  //           id: 2,
  //           numero: 2221
  //         }
  //       ],
  //       lacresCCT: [
  //         {
  //           id: 1,
  //           numero: 1112
  //         },
  //         {
  //           id: 2,
  //           numero: 2222
  //         }
  //       ],
  //       peso: 130,
  //       chaveNFE: '12345678901234567890123456789012345678901234',
  //       recepcaoRealizadaComSucesso: true,
  //       dataEnvio: new Date(),
  //       statusRecepcao: 'Realizada com sucesso!',
  //       usuario: 'Anderson',
  //       pesagem: true,
  //       cpfCnpjTransportador: '11.222.333/0001-81',
  //       nomeTransportador: 'Bruno Mendes',
  //       cpfCondutor: '422.688.370-50',
  //       nomeCondutor: 'Marco Santos',
  //       placaReboque1: 'BNM1234',
  //       placaReboque2: 'CVS4321',
  //       placaReboque3: undefined,
  //       placaCavalo: 'JUI7689',
  //       tara: 22
  //     } as ConteinerRecepcaoDocumentoTransporteDTO
  //   });
  //}
}
