import { Component, OnInit, Query, QueryList, TemplateRef, ViewChildren, ChangeDetectorRef, ViewChild } from '@angular/core';
import { RecepcaoNFDATConteinerGrid } from 'src/app/models/relatorios/RecepcaoNFDATConteiner/RecepcaoNFDATConteinerGrid';

@Component({
  selector: 'app-impressao-nf-dat-conteiner',
  templateUrl: './impressao-nf-dat-conteiner.component.html',
  styleUrls: ['./impressao-nf-dat-conteiner.component.scss']
})
export class ImpressaoNfDatConteinerComponent implements OnInit {

  protected dataInicio?: Date;
  protected dataFim?: Date;
  protected nomeRelatorio: string = '';
  protected dados: RecepcaoNFDATConteinerGrid[] = [];
  protected logo: string = '../../assets/images/logoSeptibaTecon.png';
  protected currentDate: Date = new Date();
  @ViewChildren('tableRelatorio') protected relatorios!: QueryList<TemplateRef<any>>;
  @ViewChild('templateImpressao') private templateImpressao!: TemplateRef<any>;

  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  public setPrintingData(data: {dataInicio: Date, dataFim: Date, nomeRelatorio: string, dados: RecepcaoNFDATConteinerGrid[]}): void{
    this.dataInicio = data.dataInicio;
    this.dataFim = data.dataFim;
    this.nomeRelatorio = data.nomeRelatorio;
    this.dados = data.dados;
    this.changeDetector.detectChanges();
  }

  public getTemplate(): TemplateRef<any>{
    return this.templateImpressao;
  }

}
