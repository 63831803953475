import { TipoDocumentoAnexado } from './../../../../models/DocumentosAnexados/TipoDocumentoAnexado';
import { ArquivoDocumentoAnexado } from './../../../../models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { ToastService } from 'src/app/services/toast.service';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Component, Input, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { debounceTime } from 'rxjs';
import { SolicitacaoFaturamentoEstimativaCalculoService } from 'src/app/services/EstimativaCalculo/solicitacao-faturamento-estimativa-calculo.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'tr[app-linha-grid-documentos-anexados]',
  templateUrl: './linha-grid-documentos-anexados.component.html',
  styleUrls: ['./linha-grid-documentos-anexados.component.scss']
})
export class LinhaGridDocumentosAnexadosComponent implements AfterViewInit {

  @Input() public receivedDocument!: ArquivoDocumentoAnexado;
  @Input() public isInsideModal!: boolean;
  @Input() public visualizar?: boolean;
  @Input() public finishedSolicitation?: boolean;
  @Input() public isSolicitationFinished?: boolean;
  @Output() public onUpdateAttachedDocument: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onDisplayFile: EventEmitter<ArquivoDocumentoAnexado> = new EventEmitter<ArquivoDocumentoAnexado>();
  @Output() public onDownloadFile: EventEmitter<ArquivoDocumentoAnexado> = new EventEmitter<ArquivoDocumentoAnexado>();
  @Output() public onDeleteFile: EventEmitter<ArquivoDocumentoAnexado> = new EventEmitter<ArquivoDocumentoAnexado>();
  public id!: UntypedFormControl;
  public tipoDocumento!: UntypedFormControl;
  public arquivoDocumento!: UntypedFormControl;
  public nomeArquivo!: UntypedFormControl;
  public dataHoraInclusao!: UntypedFormControl;
  public filePath!: UntypedFormControl;
  public contentType!: UntypedFormControl;
  public idSolicitacao!: UntypedFormControl;
  public arquivoAnexado!: UntypedFormGroup;
  public isAttachmentBlocked: boolean = false;
  public readonly allowedFileExtensions: string[] = ['bmp', 'jpg', 'jpeg', 'pdf', 'tif', 'tiff'];

  constructor(
    private solicitacaoFaturamentoEstimativaCalculoService: SolicitacaoFaturamentoEstimativaCalculoService,
    private spinner: NgxSpinnerService,
    private changeDetector: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private toastService:ToastService
  ) { }

  public ngAfterViewInit(): void {
    this.validation();
    this.changeDetector.detectChanges();
  }

  private validation(): void{
    console.log('arq mandado pro anexo:')
    console.log(this.receivedDocument)
    this.id = new UntypedFormControl(this.receivedDocument?.id ?? 0, Validators.compose([Validators.required]));
    this.tipoDocumento = new UntypedFormControl(this.receivedDocument?.tipoDocumento, Validators.compose([Validators.required]));
    this.arquivoDocumento = new UntypedFormControl(this.receivedDocument?.arquivoDocumento, Validators.compose([]));
    this.nomeArquivo = new UntypedFormControl(this.receivedDocument?.nomeArquivo, Validators.compose([]));
    this.dataHoraInclusao = new UntypedFormControl(this.receivedDocument?.dataHoraInclusao, Validators.compose([]));
    this.filePath = new UntypedFormControl(this.receivedDocument?.filePath, Validators.compose([]));
    this.contentType = new UntypedFormControl(this.receivedDocument?.contentType, Validators.compose([]));
    this.idSolicitacao = new UntypedFormControl(this.receivedDocument?.idSolicitacao, Validators.compose([]));
    if(this.receivedDocument?.tipoDocumento.obrigatorio){
      this.arquivoDocumento?.addValidators(Validators.required);
      this.nomeArquivo?.addValidators(Validators.required);
      this.dataHoraInclusao?.addValidators(Validators.required);
    }
    else{
      this.arquivoDocumento?.removeValidators(Validators.required);
      this.nomeArquivo?.removeValidators(Validators.required);
      this.dataHoraInclusao?.removeValidators(Validators.required);
    }
    this.arquivoAnexado = this.fb.group({
      id: this.id,
      tipoDocumento: this.tipoDocumento,
      arquivoDocumento: this.arquivoDocumento,
      nomeArquivo: this.nomeArquivo,
      dataHoraInclusao: this.dataHoraInclusao,
      filePath: this.filePath,
      contentType: this.contentType,
      idSolicitacao: this.idSolicitacao
    });
    this.arquivoAnexado.valueChanges
      .pipe(
        debounceTime(100)
      )
      .subscribe(
        () => {
          this.sendAttachedDocument();
        }
      );
    this.sendAttachedDocument();
  }

  public getAttachedDocument(): ArquivoDocumentoAnexado{
    return this.arquivoAnexado.getRawValue() as ArquivoDocumentoAnexado;
  }

  private sendAttachedDocument(): void{
    this.changeDetector.detectChanges();
    this.onUpdateAttachedDocument.emit();
  }

  public updateFile(file: any): void{
    if(this.isInsideModal){
      file.value = null;
      return;
    }
    const [uploaded] = file?.files as File[];
    if(uploaded?.size && this.checkIfExtensionIsValid(uploaded?.name)){
      this.arquivoAnexado?.patchValue({
        arquivoDocumento: uploaded,
        nomeArquivo: uploaded.name,
        dataHoraInclusao: new Date(),
        contentType: uploaded.type,
        filePath: '',
        id: 0
      });
    }
    else{
      this.toastService.error('Arquivo inválido!');
    }
    file.value = null;
  }

  private checkIfExtensionIsValid(fileName: string): boolean{
    let extension: string = fileName.split('.').pop() ?? '';
    return this.allowedFileExtensions.includes(extension.toLowerCase());
  }

  public deleteFile(): void{
    if(this.isInsideModal){
      return;
    }
    this.onDeleteFile.emit(this.getAttachedDocument());
    this.arquivoAnexado?.patchValue({
      arquivoDocumento: '',
      nomeArquivo: '',
      dataHoraInclusao: '',
      filePath: '',
      contentType: '',
      idSolicitacao: '',
      id: 0
    });
    }

  public getAllowedFileExtensions(): string{
    return this.allowedFileExtensions
      .map(extension => `.${extension}`)
      .join(',');
  }

  public get isAttachedFileValid(): boolean{
    if((this.tipoDocumento?.value as TipoDocumentoAnexado)?.obrigatorio){
      return (!!this.arquivoDocumento?.value || !!this.filePath.value) && !!this.nomeArquivo?.value && !!this.dataHoraInclusao?.value;
    }
    return !!this.tipoDocumento?.value;
  }

  public displayFile(): void{
    this.onDisplayFile.emit(this.getAttachedDocument());
  }

  public isFileUploaded(): boolean{
      return (!!this.arquivoDocumento?.value || !!this.filePath.value?.length) && !!this.nomeArquivo?.value && !!this.dataHoraInclusao?.value;
  }

  public downloadFile(): void{
    this.onDownloadFile.emit(this.getAttachedDocument());
  }

  public setFileDataFromDownloadedDocument(downloaded: File): void{
    if(downloaded && this.checkIfExtensionIsValid(downloaded.name)){
      this.arquivoDocumento?.setValue(downloaded);
    }
    else{
      this.toastService.error('Arquivo inválido!');
    }
  }

  public setDocumentDataFromReceivedDocument(arquivo: ArquivoDocumentoAnexado): void{
    this.arquivoAnexado?.patchValue({
      arquivoDocumento: arquivo.arquivoDocumento,
      nomeArquivo: arquivo.nomeArquivo,
      dataHoraInclusao: arquivo.dataHoraInclusao,
      filePath: arquivo.filePath,
      contentType: arquivo.contentType,
      idSolicitacao: arquivo.idSolicitacao,
      id: arquivo.id ?? 0
    }, {emitEvent: false});
  }

  public switchRequiredDocumentConfig(required: boolean): void{
    let tipoDocumento: TipoDocumentoAnexado = this.tipoDocumento.value;
    if(!tipoDocumento){
      return;
    }
    tipoDocumento.obrigatorio = required;
    this.arquivoAnexado.patchValue({
      tipoDocumento: tipoDocumento
    }, {emitEvent: false});
  }

  public setAttachmentAsBlockedOrNot(isBlocked: boolean): void{
    this.isAttachmentBlocked = isBlocked;
  }

  public get isAttachmentOptionSomehowBlocked(): boolean{
    return !!this.isInsideModal || !!this.isAttachmentBlocked || !!this.visualizar;
  }

  public get isDisplayButtonDisabled(): boolean{
    if(!this.arquivoDocumento.value && !this.filePath.value?.length){
      return true;
    }
    return false;
  }

  public get isDeleteButtonDisabled(): boolean{
    if(!this.arquivoDocumento.value && !this.filePath.value?.length){
      return true;
    }
    if(this.isInsideModal){
      return true;
    }
    if(this.visualizar){
      return true;
    }
    if(this.finishedSolicitation){
      return true;
    }
    if(this.isSolicitationFinished){
      return true;
    }
    return false;
  }

  public get isDownloadButtonDisabled(): boolean{
    if(!this.arquivoDocumento.value && !this.filePath.value?.length){
      return true;
    }
    return false;
  }
}
