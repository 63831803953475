import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from '../../../models/HttpResponses/ErrorResponse';
import { FiltroLiberacaoDocumental } from './../../../models/FiltroLiberacaoDocumental';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { RegimeCirculacao } from './../../../models/RegimeCirculacao';
import { StatusSolicitacaoDocumental } from './../../../models/StatusSolicitacaoDocumental';
import { TipoDocumentoSolicitacao } from './../../../models/TipoDocumentoSolicitacao';
import { DocumentCustomPattern, DocumentMaskEnum, DocumentSpecialCharactersForMask } from './../../../shared/utils/constantes-documentos';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DocumentosService } from 'src/app/services/documentos.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Telas } from 'src/app/contants/telas';

@Component({
  selector: 'app-filtro-liberacao-documental',
  templateUrl: './filtro-liberacao-documental.component.html',
  styleUrls: ['./filtro-liberacao-documental.component.scss']
})
export class FiltroLiberacaoDocumentalComponent implements OnInit {

  public form!: UntypedFormGroup;
  public bsConfig?: Partial<BsDatepickerConfig>;
  public colorTheme: string = 'theme-dark-blue';
  public maskForSolicitationDocument: string = '';
  public specialCharsForSolicitationDocument: string[] = [];
  public solicitationDocumentTypes!: TipoDocumentoSolicitacao[];
  public maskForNames: string = DocumentMaskEnum.NAME_MASK;
  public patternForNames = DocumentCustomPattern.NAME_PATTERN;
  public solicitationStatus!: StatusSolicitacaoDocumental[];
  public circulationRegimes!: RegimeCirculacao[];
  public ehUsuarioExterno!: boolean;
  private readonly locale: string = 'pt-br';
  @Output() onSendFilter: EventEmitter<FiltroLiberacaoDocumental> = new EventEmitter<FiltroLiberacaoDocumental>();
  private statusPendente = "PENDENTE"

  constructor(
    private fb: UntypedFormBuilder,
    private documentosService: DocumentosService,
    private changeDetector: ChangeDetectorRef,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private localeService: BsLocaleService,
    private toastService:ToastService,
    private route: Router,
    private authService: AuthenticationService
  ) { }

  public ngOnInit(): void {
    this.ehUsuarioExterno = !this.authService.ehUsuarioInterno;
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.localeService.use(this.locale);
    this.solicitationDocumentTypes = [];
    this.documentosService.getDocumentsForLiberacaoDocumental().subscribe({
      next: (event) => {
        this.solicitationDocumentTypes = event.data as TipoDocumentoSolicitacao[];
      },
      error: (err: ErrorResponse) => {
        console.log(err);
        err?.errors?.forEach(
          (error) => {
            this.toastService.error(error);
          }
        );
      }
    });
    this.circulationRegimes = this.documentosService.getCirculationRegimes();
    this.getStatus();
    this.validation();
  }

  private getStatus(): void{
    this.ehUsuarioExterno = !this.authService.ehUsuarioInterno;
    this.solicitationStatus = [];
    this.documentosService.getDocumentalSolicitationStatusForDocumentalLiberation()
      .subscribe({
        next: (result) => {
          this.solicitationStatus = result;
          this.setStatusToPendente();
          this.sendFilter();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = err?.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                this.toastService.error(error);
              }
            );
          }
        }
      });
  }

  private validation(): void{
    this.form = this.fb.group({
      tipoDocumentoSolicitacao: [''],
      numeroDocumentoSolicitacao: [''],
      navioSolicitacao: [''],
      viagemSolicitacao: [''],
      nomeClienteSolicitacao: ['', [Validators.minLength(3), Validators.maxLength(100)]],
      documentoClienteSolicitacao: ['', [this.validateClientDocument.bind(this)]],
      regimeCirculacaoSolicitacao: [''],
      statusSolicitacao: [''],
      dataSolicitacao: [''],
      dataLiberacao: [''],
      solicitante: [
        this.ehUsuarioExterno ? this.authService.name : '',
        [Validators.minLength(3), Validators.maxLength(100)]
      ]
    });
    if (this.route.url.includes("importacao") || this.route.url.includes("exportacao") || this.route.url.includes("cabotagem")) {
      this.form.get('regimeCirculacaoSolicitacao')?.disable();
    }
    else {
      this.form.get('regimeCirculacaoSolicitacao')?.enable();
    }

  }

  public updateMaskForSolicitationDocument(documentType: string): void{
    if(!documentType?.length){
      this.maskForSolicitationDocument = '';
    }
    else{
      this.maskForSolicitationDocument = this.solicitationDocumentTypes.find(
        (document) => document.nome == documentType
      )?.mascara || '';
    }
    this.specialCharsForSolicitationDocument = this.maskForSolicitationDocument?.length ? this.verificacaoDocumentosService.getSpecialCharsFromMask(this.maskForSolicitationDocument) : [];
    this.form.get('numeroDocumentoSolicitacao')?.setValue('');
    this.changeDetector.detectChanges();
  }

  public validateClientDocument(control: AbstractControl): {invalidClientDocument: string} | null{
    if(!control){
      return null;
    }
    let documentoCliente: string = control.value?.trim() ?? '';
    if(!documentoCliente?.length){
      return null;
    }
    documentoCliente = this.verificacaoDocumentosService.eraseMask(documentoCliente);
    let error: string | null = '';
    if(documentoCliente.length === 11){
      error = this.verificacaoDocumentosService.calculateValidCPF(documentoCliente);
    }
    else if(documentoCliente.length === 14){
      error = this.verificacaoDocumentosService.calculateValidCNPJ(documentoCliente);
    }
    else{
      error = 'Documento inválido. Favor verificar!';
    }
    if(error?.length){
      return {invalidClientDocument: error};
    }
    return null;
  }

  public sendFilter(): void{
    this.onSendFilter.emit(this.getFilterFromFormFields());
  }

  public getFilterFromFormFields(): FiltroLiberacaoDocumental{
    const formValue = this.form.getRawValue();
    let documentoClienteSolicitacao = this.verificacaoDocumentosService.eraseMask(formValue.documentoClienteSolicitacao);
    const filter: FiltroLiberacaoDocumental = {
      documento: {
        tipoDocumento: formValue.tipoDocumentoSolicitacao,
        numeroDocumento: this.verificacaoDocumentosService.eraseMask(formValue.numeroDocumentoSolicitacao)
      },
      navio: formValue.navioSolicitacao,
      viagem: formValue.viagemSolicitacao,
      cliente: {
        tipoCliente: documentoClienteSolicitacao.length == 11 ? 'fisica' : (documentoClienteSolicitacao.length == 14 ? 'juridica' : ''),
        nomeCliente: formValue.nomeClienteSolicitacao,
        cpfCnpj: formValue.documentoClienteSolicitacao,
        codigoCliente: formValue.codCliente
      },
      documentoCliente: formValue.documentoClienteSolicitacao,
      regimeCirculacao: formValue.regimeCirculacaoSolicitacao,
      status: this.solicitationStatus?.find(
        (status) => status.idStatus == formValue.statusSolicitacao
      ),
      dataSolicitacao: formValue.dataSolicitacao ? (formValue.dataSolicitacao as Date).toLocaleDateString('pt-BR') : '',
      dataLiberacao: formValue.dataLiberacao ? (formValue.dataLiberacao as Date).toLocaleDateString('pt-BR') : '',
      solicitante: this.ehUsuarioExterno ? this.authService.name : formValue.solicitante
    };
    return filter;
  }

  public setRegimeForInitialSearch(regimeCirculacao: string): void{
    this.form.get('regimeCirculacaoSolicitacao')?.setValue(
      this.circulationRegimes.find(regime => regime.nomeRegimeCirculacao.toLowerCase() == regimeCirculacao.toLowerCase())
    );
  }

  public setStatusToPendente(): void{
    this.form.get('statusSolicitacao')?.setValue(3);
  }

}
