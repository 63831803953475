<td *ngIf="conteiner">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="conteiner" readonly
    >
  </div>
</td>
<td *ngIf="numeroDocumentoParte">
  <div class="input-group">
    <input *ngIf="mascara?.modelo; else numeroDocumentoParteNoMask" type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="numeroDocumentoParte" [mask]="mascara?.modelo?.length ? mascara!.modelo : ''" [specialCharacters]="mascara?.caracteresEspeciais?.length ? mascara!.caracteresEspeciais! : []"
      (change)="onFirstEdit.emit()" [ngClass]="{'border border-danger': numeroDocumentoParte.touched && numeroDocumentoParte.errors}"
    >
    <ng-template #numeroDocumentoParteNoMask>
      <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
        [formControl]="numeroDocumentoParte"
        (change)="onFirstEdit.emit()" [ngClass]="{'border border-danger': numeroDocumentoParte.touched && numeroDocumentoParte.errors}"
      >
    </ng-template>
  </div>
</td>
<td>
  <ng-container [ngTemplateOutlet]="shouldBlockPartQuantityEditionByDocumentType ? quantidadePartesField : canalDocumentoParteField"></ng-container>
</td>
<td *ngIf="!shouldBlockPartQuantityEditionByDocumentType && situacaoAduaneiraParte">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="situacaoAduaneiraParte" (change)="onFirstEdit.emit()"
      [ngClass]="{'border border-danger': situacaoAduaneiraParte.touched && situacaoAduaneiraParte.errors}"
    >
  </div>
</td>

<ng-template #canalDocumentoParteField>
  <select class="custom-select" *ngIf="canalDocumentoParte" [formControl]="canalDocumentoParte"
    [ngClass]="{'border border-danger': canalDocumentoParte.touched && canalDocumentoParte.errors}"
    (change)="onFirstEdit.emit()"
  >
    <option value="" disabled selected>Selecione</option>
    <option *ngFor="let canal of canais" [ngValue]="canal.nome">{{canal.nome}}</option>
  </select>
</ng-template>

<ng-template #quantidadePartesField>
  <div class="input-group" *ngIf="quantidadePartes">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="quantidadePartes"
    >
  </div>
</ng-template>
