import { AuthenticationService } from './../../../services/authentication/authentication.service';
import { filter } from 'rxjs';
import { LinhaGridRelacaoBookingsCabotagemEmbarqueComponent } from './../linha-grid-relacao-bookings-cabotagem-embarque/linha-grid-relacao-bookings-cabotagem-embarque.component';
import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from '../../../models/HttpResponses/ErrorResponse';
import { CabotagemEmbarqueService } from './../../../services/cabotagem-embarque.service';
import { Viagem } from './../../../models/CabotagemEmbarque/Viagem';
import { BookingDTO } from './../../../models/CabotagemEmbarque/BookingDTO';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-relacao-bookings',
  templateUrl: './relacao-bookings.component.html',
  styleUrls: ['./relacao-bookings.component.scss']
})
export class RelacaoBookingsComponent implements OnInit {

  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;
  public bookingRelationDisplay!: BookingDTO[];
  public bsConfig?: Partial<BsDatepickerConfig>;
  private locale = 'pt-br';
  private colorTheme = 'theme-dark-blue';
  public dataHoraCadastro?: Date;
  public areAllItemsSelected: boolean = false;

  @Output() onSearchBookings: EventEmitter<BookingDTO[]> = new EventEmitter<BookingDTO[]>();
  @ViewChildren(LinhaGridRelacaoBookingsCabotagemEmbarqueComponent) bookingsGrid!: QueryList<LinhaGridRelacaoBookingsCabotagemEmbarqueComponent>;

  constructor(
    private fb: UntypedFormBuilder,
    private localeService: BsLocaleService,
    private changeDetector: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private cabotagemEmbarqueService: CabotagemEmbarqueService,
    private toastService:ToastService,
    private authenticationService : AuthenticationService
  ) { }

  public ngOnInit(): void {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, withTimepicker: true});
    this.bookingRelationDisplay = [];
    this.validation();
  }

  private validation(): void{
    this.form = this.fb.group({
      navio: [''],
      viagem: [''],
      booking: ['']
    });
  }

  public includeBooking(booking: BookingDTO): void{
    this.bookingRelationDisplay.push(booking);
    this.changeDetector.detectChanges();
  }

  public includeMultipleBookings(bookings: BookingDTO[]): void{
    bookings.forEach((booking =>{
      this.bookingRelationDisplay.push(booking);

    }))
    // this.bookingRelationDisplay = [...bookings];
    this.changeDetector.detectChanges();
  }

  public clear(): void{
    this.form.reset();
    this.eraseBookingRelation();
  }

  public eraseBookingRelation(): void{
    this.bookingRelationDisplay = [];
  }

  public get areSearchFieldsEmpty(): boolean{
    return !!(!this.form.get('navio')?.value && !this.form.get('viagem')?.value && !this.form.get('booking')?.value);
  }

  public searchBookingRelations(): void{
    this.eraseBookingRelation();
    let navio = this.form.get('navio')?.value ?? '';
    let viagem = this.form.get('viagem')?.value ?? '';
    let booking = this.form.get('booking')?.value ?? '';
    this.spinner.show();
    this.cabotagemEmbarqueService.getBookingsRelation(navio, viagem, booking)
    .subscribe({
      next: (bookingRelation: any) => {
        this.spinner.hide();
        var bookings = bookingRelation.data as BookingDTO[];
        bookings.forEach(
          (booking) => {
            if(!booking.dataHoraCadastro){
              booking.dataHoraCadastro = new Date();
            }
            if (!booking.usuario) {
              booking.usuario = this.authenticationService.name
            }
          }
        );
        this.onSearchBookings.emit(bookings);
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        this.eraseBookingRelation();
        (err.error as ErrorResponse)?.errors?.forEach(
          (error) => {
            if(error && error.length){
              this.toastService.error(error);
            }
          }
        );
      }
    });
  }

  public getBookingRelationsFromGrid(): BookingDTO[]{
    return this.bookingsGrid.map(
      (booking) => booking.getBookingFromForm()
    ) ?? [];
  }

  public getSelectedBookingRelationsFromGrid(): BookingDTO[]{
    return this.bookingsGrid?.filter(
        (booking) => !!(booking.selecionado?.value)
      ).map(
        (booking) => booking.getBookingFromForm()
      )
      ?? [];
  }

  public areAllBookingsValid(): boolean{
    return this.bookingsGrid.map(
      (booking) => booking.isBookingValid()
    )?.every(line => line);
  }

  public areSelectedBookingsValid(): boolean{
    return this.bookingsGrid?.filter(
      (booking) => !!(booking.selecionado?.value)
    ).map(
      (booking) => booking.isBookingValid()
    )?.every(line => line);
  }

  public switchSelectAll(): void{
    this.areAllItemsSelected = !this.areAllItemsSelected;
    this.bookingsGrid.forEach(
      (booking) => booking.selecionado.setValue(this.areAllItemsSelected)
    )
  }

  public eraseBookingFromGrid(index: number): void{
    this.bookingRelationDisplay.splice(index, 1);
    if(!this.bookingRelationDisplay?.length){
      this.areAllItemsSelected = false;
    }
  }
}
