import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FiltroRecepcaoNFDATConteiner } from '../../models/relatorios/RecepcaoNFDATConteiner/FiltroRecepcaoNFDATConteiner';
import { DatePipe } from '@angular/common';
import { SuccessResponse } from '../../models/HttpResponses/SuccessResponse';


@Injectable({
  providedIn: 'root'
})
export class RelatorioRecepcaoNfeDatConteinerService  {
  private logo = '../../assets/images/logoSeptibaTecon.png';

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe
    ) { }

  public searchRecepcaoNFDATConteiner(solicitation: FiltroRecepcaoNFDATConteiner): Observable<SuccessResponse> {
    return this.http.get<SuccessResponse>(`${environment.apiUrlForRelatorios}/Recepcao?${this.setQueryStringForSearchRecepcaoNFDATConteinerSolicitation(solicitation)}`);
  }

  private setQueryStringForSearchRecepcaoNFDATConteinerSolicitation(solicitation: FiltroRecepcaoNFDATConteiner): string{
    let query: string[] = [];


    if(solicitation.cliente){
      query.push(`Cliente=${encodeURIComponent(solicitation.cliente)}`);
    }
    if(solicitation.cnpj){
      query.push(`CNPJ=${encodeURIComponent(solicitation.cnpj)}`);
    }
    if(solicitation.conteiner){
      query.push(`Conteiner=${encodeURIComponent(solicitation.conteiner)}`);
    }
    if(solicitation.periodo){
      query.push(`PeriodoDe=${encodeURIComponent(new Date(solicitation.periodo).toLocaleDateString('PT-BR'))}`);
    }
    if(solicitation.periodoAte){
      query.push(`PeriodoAte=${encodeURIComponent(new Date(solicitation.periodoAte).toLocaleDateString('PT-BR'))}`);
    }
    if(solicitation.envioCct){
      query.push(`EnvioCCTDe=${encodeURIComponent(new Date(solicitation.envioCct).toLocaleDateString('PT-BR'))}`);
    }
    if(solicitation.envioCctAte){
      query.push(`EnvioCCTAte=${encodeURIComponent(new Date(solicitation.envioCctAte).toLocaleDateString('PT-BR'))}`);
    }
    query.push(`DepositadosSemRecepcao=${encodeURIComponent(!!solicitation.depositadoSemRecepcao)}`);
    return query.join('&');
  }

}
