import { ConteinerCabotagem } from 'src/app/models/SolicitacaoLiberacaoCabotagem/ConteinerCabotagem';
import { VerificacaoDocumentosService } from './../../../shared/utils/verificacao-documentos.service';
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, QueryList, ViewChildren, Input, AfterContentInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { Mascara } from 'src/app/models/Mascara';
import { DocumentoParteCabotagem } from 'src/app/models/SolicitacaoLiberacaoCabotagem/DocumentoParteCabotagem';
import { LinhaGridArvorePartesCabotagemComponent } from '../linha-grid-arvore-partes-cabotagem/linha-grid-arvore-partes-cabotagem.component';
import { Campo } from 'src/app/models/Campo';

@Component({
  selector: 'app-arvore-partes-cabotagem',
  templateUrl: './arvore-partes-cabotagem.component.html',
  styleUrls: ['./arvore-partes-cabotagem.component.scss']
})
export class ArvorePartesCabotagemComponent implements OnInit, AfterContentInit {

  public isCollapsed: boolean = false;
  public numerosDocumentosDisplay!: DocumentoParteCabotagem[];
  public storedPartDocuments!: DocumentoParteCabotagem[] | undefined;
  public arePartDocumentsSameAsStored!: boolean;
  public arvoreParteReadonly: boolean = false;

  @Input() mascara!: Mascara;
  @Input() camposFormulario!: Campo[];
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() shouldBlockPartQuantityEditionByDocumentType?: boolean;
  @Output() dataFormOut: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() onArvorePartesLoad: EventEmitter<void> = new EventEmitter<void>();
  @ViewChildren(LinhaGridArvorePartesCabotagemComponent) documentosPartes!: QueryList<LinhaGridArvorePartesCabotagemComponent>;

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private verificacaoDocumentosService: VerificacaoDocumentosService
  ) { }

  public ngOnInit(): void {
    this.numerosDocumentosDisplay = [];
    this.sendArvorePartesData();
  }

  public ngAfterContentInit(): void {
    this.onArvorePartesLoad.emit();
  }

  public sendArvorePartesData(): void {
    this.checkIfPartDocumentsSameAsStored();
    if(!this.numerosDocumentosDisplay?.length){
      this.dataFormOut.emit({"arvorePartes" : []});
      return;
    }
    this.dataFormOut.emit({'arvorePartes': this.getPartDocumentsFromForm()});

  }

  public setFormValuesFromReceivedDocumentNumbers(documentosArvorePartes: DocumentoParteCabotagem[] | undefined){
    if(!documentosArvorePartes){
      return;
    }
    if(this.arvoreParteReadonly){
      return;
    }
    this.numerosDocumentosDisplay = [...documentosArvorePartes] ?? [];
    this.sendArvorePartesData();
    this.changeDetector.detectChanges();
    this.checkIfPartDocumentsSameAsStored();
  }

  public setArvorePartesFromReceivedContainers(conteineres: ConteinerCabotagem[] | undefined){
    this.numerosDocumentosDisplay = [];
    if(!conteineres?.length){
      return;
    }
    let documentosParte: DocumentoParteCabotagem[] = [];
    conteineres.forEach(
      (conteiner) => {
        if(conteiner?.documentos?.length){
          conteiner.documentos?.forEach(
            (documento) => {
              documentosParte.push({
                numeroConteiner: documento.numeroConteiner ? documento.numeroConteiner : conteiner.numeroConteiner,
                numeroDocumento: documento.numeroDocumento,
                parteCadastrada: !!documento.parteCadastrada || !!documento.numeroDocumento?.length,
                numeroCE: documento.numeroCE
              });
            }
          );
        }
      }
    );
    this.numerosDocumentosDisplay = [...documentosParte] ?? [];
    this.storedPartDocuments = documentosParte?.length ? [...documentosParte] : undefined;
    this.sendArvorePartesData();
    this.changeDetector.detectChanges();
    this.checkIfPartDocumentsSameAsStored();
  }

  public addPartDocumentsToGrid(arvorePartesList: DocumentoParteCabotagem[] | undefined): void {
    if (!arvorePartesList?.length) {
      return;
    }
    this.numerosDocumentosDisplay.push(...arvorePartesList);
    this.sendArvorePartesData();
    this.changeDetector.detectChanges();
  }

  public removePartDocumentsFromGrid(itemsToRemove: number | undefined): void {
    if (!itemsToRemove) {
      return;
    }
    while(itemsToRemove > 0){
      this.numerosDocumentosDisplay.pop();
      --itemsToRemove;
    }
    this.sendArvorePartesData();
    this.changeDetector.detectChanges();
  }

  public cancel(){
    this.numerosDocumentosDisplay = [];
  }

  public areAllDocumentoParteLinesValid(): boolean{
    return this.documentosPartes?.map(
      (documentoParte) => documentoParte.isDocumentoParteValid()
    ).every(line => line);
  }

  public clean() {
    this.numerosDocumentosDisplay = [];
  }

  public erasePartDocumentByFailedIntegrationNumber(documentNumber: string): void{
    let partDocumentIndex: number = this.getPartDocumentsFromForm().findIndex(
      (partDocument) => this.verificacaoDocumentosService.eraseMask(partDocument.numeroDocumento) == this.verificacaoDocumentosService.eraseMask(documentNumber)
    );
    if(partDocumentIndex && partDocumentIndex >= 0){
      this.numerosDocumentosDisplay.splice(partDocumentIndex, 1);
    }
  }

  public getPartDocumentsFromForm(): DocumentoParteCabotagem[]{
    let documentosParte: DocumentoParteCabotagem[] = this.documentosPartes?.map(
      (parte) => parte.getDocumentoParteFromFormFields()
    );
    return documentosParte ?? [];
  }

  public areAllGridLinesValid(): boolean{
    if(!this.documentosPartes?.length){
      return true;
    }
    return this.documentosPartes?.map(
      (parte) => parte.isDocumentoParteValid()
    ).every(line => line);
  }

  public markEveryLineAstouched(): void{
    this.documentosPartes?.forEach(
      (documentoParte) => {
        documentoParte?.numeroDocumento?.markAsTouched();
      }
    );
  }

  public setSettingsfromFields(): void{
    if(!this.camposFormulario?.length){
      this.documentosPartes.forEach(
        (documentoParte) => {
          documentoParte.enableAllFormFields();
        }
      );
      return;
    }
    let arvoreParteReadonly: Campo | undefined = this.camposFormulario?.find(campo => campo.nome == 'arvoreParte');
    this.arvoreParteReadonly = !!arvoreParteReadonly?.leitura || !!arvoreParteReadonly?.bloqueado;
    this.changeDetector.detectChanges();
    this.documentosPartes.forEach(
      (documentoParte) => {
        documentoParte.setSettingsfromFields();
      }
    );
    this.changeDetector.detectChanges();
  }

  public checkIfPartDocumentsSameAsStored(): void{
    let savedPartDocuments = this.storedPartDocuments ? [...this.storedPartDocuments] : [];
    let currentPartDocuments = this.getPartDocumentsFromForm() ?? [];
    this.arePartDocumentsSameAsStored = savedPartDocuments.every(
      saved => currentPartDocuments?.find(
        current => {
          return (saved.parteCadastrada ?? '') == (current.parteCadastrada ?? '') &&
            (saved.numeroDocumento ?? '') == (current.numeroDocumento ?? '') &&
            (saved.numeroConteiner ?? '') == (current.numeroConteiner ?? '');
        }
      )
    ) && currentPartDocuments?.every(
      current => savedPartDocuments.find(
        saved => {
          return (saved.parteCadastrada ?? '') == (current.parteCadastrada ?? '') &&
            (saved.numeroDocumento ?? '') == (current.numeroDocumento ?? '') &&
            (saved.numeroConteiner ?? '') == (current.numeroConteiner ?? '');
        }
      )
    );
    this.changeDetector.detectChanges();
  }
}
