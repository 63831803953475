import { PrintService } from '../../../../services/relatorios/print.service';
import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, Renderer2, TemplateRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { RecepcaoNFDATConteinerGrid } from 'src/app/models/relatorios/RecepcaoNFDATConteiner/RecepcaoNFDATConteinerGrid';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { Campo } from 'src/app/models/Campo';
import { ExcelService } from 'src/app/services/excel.service';
import { RelatorioRecepcaoNfeDatConteinerService } from 'src/app/services/relatorios/relatorio-recepcao-nfe-dat-conteiner.service';
import { DatePipe } from '@angular/common';
import { ToastService } from 'src/app/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImpressaoNfDatConteinerComponent } from '../impressao-nf-dat-conteiner/impressao-nf-dat-conteiner.component';


@Component({
  selector: 'app-recepcao-nf-dat-conteiner',
  templateUrl: './recepcao-nf-dat-conteiner.component.html',
  styleUrls: ['./recepcao-nf-dat-conteiner.component.scss']
})
export class RecepcaoNfDatConteinerComponent implements OnInit {
  public form!: UntypedFormGroup;
  public recepcaoNFDATConteinerGrid!: RecepcaoNFDATConteinerGrid[];
  private locale = 'pt-br';
  public bsConfig?: Partial<BsDatepickerConfig>;
  private colorTheme = 'theme-dark-blue';
  public readonly currentDate: Date = new Date();
  protected isPrinting: boolean = false;
  @ViewChild(ImpressaoNfDatConteinerComponent) protected impressao!: ImpressaoNfDatConteinerComponent;

  // @Input() camposformulario!: Campo[];

  constructor(
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private fb: UntypedFormBuilder,
    private localeService: BsLocaleService,
    private excelService:ExcelService,
    private relatorioRecepcaoNfeDatConteinerService:RelatorioRecepcaoNfeDatConteinerService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private toastService:ToastService,
    private changeDetector: ChangeDetectorRef,
    private printService:PrintService,
  ) { 

    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme});
    this.validation();
  }

  
  public ngOnInit(): void {
  }
  private validation(): void{
    this.form = this.fb.group(
      {
        periodo: ['', [Validators.required]],
        periodoAte: ['', [Validators.required]],
        cliente: [''],
        documentoCliente: ['', [this.validateCnpj.bind(this)]],
        conteiner: [''],
        envioCct: [''],
        envioCctAte: [''],
        depositadoSemRecepcao: [false],
        recepcaoNFDATConteineres: [null],
      },
      {
        validators: [this.checkIfBothDatesAreInformed.bind(this)]
      }
    );
  }

  public searchContainerRelations(): void{
    this.clearContainerRelations();
    this.spinner.show();
    this.relatorioRecepcaoNfeDatConteinerService.searchRecepcaoNFDATConteiner(this.form.getRawValue())
    .subscribe({
      next:(response) => {
        this.spinner.hide();
        let relatorio = response.data as RecepcaoNFDATConteinerGrid[];
        if(relatorio?.length){
          this.setContainerRelations(relatorio);
        }
        response?.message?.forEach(
          (msg) => {
            if(msg?.length){
              this.toastService.error(msg);
            }
          }
        );
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      }
    });
  }


  private setContainerRelations(recepcaoNFDATConteinerGrid: RecepcaoNFDATConteinerGrid[]): void{
    // console.log(recepcaoNFDATConteinerGrid)
    this.recepcaoNFDATConteinerGrid = [...recepcaoNFDATConteinerGrid];
    this.form.get('recepcaoNFDATConteineres')?.setValue([...recepcaoNFDATConteinerGrid]);
  }

  public clearFields(): void{
    this.validation();
    this.recepcaoNFDATConteinerGrid = [];
  }

  private clearContainerRelations(): void{
    this.recepcaoNFDATConteinerGrid = [];
    this.form.get('recepcaoNFDATConteineres')?.setValue([]);
  }

  public downloadPDF(title:string){
    this.isPrinting = true;
    this.changeDetector.detectChanges();
    this.impressao.setPrintingData({
      dataInicio: this.form.value.periodo,
      dataFim: this.form.value.periodoAte,
      nomeRelatorio: title,
      dados: this.recepcaoNFDATConteinerGrid,
    });
    this.printService.setPrinting(this.impressao.getTemplate());
    this.changeDetector.detectChanges();
    window.onafterprint = () => {
    this.isPrinting = false;
    this.printService.unsetPrinting();
    }
    setTimeout(() => {
      window.print();
    }, 500);
  }

  public downloadExcel(title: string) {
    let header = [
      { nome: 'Tipo de Documento', key: 'tipoDoc', width: 25 },
      { nome: 'Nº Documento', key: 'numeroDoc', width: 25 },
      { nome: 'Cliente', key: 'cliente', width: 25 },
      { nome: 'CNPJ Cliente', key: 'cnpjCliente', width: 30 },
      { nome: 'Transportador', key: 'nomeTransportador', width: 25 },
      { nome: 'CNPJ Transportador', key: 'cnpjTransportador', width: 30 },
      { nome: 'Nome Motorista', key: 'nomeMotorista', width: 25 },
      { nome: 'CPF Motorista', key: 'cpfMotorista', width: 25 },
      { nome: 'Contêiner', key: 'conteiner', width: 25 },
      { nome: 'Dt/Hr da Recepção', key: 'dataHoraRecepcao', width: 25 },
      { nome: 'Dt/Hr da Pesagem', key: 'dataHoraPesagem', width: 25 },
      { nome: 'Retorno CCT', key: 'retornoCct', width: 25 },
      { nome: 'Tempo para Pesagem', key: 'tempoPesagem', width: 25 },
      { nome: 'Navio', key: 'navio', width: 25 },
      { nome: 'Viagem', key: 'viagem', width: 25 },
      { nome: 'Booking', key: 'booking', width: 25 },
      { nome: 'Dt/Hr da Chegada', key: 'dataHoraChegada', width: 25 },
      { nome: 'Dt/Hr Envio CCT', key: 'dataHoraEnvioCct', width: 25 },
      { nome: 'Tempo para Recepção CCT', key: 'tempoRecepcaoCct', width: 25 }

    ];
    let data = this.recepcaoNFDATConteinerGrid?.map(
      (relatorio) => {
        return {
          ...relatorio,
          dataHoraChegada: this.datePipe.transform(relatorio.dataHoraChegada, 'dd/MM/YYYY HH:mm:ss'),
          dataHoraRecepcao: this.datePipe.transform(relatorio.dataHoraRecepcao, 'dd/MM/YYYY HH:mm:ss'),
          dataHoraEnvioCct: this.datePipe.transform(relatorio.dataHoraEnvioCct, 'dd/MM/YYYY HH:mm:ss'),
          dataHoraPesagem: this.datePipe.transform(relatorio.dataHoraPesagem, 'dd/MM/YYYY HH:mm:ss')
        }
      }
    ) ?? [];
   this.excelService.exportAsExcelFileCustom(data,'Recepção-NF-DAT-Conteiner',header,title)
  }

  public isRecepcaoNfDatConteinerListNotEmpty(): boolean{
    return !!this.recepcaoNFDATConteinerGrid?.length;
  }

  private validateCnpj(control: AbstractControl): { invalidDocument: string } | null {
    let controlValue: string = control?.value;
    if (!controlValue?.length) {
      return null;
    }
    let error: string | null;
    let clearDocument: string = this.verificacaoDocumentosService.eraseMask(controlValue);
    if (clearDocument.length == 14) {
      error = this.verificacaoDocumentosService.calculateValidCNPJ(clearDocument);
    }
    else {
      error = 'O nº do CNPJ informado é inválido. Favor verificar!';
    }
    if (error?.length) {
      return { invalidDocument: error };
    }
    return null;
  }

  private checkIfBothDatesAreInformed(group: UntypedFormGroup): { invalidPeriod: string[] } | null {
    if (!group) {
      return null;
    }
    let error: string[] = [];
    let periodo = group.get('periodo')?.value ?? '';
    let periodoAte = group.get('periodoAte')?.value ?? '';
    if((periodo && !periodoAte) || (!periodo && periodoAte)){
      error.push('Favor informar ambas as datas de período!');
    }
    let envioCct = group.get('envioCct')?.value ?? '';
    let envioCctAte = group.get('envioCctAte')?.value ?? '';
    if((envioCct && !envioCctAte) || (!envioCct && envioCctAte)){
      error.push('Favor informar ambas as datas de envio ao CCT!');
    }
    if(error.length){
      return {invalidPeriod: error};
    }
    return null;
  }

}
