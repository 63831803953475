import { UsuarioInternoDirective } from './shared/UsuarioInternoDirective.directive';
import { VisualizarSolicitacoesCabotagemComponent } from './components/modais/visualizar-solicitacoes-cabotagem/visualizar-solicitacoes-cabotagem.component';
import { VisualizarSolicitacoesExpComponent } from './components/modais/visualizar-solicitacoes-exp/visualizar-solicitacoes-exp.component';
import { GridConteineresBloqueioDesbloqueioConteinerEmbarqueComponent } from './components/bloqueio-desbloqueio-conteiner-embarque/grid-conteineres-bloqueio-desbloqueio-conteiner-embarque/grid-conteineres-bloqueio-desbloqueio-conteiner-embarque.component';
import { DadosDocumentoTransporteN4Component } from './components/recepcao-documento-transporte/dados-documento-transporte-n4/dados-documento-transporte-n4.component';
import { DadosDocumentoTransporteCctComponent } from 'src/app/components/recepcao-documento-transporte/dados-documento-transporte-cct/dados-documento-transporte-cct.component';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './shared/nav/nav.component';
import { TitleComponent } from './shared/title/title.component';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { FiltroLiberacaoDocumentalComponent } from './components/liberacao-documental/filtro-liberacao-documental/filtro-liberacao-documental.component';
import { GridSolicitacoesLiberacaoDocumentalComponent } from './components/liberacao-documental/grid-solicitacoes-liberacao-documental/grid-solicitacoes-liberacao-documental.component';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SolicitacaoLiberacaoImportacaoComponent } from './pages/solicitacao-liberacao-importacao/solicitacao-liberacao-importacao.component';
import { DocumentoComponent } from './shared/components/documento/documento.component';
import { ClienteComponent } from './shared/components/cliente/cliente.component';
import { DadosComplementaresComponent } from './components/solicitacao-liberacao-importacao/dados-complementares/dados-complementares.component';
import { RelacaoConteinerImportacaoComponent } from './components/solicitacao-liberacao-importacao/relacao-conteiner-importacao/relacao-conteiner-importacao.component';
import { ArvoreDePartesComponent } from './components/solicitacao-liberacao-importacao/arvore-de-partes/arvore-de-partes.component';
import { ConsultaBloqueioComponent } from './components/liberacao-documental/consulta-de-bloqueio/consulta-de-bloqueio.component';
import { LiberacaoDocumentalComponent } from './pages/liberacao-documental/liberacao-documental.component';
import { VisualizarSolicitacoesImpComponent } from './components/modais/visualizar-solicitacoes-imp/visualizar-solicitacoes-imp.component';
import { SolicitacaoLiberacaoCabotagemComponent } from './pages/solicitacao-liberacao-cabotagem/solicitacao-liberacao-cabotagem.component';
import { DadosComplementaresCabotagemComponent } from './components/solicitacao-liberacao-cabotagem/dados-complementares-cabotagem/dados-complementares-cabotagem.component';
import { RelacaoNotasFiscaisComponent } from './components/solicitacao-liberacao-cabotagem/relacao-notas-fiscais/relacao-notas-fiscais.component';
import { ArvorePartesCabotagemComponent } from './components/solicitacao-liberacao-cabotagem/arvore-partes-cabotagem/arvore-partes-cabotagem.component';
import { RelacaoConteinerCabotagemComponent } from './components/solicitacao-liberacao-cabotagem/relacao-conteiner-cabotagem/relacao-conteiner-cabotagem.component';
import { RecepcaoNotaFiscalComponent } from './pages/recepcao-due-contigencia/recepcao-nota-fiscal/recepcao-nota-fiscal.component';
import { XmlComponent } from './shared/components/xml/xml.component';
import { DatePipe } from '@angular/common';
import { ComponenteConteinerComponent } from './components/recepcao-due-contigencia/recepcao-nota-fiscal-nfe/componente-conteiner/componente-conteiner.component';
import { InformacoesCargasComponent } from './components/recepcao-due-contigencia/recepcao-nota-fiscal-nfe/informacoes-cargas/informacoes-cargas.component';
import { DadosEnvioComponent } from './components/dados-envio/dados-envio.component';
import { NotaFiscalNfeComponent } from './components/recepcao-due-contigencia/recepcao-nota-fiscal-nfe/nota-fiscal-nfe/nota-fiscal-nfe.component';
import { SolicitacaoLiberacaoExportacaoComponent } from './pages/solicitacao-liberacao-exportacao/solicitacao-liberacao-exportacao.component';
import { DadosComplementaresExportacaoComponent } from './components/solicitacao-liberacao-exportacao/dados-complementares-exportacao/dados-complementares-exportacao.component';
import { RelacaoConteinerExportacaoComponent } from './components/solicitacao-liberacao-exportacao/relacao-conteiner-exportacao/relacao-conteiner-exportacao.component';
import { ArvorePartesExportacaoComponent } from './components/solicitacao-liberacao-exportacao/arvore-partes-exportacao/arvore-partes-exportacao.component';
import { CabotagemEmbarqueComponent } from './pages/cabotagem-embarque/cabotagem-embarque.component';
import { RelacaoBookingsComponent } from './components/cabotagem-embarque/relacao-bookings/relacao-bookings.component';
import { AnexosCabotagemEmbarqueComponent } from './components/cabotagem-embarque/anexos-cabotagem-embarque/anexos-cabotagem-embarque.component';
import { IncluirBookingComponent } from './components/modais/incluir-booking/incluir-booking.component';
import { CondutorComponent } from './components/recepcao-due-contigencia/recepcao-nota-fiscal-nfe/condutor/condutor.component';
import { TransportadorComponent } from './components/recepcao-due-contigencia/recepcao-nota-fiscal-nfe/transportador/transportador.component';
import { RecepcaoDueContigenciaComponent } from './pages/recepcao-due-contigencia/recepcao-due-contigencia.component';
import { ModalRespostaPositivaNegativaComponent } from './shared/components/modais/modal-resposta-positiva-negativa/modal-resposta-positiva-negativa.component';
import { RecepcaoDocumentoTransporteComponent } from './pages/recepcao-due-contigencia/recepcao-documento-transporte/recepcao-documento-transporte.component';
import { DadosEnvioTransporteComponent } from './components/recepcao-documento-transporte/dados-envio-transporte/dados-envio-transporte.component';
import { DocumentoTransporteComponent } from './components/recepcao-documento-transporte/documento-transporte/documento-transporte.component';
import { EntregaDueContingenciaComponent } from './pages/entrega-due-contingencia/entrega-due-contingencia.component';
import { EntregaConteinerComponent } from './pages/entrega-due-contingencia/entrega-conteiner/entrega-conteiner.component';
import { EntregaDocumentoTransporteComponent } from './pages/entrega-due-contingencia/entrega-documento-transporte/entrega-documento-transporte.component';
import { RecepcaoConteinerComponent } from './pages/recepcao-due-contigencia/recepcao-conteiner/recepcao-conteiner.component';
import { ConteinerComponent } from './components/recepcao-due-contigencia/recepcao-conteiner/conteiner/conteiner.component';
import { TransporteComponent } from './components/recepcao-due-contigencia/recepcao-conteiner/transporte/transporte.component';
import { DocumentoEDTComponent } from './components/entrega-due-contingencia/entrega-documento-transporte/documento-edt/documento-edt.component';
import { SubpageTabsComponent } from './shared/subpage-tabs/subpage-tabs.component';
import { DadosDocumentoTransporteN4EDTComponent } from './components/entrega-due-contingencia/entrega-documento-transporte/dados-documento-transporte-n4-edt/dados-documento-transporte-n4-edt.component';
import { DadosEnvioEDTComponent } from './components/entrega-due-contingencia/entrega-documento-transporte/dados-envio-edt/dados-envio-edt.component';
import { EntregaRelacaoConteinerComponent } from './components/entrega-relacao-conteiner/entrega-relacao-conteiner.component';
import { RecepcaoConteinerTransshipComponent } from './pages/recepcao-due-contigencia/recepcao-conteiner-transship/recepcao-conteiner-transship.component';
import { RelacaoConteineresRCTComponent } from './components/recepcao-due-contigencia/recepcao-conteiner-transship/relacao-conteineres-rct/relacao-conteineres-rct.component';
import { DadosDocumentoTransporteCCTEDTComponent } from './components/entrega-due-contingencia/entrega-documento-transporte/dados-documento-transporte-cct-edt/dados-documento-transporte-cct-edt.component';
import { ReprovacaoAprovacaoLiberacaoPorPeriodoComponent } from './pages/relatorios/reprovacao-aprovacao-liberacao-por-periodo/reprovacao-aprovacao-liberacao-por-periodo.component';
import { ReprovacaoAprovacaoLiberacaoComponent } from './components/relatorios/relatorio-reprovacao-aprovacao-liberacao/reprovacao-aprovacao-liberacao/reprovacao-aprovacao-liberacao.component';
import { FiltroRelatorioEntregaDaCargaNavioComponent } from './components/relatorios/relatorio-entrega-da-carga-navio/filtro-relatorio-entrega-da-carga-navio/filtro-relatorio-entrega-da-carga-navio.component';
import { RelatorioEntregaDaCargaNavioComponent } from './pages/relatorios/relatorio-entrega-da-carga-navio/relatorio-entrega-da-carga-navio.component';
import { GridRelatorioEntregaDaCargaNavioComponent } from './components/relatorios/relatorio-entrega-da-carga-navio/grid-relatorio-entrega-da-carga-navio/grid-relatorio-entrega-da-carga-navio.component';
import { LinhaGridRelacaoBookingsCabotagemEmbarqueComponent } from './components/cabotagem-embarque/linha-grid-relacao-bookings-cabotagem-embarque/linha-grid-relacao-bookings-cabotagem-embarque.component';
import { RelatorioMovimentacaoClientesComponent } from './pages/relatorios/relatorio-movimentacao-clientes/relatorio-movimentacao-clientes.component';
import { FiltrosPesquisaGridRelatorioMovimentacaoClientesComponent } from './components/relatorios/relatorio-movimentacao-clientes/filtros-pesquisa-grid-relatorio-movimentacao-clientes/filtros-pesquisa-grid-relatorio-movimentacao-clientes.component';
import { RelatorioConteineresLiberadosComponent } from './pages/relatorios/relatorio-conteineres-liberados/relatorio-conteineres-liberados.component';
import { RelatorioRecepcaoNfDatConteinerComponent } from './pages/relatorios/relatorio-recepcao-nf-dat-conteiner/relatorio-recepcao-nf-dat-conteiner.component';
import { RecepcaoNfDatConteinerComponent } from './components/relatorios/relatorio-nf-dat-conteiner/recepcao-nf-dat-conteiner/recepcao-nf-dat-conteiner.component';
import { DadosEnvioRNFComponent } from './components/recepcao-due-contigencia/recepcao-nota-fiscal-nfe/dados-envio-rnf/dados-envio-rnf.component';
import { ConteineresLiberadosComponent } from './components/relatorios/relatorio-conteineres-liberados/conteineres-liberados/conteineres-liberados.component';
import { AcessoNegadoComponent } from './components/acesso-negado/acesso-negado.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { AuthDirective } from './shared/AuthDirective.directive';
import { TokenInterceptor } from './interceptors/token-interceptor.interceptor';
import { EstimativaCalculoComponent } from './pages/estimativa-calculo/estimativa-calculo.component';
import { CadastroDocumentoEstimativaCalculoComponent } from './pages/estimativa-calculo/cadastro-documento-estimativa-calculo/cadastro-documento-estimativa-calculo.component';
import { DocumentoEstimativaCalculoComponent } from './components/estimativa-calculo/cadastro-documento-estimativa-calculo/documento-estimativa-calculo/documento-estimativa-calculo.component';
import { ClienteEstimativaCalculoComponent } from './components/estimativa-calculo/cadastro-documento-estimativa-calculo/cliente-estimativa-estimativa-calculo/cliente-estimativa-calculo.component';
import { DadosComplementaresEstimativaCalculoComponent } from './components/estimativa-calculo/cadastro-documento-estimativa-calculo/dados-complementares-estimativa-calculo/dados-complementares-estimativa-calculo.component';
import { RelacaoConteinerEstimativaCalculoComponent } from './components/estimativa-calculo/cadastro-documento-estimativa-calculo/relacao-conteiner-estimativa-calculo/relacao-conteiner-estimativa-calculo.component';
import { DocumentosAnexadosComponent } from './shared/components/DocumentosAnexados/documentos-anexados/documentos-anexados.component';
import { ExtrairDadosSiscomexComponent } from './components/modais/extrair-dados-siscomex/extrair-dados-siscomex.component';
import { SolicitacaoFaturamentoEstimativaCalculoComponent } from './pages/estimativa-calculo/solicitacao-faturamento-estimativa-calculo/solicitacao-faturamento-estimativa-calculo.component';
import { DocumentoCabotagemComponent } from './shared/components/documento-cabotagem/documento-cabotagem.component';
import { EmissaoTicketPesagemExportacaoComponent } from './pages/emissao-ticket-pesagem-exportacao/emissao-ticket-pesagem-exportacao.component';
import { PesquisaEmissaoTicketPesagemExportacaoComponent } from './components/emissao-ticket-pesagem-exportacao/pesquisa-emissao-ticket-pesagem-exportacao/pesquisa-emissao-ticket-pesagem-exportacao.component';
import { GridEmissaoTicketPesagemExportacaoComponent } from './components/emissao-ticket-pesagem-exportacao/grid-emissao-ticket-pesagem-exportacao/grid-emissao-ticket-pesagem-exportacao.component';
import { BloqueioDesbloqueioConteinerEmbarqueComponent } from './pages/bloqueio-desbloqueio-conteiner-embarque/bloqueio-desbloqueio-conteiner-embarque.component';
import { LinhaGridRelacaoConteinerCabotagemComponent } from './components/solicitacao-liberacao-cabotagem/linha-grid-relacao-conteiner-cabotagem/linha-grid-relacao-conteiner-cabotagem.component';
import { LinhaGridRelacaoNotasFiscaisComponent } from './components/solicitacao-liberacao-cabotagem/linha-grid-relacao-notas-fiscais/linha-grid-relacao-notas-fiscais.component';
import { LinhaGridArvorePartesCabotagemComponent } from './components/solicitacao-liberacao-cabotagem/linha-grid-arvore-partes-cabotagem/linha-grid-arvore-partes-cabotagem.component';
import { LinhaGridArvoreDePartesComponent } from './components/solicitacao-liberacao-importacao/linha-grid-arvore-de-partes/linha-grid-arvore-de-partes.component';
import { LinhaGridRelacaoConteinerImportacaoComponent } from './components/solicitacao-liberacao-importacao/linha-grid-relacao-conteiner-importacao/linha-grid-relacao-conteiner-importacao.component';
import { LinhaGridArvorePartesExportacaoComponent } from './components/solicitacao-liberacao-exportacao/linha-grid-arvore-partes-exportacao/linha-grid-arvore-partes-exportacao.component';
import { LinhaGridRelacaoConteinerExportacaoComponent } from './components/solicitacao-liberacao-exportacao/linha-grid-relacao-conteiner-exportacao/linha-grid-relacao-conteiner-exportacao.component';
import { IntegracaoDocumentoParteComponent } from './components/modais/integracao-documento-parte/integracao-documento-parte.component';
import { MonitoramentoRpaApiComponent } from './pages/monitoramento-rpa-api/monitoramento-rpa-api.component';
import { PesquisaMonitoramentoRpaApiComponent } from './components/monitoramento-rpa-api/pesquisa-monitoramento-rpa-api/pesquisa-monitoramento-rpa-api.component';
import { GridMonitoramentoRpaApiComponent } from './components/monitoramento-rpa-api/grid-monitoramento-rpa-api/grid-monitoramento-rpa-api.component';
import { ModalDetalhesRpaApiComponent } from './components/monitoramento-rpa-api/modal-detalhes-rpa-api/modal-detalhes-rpa-api.component';
import { NgChartsModule } from 'ng2-charts';
import { ModalDetalhesEventosRequestResponseComponent } from './components/monitoramento-rpa-api/modal-detalhes-eventos-request-response/modal-detalhes-eventos-request-response.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ExibirJsonComponent } from './components/modais/exibir-json/exibir-json.component';
import { ModalRecusaLiberacaoComponent } from './shared/components/modais/modal-recusa-liberacao/modal-recusa-liberacao.component';
import { ModalRecusaAutorizacaoSupervisorComponent } from './shared/components/modais/modal-recusaAutorizacao-supervisor/modal-recusaAutorizacao-supervisor.component';
import { LinhaGridRelacaoConteinerEstimativaCalculoComponent } from './components/estimativa-calculo/cadastro-documento-estimativa-calculo/linha-grid-relacao-conteiner-estimativa-calculo/linha-grid-relacao-conteiner-estimativa-calculo.component';
import { LinhaGridBloqueioDesbloqueioConteinerEmbarqueComponent } from './components/bloqueio-desbloqueio-conteiner-embarque/linha-grid-bloqueio-desbloqueio-conteiner-embarque/linha-grid-bloqueio-desbloqueio-conteiner-embarque.component';
import { LinhaGridDocumentosAnexadosComponent } from './shared/components/DocumentosAnexados/linha-grid-documentos-anexados/linha-grid-documentos-anexados.component';
import { ModalExibirAnexosComponent } from './shared/components/DocumentosAnexados/modal-exibir-anexos/modal-exibir-anexos.component';
import { LoginPage } from './components/login/login.page';
import { HomeComponent } from './components/home/home.component';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelecaoConteineresDivergentesComponent } from './components/estimativa-calculo/cadastro-documento-estimativa-calculo/selecao-conteineres-divergentes/selecao-conteineres-divergentes.component';
import { LinhaGridSolicitacoesUsuarioInternoComponent } from './components/estimativa-calculo/solicitacao-faturamento-estimativa-calculo/linha-grid-solicitacoes-usuario-interno/linha-grid-solicitacoes-usuario-interno.component';
import { ModalDocumentosAnexadosEstimativaCalculoComponent } from './components/estimativa-calculo/modal-documentos-anexados-estimativa-calculo/modal-documentos-anexados-estimativa-calculo.component';
import { LinhaGridSolicitacoesUsuarioExternoComponent } from './components/estimativa-calculo/solicitacao-faturamento-estimativa-calculo/linha-grid-solicitacoes-usuario-externo/linha-grid-solicitacoes-usuario-externo.component';
import { ModalMensagemComponent } from './components/modais/modal-mensagem/modal-mensagem.component';
import { ToastComponent } from './components/toast/toast.component';
import { GlobalErrorHandler } from './interceptors/global-error.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { LinhaGridRelacaoConteinerN4DocumentoTransporteComponent } from './shared/components/documento-transporte/linha-grid-relacao-conteiner-n4-documento-transporte/linha-grid-relacao-conteiner-n4-documento-transporte.component';
import { LinhaGridRelacaoConteinerCctDocumentoTransporteComponent } from './shared/components/documento-transporte/linha-grid-relacao-conteiner-cct-documento-transporte/linha-grid-relacao-conteiner-cct-documento-transporte.component';
import { ModalConfirmarLiberacaoComponent } from './shared/components/modais/modal-confirmar-liberacao/modal-confirmar-liberacao.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CampoNumeroDueComponent } from './shared/components/campo-numero-due/campo-numero-due.component';
import { GridDueConteinerExpComponent } from './components/solicitacao-liberacao-exportacao/grid-due-conteiner-exp/grid-due-conteiner-exp.component';
import { LinhaGridDueConteinerExpComponent } from './components/solicitacao-liberacao-exportacao/linha-grid-due-conteiner-exp/linha-grid-due-conteiner-exp.component';
import { ModalOKComponent } from './shared/components/modais/modal-ok/modal-ok.component';
import { ModalCancelarLiberacaoComponent } from './shared/components/modais/modal-cancelar-liberacao/modal-cancelar-liberacao.component';
import { ImpressaoNfDatConteinerComponent } from './components/relatorios/relatorio-nf-dat-conteiner/impressao-nf-dat-conteiner/impressao-nf-dat-conteiner.component';
import { ImpressaoConteineresLiberadosComponent } from './components/relatorios/relatorio-conteineres-liberados/impressao-conteineres-liberados/impressao-conteineres-liberados.component';
import { ImpressaoRelatorioMovimentacaoClientesComponent } from './components/relatorios/relatorio-movimentacao-clientes/impressao-relatorio-movimentacao-clientes/impressao-relatorio-movimentacao-clientes.component';
import { ImpressaoRelatorioEntregaDaCargaNavioComponent } from './components/relatorios/relatorio-entrega-da-carga-navio/impressao-relatorio-entrega-da-carga-navio/impressao-relatorio-entrega-da-carga-navio.component';
import { ImpressaoReprovacaoAprovacaoLiberacaoComponent } from './components/relatorios/relatorio-reprovacao-aprovacao-liberacao/impressao-reprovacao-aprovacao-liberacao/impressao-reprovacao-aprovacao-liberacao.component';
import { ArvorePartesEstimativaCalculoComponent } from './components/estimativa-calculo/cadastro-documento-estimativa-calculo/arvore-partes-estimativa-calculo/arvore-partes-estimativa-calculo.component';
import { LinhaGridArvorePartesEstimativaCalculoComponent } from './components/estimativa-calculo/cadastro-documento-estimativa-calculo/linha-grid-arvore-partes-estimativa-calculo/linha-grid-arvore-partes-estimativa-calculo.component';
import { ArvorePartesLiberacaoDocumentalComponent } from './components/liberacao-documental/arvore-partes/arvore-partes-liberacao-documental/arvore-partes-liberacao-documental.component';
import { LinhaParteLiberacaoDocumentalComponent } from './components/liberacao-documental/arvore-partes/linha-parte-liberacao-documental/linha-parte-liberacao-documental.component';
import { ModalOkCancelComponent } from './shared/components/modais/modal-ok-cancel/modal-ok-cancel.component';

defineLocale('pt-br', ptBrLocale);

@NgModule({
    declarations: [
        AppComponent,
        ToastComponent,
        NavComponent,
        TitleComponent,
        SolicitacaoLiberacaoImportacaoComponent,
        DocumentoComponent,
        ClienteComponent,
        DadosComplementaresComponent,
        RelacaoConteinerImportacaoComponent,
        ArvoreDePartesComponent,
        ConsultaBloqueioComponent,
        LiberacaoDocumentalComponent,
        FiltroLiberacaoDocumentalComponent,
        GridSolicitacoesLiberacaoDocumentalComponent,
        VisualizarSolicitacoesImpComponent,
        VisualizarSolicitacoesExpComponent,
        VisualizarSolicitacoesCabotagemComponent,
        SolicitacaoLiberacaoCabotagemComponent,
        DadosComplementaresCabotagemComponent,
        RelacaoNotasFiscaisComponent,
        ArvorePartesCabotagemComponent,
        RelacaoConteinerCabotagemComponent,
        XmlComponent,
        ComponenteConteinerComponent,
        InformacoesCargasComponent,
        NotaFiscalNfeComponent,
        SolicitacaoLiberacaoExportacaoComponent,
        DadosComplementaresExportacaoComponent,
        RelacaoConteinerExportacaoComponent,
        ArvorePartesExportacaoComponent,
        CabotagemEmbarqueComponent,
        RelacaoBookingsComponent,
        AnexosCabotagemEmbarqueComponent,
        IncluirBookingComponent,
        TransportadorComponent,
        RecepcaoNotaFiscalComponent,
        DadosEnvioComponent,
        CondutorComponent,
        TransportadorComponent,
        RecepcaoDueContigenciaComponent,
        ModalRespostaPositivaNegativaComponent,
        ModalOKComponent,
        RecepcaoDocumentoTransporteComponent,
        DadosEnvioTransporteComponent,
        DocumentoTransporteComponent,
        DadosDocumentoTransporteCctComponent,
        DadosDocumentoTransporteN4Component,
        EntregaDueContingenciaComponent,
        EntregaConteinerComponent,
        EntregaDocumentoTransporteComponent,
        RecepcaoConteinerComponent,
        ConteinerComponent,
        TransporteComponent,
        DocumentoEDTComponent,
        SubpageTabsComponent,
        EntregaRelacaoConteinerComponent,
        RecepcaoConteinerTransshipComponent,
        RelacaoConteineresRCTComponent,
        DadosDocumentoTransporteN4EDTComponent,
        DadosDocumentoTransporteCCTEDTComponent,
        DadosEnvioEDTComponent,
        RelatorioMovimentacaoClientesComponent,
        FiltrosPesquisaGridRelatorioMovimentacaoClientesComponent,
        RelatorioRecepcaoNfDatConteinerComponent,
        RecepcaoNfDatConteinerComponent,
        ReprovacaoAprovacaoLiberacaoPorPeriodoComponent,
        ReprovacaoAprovacaoLiberacaoComponent,
        FiltroRelatorioEntregaDaCargaNavioComponent,
        RelatorioEntregaDaCargaNavioComponent,
        GridRelatorioEntregaDaCargaNavioComponent,
        LinhaGridRelacaoBookingsCabotagemEmbarqueComponent,
        DadosEnvioRNFComponent,
        RelatorioConteineresLiberadosComponent,
        ConteineresLiberadosComponent,
        AuthCallbackComponent,
        AcessoNegadoComponent,
        AuthDirective,
        UsuarioInternoDirective,
        EstimativaCalculoComponent,
        CadastroDocumentoEstimativaCalculoComponent,
        DocumentoEstimativaCalculoComponent,
        ClienteEstimativaCalculoComponent,
        DadosComplementaresEstimativaCalculoComponent,
        RelacaoConteinerEstimativaCalculoComponent,
        DocumentosAnexadosComponent,
        ExtrairDadosSiscomexComponent,
        SolicitacaoFaturamentoEstimativaCalculoComponent,
        DocumentoCabotagemComponent,
        EmissaoTicketPesagemExportacaoComponent,
        PesquisaEmissaoTicketPesagemExportacaoComponent,
        GridEmissaoTicketPesagemExportacaoComponent,
        BloqueioDesbloqueioConteinerEmbarqueComponent,
        LinhaGridRelacaoConteinerCabotagemComponent,
        LinhaGridRelacaoNotasFiscaisComponent,
        LinhaGridArvorePartesCabotagemComponent,
        LinhaGridArvoreDePartesComponent,
        LinhaGridRelacaoConteinerImportacaoComponent,
        LinhaGridArvorePartesExportacaoComponent,
        LinhaGridRelacaoConteinerExportacaoComponent,
        IntegracaoDocumentoParteComponent,
        MonitoramentoRpaApiComponent,
        PesquisaMonitoramentoRpaApiComponent,
        GridMonitoramentoRpaApiComponent,
        ModalDetalhesRpaApiComponent,
        ModalDetalhesEventosRequestResponseComponent,
        ExibirJsonComponent,
        LinhaGridRelacaoConteinerEstimativaCalculoComponent,
        LinhaGridBloqueioDesbloqueioConteinerEmbarqueComponent,
        LinhaGridDocumentosAnexadosComponent,
        ModalExibirAnexosComponent,
        ModalRecusaLiberacaoComponent,
        ModalRecusaAutorizacaoSupervisorComponent,
        HomeComponent,
        LoginPage,
        SelecaoConteineresDivergentesComponent,
        LinhaGridSolicitacoesUsuarioInternoComponent,
        ModalDocumentosAnexadosEstimativaCalculoComponent,
        LinhaGridSolicitacoesUsuarioExternoComponent,
        ModalMensagemComponent,
        GridConteineresBloqueioDesbloqueioConteinerEmbarqueComponent,
        LinhaGridRelacaoConteinerN4DocumentoTransporteComponent,
        LinhaGridRelacaoConteinerCctDocumentoTransporteComponent,
        ModalConfirmarLiberacaoComponent,
        ModalOkCancelComponent,
        CampoNumeroDueComponent,
        GridDueConteinerExpComponent,
        ModalCancelarLiberacaoComponent,
        LinhaGridDueConteinerExpComponent,
        ImpressaoNfDatConteinerComponent,
        ImpressaoConteineresLiberadosComponent,
        ImpressaoRelatorioMovimentacaoClientesComponent,
        ImpressaoRelatorioEntregaDaCargaNavioComponent,
        ImpressaoReprovacaoAprovacaoLiberacaoComponent,
        ArvorePartesEstimativaCalculoComponent,
        LinhaGridArvorePartesEstimativaCalculoComponent,
        ArvorePartesLiberacaoDocumentalComponent,
        LinhaParteLiberacaoDocumentalComponent
    ],
    imports: [
        CommonModule,
        CurrencyMaskModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        NgxMaskModule.forRoot({
            dropSpecialCharacters: false,
        }),
        BsDatepickerModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            progressBar: true,
        }),
        NgxSpinnerModule,
        HttpClientModule,
        NgChartsModule,
        NgxJsonViewerModule,
        NgbModule,
        PdfViewerModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        DatePipe,
        HttpClientModule,
        JwtHelperService,
        MaskPipe,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
