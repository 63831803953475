import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PesquisaEmissaoTicketPesagemExportacao } from 'src/app/models/EmissaoTicketPesagemExportacao/PesquisaEmissaoTicketPesagemExportacao';
import { DocumentMaskEnum, DocumentSpecialCharactersForMask } from 'src/app/shared/utils/constantes-documentos';

@Component({
  selector: 'app-pesquisa-emissao-ticket-pesagem-exportacao',
  templateUrl: './pesquisa-emissao-ticket-pesagem-exportacao.component.html',
  styleUrls: ['./pesquisa-emissao-ticket-pesagem-exportacao.component.scss']
})
export class PesquisaEmissaoTicketPesagemExportacaoComponent implements OnInit {

  public form!: UntypedFormGroup;
  @Output() public onSearch: EventEmitter<PesquisaEmissaoTicketPesagemExportacao> = new EventEmitter<PesquisaEmissaoTicketPesagemExportacao>();

  public maskForDUE: string = DocumentMaskEnum.DUE_MASK;
  public specialCharsForDUE: string[] = DocumentSpecialCharactersForMask.DUE_SPECIAL_CHARS;

  public get isAnyFormControlFilled(): boolean{
    return !!this.form.get('conteiner')?.value?.length
      || !!this.form.get('booking')?.value?.length
      || !!this.form.get('numeroDUE')?.value?.length;
  }

  constructor(
    private fb: UntypedFormBuilder
  ) { }

  public ngOnInit(): void {
    this.validation();
  }

  private validation(): void{
    this.form = this.fb.group({
      conteiner: [''],
      booking: [''],
      numeroDUE: ['']
    });
  }

  public search(): void{
    this.onSearch.emit(this.form.getRawValue());
  }

  public clear(): void{
    this.form.reset();
  }

}
