import { RelacaoConteinerEmissaoTicketPesagemExportacao } from './../../../models/EmissaoTicketPesagemExportacao/RelacaoConteinerEmissaoTicketPesagemExportacao';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid-emissao-ticket-pesagem-exportacao',
  templateUrl: './grid-emissao-ticket-pesagem-exportacao.component.html',
  styleUrls: ['./grid-emissao-ticket-pesagem-exportacao.component.scss']
})
export class GridEmissaoTicketPesagemExportacaoComponent implements OnInit {

  public relacoesConteiner!: RelacaoConteinerEmissaoTicketPesagemExportacao[];
  public areAllContainerRelationsSelected: boolean = false;

  constructor() { }

  public ngOnInit(): void {
    this.clearContainerRelations();
  }

  public clearContainerRelations(): void{
    this.relacoesConteiner = [];
    this.areAllContainerRelationsSelected = false;
  }

  public setContainerRelations(relacoesConteiner: RelacaoConteinerEmissaoTicketPesagemExportacao[]): void{
    this.relacoesConteiner = relacoesConteiner;
    this.relacoesConteiner.forEach(
      (relacaoConteiner) => {
        relacaoConteiner.selecionado = !!relacaoConteiner.selecionado;
      }
    )
  }

  public switchSelectedForAll(): void{
    this.areAllContainerRelationsSelected = !this.areAllContainerRelationsSelected;
    this.relacoesConteiner.forEach(
      (relacaoConteiner) => {
        relacaoConteiner.selecionado = this.areAllContainerRelationsSelected;
      }
    );
  }

  public switchSelectedForSingleItem(index: number): void{
    this.areAllContainerRelationsSelected = false;
    this.relacoesConteiner[index].selecionado = !this.relacoesConteiner[index].selecionado;
  }

  public sendSelectedContainerRelations(): RelacaoConteinerEmissaoTicketPesagemExportacao[]{
    return this.relacoesConteiner?.filter(
      (relacaoConteiner) => relacaoConteiner.selecionado
    ) ?? [];
  }

}
