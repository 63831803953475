<ng-template #templateImpressao>
  <div class="display-on-print">
    <table *ngFor="let relatorio of relatorios" class="relatorio">
      <thead>
        <tr class="header borderBottom">
          <td colspan="4">
            <img class="logo" src="{{this.logo}}" alt="logo" />
          </td>
          <td colspan="4" >
            <span class="header-nomeRelatorio"> {{nomeRelatorio}} </span>
          </td>
          <td colspan="12">
            <span class="header-now-date">
              {{currentDate.toLocaleString('pt-BR')}}
            </span>
          </td>
        </tr>
        <tr class="header borderBottom" *ngIf="dataInicio && dataFim">
          <td colspan="24">
            <span>
              Período De {{dataInicio ? dataInicio.toLocaleDateString('pt-BR') : ""}} Até {{dataFim ? dataFim.toLocaleDateString('pt-BR') : ''}}
            </span>
          </td>
        </tr>
      </thead>
      <tbody *ngTemplateOutlet="relatorio"></tbody>
      <tfoot>
        <tr class="foot-cont"></tr>
      </tfoot>
    </table>
  </div>
</ng-template>

<ng-template #tableRelatorio #tableRelatorio1>
  <thead>
    <tr>
      <th class="header-5-porcento" >Tipo de Documento</th>
      <th class="header-5-porcento" >Nº Documento</th>
      <th class="header-5-porcento" >Contêiner</th>
      <th class="header-5-porcento">Dt/Hr Entrega</th>
      <th class="header-5-porcento">Navio</th>
      <th class="header-5-porcento">Viagem</th>
      <th class="header-5-porcento">Booking</th>
      <th class="header-5-porcento">Status</th>
      <th class="header-5-porcento" >Armador</th>
      <th class="header-5-porcento" >CNPJ Armador</th>
    </tr>
  </thead>
  <tr *ngFor="let relatorio of dados">
    <td class="header-5-porcento">{{relatorio?.tipoDocumento ? relatorio.tipoDocumento : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.numeroDocumento ? relatorio.numeroDocumento : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.conteiner ? relatorio.conteiner : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.dataHoraEntrega ?  (relatorio.dataHoraEntrega | date : 'dd/MM/YYYY HH:mm:ss') : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.navio ? relatorio.navio : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.viagem ? relatorio.viagem : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.booking ? relatorio.booking : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.status ? relatorio.status : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.armador ? relatorio.armador : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.cnpjArmador ? relatorio.cnpjArmador : ''}}</td>

  </tr>
</ng-template>
