import { AbaSubpagina } from './../../models/AbaSubpagina';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthRoles } from 'src/app/models/authRoles';

@Component({
  selector: 'app-subpage-tabs',
  templateUrl: './subpage-tabs.component.html',
  styleUrls: ['./subpage-tabs.component.scss']
})
export class SubpageTabsComponent implements OnInit {

  @Input() public tabs!: AbaSubpagina[];
  @Input() public activeTabPath!: string;
  @Output() public onSelectTab: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  public ngOnInit(): void {

  }

  public selectTab(tabPath: string): void{
    if(tabPath == this.activeTabPath){
      return;
    }
    this.onSelectTab.emit(tabPath);
  }

  validationAbas() {
   
  }

}
