<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Documento <span class="text-danger" *ngIf="!isDocumentoValid()">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-4">
                    <div class="col">
                        <label>Tipo do Documento: *</label>
                        <select class="custom-select" formControlName="tipoDocumento" placeholder="Selecione" [ngClass]="{'border border-danger': form.get('tipoDocumento')?.errors && !form.get('tipoDocumento')?.pristine}" (change)="changeDocumentTypeOnSelect()">
              <option [ngValue]="null" selected disabled>Selecione</option>
              <option *ngFor="let documentType of documentTypes" [ngValue]="documentType.nome">{{documentType.nome}}</option>
            </select>
                        <div *ngIf="form.get('tipoDocumento')?.errors && !form.get('tipoDocumento')?.pristine">
                            <p class="text-danger" *ngIf="form.get('tipoDocumento')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                        </div>
                    </div>
                    <div class="col">
                        <label>Nº Documento: *</label>
                        <div class="input-group">
                            <input *ngIf="!documentPattern; else inputForPatternUse" formControlName="numeroDocumento" type="text" class="form-control" aria-describedby="basic-addon2" placeholder="Digite aqui..." [mask]="maskForDocumentNumber" [specialCharacters]="specialCharsForMask"
                                [ngClass]="{'border border-danger': form.get('numeroDocumento')?.errors && form.get('numeroDocumento')?.touched}" #numDoc>
                            <ng-template #inputForPatternUse>
                                <input #numDoc formControlName="numeroDocumento" type="text" class="form-control" aria-describedby="basic-addon2" placeholder="Digite aqui..." [mask]="maskForDocumentNumber" [specialCharacters]="specialCharsForMask" [patterns]="documentPattern" [ngClass]="{'border border-danger': form.get('numeroDocumento')?.errors && form.get('numeroDocumento')?.touched}">
                            </ng-template>
                            <button class="action-primary-button" [disabled]="visualizar || form.invalid" (click)="search()">PESQUISAR</button>
                        </div>
                        <div *ngIf="form.get('numeroDocumento')?.errors && !form.get('numeroDocumento')?.pristine">
                            <p class="text-danger" *ngIf="form.get('numeroDocumento')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                            <p class="text-danger" *ngIf="form.get('numeroDocumento')?.errors?.['mask']">
                                Preencher no formato correto!
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-6" *ngIf="isNumeroCEVisible">
                        <label>Nº CE:</label>
                        <input type="text" class="form-control" [mask]="maskForCE" formControlName="numeroCE" [ngClass]="{'border border-danger': form.get('numeroCE')?.touched && form.get('numeroCE')?.errors}">
                        <div *ngIf="form.get('numeroCE')?.errors && form.get('numeroCE')?.touched">
                            <p class="text-danger" *ngIf="form.get('numeroCE')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                            <p class="text-danger" *ngIf="form.get('numeroCE')?.errors?.['mask']">
                                Preencher no formato correto!
                            </p>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="form.get('tipoDocumento')?.value == 'NF'">
                        <label>Chave Nota Fiscal:</label>
                        <input type="text" class="form-control" [mask]="maskForChaveNotaFiscal" formControlName="chaveNotaFiscal" [ngClass]="{'border border-danger': form.get('chaveNotaFiscal')?.touched && form.get('chaveNotaFiscal')?.errors}">
                        <div *ngIf="form.get('chaveNotaFiscal')?.errors && form.get('chaveNotaFiscal')?.touched">
                            <p class="text-danger" *ngIf="form.get('chaveNotaFiscal')?.errors?.['mask']">
                                Preencher no formato correto!
                            </p>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="form.get('tipoDocumento')?.value == 'DAT'">
                        <label>Nº DUE: *</label>
                        <app-campo-numero-due [visualizar]="visualizar" *ngFor="let numeroDUE of numerosDUE; let i = index" [index]="i" (onAddNumeroDUE)="addNumeroDUE()" (onRemoveNumeroDUE)="removeNumeroDUE(i)" [numeroDUESalvo]="numeroDUE.numeroDUE" (onUpdateNumeroDUE)="form.updateValueAndValidity()"></app-campo-numero-due>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-6">
                        <label>Arquivo XML:</label>
                        <input type="file" hidden #arquivoXML accept=".xml" (change)="uploadXmlFile($event.target)" [disabled]="isXmlUploadBlocked">
                        <div class="div-anexo my-2">
                            <div class="botao-anexo" (click)="arquivoXML.click()">
                                <img src="assets/images/upload.svg" alt="">
                                <span>ANEXAR ARQUIVO</span>
                            </div>
                        </div>
                        <div class="mostra-arquivo my-2" *ngIf="xmlFile">
                            <div class="nome-arquivo">
                                <img src="assets/images/grey-paperclip.svg" alt="">
                                <span>{{xmlFile.name}}</span>
                            </div>
                            <img src="assets/images/red-trashcan.svg" alt="" (click)="eraseXmlFile()">
                        </div>
                    </div>
                    <div class="col-6 d-flex align-items-end">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
