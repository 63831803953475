import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-cancelar-liberacao',
  templateUrl: './modal-cancelar-liberacao.component.html',
  styleUrls: ['./modal-cancelar-liberacao.component.scss']
})
export class ModalCancelarLiberacaoComponent implements OnInit {

  public modalRef!: BsModalRef;
  public justificativas = ['justificativa 1', 'justificativa 2', 'Outros'];

  @Input() public modalTitle?: string;
  @Input() public negativeText?: string;
  @Input() public positiveText?: string;

  @Output() public onChoosePositiveAction: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onChooseNegativeAction: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('modalRespostaPositivaNegativa', {static: false}) modal!: TemplateRef<any>;


  constructor(
    private modalService: BsModalService,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {

  }

  public openModalForConfirmOrRefuse(): void{
    this.modalRef = this.modalService.show(this.modal, {
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }

  public closeModal(): void{
    this.modalService.hide();
  }

  public negativeAction(): void{
    this.onChooseNegativeAction.emit();
    this.closeModal();
  }

  public positiveAction(justificativa: string): void{
    if(!justificativa?.length){
      return;
    }
    this.onChoosePositiveAction.emit(justificativa);
    this.closeModal();
  }

}
