<td *ngIf="tipoDocumento">
    {{tipoDocumento.value?.nomeDocumento ?? tipoDocumento.value?.tipoDocumento}}<span class="text-danger" *ngIf="!(isAttachmentOptionSomehowBlocked) && tipoDocumento?.value?.obrigatorio">*</span>
</td>
<td *ngIf="nomeArquivo">
    {{nomeArquivo.value ?? ''}}
</td>
<td>
    <span class="attach-button" (click)="arquivoDocumentoInput.click()" [ngClass]="{'disabled-attachment': (isAttachmentOptionSomehowBlocked || finishedSolicitation)}">
    ANEXAR ARQUIVO
  </span>
    <input type="file" hidden #arquivoDocumentoInput (change)="updateFile($event.target)" [accept]="getAllowedFileExtensions()" [disabled]="isAttachmentOptionSomehowBlocked || finishedSolicitation">
</td>
<td *ngIf="dataHoraInclusao">
    {{(dataHoraInclusao.value | date: "dd/MM/yyyy HH:mm") ?? '__/__/____'}}
</td>
<td class="attached-file-buttons" *ngIf="arquivoDocumento">
    <button [disabled]="isDisplayButtonDisabled" (click)="displayFile()">
    <img src="assets/images/expand.svg" alt="">
  </button>
    <button [disabled]="isDeleteButtonDisabled" (click)="deleteFile()">
    <img src="assets/images/red-trashcan.svg" alt="">
  </button>
    <button [disabled]="isDownloadButtonDisabled" (click)="downloadFile()">
    <img src="assets/images/save-file.svg" alt="">
  </button>
</td>
<ng-content id="additionalCell"></ng-content>
