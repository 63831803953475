<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Documento</span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>

    <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-3">
                    <div class="col">
                        <label>Tipo do Documento: *</label>
                        <select class="custom-select" formControlName="tipoDocumento" #docselect
                          [ngClass]="{'border border-danger': form.get('tipoDocumento')?.invalid && !form.get('tipoDocumento')?.pristine}"
                        >
                            <option [ngValue]="null" selected disabled>Selecione</option>
                            <option *ngFor="let documentType of documentTypes" [ngValue]="documentType.nome">{{documentType.nome}}</option>
                        </select>
                        <div *ngIf="form.get('tipoDocumento')?.errors && !form.get('tipoDocumento')?.pristine">
                          <p class="text-danger" *ngIf="form.get('tipoDocumento')?.errors?.['required']">
                            Campo de preenchimento obrigatório!
                          </p>
                        </div>
                    </div>
                    <div class="col">
                        <label>N° Documento: *</label>
                        <div class="input-group mb-3">
                            <input class="form-control" formControlName="numeroDocumento" [maxlength]="50" #numDoc type="text"
                              placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
                              [mask]="mask" [specialCharacters]="specialCharsForMask"
                              [ngClass]="{'border border-danger': form.get('numeroDocumento')?.invalid && !form.get('numeroDocumento')?.pristine}"
                            >
                        </div>
                        <div *ngIf="form.get('numeroDocumento')?.errors && !form.get('numeroDocumento')?.pristine">
                          <p class="text-danger" *ngIf="form.get('numeroDocumento')?.errors?.['required']">
                            Campo de preenchimento obrigatório!
                          </p>
                          <p class="text-danger" *ngIf="form.get('numeroDocumento')?.errors?.['mask']">
                            Preencher no formato correto!
                          </p>
                        </div>
                    </div>
                </div>
                <div class="row d-flex">
                  <div class="col-3 action-button-group">
                    <button class="action-primary-button" [disabled]="form.invalid" (click)="searchContainer()">PESQUISAR</button>
                  </div>
                  <div class="col-9 d-flex align-items-end justify-content-end">
                    <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                  </div>
                </div>
              </div>
        </form>
    </div>
</div>
