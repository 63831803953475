<div [auth]="this.visualizacao">
    <app-title [title]="title" [subtitle]='subtitle'></app-title>
    <app-documento-estimativa-calculo (onChangeDocumentTypeOnSelect)="changeDocumentType($event)" (onSearch)="searchDocumentRegisterByButton($event)" [fieldSettings]="fieldSettings" (onUploadXml)="getValuesFromXML($event)" (onEraseXml)="clearFormFields()"
        [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration" [visualizar]="visualizar"></app-documento-estimativa-calculo>
    <div [hidden]="!isSearchDoneOrXMLUploaded">
        <app-cliente-estimativa-calculo [fieldSettings]="fieldSettings" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration" (onUpdateClient)="setAttachedDocumentAsRequiredOrNotByClientDivergencyAndUserType()" [visualizar]="visualizar"></app-cliente-estimativa-calculo>
        <app-dados-complementares-estimativa-calculo [fieldSettings]="fieldSettings" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration" [visualizar]="visualizar"></app-dados-complementares-estimativa-calculo>
        <app-relacao-conteiner-estimativa-calculo [fieldSettings]="fieldSettings"
          [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
          (onEraseConteiner)="eraseConteiner($event)" [visualizar]="visualizar"
          (onSearchShipAndTripByContainerNumber)="searchShipAndTripByContainerNumber($event)"
          [selectedDocumentType]="selectedDocumentType"
          (onUpdateContainerRelation)="updateArvorePartes()"
          [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
          [isExpDocument]="isExpDocument"
        ></app-relacao-conteiner-estimativa-calculo>
        <app-arvore-partes-estimativa-calculo *ngIf="shouldBlockPartQuantityEditionByDocumentType"
              [mascara]="maskForArvorePartes" [camposFormulario]="fieldSettings"
              (dataFormOut)="{}" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
              [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
              [isImpDocument]="isImpDocument" [isExpDocument]="isExpDocument"
            ></app-arvore-partes-estimativa-calculo>
        <app-documentos-anexados (onDownloadFileToDisplay)="downloadFileToDisplay($event)" (onDownloadFileToSave)="downloadFileToSave($event)" (onSendAttachedFiles)="setAttachedDocumentAsRequiredOrNotByClientDivergencyAndUserType()" [visualizar]="visualizar"
            [isInsideModal]="!!visualizar"></app-documentos-anexados>
        <div class="d-flex mt-5">
            <div class="action-button-group action-quadruple-buttons">
                <!-- <button [auth]="this.salvarDocParte" class="action-secondary-button" *ngIf="!shouldBlockPartQuantityEditionByDocumentType" (click)="{}" [disabled]="!isSalvarDocumentoParteEnabled || visualizar">SALVAR DOCUMENTO PARTE</button> -->
                <button [auth]="this.cancelar" class="action-secondary-button" (click)="cancelDocumentRegister()" [disabled]="visualizar">CANCELAR</button>
                <button [auth]="this.salvarRascunho " class="action-secondary-button" (click)="saveDraft()" [disabled]="!isSearchDoneOrXMLUploaded || !isSaveDraftButtonEnabled || visualizar">SALVAR RASCUNHO</button>
                <button [auth]="this.memoriaCalculo" class="action-primary-button" (click)="sendMemoriaCalculo()" [disabled]="!areAllFormsValid() || visualizar">MEMÓRIA DE CÁLCULO</button>
                <button [auth]="this.liberacaoFinanceira" class="action-primary-button" (click)="sendFinancialLiberation()" [disabled]="!areAllFormsValid() || !isClienteFaturarAPrazo || visualizar">LIBERAÇÃO FINANCEIRA</button>
            </div>
        </div>
    </div>

    <app-extrair-dados-siscomex></app-extrair-dados-siscomex>
    <app-selecao-conteineres-divergentes #modalDivergenciaConteineres (onConfirmSelection)="confirmContainerPreviousShipping()">
    </app-selecao-conteineres-divergentes>
</div>

<app-modal-mensagem #modalMensagemErrosIntegracao></app-modal-mensagem>

<app-modal-ok #modalOkPesquisa [modalTitle]="'Solicitação já existente'" (onChooseNegativeAction)="hideForms()" (onOkAction)="confirmCreateDocumentRegister()">
    <div class="row mb-3">
        <div class="col">
            <p>
                {{textoConfirmarCriarSolicitacaoExistente}}
            </p>
        </div>
    </div>
</app-modal-ok>

<app-modal-ok #modalOkPesquisaXml [modalTitle]="'Solicitação já existente'" (onChooseNegativeAction)="hideForms()" (onOkAction)="confirmCreateIntegrationsFromXML()">
    <div class="row mb-3">
        <div class="col">
            <p>
                {{textoConfirmarCriarSolicitacaoExistente}}
            </p>
        </div>
    </div>
</app-modal-ok>

<ng-template #modalAlertaErrosIntegracao>
  <div class="modal-content">
      <div class="modal-header">
          <span class="modal-title" id="exampleModalLabel"></span>
          <button type="button" class="btn close btn-close" data-bs-dismiss="modal" aria-label="close" (click)="closeModal()">&times;</button>
      </div>
      <div class="modal-body">
          <ul>
              <li *ngFor="let error of integrationErrors">{{error}}</li>
          </ul>
      </div>
  </div>
</ng-template>
