import { MaskPipe } from 'ngx-mask';
import { TipoVeiculoDocumentoTransporte } from 'src/app/models/TipoVeiculoDocumentoTransporte';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { DadosDocumentoTransporteCCT } from '../../../../models/EntregaDueContingencia/EntregaDocumentoTransporte/DadosDocumentoTransporteCCT';
import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, UntypedFormControl } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DocumentCustomPattern } from 'src/app/shared/utils/constantes-documentos';
import { RelacaoConteineresCCT } from 'src/app/models/EntregaDueContingencia/EntregaDocumentoTransporte/RelacaoConteineresCCT';
import { DocumentosService } from 'src/app/services/documentos.service';
import { DadosEntregaDocumentoTransporteCCTDTO } from 'src/app/models/EntregaDueContingencia/EntregaDocumentoTransporte/ConteinerEntregaDocumentoTransporte';
import { RelacaoConteinerCCTDocumentoTransporte } from 'src/app/models/documento-transporte/LacreConteinerRecepcaoDocumentoTransporte';
import { LinhaGridRelacaoConteinerCctDocumentoTransporteComponent } from 'src/app/shared/components/documento-transporte/linha-grid-relacao-conteiner-cct-documento-transporte/linha-grid-relacao-conteiner-cct-documento-transporte.component';

@Component({
  selector: 'app-dados-documento-transporte-cct-edt',
  templateUrl: './dados-documento-transporte-cct-edt.component.html',
  styleUrls: ['./dados-documento-transporte-cct-edt.component.scss']
})
export class DadosDocumentoTransporteCCTEDTComponent implements OnInit {

  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;
  public tipoVeiculo!: UntypedFormControl;
  private colorTheme = 'theme-dark-blue';
  public bsConfig?: Partial<BsDatepickerConfig>;
  private locale = 'pt-br';
  public namePattern = DocumentCustomPattern.NAME_PATTERN;
  public relacaoConteineresCCTDisplay!: RelacaoConteinerCCTDocumentoTransporte[];
  public vehicleTypes!: TipoVeiculoDocumentoTransporte[];
  public quantidadeReboques: number = 0;
  @ViewChildren(LinhaGridRelacaoConteinerCctDocumentoTransporteComponent) gridConteineres!: QueryList<LinhaGridRelacaoConteinerCctDocumentoTransporteComponent>;

  constructor(
    private fb: UntypedFormBuilder,
    private localeService: BsLocaleService,
    private documentosService: DocumentosService,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private changeDetector: ChangeDetectorRef,
    private maskPipe: MaskPipe
  ) { }

  public ngOnInit(): void {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.validation();
    this.relacaoConteineresCCTDisplay = [];
    this.getVehicleTypes();
  }

  private validation(): void{
    this.form = this.fb.group({
      dataEmissao: [''],
      situacao: [''],
      localDestinoPrevisto: [''],
      cnpjCpfTransportador: ['', [this.validateCpfOrCnpj.bind(this)]],
      cpfCondutor: ['', [this.validateCpfOrCnpj.bind(this)]],
      nomeCondutor: [''],
      placaCavalo: [''],
      placaReboque1: [''],
      placaReboque2: [''],
      placaReboque3: [''],
      dadosVeiculoConferem: [''],
      dadosConteinerConferem: [''],
      dadosEmbalagemConferem: [''],
      dadosUnidadeTransporteConferem: [''],
      relacaoConteineresCCT: [''],
      pesoAferido: [''],
      codigoURFDestino: [''],
      cnpjRespDestino: [''],
      codigoRADestino: [''],
    });
    this.tipoVeiculo = new UntypedFormControl();
    this.tipoVeiculo?.valueChanges.subscribe(
      (tipoVeiculo) => {
        this.changeQuantidadeReboques(tipoVeiculo);
      }
    );
  }

  private getVehicleTypes(): void{
    this.documentosService.getVehicleTypesForDocumentoEntregaTransporte()
      .subscribe({
        next: (vehicletypes) => {
          this.vehicleTypes = [...vehicletypes];
          this.changeDetector.detectChanges();
        },
        error: (err) => {
          console.log(err);
        }
      });
  }

  private changeQuantidadeReboques(tipoVeiculoDocumentoTransporte?: TipoVeiculoDocumentoTransporte): void{
    this.quantidadeReboques = tipoVeiculoDocumentoTransporte?.quantidadeReboque ?? 0;
    for(let plate: number = 1; plate <= 3; plate++){
      if(plate > this.quantidadeReboques){
        this.form.get(`placaReboque${plate}`)?.setValue('');
      }
    }
  }

  public clear(): void{
    this.form.reset();
    this.relacaoConteineresCCTDisplay = [];
  }

  public setFormValuesFromReceivedCCTTransportDocumentData(dadosDocumentoTransporteCCT: DadosEntregaDocumentoTransporteCCTDTO | undefined | null): void{
    if(!dadosDocumentoTransporteCCT){
      return;
    }
    this.tipoVeiculo?.setValue(
      this.vehicleTypes.find(veiculo => veiculo.quantidadeReboque == (
        (dadosDocumentoTransporteCCT.placaReboque1?.length ? 1 : 0) +
        (dadosDocumentoTransporteCCT.placaReboque2?.length ? 1 : 0) +
        (dadosDocumentoTransporteCCT.placaReboque3?.length ? 1 : 0)
        ))
    );
    this.form.patchValue(dadosDocumentoTransporteCCT);
    if (dadosDocumentoTransporteCCT.dataEmissao) {
      this.form.get('dataEmissao')?.setValue(new Date(dadosDocumentoTransporteCCT.dataEmissao))
    }
    this.form.get('cpfCondutor')?.setValue(this.maskPipe.transform(dadosDocumentoTransporteCCT?.cpfCondutor!, '000.000.000-00'));
    this.form.get('cpfCnpjTransportador')?.setValue(this.maskPipe.transform(dadosDocumentoTransporteCCT?.cnpjCpfTransportador!, '00.000.000/0000-00||000.000.000-00'));
    // this.form.get('situacao')?.setValue(dadosDocumentoTransporteCCT.situacao);
    // this.form.get('localDestinoPrevisto')?.setValue(dadosDocumentoTransporteCCT.localDestinoPrevisto);
    // this.form.get('cnpjCpfTransportador')?.setValue(dadosDocumentoTransporteCCT.cnpjCpfTransportador);
    // this.form.get('cpfCondutor')?.setValue(dadosDocumentoTransporteCCT.cpfCondutor);
    // this.form.get('nomeCondutor')?.setValue(dadosDocumentoTransporteCCT.nomeCondutor);
    // this.form.get('placaCavalo')?.setValue(dadosDocumentoTransporteCCT.placaCavalo);
    // this.form.get('placaReboque1')?.setValue(dadosDocumentoTransporteCCT.placaReboque1);
    // this.form.get('placaReboque2')?.setValue(dadosDocumentoTransporteCCT.placaReboque2);
    // this.form.get('placaReboque3')?.setValue(dadosDocumentoTransporteCCT.placaReboque3);
    // this.form.get('dadosVeiculoConferem')?.setValue(dadosDocumentoTransporteCCT.dadosVeiculoConferem);
    // this.form.get('dadosConteinerConferem')?.setValue(dadosDocumentoTransporteCCT.dadosConteinerConferem);
    // this.form.get('dadosEmbalagemConferem')?.setValue(dadosDocumentoTransporteCCT.dadosEmbalagemConferem);
    // this.form.get('dadosUnidadeTransporteConferem')?.setValue(dadosDocumentoTransporteCCT.dadosUnidadeTransporteConferem);
    // this.form.get('relacaoConteineresCCT')?.setValue(dadosDocumentoTransporteCCT.relacaoConteinerLacresCCT);
    this.relacaoConteineresCCTDisplay = dadosDocumentoTransporteCCT.relacaoConteinerLacresCCT ? dadosDocumentoTransporteCCT.relacaoConteinerLacresCCT:[];
    this.changeDetector.detectChanges();
  }

  public getDocumentoCCTFromForm(): DadosEntregaDocumentoTransporteCCTDTO{
    let documentoCCT: DadosEntregaDocumentoTransporteCCTDTO = this.form.getRawValue();
    documentoCCT.relacaoConteinerLacresCCT = this.getContainers();
    return documentoCCT;
  }

  public checkValidCPFOrCNPJ(cpfCnpj: string): string | null{
    if(!cpfCnpj || !cpfCnpj.length){
      return null;
    }
    let cleanCpfCnpj = this.verificacaoDocumentosService.eraseMask(cpfCnpj);
    if(cleanCpfCnpj.length == 11){
      return this.verificacaoDocumentosService.calculateValidCPF(cleanCpfCnpj);
    }
    else if(cleanCpfCnpj.length == 14){
      return this.verificacaoDocumentosService.calculateValidCNPJ(cleanCpfCnpj);
    }
    else{
      return this.verificacaoDocumentosService.checkCPFOrCNPJ(cleanCpfCnpj, 'fisica');
    }
  }

  public validateCpfOrCnpj(control: AbstractControl): { invalidDocument: string } | null {
    let controlValue: string = control?.value;
    if (!controlValue?.length) {
      return null;
    }
    let error: string | null;
    let clearDocument: string = this.verificacaoDocumentosService.eraseMask(controlValue);
    if (clearDocument.length == 11) {
      error = this.verificacaoDocumentosService.calculateValidCPF(clearDocument);
    }
    else if (clearDocument.length == 14) {
      error = this.verificacaoDocumentosService.calculateValidCNPJ(clearDocument);
    }
    else {
      error = 'O nº do CNPJ/CPF informado é inválido. Favor verificar!';
    }
    if (error?.length) {
      return { invalidDocument: error };
    }
    return null;
  }

  public getContainers(): RelacaoConteinerCCTDocumentoTransporte[] {
    return this.gridConteineres?.map(
      (container) => container.getContainer()
    ) ?? [];
  }

  public areAllContainersValid(): boolean {
    if (!this.gridConteineres?.length) {
      return true;
    }
    return this.gridConteineres?.map(
      (container) => container.isContainerValid()
    )?.every(line => line);
  }

  public setDivergency(dadosVeiculoConferem: boolean, dadosConteinerConferem: boolean, dadosEmbalagemConferem: boolean, dadosUnidadeTransporteConferem: boolean): void{
    this.form.get('dadosVeiculoConferem')?.setValue(dadosVeiculoConferem ? 'S' : 'N');
    this.form.get('dadosConteinerConferem')?.setValue(dadosConteinerConferem ? 'S' : 'N');
    this.form.get('dadosEmbalagemConferem')?.setValue(dadosEmbalagemConferem ? 'S' : 'N');
    this.form.get('dadosUnidadeTransporteConferem')?.setValue(dadosUnidadeTransporteConferem ? 'S' : 'N');
  }

  public isFormCCTValid(): boolean {
    return !this.form.invalid && this.areAllContainersValid();
  }
}
