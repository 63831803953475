import { NgxSpinnerService } from 'ngx-spinner';
import { RecepcaoConteinerTransshipComponent } from './recepcao-conteiner-transship/recepcao-conteiner-transship.component';
import { RecepcaoConteinerComponent } from './recepcao-conteiner/recepcao-conteiner.component';
import { RecepcaoDocumentoTransporteComponent } from './recepcao-documento-transporte/recepcao-documento-transporte.component';
import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AbaSubpagina } from 'src/app/models/AbaSubpagina';
import { RecepcaoNotaFiscalComponent } from './recepcao-nota-fiscal/recepcao-nota-fiscal.component';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';

@Component({
  selector: 'app-recepcao-due-contigencia',
  templateUrl: './recepcao-due-contigencia.component.html',
  styleUrls: ['./recepcao-due-contigencia.component.scss']
})
export class RecepcaoDueContigenciaComponent implements OnInit, AfterViewInit {

  public formOut!: UntypedFormGroup;
  public selectedPage!: string;
  public readonly subpageTabs: AbaSubpagina[] = [
    {
      titulo: 'Nota Fiscal (NFE)',
      path: AbaSubpaginaRecepcaoDueContingencia.RECEPCAO_NOTA_FISCAL,
      authRoles: new AuthRoles(Telas.recepcaoNotaFiscal, Permissoes.visualizacao)
    },
    {
      titulo: 'Contêiner (Trânsito Simplificado)',
      path: AbaSubpaginaRecepcaoDueContingencia.RECEPCAO_CONTEINER,
      authRoles: new AuthRoles(Telas.recepcaoTransitoSimplificado, Permissoes.visualizacao)

    },
    {
      titulo: 'Documento de Transporte',
      path: AbaSubpaginaRecepcaoDueContingencia.RECEPCAO_DOCUMENTO_TRANSPORTE,
      authRoles: new AuthRoles(Telas.recepcaoDocumentoTransporte, Permissoes.visualizacao)

    },
    {
      titulo: 'Contêineres Transship',
      path: AbaSubpaginaRecepcaoDueContingencia.RECEPCAO_CONTEINER_TRANSSHIP,
      authRoles: new AuthRoles(Telas.recepcaoConteinerTransship, Permissoes.visualizacao)
    }
  ];
  public dataSendingBlocked: boolean = false;

  @ViewChild(RecepcaoNotaFiscalComponent) recepcaoNotaFiscal!: RecepcaoNotaFiscalComponent;
  @ViewChild(RecepcaoConteinerComponent) recepcaoConteiner!: RecepcaoConteinerComponent;
  @ViewChild(RecepcaoDocumentoTransporteComponent) recepcaoDocumentoTransporte!: RecepcaoDocumentoTransporteComponent;
  @ViewChild(RecepcaoConteinerTransshipComponent) recepcaoConteinerTransship!: RecepcaoConteinerTransshipComponent;

  public readonly visualizacaoRecepcaoNotaFiscal = new AuthRoles(Telas.recepcaoNotaFiscal, Permissoes.visualizacao);
  public readonly visualizacaorecepcaoConteiner = new AuthRoles(Telas.recepcaoTransitoSimplificado, Permissoes.visualizacao)
  public readonly visualizacaorecepcaoDocumentoTransporte = new AuthRoles(Telas.recepcaoDocumentoTransporte, Permissoes.visualizacao)
  public readonly visualizacaorecepcaoConteinerTransship = new AuthRoles(Telas.recepcaoConteinerTransship, Permissoes.visualizacao)

  constructor(
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private changeDetector: ChangeDetectorRef
    ){}

  public ngOnInit(): void {
    this.selectedPage = AbaSubpaginaRecepcaoDueContingencia.RECEPCAO_NOTA_FISCAL;
    this.validation();
  }

  public ngAfterViewInit(): void {
  }

  public validation(): void {
    this.formOut = this.fb.group({
      id: [null],
    });

  }

  public changeSelectedPage(pageName: string | AbaSubpaginaRecepcaoDueContingencia): void{
    if(this.selectedPage == pageName){
      return;
    }
    this.selectedPage = pageName;
  }

  public clear(): void{
    this.spinner.show('limpar');
    setTimeout(() => {
      this.spinner.hide('limpar');
    }, 1500);
    if(this.recepcaoNotaFiscal){
      this.recepcaoNotaFiscal.clear();
    }
    if(this.recepcaoConteinerTransship){
      this.recepcaoConteinerTransship.clear();
    }
  }

  public sendDataToCCT(): void{
    if(this.recepcaoNotaFiscal){
      this.recepcaoNotaFiscal.checkForSealDivergences();
    }
  }

  public switchFormValid(valid: boolean): void{
    this.dataSendingBlocked = !valid;
    this.changeDetector.detectChanges();
  }
}

enum AbaSubpaginaRecepcaoDueContingencia{
  RECEPCAO_NOTA_FISCAL = 'recepcao-nota-fiscal',
  RECEPCAO_CONTEINER = 'recepcao-conteiner',
  RECEPCAO_DOCUMENTO_TRANSPORTE = 'recepcao-documento-transporte',
  RECEPCAO_CONTEINER_TRANSSHIP = 'recepcao-conteiner-transship'
}
