import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast.service';
import { Permissoes } from 'src/app/contants/permissoes';
import { Telas } from 'src/app/contants/telas';
import { AuthRoles } from 'src/app/models/authRoles';
import { Campo } from 'src/app/models/Campo';
import { EntregaDue, RelacaoConteinerEntregaConteiner } from 'src/app/models/EntregaDueContigencia/EntregaRelacaoConteiner/RelacaoConteinerEntregaConteiner';
import { ConteinerIMP } from 'src/app/models/SolicitacaoLiberacaoIMP/ConteinerIMP';
import { EntregaDueContigenciaService } from 'src/app/services/entrega-due-contigencia.service';
import { EntregaDueConteiner } from 'src/app/models/EntregaDueContigencia/EntregaRelacaoConteiner/EnvioEntregaDueConteiner';

@Component({
  selector: 'app-entrega-relacao-conteiner',
  templateUrl: './entrega-relacao-conteiner.component.html',
  styleUrls: ['./entrega-relacao-conteiner.component.scss']
})
export class EntregaRelacaoConteinerComponent implements OnInit {
  public form!: UntypedFormGroup;
  public isCollapsed = false;
  public readonly enviarCCT = new AuthRoles(Telas.entregaDueConteiner, Permissoes.enviarCCT)
  public readonly salvar = new AuthRoles(Telas.entregaDueConteiner, Permissoes.salvar)

  // public relacaoConteinerDisplay!: EntregaDue;
  // public relacaoConteinerForm!: EntregaDue;

  @Input() camposformulario!: Campo[];
  public blockSending: boolean = true;
  public areAllItemsSelected: boolean = false;
  public anyItemDelivered: boolean = false;
  public selectedContainers: boolean[] = [];

  constructor(
    private entregaDueContingenciaService: EntregaDueContigenciaService,
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.validation();
  }

  public validation(): void {
    this.form = this.fb.group({
      navio: ['', [Validators.required]],
      viagem: ['', [Validators.required]],
      qtdConteineres: [''],
      qtdConteineresEnviados: [''],
      qtdConteineresErrosEnviados: [''],
      identificador: [''],
      usuario: [''],
      conteineres: [[]]
    });
  }

  public clear(): void {
    this.form.reset();
    this.updateArrayForContainerSelection();
  }

  public searchConteinerData() {
    let navio = this.form.get('navio')?.value as string;
    let viagem = this.form.get('viagem')?.value as string;
    if (!navio?.length || !viagem?.length) {
      return;
    }
    this.areAllItemsSelected = false;
    this.spinner.show();
    this.entregaDueContingenciaService.searchForContainerOnForEntregaDUE(navio, viagem)
      .subscribe({
        next: (conteiner) => {
          this.spinner.hide();
          this.blockSending = false;
          let conteinerData = conteiner.data as EntregaDueConteiner;
          this.setValueFromReceivedEntregaDUE(conteinerData);
          console.log(conteinerData);

          conteinerData?.conteineres?.forEach((c) => {
            console.log(c.retornoApiCct)
            if (c.retornoApiCct !== null &&
              c.retornoApiCct !== undefined &&
              c.retornoApiCct.length > 0) {
              this.anyItemDelivered = true
            }

          });

        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.blockSending = true;
          console.log(err);
          let errorMessage = err.error as ErrorResponse;
          if (errorMessage?.errors?.length) {
            errorMessage?.errors?.forEach(
              (error) => {
                if (error?.length) {
                  this.toastService.error(error);
                }
              }
            );
          }
          if (errorMessage?.data) {
            this.setValueFromReceivedEntregaDUE(errorMessage?.data);
          }
        },
        complete: () => {
          // this.changeDetector.detectChanges();
        }
      });
  }

  public sendDataToCCT(): void {
    if (this.blockSending || this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.entregaDueContingenciaService.sendDataToCCTForEntregaDUE(this.getEntregaDUEForSaving())
      .subscribe({
        next: (response) => {
          response.message?.forEach(
            (message) => {
              if (message && message.length) {
                this.toastService.success(message);
              }
            }
          );
          if (response.data) {
            this.setValueFromReceivedEntregaDUE(response.data);
          }
          this.blockSending = true;
          this.spinner.hide();
          this.searchConteinerData()
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          let errorMessage = err.error as ErrorResponse;
          if (errorMessage?.errors?.length) {
            errorMessage?.errors?.forEach(
              (error) => {
                if (error?.length) {
                  this.toastService.error(error);
                }
              }
            );
          }
          if (errorMessage?.data) {
            this.setValueFromReceivedEntregaDUE(errorMessage?.data);
          }
        }
      });
  }

  public saveEntregaDUE(): void {
    if (this.blockSending || this.form.invalid) {
      return;
    }
    this.spinner.show();
    this.entregaDueContingenciaService.saveEntregaDUE(this.getEntregaDUEForSaving())
      .subscribe({
        next: (response) => {
          response.message?.forEach(
            (message) => {
              if (message && message.length) {
                this.toastService.success(message);
              }
            }
          );
          if (response.data) {
            this.setValueFromReceivedEntregaDUE(response.data);
          }
          this.blockSending = true;
          this.spinner.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = err.error as ErrorResponse;
          if (errorMessage?.errors?.length) {
            errorMessage?.errors?.forEach(
              (error) => {
                if (error?.length) {
                  this.toastService.error(error);
                }
              }
            );
          }
          if (errorMessage?.data) {
            this.setValueFromReceivedEntregaDUE(errorMessage?.data);
          }
        }
      });
  }

  public getEntregaDUEForSaving(): EntregaDueConteiner {
    let entrega: EntregaDueConteiner = this.form.getRawValue();
    entrega.conteineres = entrega.conteineres?.filter(
      (conteiner, index) => this.selectedContainers[index]
    ) ?? [];
    return entrega;
  }

  public switchSelectionForAll(): void {
    this.areAllItemsSelected = !this.areAllItemsSelected;
    this.updateSelectionForAll(this.areAllItemsSelected);
  }

  private updateSelectionForAll(selected: boolean): void {
    this.selectedContainers = this.selectedContainers.map(
      (container, index) =>
      {
        let cont = this.form.get('conteineres')?.value
        if(cont[index].retornoApiCct !== null) return false
        else return true;
      }
    ) ?? [];
  }

  private updateArrayForContainerSelection(): void {
    this.selectedContainers = (this.form.getRawValue() as EntregaDueConteiner).conteineres?.map(
      (container) => false
    ) ?? [];
  }

  public switchSingleSelection(index: number): void {
    this.selectedContainers[index] = !this.selectedContainers[index];
    this.areAllItemsSelected = false;
  }

  private setValueFromReceivedEntregaDUE(entrega: EntregaDueConteiner): void {
    this.form.patchValue(entrega);
    if (entrega?.conteineres?.length && entrega?.qtdConteineres != entrega?.conteineres?.length) {
      this.form.get('qtdConteineres')?.setValue(entrega?.conteineres?.length);
    }
    this.updateArrayForContainerSelection();
  }

  public get isEnviarCCTButtonEnabled(): boolean {
    return !this.blockSending &&
      !this.form.invalid &&
      !!this.form.value.conteineres?.length &&
      !!this.selectedContainers.some(conteiner => conteiner);
  }

  public get isSalvarButtonEnabled(): boolean {
    return !this.form.invalid &&
      !!this.form.value.conteineres?.length &&
      !!this.selectedContainers.some(conteiner => conteiner);
  }
}
