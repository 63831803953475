import { UserTypeEnum } from '../../models/UserTypeEnum';

import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings, UserManagerSettingsStore } from 'oidc-client-ts';
import { Modulo } from 'src/app/contants/modulo';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  private manager = new UserManager(getClientSettings(new UserManagerSettingsStore({
    authority: environment.AUTHENTICATION.authority,
    client_id: environment.AUTHENTICATION.client_id,
    client_secret: environment.AUTHENTICATION.client_secret,
    redirect_uri: environment.AUTHENTICATION.redirect_uri,
    post_logout_redirect_uri: environment.AUTHENTICATION.post_logout_redirect_uri,
    response_type: environment.AUTHENTICATION.response_type,
    response_mode: 'query',
    filterProtocolClaims: environment.AUTHENTICATION.filterProtocolClaims,
    loadUserInfo: environment.AUTHENTICATION.loadUserInfo,
    automaticSilentRenew: environment.AUTHENTICATION.automaticSilentRenew,
    silent_redirect_uri: environment.AUTHENTICATION.silent_redirect_uri,
    scope: environment.AUTHENTICATION.scope
  })));

  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();

  constructor(private http: HttpClient,
    private route: Router,
    private helper: JwtHelperService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  login() {
    this._authNavStatusSource.next(false);
    this.route.navigate(["login"]);
  }

  isAuthenticated(): boolean {
    if (!this.getUser()) {
      return false;
    }

    if (this.helper.isTokenExpired(this.getUser()?.access_token)) {
      return false
    }
    else {
      return true;
    }
  }

  get authorizationHeaderValue(): string {
    return `${this.getUser()?.token_type} ${this.getUser()?.access_token}`;
  }

  get name(): string | undefined {
    return this.checkIfUserIsNotEmpty(this.getUser()) ? this.getUser()?.profile?.name : '';
  }

  get userName(): string | undefined {
    return this.checkIfUserIsNotEmpty(this.getUser()) ? this.getUser()?.profile?.userName : '';
  }

  get fullName(): string | undefined {
    return this.checkIfUserIsNotEmpty(this.getUser()) ? this.getUser()?.profile?.fullName : '';
  }

  get userEmail(): string | undefined {
    return this.checkIfUserIsNotEmpty(this.getUser()) ? this.getUser()?.profile?.emailAddress : '';
  }

  get ehUsuarioInterno(): boolean{
    if (this.isUsuarioRoot()) return true;
    return this.getUser()?.profile?.userType.toLocaleUpperCase() == UserTypeEnum[UserTypeEnum.Internal].toLocaleUpperCase() ? true : false
  }

  private checkIfUserIsNotEmpty(user: any): boolean{
    if(!user){
      return false;
    }
    if(JSON.stringify(user) == JSON.stringify({})){
      return false;
    }
    return !!user;
  }

  async signout() {
    localStorage.clear();
    this.login();
  }

  public getUser() {
    let user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
    else {
      return JSON.parse("{}");
    }
  }

  public getRoles() {
    let roles = localStorage.getItem('roles');
    if (roles) {
      return JSON.parse(roles);
    }
    else {
      return JSON.parse("{}");
    }
  }

  public getTokenExpirationDate() {
    return this.helper.getTokenExpirationDate(this.getUser().access_token)
  }

  public isTimeToRefreshToken() {

    var dataToken = this.getTokenExpirationDate()!.toLocaleString('pt-BR');
    if (dataToken == null || dataToken == undefined) {
      return false;
    }
    var now = new Date().toLocaleString('pt-BR');

    var ms = moment(dataToken, "DD/MM/YYYY HH:mm:ss").diff(moment(now, "DD/MM/YYYY HH:mm:ss"));

    if (ms < environment.AUTHENTICATION.timeToRefresh) {
      return true;
    }
    else {
      return false;
    }
  }

  public verificarAcessoModulo(): boolean {
    if (this.isUsuarioRoot()) return true;
    let roles = this.getRoles();
    return roles?.find((m: any) => m.Nome.toLocaleUpperCase() == Modulo.documental.toLocaleUpperCase()) !== undefined;

  }

  public verificarAcessoTela(tela: string): boolean {
 
    if (this.isUsuarioRoot()) return true;
    let roles = this.getRoles();
 
    return roles.find((m: any) => m.Nome.toLocaleUpperCase() == Modulo.documental.toLocaleUpperCase())?.Telas.find((t:any) => t.Nome.toLocaleUpperCase() == tela.toLocaleUpperCase()) !== undefined;
  }

  public verificarAcessoPermissao(tela: string, permissao: string): boolean {
   
    if (this.isUsuarioRoot()) return true;
    let roles = this.getRoles();
    return roles?.find((m:any) => m.Nome.toLocaleUpperCase() == Modulo.documental.toLocaleUpperCase())?.Telas.find((t:any) => t.Nome.toLocaleUpperCase() == tela.toLocaleUpperCase())?.Permissoes.find((p:any) => p.Nome.toLocaleUpperCase() == permissao.toLocaleUpperCase()) !== undefined
  }

  public isUsuarioRoot(): boolean {
    let user = this.getUser();
    return user?.profile?.name == 'root'
  }

  async completeAuthentication() {
    try{
      this._authNavStatusSource.next(this.isAuthenticated());
    }
    catch{
      this.login();
    }
  }



  async decodeToken(accessToken:string){
    const decodedToken = this.helper.decodeToken(accessToken);
    console.log("...");
    let user = new User({
      access_token: accessToken,
      refresh_token: '',
      scope: '',
      token_type: '',
      profile: decodedToken
    });

    let roles = await JSON.parse(decodedToken?.acessos);
    
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('roles', JSON.stringify(roles));
    this.setRedirectDocumental();
    this._authNavStatusSource.next(true);


  }
  public clearLocalStorage() {
    localStorage.clear();
  }
  async signin(matricula: string, senha: string) {
    this.spinner.show();

    return new Promise(async (resolve, reject) => {
      let URL = environment.AUTHENTICATION.authority
      const body = new HttpParams()
        .set('client_id', environment.AUTHENTICATION.client_id)
        .set('client_secret', environment.AUTHENTICATION.client_secret)
        .set('grant_type', environment.AUTHENTICATION.grant_type)
        .set('scope', environment.AUTHENTICATION.scope)
        .set('username', matricula)
        .set('password',  btoa(senha))
        .set('userType', 1)
      console.log(body)
      this.http.post(URL + '/connect/token', body).toPromise()
        .then(async (response: any) => {
          const decodedToken = this.helper.decodeToken(response.access_token);
          let user = new User({
            access_token: response.access_token,
            refresh_token: response.refresh_token,
            scope: response.scope,
            token_type: response.token_type,
            profile: decodedToken
          });

          let roles = await JSON.parse(decodedToken?.acessos);

          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('roles', JSON.stringify(roles));
          localStorage.setItem('Portal', "Documental");
          localStorage.setItem('redirectDocumental','false');
          this._authNavStatusSource.next(true);
          this.spinner.hide();

          resolve(true)
        }).catch((err) => {
          console.log(err);
          this.clearLocalStorage();
          this._authNavStatusSource.next(false);
          this.toastrService.error( "Usuário ou senha inválidos", "Erro!");
          this.spinner.hide();
          reject(false);
        });
    });
  }

  async redirectSignin(matricula: string, senha: string, userType:string): Promise<string> {
    this.spinner.show();

    return new Promise<string>(async (resolve, reject) => {
      let URL = environment.AUTHENTICATION.authority
      const body = new HttpParams()
        .set('client_id', environment.AUTHENTICATION.client_id)
        .set('client_secret', environment.AUTHENTICATION.client_secret)
        .set('grant_type', environment.AUTHENTICATION.grant_type)
        .set('scope', environment.AUTHENTICATION.scope)
        .set('username', matricula)
        .set('password', btoa(senha))
        .set('userType', userType)

      this.http.post(URL + '/connect/token', body).toPromise()
        .then(async (response: any) => {
          const decodedToken = this.helper.decodeToken(response.access_token);
          let user = new User({
            access_token: response.access_token,
            refresh_token: response.refresh_token,
            scope: response.scope,
            token_type: response.token_type,
            profile: decodedToken
          });

          localStorage.setItem('accessToken', response.access_token);

          let roles = await JSON.parse(decodedToken?.acessos);

          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('roles', JSON.stringify(roles));
          this._authNavStatusSource.next(true);
          this.spinner.hide();

          resolve(response.access_token); 
        }).catch((err) => {
          console.log(err);
          this.clearLocalStorage();
          this._authNavStatusSource.next(false);
          this.toastrService.error( "Usuário ou senha inválidos", "Erro!");
          this.spinner.hide();
          reject(err);
        });
    });
}

  public async storageDataAfterRefreshToken(response: any)
  {
      const decodedToken = this.helper.decodeToken(response.access_token);
      let user = new User({
        access_token: response.access_token,
        refresh_token: response.refresh_token,
        scope: response.scope,
        token_type: response.token_type,
        profile: decodedToken
      });

      let roles = await JSON.parse(decodedToken?.acessos);

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('roles', JSON.stringify(roles));
      this._authNavStatusSource.next(true);
  }

  public setRedirectDocumental()
  {
    localStorage.setItem('redirectDocumental', 'true');
    localStorage.setItem('Portal', "Tecon");
  }

  public isRedirectDocumental() : boolean
  {
    return localStorage.getItem('redirectDocumental') == 'true' ? true : false;
  }

  public isPortalTecon() : boolean
  {
    return localStorage.getItem('Portal') == 'Tecon' ? true : false;
  }
  refreshToken() {
      let URL = environment.AUTHENTICATION.authority
      const body = new HttpParams()
        .set('client_id', environment.AUTHENTICATION.client_id)
        .set('client_secret', environment.AUTHENTICATION.client_secret)
        .set('grant_type', "refresh_token")
        .set('refresh_token', this.getUser().refresh_token)

      return this.http.post(URL + '/connect/token', body);
  }

}

export function getClientSettings(authenticationConfigs: UserManagerSettings): UserManagerSettings {
  return authenticationConfigs;
}
