import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { RecepcaoNfDatConteinerComponent } from 'src/app/components/relatorios/relatorio-nf-dat-conteiner/recepcao-nf-dat-conteiner/recepcao-nf-dat-conteiner.component';
import { Permissoes } from 'src/app/contants/permissoes';
import { Telas } from 'src/app/contants/telas';
import { AuthRoles } from 'src/app/models/authRoles';

@Component({
  selector: 'app-relatorio-recepcao-nf-dat-conteiner',
  templateUrl: './relatorio-recepcao-nf-dat-conteiner.component.html',
  styleUrls: ['./relatorio-recepcao-nf-dat-conteiner.component.scss']
})
export class RelatorioRecepcaoNfDatConteinerComponent implements OnInit {
  public title: string = 'Recepção de NF, DAT, Contêiner por Período';
  public subtitle: string = '';
  public formOut!: UntypedFormGroup;
  @ViewChild(RecepcaoNfDatConteinerComponent) private recepcaoNfDatConteinerComponent!: RecepcaoNfDatConteinerComponent;

  public readonly visualizacao = new AuthRoles(Telas.relatoioRecepcaoNfDatConteiner, Permissoes.visualizacao)

  constructor(
    private spinner: NgxSpinnerService,
  ) { }

  public ngOnInit(): void {
  }

  async imprimirPdf() {
    await this.recepcaoNfDatConteinerComponent.downloadPDF(this.title);
  }

  async salvarExcel() {
    await this.recepcaoNfDatConteinerComponent.downloadExcel(this.title);
  }

  public isRecepcaoNfDatConteinerListNotEmpty(): boolean{
    return !!this.recepcaoNfDatConteinerComponent?.isRecepcaoNfDatConteinerListNotEmpty();
  }

  public clean() {
      this.recepcaoNfDatConteinerComponent?.clearFields();
  }
}
