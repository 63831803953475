<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Árvore de Partes <span class="text-danger" *ngIf="arvorePartesList?.length && !areAllDocumentoParteLinesValid">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
            <!--fa fa-chevron-down-->
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <div class="table-container">
          <table class="grid-table">
            <thead>
              <tr>
                <th>Nº do Contêiner</th>
                <th>Nº do Documento</th>
                <th>{{shouldBlockPartQuantityEditionByDocumentType ? 'Quantidade de Partes' : 'Canal do Documento'}}</th>
                <th *ngIf="!shouldBlockPartQuantityEditionByDocumentType">Situação Aduaneira DUE</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let arvorePartes of arvorePartesList; let indexArvorePartes = index" [canais]="canais"
                app-linha-grid-arvore-partes-exportacao [mascara]="mascara" [documentoParte]="arvorePartes"
                (onFirstEdit)="markEveryLineAstouched()" (onSendArvoreParte)="sendArvorePartesData()"
                [camposFormulario]="camposFormulario" [visualizar]="arvoreParteReadonly"
                [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
              ></tr>
            </tbody>
          </table>
        </div>
    </div>
</div>
