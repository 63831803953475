<ng-template #modalOkCancelar>
  <div class="modal-header">
    <h4>{{modalTitle}}</h4>
    <button class="btn-close close pull right"><span class="visualli-hidden"
      (click)="closeModal()">&times;</span></button>
  </div>
  <div class="modal-body">
    <form>
      <ng-content></ng-content>
      <div class="my-2 action-button-group action-double-buttons">
        <button class="action-secondary-button" (click)="negativeAction()">{{negativeText}}</button>
        <button class="action-primary-button"
           (click)="positiveAction()">
          {{positiveText}}
        </button>
      </div>
    </form>
  </div>
</ng-template>
