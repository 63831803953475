import { MaskPipe } from 'ngx-mask';
import { Mascara } from './../../../models/Mascara';
import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from '../../../models/HttpResponses/ErrorResponse';
import { SuccessResponse } from '../../../models/HttpResponses/SuccessResponse';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { TipoDocumentoSolicitacao } from '../../../models/TipoDocumentoSolicitacao';
import {
  Component,
  AfterContentInit,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  Input,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime, Observable } from 'rxjs';
import { Documento } from 'src/app/models/Documento';
import { DefaultMaskPattern, DocumentCustomPattern, DocumentMaskEnum, DocumentSpecialCharactersForMask } from 'src/app/shared/utils/constantes-documentos';
import { Campo } from 'src/app/models/Campo';
import { CampoNumeroDueComponent } from 'src/app/shared/components/campo-numero-due/campo-numero-due.component';


@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.scss'],
})
export class DocumentoComponent implements OnInit, AfterContentInit {
  public form!: UntypedFormGroup;
  public isCollapsed = false;
  public read: string = '';

  private readonly fieldsNotToBlock: string[] = ['tipoDocumento', 'numeroDocumento'];

  public maskForDUE: string = DocumentMaskEnum.DUE_MASK;
  public specialCharsForDUE: string[] = DocumentSpecialCharactersForMask.DUE_SPECIAL_CHARS;
  public maskForCE: string = DocumentMaskEnum.CE_MASK;
  public documentPattern?: any | null;

  public numerosDUE: string[] = [];

  public readonly documentTypesForShowingNumeroCE: string[] = ['PRC', 'DTC', 'DSIm'];
  public isNumeroCEVisible: boolean = false;

  @Input() isInsideModal?: boolean;
  @Input() visualizar?: boolean = false;
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() camposFormulario!: Campo[];
  @Output() dataFormOut = new EventEmitter<{}>();
  @Output() btnValid = new EventEmitter<boolean>();
  @Output() onDocumentLoad: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDocumentTypesLoaded: EventEmitter<void> = new EventEmitter<void>();
  @Output() onChangeDocumentType: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChangeDocumentTypeOnSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSendDocument: EventEmitter<Documento> = new EventEmitter<Documento>();
  @Output() onSendMask: EventEmitter<Mascara> = new EventEmitter<Mascara>();

  @ViewChild('numDoc') search!: ElementRef;
  @ViewChildren(CampoNumeroDueComponent) camposNumeroDUE!: QueryList<CampoNumeroDueComponent>;

  constructor(
    private fb: UntypedFormBuilder,
    private documentosService: DocumentosService,
    private changeDetector: ChangeDetectorRef,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private toastService:ToastService,
    private maskPipe: MaskPipe
  ) {}

  public ngAfterContentInit(): void {
    this.onDocumentLoad.emit();
  }

  public isDisable: boolean = true;
  public maskDoc!: string;
  public specialCharsForMask!: string[];
  public tipoDocumento!: TipoDocumentoSolicitacao[];

  filtrarMascara(valor: string) {
    this.form.get('numeroDocumento')?.setValue('');
    this.setMask(valor);
    this.changeDetector.detectChanges();
  }

  private setMask(documentType: string): void{
    let numeroDocumento = this.form?.get('numeroDocumento')?.value;
    if(documentType == 'DSIm'){
      this.maskDoc = 'D*';
      this.specialCharsForMask = [];
      this.documentPattern = DocumentCustomPattern.DSIM_MASK_PATTERN;
    }
    else{
      this.maskDoc = this.tipoDocumento?.find(
        (document) => document.nome == documentType
        )?.mascara || '';
        this.specialCharsForMask = this.verificacaoDocumentosService.getSpecialCharsFromMask(this.maskDoc);
        this.documentPattern = null;
    }
    this.changeDetector.detectChanges();
    this.onSendMask.emit({
      modelo: this.maskDoc,
      caracteresEspeciais: this.specialCharsForMask
    });
    if(!this.maskDoc?.length){
      this.form.patchValue({
        numeroDocumento
      }, {emitEvent: false})
    }
  }

  ngOnInit() {
    this.maskDoc = '';
    this.validation();
    this.changeDetector.detectChanges();
  }

  public setDocumentTypes(documentsForSelection: Observable<SuccessResponse>): void{
    documentsForSelection.subscribe({
      next: (event) => {
        this.tipoDocumento = event.data as TipoDocumentoSolicitacao[];
        this.changeDetector.detectChanges();
        this.onDocumentTypesLoaded.emit();
      },
      error: (err: ErrorResponse) => {
        console.log(err);
        err?.errors?.forEach(
          (error) => {
            this.toastService.error(error);
          }
        );
      }
    });
  }

  public validation(): void {
    this.form = this.fb.group({
      id: new UntypedFormControl('', Validators.required),
      tipoDocumento: new UntypedFormControl('', Validators.required),
      numeroDocumento: new UntypedFormControl('', Validators.required),
      numeroCE: new UntypedFormControl(''),
      numeroDUE: new UntypedFormControl('')
    }, {
      validators: [this.validateNumeroDUE.bind(this)]
    });
    this.form.valueChanges
      .subscribe(
        () => {
          this.isDisable = !this.checkValidDocumentForm();
          if(this.form.valid){
            this.sendDocumentData();
          }
        }
      );
    this.form?.get('tipoDocumento')?.valueChanges.subscribe(
      (value) => {
        this.changeDocumentType(value);
      }
    )
    this.changeDetector.detectChanges();
  }

  public getData() {
    this.sendDocumentData();
    if(!this.form.invalid){
      this.onSendDocument.emit(this.getDocumentFromFormFields());
      this.btnValid.emit(true);
    }
  }

  public getIdDoc(tipoDocumento: string){
    var dados = this.tipoDocumento.filter((e) => e.nome == tipoDocumento)
    return dados[0]?.id;
  }

  public ngOnChanges(changes: SimpleChanges) {}

  public checkValidDocumentForm() {
    return !!this.form.value.numeroDocumento
      && !!this.form.value.tipoDocumento
      && this.form.value.numeroDocumento.length >= this.maskDoc.length
      && this.form.valid;
  }

  public clean() {
    this.form.reset();
    this.search.nativeElement.focus();
  }

  public setFormValuesFromDocument(document: Documento | undefined): void{
    if(!document){
      return;
    }
    let tipoDocumento: string = document?.tipoDocumento ? document.tipoDocumento : this.form.value.tipoDocumento;
    let numeroDocumento: string = document?.numeroDocumento ? document.numeroDocumento : this.form.value.numeroDocumento;
    numeroDocumento = (!this.isInsideModal && this.maskDoc?.length) ? this.maskPipe.transform(numeroDocumento, [this.maskDoc, DefaultMaskPattern.DEFAULT_MASK_CHARS]) : numeroDocumento
    this.setMask(tipoDocumento);
    this.form.patchValue({
      id: document?.id ? document.id : this.form.value.id,
      tipoDocumento: tipoDocumento,
      numeroDocumento: numeroDocumento,
      numeroCE: document?.numeroCE ? document.numeroCE : this.form.value.numeroCE,
      numeroDUE: document?.numeroDUE ? document.numeroDUE : this.form.value.numeroDUE
    }, {emitEvent: false});
    this.switchNumeroCE();
    this.switchNumeroDUE();
    this.form.markAllAsTouched();
    this.changeDetector.detectChanges();
  }

  public changeDocumentType(document: string): void{
    this.form?.get('id')?.setValue(this.getIdDoc(document));
    this.setMask(document);
    this.changeDetector.detectChanges();
    this.onChangeDocumentType.emit(document);
    this.form?.updateValueAndValidity();
  }

  public changeDocumentTypeOnSelect(document: string): void{
    this.form?.get('numeroDocumento')?.setValue('');
    this.switchNumeroCE();
    this.switchNumeroDUE();
    if(document == 'DAT'){
      if(!this.numerosDUE?.length){
        this.numerosDUE = [''];
      }
    }
    else{
      this.numerosDUE = [];
    }
    this.changeDetector.detectChanges();
    this.onChangeDocumentTypeOnSelect.emit(document);
    this.form?.updateValueAndValidity();
  }

  public switchNumeroCE(): void{
    this.isNumeroCEVisible = !!this.documentTypesForShowingNumeroCE?.includes(this.form.get('tipoDocumento')?.value);
    this.changeDetector.detectChanges();
    if(this.isNumeroCEVisible){
      this.form?.get('numeroCE')?.addValidators(Validators.required);
      this.form?.get('numeroCE')?.enable();
    }
    else{
      this.form?.get('numeroCE')?.removeValidators(Validators.required);
      this.form?.get('numeroCE')?.reset('');
      this.form?.get('numeroCE')?.disable();
    }
    this.changeDetector.detectChanges();
  }

  public switchNumeroDUE(): void{
    if(this.form.get('tipoDocumento')?.value == 'DAT'){
      this.form?.get('numeroDUE')?.addValidators(Validators.required);
    }
    else{
      this.form?.get('numeroDUE')?.removeValidators(Validators.required);
      this.form?.get('numeroDUE')?.reset('');
    }
    this.changeDetector.detectChanges();
  }

  private sendDocumentData(): void{
    this.dataFormOut.emit({'documento': this.getDocumentFromFormFields()});
    this.onFormValid.emit(this.form.valid);
  }

  public getDocumentFromFormFields(): Documento{
    let document = this.form.getRawValue() as Documento;
    document.numeroDocumento = this.verificacaoDocumentosService.eraseMask(this.form.value.numeroDocumento)
    if(!document?.id){
      document.id = this.tipoDocumento?.find(tipoDocumento => tipoDocumento?.nome == document.tipoDocumento)?.id
    }
    if(document.tipoDocumento === 'DAT'){
      document.numeroDUE = this.getNumerosDUE();
    }
    return document;
  }

  public isDocumentValid(): boolean{
    return !this.form.invalid;
  }

  public setFormValuesFromDIXML(xml: any): void{
    this.setMask('DI');
    this.form.patchValue({
      tipoDocumento: "DI",
      numeroDocumento: (
        this.maskDoc?.length ?
        this.maskPipe.transform(
          xml.ListaDeclaracoes.declaracaoImportacao.numeroDI._text,
          this.maskDoc
        ) :
        xml.ListaDeclaracoes.declaracaoImportacao.numeroDI._text
      ),
      id: this.tipoDocumento?.find(documento => documento.nome == 'DI')?.id,
      numeroCE: xml.ListaDeclaracoes.declaracaoImportacao.conhecimentoCargaIdMaster._text
    }, {emitEvent: false});
    this.form.markAllAsTouched();
    this.changeDetector.detectChanges();
  }

  public setSettingsfromFields(): void{
    if(!this.form){
      return;
    }
    if(!this.camposFormulario?.length){
      this.enableAllFormFields();
      this.switchNumeroCE();
      this.switchNumeroDUE();
      return;
    }
    for(let control in this.form.controls){
      let fieldIndex = this.camposFormulario.findIndex(
        (field) => field.nome == control
      );
      if(fieldIndex >= 0){
        if(this.camposFormulario[fieldIndex].obrigatorio){
          this.form.get(control)?.addValidators(Validators.required);
        }
        else{
          this.form.get(control)?.removeValidators(Validators.required);
        }
        if(this.doesSolicitationHaveIntegration === undefined){
          if(this.camposFormulario[fieldIndex].leitura && !!this.form.get(control)?.value){
            this.form.get(control)?.disable();
          }
          else{
            this.form.get(control)?.enable();
          }
        }
        else{
          if(this.doesSolicitationHaveIntegration){
            if(this.camposFormulario[fieldIndex].leitura && !!this.form.get(control)?.value){
              this.form.get(control)?.disable();
            }
            else{
              this.form.get(control)?.enable();
            }
          }
          else{
            if(this.camposFormulario[fieldIndex].leitura){
              this.form.get(control)?.disable();
            }
            else{
              this.form.get(control)?.enable();
            }
          }
        }
        if(!this.form.get(control)?.value && this.camposFormulario[fieldIndex].obrigatorio){
          this.form.get(control)?.enable();
        }
        if(!!this.camposFormulario[fieldIndex]?.bloqueado && !this.fieldsNotToBlock.includes(control)){
          this.form.get(control)?.disable();
        }
      }
      else{
        if(!this.fieldsNotToBlock.includes(control)){
          this.form.get(control)?.enable();
          this.form.get(control)?.removeValidators(Validators.required);
        }
      }
      if(this.fieldsNotToBlock.includes(control)){
        this.form.get(control)?.enable();
        this.form.get(control)?.addValidators(Validators.required);
      }
    }
    this.changeDetector.detectChanges();
    this.switchNumeroCE();
    this.switchNumeroDUE();
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.form.controls){
      this.form.get(field)?.enable();
      if(this.fieldsNotToBlock.includes(field)){
        this.form.get(field)?.addValidators(Validators.required);
      }
      else{
        this.form.get(field)?.removeValidators(Validators.required);
      }
      this.changeDetector.detectChanges();
    }
  }

  public addNumeroDUE(): void{
    this.numerosDUE?.push('');
    this.changeDetector.detectChanges();
    this.form.updateValueAndValidity();
  }

  public removeNumeroDUE(index: number): void{
    let numerosDUESalvos = this.getNumerosDUE();
    numerosDUESalvos.splice(index, 1);
    this.numerosDUE = [...numerosDUESalvos];
    this.changeDetector.detectChanges();
    this.form.updateValueAndValidity();
  }

  public getNumerosDUE(): string[]{
    return this.camposNumeroDUE?.map(
      (campoNumeroDUE) => campoNumeroDUE.getNumeroDUE()
    ) ?? [];
  }

  public areAllNumerosDUEValid(): boolean{
    return this.camposNumeroDUE?.map(
      (campoNumeroDUE) => campoNumeroDUE.isNumeroDUEValid()
    )?.every(validDUE => validDUE);
  }

  private validateNumeroDUE(group: UntypedFormGroup): {invalidDUE: boolean} | null{
    if(group.value.tipoDocumento !== 'DAT'){
      return null;
    }
    if(this.areAllNumerosDUEValid()){
      return null;
    }
    return {invalidDUE: true};
  }

  public updateNumerosDUE(): void{
    let numeroDUE = this.getNumerosDUE();
    this.form.get('numeroDUE')?.setValue(numeroDUE.length ? numeroDUE : '');
    this.form.updateValueAndValidity();
  }

  public setFormValuesForModal(documento: Documento | undefined): void{
    this.form?.patchValue(documento ?? {}, {emitEvent: false});
    this.form.disable({emitEvent: false});
  }
}
