<div class="modal-header">
  <h4>Visualizar solicitação</h4>
  <button class="btn-close close pull right"><span class="visualli-hidden"
      (click)="closeModal()">&times;</span></button>
</div>
<div class="modal-body">
  <div class="p-3">
    <app-documento #documento (onSendMask)="mascara = $event" [camposFormulario]="camposFormulario" [visualizar]="true"
      [isInsideModal]="true"></app-documento>
    <app-cliente #cliente [camposFormulario]="camposFormulario" [visualizar]="blockEdition"></app-cliente>
    <app-dados-complementares #dadosComplementares [camposFormulario]="camposFormulario"
      [visualizar]="blockEdition"
      [Moedas]="moedas" [Canais]="canais" [Modalidades]="modalidades" [ModalidadesDTA]="modalidadesDTA"
    ></app-dados-complementares>
    <app-relacao-conteiner-importacao #relacaoConteineres [doesSolicitationHaveIntegration]="blockEdition"
      [camposFormulario]="camposFormulario" [blockAddition]="true"
      [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
      [visualizar]="blockEdition"></app-relacao-conteiner-importacao>
    <app-arvore-partes-liberacao-documental #arvoreDePartes [isExpDocument]="false" [mascara]="mascara"
      (onExpandPartDocument)="expandPartDocument($event)"></app-arvore-partes-liberacao-documental>
    <!-- <app-consulta-bloqueio #consultaBloqueio (onConsultaBloqueioLoad)="consultaBloqueio.setFormValuesFromConsultaBloqueio(solicitation?.consultaBloqueio)"></app-consulta-bloqueio> -->
    <app-documentos-anexados #documentosAnexados (onDownloadFileToDisplay)="documentosAnexados.downloadFileToDisplay(
                solicitacaoLiberacaoImpService.downloadFileForAttachedDocumentGridOnModal($event.arquivoDocumentoAnexado),
                $event.index
              )" (onDownloadFileToSave)="documentosAnexados.downloadFileToSave(
                solicitacaoLiberacaoImpService.downloadFileForAttachedDocumentGridOnModal($event.arquivoDocumentoAnexado),
                $event.index
              )" [visualizar]="blockEdition" [isInsideModal]="blockEdition"></app-documentos-anexados>
  </div>
</div>
<div class="modal-footer">
  <div *ngIf="!blockEdition" class="action-button-group action-triple-buttons w-100 justify-content-end">
    <div class="span"></div>
    <div class="span"></div>
    <button class="action-primary-button" [disabled]="!areAllFormsValid" (click)="saveSolicitation()">SALVAR</button>
  </div>
</div>
