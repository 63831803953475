import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from '../../../models/HttpResponses/ErrorResponse';
import {
  VerificacaoDocumentosService,
  ClientDocTypeEnum,
} from '../../utils/verificacao-documentos.service';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Cliente } from 'src/app/models/Cliente';
import { DocumentCustomPattern } from 'src/app/shared/utils/constantes-documentos';
import { Campo } from 'src/app/models/Campo';
import { debounceTime } from 'rxjs';
import { DocumentosService } from 'src/app/services/documentos.service';
import { DadosCliente } from 'src/app/models/DadosCliente';
import { MaskPipe } from 'ngx-mask';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss'],
})
export class ClienteComponent implements OnInit, AfterViewInit, AfterContentInit {
  public isCollapsed = false;
  public form!: UntypedFormGroup;
  public docMask!: string;
  public namePattern = DocumentCustomPattern.NAME_PATTERN;

  @Input() camposFormulario!: Campo[];
  @Input() visualizar?: boolean = false;
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Output() dataFormOut = new EventEmitter<{}>();
  @Output() onClientLoad: EventEmitter<void> = new EventEmitter<void>();
  @Output() onEndEditClient: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSearchClient: EventEmitter<Cliente> = new EventEmitter<Cliente>();

  public Cliente!: Cliente
  public DadosCliente!: any
  public DocNomeCliente: DadosCliente = { Nome: '', CGCCFO: '' }

  constructor(
    private fb: UntypedFormBuilder,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private changeDetector: ChangeDetectorRef,
    private maskPipe: MaskPipe,
    private documentosService: DocumentosService,
    private toastService:ToastService
  ) { }

  public ngOnInit(): void {
    this.validation();
  }

  public ngAfterViewInit(): void {
    if (!this.camposFormulario || !this.camposFormulario.length) {
      return;
    }
    this.camposFormulario.forEach(
      (campo) => {
        if (campo.obrigatorio) {
          this.form.get(campo.nome)?.addValidators(Validators.required);
        }
      }
    );
  }

  public ngAfterContentInit(): void {
    this.onClientLoad.emit();
  }

  public validation(): void {
    this.form = this.fb.group({
      tipoCliente: ['', []],
      cpfCnpj: ['', [Validators.minLength(11), Validators.maxLength(18), this.validateCpfOrCnpj.bind(this)]],
      nomeCliente: ['', [Validators.minLength(3), Validators.maxLength(100)]]
    });
    this.form.get('tipoCliente')?.valueChanges.subscribe(
      () => {
        this.updateDocWithMask();
      }
    );
    this.form.get('cpfCnpj')?.valueChanges
    .pipe(
      debounceTime(100)
    )
    .subscribe(
      () => {
        if(!this.form.get('tipoCliente')?.value && this.form.get('cpfCnpj')?.pristine){
          this.setDocTypeByCharCount();
        }
      }
    );
      this.form.get('nomeCliente')?.valueChanges.pipe(debounceTime(1000)).subscribe((val) => {
        this.searchCliente()
      })

      this.form.get('cpfCnpj')?.valueChanges.pipe(debounceTime(1000)).subscribe((val) => {
        if(this.form.get('cpfCnpj')?.valid)
          this.searchCliente()
      })
    this.getClientDocMask();
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   for (const change in changes) {
  //     const item = changes[change];
  //     if (item['currentValue'] != undefined && this.form != undefined) {
  //       const itemForm = item['currentValue'];
  //       this.form.patchValue(itemForm);
  //       if (this.form.value.cpfCnpj != '') this.read = 'readonly';
  //       this.isCollapsed = false;
  //     }
  //   }
  // }

  public searchCliente() {
    let cliente: Cliente = this.getClientFromForm();
    if(!cliente.nomeCliente?.length && !cliente.cpfCnpj?.length){
      return;
    }

    this.onSearchClient.emit(cliente);
  }

  public setSearchedClientData(client: Cliente | undefined): void{
    if(!client){
      return;
    }
    if(!client?.cpfCnpj?.trim()?.length || !client?.nomeCliente?.trim()?.length){
      return;
    }
    let cpfCnpjShort = this.verificacaoDocumentosService.eraseMask(client.cpfCnpj ?? '');
    client.cpfCnpj = this.maskPipe.transform(
      (cpfCnpjShort?.length ? cpfCnpjShort : this.form.value.cpfCnpj),
      '00.000.000/0000-00||000.000.000-00'
    )
    client.tipoCliente = cpfCnpjShort?.length == 14 ? 'juridica' : cpfCnpjShort?.length == 11 ? 'fisica' : '';
    client.nomeCliente = client?.nomeCliente?.length ? client?.nomeCliente : this.form.value.nomeCliente;
    this.form.patchValue(client, {emitEvent: false});
    this.changeDetector.detectChanges();
    this.onEndEditClient.emit();
  }

  sendClientData() {
    this.Cliente = {
      nomeCliente: this.form.value.nomeCliente,
      tipoCliente: this.form.value.tipoCliente,
      cpfCnpj: this.verificacaoDocumentosService.eraseMask(this.form.value.cpfCnpj),
      codigoCliente: this.form.value.codCliente
    };
    this.DadosCliente = { 'cliente': this.Cliente }
    this.dataFormOut.emit(this.DadosCliente);
  }

  public clean() {
    this.form.reset();
  }

  public validateCpfOrCnpj(control: AbstractControl): { invalidDocument: string } | null {
    let controlValue: string = control?.value;
    if (!controlValue?.length) {
      return null;
    }
    let error: string | null;
    let clearDocument: string = this.verificacaoDocumentosService.eraseMask(controlValue);
    if (clearDocument.length == 11) {
      error = this.verificacaoDocumentosService.calculateValidCPF(clearDocument);
    }
    else if (clearDocument.length == 14) {
      error = this.verificacaoDocumentosService.calculateValidCNPJ(clearDocument);
    }
    else {
      error = 'O nº do CNPJ/CPF informado é inválido. Favor verificar!';
    }
    if (error?.length) {
      return { invalidDocument: error };
    }
    return null;
  }

  public getClientDocMask() {
    this.docMask =
      this.verificacaoDocumentosService.getDocMask(
        this.form.get('tipoCliente')?.value
      ) || '00000000000000';
    this.changeDetector.detectChanges();
  }

  public setDocTypeByCharCount(): void {
    if (!this.form.get('tipoCliente')?.value) {
      this.form
        .get('tipoCliente')
        ?.setValue(
          this.verificacaoDocumentosService.eraseMask(this.form.get('cpfCnpj')?.value).length == 11
            ? 'fisica'
            : this.verificacaoDocumentosService.eraseMask(this.form.get('cpfCnpj')?.value).length == 14
              ? 'juridica'
              : ''
        );
    }
    this.updateDocWithMask();
  }

  private updateDocWithMask(): void {
    let docNumberValue = this.verificacaoDocumentosService.eraseMask(
      this.form.get('cpfCnpj')?.value as string
    );
    if (!docNumberValue.length) {
      return;
    }
    if (this.form.get('tipoCliente')?.value == ClientDocTypeEnum.FISICA) {
      this.form
        .get('cpfCnpj')
        ?.setValue(
          this.verificacaoDocumentosService.setMaskForCPF(docNumberValue)
        );
    } else if (this.form.get('tipoCliente')?.value == ClientDocTypeEnum.JURIDICA) {
      this.form
        .get('cpfCnpj')
        ?.setValue(
          this.verificacaoDocumentosService.setMaskForCNPJ(docNumberValue)
        );
    }
    this.changeDetector.detectChanges()
  }

  public setDocType(documento: string): void {
    if (documento.length == 11) {
      this.form.get('tipoCliente')?.setValue('fisica')
    } else if (documento.length == 14) {
      this.form.get('tipoCliente')?.setValue('juridica')
    } else {
      this.form.get('tipoCliente')?.setValue('')
    }
  }

  public removeExtremitySpacesFromClientName(): void {
    this.form
      .get('nomeCliente')
      ?.setValue((this.form.get('nomeCliente')?.value as string).trim());
  }

  public setFormValuesFromReceivedClient(client: Cliente | undefined, event?: boolean): void {
    if (!client) {
      return;
    }
    let cpfCnpjShort = this.verificacaoDocumentosService.eraseMask(client.cpfCnpj)
    if(cpfCnpjShort.length == 11){
      client.tipoCliente = 'fisica';
      client.cpfCnpj = this.verificacaoDocumentosService.setMaskForCPF(cpfCnpjShort);
    }
    else if(cpfCnpjShort.length == 14){
      client.tipoCliente = 'juridica';
      client.cpfCnpj = this.verificacaoDocumentosService.setMaskForCNPJ(cpfCnpjShort);
    }
    this.form.patchValue({
      nomeCliente: client.nomeCliente ? client.nomeCliente : this.form.value.nomeCliente,
      cpfCnpj: client.cpfCnpj ? client.cpfCnpj : this.form.value.cpfCnpj,
      tipoCliente: client.tipoCliente ? client.tipoCliente : this.form.value.tipoCliente
    }, {emitEvent: !!event});
    this.form.markAllAsTouched();
    this.changeDetector.detectChanges();
    let cliente = this.form.getRawValue();
    this.onEndEditClient.emit();
    if(cliente.nomeCliente?.length && cliente.cpfCnpj?.length){
      return
    }
    this.searchCliente();
  }

  public setSettingsfromFields(): void{
    if(!this.form){
      return;
    }
    if(!this.camposFormulario?.length){
      this.enableAllFormFields();
      return;
    }
    for(let control in this.form.controls){
      let fieldIndex = this.camposFormulario.findIndex(
        (field) => field.nome == control
      );
      if(fieldIndex >= 0){
        if(this.camposFormulario[fieldIndex].obrigatorio){
          this.form.get(control)?.addValidators(Validators.required);
        }
        else{
          this.form.get(control)?.removeValidators(Validators.required);
        }
        if(this.doesSolicitationHaveIntegration === undefined){
          if(this.camposFormulario[fieldIndex].leitura && !!this.form.get(control)?.value){
            this.form.get(control)?.disable();
          }
          else{
            this.form.get(control)?.enable();
          }
        }
        else{
          if(this.doesSolicitationHaveIntegration){
            if(this.camposFormulario[fieldIndex].leitura && !!this.form.get(control)?.value){
              this.form.get(control)?.disable();
            }
            else{
              this.form.get(control)?.enable();
            }
          }
          else{
            if(this.camposFormulario[fieldIndex].leitura){
              this.form.get(control)?.disable();
            }
            else{
              this.form.get(control)?.enable();
            }
          }
        }
        if(!!this.camposFormulario[fieldIndex]?.bloqueado){
          this.form.get(control)?.disable();
        }
        if(!this.form.get(control)?.value && this.camposFormulario[fieldIndex].obrigatorio){
          this.form.get(control)?.enable();
        }
      }
      else{
        this.form.get(control)?.enable();
        this.form.get(control)?.removeValidators(Validators.required);
      }
      this.changeDetector.detectChanges();
      this.showFieldAsRequired(control);
    }
    this.changeDetector.detectChanges();
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.form.controls){
      this.form.get(field)?.enable();
      this.form.get(field)?.removeValidators(Validators.required);
      this.showFieldAsRequired(field);
      this.changeDetector.detectChanges();
    }
  }

  public getClientFromForm(): Cliente{
    return this.form.getRawValue() as Cliente;
  }

  public isClientValid(): boolean{
    return !this.form?.invalid;
  }

  public setFormValuesFromDIXML(xml: any): void{
    let tipoCliente = '';
    let cpfCnpjShort = this.verificacaoDocumentosService.eraseMask(xml.ListaDeclaracoes.declaracaoImportacao.importadorNumero._text)
    if(cpfCnpjShort.length == 11){
      tipoCliente = 'fisica';
    }
    else if(cpfCnpjShort.length == 14){
      tipoCliente = 'juridica';
    }
    this.form.patchValue({
      cpfCnpj: xml.ListaDeclaracoes.declaracaoImportacao.importadorNumero._text,
      nomeCliente: xml.ListaDeclaracoes.declaracaoImportacao.importadorNome._text,
      tipoCliente: tipoCliente
    }, {emitEvent: false});
    this.form.markAllAsTouched();
    this.changeDetector.detectChanges();
    this.searchCliente();
  }

  public setFormValuesFromCTEXML(xml: Partial<Cliente>): void{
    let tipoCliente = '';
    let cpfCnpjShort = this.verificacaoDocumentosService.eraseMask(xml.cpfCnpj ?? '')
    if(cpfCnpjShort.length == 11){
      tipoCliente = 'fisica';
    }
    else if(cpfCnpjShort.length == 14){
      tipoCliente = 'juridica';
    }
    xml.tipoCliente = tipoCliente;
    this.form.patchValue(
      xml,
      {emitEvent: false}
    );
    this.form.markAllAsTouched();
    this.changeDetector.detectChanges();
    this.searchCliente();
  }

  public setFormValuesFromNFXML(xml: any): void{
    let tipoCliente = '';
    let cpfCnpjShort = this.verificacaoDocumentosService.eraseMask(xml?.nfeProc?.NFe?.infNFe?.dest?.CNPJ?._text)
    if(cpfCnpjShort.length == 11){
      tipoCliente = 'fisica';
    }
    else if(cpfCnpjShort.length == 14){
      tipoCliente = 'juridica';
    }
    this.form.patchValue({
      cpfCnpj: cpfCnpjShort,
      tipoCliente: tipoCliente
    }, {emitEvent: false});
    this.form.markAllAsTouched();
    this.changeDetector.detectChanges();
    this.searchCliente();
  }

  private showFieldAsRequired(field: string): void{
    if(!!this.form.get(field)?.hasValidator(Validators.required)){
      document.getElementById(`${field}-required-label`)?.classList?.remove('invisible');
      document.getElementById(`${field}-required-label`)?.classList?.add('visible');
    }
    else{
      document.getElementById(`${field}-required-label`)?.classList?.remove('visible');
      document.getElementById(`${field}-required-label`)?.classList?.add('invisible');
    }
    this.changeDetector.detectChanges();
  }
}
