<div class="conteudo">

  <div class="card card-login">

    <h2 class="card-login-title">Seja bem-vindo,</h2>
    <span class="card-login-text">Ao Sistema Integrado de Segurança</span>

    <div class="card-body card-body-login">
      <form [formGroup]="loginForm" class="form-login">

        <div class="form-group form-group-login">
          <label class="form-label">Matrícula</label>
          <input class="form-control" required formControlName="matricula" type="text">
        </div>

        <div id="form-group-login-password" class="form-group form-group-login">
          <label class="form-label">Senha</label>
          <input class="form-control" required formControlName="senha" type="password">
        </div>
        <button  [disabled]="this.haveUpdateStatus" class="btn-login" name="button" value="login" (click)="signin()">{{!this.haveUpdateStatus ? 'Entrar':'Atualizando sistema...'}}</button>
      </form>
    </div>
  </div>
</div>