import { MonitoramentoRpaApiComponent } from './pages/monitoramento-rpa-api/monitoramento-rpa-api.component';
import { EmissaoTicketPesagemExportacaoComponent } from './pages/emissao-ticket-pesagem-exportacao/emissao-ticket-pesagem-exportacao.component';
import { RelatorioMovimentacaoClientesComponent } from './pages/relatorios/relatorio-movimentacao-clientes/relatorio-movimentacao-clientes.component';
import { EntregaDueContingenciaComponent } from './pages/entrega-due-contingencia/entrega-due-contingencia.component';
import { CabotagemEmbarqueComponent } from './pages/cabotagem-embarque/cabotagem-embarque.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LiberacaoDocumentalComponent } from './pages/liberacao-documental/liberacao-documental.component';
import { SolicitacaoLiberacaoCabotagemComponent } from './pages/solicitacao-liberacao-cabotagem/solicitacao-liberacao-cabotagem.component';
import { SolicitacaoLiberacaoImportacaoComponent } from './pages/solicitacao-liberacao-importacao/solicitacao-liberacao-importacao.component';
import { SolicitacaoLiberacaoExportacaoComponent } from './pages/solicitacao-liberacao-exportacao/solicitacao-liberacao-exportacao.component';
import { RecepcaoDueContigenciaComponent } from './pages/recepcao-due-contigencia/recepcao-due-contigencia.component';
import { RelatorioRecepcaoNfDatConteinerComponent } from './pages/relatorios/relatorio-recepcao-nf-dat-conteiner/relatorio-recepcao-nf-dat-conteiner.component';
import { ReprovacaoAprovacaoLiberacaoPorPeriodoComponent } from './pages/relatorios/reprovacao-aprovacao-liberacao-por-periodo/reprovacao-aprovacao-liberacao-por-periodo.component';
import { RelatorioEntregaDaCargaNavioComponent } from './pages/relatorios/relatorio-entrega-da-carga-navio/relatorio-entrega-da-carga-navio.component';
import { RelatorioConteineresLiberadosComponent } from './pages/relatorios/relatorio-conteineres-liberados/relatorio-conteineres-liberados.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { AcessoNegadoComponent } from './components/acesso-negado/acesso-negado.component';
import { AuthGuard } from './auth.guard';
import { Telas } from './contants/telas';
import { EstimativaCalculoComponent } from './pages/estimativa-calculo/estimativa-calculo.component';
import { BloqueioDesbloqueioConteinerEmbarqueComponent } from './pages/bloqueio-desbloqueio-conteiner-embarque/bloqueio-desbloqueio-conteiner-embarque.component';
import { LoginPage } from './components/login/login.page';
import { HomeComponent } from './components/home/home.component';
import { PortalTeconRedirectComponent } from './pages/portal-tecon-redirect/portal-tecon-redirect.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'portal-tecon-redirect',
    component: PortalTeconRedirectComponent,
  },
  {
    path: 'solicitacao-liberacao-importacao',
    canActivate: [AuthGuard],
    component: SolicitacaoLiberacaoImportacaoComponent,
    data: {
      tela: Telas.solicitacaoLiberacaoImportacao
    }
  },
  {
    path: 'liberacao-documental-importacao',
    canActivate: [AuthGuard],
    component: LiberacaoDocumentalComponent,
    data: {
      tela: Telas.liberacaoDocumentalImportacao
    }
  },
  {
    path: 'liberacao-documental-exportacao',
    canActivate: [AuthGuard],
    component: LiberacaoDocumentalComponent,
    data: {
      tela: Telas.liberacaoDocumentalExportacao
    }
  },
  {
    path: 'liberacao-documental-cabotagem',
    canActivate: [AuthGuard],
    component: LiberacaoDocumentalComponent,
    data: {
      tela: Telas.liberacaoDocumentalCabotagem
    }
  },
  {
    path: 'liberacao-documental',
    canActivate: [AuthGuard],
    component: LiberacaoDocumentalComponent
  },
  {
    path: 'solicitacao-liberacao-cabotagem',
    canActivate: [AuthGuard],
    component: SolicitacaoLiberacaoCabotagemComponent,
    data: {
      tela: Telas.solicitacaoLiberacaoCabotagem
    }
  },

  {
    path: 'recepcao-conteiner',
    canActivate: [AuthGuard],
    component: RecepcaoDueContigenciaComponent,
    data: {
      tela: Telas.recepcaoConteiner
    }
  },
  {
    path: 'bloqueio-desbloqueio-conteiner-embarque',
    component: BloqueioDesbloqueioConteinerEmbarqueComponent,
    canActivate:[AuthGuard],
    data:{
      Telas: Telas.bloqueioDesbloqueioConteinerEmbarque
    }
  },
  {
    path: 'estimativa-calculo',
    component: EstimativaCalculoComponent,
    canActivate:[AuthGuard],
    data:{
      Telas: Telas.estimativaCalculo
    }
  },
  {
    path: 'solicitacao-liberacao-exportacao',
    canActivate: [AuthGuard],
    component: SolicitacaoLiberacaoExportacaoComponent,
    data: {
      tela: Telas.solicitacaoLiberacaoExportacao
    }
  },
  {
    path: 'cabotagem-embarque',
    canActivate: [AuthGuard],
    component: CabotagemEmbarqueComponent,
    data: {
      tela: Telas.cabotagemEmbarque
    }
  },
  {
    path: 'recepcao-nf-dat-conteiner',
    canActivate: [AuthGuard],
    component: RelatorioRecepcaoNfDatConteinerComponent,
    data: {
      tela: Telas.relatoioRecepcaoNfDatConteiner
    }
  },
  {
    path: 'entrega-due-contingencia',
    canActivate: [AuthGuard],
    component: EntregaDueContingenciaComponent,
    data: {
      tela: Telas.entregaDueConteiner
    }
  },
  {
    path: 'relatorio-movimentacao-clientes',
    canActivate: [AuthGuard],
    component: RelatorioMovimentacaoClientesComponent,
    data: {
      tela: Telas.relatorioMovimentacaoClientes
    }
  },
  {
    path: 'reprovacao-aprovacao-liberacao',
    canActivate: [AuthGuard],
    component: ReprovacaoAprovacaoLiberacaoPorPeriodoComponent,
    data: {
      tela: Telas.relatorioReprovacaoAprovacaoLiberacao
    }
  },
  {
    path: 'relatorio-entrega-da-carga-navio',
    canActivate: [AuthGuard],
    component: RelatorioEntregaDaCargaNavioComponent,
    data: {
      tela: Telas.relatorioEntregaDaCargaNavio
    }
  },
  {
    path: 'relatorio-conteineres-liberados',
    component: RelatorioConteineresLiberadosComponent,
    canActivate: [AuthGuard],
    data: {
      tela: Telas.relatorioConteineresLiberados
    }
  },
  {
    path: 'emissao-ticket-pesagem-exportacao',
    component: EmissaoTicketPesagemExportacaoComponent,
    canActivate: [AuthGuard],
    data: {
      tela: Telas.emissaoTicketPesagemExportacao
    }
  },
  {
    path: 'monitoramento-rpa-api',
    component: MonitoramentoRpaApiComponent,
    canActivate: [AuthGuard],
    data: {
      tela: Telas.monitoramentoRpaApi
    }
  },
  {
    path: 'login',
    component: LoginPage,
  },

  {
    path: 'auth-callback',
    component: AuthCallbackComponent
  },
  {
    path: 'acesso-negado',
    component: AcessoNegadoComponent
  },

  {
    path: 'home',
    canActivate: [AuthGuard],
    component: HomeComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
