import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  fullName: string | undefined;

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    this.validaPortal();
    this.updateInfo();

  }

  async updateInfo() {
    this.fullName = this.authService.getUser().fullName;
  }
  async validaPortal() {
    if (this.authService.isPortalTecon() && this.authService.isRedirectDocumental()) {
      this.authService.signout()
    }
  }

}
