import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { DocumentSpecialCharactersForMask } from 'src/app/shared/utils/constantes-documentos';
import { DocumentMaskEnum } from 'src/app/shared/utils/constantes-documentos';
import { DocumentCustomPattern } from 'src/app/shared/utils/constantes-documentos';

@Component({
  selector: 'app-campo-numero-due',
  templateUrl: './campo-numero-due.component.html',
  styleUrls: ['./campo-numero-due.component.scss']
})
export class CampoNumeroDueComponent implements OnInit, AfterViewInit {
  enable() {
    this.numeroDUE.enable();
  }
  disable() {
    this.numeroDUE.disable();
  }

  public numeroDUE!: UntypedFormControl;
  public maskForDUE: string = DocumentMaskEnum.DUE_MASK;
  public specialCharsForDUE: string[] = DocumentSpecialCharactersForMask.DUE_SPECIAL_CHARS;
  @Input() index!: number;
  @Input() public visualizar?: boolean = false
  @Input() numeroDUESalvo?: string;
  @Output() onAddNumeroDUE: EventEmitter<void> = new EventEmitter<void>();
  @Output() onRemoveNumeroDUE: EventEmitter<void> = new EventEmitter<void>();
  @Output() onUpdateNumeroDUE: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.numeroDUE = new UntypedFormControl(this.numeroDUESalvo ?? '', Validators.compose([Validators.required]));
    if(this.numeroDUESalvo?.length){
      this.numeroDUE.markAsTouched();
    }
    this.numeroDUE.valueChanges.pipe(
      debounceTime(100)
    ).subscribe(
      () => {
        this.onUpdateNumeroDUE.emit();
      }
    );
    this.onUpdateNumeroDUE.emit();
    if(this.visualizar){
      this.numeroDUE.disable();
    }

  }

  public getNumeroDUE(): string{
    return this.numeroDUE?.value || '';
  }

  public isNumeroDUEValid(): boolean{
    return !this.numeroDUE?.invalid;
  }
}
