<td *ngIf="numeroDUE">
  <div class="input-group">
    <input type="text" class="form-control"
      placeholder="Digite aqui..." aria-label="Digite aqui..."
      [formControl]="numeroDUE" readonly
    >
  </div>
</td>
<td *ngIf="numeroConteiner">
  <div class="input-group">
    <input type="text" class="form-control"
      placeholder="Digite aqui..." aria-label="Digite aqui..."
      [formControl]="numeroConteiner" readonly
    >
  </div>
</td>
<td *ngIf="cnpj">
  <div class="input-group">
    <input type="text" class="form-control"
      placeholder="Digite aqui..." aria-label="Digite aqui..."
      [formControl]="cnpj" readonly
    >
  </div>
</td>
<td *ngIf="canal">
  <div class="input-group">
    <select class="custom-select" [formControl]="canal">
      <option value="" disabled selected>Selecione</option>
      <option *ngFor="let canal of canais" [value]="canal.nome">{{canal.nome}}</option>
    </select>
  </div>
</td>
