<div class="card mt-4">
    <div>
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-3">
                    <div class="col-3">
                        <label>Periodo de: *</label>
                        <input type="text" class="form-control" formControlName="periodo" bsDatepicker #dpSolicitacao="bsDatepicker" aria-describedby="basic-addon2" placeholder="dd/mm/aaaa" [bsConfig]="bsConfig" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [ngClass]="{'border border-danger': form.get('periodo')?.invalid && !form.get('periodo')?.pristine}" [maxDate]="form.get('periodoAte')?.value ? form.get('periodoAte')?.value : currentDate ? currentDate : undefined"
                            [mask]="'00/00/0000'"
                        >
                        <div *ngIf="form.get('periodo')?.errors && !form.get('periodo')?.pristine">
                            <p class="text-danger" *ngIf="form.get('periodo')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                        </div>
                    </div>
                    <div class="col-3">
                        <label>Até: *</label>
                        <input type="text" class="form-control" formControlName="periodoAte" bsDatepicker #dpSolicitacao="bsDatepicker" aria-describedby="basic-addon2" placeholder="dd/mm/aaaa" [bsConfig]="bsConfig" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [ngClass]="{'border border-danger': form.get('periodoAte')?.invalid && !form.get('periodoAte')?.pristine}" [minDate]="form.get('periodo')?.value ?? undefined" [maxDate]="currentDate ? currentDate : undefined"
                            [mask]="'00/00/0000'"
                        >
                        <div *ngIf="form.get('periodoAte')?.errors && !form.get('periodoAte')?.pristine">
                            <p class="text-danger" *ngIf="form.get('periodoAte')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                        </div>
                    </div>
                    <div class="col">
                        <label for="">Cliente:</label>
                        <input type="text" class="form-control" formControlName="cliente" placeholder="Digite aqui...">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label>CNPJ Cliente: </label>
                        <input type="text" class="form-control" formControlName="documentoCliente" [ngClass]="{'border border-danger': !form.get('documentoCliente')?.pristine && form.get('documentoCliente')?.errors }" mask="00.000.000/0000-00" placeholder="Digite aqui..." aria-label="Digite aqui..."
                            aria-describedby="basic-addon2">
                    </div>
                    <div class="col">
                        <label for="">Conteiner:</label>
                        <input type="text" class="form-control" formControlName="conteiner" placeholder="Digite aqui...">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-3">
                        <label>Envio ao CCT - De:</label>
                        <input type="text" class="form-control" formControlName="envioCct" bsDatepicker #dpSolicitacao="bsDatepicker" aria-describedby="basic-addon2" placeholder="dd/mm/aaaa" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [maxDate]="form.get('envioCctAte')?.value ? form.get('envioCctAte')?.value : currentDate ? currentDate : undefined" [bsConfig]="bsConfig"
                            [mask]="'00/00/0000'"
                        >
                    </div>
                    <div class="col-3">
                        <label>Envio ao CCT - Até:</label>
                        <input type="text" class="form-control" formControlName="envioCctAte" bsDatepicker #dpSolicitacao="bsDatepicker" aria-describedby="basic-addon2" placeholder="dd/mm/aaaa" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [minDate]="form.get('envioCct')?.value ?? undefined" [bsConfig]="bsConfig" [maxDate]="currentDate ? currentDate : undefined"
                            [mask]="'00/00/0000'"
                        >
                    </div>
                    <div class="col">
                        <div class="form-check">
                            <input formControlName="depositadoSemRecepcao" class="form-chek-input" type="checkbox">
                            <label class="form-check-label" for="defaultIncluir">&nbsp;Depositados sem Recepção</label>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-9 d-flex align-items-end">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                    <div class="col-3 action-button-group">
                        <button class="action-primary-button" [disabled]="form.invalid" (click)="searchContainerRelations()">PESQUISAR</button>
                    </div>
                </div>
            </div>
        </form>
        <div class="overflow-auto my-3">
            <div class="table-container">
                <table class="grid-table table table-bordered text-nowrap" *ngIf="recepcaoNFDATConteinerGrid?.length">
                    <thead>
                        <tr>
                            <th>Tipo de Documento</th>
                            <th>N° do Documento</th>
                            <th>Cliente</th>
                            <th>CNPJ Cliente</th>
                            <th>Nome Transportador</th>
                            <th>CNPJ Transportador</th>
                            <th>Nome do Motorista</th>
                            <th>CPF Motorista</th>
                            <th>Contêiner</th>
                            <th>Data/Hora da Recepção</th>
                            <th>Data/Hora da Pesagem</th>
                            <th>Retorno do CCT</th>
                            <th>Tempo para Pesagem</th>
                            <th>Navio</th>
                            <th>Viagem</th>
                            <th>Booking</th>
                            <th>Data e hora da chegada</th>
                            <th>Data e hora do envio CCT</th>
                            <th>Tempo para Recepção CCT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let recepcaoNFDATConteiner of recepcaoNFDATConteinerGrid; let indexRecepcao = index">
                            <td>
                                <span>{{recepcaoNFDATConteiner.tipoDoc}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.numeroDoc}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.cliente}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.cnpjCliente | mask: '00.000.000/0000-00'}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.nomeTransportador}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.cnpjTransportador | mask: '00.000.000/0000-00'}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.nomeMotorista}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.cpfMotorista | mask: '000.000.000-00'}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.conteiner}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.dataHoraRecepcao | date: 'dd/MM/yyyy - HH:mm:ss '}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.dataHoraPesagem | date: 'dd/MM/yyyy - HH:mm:ss'}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.retornoCct}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.tempoPesagem}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.navio}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.viagem}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.booking}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.dataHoraChegada | date: 'dd/MM/yyyy - HH:mm:ss'}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.dataHoraEnvioCct | date: 'dd/MM/yyyy - HH:mm:ss'}}</span>
                            </td>
                            <td>
                                <span>{{recepcaoNFDATConteiner.tempoRecepcaoCct}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    </div>
  <app-impressao-nf-dat-conteiner *ngIf="isPrinting"></app-impressao-nf-dat-conteiner>
