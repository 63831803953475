import { Injectable } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import { Workbook } from 'exceljs'
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  private logo = '../../assets/images/logoSeptibaTecon.png';

  constructor(
    private papa: Papa
  ) { }

  public convertXlsToJson(xlsFile: File): any[] | null {
    let workbook = XLSX.read(xlsFile);
    let resultItems: any[] = [];
    for (let sheetName of workbook.SheetNames) {
      let ref = workbook.Sheets[sheetName]['!ref'] as string;
      if (ref && ref.length) {
        let rowName = ref[0];
        let colStart = parseInt(ref[1]);
        let colEnd = parseInt(ref[4]);
        for (let col: number = colStart; col <= colEnd; col++) {
          if(workbook.Sheets[sheetName][`${rowName + col}`]){
            workbook.Sheets[sheetName][`${rowName + col}`].w = workbook.Sheets[sheetName][`${rowName + col}`]?.w ? this.toCamelCase(workbook.Sheets[sheetName][`${rowName + col}`].w) : col.toString();
          }
        }
      }
      resultItems.push(...XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { blankrows: false }));
    }
    return resultItems;
  }

  public convertCsvToJson(csvFile: Blob): any[] | null {
    let parseResult = this.papa.parse(csvFile, {
      header: true,
      transformHeader: (header) => this.toCamelCase(header),
      skipEmptyLines: true
    });
    if (!parseResult.errors?.length) {
      return parseResult.data;
    }
    return null;
  }

  public toCamelCase(str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = {
      Sheets: { data: myworksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(myworkbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }

  async getBase64ImageFromUrl(imageUrl: string) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  public async exportAsExcelFileCustom(json: any[], excelFileName: string, headersArray: any[], titulo: string) {
    let logoBase64: any = "";
    await this.getBase64ImageFromUrl(this.logo)
      .then(result => logoBase64 = result)
      .catch(err => console.error(err));
    //Excel Title, Header, Data
    const header = headersArray;
    const data = json;

    //Create workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelFileName);
    let headerName: any[] = [];
    const logoConfig = workbook.addImage({
      base64: logoBase64,
      extension: 'png',
    });

    worksheet.addImage(logoConfig, 'A1:A4');


    worksheet.getCell(`B2`).value = titulo;
    worksheet.mergeCells('B2:E2');
    worksheet.getCell(`B2`).alignment = { horizontal: 'center' };

    worksheet.getCell(`F2`).value = new Date().toLocaleString('pt-BR');
    worksheet.mergeCells('F2:H2');
    worksheet.getCell(`F2`).alignment = { horizontal: 'center' };

    worksheet.addRow(['']);
    worksheet.addRow(['']);
    worksheet.addRow(['']);


    header.forEach((el) => {
      headerName.push(el.nome.toString())
    })

    let headerRow = worksheet.addRow(headerName);

    worksheet.addRow(['']);
    worksheet.addRow(['']);

    for (let index = 0; index < header.length; index++) {
      worksheet.getColumn(index + 1).key = header[index].key
      worksheet.getColumn(index + 1).width = header[index].width;
      worksheet.getColumn(index + 1).font = { bold: true }
      worksheet.getColumn(index + 1).alignment = { horizontal: 'center' };
    }

    data.forEach(d => {
      let row = worksheet.addRow(d);
      row.font = { bold: false }
      row.alignment = { horizontal: 'center' };
    });

    worksheet.addRow(['']);
    worksheet.addRow(['']);

    worksheet.getCell(`A:${worksheet.lastRow?.number}`).value = 'Gerado por CSN © '+new Date().getFullYear();
    worksheet.mergeCells(`A${worksheet.lastRow?.number}:B${worksheet.lastRow?.number}`);
    worksheet.getCell(`A:${worksheet.lastRow?.number}`).alignment = { horizontal: 'center' };


    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      FileSaver.saveAs(blob, excelFileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    })
  }

}
