import { TransportadorRNF } from './../../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/TransportadorRNF';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DocumentCustomPattern, DocumentMaskEnum } from 'src/app/shared/utils/constantes-documentos';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';

@Component({
  selector: 'app-transportador',
  templateUrl: './transportador.component.html',
  styleUrls: ['./transportador.component.scss']
})
export class TransportadorComponent implements OnInit {
  public form!: UntypedFormGroup;
  public isCollapsed = false;
  public maskForClientDocument: string = '000.000.000-00||00.000.000/0000-00';
  public maskForNames: string = DocumentMaskEnum.NAME_MASK;
  public patternForNames = DocumentCustomPattern.NAME_PATTERN;

  @Output() onSendTransporterData: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnInit() {
    this.validation();
  }

  private validation(): void{
    this.form = this.fb.group({
      cpfCnpjTransportador: [''],
      nomeTransportador: ['']
    });
    this.form.valueChanges.subscribe(
      () => {
        this.onSendTransporterData.emit({transportador: this.form.getRawValue()});
      }
    );
  }

  public validateClientDocument(clientDocument: string): string | null{
    if(!clientDocument){
      return null;
    }
    if(this.verificacaoDocumentosService.eraseMask(clientDocument).length === 11){
      return this.verificacaoDocumentosService.checkCPFOrCNPJ(clientDocument, 'fisica');
    }
    else if(this.verificacaoDocumentosService.eraseMask(clientDocument).length === 14){
      return this.verificacaoDocumentosService.checkCPFOrCNPJ(clientDocument, 'juridica');
    }
    if(this.verificacaoDocumentosService.eraseMask(clientDocument).length != 11 && this.verificacaoDocumentosService.eraseMask(clientDocument).length != 14){
      return 'Documento inválido. Favor verificar!';
    }
    return 'Documento inválido. Favor verificar!';
  }

  public setTransporterDataFromReceivedTransporter(transportador: TransportadorRNF): void{
    this.form.get('cpfCnpjTransportador')?.setValue(transportador.cpfCnpjTransportador);
    this.form.get('nomeTransportador')?.setValue(transportador.nomeTransportador);
  }

  public getTransporterDataFromForm(): TransportadorRNF{
    return this.form.getRawValue() as TransportadorRNF;
  }

  public clear(): void{
    this.form.reset();
  }
}
