import { ToastService } from 'src/app/services/toast.service';
import { BookingDTOPlain } from './../../../models/CabotagemEmbarque/BookingDTOPlain';
import { ExcelService } from './../../../services/excel.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { retryWhen } from 'rxjs';

@Component({
  selector: 'app-anexos-cabotagem-embarque',
  templateUrl: './anexos-cabotagem-embarque.component.html',
  styleUrls: ['./anexos-cabotagem-embarque.component.scss']
})
export class AnexosCabotagemEmbarqueComponent implements OnInit {

  public isCollapsed: boolean = false;
  public readonly allowedFileExtensions: string[] = ['.csv', '.xls', '.xlsx'];
  public readonly header: string[] = ['navio', 'viagem', 'armador', 'booking', 'modalidade'];
  @Output() onSendBookings: EventEmitter<BookingDTOPlain[]> = new EventEmitter<BookingDTOPlain[]>();

  constructor(
    private excelService: ExcelService,
    private toastService:ToastService
  ) { }

  public ngOnInit(): void {
  }

  public checkExcelFileToSend(upload: any): void {
    if (!upload) {
      return;
    }
    const [file] = upload?.target?.files as File[];
    if (!file) {
      return;
    }
    (document.getElementById('arquivoExcel') as HTMLInputElement).value = '';
    let extension: string = `.${file.name.split('.').pop() ?? ''}`;
    if (!this.allowedFileExtensions.includes(extension)) {
      this.toastService.error('Arquivo inválido');
      return;
    }
    if (extension == '.csv') {
      this.checkCsvFile(file);
    }
    else {
      this.checkXlsFile(file);
    }
  }

  private checkCsvFile(csvFile: File): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      let base = event.target.result;
      let parseResult = this.excelService.convertCsvToJson(base);
      this.emitSheetIfValid(parseResult);
    };
    reader.readAsText(csvFile);
  }

  private checkXlsFile(xlsFile: File): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      let base = event.target.result;
      let parseResult = this.excelService.convertXlsToJson(base);
      this.emitSheetIfValid(parseResult);
    };
    reader.readAsArrayBuffer(xlsFile);
  }

  private isBookingDTOPlain(object: unknown): object is BookingDTOPlain {
    return Object.prototype.hasOwnProperty.call(object, "navio")
      && Object.prototype.hasOwnProperty.call(object, "viagem")
      && Object.prototype.hasOwnProperty.call(object, "armador")
      && Object.prototype.hasOwnProperty.call(object, "booking")
      && Object.prototype.hasOwnProperty.call(object, "modalidade");
  }

  private emitSheetIfValid(sheet: any): void {
    if (!sheet || !sheet?.length) {
      this.toastService.error('Planilha inválida!');
      return;
    }
  
    let bookings = sheet.filter(
      (result: any) => this.isBookingDTOPlain(result)
    );
    if (!bookings || !bookings.length) {
      this.toastService.error('Planilha inválida!');
      return;
    }
    this.onSendBookings.emit(bookings);
  }
 
}
