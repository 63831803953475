<td>{{solicitation.numeroSolicitacao}}</td>
<td>{{solicitation.solicitante}}</td>
<td>{{solicitation.cliente.nomeCliente == 'null' ? '' : solicitation.cliente.nomeCliente}}</td>
<td>{{solicitation.cliente.nomeClienteFaturar}}</td>
<td>{{solicitation.documento.tipoDocumento}}</td>
<td>{{
  (mask.length) ?
  (solicitation.documento.numeroDocumento | mask : [mask, maskPattern]) :
  (solicitation.documento.numeroDocumento)}}
</td>
<td>{{solicitation.dataSolicitacao | date: 'dd/MM/yyyy HH:mm'}}</td>
<td>{{solicitation.tipoSolicitacao}}</td>
<td>{{solicitation.status}}</td>
<td *ngIf="solicitation.justificativaRecusa && solicitation.justificativaRecusa.length > 25; else justificativaCurta">
    {{shortenTextForJustificationDisplay()}} <a class="see-more-link" (click)="openModalForJustificationDisplay()">ver
    mais</a>
</td>
<ng-template #justificativaCurta>
    <td>{{solicitation.justificativaRecusa ?? ''}}</td>
</ng-template>
<td>
    <div class="grid-action-buttons">
        <button (click)="expandSolicitation()">
      <img src="assets/images/expand.svg" alt="">
    </button>
    </div>
</td>
<td>
    <div class="grid-action-buttons">
        <button class="attach-button"  (click)="showAttachedDocuments()"
        >
          <img src="assets/images/blue-paperclip.svg" alt="">
          <span>Anexar</span>
        </button>
        <ng-template #finishedSolicitationAttachments>
          <button class="attach-button" (click)="showAttachedDocumentsOnFinished()"
          >
            <img src="assets/images/blue-paperclip.svg" alt="">
            <span>Anexos</span>
          </button>
        </ng-template>
    </div>
</td>
<td class="input-rps">
    <div class="row" *ngIf="numeroRps">
        <div class="col-7">
            <input type="text" [placeholder]="isNumeroRPSEnabled ? 'Digite aqui' : ''" maxlength="10" [formControl]="numeroRps">
        </div>
        <div class="col-5 grid-action-buttons">
            <button class="send-button" [disabled]="!isEnviarNumeroRPSEnabled" (click)="sendRpsNumber()">Enviar</button>
        </div>
    </div>
</td>
<td>
    <div dropdown container="body">
        <a bsDropdownToggle *ngIf="isConfirmarLiberacaoFinanceiraEnabled || isRecusarLiberacaoFinanceiraEnabled"><i class="fa fa-ellipsis-v"></i></a>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <li class="menu-item" *ngIf="isConfirmarLiberacaoFinanceiraEnabled">
                <a class="dropdown-item" (click)="setFinancialLiberation()">Confirmar</a>
            </li>
            <li *ngIf="isRecusarLiberacaoFinanceiraEnabled" class="menu-item">
                <a class="dropdown-item" (click)="openModalForRefusingSolicitation()">Recusar</a>
            </li>
        </ul>
    </div>
</td>

<!-- <div class="grid-action-buttons">
    <button class="send-button" [disabled]="!isConfirmarLiberacaoFinanceiraEnabled"
      (click)="setFinancialLiberation()"
    >
      Confirmar
    </button>
    <button *ngIf="solicitation.status != 'RECUSADO' " class="send-button ml-2"
      (click)="openModalForRefusingSolicitation()"
    >
      Recusar
    </button>
  </div> -->
<!-- </td> -->

<app-modal-resposta-positiva-negativa #modalConfirmarLiberacaoFinanceira [modalTitle]="'Confirmação'" [positiveText]="'Sim'" [negativeText]="'Não'" (onChoosePositiveAction)="confirmFinancialLiberation()">
    <p>
        Deseja confirmar a liberação financeira? (S/N)
    </p>
</app-modal-resposta-positiva-negativa>

<app-modal-recusa-liberacao #modalRecusaLiberacao [modalTitle]="'Recusar liberação'" [positiveText]="'Recusar'" [negativeText]="'Cancelar'" [optionalParam]="'Faturamento'" (onChoosePositiveAction)="confirmRefusingSolicitation($event)">

</app-modal-recusa-liberacao>

<app-modal-mensagem #modalJustificativaRecusa></app-modal-mensagem>
