<td *ngIf="numeroConteiner">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="numeroConteiner" readonly
    >
  </div>
</td>
<td>
  <ngContainer [ngTemplateOutlet]="shouldBlockPartQuantityEditionByDocumentType ? numeroCEField : numeroDocumentoField"></ngContainer>
</td>
<td *ngIf="shouldBlockPartQuantityEditionByDocumentType">
  <ngContainer [ngTemplateOutlet]="numeroDocumentoField"></ngContainer>
</td>

<ng-template #numeroDocumentoField>
  <div class="input-group" *ngIf="numeroDocumento">
    <input *ngIf="mascara?.modelo; else numeroDocumentoFieldNoMask" type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="numeroDocumento" [mask]="mascara?.modelo ?? ''" [specialCharacters]="mascara?.caracteresEspeciais ?? []"
      [ngClass]="{'border border-danger': numeroDocumento.touched && numeroDocumento.errors}" (change)="onFirstEdit.emit()"
    >
    <ng-template #numeroDocumentoFieldNoMask>
      <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
        [formControl]="numeroDocumento"
        [ngClass]="{'border border-danger': numeroDocumento.touched && numeroDocumento.errors}" (change)="onFirstEdit.emit()"
      >
    </ng-template>
  </div>
</ng-template>

<ng-template #numeroCEField>
  <div class="input-group" *ngIf="numeroCE">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="numeroCE" [mask]="'000000000000000'"
      [ngClass]="{'border border-danger': numeroCE.touched && numeroCE.errors}" (change)="onFirstEdit.emit()"
    >
  </div>
</ng-template>
