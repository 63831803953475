import { MaskPipe } from 'ngx-mask';
import { DocumentosService } from 'src/app/services/documentos.service';
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Campo } from 'src/app/models/Campo';
import { DadosComplementaresEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/DadosComplementaresEstimativaCalculo';
import { Moeda } from 'src/app/models/Moeda';
import { debounceTime } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DadosNavioVigemDTO } from 'src/app/models/SolicitacaoLiberacaoCabotagem/DadosNavioVigemDTO';

@Component({
  selector: 'app-dados-complementares-estimativa-calculo',
  templateUrl: './dados-complementares-estimativa-calculo.component.html',
  styleUrls: ['./dados-complementares-estimativa-calculo.component.scss']
})
export class DadosComplementaresEstimativaCalculoComponent implements OnInit {

  @Input() fieldSettings!: Campo[];
  @Input() public visualizar?: boolean = false
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Output() public onUpdateComplementaryData: EventEmitter<{}> = new EventEmitter<{}>();

  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;
  public moedas: Moeda[] = [];
  private readonly colorTheme = 'theme-dark-blue';
  public bsConfig?: Partial<BsDatepickerConfig>;
  private readonly locale = 'pt-br';

  constructor(
    private fb: UntypedFormBuilder,
    private authentication: AuthenticationService,
    private localeService: BsLocaleService,
    private documentosService: DocumentosService,
    private changeDetector: ChangeDetectorRef,
    private maskPipe: MaskPipe
    ) { }

  public ngOnInit() {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.documentosService.getMoedas().subscribe({
      next: (moedas) =>{
        this.moedas = moedas
      }
    });
    this.validation();
  }

  private validation(): void {
    this.form = this.fb.group({
      desembaracoAntecipado: [""],
      previsaoRetiradaChegada: [""],
      valorMercadoria: [""],
      moeda: [""],
      modalidadeCabotagem: [""],
      unidadeDespacho: [""],
      unidadeEmbarque: [""],
      recinto: [""],
      valorCIF: [""],
      solicitante: [''],
      emailSolicitante: ["", [Validators.email]],
      booking: [""],
      bl: [""],
      telefone: [""],
      leilao: ['', [this.ValidaLeilao.bind(this)]],
      lote: ['']
    });
    this.form.valueChanges
      .pipe(
        debounceTime(100)
      )
      .subscribe(
        () => {
          this.updateComplementaryData();
        }
      );
      this.validateBlockAndDataOnSolicitante();
  }

  public checkIfFieldIsRequired(fieldName: string): boolean{
    return !!this.fieldSettings?.find(
      campo => campo.nome == fieldName
    )?.obrigatorio;
  }

  public checkIfFieldIsReadonly(fieldName: string): boolean{
    return !!this.fieldSettings?.find(
      campo => campo.nome == fieldName
    )?.leitura;
  }

  public validateBlockAndDataOnSolicitante(): void{
    let solicitante = this.form.get('solicitante');
    let emailSolicitante = this.form.get('emailSolicitante');
    if(this.visualizar){

    }
    else{
      if(!emailSolicitante?.value?.length){
        emailSolicitante?.setValue(this.authentication.userEmail);
      }
      emailSolicitante?.enable({emitEvent: false});
      solicitante?.setValue(this.authentication.fullName);
      solicitante?.disable({emitEvent: false});
    }
  }

  public setFormFieldsFromReceivedComplementaryData(complementaryData: DadosComplementaresEstimativaCalculo | undefined): void{
    if(!complementaryData){
      return;
    }
    this.form.get('desembaracoAntecipado')?.setValue(complementaryData?.desembaracoAntecipado ? complementaryData?.desembaracoAntecipado : this.form.value.desembaracoAntecipado);
    if(complementaryData?.previsaoRetiradaChegada)
      this.form.get('previsaoRetiradaChegada')?.setValue(new Date(complementaryData?.previsaoRetiradaChegada));
    this.form.get('valorMercadoria')?.setValue(complementaryData?.valorMercadoria ? complementaryData?.valorMercadoria : this.form.value.valorMercadoria);
    if(complementaryData?.moeda){
      this.form.get('moeda')?.setValue(
        this.moedas.find(moeda => moeda?.id == complementaryData?.moeda?.id ?? 0)
      );
    }
    this.form.get('modalidadeCabotagem')?.setValue(complementaryData?.modalidadeCabotagem ? complementaryData?.modalidadeCabotagem : this.form.value.modalidadeCabotagem);
    this.form.get('unidadeDespacho')?.setValue(complementaryData?.unidadeDespacho ? complementaryData?.unidadeDespacho : this.form.value.unidadeDespacho);
    this.form.get('unidadeEmbarque')?.setValue(complementaryData?.unidadeEmbarque ? complementaryData?.unidadeEmbarque : this.form.value.unidadeEmbarque);
    this.form.get('recinto')?.setValue(complementaryData?.recinto ? complementaryData?.recinto : this.form.value.recinto);
    this.form.get('valorCIF')?.setValue(complementaryData?.valorCIF ? complementaryData?.valorCIF: this.form.value.valorCIF);
    this.form.get('solicitante')?.setValue(complementaryData?.solicitante ? complementaryData?.solicitante : this.form.value.solicitante);
    this.form.get('emailSolicitante')?.setValue(complementaryData?.emailSolicitante ? complementaryData?.emailSolicitante : this.form.value.emailSolicitante);
    this.form.get('bl')?.setValue(complementaryData?.bl ? complementaryData?.bl : this.form.value.bl);
    this.form.get('booking')?.setValue(complementaryData?.booking ? complementaryData?.booking: this.form.value.booking);
    this.form.get('telefone')?.setValue(
      complementaryData?.telefone ?
      this.maskPipe.transform(complementaryData?.telefone, '(00) 0 0000-0000||(00) 0000-0000') :
      this.form.value.telefone
    );
    this.form.get('leilao')?.setValue(complementaryData?.leilao ? complementaryData?.leilao : this.form.value.leilao);
    this.form.get('lote')?.setValue(complementaryData?.lote ? complementaryData?.lote : this.form.value.lote);
    this.changeDetector.detectChanges();
    this.validateBlockAndDataOnSolicitante();
  }

  public updateComplementaryData(): void{
    this.onUpdateComplementaryData.emit({'dadosComplementares': this.form.getRawValue()});
  }

  public isComplementaryDataValid(): boolean{
    return !this.form?.invalid;
  }

  public updateValidators(): void{
    if (this.visualizar) {
      this.form.disable({emitEvent: false});
      this.changeDetector.detectChanges();
      this.validateBlockAndDataOnSolicitante();
      return;
    }
    if(!this.fieldSettings?.length){
      this.enableAllFormFields();
      this.validateBlockAndDataOnSolicitante();
      return;
    }
    for(let field in this.form.controls){
      let fieldSetting: Campo = this.fieldSettings.find(
        (setting) => setting.nome == field
      ) as Campo;
      if(fieldSetting){
        if(fieldSetting.obrigatorio){
          this.form.get(field)?.addValidators(Validators.required);
        }
        else{
          this.form.get(field)?.removeValidators(Validators.required);
        }
        if(this.doesSolicitationHaveIntegration){
          if(fieldSetting.leitura && this.form.get(field)?.value){
            this.form.get(field)?.disable();
          }
          else{
            this.form.get(field)?.enable();
          }
        }
        else{
          if(fieldSetting.leitura){
            this.form.get(field)?.disable();
          }
          else{
            this.form.get(field)?.enable();
          }
        }
        if(fieldSetting.bloqueado){
          this.form.get(field)?.disable();
        }
        if(!this.form.get(field)?.value && fieldSetting.obrigatorio){
          this.form.get(field)?.enable();
        }
      }
      else{
        this.form.get(field)?.removeValidators(Validators.required);
        this.form.get(field)?.enable();
      }
      this.changeDetector.detectChanges();
      this.showFieldAsRequired(field);
    }
    this.validateBlockAndDataOnSolicitante();
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.form.controls){
      this.form.get(field)?.enable();
      this.form.get(field)?.removeValidators(Validators.required);
      this.showFieldAsRequired(field);
      this.changeDetector.detectChanges();
    }
  }

  private showFieldAsRequired(field: string): void{
    if(!!this.form.get(field)?.hasValidator(Validators.required)){
      document.getElementById(`${field}-required-label`)?.classList?.remove('invisible');
      document.getElementById(`${field}-required-label`)?.classList?.add('visible');
    }
    else{
      document.getElementById(`${field}-required-label`)?.classList?.remove('visible');
      document.getElementById(`${field}-required-label`)?.classList?.add('invisible');
    }
    this.changeDetector.detectChanges();
  }

  public clearFields(): void{
    this.form.reset();
  }

  public setValuesFromDIXML(xml: any): void{
    this.form.patchValue({
      valorMercadoria: parseFloat(xml.ListaDeclaracoes.declaracaoImportacao.localDescargaTotalReais._text)/100,
      moeda: this.moedas.find(moeda => moeda.nome == 'Real')
    }, {emitEvent: false});
  }

  public setValuesFromCTEXML(xml: Partial<DadosComplementaresEstimativaCalculo>): void{
    this.form.patchValue({
      valorMercadoria: xml.valorMercadoria,
      moeda: this.moedas.find(moeda => moeda.nome == 'Real')
    }, {emitEvent: false});
  }

  public setValuesFromNFXML(xml: any): void{
    this.form.patchValue({
      valorMercadoria: xml.nfeProc.NFe.infNFe.total.ICMSTot.vNF._text,
      moeda: this.moedas.find(moeda => moeda.nome == 'Real')
    }, {emitEvent: false});
  }

  public getComplementaryDataFromFormFields(): DadosComplementaresEstimativaCalculo{
    return this.form.getRawValue() as DadosComplementaresEstimativaCalculo;
  }

  public ValidaLeilao(control: AbstractControl) : {leilaoInvalido: string} | null{
    if(!control){
      return null;
    }
    let leilaoNumber = control.value as string;
    let onlyDigitLeilaoNumber = leilaoNumber;
    if(!leilaoNumber || !leilaoNumber.length){return null;}
    onlyDigitLeilaoNumber = onlyDigitLeilaoNumber.split('/').join('');

    if(onlyDigitLeilaoNumber.length === 17 ){

      let onlyYear = leilaoNumber.split('/')[2]
      let yearNow = (new Date()).getFullYear()

      if((+onlyYear-2)===(yearNow-2)){
        return null
      }else{
        return {leilaoInvalido: ''}
      }
    }
    return {leilaoInvalido: ''};
  }

  public setShipAndTripFromSearchByContainerNumber(shipAndTrip: DadosNavioVigemDTO): void{
    this.form?.patchValue({
      navio: shipAndTrip?.navio ? shipAndTrip.navio : this.form?.value?.navio,
      viagem: shipAndTrip?.viagem ? shipAndTrip.viagem : this.form?.value?.viagem,
      booking: shipAndTrip?.booking ? shipAndTrip.booking : this.form?.value?.booking
    });
  }

}
