import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { Campo } from 'src/app/models/Campo';
import { TipoDocumentoAnexado } from 'src/app/models/DocumentosAnexados/TipoDocumentoAnexado';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { SuccessResponse } from 'src/app/models/HttpResponses/SuccessResponse';
import { DocumentoParteLiberacaoDocumental } from 'src/app/models/LiberacaoDocumental/DocumentoParteLiberacaoDocumental';
import { PesquisaExpansaoDocumentoParte } from 'src/app/models/LiberacaoDocumental/PesquisaExpansaoDocumentoParte';
import { Mascara } from 'src/app/models/Mascara';
import { ConteinerCabotagem } from 'src/app/models/SolicitacaoLiberacaoCabotagem/ConteinerCabotagem';
import { SolicitacaoLiberacaoCabotagem } from 'src/app/models/SolicitacaoLiberacaoCabotagem/SolicitacaoLiberacaoCabotagem';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ToastService } from 'src/app/services/toast.service';
import { DocumentoCabotagemComponent } from 'src/app/shared/components/documento-cabotagem/documento-cabotagem.component';
import {
  DocumentosAnexadosComponent,
} from 'src/app/shared/components/DocumentosAnexados/documentos-anexados/documentos-anexados.component';
import { documentTypesToBlockPartQuantityCab } from 'src/app/shared/utils/document-types-to-block-part-quantity';

import { ClienteComponent } from '../../../shared/components/cliente/cliente.component';
import {
  ArvorePartesLiberacaoDocumentalComponent,
} from '../../liberacao-documental/arvore-partes/arvore-partes-liberacao-documental/arvore-partes-liberacao-documental.component';
import {
  DadosComplementaresCabotagemComponent,
} from '../../solicitacao-liberacao-cabotagem/dados-complementares-cabotagem/dados-complementares-cabotagem.component';
import {
  RelacaoConteinerCabotagemComponent,
} from '../../solicitacao-liberacao-cabotagem/relacao-conteiner-cabotagem/relacao-conteiner-cabotagem.component';
import { SolicitacaoLiberacaoCabotagemService } from './../../../services/solicitacao-liberacao-cabotagem.service';
import { Moeda } from 'src/app/models/Moeda';

@Component({
  selector: 'app-visualizar-solicitacoes-cabotagem',
  templateUrl: './visualizar-solicitacoes-cabotagem.component.html',
  styleUrls: ['./visualizar-solicitacoes-cabotagem.component.scss']
})
export class VisualizarSolicitacoesCabotagemComponent implements OnInit {

  @Output() onExpandPartDocument: EventEmitter<PesquisaExpansaoDocumentoParte> = new EventEmitter<PesquisaExpansaoDocumentoParte>();
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSaveSolicitation: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(DocumentoCabotagemComponent) documento!: DocumentoCabotagemComponent;
  @ViewChild(ClienteComponent) cliente!: ClienteComponent;
  @ViewChild(DadosComplementaresCabotagemComponent) dadosComplementares!: DadosComplementaresCabotagemComponent;
  @ViewChild(RelacaoConteinerCabotagemComponent) relacaoConteineres!: RelacaoConteinerCabotagemComponent;
  @ViewChild(ArvorePartesLiberacaoDocumentalComponent) arvoreDePartes!: ArvorePartesLiberacaoDocumentalComponent;
  @ViewChild(DocumentosAnexadosComponent) documentosAnexados!: DocumentosAnexadosComponent;

  protected solicitationId: number = 0;
  protected camposFormulario: Campo[] = [];
  protected shouldBlockPartQuantityEditionByDocumentType: boolean = false;
  protected blockEdition: boolean = true;
  protected mascara!: Mascara;
  protected moedas: Moeda[] = [];
  private readonly documentTypesToBlockPartQuantityEdition: string[] = [...documentTypesToBlockPartQuantityCab];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private documentosService: DocumentosService,
    public solicitacaoLiberacaoCabotagemService: SolicitacaoLiberacaoCabotagemService,
    private spinner: NgxSpinnerService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {

  }

  public openModal(solicitacaoLiberacao : SolicitacaoLiberacaoCabotagem) {
    this.solicitationId = solicitacaoLiberacao.id ?? 0;
    this.blockEdition = solicitacaoLiberacao.status?.toUpperCase() !== 'PENDENTE';
    this.shouldBlockPartQuantityEditionByDocumentType = this.documentTypesToBlockPartQuantityEdition.includes(solicitacaoLiberacao.documento?.tipoDocumento ?? '');
    this.documento.setDocumentTypes(this.getDocumentTypes());
    if(!this.blockEdition){
      this.setFormConfigurations(solicitacaoLiberacao.documento?.tipoDocumento!!);
    }
    this.changeDetector.detectChanges();
    this.setFormvaluesFromSolicitation(solicitacaoLiberacao);
    this.changeDetector.detectChanges();
  }

  public getDocumentTypes(): Observable<SuccessResponse>{
    return this.documentosService.GetDocumentsForSolicitacaoLiberacaoCabotagem();
  }

  public getAttachedFilesTypes(): Observable<TipoDocumentoAnexado[]>{
    return this.solicitacaoLiberacaoCabotagemService.getAttachmentDocumentsForCabotageLiberationByDocumentType();
  }

  public closeModal(): void{
    this.onCloseModal.emit();
  }

  protected getPartDocumentsFromContainers(containers: ConteinerCabotagem[]): DocumentoParteLiberacaoDocumental[]{
    return containers
      .filter((container) => container.documentos?.length)
      .flatMap((container) => {
        return container.documentos!.map((documentoParte) => {
          return {
            numeroConteiner: container.numeroConteiner,
            numeroDocumento: documentoParte.numeroDocumento,
            status: documentoParte.status ?? '',
            numeroCE: documentoParte.numeroCE ?? ''
          };
        });
    });
  }

  protected expandPartDocument(documentoParte: PesquisaExpansaoDocumentoParte): void{
    this.onExpandPartDocument.emit({
      ...documentoParte,
      tipoDocumento: this.documento?.getCabotageDocumentFromForm()?.tipoDocumento,
      nomeRegimeCirculacao: 'Cabotagem',
      idRegimeCirculacao: 3
    });
  }

  protected getSolicitationFromForms(): SolicitacaoLiberacaoCabotagem{
    let solicitation: SolicitacaoLiberacaoCabotagem = {};
    solicitation.id = this.solicitationId;
    solicitation.documento = this.documento?.getCabotageDocumentFromForm();
    solicitation.cliente = this.cliente?.getClientFromForm();
    solicitation.dadosComplementares = this.dadosComplementares?.getCabotageComplementaryDataFromForm();
    solicitation.relacaoConteineres = this.relacaoConteineres?.getContainersListFromForm();
    solicitation.arvorePartes = this.arvoreDePartes?.getDocumentosParteFromFormFields();
    solicitation.documentosAnexosLiberacao = this.documentosAnexados.getAllDocumentsWithAttachedFiles();
    return solicitation;
  }

  protected setFormvaluesFromSolicitation(solicitation: SolicitacaoLiberacaoCabotagem): void{
    if(!solicitation.documento?.numeroCE && solicitation.dadosComplementares?.numeroCE){
      solicitation.documento!.numeroCE = solicitation.dadosComplementares?.numeroCE;
    }
    this.documento?.setFormValuesForModal(solicitation.documento);
    this.cliente?.setFormValuesFromReceivedClient(solicitation.cliente);
    this.dadosComplementares?.setFormValuesFromReceivedComplementaryData(solicitation.dadosComplementares);
    this.relacaoConteineres?.setFormValuesFromReceivedContainerRelation(solicitation.relacaoConteineres);
    this.arvoreDePartes?.setFormvaluesFromReceivedPartDocuments(this.getPartDocumentsFromContainers(solicitation.relacaoConteineres ?? []));
    this.documentosAnexados.setAttachedDocumentTypes(
      solicitation?.documento?.id!,
      solicitation?.documentosAnexosLiberacao ?? [],
      this.getAttachedFilesTypes()
    );
  }

  protected setFormSettings(): void{
    this.cliente?.setSettingsfromFields();
    this.dadosComplementares?.setSettingsfromFields();
    this.relacaoConteineres?.setSettingsfromFields();
  }

  protected get areAllFormsValid(): boolean{
    return this.documento?.isDocumentValid() &&
      this.cliente?.isClientValid() &&
      this.dadosComplementares?.areComplementaryDataValid() &&
      this.documentosAnexados?.areAllFilesValid &&
      this.relacaoConteineres?.areAllContainersValid &&
      this.arvoreDePartes?.areAllPartDocumentsValid;
  }

  protected saveSolicitation(): void{
    if(!this.areAllFormsValid){
      return;
    }
    const solicitation = this.getSolicitationFromForms();
    this.spinner.show();
    this.solicitacaoLiberacaoCabotagemService.saveSolicitationOnLiberationModal(solicitation)
      .subscribe({
        next: (result) => {
          result.message?.forEach((msg) => {
            this.toastService.success(msg)
          });
          this.uploadFiles();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  private setFormConfigurations(tipoDocumento: string): void{
    this.camposFormulario = [];
    this.documentosService
      .getFormFieldsByDocumentTypeInSolicitacaoLiberacaoCabotagem(tipoDocumento)
      .subscribe({
        next: (result) => {
          this.camposFormulario = result.data;
          this.changeDetector.detectChanges();
          this.setFormSettings();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
          this.changeDetector.detectChanges();
        }
      })
  }

  protected uploadFiles(): void{
    var documentos = this.documentosAnexados?.getAllDocumentsWithAttachedFiles();
    if(!documentos || !documentos.length){
      this.spinner.hide();
      this.onSaveSolicitation.emit();
      return;
    }
    this.solicitacaoLiberacaoCabotagemService.arquivoUploadSolicitacaoLiberacaoCabotagem(documentos, this.solicitationId).subscribe(
    {
      next: (msg) => {
        this.spinner.hide();
        msg?.message?.forEach(
          (message) => {
            this.toastService.success(message);
          }
        );
        this.onSaveSolicitation.emit();
    },
      error: (err: HttpErrorResponse) =>{
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      }
    }
  );
  }

  public setSelectOptions(moedas: Moeda[]): void{
    this.moedas = moedas;
    this.changeDetector.detectChanges();
  }

}
