import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cliente } from '../models/Cliente';
import { Documento } from '../models/Documento';
import { SuccessResponse } from '../models/HttpResponses/SuccessResponse';
import { CadastroDocumentoParteEXPDTO } from '../models/SolicitacaoLiberacaoExportacao/CadastroDocumentoParteEXPDTO';
import { SolicitacaoLiberacaoExportacao } from '../models/SolicitacaoLiberacaoExportacao/SolicitacaoLiberacaoExportacao';
import { TipoDocumentoAnexado } from '../models/DocumentosAnexados/TipoDocumentoAnexado';
import { ArquivoDocumentoAnexado } from '../models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { ConteinerEXP } from '../models/SolicitacaoLiberacaoExportacao/ConteinerEXP';
import { AuthenticationService } from './authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoLiberacaoExportacaoService {

  constructor(
    private http: HttpClient,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private authenticationService: AuthenticationService
  ) { }

  public saveExportacaoLiberationSolicitation(solicitation: SolicitacaoLiberacaoExportacao): Observable<any>{
    if(solicitation.documentosAnexosLiberacao?.length){
      solicitation.documentosAnexosLiberacao?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    return this.http.post(`${environment.apiUrlForSolicitacaoLiberacaoExp}/SolicitacaoLiberacaoExp?emailUserLogado=${this.authenticationService.userEmail}`, solicitation);
  }

  public cancelExportacaoLiberationSolicitationBySolicitationId(solicitation: SolicitacaoLiberacaoExportacao): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/SolicitacaoLiberacaoExp/${solicitation.id}/Cancelar`, solicitation.id);
  }

  public getExportacaoLiberationSolicitationDataByDocument(document: Documento): Observable<SuccessResponse>{
    let query: string[] = [];
    query.push('confirmado=false');
    if(document?.numeroDUE?.length){
      query.push(
        document.numeroDUE.map(
          (numeroDUE) => `numeroDUE=${numeroDUE}`
        )?.join('&')
      );
    }
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/SolicitacaoLiberacaoExp/Pesquisar/${document.id}/${document.numeroDocumento}?${query.join('&')}`);
  }

  public confirmCreateExportacaoLiberationSolicitation(document: Documento): Observable<SuccessResponse>{
    let query: string[] = [];
    query.push('confirmado=true');
    if(document?.numeroDUE?.length){
      query.push(
        document.numeroDUE.map(
          (numeroDUE) => `numeroDUE=${numeroDUE}`
        )?.join('&')
      );
    }
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/SolicitacaoLiberacaoExp/Pesquisar/${document.id}/${document.numeroDocumento}?${query.join('&')}`);
  }

  public savePartDocument(cadastroDocumentoParte: CadastroDocumentoParteEXPDTO, correlationId: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlationId);
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/SolicitacaoLiberacaoEXP/DocumentoParte`, cadastroDocumentoParte, {headers});
  }

  public getCliente(client: Cliente): Observable<SuccessResponse>{
    let query: string[] = [];
    if(client.cpfCnpj && client.cpfCnpj.length){
      query.push(`CpfCnpj=${this.verificacaoDocumentosService.eraseMask(client.cpfCnpj)}`);
    }
    if(client.nomeCliente && client.nomeCliente.length){
      query.push(`nome=${client.nomeCliente}`);
    }
    return this.http.get<SuccessResponse>(
      `${environment.apiUrlForSolicitacaoLiberacaoExp}/api/Cliente?${query.join('&')}`
    );
  }

  public saveDraft(SolicitacaoLiberacaoExportacao: SolicitacaoLiberacaoExportacao): Observable<SuccessResponse>{
    if(SolicitacaoLiberacaoExportacao.documentosAnexosLiberacao?.length){
      SolicitacaoLiberacaoExportacao.documentosAnexosLiberacao?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/SolicitacaoLiberacaoExp/Rascunho`, SolicitacaoLiberacaoExportacao);
  }

  public getAttachmentDocumentsForExpLiberationByDocumentType(): Observable<TipoDocumentoAnexado[]>{
    return this.http.get<TipoDocumentoAnexado[]>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/DocumentosLiberacao`);
  }

  public arquivoUploadSolicitacaoLiberacaoExp(dados: ArquivoDocumentoAnexado[], solicitacaoId: number): Observable<SuccessResponse>{
    const formData = new FormData();
    dados.forEach(e => {
      var arq = e.arquivoDocumento as File;
      var id = e.tipoDocumento?.idDocumento.toString() ? e.tipoDocumento?.idDocumento.toString() : '';
      if(arq?.size){
        formData.append('files', arq);
        formData.append('id', id);
      }
    });
    return this.http
      .post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/UploadArquivosSolicitacao/uploadfiles/${solicitacaoId}`, formData);
  }

  public downloadFileForAttachedDocumentGrid(arquivo: ArquivoDocumentoAnexado): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/UploadArquivosSolicitacao/BaixarArquivo?filePath=${arquivo.filePath}&fileName=${arquivo.nomeArquivo}&contentType=${arquivo.contentType}`);
  }
  public getTelefoneUsuarioExterno(): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/SolicitacaoLiberacaoExp/ObterInformacoesUsuarioLogado/`+this.authenticationService.userEmail);
  }

  public downloadFileForAttachedDocumentGridOnModal(arquivo: ArquivoDocumentoAnexado): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/UploadArquivosSolicitacao/BaixarArquivo?idSolicitacao=${arquivo.idSolicitacao}&filePath=${arquivo.filePath}&fileName=${arquivo.nomeArquivo}&contentType=${arquivo.contentType}`);
  }

  public postCancelarSolicitacao(solicitacaoLiberacaoImp: SolicitacaoLiberacaoExportacao, conteiner: ConteinerEXP): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/SolicitacaoLiberacaoExp/ExcluirConteiner/${solicitacaoLiberacaoImp.id}/${conteiner.conteiner}`, null);
  }

  public searchShipAndTripByContainerNumber(containerNumber: string): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/SolicitacaoLiberacaoExp/BuscarNavioViagem/${containerNumber}`);
  }

  public saveSolicitationOnLiberationModal(solicitation: SolicitacaoLiberacaoExportacao): Observable<SuccessResponse>{
    if(solicitation.documentosAnexosLiberacao?.length){
      solicitation.documentosAnexosLiberacao?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    return this.http.put<SuccessResponse>(
      `${environment.apiUrlForSolicitacaoLiberacaoExp}/SolicitacaoLiberacaoExp/Exp`,
      solicitation
    );
  }
}
