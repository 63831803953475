<app-title [title]="title"></app-title>
<app-documento-edt (onSearchDocument)="searchTransportDocumentData($event)"></app-documento-edt>
<app-dados-documento-transporte-n4-edt></app-dados-documento-transporte-n4-edt>
<app-dados-documento-transporte-cct-edt></app-dados-documento-transporte-cct-edt>
<app-dados-envio-edt></app-dados-envio-edt>
<div class="action-button-group action-double-buttons mt-4 w-75 ml-auto mr-auto">
    <button class="action-secondary-button" (click)="clear()">LIMPAR DADOS</button>
    <!-- <button class="action-primary-button" [auth]="this.salvar" [disabled]="!isSalvarEnabled" (click)="checkDataSendingToSave()">SALVAR</button> -->
    <button class="action-primary-button" [auth]="this.enviarCCT" [disabled]="!isEnviarCCTEnabled" (click)="checkDataSendingToCCT()">ENVIAR AO CCT</button>

</div>

<app-modal-resposta-positiva-negativa #modalConfirmarEnviarCCTComDivergencias [modalTitle]="'Divergências encontradas'" [negativeText]="'Recusar'" [positiveText]="'Confirmar'" (onChoosePositiveAction)="sendDataToCCT()">
    <div class="row mb-3">
        <div class="col">
            <p>“Foi(ram) encontrada(s) divergência(s) dos Dados de Transporte entre N4 x CCT. Confirma envio com dados do físico?”</p>
        </div>
    </div>
</app-modal-resposta-positiva-negativa>

<app-modal-resposta-positiva-negativa #modalConfirmarSalvarComDivergencias [modalTitle]="'Divergências encontradas'" [negativeText]="'Recusar'" [positiveText]="'Confirmar'" (onChoosePositiveAction)="save()">
    <div class="row mb-3">
        <div class="col">
            <p>“Foi(ram) encontrada(s) divergência(s) dos Dados de Transporte entre N4 x CCT. Confirma envio com dados do físico?”</p>
        </div>
    </div>
</app-modal-resposta-positiva-negativa>
