import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RelacaoConteinerTransitoSimplificado } from '../models/RecepcaoDueContingencia/ConteinerTransitoSimplificado/RelacaoConteinerTransitoSimplificado';
import { SuccessResponse } from '../models/HttpResponses/SuccessResponse';
import { ConteinerRecepcaoDocumentoTransporteDTO } from '../models/RecepcaoDocumentoTransporte/ConteinerRecepcaoDocumentoTransporteDTO';

@Injectable({
  providedIn: 'root'
})
export class ConteinerTransitoSimplificadoService {

  constructor(
    private http: HttpClient
  ) { }

  public getConteiners(conteinerNumber: string): Observable<SuccessResponse> {
    return this.http.get<SuccessResponse>(`${environment.apiUrlForRecepcaoDue}/api/TransitoSimplificado/conteiner/${conteinerNumber}`);
  }
  public sendDataToCCTForRecepcaoTransitoSimplificado(conteinerEnvioCCT: RelacaoConteinerTransitoSimplificado): Observable<SuccessResponse>{
    console.log(conteinerEnvioCCT)
    return this.http.post<SuccessResponse>(`${environment.apiUrlForRecepcaoDue}/api/TransitoSimplificado/enviarCCT`, conteinerEnvioCCT);
  }

  public saveDataToCCTForRecepcaoTransitoSimplificado(conteinerEnvioCCT: RelacaoConteinerTransitoSimplificado): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForRecepcaoDue}/api/TransitoSimplificado/salvar`, conteinerEnvioCCT);
  }

}
