import { ModalDetalhesRpaApiComponent } from './../../components/monitoramento-rpa-api/modal-detalhes-rpa-api/modal-detalhes-rpa-api.component';
import { ErrorResponse } from './../../models/HttpResponses/ErrorResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'src/app/services/toast.service';
import { GridRpaApi } from './../../models/MonitoramentoRpaApi/GridRpaApi';
import { MonitoramentoRpaApiService } from './../../services/monitoramento-rpa-api.service';
import { GridMonitoramentoRpaApiComponent } from './../../components/monitoramento-rpa-api/grid-monitoramento-rpa-api/grid-monitoramento-rpa-api.component';
import { PesquisaMonitoramentoRpaApiComponent } from './../../components/monitoramento-rpa-api/pesquisa-monitoramento-rpa-api/pesquisa-monitoramento-rpa-api.component';
import { PesquisaRpaApi } from './../../models/MonitoramentoRpaApi/PesquisaRpaApi';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-monitoramento-rpa-api',
  templateUrl: './monitoramento-rpa-api.component.html',
  styleUrls: ['./monitoramento-rpa-api.component.scss']
})
export class MonitoramentoRpaApiComponent implements OnInit {

  public readonly title: string = `Monitoramento de RPA's / API's`;
  @ViewChild(PesquisaMonitoramentoRpaApiComponent) pesquisa!: PesquisaMonitoramentoRpaApiComponent;
  @ViewChild(GridMonitoramentoRpaApiComponent) grid!: GridMonitoramentoRpaApiComponent;
  @ViewChild(ModalDetalhesRpaApiComponent) modalDetalhesRpaApi!: ModalDetalhesRpaApiComponent;
  public readonly visualizacao = new AuthRoles(Telas.monitoramentoRpaApi, Permissoes.visualizacao)

  constructor(
    private monitoramentoRpaApiService: MonitoramentoRpaApiService,
    private toastService:ToastService,
    private spinner: NgxSpinnerService,

  ) { }

  public ngOnInit(): void {
  }

  public searchForRpaApiInfo(searchFilter: PesquisaRpaApi): void{
    this.spinner.show();
    this.monitoramentoRpaApiService.searchForRpaAndApiByNameOrPeriod(searchFilter)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          let rpaApiList: GridRpaApi[] = result.data as GridRpaApi[];
          this.grid.setRpaApiList(rpaApiList, searchFilter);
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = err?.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public openRpaApiDetailsModal(rpaApi: PesquisaRpaApi): void{
    this.modalDetalhesRpaApi.openModal(rpaApi);
  }

}
