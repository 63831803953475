<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Dados do Envio</span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}"
        data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false"
        aria-label="Toggle navigation"></i>
      <!--fa fa-chevron-down-->
    </div>
  </div>

  <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <form *ngIf="form" [formGroup]="form">
      <div class="p-3">
        <div class="row">
          <div class="col-3">
            <label>Data da Solicitação:</label>
            <input type="text"  formControlName="dataSolicitacao" bsDatepicker #dpSolicitacao="bsDatepicker" readonly [attr.disabled]="'disabled'"
              class="form-control" aria-describedby="basic-addon2" placeholder="dd/mm/aaaa"
              style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; ">
          </div>
          <div class="col">
            <label>Usuário:</label>
            <input formControlName="usuario" type="text" class="form-control" readonly
              placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2" maxlength="14">
          </div>
          <div class="col">
            <label>Status Recepção: </label>
            <div class="input-group mb-3">
              <input formControlName="status" type="text" class="form-control" readonly
                placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
                maxlength="100">
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
