import { NgxSpinnerService } from 'ngx-spinner';
import { FiltrosPesquisaRelatorioMovimentacaoClientes } from '../../../../models/relatorios/RelatorioMovimentacaoClientes/FiltrosPesquisaRelatorioMovimentacaoClientes';
import { RelatorioMovimentacaoClientesService } from '../../../../services/relatorios/relatorio-movimentacao-clientes.service';
import { RelatorioMovimentacaoClientes } from '../../../../models/relatorios/RelatorioMovimentacaoClientes/RelatorioMovimentacaoClientes';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ExcelService } from 'src/app/services/excel.service';
import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { ImpressaoRelatorioMovimentacaoClientesComponent } from '../impressao-relatorio-movimentacao-clientes/impressao-relatorio-movimentacao-clientes.component';
import { PrintService } from 'src/app/services/relatorios/print.service';

@Component({
  selector: 'app-filtros-pesquisa-grid-relatorio-movimentacao-clientes',
  templateUrl: './filtros-pesquisa-grid-relatorio-movimentacao-clientes.component.html',
  styleUrls: ['./filtros-pesquisa-grid-relatorio-movimentacao-clientes.component.scss']
})
export class FiltrosPesquisaGridRelatorioMovimentacaoClientesComponent implements OnInit {

  public form!: UntypedFormGroup;
  public clientMovementReportGrid!: RelatorioMovimentacaoClientes[];
  public shipServices!: string[];
  protected isPrinting: boolean = false;
  @ViewChild(ImpressaoRelatorioMovimentacaoClientesComponent) protected impressao!: ImpressaoRelatorioMovimentacaoClientesComponent;

  @Output() onGridHasData: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private fb: UntypedFormBuilder,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private relatorioMovimentacaoClientesService: RelatorioMovimentacaoClientesService,
    private spinner: NgxSpinnerService,
    private excelService:ExcelService,
    private toastService:ToastService,
    private changeDetector: ChangeDetectorRef,
    private printService: PrintService
    ) { }

  public ngOnInit(): void {
    this.clientMovementReportGrid = [];
    this.getshipServices();
    this.validation();
    this.onGridHasData.emit(!!this.clientMovementReportGrid.length);
  }

  private validation(): void{
    this.form = this.fb.group(
      {
        cliente: [''],
        cnpjCliente: ['', [this.validateCnpj.bind(this)]],
        navio: [''],
        viagem: [''],
        servicoNavio: ['']
      },
      {
        validators: [this.checkIfNoFieldIsFilled.bind(this)]
      }
    );
  }

  private getshipServices(){
    this.shipServices = [
      'ALCT1NB',
      'STSA',
      'Serviço 3'
    ];
  }

  public clearFields(): void{
    this.validation();
    this.clientMovementReportGrid = [];
  }

  private clearGridData(): void{
    this.clientMovementReportGrid = [];
  }

  public searchClientMovementReport(): void{
    this.clearGridData();
    this.spinner.show();
    let filtrosPesquisa: FiltrosPesquisaRelatorioMovimentacaoClientes = this.form.getRawValue();
    this.relatorioMovimentacaoClientesService.getClientMovementReport(filtrosPesquisa)
      .subscribe({
        next: (response) => {
          this.spinner.hide();
          let relatorio = response.data as RelatorioMovimentacaoClientes[];
          if(relatorio?.length){
            this.clientMovementReportGrid = [...relatorio];
          }
          response?.message?.forEach(
            (msg) => {
              if(msg?.length){
                this.toastService.error(msg);
              }
            }
          );
        },
        error: (err) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        },
        complete: () => {
          this.spinner.hide('carregando');
          this.onGridHasData.emit(!!this.clientMovementReportGrid.length);
        }
      });
  }

  public downloadPDF(title:string){
    this.isPrinting = true;
    this.changeDetector.detectChanges();
    this.impressao.setPrintingData({
      dataInicio: this.form.value.periodo,
      dataFim: this.form.value.periodoAte,
      nomeRelatorio: title,
      dados: this.clientMovementReportGrid,
    });
    this.printService.setPrinting(this.impressao.getTemplate());
    this.changeDetector.detectChanges();
    window.onafterprint = () => {
    this.isPrinting = false;
    this.printService.unsetPrinting();
    }
    setTimeout(() => {
      window.print();
    }, 500);
  }

  public downloadExcel(title: string) {
    let header = [
      { nome: 'Cliente', key: 'cliente', width: 25 },
      { nome: 'Cnpj', key: 'cnpjCliente', width: 25 },
      { nome: 'Navio', key: 'navio', width: 25 },
      { nome: 'Viagem', key: 'viagem', width: 30 },
      { nome: 'Servico', key: 'servicoNavio', width: 30 },
      { nome: 'Qtde de Contêiner', key: 'quantidadeConteineres', width: 25 },
      { nome: 'Canal', key: 'canal', width: 25 },
      { nome: 'Escritorio', key: 'escritorio', width: 25 }

    ];
   this.excelService.exportAsExcelFileCustom(this.clientMovementReportGrid,'Movimentaçao-Clientes',header,title)
  }

  private checkIfNoFieldIsFilled(group: UntypedFormGroup): {unfilledForm: boolean} | null{
    if(!group){
      return null;
    }
    let cliente = group.get('cliente')?.value ?? '';
    let cnpjCliente = group.get('cnpjCliente')?.value ?? '';
    let navio = group.get('navio')?.value ?? '';
    let viagem = group.get('viagem')?.value ?? '';
    let servicoNavio = group.get('servicoNavio')?.value ?? '';
    if(!cliente && !cnpjCliente && !navio && !viagem && !servicoNavio){
      return {unfilledForm: true}
    }
    return null;
  }

  private validateCnpj(control: AbstractControl): { invalidDocument: string } | null {
    let controlValue: string = control?.value;
    if (!controlValue?.length) {
      return null;
    }
    let error: string | null;
    let clearDocument: string = this.verificacaoDocumentosService.eraseMask(controlValue);
    if (clearDocument.length == 14) {
      error = this.verificacaoDocumentosService.calculateValidCNPJ(clearDocument);
    }
    else {
      error = 'O nº do CNPJ/CPF informado é inválido. Favor verificar!';
    }
    if (error?.length) {
      return { invalidDocument: error };
    }
    return null;
  }

}
