import { FiltroPesquisaBloqueioDesbloqueio } from './../../models/BloqueioDesbloqueioConteinerEmbarque/filtro-pesquisa-bloqueio-desbloqueio';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BloqueioDesbloqueioConteinerEmbarque } from 'src/app/models/BloqueioDesbloqueioConteinerEmbarque/bloqueio-desbloqueio-conteiner-embarque';
import { BloqueioDesbloqueioConteinerEmbarqueService } from 'src/app/services/bloqueio-desbloqueio-conteiner-embarque.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';
import { DocumentoTransporte } from 'src/app/models/RecepcaoDocumentoTransporte/DocumentoTransporte';
import { Campo } from 'src/app/models/Campo';
import { LinhaGridBloqueioDesbloqueioConteinerEmbarqueComponent } from 'src/app/components/bloqueio-desbloqueio-conteiner-embarque/linha-grid-bloqueio-desbloqueio-conteiner-embarque/linha-grid-bloqueio-desbloqueio-conteiner-embarque.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { ToastService } from 'src/app/services/toast.service';
import { DocumentMaskEnum, DocumentSpecialCharactersForMask } from 'src/app/shared/utils/constantes-documentos';
import { GridConteineresBloqueioDesbloqueioConteinerEmbarqueComponent } from 'src/app/components/bloqueio-desbloqueio-conteiner-embarque/grid-conteineres-bloqueio-desbloqueio-conteiner-embarque/grid-conteineres-bloqueio-desbloqueio-conteiner-embarque.component';
import { ModalRespostaPositivaNegativaComponent } from 'src/app/shared/components/modais/modal-resposta-positiva-negativa/modal-resposta-positiva-negativa.component';
@Component({
  selector: 'app-bloqueio-desbloqueio-conteiner-embarque',
  templateUrl: './bloqueio-desbloqueio-conteiner-embarque.component.html',
  styleUrls: ['./bloqueio-desbloqueio-conteiner-embarque.component.scss']
})
export class BloqueioDesbloqueioConteinerEmbarqueComponent implements OnInit {

  public title: string = 'Bloqueio/Desbloqueio de Contêiner para Embarque';
  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;

   public readonly visualizacao = new AuthRoles(Telas.bloqueioDesbloqueioConteinerEmbarque, Permissoes.visualizacao)
  // public readonly bloquearBtn = new AuthRoles(Telas.bloqueioDesbloqueioConteinerEmbarque, Permissoes.bloquear)
  // public readonly desbloquear = new AuthRoles(Telas.bloqueioDesbloqueioConteinerEmbarque, Permissoes.desbloquear)
  // public readonly solicitarDesbloqueioBtn = new AuthRoles(Telas.bloqueioDesbloqueioConteinerEmbarque, Permissoes.solicitar)
  public readonly maskForDUE: string = DocumentMaskEnum.DUE_MASK;
  public readonly specialCharsForDUE = DocumentSpecialCharactersForMask.DUE_SPECIAL_CHARS;
  //@ViewChildren(LinhaGridBloqueioDesbloqueioConteinerEmbarqueComponent) containersGrid!: QueryList<LinhaGridBloqueioDesbloqueioConteinerEmbarqueComponent>;
  @Output() onSearchContainer: EventEmitter<{} | DocumentoTransporte> = new EventEmitter<{} | DocumentoTransporte>();
  @Output() public onUpdateContainerRelation: EventEmitter<{}> = new EventEmitter<{}>();
  @ViewChild(GridConteineresBloqueioDesbloqueioConteinerEmbarqueComponent) public gridConteineres!: GridConteineresBloqueioDesbloqueioConteinerEmbarqueComponent;
  @ViewChild('modalConfirmarBloquear') public modalConfirmarBloquear!: ModalRespostaPositivaNegativaComponent;
  @ViewChild('modalConfirmarDesbloquear') public modalConfirmarDesbloquear!: ModalRespostaPositivaNegativaComponent;

  constructor(
    private fb: UntypedFormBuilder,
    private service: BloqueioDesbloqueioConteinerEmbarqueService,
    private spinner: NgxSpinnerService,
    private toastService:ToastService
  ) { }

  ngOnInit() {
    this.validation();
  }

  private validation(): void {
    this.form = this.fb.group({
      numeroDUE: [''],
      conteiner: ['']
    });
  }

  pesquisarConteiner() {
    this.spinner.show();
    let filtroPesquisaBloqueioDesbloqueio: FiltroPesquisaBloqueioDesbloqueio = this.form.getRawValue();
    this.service.pesquisarConteiner(filtroPesquisaBloqueioDesbloqueio).subscribe({
      next: (result) => {
        this.gridConteineres?.setListaBloqueioDesbloqueio(result.data as BloqueioDesbloqueioConteinerEmbarque[]);
        this.spinner.hide();
        result?.message?.forEach(
          (msg) => {
            if(msg?.length){
              this.toastService.success(msg);
            }
          }
        );
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        (err.error as ErrorResponse)?.errors?.forEach(
          (error) => {
            if (error && error.length) {
              this.toastService.error(error);
            }
          }
        );
      }
    });
  }

  public clear(): void{
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
    this.form.reset();
    this.gridConteineres?.clear();
  }

  // public updateContainerRelation(): void{
  //   this.onUpdateContainerRelation.emit({'relacaoConteineres': this.getContainers()});
  // }

  // public getContainers(): BloqueioDesbloqueioConteinerEmbarque[]{
  //   return this.containersGrid?.map(
  //     (container) => container.sendContainer()
  //   );
  // }

  public bloquear(): void{
    let containers = this.gridConteineres.getSelectedContainers();
    if(!containers?.length){
      return;
    }
    this.toastService.error(`Atenção, haverá quebra de lote caso todos os contêineres da DUE não sejam bloqueados!`);
    this.spinner.show();
    this.service.bloquearConteiner(containers).subscribe({
      next: (result) => {
        // this.spinner.hide();
        result?.message?.forEach(
          (msg) => {
            if(msg?.length){
              this.toastService.success(msg);
            }
          }
        );
        this.pesquisarConteiner();
      },
      error: (err) => {
        // this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
        this.pesquisarConteiner();
      }
    });
  }

  public desbloquear(): void{
    let containers = this.gridConteineres.getSelectedContainers();
    if(!containers?.length){
      return;
    }
    this.spinner.show();
    this.service.desbloquearConteiner(containers).subscribe({
      next: (result) => {
        // this.spinner.hide();
        result?.message?.forEach(
          (msg) => {
            if(msg?.length){
              this.toastService.success(msg);
            }
          }
        );
        this.pesquisarConteiner();
      },
      error: (err) => {
        // this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
        this.pesquisarConteiner();
      }
    });
  }

  public get isAnySearchFieldFilled(): boolean{
    return !!this.form.value.numeroDUE || !!this.form.value.conteiner;
  }

  public confirmarBloquear(): void{
    this.modalConfirmarBloquear.openModalForConfirmOrRefuse();
  }

  public confirmarDesbloquear(): void{
    this.modalConfirmarDesbloquear.openModalForConfirmOrRefuse();
  }
}
