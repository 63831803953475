import { VerificacaoDocumentosService } from '../../../../shared/utils/verificacao-documentos.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { VisualizarSolicitacoesImpComponent } from '../../../modais/visualizar-solicitacoes-imp/visualizar-solicitacoes-imp.component';
import { SolicitacaoLiberacaoIMP } from 'src/app/models/SolicitacaoLiberacaoIMP/SolicitacaoLiberacaoIMP';
import { RelatorioEntregaCargaNavio } from 'src/app/models/relatorios/RelatorioEntregaCargaNavio/RelatorioEntregaCargaNavio';

@Component({
  selector: 'app-grid-relatorio-entrega-da-carga-navio',
  templateUrl: './grid-relatorio-entrega-da-carga-navio.component.html',
  styleUrls: ['./grid-relatorio-entrega-da-carga-navio.component.scss']
})
export class GridRelatorioEntregaDaCargaNavioComponent implements OnInit {

  @Input() public entregaCargaNavio!: RelatorioEntregaCargaNavio[];
  public isCollapsed: boolean = false;

  // @ViewChild("visualizarSolicitacoes",{static: false}) visualizarSolicitacoes!: VisualizarSolicitacoesComponent;

  constructor(private verificacaoDocumentosService: VerificacaoDocumentosService) { }

  public ngOnInit(): void {
  }

  public clearFields(): void {
    this.entregaCargaNavio = [];
  }

  // public setMaskForPersonDocument(document: string | undefined): string{
  //   if(!document){
  //     return '';
  //   }
  //   if(document.length == 11){
  //     return this.verificacaoDocumentosService.setMaskForCPF(document);
  //   }
  //   if(document.length == 14){
  //     return this.verificacaoDocumentosService.setMaskForCNPJ(document);
  //   }
  //   return document;
  // }

  // public shortenTextForJustificationDisplay(justification: string | undefined): string{
  //   if(!justification || !justification.length){
  //     return '';
  //   }
  //   let shortenedText = justification.substring(0, 25);
  //   if(justification.length > 25){
  //     shortenedText += '...';
  //   }
  //   return shortenedText;
  // }


  // visualizar(solicitation: SolicitacaoLiberacaoIMP): void{
  //   this.visualizarSolicitacoes.openModal(solicitation);
  // }
}
