<div [auth]="this.visualizacao">

    <div class="mt-5">
        <app-title [title]="title" [subtitle]="subtitle"></app-title>
        <app-documento-cabotagem (onSendDocument)="searchCabotageSolicitationByButton($event)" (onChangeDocumentType)="changeDocumentType($event)" (onSendMask)="setMaskFromDocumentSelected($event)" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
            [camposFormulario]="camposFormularios"
            [isInsideModal]="isRequesting"></app-documento-cabotagem>
        <app-xml [hidden]="documentTypeSelected != 'CTE' && documentTypeSelected != 'NF'" *ngIf="documento"
          [isBlocked]="documentTypeSelected == 'CTE' && (!documento.isNumeroCEValid || isRequesting)"
          (xmlToJson)="getXmlData($event)"
          [documentType]="documentTypeSelected"></app-xml>
        <div [hidden]="!isSearchDone">
          <!-- <div> -->
            <app-cliente [camposFormulario]="camposFormularios" (onSearchClient)="searchClient($event)"
              [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
              (onEndEditClient)="searchNomesDespachantes()"
            ></app-cliente>
            <app-dados-complementares-cabotagem [camposFormulario]="camposFormularios" (dataFormOut)="checkRequiredAttachedDocumentsForCTESolicitation()" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
              [Moedas]="moedas"
            ></app-dados-complementares-cabotagem>
            <app-relacao-conteiner-cabotagem [camposFormulario]="camposFormularios" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration" (dataFormOut)="updateArvorePartes()" (onUpdateSelectedContainers)="updateArvorePartes()" (onEraseConteiner)="eraseConteiner($event)"
              (onSearchShipAndTripByContainerNumber)="searchShipAndTripByContainerNumber($event)"
              [selectedDocumentType]="documentTypeSelected"
              [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
            ></app-relacao-conteiner-cabotagem>
            <div class="row">
                <div class="col">
                    <app-relacao-notas-fiscais [camposFormulario]="camposFormularios" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"></app-relacao-notas-fiscais>
                </div>
                <div class="col">
                    <app-arvore-partes-cabotagem [mascara]="maskForArvorePartes" (dataFormOut)="saveArvorePartes()" [camposFormulario]="camposFormularios" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
                      [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
                    ></app-arvore-partes-cabotagem>
                </div>
            </div>
            <app-documentos-anexados (onDownloadFileToDisplay)="downloadFileToDisplay($event)" (onDownloadFileToSave)="downloadFileToSave($event)" (onSendAttachedFiles)="checkRequiredAttachedDocumentsForCTESolicitation()"></app-documentos-anexados>
        </div>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-5 row">
        <div [hidden]="!isSearchDone" class="solicitation-action-buttons action-double-buttons col-5">
            <button [auth]="this.salvarDocParte"
            *ngIf="!shouldBlockPartQuantityEditionByDocumentType"
              class="action-tertiary-button d-flex align-items-center justify-content-center"
              [disabled]="!isSalvarDocumentoParteEnabled" (click)="setAttachedFilesForSavingPartDocument()"
            >
        <img src="assets/images/SaveDoc.png" alt="">
        <span class="ml-2">SALVAR DOCUMENTO PARTE</span>
      </button>
            <button class="action-tertiary-button d-flex align-items-center justify-content-center" (click)="saveDraft()" [disabled]="!isSaveDraftButtonEnabled">
        <img src="assets/images/SaveRasc.png" alt="">
        <span [auth]="this.salvarRascunho" class="ml-2">SALVAR RASCUNHO</span>
      </button>
        </div>
        <div class="col-1"></div>
        <div [hidden]="!isSearchDone" class="solicitation-action-buttons action-triple-buttons col-6">
            <button class="action-tertiary-button" (click)="clearFormFields()">LIMPAR CAMPOS</button>
            <button [auth]="this.cancelar" [hidden]="isSolicitationReceived" class="action-secondary-button" [disabled]="isRequesting" (click)="cancelSolicitation()">CANCELAR</button>
            <button [auth]="this.concluir" class="action-primary-button" [disabled]="!isConcludeButtonEnabled" (click)="save()">CONCLUIR</button>
        </div>
    </div>

    <app-extrair-dados-siscomex
      (onOpenModal)="isRequesting = false"
      (onCloseModal)="displayIntegrationErrors()">
    </app-extrair-dados-siscomex>
    <app-integracao-documento-parte
      (onOpenModal)="isRequesting = false"
    ></app-integracao-documento-parte>

</div>

<ng-template #modalAlertaErrosIntegracao>
    <div class="modal-content">
        <div class="modal-header">
            <span class="modal-title" id="exampleModalLabel"></span>
            <button type="button" class="btn close btn-close" data-bs-dismiss="modal" aria-label="close" (click)="closeModal()">&times;</button>
        </div>
        <div class="modal-body">
            <ul>
                <li *ngFor="let error of integrationErrors">{{error}}</li>
            </ul>
        </div>
    </div>
</ng-template>

<app-modal-ok #modalSolicitacaoPesquisadaExistente (onOkAction)="resetPage()">
  <p>{{textoSolicitacaoExistente}}</p>
</app-modal-ok>
