import { ErrorResponse } from '../models/HttpResponses/ErrorResponse';
import { SuccessResponse } from '../models/HttpResponses/SuccessResponse';
import { BookingDTO } from 'src/app/models/CabotagemEmbarque/BookingDTO';
import { Viagem } from './../models/CabotagemEmbarque/Viagem';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CabotagemEmbarqueService {

  constructor(
    private http: HttpClient
  ) { }

  public getRelatedShipNames(shipName: string): Observable<string[]>{
    return this.http.get<{nome: string}[]>(`${environment.apiUrlForCabotagemEmbarque}/Navios/${shipName}`)
      .pipe(
        map(shipNames => shipNames.map(navio => navio.nome))
      );
  }

  public getRelatedTripNumbers(tripNumber: string): Observable<string[]>{
    return this.http.get<Viagem[]>(`${environment.apiUrlForCabotagemEmbarque}/Viagens/${tripNumber}`)
      .pipe(
        map(trip => trip.map(viagem => viagem.numeroViagem))
      );
  }

  public getRelatedBookingNumbers(bookingNumber: string): Observable<string[]>{
    return this.http.get<BookingDTO[]>(`${environment.apiUrlForCabotagemEmbarque}/Booking/${bookingNumber}`)
      .pipe(
        map(bookingNumber => bookingNumber.map(booking => booking.numeroBooking))
      );
  }

  public getBookingsRelation(ship: string, trip: string, booking: string): Observable<BookingDTO[]>{
    return this.http.get<BookingDTO[]>(`${environment.apiUrlForCabotagemEmbarque}/Booking?${this.getQueryStringForBookingSearchByShipTripAndBooking(ship, trip, booking)}`);
  }
  public DeleteBookingsRelation(bookingId:number): Observable<any>{
    return this.http.delete<any>(`${environment.apiUrlForCabotagemEmbarque}/Booking/${bookingId}`);
  }

  private getQueryStringForBookingSearchByShipTripAndBooking(ship: string, trip: string, booking: string): string{
    let bookingQuerySearch: string[] = [];
    if(ship && ship.length){
      bookingQuerySearch.push(`NomeNavio=${ship}`);
    }
    if(trip && trip.length){
      bookingQuerySearch.push(`NumeroViagem=${trip}`);
    }
    if(booking && booking.length){
      bookingQuerySearch.push(`NumeroBooking=${booking}`);
    }
    return bookingQuerySearch.join('&');
  }

  public getBookingsByBookingNumberArray(bookings: BookingDTO[]): Observable<BookingDTO[]>{
    return this.http.get<BookingDTO[]>(`${environment.apiUrlForCabotagemEmbarque}/Booking/cadastradas?${this.getQueryStringForBookingArray(bookings)}`);
  }

  private getQueryStringForBookingArray(bookings: BookingDTO[]): string{
    return bookings.map(
      (booking) => `numero=${booking.numeroBooking}`
    ).join('&');
  }

  public saveBookingRelation(bookingRelation: BookingDTO[]): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForCabotagemEmbarque}/Booking`, bookingRelation);
  }
}
