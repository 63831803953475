<div class="modal-header">
  <h4>Visualizar solicitação</h4>
  <button class="btn-close close pull right"><span class="visualli-hidden"
      (click)="closeModal()">&times;</span></button>
</div>
<div class="modal-body">
  <div class="p-3">
    <app-documento-cabotagem #documento
      [isInsideModal]="true"
      [visualizar]="true" (onSendMask)="mascara = $event"></app-documento-cabotagem>
    <app-cliente #cliente
      [visualizar]="blockEdition"
      [camposFormulario]="camposFormulario"
    ></app-cliente>
    <app-dados-complementares-cabotagem #dadosComplementares
      [visualizar]="blockEdition"
      [camposFormulario]="camposFormulario"
      [Moedas]="moedas"
    ></app-dados-complementares-cabotagem>
    <app-relacao-conteiner-cabotagem #relacaoConteineres
      [isInsideModal]="blockEdition" [visualizar]="blockEdition" [doesSolicitationHaveIntegration]="true"
      [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
      [camposFormulario]="camposFormulario"
    ></app-relacao-conteiner-cabotagem>
    <app-arvore-partes-liberacao-documental #arvoreDePartes
      [isExpDocument]="false" [mascara]="mascara"
      (onExpandPartDocument)="expandPartDocument($event)"
    ></app-arvore-partes-liberacao-documental>
    <!-- <app-consulta-bloqueio #consultaBloqueio (onConsultaBloqueioLoad)="consultaBloqueio.setFormValuesFromConsultaBloqueio(solicitation?.consultaBloqueio)"></app-consulta-bloqueio> -->
    <app-documentos-anexados #documentosAnexados
      (onDownloadFileToDisplay)="
        documentosAnexados.downloadFileToDisplay(
        solicitacaoLiberacaoCabotagemService.downloadFileForAttachedDocumentGridOnModal($event.arquivoDocumentoAnexado),
        $event.index
      )"
      (onDownloadFileToSave)="
        documentosAnexados.downloadFileToSave(
        solicitacaoLiberacaoCabotagemService.downloadFileForAttachedDocumentGridOnModal($event.arquivoDocumentoAnexado),
        $event.index
      )" [isInsideModal]="blockEdition" ></app-documentos-anexados>
  </div>
</div>
<div class="modal-footer">
  <div *ngIf="!blockEdition" class="action-button-group action-triple-buttons w-100 justify-content-end">
    <div class="span"></div>
    <div class="span"></div>
    <button class="action-primary-button"
      [disabled]="!areAllFormsValid"
      (click)="saveSolicitation()"
    >SALVAR</button>
  </div>
</div>
