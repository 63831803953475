import { AuthenticationService } from './authentication/authentication.service';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { CadastroDocumentoParteCabotagemDTO } from '../models/SolicitacaoLiberacaoCabotagem/CadastroDocumentoParteCabotagemDTO';
import { DocumentoCabotagem } from 'src/app/models/DocumentoCabotagem';
import { SuccessResponse } from '../models/HttpResponses/SuccessResponse';
import { SolicitacaoLiberacaoCabotagem } from '../models/SolicitacaoLiberacaoCabotagem/SolicitacaoLiberacaoCabotagem';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Documento } from '../models/Documento';
import { Cliente } from '../models/Cliente';
import { TipoDocumentoAnexado } from '../models/DocumentosAnexados/TipoDocumentoAnexado';
import { ArquivoDocumentoAnexado } from '../models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { ConteinerCabotagem } from '../models/SolicitacaoLiberacaoCabotagem/ConteinerCabotagem';

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoLiberacaoCabotagemService {

  constructor(
    private http: HttpClient,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private authenticationService: AuthenticationService
  ) { }

  public saveCabotageLiberationSolicitation(solicitation: SolicitacaoLiberacaoCabotagem): Observable<SuccessResponse>{
    if(solicitation.documentosAnexosLiberacao && solicitation.documentosAnexosLiberacao.length){

      solicitation.documentosAnexosLiberacao?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem?solicitante=${this.authenticationService.userName}&emailUserLogado=${this.authenticationService.userEmail}&userInterno=${this.authenticationService.ehUsuarioInterno}`, solicitation);
  }

  public saveCabotageLiberationSolicitationFromIntegratedDTC(solicitation: SolicitacaoLiberacaoCabotagem): Observable<SuccessResponse>{
    if(solicitation.documentosAnexosLiberacao && solicitation.documentosAnexosLiberacao.length){

      solicitation.documentosAnexosLiberacao?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem?solicitante=${this.authenticationService.userName}&emailUserLogado=${this.authenticationService.userEmail}&bloqueioDTCverificado=true&userInterno=${this.authenticationService.ehUsuarioInterno}`, solicitation);
  }

  public getCabotageLiberationSolicitationDataByDocument(document: DocumentoCabotagem, correlation_id: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlation_id);
    let query: string[] = [];
    query.push('confirmado=false');
    if(document?.numeroCE){
      query.push(`numeroCE=${document?.numeroCE}`);
    }
    if(document?.chaveNotaFiscal){
      query.push(`chaveNotaFiscal=${document?.chaveNotaFiscal}`);
    }
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`)

    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/Pesquisar/${document.tipoDocumento}/${document.numeroDocumento}?${query.join('&')}`, {headers});
  }

  public confirmCreateSolicitationFromSearch(document: DocumentoCabotagem, correlation_id: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlation_id);
    let query: string[] = [];
    query.push('confirmado=true');
    if(document?.numeroCE){
      query.push(`numeroCE=${document?.numeroCE}`);
    }
    if(document?.chaveNotaFiscal){
      query.push(`chaveNotaFiscal=${document?.chaveNotaFiscal}`);
    }
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`)
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/Pesquisar/${document.tipoDocumento}/${document.numeroDocumento}?${query.join('&')}`, {headers});
  }

  public cancelCabotageLiberationSolicitationBySolicitationId(solicitationId: number): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/${solicitationId}/Cancelar`, solicitationId);
  }

  public savePartDocument(cadastroDocumentoParte: CadastroDocumentoParteCabotagemDTO, correlationId: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlationId);
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/DocumentoParte`, cadastroDocumentoParte, {headers});
  }

  public getCliente(client: Cliente): Observable<SuccessResponse>{
    let query: string[] = [];
    if(client.cpfCnpj && client.cpfCnpj.length){
      query.push(`CpfCnpj=${this.verificacaoDocumentosService.eraseMask(client.cpfCnpj)}`);
    }
    if(client.nomeCliente && client.nomeCliente.length){
      query.push(`nome=${client.nomeCliente}`);
    }
    return this.http.get<SuccessResponse>(
      `${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/api/Cliente?${query.join('&')}`
    );
  }

  public saveDraft(solicitacaoLiberacaoCabotagem: SolicitacaoLiberacaoCabotagem): Observable<SuccessResponse>{
    if(solicitacaoLiberacaoCabotagem.documentosAnexosLiberacao && solicitacaoLiberacaoCabotagem.documentosAnexosLiberacao.length){

      solicitacaoLiberacaoCabotagem.documentosAnexosLiberacao?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/Rascunho`, solicitacaoLiberacaoCabotagem);
  }

  public getAttachmentDocumentsForCabotageLiberationByDocumentType(): Observable<TipoDocumentoAnexado[]>{
    return this.http.get<TipoDocumentoAnexado[]>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/DocumentosLiberacao`);
  }

  public arquivoUploadSolicitacaoLiberacaoCabotagem(dados: ArquivoDocumentoAnexado[], solicitacaoId?: number): Observable<SuccessResponse>{
    const formData = new FormData();
    dados.forEach(e => {
      var arq = e.arquivoDocumento as File;
      var id = e.tipoDocumento?.idDocumento.toString() ? e.tipoDocumento?.idDocumento.toString() : '';
      if(arq?.size){
        formData.append('files', arq);
        formData.append('id', id);
      }
    });
    return this.http
      .post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/UploadArquivosSolicitacao/uploadfiles/${solicitacaoId ?? null}`, formData);
  }

  public downloadFileForAttachedDocumentGrid(arquivo: ArquivoDocumentoAnexado): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/UploadArquivosSolicitacao/BaixarArquivo?filePath=${arquivo.filePath}&fileName=${arquivo.nomeArquivo}&contentType=${arquivo.contentType}`);
  }

  public downloadFileForAttachedDocumentGridOnModal(arquivo: ArquivoDocumentoAnexado): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/UploadArquivosSolicitacao/BaixarArquivo?idSolicitacao=${arquivo.idSolicitacao}&filePath=${arquivo.filePath}&fileName=${arquivo.nomeArquivo}&contentType=${arquivo.contentType}`);
  }

  public getIntegrationsFromXML(solicitation: SolicitacaoLiberacaoCabotagem, correlationId: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlationId);
    let query: string[] = [];
    query.push('confirmado=false');
    if(solicitation?.documento?.numeroCE){
      query.push(`numeroCE=${solicitation?.documento?.numeroCE}`);
    }
    if(solicitation?.documento?.chaveNotaFiscal){
      query.push(`chaveNotaFiscal=${solicitation?.documento?.chaveNotaFiscal}`);
    }
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`)

    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/UploadXml/${solicitation?.documento?.tipoDocumento}/${solicitation?.documento?.numeroDocumento}?${query.join('&')}`, solicitation, {headers});
  }

  public confirmCreateSolicitationFromXML(solicitation: SolicitacaoLiberacaoCabotagem, correlationId: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlationId);
    let query: string[] = [];
    query.push('confirmado=false');
    if(solicitation?.documento?.numeroCE){
      query.push(`numeroCE=${solicitation?.documento?.numeroCE}`);
    }
    if(solicitation?.documento?.chaveNotaFiscal){
      query.push(`chaveNotaFiscal=${solicitation?.documento?.chaveNotaFiscal}`);
    }
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`)

    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/UploadXml/${solicitation?.documento?.tipoDocumento}/${solicitation?.documento?.numeroDocumento}?${query.join('&')}`, solicitation, {headers});
  }

  public postCancelarSolicitacao(solicitacao: SolicitacaoLiberacaoCabotagem, conteiner: ConteinerCabotagem): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/ExcluirConteiner/${solicitacao.id}/${conteiner.numeroConteiner}`, null);
  }

  public searchNomesDespachantes(solicitation: SolicitacaoLiberacaoCabotagem): Observable<SuccessResponse>{
    let query: string[] = [];
    console.log(solicitation)
    console.log(this.authenticationService.ehUsuarioInterno);
    console.log(this.authenticationService.userName);
    if(this.authenticationService.ehUsuarioInterno){
      query.push(`userType=1`);
      query.push(`cnpjCliente=${solicitation?.cliente?.cpfCnpj}`);
    }
    else{
      query.push(`userType=0`);
      query.push(`matricula=${this.authenticationService?.userName}`);
    }
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/ObterDadosDespachante?${query.join('&')}`);
  }

  public searchShipAndTripByContainerNumber(containerNumber: string): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/BuscarNavioViagem/${containerNumber}`);
  }

  public getNumeroCEByContainerNumber(containerNumber: string): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/BuscarNumeroCEPeloConteiner/${containerNumber}`);
  }
  public getTelefoneUsuarioExterno(): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/ObterInformacoesUsuarioLogado/`+this.authenticationService.userEmail);
  }

  public saveSolicitationOnLiberationModal(solicitation: SolicitacaoLiberacaoCabotagem): Observable<SuccessResponse>{
    if(solicitation.documentosAnexosLiberacao?.length){
      solicitation.documentosAnexosLiberacao?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    return this.http.put<SuccessResponse>(
      `${environment.apiUrlForSolicitacaoLiberacaoCabotagem}/SolicitacaoLiberacaoCabotagem/Cabotagem`,
      solicitation
    );

  }
}
