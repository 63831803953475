<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Cliente <span class="text-danger" *ngIf="!isClientValid()">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>

    <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-4">
                    <div class="col">
                        <label>Nome do Cliente: <span id="nomeCliente-required-label" class="invisible">*</span></label>
                        <select class="custom-select" formControlName="codCliente" [ngClass]="{'border border-danger': form.get('codCliente')?.invalid && !form.get('codCliente')?.pristine && form.get('codCliente')?.value == null}">
                          <option [value]="-1">Selecione</option>
                          <option *ngFor="let cliente of CodigosClientes; let i = index" [value]="i" (click)="SetClientControls(i)" [selected]="CodigosClientes.length == 1">{{ cliente.nomeCliente + "-" + cliente.cpfCnpj +" ("  + cliente.codigoCliente +")" }}</option>
                        </select>
                    </div>
                    <div class="col">
                        <label>CPF/CNPJ do Cliente: <span id="cpfCnpjCliente-required-label" class="invisible">*</span></label>
                        <input formControlName="cpfCnpjCliente" type="text" class="form-control" aria-describedby="basic-addon2" placeholder="Digite aqui..." [mask]="'00.000.000/0000-00||000.000.000-00'" (change)="setClientTypeByDocumentNumber('Cliente')" [ngClass]="{'border border-danger': form.get('cpfCnpjCliente')?.errors && !form.get('cpfCnpjCliente')?.pristine}">
                        <div *ngIf="form.get('cpfCnpjCliente')?.errors && !form.get('cpfCnpjCliente')?.pristine">
                            <p class="text-danger" *ngIf="form.get('cpfCnpjCliente')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                            <p class="text-danger" *ngIf="form.get('cpfCnpjCliente')?.errors?.['mask']">
                                Preencher no formato correto!
                            </p>
                            <p class="text-danger" *ngIf="form.get('cpfCnpjCliente')?.errors?.['invalidDocument']">
                                {{form.get('cpfCnpjCliente')?.errors?.['invalidDocument']}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                  <div class="col-3">
                      <label>Tipo de Cliente: <span id="tipoCliente-required-label" class="invisible">*</span></label>
                        <div class="radio">
                            <input formControlName="tipoCliente" type="radio" value="fisica" [checked]="form.get('tipoCliente')?.value == 'fisica'" value="fisica">
                            <label>Pessoa Física</label>
                        </div>
                    </div>
                    <div class="col-3"><label><span><br></span></label>
                        <div class="radio">
                            <input formControlName="tipoCliente" type="radio" value="juridica" [checked]="form.get('tipoCliente')?.value == 'juridica'" value="juridica"> <label>Pessoa
                Jurídica</label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    <hr>

        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-4">
                    <div class="col">
                        <label>Nome do Cliente a Faturar: <span id="nomeClienteFaturar-required-label"
                class="invisible">*</span></label>
                <select class="custom-select" formControlName="codClienteFaturar" [ngClass]="{'border border-danger': form.get('codClienteFaturar')?.invalid && !form.get('codClienteFaturar')?.pristine && form.get('codClienteFaturar')?.value == null}">
                  <option [value]="-1" >Selecione</option>
                  <option *ngFor="let cliente of CodigosClientesFaturar; let i = index" [value]="i" (click)="SetClienFaturarControls(i)" [selected]="CodigosClientesFaturar.length == 1">{{ cliente.nomeClienteFaturar + "-" + cliente.cpfCnpjFaturar +" ("  + cliente.codigoClienteFaturar +")" }}</option>
                </select>
                    </div>
                    <div class="col">
                        <label>CPF/CNPJ do Cliente a Faturar: <span id="cpfCnpjClienteFaturar-required-label"
                class="invisible">*</span></label>
                        <input formControlName="cpfCnpjClienteFaturar" type="text" class="form-control" aria-describedby="basic-addon2" placeholder="Digite aqui..." [mask]="'00.000.000/0000-00||000.000.000-00'" (change)="setClientTypeByDocumentNumber('ClienteFaturar')" [ngClass]="{'border border-danger': form.get('cpfCnpjClienteFaturar')?.errors && !form.get('cpfCnpjClienteFaturar')?.pristine}">
                        <div *ngIf="form.get('cpfCnpjClienteFaturar')?.errors && !form.get('cpfCnpjClienteFaturar')?.pristine">
                            <p class="text-danger" *ngIf="form.get('cpfCnpjClienteFaturar')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                            <p class="text-danger" *ngIf="form.get('cpfCnpjClienteFaturar')?.errors?.['mask']">
                                Preencher no formato correto!
                            </p>
                            <p class="text-danger" *ngIf="form.get('cpfCnpjClienteFaturar')?.errors?.['invalidDocument']">
                                {{form.get('cpfCnpjClienteFaturar')?.errors?.['invalidDocument']}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                  <div class="col-3">
                <label>Tipo de Cliente a Faturar: <span id="tipoClienteFaturar-required-label" class="invisible">*</span></label>
                        <div class="radio">
                            <input formControlName="tipoClienteFaturar" type="radio" value="fisica">
                            <label>Pessoa Física</label>
                        </div>
                    </div>
                    <div class="col-3"><label><span><br></span></label>
                        <div class="radio">
                            <input formControlName="tipoClienteFaturar" type="radio" value="juridica">
                            <label>Pessoa Jurídica</label>
                        </div>
                    </div>
                  </div>
            </div>
        </form>
        <div class="row mt-3 p-3">
          <div class="col d-flex">
              <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
          </div>
      </div>
    </div>
</div>
