import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SanitizerHtmlService {
  public sanitizer(input: string) {
    if (input != null) {
      const regex = /(<[a-z]*|[0-9]>)?(<\/[a-z]*|[0-9]*>)/gm;
      return input.replace(regex, '');
    }
    return ''
  }
}
