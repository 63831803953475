<div [auth]="this.visualizacao" class="mt-5">

  <app-title [title]='title'></app-title>

  <div class="card mt-4">
    <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
      <form [formGroup]="form">
        <div class="p-3">
          <div class="row mb-4">
            <div class="col">
              <label>N° da Due:</label>
              <input formControlName="numeroDUE" type="text" class="form-control" aria-describedby="basic-addon2"
              [mask]="maskForDUE" [specialCharacters]="specialCharsForDUE" placeholder="Digite aqui...">
            </div>
            <div class="col">
              <label>Contêiner:</label>
              <input formControlName="conteiner" type="text" class="form-control" aria-describedby="basic-addon2"
                maxlength="11" placeholder="Digite aqui...">
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-6 d-flex align-items-end">
              <p class="required-fields-paragraph">Preencher ao menos um campo para pesquisar</p>
            </div>
            <div class="col-6 action-button-group action-double-buttons">
              <button class="action-secondary-button" (click)="clear()" type="button">
                Limpar
              </button>
              <button class="action-primary-button" [disabled]="!isAnySearchFieldFilled"
                (click)="pesquisarConteiner()"  type="submit"
              >
                PESQUISAR
              </button>
            </div>
          </div>
          <hr>
          <div class="row mb-4">
            <div class="col">
              <app-grid-conteineres-bloqueio-desbloqueio-conteiner-embarque></app-grid-conteineres-bloqueio-desbloqueio-conteiner-embarque>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
<!--
  <div class="d-flex mt-5 row">
    <div class="col-3"></div>
    <div class="col-6 action-button-group action-triple-buttons">
      <button [auth]="this.bloquearBtn" class="action-secondary-button" (click)="bloquear()">BLOQUEAR</button>
      <button [auth]="this.solicitarDesbloqueioBtn" class="action-secondary-button"
        (click)="solicitarDesbloqueio()">SOLICITAR
        DESBLOQUEIO</button>
      <button [auth]="this.desbloquear" class="action-primary-button" (click)="{}">DESBLOQUEAR</button>
    </div>
    <div class="col-3"></div>
  </div> -->

  <div class="d-flex mt-5 row">
    <div class="col-3"></div>
    <div class="col-6 action-button-group action-double-buttons" *ngIf="gridConteineres">
      <button  class="action-secondary-button" (click)="confirmarBloquear()"
        [disabled]="!gridConteineres.isAnyContainerSelected"
      >
        BLOQUEAR
      </button>
      <button class="action-primary-button" (click)="confirmarDesbloquear()"
        [disabled]="!gridConteineres.isAnyContainerSelected"
      >
        DESBLOQUEAR
      </button>
    </div>
    <div class="col-3"></div>
  </div>

</div>

<app-modal-resposta-positiva-negativa #modalConfirmarBloquear
  [modalTitle]="'Confirmar bloqueio'"
  [positiveText]="'Bloquear'"
  [negativeText]="'Voltar'"
  (onChoosePositiveAction)="bloquear()"
>
  <p>Deseja mesmo bloquear estes contêineres?</p>
</app-modal-resposta-positiva-negativa>

<app-modal-resposta-positiva-negativa #modalConfirmarDesbloquear
  [modalTitle]="'Confirmar desbloqueio'"
  [positiveText]="'Desbloquear'"
  [negativeText]="'Voltar'"
  (onChoosePositiveAction)="desbloquear()"
>
  <p>Deseja mesmo desbloquear estes contêineres?</p>
</app-modal-resposta-positiva-negativa>
