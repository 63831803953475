<div class="card mt-4">

  <div class="card-body" id="cardCollapse">
    <form [formGroup]="form">
      <div class="p-3">
        <div class="row">
          <div class="col">
            <label>Tipo de Documento:</label>
            <select formControlName="tipoDocumentoSolicitacao" class="custom-select" (change)="updateMaskForSolicitationDocument(form.get('tipoDocumentoSolicitacao')?.value)">
              <option [ngValue]="''" selected>Selecione</option>
              <option *ngFor="let document of solicitationDocumentTypes" [value]="document.nome">{{document.nome}}</option>
            </select>
          </div>
          <div class="col">
            <label>N° Documento:</label>
            <input formControlName="numeroDocumentoSolicitacao" type="text" class="form-control" placeholder="Digite aqui..."
                aria-label="Digite aqui..." aria-describedby="basic-addon2" [mask]="maskForSolicitationDocument"
                [specialCharacters]="specialCharsForSolicitationDocument" [ngClass]="{'border border-danger': !form.get('numeroDocumentoSolicitacao')?.pristine && form.get('numeroDocumentoSolicitacao')?.errors}">
          </div>
        </div><br/>
        <div class="row">
          <div class="col">
            <label>Navio:</label>
            <input formControlName="navioSolicitacao" type="text" class="form-control" placeholder="Digite aqui..."
                aria-label="Digite aqui..." aria-describedby="basic-addon2" >
          </div>
          <div class="col">
            <label>Viagem:</label>
            <input formControlName="viagemSolicitacao" type="text" class="form-control" placeholder="Digite aqui..."
                aria-label="Digite aqui..." aria-describedby="basic-addon2" >
          </div>
        </div><br/>
        <div class="row">
          <div class="col">
            <label>Nome do Cliente:</label>
            <input formControlName="nomeClienteSolicitacao" type="text" class="form-control" placeholder="Digite aqui..."
                aria-label="Digite aqui..." aria-describedby="basic-addon2" maxlength="100">
          </div>
          <div class="col">
            <label>CPF/CNPJ:</label>
            <input formControlName="documentoClienteSolicitacao" type="text" class="form-control" placeholder="Digite aqui..."
                aria-label="Digite aqui..." aria-describedby="basic-addon2" [mask]="'00.000.000/0000-00||000.000.000-00'"
                [ngClass]="{'border border-danger': form.get('documentoClienteSolicitacao')?.touched && form.get('documentoClienteSolicitacao')?.errors}">
            <p *ngIf="form.get('documentoClienteSolicitacao')?.touched && form.get('documentoClienteSolicitacao')?.errors?.['invalidClientDocument']"
            class="text-danger">
              {{form.get('documentoClienteSolicitacao')?.errors?.['invalidClientDocument']}}
            </p>
          </div>
        </div><br/>
        <div class="row">
          <div class="col">
            <label>Regime de Circulação:</label>
            <select formControlName="regimeCirculacaoSolicitacao" class="custom-select">
              <option [ngValue]="''" selected>Selecione</option>
              <option *ngFor="let circulationRegime of circulationRegimes" [ngValue]="circulationRegime">{{circulationRegime.nomeRegimeCirculacao}}</option>
            </select>
          </div>
          <div class="col">
            <label>Status:</label>
            <select formControlName="statusSolicitacao" class="custom-select">
              <option [ngValue]="''" selected>Selecione</option>
              <option *ngFor="let status of solicitationStatus" [value]="status.idStatus">{{status.nomeStatus}}</option>
            </select>
          </div>
        </div><br/>
        <div class="row">
          <div class="col-3">
            <label>Data da Solicitação:</label>
            <input type="text" formControlName="dataSolicitacao"
            bsDatepicker #dpSolicitacao="bsDatepicker" class="form-control" aria-describedby="basic-addon2" [bsConfig]="bsConfig"
            placeholder="dd/mm/aaaa"
            style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; ">
          </div>
          <div class="col-3">
            <label>Data da Liberação:</label>
            <input type="text" formControlName="dataLiberacao"
            bsDatepicker #dpLiberacao="bsDatepicker" class="form-control" aria-describedby="basic-addon2" [bsConfig]="bsConfig"
            placeholder="dd/mm/aaaa"
            style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; ">
          </div>
          <div class="col-6" *ngIf="!ehUsuarioExterno">
            <label>Solicitante:</label>
            <input formControlName="solicitante" type="text" class="form-control" placeholder="Digite aqui..."
                aria-label="Digite aqui..." aria-describedby="basic-addon2" maxlength="100">
          </div>
        </div><br>
        <div class="row">
          <div class="col-9"></div>
          <div class="col-3">
            <button class="btn-pesquisar"  (click)="sendFilter()">PESQUISAR</button>
          </div>
        </div><br>
      </div>
    </form>
  </div>
</div>

