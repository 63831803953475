import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr/dist/esm/HubConnection';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { SolicitacaoDTO } from 'src/app/models/LiberacaoDocumental/SolicitacaoDTO';
import { RetornoConsultaBloqueioDTO } from 'src/app/models/RetornoConsultaBloqueioDTO';
import { StatusBloqueioEnum } from 'src/app/models/StatusBloqueioEnum';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LiberacaoDocumentalService } from 'src/app/services/liberacao-documental.service';
import { ToastService } from 'src/app/services/toast.service';
import { TimeConstants } from 'src/app/shared/utils/time-constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consulta-bloqueio',
  templateUrl: './consulta-de-bloqueio.component.html',
  styleUrls: ['./consulta-de-bloqueio.component.scss']
})
export class ConsultaBloqueioComponent implements OnInit, AfterContentInit, OnDestroy{
  public form!: UntypedFormGroup;
  public isCollapsed = false;
  public read: string = '';
  public retornoBloqueios!: RetornoConsultaBloqueioDTO[];
  private timeoutForSiscomexIntegration!: any;

  private readonly documentsForSimpleApiRequest: string[] = ['DUE', 'DAT'];
  
    private signalRConnection: HubConnection = new signalR.HubConnectionBuilder()
  .withUrl(`${environment.apiUrlForLiberacaoDocumentalHub}/consultaBloqueioHub`)
  .withAutomaticReconnect()
  .configureLogging(signalR.LogLevel.Debug)
  .build();

  @ViewChild("modal",{static: false}) modal!: TemplateRef<any>;
  @Input() dataFormIn!: UntypedFormGroup;
  @Output() dataFormOut = new EventEmitter<UntypedFormGroup>();
  @Output() onConsultaBloqueioLoad: EventEmitter<void> = new EventEmitter<void>();
  @Output() onErroIntegracao: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private authService: AuthenticationService,
    private fb: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private liberacaoService:LiberacaoDocumentalService,
    private changeDetector: ChangeDetectorRef,
    private toastService:ToastService,
    private spinner: NgxSpinnerService
  ) { }

  public ngOnInit(): void{

  }

  public ngAfterContentInit(): void {
    this.onConsultaBloqueioLoad.emit();
  }

  public ngOnDestroy(): void {
    this.closeSignalRConnection();
  }

  public onClose(): void{
    this.bsModalRef.hide();
    this.changeDetector.detectChanges();
  }

  public closeAllModals(): void{
    this.bsModalService.hide();
    this.changeDetector.detectChanges();
  }

  public retornaStatus(valor:number):string{
    return StatusBloqueioEnum[valor];
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const change in changes) {
      const item = changes[change];
      if (item['currentValue'] != undefined && this.form != undefined) {
        const itemForm = item['currentValue'];
        this.form.patchValue(itemForm);
        if (this.form.value.numDoc != '') this.read = 'readonly';
      }
    }
  }

  public cancel(){
    this.form.reset();
  }

  public openModal(solicitacaoLiberacao : SolicitacaoDTO) {
    this.retornoBloqueios = [];
    this.bsModalRef = this.bsModalService.show(this.modal, Object.assign({}, { class: 'modal-md' }));
    this.bsModalRef?.onHide?.subscribe(() => {
      this.closeSignalRConnection();
    });
    this.getConsultabloqueio(solicitacaoLiberacao);
    this.changeDetector.detectChanges();
  }

  private getConsultabloqueio(solicitacaoLiberacao : SolicitacaoDTO): void{
    this.spinner.show('carregando');
    clearTimeout(this.timeoutForSiscomexIntegration);
    const correlation_id = solicitacaoLiberacao.aggregateId!;
    this.liberacaoService.getConsultaBloqueio(solicitacaoLiberacao, correlation_id)
      ?.subscribe({
        next: (consultaBloqueio) => {
          console.log(consultaBloqueio)
          if(this.documentsForSimpleApiRequest.includes(solicitacaoLiberacao.documento?.tipoDocumento as string)){
            this.retornoBloqueios = [...consultaBloqueio.data as RetornoConsultaBloqueioDTO[]];
            this.changeDetector.detectChanges();
            if(!this.retornoBloqueios?.length){
              this.closeAllModals();
              return;
            }
            this.cleanEmptyRows();
          }
          else{
            this.retornoBloqueios = [...consultaBloqueio.data as RetornoConsultaBloqueioDTO[]];
            this.changeDetector.detectChanges();
            if(!this.retornoBloqueios?.length){
              this.closeAllModals();
              return;
            }
            this.getConsultaBloqueioSignalR(correlation_id);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide('carregando');
          console.log(err);
          (err.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              if(error && error.length){
                this.toastService.error(error);
              }
            }
          );
          this.cleanEmptyRows();
        }
      });
  }

  private getConsultaBloqueioSignalR(correlation_id: string): void{
    this.spinner.hide('carregando');
    let errorShown: boolean = false;
    this.signalRConnection.on('ConsultaBloqueio', (response: RetornoConsultaBloqueioDTO[]) => {
      response.forEach((retornoBloqueio) => {
        let indexBloqueio: number = this.retornoBloqueios.findIndex(
          (bloqueio) => bloqueio?.tipoConsulta?.trim()?.toLowerCase() === retornoBloqueio?.tipoConsulta?.trim()?.toLowerCase()
        );
        if(indexBloqueio >= 0){
          this.retornoBloqueios[indexBloqueio].status = retornoBloqueio.status;
          this.retornoBloqueios[indexBloqueio].descricao = retornoBloqueio.descricao?.length ? retornoBloqueio.descricao : 'Sem descrição';
        }
      });
      this.spinner.hide('carregando');
      if(
        !this.retornoBloqueios.filter(bloqueio => !bloqueio.tipoConsulta || !bloqueio.status)?.length
      ){
        this.closeSignalRConnection();
      }
    });
    this.signalRConnection.on('MensagemEventoErro', (mensagem: string) => {
      this.spinner.hide();
      this.closeSignalRConnection();
      this.onClose();
      if(!errorShown){
        errorShown = true;
        this.changeDetector.detectChanges();
        this.onErroIntegracao.emit(mensagem);
      }
    });
    this.signalRConnection.start()
      .then(
        () => this.signalRConnection.invoke('CriarConexaoConsultaBloqueio', correlation_id)
          .catch((err) => {
            console.log(err);
            this.closeSignalRConnection();
            this.spinner.hide('carregando');
          })
      );
    this.timeoutForSiscomexIntegration = setTimeout(() => {
      if(!this.bsModalService.getModalsCount()){
        return;
      }
      this.spinner.hide('carregando');
      this.closeSignalRConnection();
      if(
        this.retornoBloqueios.filter(bloqueio => !bloqueio.tipoConsulta || !bloqueio.status)?.length
      ){
        this.cleanEmptyRows();
        this.toastService.error('Consulta de bloqueio excedeu limite de tempo!');
      }
    }, TimeConstants.TIMEOUT_FOR_CONSULTA_BLOQUEIO);
  }

  private cleanEmptyRows(): void{
    this.retornoBloqueios = [...this.retornoBloqueios.filter(
      bloqueio => bloqueio.tipoConsulta && bloqueio.status
    )];
  }

  private closeSignalRConnection(): void{
    this.signalRConnection.stop();
  }
}
