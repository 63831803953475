<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Árvore de Partes <span class="text-danger" *ngIf="numerosDocumentosDisplay?.length && !areAllGridLinesValid()">*</span></span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}"
        data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false"
        aria-label="Toggle navigation"></i>
    </div>
  </div>
  <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
      <div class="table-container">
        <table class="grid-table">
          <thead class="">
            <tr>
              <th>N° do Contêiner</th>
              <th>{{shouldBlockPartQuantityEditionByDocumentType ? 'N° do CE ' : 'N° do Documento'}}</th>
              <th *ngIf="shouldBlockPartQuantityEditionByDocumentType">N° do Documento</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let numeroDocumento of numerosDocumentosDisplay; let indexDocumento = index"
              app-linha-grid-arvore-partes-cabotagem [documentoParte]="numeroDocumento" [mascara]="mascara"
              (onFirstEdit)="markEveryLineAstouched()" [camposFormulario]="camposFormulario"
              (onSendDocumentoParte)="sendArvorePartesData()" [visualizar]="arvoreParteReadonly"
              [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
            ></tr>
          </tbody>
        </table>
      </div>
  </div>
</div>
