<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Dados Complementares <span class="text-danger" *ngIf="!areComplementaryDataValid()">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row">
                    <div class="col">
                        <label for="">Navio: <span id="navio-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="navio" [ngClass]="{'border border-danger': !form.get('navio')?.pristine && form.get('navio')?.invalid}" (change)="removeSpacesFromExtremities('navio')" [attr.disabled]="visualizar ? '': null">
                    </div>
                    <div class="col">
                        <label for="">Viagem: <span id="viagem-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="viagem" [ngClass]="{'border border-danger': !form.get('viagem')?.pristine && form.get('viagem')?.invalid}" (change)="removeSpacesFromExtremities('viagem')" [attr.disabled]="visualizar ? '': null">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <label for="">Valor da Mercadoria: <span id="valorMercadoria-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="valorMercadoria" currencyMask [options]="{prefix: '', align:'left', thousands: '.', decimal: ','}" [ngClass]="{'border border-danger': !form.get('valorMercadoria')?.pristine && form.get('valorMercadoria')?.invalid}"
                            [attr.disabled]="visualizar ? '': null">
                    </div>
                    <div class="col">
                        <label>Moeda: <span id="moeda-required-label" class="invisible">*</span></label>
                        <select class="custom-select" formControlName="moeda" [ngClass]="{'border border-danger': form.get('moeda')?.invalid && !form.get('moeda')?.pristine && form.get('moeda')?.value == null} " [attr.disabled]="visualizar ? '': null">
            <option [ngValue]="null" selected>Selecione</option>
            <option *ngFor="let moeda of Moedas" [value]="moeda.id">{{ moeda.nome }}</option>
          </select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3">
                        <label for="">Data de Registro: <span id="dataRegistro-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="dataRegistro" [ngClass]="{'border border-danger': form.get('dataRegistro')?.invalid && !form.get('dataRegistro')?.pristine} " bsDatepicker #dp="bsDatepicker" class="form-control" aria-describedby="basic-addon2" [bsConfig]="bsConfig"
                            placeholder="dd/mm/aaaa" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; " [attr.disabled]="visualizar ? '': null">
                    </div>
                    <div class="col-3">
                        <label for="">Modalidade: <span id="modalidade-required-label" class="invisible">*</span></label>
                        <div class="form-check" *ngFor="let modalidade of modalidades">
                            <input formControlName="modalidade" class="form-check-input" name="modalidade" type="radio" [value]="modalidade.id" id="flexCheckDefault" [attr.disabled]="visualizar ? '': null">
                            <label class="form-check-label" for="flexCheckDefault">
                &nbsp;{{modalidade.nome}}
              </label>
                        </div>
                    </div>
                    <div class="col-6">
                        <label for="">Nome Despachante: <span id="nomeDespachante-required-label" class="invisible">*</span></label>
                        <select class="custom-select" formControlName="nomeDespachante" [ngClass]="{'border border-danger': form.get('nomeDespachante')?.invalid && !form.get('nomeDespachante')?.pristine}" [attr.disabled]="visualizar ? '': null">
                          <option>Selecione</option>
                          <!-- <option *ngIf="usuarioExterno" [ngValue]="nomeUsuarioLogado" selected>{{nomeUsuarioLogado}}</option> -->
                          <option *ngFor="let nomeDespachante of nomesDespachantes" [ngValue]="nomeDespachante.nomeDespachante" [selected]="nomeDespachante.nomeDespachante">
                            {{nomeDespachante.nomeDespachante}}
                          </option>
                        </select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <label for="">Telefone: <span id="telefoneContato-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="telefoneContato" [mask]="'(00) 0 0000-0000'" [ngClass]="{'border border-danger': form.get('telefoneContato')?.invalid && !form.get('telefoneContato')?.pristine}" [attr.disabled]="visualizar ? '': null">
                    </div>
                    <div class="col">
                        <label for="">E-mail: <span id="emailContato-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="emailContato" [ngClass]="{'border border-danger': form.get('emailContato')?.invalid && !form.get('emailContato')?.pristine}" [attr.disabled]="visualizar ? '': null">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3">
                        <label for="">Descarga Direta: <span id="descargaDireta-required-label" class="invisible">*</span></label>
                        <select name="" id="" class="custom-select" formControlName="descargaDireta" [ngClass]="{'border border-danger': form.get('descargaDireta')?.invalid && !form.get('descargaDireta')?.pristine}" [attr.disabled]="visualizar ? '': null">
              <option [ngValue]="null" disabled selected>Selecione</option>
              <option [ngValue]="true">Sim</option>
              <option [ngValue]="false">Não</option>
            </select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col">
                        <label for="">Comentário: <span id="comentario-required-label" class="invisible">*</span></label>
                        <textarea cols="60" rows="8" class="form-control" formControlName="comentario" maxlength="300" [ngClass]="{'border border-danger': form.get('comentario')?.invalid && !form.get('comentario')?.pristine}" (change)="removeSpacesFromExtremities('comentario')"
                            [attr.disabled]="visualizar ? '': null"></textarea>
                    </div>
                    <div class="col">
                        <label for="">Status do Documento: <span id="statusDocumento-required-label" class="invisible">*</span></label>
                        <textarea cols="60" rows="8" class="form-control" formControlName="statusDocumento" maxlength="300" [ngClass]="{'border border-danger': form.get('statusDocumento')?.invalid && !form.get('statusDocumento')?.pristine}" (change)="removeSpacesFromExtremities('statusDocumento')"
                            [attr.disabled]="visualizar ? '': null"></textarea>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col d-flex">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
