import { NotaFiscalCabotagemDTO } from './../../../models/SolicitacaoLiberacaoCabotagem/NotaFiscalCabotagemDTO';
import { UntypedFormControl, Validators, UntypedFormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Campo } from 'src/app/models/Campo';

@Component({
  selector: 'tr[app-linha-grid-relacao-notas-fiscais]',
  templateUrl: './linha-grid-relacao-notas-fiscais.component.html',
  styleUrls: ['./linha-grid-relacao-notas-fiscais.component.scss']
})
export class LinhaGridRelacaoNotasFiscaisComponent implements AfterViewInit {

  @Input() notaFiscal!: NotaFiscalCabotagemDTO;
  @Input() camposFormulario!: Campo[];
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() visualizar!: boolean;
  @Output() onSendInvoice: EventEmitter<NotaFiscalCabotagemDTO> = new EventEmitter<NotaFiscalCabotagemDTO>();
  @Output() onCheckLineValidness: EventEmitter<boolean> = new EventEmitter<boolean>();
  public id!: UntypedFormControl;
  public numeroNotaFiscal!: UntypedFormControl;
  public formNotaFiscal!: UntypedFormGroup;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder
  ) { }

  public ngAfterViewInit(): void {
    this.validation();
  }

  private validation(): void{
    this.id = new UntypedFormControl(this.notaFiscal.id, Validators.compose([Validators.required]));
    this.numeroNotaFiscal = new UntypedFormControl(this.notaFiscal.numeroNotaFiscal, Validators.compose([Validators.required]));
    this.formNotaFiscal = this.fb.group({
      id: this.id,
      numeroNotaFiscal: this.numeroNotaFiscal
    });
    this.formNotaFiscal.valueChanges.subscribe(
      () => {
        this.sendInvoiceData();
      }
    );
    this.sendInvoiceData();
    this.setSettingsfromFields();
  }

  private sendInvoiceData(): void{
    this.onSendInvoice.emit(this.getInvoicefromFormFields());
    this.changeDetector.detectChanges();
  }

  public getInvoicefromFormFields(): NotaFiscalCabotagemDTO{
    return this.formNotaFiscal.getRawValue();
  }

  public isInvoiceLineValid(): boolean{
    return !this.formNotaFiscal?.invalid;
  }

  public setSettingsfromFields(): void{
    if(this.visualizar){
      this.disableAllFormFields();
      return;
    }
    if(!this.camposFormulario?.length){
      this.enableAllFormFields();
      return;
    }
    for(let control in this.formNotaFiscal.controls){
      let fieldIndex = this.camposFormulario.findIndex(
        (field) => field.nome == control
      );
      if(fieldIndex >= 0){
        if(this.camposFormulario[fieldIndex].obrigatorio){
          this.formNotaFiscal.get(control)?.addValidators(Validators.required);
        }
        else{
          this.formNotaFiscal.get(control)?.removeValidators(Validators.required);
        }
        if(this.doesSolicitationHaveIntegration){
          if(this.camposFormulario[fieldIndex].leitura && !!this.formNotaFiscal.get(control)?.value){
            this.formNotaFiscal.get(control)?.disable();
          }
          else{
            this.formNotaFiscal.get(control)?.enable();
          }
        }
        else{
          if(this.camposFormulario[fieldIndex].leitura){
            this.formNotaFiscal.get(control)?.disable();
          }
          else{
            this.formNotaFiscal.get(control)?.enable();
          }
        }
        if(!!this.camposFormulario[fieldIndex]?.bloqueado){
          this.formNotaFiscal.get(control)?.disable();
        }
        if(!this.formNotaFiscal.get(control)?.value && this.camposFormulario[fieldIndex].obrigatorio){
          this.formNotaFiscal.get(control)?.enable();
        }
      }
      else{
        this.formNotaFiscal.get(control)?.enable();
        this.formNotaFiscal.get(control)?.removeValidators(Validators.required);
      }
      this.changeDetector.detectChanges();
    }
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.formNotaFiscal.controls){
      this.formNotaFiscal.get(field)?.enable();
      this.formNotaFiscal.get(field)?.removeValidators(Validators.required);
      this.changeDetector.detectChanges();
    }
  }

  public disableAllFormFields(): void{
    this.formNotaFiscal.disable({emitEvent: false});
    this.changeDetector.detectChanges();
  }

}
