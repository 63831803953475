<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Contêiner</span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}"
        data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false"
        aria-label="Toggle navigation"></i>
      <!--fa fa-chevron-down-->
    </div>
  </div>

  <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <form [formGroup]="form" >
      <div class="p-3">
        <div class="row mb-3">
          <div class="col-6">
            <label>Contêiner: *</label>
            <div class="input-group">
              <input formControlName="conteiner" class="form-control" placeholder="Digite aqui..."
                [ngClass]="{'border border-danger': form.get('conteiner')?.errors && !form.get('conteiner')?.pristine}"
              >
              <button (click)=pesquisarConteiner() class="action-primary-button" [disabled]="form.invalid || this.form.value.conteiner == ''">PESQUISAR</button>
            </div>
            <div *ngIf="form.get('conteiner')?.errors && !form.get('conteiner')?.pristine">
              <p class="text-danger" *ngIf="form.get('conteiner')?.errors?.['required']">
                Campo de preenchimento obrigatório!
              </p>
            </div>
          </div>
          <div class="col">
            <label>Peso Apurado: </label>
            <input formControlName="pesoApurado" type="text" class="form-control" placeholder="Digite aqui..."
            aria-label="Digite aqui..." aria-describedby="basic-addon2" maxlength="44">
          </div>
          <div class="col">
            <label>Tara: </label>
            <input formControlName="tara" type="text" class="form-control" placeholder="Digite aqui..."
            aria-label="Digite aqui..." aria-describedby="basic-addon2" maxlength="44">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label>Lacres:</label>
          </div>
        </div>
        <table>
          <tr>
            <th>Id</th>
            <th>Número</th>
          </tr>
          <tr *ngFor="let lacre of lacresN4" class="readonly-grid-line">
            <td>{{lacre.id}}</td>
            <td>{{lacre.numero}}</td>
          </tr>

          <!-- <tr>
            <td>
              <div class="input-group" id="check1">
                <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
                  aria-describedby="basic-addon2">
              </div>
            </td>
            <td>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
                  aria-describedby="basic-addon2">
              </div>
            </td>
          </tr> -->
        </table>

      </div>
    </form>
  </div>
</div>
