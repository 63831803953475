<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Dados do Envio</span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}"
        data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false"
        aria-label="Toggle navigation"></i>
    </div>
  </div>

  <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <form *ngIf="form" [formGroup]="form">
      <div class="p-3">
        <div class="row mb-3">
          <div class="col-3">
            <label>Data do Envio:</label>
            <input type="text" formControlName="dataEnvio" class="form-control" readonly [attr.disabled]="'disabled'"
            bsDatepicker #dp="bsDatepicker" [bsConfig]="bsConfig" placeholder="dd/mm/aaaa"
            style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; ">
          </div>
          <div class="col-3"></div>
          <div class="col-6">
            <label>Usuário:</label>
            <input formControlName="usuario" type="text" class="form-control"
              aria-describedby="basic-addon2" maxlength="14" readonly>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <label>Status Recepção: </label>
            <div class="input-group mb-3">
              <input formControlName="statusRecepcao" type="text" class="form-control"
                aria-describedby="basic-addon2" maxlength="100" readonly>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
