<div class="my-5">
    <form [formGroup]="form">
        <div class="row mb-3">
            <div class="col-3">
                <label for="">Período De:</label>
                <input type="text" formControlName="periodoDe" class="form-control" [bsConfig]="bsConfig" bsDatepicker
                  #dp="bsDatepicker" placeholder="Selecione a data"
                  style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                  [maxDate]="form.get('periodoAte')?.value ? form.get('periodoAte')?.value :currentDate ? currentDate : undefined"
                >
            </div>
            <div class="col-3">
                <label for="">Até:</label>
                <input type="text" formControlName="periodoAte" class="form-control" [bsConfig]="bsConfig" bsDatepicker
                  #dp="bsDatepicker" placeholder="Selecione a data"
                  style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                  [minDate]="form.get('periodoDe')?.value ? form.get('periodoDe')?.value : undefined" [maxDate]="currentDate ? currentDate : undefined"
                >
            </div>
            <div class="col-4">
                <label for="">Nome:</label>
                <input type="text" class="form-control" formControlName="nome">
            </div>
            <div class="col-2 action-button-group d-flex align-items-end">
                <button class="action-primary-button"  (click)="searchRpaApi()">PESQUISAR</button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="required-fields-paragraph">Preencher o período e/ou o nome para pesquisar</p>
            </div>
        </div>
    </form>
</div>
