import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-resposta-positiva-negativa',
  templateUrl: './modal-resposta-positiva-negativa.component.html',
  styleUrls: ['./modal-resposta-positiva-negativa.component.scss']
})
export class ModalRespostaPositivaNegativaComponent implements OnInit {

  public modalRef!: BsModalRef;

  @Input() public modalTitle!: string;
  @Input() public negativeText!: string;
  @Input() public positiveText!: string;

  @Output() public onChoosePositiveAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onChooseNegativeAction: EventEmitter<void> = new EventEmitter<void>();

  @Output() onOpenModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('modalRespostaPositivaNegativa', {static: false}) modal!: TemplateRef<any>;

  constructor(
    private modalService: BsModalService
  ) { }

  public ngOnInit(): void {

  }

  public openModalForConfirmOrRefuse(): void{
    this.modalRef = this.modalService.show(this.modal, {
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
    this.onOpenModal.emit();
  }

  public closeModal(): void{
    this.modalService.hide();
    this.onCloseModal.emit();
  }

  public negativeAction(): void{
    this.onChooseNegativeAction.emit();
    this.closeModal();
  }

  public positiveAction(): void{
    this.onChoosePositiveAction.emit();
    this.closeModal();
  }

}
