import { AuthenticationService } from './../../../services/authentication/authentication.service';
import { DocumentCustomPattern } from './../../../shared/utils/constantes-documentos';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Campo } from 'src/app/models/Campo';
import { Moeda } from 'src/app/models/Moeda';
import { debounceTime } from 'rxjs';
import { DadosComplementaresExportacao } from 'src/app/models/SolicitacaoLiberacaoExportacao/DadosComplementaresExportacao';
import { Canal } from 'src/app/models/Canal';
import { DocumentosService } from 'src/app/services/documentos.service';
import { DadosNavioVigemDTO } from 'src/app/models/SolicitacaoLiberacaoCabotagem/DadosNavioVigemDTO';
import { MaskPipe } from 'ngx-mask';
@Component({
  selector: 'app-dados-complementares-exportacao',
  templateUrl: './dados-complementares-exportacao.component.html',
  styleUrls: ['./dados-complementares-exportacao.component.scss']
})
export class DadosComplementaresExportacaoComponent implements OnInit, AfterViewInit, AfterContentInit {
  public isCollapsed: boolean = false;
  @Input() public Moedas: Moeda[] = [];
  @Input() public Canais: Canal[] = [];
  public form!: UntypedFormGroup;
  private colorTheme = 'theme-dark-blue';
  public bsConfig?: Partial<BsDatepickerConfig>;
  public namePattern = DocumentCustomPattern.NAME_PATTERN;
  public dadosComplementares!: DadosComplementaresExportacao;
  public locale = 'pt-br';

  @Input() camposFormulario!: Campo[];
  @Input() visualizar?: boolean = false;
  @Output() dataFormOut: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() onComplementaryDataLoad: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private localeService: BsLocaleService,
    private documentosService: DocumentosService,
    private authenticationService: AuthenticationService,
    private maskPipe: MaskPipe
  ) {
    this.validation();
   }

  public ngOnInit(): void {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.validation();
    }

  public ngAfterViewInit(): void {
    this.setSettingsfromFields();
  }

  public ngAfterContentInit(): void {
    this.onComplementaryDataLoad.emit();
  }


  private validation(): void{
    this.form = this.fb.group({
      navio: [''],
      viagem: [''],
      valorFob: [''],
      moeda: [''],
      valorMercadoria: [''],
      dataRegistro: [null],
      dataDesembaraco: [null],
      situacaoAduaneira: [''],
      telefoneContato: [''],
      emailContato: ['', [Validators.email]],
      unidadeDespacho: [''],
      unidadeEmbarque: [''],
      canal: [''],
      recinto: [''],
      embarqueDireto:[''],
      solicitante: [this.authenticationService.userName, [Validators.required]]
    });
    this.form.valueChanges
      .pipe(
        debounceTime(100)
      )
      .subscribe({
        next: () => {
          this.sendComplementaryData();
        }
      });
  }

  public removeSpacesFromExtremities(inputName: string): void{
    this.form.get(inputName)?.setValue((this.form.get(inputName)?.value as string).trim());
  }

  public sendComplementaryData(): void {
    this.dadosComplementares = {
      navio: this.form.value.navio,
      viagem: this.form.value.viagem,
      valorFob: this.form.value.valorFob,
      moeda: this.Moedas.find(moeda => moeda.id === this.form.value.moeda)!,
      valorMercadoria: this.form.value.valorMercadoria,
      dataRegistro: this.form.value.dataRegistro,
      dataDesembaraco: this.form.value.dataDesembaraco,
      situacaoAduaneira: this.form.value.situacaoAduaneira,
      telefoneContato: this.form.value.telefoneContato,
      emailContato: this.form.value.emailContato,
      unidadeDespacho: this.form.value.unidadeDespacho,
      unidadeEmbarque: this.form.value.unidadeDespacho,
      canal: this.Canais.find(canal => canal.id === this.form.value.canal)!,
      recinto: this.form.value.recinto,
      embarqueDireto:this.form.value.embarqueDireto,
      solicitante:this.form.value.solicitante
    }
    this.dataFormOut.emit({'dadosComplementares': this.dadosComplementares});
  }

  public setFormValuesFromReceivedComplementaryData(complementaryData: DadosComplementaresExportacao | undefined): void{
    if(!complementaryData){
      return;
    }
    this.form.get('navio')?.setValue(complementaryData?.navio);
    this.form.get('viagem')?.setValue(complementaryData?.viagem);
    this.form.get('valorFob')?.setValue(complementaryData?.valorFob);
    if(complementaryData.moeda){
      this.form.get('moeda')?.setValue(complementaryData?.moeda?.id);
    }
    this.form.get('valorMercadoria')?.setValue(complementaryData?.valorMercadoria);
    if(complementaryData?.dataRegistro){
      this.form.get('dataRegistro')?.setValue(new Date(complementaryData?.dataRegistro));
    }
    if(complementaryData?.dataDesembaraco){
      this.form.get('dataDesembaraco')?.setValue(new Date(complementaryData?.dataDesembaraco));
    }
    if(complementaryData.canal){
      this.form.get('canal')?.setValue(complementaryData?.canal?.id)
    }
    this.form.get('telefoneContato')?.setValue(complementaryData?.telefoneContato);
    this.form.get('emailContato')?.setValue(complementaryData?.emailContato);
    this.form.get('embarqueDireto')?.setValue(complementaryData?.embarqueDireto);
    this.form.get('situacaoAduaneira')?.setValue(complementaryData?.situacaoAduaneira);
    this.form.get('unidadeDespacho')?.setValue(complementaryData?.unidadeDespacho);
    this.form.get('unidadeEmbarque')?.setValue(complementaryData?.unidadeEmbarque);
    this.form.get('recinto')?.setValue(complementaryData?.recinto);
    this.form.get('solicitante')?.setValue(complementaryData?.solicitante || this.authenticationService.userName);
    this.changeDetector.detectChanges();
  }

  public setSettingsfromFields(): void{
    if(!this.camposFormulario?.length){
      if(this.visualizar){
        this.form.disable();
        this.changeDetector.detectChanges();
      }
      else{
        this.enableAllFormFields();
      }
      return;
    }
    for(let control in this.form.controls){
      let fieldIndex = this.camposFormulario.findIndex(
        (field) => field.nome == control
      );
      if(fieldIndex >= 0){
        if(this.camposFormulario[fieldIndex].obrigatorio){
          this.form.get(control)?.addValidators(Validators.required);
        }
        else{
          this.form.get(control)?.removeValidators(Validators.required);
        }
        if(this.camposFormulario[fieldIndex].leitura && !!this.form.get(control)?.value){
          this.form.get(control)?.disable();
        }
        else{
          this.form.get(control)?.enable();
        }
        if(!!this.camposFormulario[fieldIndex]?.bloqueado){
          this.form.get(control)?.disable();
        }
        if(!this.form.get(control)?.value && this.camposFormulario[fieldIndex].obrigatorio){
          this.form.get(control)?.enable();
        }
      }
      else{
        this.form.get(control)?.enable();
        this.form.get(control)?.removeValidators(Validators.required);
      }
      this.changeDetector.detectChanges();
      this.showFieldAsRequired(control);
    }
    this.changeDetector.detectChanges();
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.form.controls){
      this.form.get(field)?.enable();
      this.form.get(field)?.removeValidators(Validators.required);
      this.showFieldAsRequired(field);
      this.changeDetector.detectChanges();
    }
  }

  public clean(){
    this.form.reset();
  }

  public getComplementaryDataFromForm(): DadosComplementaresExportacao{
    let complementaryData = this.form.getRawValue() as DadosComplementaresExportacao;
    if(!complementaryData?.solicitante?.length){
      complementaryData.solicitante = this.authenticationService.userName ?? '';
    }
     if(this.form.get('canal')?.value){
      let canal: Canal = {id: this.form.get('canal')?.value, nome: '', descricao:''}
      complementaryData.canal = canal;
    }
    return complementaryData;
  }

  public get areComplementaryDataValid(): boolean{
    return !this.form?.invalid;
  }

  private showFieldAsRequired(field: string): void{
    if(!!this.form.get(field)?.hasValidator(Validators.required)){
      document.getElementById(`${field}-required-label`)?.classList?.remove('invisible');
      document.getElementById(`${field}-required-label`)?.classList?.add('visible');
    }
    else{
      document.getElementById(`${field}-required-label`)?.classList?.remove('visible');
      document.getElementById(`${field}-required-label`)?.classList?.add('invisible');
    }
    this.changeDetector.detectChanges();
  }

  public setShipAndTripFromSearchByContainerNumber(shipAndTrip: DadosNavioVigemDTO): void{
    this.form?.patchValue({
      navio: shipAndTrip?.navio ? shipAndTrip.navio : this.form?.value?.navio,
      viagem: shipAndTrip?.viagem ? shipAndTrip.viagem : this.form?.value?.viagem
    });
  }
  public setTelefoneContatoEmail(telefoneContato: string = "") {
    console.log(telefoneContato);
    let telefoneFormatado = this.maskPipe.transform(telefoneContato, '(00) 0 0000-0000')
   this.form?.patchValue({
    telefoneContato: !!telefoneContato ? telefoneFormatado : this.form?.value?.telefoneContato,
    emailContato: this.authenticationService.userEmail != null ? this.authenticationService.userEmail : this.form?.value?.emailContato,
   });

   this.changeDetector.detectChanges();
  }

}
