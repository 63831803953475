import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { DadosEnvioEntregaDocumentoTransporte } from 'src/app/models/EntregaDueContingencia/EntregaDocumentoTransporte/DadosEnvioEntregaDocumentoTransporte';
import { DadosDocumentoTransporteN4 } from './../../../models/EntregaDueContingencia/EntregaDocumentoTransporte/DadosDocumentoTransporteN4';
import { ModalRespostaPositivaNegativaComponent } from './../../../shared/components/modais/modal-resposta-positiva-negativa/modal-resposta-positiva-negativa.component';
import { EntregaBaseDocumentoTransporte } from './../../../models/EntregaDueContingencia/EntregaDocumentoTransporte/EntregaBaseDocumentoTransporte';
import { DocumentoEntregaDocumentoTransporte } from 'src/app/models/EntregaDueContingencia/EntregaDocumentoTransporte/DocumentoEntregaDocumentoTransporte';
import { UntypedFormBuilder, FormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { DadosEnvioEDTComponent } from '../../../components/entrega-due-contingencia/entrega-documento-transporte/dados-envio-edt/dados-envio-edt.component';
import { DadosDocumentoTransporteCCTEDTComponent } from '../../../components/entrega-due-contingencia/entrega-documento-transporte/dados-documento-transporte-cct-edt/dados-documento-transporte-cct-edt.component';
import { DadosDocumentoTransporteN4EDTComponent } from '../../../components/entrega-due-contingencia/entrega-documento-transporte/dados-documento-transporte-n4-edt/dados-documento-transporte-n4-edt.component';
import { DocumentoEDTComponent } from '../../../components/entrega-due-contingencia/entrega-documento-transporte/documento-edt/documento-edt.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DadosDocumentoTransporteCCT } from 'src/app/models/EntregaDueContingencia/EntregaDocumentoTransporte/DadosDocumentoTransporteCCT';
import { EntregaDueContigenciaService } from 'src/app/services/entrega-due-contigencia.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DadosEntregaDocumentoTransporte, DadosEntregaDocumentoTransporteCCTDTO, DadosEntregaDocumentoTransporteN4DTO } from 'src/app/models/EntregaDueContingencia/EntregaDocumentoTransporte/ConteinerEntregaDocumentoTransporte';
import { ToastService } from 'src/app/services/toast.service';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';

@Component({
  selector: 'app-entrega-documento-transporte',
  templateUrl: './entrega-documento-transporte.component.html',
  styleUrls: ['./entrega-documento-transporte.component.scss']
})
export class EntregaDocumentoTransporteComponent implements OnInit {
  public blockSending: boolean = true;

  public title: string = 'Entrega por Base Documento de Transporte';
  @ViewChild(DocumentoEDTComponent) documento!: DocumentoEDTComponent;
  @ViewChild(DadosDocumentoTransporteN4EDTComponent) dadosDocumentoTransporteN4!: DadosDocumentoTransporteN4EDTComponent;
  @ViewChild(DadosDocumentoTransporteCCTEDTComponent) dadosDocumentoTransporteCCT!: DadosDocumentoTransporteCCTEDTComponent;
  @ViewChild(DadosEnvioEDTComponent) dadosEnvio!: DadosEnvioEDTComponent;
  @ViewChild(ModalRespostaPositivaNegativaComponent) modalConfirmarEnviarCCTComDivergencias!: ModalRespostaPositivaNegativaComponent;
  @ViewChild(ModalRespostaPositivaNegativaComponent) modalConfirmarSalvarComDivergencias!: ModalRespostaPositivaNegativaComponent;
  public readonly enviarCCT = new AuthRoles(Telas.entregaDueDocumentoTransporte , Permissoes.enviarCCT)
  public readonly salvar = new AuthRoles(Telas.entregaDueDocumentoTransporte , Permissoes.salvar)

  public identificador!: UntypedFormControl;

  constructor(
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private entregaDueContingenciaService: EntregaDueContigenciaService,
    private toastService:ToastService,
    private verificacaoDocumentosService: VerificacaoDocumentosService
  ) { }

  public ngOnInit(): void {
    this.identificador = new UntypedFormControl('');
  }

  public clear(): void {
    this.spinner.show('limpar');
    setTimeout(() => {
      this.spinner.hide('limpar');
    }, 2000);
    this.documento.clear();
    this.dadosDocumentoTransporteN4.clear();
    this.dadosDocumentoTransporteCCT.clear();
    this.dadosEnvio.clear();
    this.identificador.reset();
  }

  public searchTransportDocumentData(documento: any): void {
    if (!documento) {
      return;
    }
    let documentoTransporte: DocumentoEntregaDocumentoTransporte = documento.documento as DocumentoEntregaDocumentoTransporte;
    this.spinner.show();
    this.entregaDueContingenciaService.searchForContainerOnForEntregaDocumentoTransporte(documentoTransporte)
      .subscribe({
        next: (conteiner) => {
          this.blockSending = false;
          let conteinerData = conteiner.data as DadosEntregaDocumentoTransporte;
          console.log(conteinerData)
          this.identificador.setValue(conteinerData.identificador ?? '');
          let dadosN4 = conteinerData.dadosEntregaDocumentoTransporteN4;
          let dadosCCT = conteinerData.dadosEntregaDocumentoTransporteCCT;
          if (dadosN4.recepcaoRealizadaComSucesso) {
            this.dadosEnvio.setFormValuesFromReceivedShippingData({
              dataEnvio: dadosN4.dataEnvio as Date,
              usuario: dadosN4.usuario as string,
              status: dadosN4.statusRecepcao as string
            });
            this.blockSending = true;
          }
          if (!dadosN4.pesagem) {
            this.toastService.error("Contêiner não possui o registro de TC_PESAGEM");
            this.blockSending = true;
          }
          this.dadosDocumentoTransporteN4.setFormValuesFromReceivedN4TransportDocumentData(dadosN4);
          this.dadosDocumentoTransporteCCT.setFormValuesFromReceivedCCTTransportDocumentData(dadosCCT);
          this.spinner.hide('carregando');
          if (!this.checkIfContainerNumbersAndSealsAreEqual(dadosN4, dadosCCT)) {
            this.toastService.error("Existe(m) divergência(s) entre o(s) Lacre(s) do N4 x CCT!");
            this.blockSending = true;
          }

          this.spinner.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = err.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        },
        complete: () => {
          // this.changeDetector.detectChanges();
        }
      });
    // this.isSearchDone = true;
  }

  public setDataFromReceivedEntregaBaseDocumentoTransporte(documentoTransporte: EntregaBaseDocumentoTransporte): void {
    this.dadosDocumentoTransporteN4.setFormValuesFromReceivedN4TransportDocumentData(documentoTransporte.dadosDocumentoTransporteN4);
    this.dadosDocumentoTransporteCCT.setFormValuesFromReceivedCCTTransportDocumentData(documentoTransporte.dadosDocumentoTransporteCCT);
    this.dadosEnvio.setFormValuesFromReceivedShippingData(documentoTransporte.dadosEnvio);
  }

  public checkDataSendingToCCT(): void{
    let dadosVeiculoConferem: boolean;
    let dadosConteinerConferem: boolean;
    let dadosEmbalagemConferem: boolean;
    let dadosUnidadeTransporteConferem: boolean;
    let formN4 = this.dadosDocumentoTransporteN4.getDocumentoN4FromForm();
    let formCct = this.dadosDocumentoTransporteCCT.getDocumentoCCTFromForm();
    dadosVeiculoConferem = this.checkIfVehiclePlatesAndConductorDocumentAreEqual(formN4, formCct);
    dadosConteinerConferem = this.checkIfContainerNumbersAndSealsAreEqual(formN4, formCct);
    dadosEmbalagemConferem = (formCct.dadosEmbalagemConferem == 'S');
    dadosUnidadeTransporteConferem = this.checkIfTransportUnitsAreEqual(formN4, formCct);
    this.dadosDocumentoTransporteCCT.setDivergency(dadosVeiculoConferem, dadosConteinerConferem, dadosEmbalagemConferem, dadosUnidadeTransporteConferem);
    if(dadosVeiculoConferem && dadosConteinerConferem && dadosEmbalagemConferem && dadosUnidadeTransporteConferem){
      this.sendDataToCCT();
    }
    else{
      this.modalConfirmarEnviarCCTComDivergencias.openModalForConfirmOrRefuse();
    }
  }

  public checkDataSendingToSave(): void{
    let dadosVeiculoConferem: boolean;
    let dadosConteinerConferem: boolean;
    let dadosEmbalagemConferem: boolean;
    let dadosUnidadeTransporteConferem: boolean;
    let formN4 = this.dadosDocumentoTransporteN4.getDocumentoN4FromForm();
    let formCct = this.dadosDocumentoTransporteCCT.getDocumentoCCTFromForm();
    dadosVeiculoConferem = this.checkIfVehiclePlatesAndConductorDocumentAreEqual(formN4, formCct);
    dadosConteinerConferem = this.checkIfContainerNumbersAndSealsAreEqual(formN4, formCct);
    dadosEmbalagemConferem = (formCct.dadosEmbalagemConferem == 'S');
    dadosUnidadeTransporteConferem = this.checkIfTransportUnitsAreEqual(formN4, formCct);
    this.dadosDocumentoTransporteCCT.setDivergency(dadosVeiculoConferem, dadosConteinerConferem, dadosEmbalagemConferem, dadosUnidadeTransporteConferem);
    if(dadosVeiculoConferem && dadosConteinerConferem && dadosEmbalagemConferem && dadosUnidadeTransporteConferem){
      this.save();
    }
    else{
      this.modalConfirmarSalvarComDivergencias.openModalForConfirmOrRefuse();
    }
  }

  public async sendDataToCCT() {
    if (!this.isEnviarCCTEnabled) {
      return;
    }
    this.spinner.show();
    this.entregaDueContingenciaService.sendDataToCCTForEntregaDocumentoTransporte(this.getEntregaDocumentoTransporteFromForms())
      .subscribe({
        next: (response) => {
          response.message?.forEach(
            (message) => {
              if (message && message.length) {
                this.toastService.success(message);
              }
            }
          )
          let dadosEnvio = response.data as DadosEnvioEntregaDocumentoTransporte;
          this.dadosEnvio.setFormValuesFromReceivedShippingData(dadosEnvio);
          this.blockSending = true;
          this.spinner.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = err.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
          if(errorMessage.data){
            this.dadosEnvio.setFormValuesFromReceivedShippingData(errorMessage.data);
          }
        }
      });
  }

  public async save() {
    if (!this.isSalvarEnabled) {
      return;
    }
    this.spinner.show('carregando');
    this.entregaDueContingenciaService.saveEntregaDocumentoTransporte(this.getEntregaDocumentoTransporteFromForms())
      .subscribe({
        next: (response) => {
          response.message?.forEach(
            (message) => {
              if (message?.length) {
                this.toastService.success(message);
              }
            }
          );
          this.spinner.hide('carregando');
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide('carregando');
          console.log(err);
          let errorMessage = err.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
          if(errorMessage.data){
            this.dadosEnvio.setFormValuesFromReceivedShippingData(errorMessage.data);
          }
        }
      });
  }

  public getEntregaDocumentoTransporteFromForms(): DadosEntregaDocumentoTransporte{
    let dados: DadosEntregaDocumentoTransporte = new DadosEntregaDocumentoTransporte();
    dados.dadosEntregaDocumentoTransporteCCT = this.dadosDocumentoTransporteCCT?.getDocumentoCCTFromForm();
    dados.dadosEntregaDocumentoTransporteN4 = this.dadosDocumentoTransporteN4?.getDocumentoN4FromForm();
    dados.tipoDocumento = this.documento?.getDocumentoFromForm()?.tipoDocumento;
    dados.numeroDocumento = this.documento?.getDocumentoFromForm()?.numeroDocumento;
    dados.identificador = this.identificador.value;
    return dados;
  }

  public get areFormsValid(): boolean{
    return this.documento?.isDocumentoValid() &&
      this.dadosDocumentoTransporteN4?.isFormN4Valid() &&
      this.dadosDocumentoTransporteCCT?.isFormCCTValid();
  }

  public checkIfVehiclePlatesAndConductorDocumentAreEqual(dadosN4: DadosEntregaDocumentoTransporteN4DTO, dadosCct: DadosEntregaDocumentoTransporteCCTDTO): boolean{
    if(!this.checkIfVehiclePlatesAreEqual(dadosN4, dadosCct)){
      return false;
    }
    if(this.verificacaoDocumentosService.eraseMask(dadosN4.cpfCondutor ?? '') !== this.verificacaoDocumentosService.eraseMask(dadosCct.cpfCondutor ?? '')){
      return false;
    }
    if((dadosN4.nomeCondutor ?? '')?.trim() !== (dadosCct.nomeCondutor ?? '')?.trim()){
      return false;
    }
    return true;
  }

  public checkIfTransportUnitsAreEqual(dadosN4: DadosEntregaDocumentoTransporteN4DTO, dadosCct: DadosEntregaDocumentoTransporteCCTDTO): boolean{
    if(!this.checkIfVehiclePlatesAreEqual(dadosN4, dadosCct)){
      return false;
    }
    if(this.verificacaoDocumentosService.eraseMask(dadosN4.cpfCnpjTransportador ?? '') !== this.verificacaoDocumentosService.eraseMask(dadosCct.cnpjCpfTransportador ?? '')){
      return false;
    }
    return true;
  }

  private checkIfVehiclePlatesAreEqual(dadosN4: DadosEntregaDocumentoTransporteN4DTO, dadosCct: DadosEntregaDocumentoTransporteCCTDTO): boolean{
    let placasN4: string[] = [
      (dadosN4?.placaCavalo ?? '').trim(),
      (dadosN4?.placaReboque1 ?? '').trim(),
      (dadosN4?.placaReboque2 ?? '').trim(),
      (dadosN4?.placaReboque3 ?? '').trim()
    ];
    let placasCCT: string[] = [
      (dadosCct?.placaCavalo ?? '').trim(),
      (dadosCct?.placaReboque1 ?? '').trim(),
      (dadosCct?.placaReboque2 ?? '').trim(),
      (dadosCct?.placaReboque3 ?? '').trim()
    ];
    if(!placasN4?.every(placaN4 => placasCCT.includes(placaN4))){
      return false;
    }
    if(!placasCCT?.every(placaCCT => placasN4.includes(placaCCT))){
      return false;
    }
    return true;
  }

  public checkIfContainerNumbersAndSealsAreEqual(dadosN4: DadosEntregaDocumentoTransporteN4DTO, dadosCct: DadosEntregaDocumentoTransporteCCTDTO): boolean{
    let conteineresN4 = dadosN4?.relacaoConteinerLacresN4?.map(conteiner => conteiner.conteiner.trim()) ?? [];
    let conteineresCCT = dadosCct?.relacaoConteinerLacresCCT?.map(conteiner => conteiner.conteiner.trim()) ?? [];
    if(
      !conteineresN4.every(conteiner => conteineresCCT.includes(conteiner)) ||
      !conteineresCCT.every(conteiner => conteineresN4.includes(conteiner))
    ){
      return false;
    }
    if(conteineresN4?.length != conteineresCCT?.length){
      return false;
    }
    if(!dadosN4?.relacaoConteinerLacresN4?.length && !dadosCct?.relacaoConteinerLacresCCT?.length){
      return true;
    }
    let lacreValido = true;
    dadosN4?.relacaoConteinerLacresN4?.forEach(
      (n4) => {
        let index = dadosCct?.relacaoConteinerLacresCCT?.findIndex(conteiner => conteiner.conteiner.trim() == n4.conteiner.trim()) ?? -1;
        if(index < 0){
          lacreValido = false;
        }
        let lacresN4 = n4.lacres.split(',').map(lacre => lacre.trim()) ?? [];
        let lacresCCT = dadosCct?.relacaoConteinerLacresCCT?.[index].lacres.split(',').map(lacre => lacre.trim()) ?? [];
        if(lacresN4?.length != lacresCCT?.length){
          lacreValido = false;
        }
        if(!lacresN4?.length || !lacresCCT?.length){
          lacreValido = false;
        }
        if(
          !lacresN4.every(lacreN4 => lacresCCT.includes(lacreN4)) ||
          !lacresCCT.every(lacreCCT => lacresN4.includes(lacreCCT))
        ){
          lacreValido = false;
        }
      }
    );
    return lacreValido;
  }

  public get isEnviarCCTEnabled(): boolean{
    return !this.blockSending &&
      this.areFormsValid &&
      (!!this.dadosDocumentoTransporteN4?.getContainers()?.length ||
      !!this.dadosDocumentoTransporteCCT?.getContainers()?.length)
  }

  public get isSalvarEnabled(): boolean{
    return this.areFormsValid &&
      (!!this.dadosDocumentoTransporteN4?.getContainers()?.length ||
      !!this.dadosDocumentoTransporteCCT?.getContainers()?.length)
  }
}
