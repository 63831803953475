<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Dados do Documento de Transporte no N4</span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}"
        data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false"
        aria-label="Toggle navigation"></i>
    </div>
  </div>
  <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <form [formGroup]="form">
      <div class="p-3">
        <div class="row mb-3">
          <div class="col">
            <label>CPF Condutor:</label>
            <input type="text" formControlName="cpfCondutor"
              [ngClass]="{'border border-danger': form.get('cpfCondutor')?.errors && form.get('cpfCondutor')?.touched }"
              class="form-control" mask="000.000.000-00" placeholder="Digite aqui..." aria-label="Digite aqui..."
              aria-describedby="basic-addon2" [required]="checkIfFieldIsRequired('cpfCondutor')"
              [readonly]="checkIfFieldIsReadonly('cpfCondutor')">
          </div>
          <div class="col">
            <label for="">Nome Condutor:</label>
            <input type="text" class="form-control" formControlName="nomeCondutor" placeholder="Digite aqui..."
              (change)="removeSpacesFromExtremities('nomeCondutor')" [required]="checkIfFieldIsRequired('nomeCondutor')"
              [readonly]="checkIfFieldIsReadonly('nomeCondutor')">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <label>Tipo de Veículo: </label>
            <select formControlName="tipoVeiculo"
              [ngClass]="{'border border-danger': form.get('tipoVeiculo')?.invalid && !form.get('tipoVeiculo')?.pristine && form.get('tipoVeiculo')?.value == null} "
              class="custom-select" id="tipoVeiculo"
              [attr.disabled]="checkIfFieldIsReadonly('tipoVeiculo') ? '' : null">
              <option [ngValue]="null" selected disabled>Selecione</option>
              <option *ngFor="let tipoVeiculo of TipoVeiculos" [ngValue]="tipoVeiculo">{{tipoVeiculo.nome}}</option>
            </select>
          </div>
          <!-- <div class="row mb-3"> -->
          <div class="col-3">
            <label for="">Placa Cavalo:</label>
            <input type="text" class="form-control" formControlName="placaCavalo" placeholder="Digite aqui..."
              [readonly]="checkIfFieldIsReadonly('placaCavalo')">
          </div>
          <div class="col-3" *ngIf="quantidadeReboques >= 1">
            <label for="">Placa Reboque 1:</label>
            <input type="text" class="form-control" formControlName="placaReboque1" placeholder="Digite aqui..."
              [readonly]="checkIfFieldIsReadonly('placaReboque1')">
          </div>
        </div>
        <div class="row mb-3">

          <div class="col-3" *ngIf="quantidadeReboques >= 2">
            <label for="">Placa Reboque 2:</label>
            <input type="text" class="form-control" formControlName="placaReboque2" placeholder="Digite aqui..."
              [readonly]="checkIfFieldIsReadonly('placaReboque2')">
          </div>
          <div class="col-3" *ngIf="quantidadeReboques >= 3">
            <label for="">Placa Reboque 3:</label>
            <input type="text" class="form-control" formControlName="placaReboque3" placeholder="Digite aqui..."
              [readonly]="checkIfFieldIsReadonly('placaReboque3')">
          </div>
        </div>
        <h3>Relação de Contêineres N4:</h3>
        <div class="overflow-auto my-3">
          <table class="grid-table">
            <thead>
              <tr>
                <th>Contêiner</th>
                <th>Peso Apurado</th>
                <th>Tara</th>
                <th>Lacres</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let lacre of lacresN4" app-linha-grid-relacao-conteiner-n4-documento-transporte
                [relacaoConteiner]="lacre">
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>
</div>
