import { Component, EventEmitter, OnInit, Output, ChangeDetectorRef, Input, ViewChildren, QueryList, AfterContentInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { Campo } from 'src/app/models/Campo';
import { ConteinerCabotagem } from 'src/app/models/SolicitacaoLiberacaoCabotagem/ConteinerCabotagem';
import { LinhaGridRelacaoConteinerCabotagemComponent } from '../linha-grid-relacao-conteiner-cabotagem/linha-grid-relacao-conteiner-cabotagem.component';

@Component({
  selector: 'app-relacao-conteiner-cabotagem',
  templateUrl: './relacao-conteiner-cabotagem.component.html',
  styleUrls: ['./relacao-conteiner-cabotagem.component.scss']
})
export class RelacaoConteinerCabotagemComponent implements OnInit, AfterContentInit{

  public isCollapsed: boolean = false;
  public containerRelationList!: ConteinerCabotagem[];
  public areAllItemsSelected!: boolean;
  public selectedContainerNumbers: string[] = [];
  public form!: UntypedFormGroup;
  public relacaoConteinerReadonly: boolean = false;
  public relacaoConteinerRequired: boolean = false;
  public arvoreParteReadonly?: boolean;

  @Input() camposFormulario!: Campo[];
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() isInsideModal!: boolean;
  @Input() visualizar?: boolean = false;
  @Input() selectedDocumentType?: string;
  @Input() shouldBlockPartQuantityEditionByDocumentType?: boolean;
  @Output() dataFormOut: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() onUpdateSelectedContainers: EventEmitter<void> = new EventEmitter<void>();
  @Output() onRelacaoConteinerLoad: EventEmitter<void> = new EventEmitter<void>();
  @Output() onEraseConteiner: EventEmitter<ConteinerCabotagem> = new EventEmitter<ConteinerCabotagem>()
  @Output() onSearchShipAndTripByContainerNumber: EventEmitter<string> = new EventEmitter<string>()

  @ViewChildren(LinhaGridRelacaoConteinerCabotagemComponent) containerGrid!: QueryList<LinhaGridRelacaoConteinerCabotagemComponent>;

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {

   }

  public ngOnInit(): void {
    this.containerRelationList = [];
    this.areAllItemsSelected = false;
    this.selectedContainerNumbers = [];
    this.dataFormOut.emit({"relacaoConteineres": []});
  }

  public ngAfterContentInit(): void {
    this.onRelacaoConteinerLoad.emit();
  }

  public sendContainerRelationData(): void {
    let containers: ConteinerCabotagem[] = this.getContainersListFromForm();
    if(!containers || !containers.length){
      this.dataFormOut.emit({'relacaoConteineres': []});
      return;
    }
    this.dataFormOut.emit({'relacaoConteineres': containers});
  }

  public setFormValuesFromReceivedContainerRelation(relacaoConteineres: ConteinerCabotagem[] | undefined): void{
    this.containerRelationList = [];
    if(!relacaoConteineres){
      return;
    }
    this.containerRelationList = [...relacaoConteineres] ?? [];
    this.changeDetector.detectChanges();
  }

  public setSettingsfromFields(): void{
    if(!this.camposFormulario?.length){
      this.containerGrid.forEach(
        (conteiner) => {
          conteiner.enableAllFormFields();
        }
      );
      return;
    }
    let campoRelacaoConteiner: Campo | undefined = this.camposFormulario?.find(campo => campo.nome == 'relacaoConteiner');
    this.relacaoConteinerReadonly = !!campoRelacaoConteiner?.leitura || !!campoRelacaoConteiner?.bloqueado;
    this.relacaoConteinerRequired = !!campoRelacaoConteiner?.obrigatorio;
    let campoArvoreParte: Campo | undefined = this.camposFormulario?.find(campo => campo.nome == 'arvoreParte');
    this.arvoreParteReadonly = campoArvoreParte ?
      (!!campoArvoreParte?.leitura || !!campoArvoreParte?.bloqueado) :
      false;
    this.changeDetector.detectChanges();
    this.containerGrid.forEach(
      (conteiner) => {
        conteiner.setSettingsfromFields();
      }
    );
    this.changeDetector.detectChanges();
  }

  public get areAllContainersValid(): boolean{
    if(this.relacaoConteinerRequired && !this.getContainersListFromForm()?.length){
      return false;
    }
    if(
      this.selectedDocumentType === 'CTE' &&
      !this.getSelectedContainersFromForms()?.length
    ){
      return false;
    }
    if(!this.containerGrid?.length){
      return true;
    }
    return this.containerGrid?.map(
      (conteiner) => conteiner.isContainerValid
    )?.every(line => line);
  }

  public cancel(){
    this.containerRelationList = [];
  }

  public updateContainerLine(): void{
    this.sendContainerRelationData();
  }

  public switchSelectAll(): void{
    this.areAllItemsSelected = !this.areAllItemsSelected;
    this.updateSelectedForAll();
  }

  private updateSelectedForAll(): void{
    this.containerGrid.forEach(
      (container) => {
        container.switchSelectionFromParent(this.areAllItemsSelected);
      }
    );
  }

  public addSelectedContainerNumber(containerNumber: string): void{
    if(this.selectedContainerNumbers.includes(containerNumber)){
      return;
    }
    this.selectedContainerNumbers.push(containerNumber);
  }

  public removeSelectedContainerNumber(containerNumber: string): void{
    if(!this.selectedContainerNumbers.includes(containerNumber)){
      return;
    }
    this.selectedContainerNumbers.splice(this.selectedContainerNumbers.indexOf(containerNumber), 1);
  }

  public getContainersListFromForm(): ConteinerCabotagem[]{
    return this.containerGrid?.map(
      (conteiner) => conteiner.getContainerRelationFromFormFields()
    ) ?? [];
  }

  public getSelectedContainersFromForms(): ConteinerCabotagem[]{
    return this.containerGrid?.filter(
      (conteiner) => !!conteiner.selecionado?.value
    )?.map(
      (conteiner) => conteiner.getContainerRelationFromFormFields()
    ) ?? [];
  }

  public areAllGridLinesValid(): boolean{
    if(
      this.selectedDocumentType === 'CTE' &&
      !this.getSelectedContainersFromForms()?.length
    ){
      return false;
    }
    return this.containerGrid?.map(
      (container) => container.isContainerRelationLineValid()
    ).every(line => line);
  }

  public addManualContainer(): void{
    if(this.doesSolicitationHaveIntegration){
      return;
    }
    this.containerRelationList.push({
      numeroConteiner: '',
      numeroConteinerTransload: '',
      parte: false,
      quantidadePartes: 0
    });
    this.changeDetector.detectChanges();
  }

  public updateContainerInfo(container: ConteinerCabotagem): void{
    if(!container){
      return;
    }
    if(container.selecionado){
      this.addSelectedContainerNumber(container.numeroConteiner);
    }
    else{
      this.removeSelectedContainerNumber(container.numeroConteiner);
    }
    this.deleteNonexistentContainerNumbers();
    this.changeDetector.detectChanges();
    if(!this.getSelectedContainersFromForms()?.length){
      this.selectedContainerNumbers = [];
    }
    this.containerGrid.forEach(
      (container) => {
        container.switchEdition();
      }
    );
    this.onUpdateSelectedContainers.emit();
    this.changeDetector.detectChanges();
    if(this.doesSolicitationHaveIntegration){
      // this.visualizar = true;
    }
  }

  private deleteNonexistentContainerNumbers(): void{
    let existentcontainerNumbers: string[] = this.getContainersListFromForm()
      ?.map((container) => container.numeroConteiner);
    for(let containerIndex: number = 0; containerIndex < this.selectedContainerNumbers?.length ?? 0; containerIndex++){
      if(!existentcontainerNumbers.includes(this.selectedContainerNumbers[containerIndex])){
        this.selectedContainerNumbers.splice(containerIndex, 1);
      }
    }
  }

  public clear(): void{
    this.form.reset();
    this.eraseConteinerRelation();
  }

  public eraseConteinerRelation(): void{
    this.containerRelationList = [];
  }

  public eraseConteinerFromGrid(conteiner: ConteinerCabotagem): void{
    let index = this.containerGrid.toArray().findIndex(conteinerGrid => conteinerGrid.numeroConteiner.value == conteiner.numeroConteiner)
    if(index >= 0 ){
      this.containerRelationList.splice(index, 1);
          if(!this.containerRelationList?.length){
            this.areAllItemsSelected = false;
          }
          this.changeDetector.detectChanges();
          this.onUpdateSelectedContainers.emit();
    }
    }

    public sendConteinerToErase(index: number): void{
      if(this.doesSolicitationHaveIntegration){
        return;
      }
      this.onEraseConteiner.emit(this.containerGrid.get(index)?.getContainerRelationFromFormFields());
    }

    public sendContainerToSearchShipAndTrip(containerNumber: string, index: number): void{
      if(index !== 0){
        return;
      }
      this.onSearchShipAndTripByContainerNumber.emit(containerNumber);
    }
}
