<ng-template #modalOK>
  <div class="modal-header">
    <h4>{{modalTitle}}</h4>
    <button class="btn-close close pull right"><span class="visualli-hidden"
      (click)="closeModal()">&times;</span></button>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
    <div class="action-button-group ">
      <button class="action-primary-button" (click)="okAction()">OK</button>
    </div>
  </div>
</ng-template>
