<td *ngIf="selecionado" class="text-center">
    <input type="checkbox" [formControl]="selecionado" (change)="onManualSelection.emit()">
</td>
<td *ngIf="nomeNavio" class="readonlyTd">
    {{nomeNavio.value}}
</td>
<td *ngIf="numeroViagem" class="readonlyTd">
    {{numeroViagem.value}}
</td>
<td *ngIf="armador" class="readonlyTd">
    {{armador.value}}
</td>
<td *ngIf="numeroBooking" class="readonlyTd">
    {{numeroBooking.value}}
</td>
<td *ngIf="modalidade && selecionado">
    <select class="custom-select" [formControl]="modalidade" [ngClass]="{'border border-danger': selecionado.value === true && modalidade.value ==='' }">

    <option [ngValue]="''" disabled selected>Selecione</option>
    <option *ngFor="let tipoModalidade of modalidades" [ngValue]="tipoModalidade">{{tipoModalidade}}</option>
  </select>
</td>
<td *ngIf="dataHoraCadastro" class="readonlyTd">
    {{dataHoraCadastro.value | date : 'dd/MM/yyyy HH:mm' : getTimezoneFromDate(dataHoraCadastro.value ?? '')}}
</td>
<td *ngIf="usuario" class="readonlyTd">
    {{usuario.value}}
</td>
<td *ngIf="selecionado">
    <button class="erase-line-button" *ngIf="!!selecionado.value" (click)="eraseLine()">
    <img src="assets/images/red-trashcan.svg" alt="">
  </button>
</td>
