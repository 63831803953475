import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { InformacoesCargaRNF } from 'src/app/models/RecepcaoDueContingencia/RecepcaoNotaFiscal/InformacoesCargaRNF';

@Component({
  selector: 'app-informacoes-cargas',
  templateUrl: './informacoes-cargas.component.html',
  styleUrls: ['./informacoes-cargas.component.scss']
})
export class InformacoesCargasComponent implements OnInit {
  public form!: UntypedFormGroup;
  public isCollapsed = false;
  public maskForClientDocument: string = '0*';

  @Output() onSendCargoInfoData: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnInit() {
    this.validation();
  }


  private validation(): void{
    this.form = this.fb.group({
      motivoNaoPesagem: [''],
      localArmazenamento: [''],
      avariasIdentificadas: [''],
      divergenciasIdentificadas: [''],
      observacoesGerais: [''],
      identificador: ['']

    });
    this.form.valueChanges.subscribe(
      () => {
        this.onSendCargoInfoData.emit({informacoesCarga: this.form.getRawValue()});
      }
    );
  }

  public clear(): void{
    this.form.reset();
  }

  public getCargoInfoFromForm(): InformacoesCargaRNF{
    return this.form.getRawValue() as InformacoesCargaRNF;
  }

  public setFormValueFromReceivedCargoInfo(cargoInfo: InformacoesCargaRNF): void{
    console.log('dsadsadsads')
    this.form.get("identificador")?.setValue(cargoInfo.identificador)
    this.form.get("avariasIdentificadas")?.setValue(cargoInfo.avariasIdentificadas)
    this.form.get("divergenciasIdentificadas")?.setValue(cargoInfo.divergenciasIdentificadas)
    this.form.get("localArmazenamento")?.setValue(cargoInfo.localArmazenamento)
    this.form.get("motivoNaoPesagem")?.setValue(cargoInfo.motivoNaoPesagem)
    this.form.get("observacoesGerais")?.setValue(cargoInfo.observacoesGerais)
  }
}
