import { ModalMensagemComponent } from './../../../modais/modal-mensagem/modal-mensagem.component';
import { Component, OnInit, AfterViewInit, Input, EventEmitter, Output, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast.service';
import { GridSolicitacaoFaturamento } from 'src/app/models/EstimativaCalculo/SolicitacaoFaturamento/GridSolicitacaoFaturamento';
import { DefaultMaskPattern } from 'src/app/shared/utils/constantes-documentos';

@Component({
  selector: 'tr[app-linha-grid-solicitacoes-usuario-externo]',
  templateUrl: './linha-grid-solicitacoes-usuario-externo.component.html',
  styleUrls: ['./linha-grid-solicitacoes-usuario-externo.component.scss']
})
export class LinhaGridSolicitacoesUsuarioExternoComponent implements AfterViewInit {

  @Input() solicitation!: GridSolicitacaoFaturamento;
  @Input() mask!: string;
  @Output() public onExpandSolicitation: EventEmitter<GridSolicitacaoFaturamento> = new EventEmitter<GridSolicitacaoFaturamento>();
  @Output() public onShowAttachedDocuments: EventEmitter<GridSolicitacaoFaturamento> = new EventEmitter<GridSolicitacaoFaturamento>();
  @ViewChild('modalMensagem') private modalMensagem!: ModalMensagemComponent;
  @ViewChild('modalJustificativaRecusa') private modalJustificativaRecusa!: ModalMensagemComponent;
  public maskPattern: any = DefaultMaskPattern.DEFAULT_MASK_CHARS;

  constructor(
    private spinner: NgxSpinnerService,
    private toastService:ToastService,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngAfterViewInit(): void {

  }

  public expandSolicitation(): void{
    this.onExpandSolicitation.emit(this.solicitation);
  }

  public showRPSNumber(): void{
    if(!this.isVisualizarNumeroRPSEnabled){
      return;
    }
    this.modalMensagem.displayMessage(`RPS número ${this.solicitation.numeroRPS}`, 'Nº RPS');
  }

  public showAttachedDocuments(): void{
    this.onShowAttachedDocuments.emit(this.solicitation);
  }

  public showAttachedDocumentsOnFinished(): void{
    this.onShowAttachedDocuments.emit(this.solicitation);
  }

  public shortenTextForJustificationDisplay(): string{
    return (this.solicitation?.justificativaRecusa ?? '')?.substring(0, 25) + '...';
  }

  public openModalForJustificationDisplay(): void{
    this.modalJustificativaRecusa?.displayMessage(this.solicitation?.justificativaRecusa ?? '', 'Justificativa');
  }

  public get isAnexarComprovanteEnabled(): boolean{
    return this.solicitation.tipoSolicitacao === 'Memória de Cálculo' &&
      this.solicitation.status === 'FINALIZADO';
  }

  public get isVisualizarNumeroRPSEnabled(): boolean{
    return !!this.solicitation.numeroRPS?.length &&
      this.solicitation.tipoSolicitacao === 'Confirmar Pagamento' &&
      this.solicitation.status === 'FINALIZADO';
  }
}
