import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReprovacaoAprovacaoLiberacaoComponent } from 'src/app/components/relatorios/relatorio-reprovacao-aprovacao-liberacao/reprovacao-aprovacao-liberacao/reprovacao-aprovacao-liberacao.component';
import { Permissoes } from 'src/app/contants/permissoes';
import { Telas } from 'src/app/contants/telas';
import { AuthRoles } from 'src/app/models/authRoles';

@Component({
  selector: 'app-reprovacao-aprovacao-liberacao-por-periodo',
  templateUrl: './reprovacao-aprovacao-liberacao-por-periodo.component.html',
  styleUrls: ['./reprovacao-aprovacao-liberacao-por-periodo.component.scss']
})
export class ReprovacaoAprovacaoLiberacaoPorPeriodoComponent implements OnInit {

  public title: string = 'Reprovações e Aprovações de Liberações';
  public subtitle: string = '';

  @ViewChild(ReprovacaoAprovacaoLiberacaoComponent) private reprovacaoAprovacaoLiberacaoComponent!: ReprovacaoAprovacaoLiberacaoComponent;
  public readonly visualizacao = new AuthRoles(Telas.relatorioReprovacaoAprovacaoLiberacao, Permissoes.visualizacao)

  constructor(
    private spinner: NgxSpinnerService,
  ) { }

  public ngOnInit(): void {
  }

  async imprimirPdf() {
    await this.reprovacaoAprovacaoLiberacaoComponent.downloadPDF(this.title);
  }


  async salvarExcel() {
    await this.reprovacaoAprovacaoLiberacaoComponent.downloadExcel(this.title);
  }

  public isLiberationListNotEmpty(): boolean{
    return !!this.reprovacaoAprovacaoLiberacaoComponent?.isLiberationListNotEmpty();
  }

  public clean() {
      this.reprovacaoAprovacaoLiberacaoComponent?.clearFields();
  }
}
