import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  private isPrinting$ = new BehaviorSubject<TemplateRef<any> | null>(null);
  public isPrintingActive$ = this.isPrinting$.asObservable();

  constructor() { }

  public setPrinting(template: TemplateRef<any>): void{
    this.isPrinting$.next(template);
  }

  public unsetPrinting(): void{
    this.isPrinting$.next(null);
  }
}
