import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MaskPipe } from 'ngx-mask';
import { DocumentoParteLiberacaoDocumental } from 'src/app/models/LiberacaoDocumental/DocumentoParteLiberacaoDocumental';
import { PesquisaExpansaoDocumentoParte } from 'src/app/models/LiberacaoDocumental/PesquisaExpansaoDocumentoParte';
import { Mascara } from 'src/app/models/Mascara';
import { DefaultMaskPattern } from 'src/app/shared/utils/constantes-documentos';

@Component({
  selector: 'tr[app-linha-parte-liberacao-documental]',
  templateUrl: './linha-parte-liberacao-documental.component.html',
  styleUrls: ['./linha-parte-liberacao-documental.component.scss']
})
export class LinhaParteLiberacaoDocumentalComponent implements AfterViewInit {

  protected numeroConteiner!: UntypedFormControl;
  protected numeroDocumento!: UntypedFormControl;
  protected numeroCE!: UntypedFormControl;
  protected quantidadePartes!: UntypedFormControl;
  protected status!: UntypedFormControl;
  protected form!: UntypedFormGroup;

  @Input() documentoParte!: DocumentoParteLiberacaoDocumental;
  @Input() isExpDocument!: boolean;
  @Input() mascara!: Mascara;
  @Input() specialCharsForDocumentNumber!: boolean;
  @Output() onExpandPartDocument: EventEmitter<PesquisaExpansaoDocumentoParte> = new EventEmitter<PesquisaExpansaoDocumentoParte>();

  constructor(
    private fb: UntypedFormBuilder,
    private maskPipe: MaskPipe,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngAfterViewInit(): void {
    this.validation();
  }

  private validation(): void{
    this.numeroConteiner = new UntypedFormControl(this.documentoParte?.numeroConteiner, [Validators.required]);
    this.numeroDocumento = new UntypedFormControl(
      (
        !this.mascara?.modelo?.length ?
        this.documentoParte?.numeroDocumento :
        this.maskPipe.transform(this.documentoParte?.numeroDocumento, [this.mascara?.modelo, DefaultMaskPattern.DEFAULT_MASK_CHARS])
      ),
      [Validators.required]
    );
    this.numeroCE = new UntypedFormControl(this.documentoParte?.numeroCE, [Validators.required]);
    this.quantidadePartes = new UntypedFormControl(this.documentoParte?.quantidadePartes, [Validators.required]);
    this.status = new UntypedFormControl(this.documentoParte?.status, [Validators.required]);
    this.form = this.fb.group({
      numeroConteiner: this.numeroConteiner,
      numeroDocumento: this.numeroDocumento,
      numeroCE: this.numeroCE,
      quantidadePartes: this.quantidadePartes,
      status: this.status
    });
    this.changeDetector.detectChanges();
  }

  public getPartDocumentFromFields(): DocumentoParteLiberacaoDocumental{
    return this.form?.getRawValue();
  }

  public get isPartDocumentValid(): boolean{
    return !this.form.invalid;
  }

  protected expandPartDocument(): void{
    const numeroConteiner = this.numeroConteiner?.value;
    const numeroDocumento = this.numeroDocumento?.value;
    const status = this.status?.value;
    if(!numeroConteiner?.length || !numeroDocumento?.length || !status?.length){
      return;
    }
    this.onExpandPartDocument.emit({numeroDocumento, numeroConteiner, status: status});
  }

}
