<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Relação de Bookings:</span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-3">
                    <div class="col-6">
                        <label for="">Navio: *</label>
                        <input type="text" class="form-control" placeholder="Digite aqui..." formControlName="navio" [ngClass]="{'border border-danger': form.get('navio')?.errors && !form.get('navio')?.pristine}">
                        <div *ngIf="form.get('navio')?.errors && !form.get('navio')?.pristine">
                            <p class="text-danger" *ngIf="form.get('navio')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <label>Viagem: *</label>
                        <div class="input-group">
                            <input formControlName="viagem" type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2" [ngClass]="{'border border-danger': form.get('viagem')?.errors && !form.get('viagem')?.pristine}">
                            <button class="action-primary-button" [disabled]="areSearchFieldsEmpty" (click)="searchContainerRelations()">PESQUISAR</button>
                        </div>
                        <div *ngIf="form.get('viagem')?.errors && !form.get('viagem')?.pristine">
                            <p class="text-danger" *ngIf="form.get('viagem')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                </div>
                <div class="overflow-auto my-3">
                    <table class="grid-table text-nowrap">
                        <thead>
                            <tr>
                                <th>Contêiner</th>
                                <th>Peso Bruto</th>
                                <th>Tara</th>
                                <th>Lacres</th>
                                <th>CNPJ Responsável</th>
                                <th>Nome Armador</th>
                                <th>Nº da DUE</th>
                                <th>Retorno da API CCT</th>
                                <th>Data do Envio</th>
                                <th>Usuário</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let relacaoConteiner of relacaoConteinerRCTDisplay; let indexRelacao = index" class="readonly-grid-line">
                                <td>
                                    {{relacaoConteiner.conteiner}}
                                </td>
                                <td>
                                    {{relacaoConteiner.pesoBruto}}
                                </td>
                                <td>
                                    {{relacaoConteiner.tara}}
                                </td>
                                <td>
                                    {{relacaoConteiner.lacres}}
                                </td>
                                <td>
                                    {{relacaoConteiner.cnpjResponsavel ?? '' | mask: '00.000.000/0000-00'}}
                                </td>
                                <td>
                                    {{relacaoConteiner.nomeArmador}}
                                </td>
                                <td>
                                    {{relacaoConteiner.numeroDue ?? '' | mask: '0*'}}
                                </td>
                                <td>
                                    {{relacaoConteiner.retornoAPICCT}}
                                </td>
                                <td>
                                    {{relacaoConteiner.dataEnvio ?? ''| date: 'dd/MM/yyyy' : 'UTC+0'}}
                                </td>
                                <td>
                                    {{relacaoConteiner.usuario}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row mb-3">
                    <div class="col-6">
                        <label for="">Quantidade Contêiner(es):</label>
                        <input type="text" class="form-control" placeholder="Digite aqui..." formControlName="qtdConteineres" [attr.disabled]="'disabled'" [mask]="'0*'">
                    </div>
                    <div class="col-6">
                        <label for="">Quantidade Contêiner(es) Enviado(s):</label>
                        <input type="text" class="form-control" placeholder="Digite aqui..." formControlName="qtdConteineresEnviados" [attr.disabled]="'disabled'" [mask]="'0*'">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-6">
                        <label for="">Quantidade de Erros Encontrados:</label>
                        <input type="text" class="form-control" placeholder="Digite aqui..." formControlName="qtdConteineresErrosEnviados" [attr.disabled]="'disabled'" [mask]="'0*'">
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="d-flex justify-content-center align-content-center mt-5">
    <div class="solicitation-action-buttons action-double-buttons">
        <button class="action-secondary-button" (click)="clear()">LIMPAR CAMPOS</button>
        <!-- <button class="action-primary-button" [disabled]="blockSending || form.invalid" (click)="save()">SALVAR</button> -->
        <button (click)="sendDataToCCT()" class="action-primary-button" [disabled]="blockSending || form.invalid">ENVIAR AO CCT</button>

    </div>
</div>
