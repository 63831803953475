import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-mensagem',
  templateUrl: './modal-mensagem.component.html',
  styleUrls: ['./modal-mensagem.component.scss']
})
export class ModalMensagemComponent implements OnInit {

  @ViewChild('modalMensagem', {static: true}) public modalMensagem!: TemplateRef<any>;
  public title!: string;
  public message!: string;

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService
  ) { }

  public ngOnInit(): void {
  }

  public displayMessage(message: string, title?: string): void{
    this.message = message;
    this.title = title ?? '';
    this.bsModalRef = this.bsModalService.show(this.modalMensagem, Object.assign({}, {
      class: 'modal-md'
    }));
  }

  public displaySingleMessage(message: string, title?: string): void{
    this.message = message;
    this.title = title ?? '';
    if(this.bsModalService?.getModalsCount() ?? 0 < 1){
      this.bsModalRef = this.bsModalService.show(this.modalMensagem, Object.assign({}, {
        class: 'modal-md'
      }));
    }
  }

  public closeModal(): void{
    this.bsModalRef.hide();
  }

}
