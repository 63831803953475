<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Árvore de Partes <span class="text-danger"
          *ngIf="arvorePartesList?.length && !areAllDocumentoParteLinesValid">*</span></span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}"
        data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false"
        aria-label="Toggle navigation"></i>
    </div>
  </div>
  <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <div class="table-container">
      <table class="grid-table">
        <thead>
          <tr>
            <th>Nº do Contêiner</th>
            <th>{{isImpDocument ? 'Nº do CE' : (isExpDocument ? 'Nº do Documento' : '')}}</th>
            <th>{{isImpDocument ? 'Nº do Documento' : (isExpDocument ? 'Quantidade de Partes' : '')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let arvorePartes of arvorePartesList; let indexArvorePartes = index"
            app-linha-grid-arvore-partes-estimativa-calculo [documentoParte]="arvorePartes" [mascara]="mascara"
            (onSendArvoreParte)="updateArvoreParte($event, indexArvorePartes)" [camposFormulario]="camposFormulario"
            (onFirstEdit)="markEveryLineAstouched()" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
            [visualizar]="arvoreParteReadonly"
            [isImpDocument]="isImpDocument" [isExpDocument]="isExpDocument"
            [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType">
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
