import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { EntregaRelacaoConteinerComponent } from 'src/app/components/entrega-relacao-conteiner/entrega-relacao-conteiner.component';

@Component({
  selector: 'app-entrega-conteiner',
  templateUrl: './entrega-conteiner.component.html',
  styleUrls: ['./entrega-conteiner.component.scss']
})
export class EntregaConteinerComponent implements OnInit {

  @ViewChild(EntregaRelacaoConteinerComponent) relacaoConteiner!: EntregaRelacaoConteinerComponent;

  public title: string = 'Entrega de DUE por Contêiner';
  public form!: UntypedFormGroup;

  constructor(
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder
  ) { }

  public ngOnInit(): void {
    this.validation();
  }

  private validation(): void{
    this.form = this.fb.group({
      relacaoConteiner: [null]
    });
  }

  public clear(): void{
    this.spinner.show('limpar');
    setTimeout(() => {
      this.spinner.hide('limpar');
    }, 2000);
    this.relacaoConteiner.clear();
    this.form.reset();
  }

}
