<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Relação de Bookings:</span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-3">
                    <div class="col">
                        <label>Navio:</label>
                        <input formControlName="navio" type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2">
                    </div>
                    <div class="col">
                        <label>Viagem:</label>
                        <div class="input-group">
                            <input formControlName="viagem" type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2">
                            <button class="action-primary-button" [disabled]="areSearchFieldsEmpty" (click)="searchBookingRelations()">PESQUISAR</button>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label>Booking:</label>
                        <input formControlName="booking" type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2">
                    </div>
                    <div class="col d-flex align-items-end">
                        <p class="required-fields-paragraph">Preencher ao menos um campo para pesquisar</p>
                    </div>
                </div>
                <div class="my-3 overflow-auto">
                    <table class="grid-table">
                        <thead class="text-nowrap">
                            <tr>
                                <th><input class="form-chek-input" type="checkbox" value="" id="incluir" [checked]="areAllItemsSelected" (click)="switchSelectAll()"></th>
                                <th>Navio</th>
                                <th>Viagem</th>
                                <th>Armador</th>
                                <th>Booking</th>
                                <th>Modalidade</th>
                                <th>Data/Hora Cadastro</th>
                                <th>Usuário</th>
                                <th>...</th>
                            </tr>
                        </thead>
                        <tbody class="text-nowrap">
                            <tr app-linha-grid-relacao-bookings-cabotagem-embarque *ngFor="let bookingRelation of bookingRelationDisplay; let brIndex = index" [relacaoBooking]="bookingRelation" (onManualSelection)="areAllItemsSelected = false" (onEraseLine)="eraseBookingFromGrid(brIndex)"></tr>
                        </tbody>
                    </table>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label>Quantidade de Registros:</label>
                        <input type="text" class="form-control" aria-label="" aria-describedby="basic-addon2" readonly [value]="bookingRelationDisplay.length">
                    </div>
                    <div class="col"></div>
                </div>
            </div>
        </form>
    </div>
