<!-- <div class="conteiner">
    <div clss="child">
        <h1>Bem vindo(a), {{fullName}}</h1>
        <img src="../../../assets/images/portoSepetiba.jpg">
    </div>
</div> -->

<div class="container">
    <div class="child">
        <h1>Bem vindo(a), {{fullName | uppercase}}</h1>

        <img src="../../../assets/images/portoSepetiba.jpg">
    </div>
  </div>