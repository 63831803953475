<ng-template #templateImpressao>
  <div class="display-on-print">
    <table *ngFor="let relatorio of relatorios" class="relatorio">
      <thead>
        <tr class="header borderBottom">
          <td colspan="6">
            <img class="logo" src="{{this.logo}}" alt="logo" />
          </td>
          <td colspan="4" >
            <span class="header-nomeRelatorio"> {{nomeRelatorio}} </span>
          </td>
          <td colspan="12">
            <span class="header-now-date">
              {{currentDate.toLocaleString('pt-BR')}}
            </span>
          </td>
        </tr>
        <tr class="header borderBottom" *ngIf="dataInicio && dataFim">
          <td colspan="24">
            <span>
              Período De {{dataInicio ? (dataInicio.toLocaleDateString('pt-BR')) : ""}} Até {{dataFim ? (dataFim.toLocaleDateString('pt-BR')) : ''}}
            </span>
          </td>
        </tr>
      </thead>
      <tbody *ngTemplateOutlet="relatorio"></tbody>
      <tfoot>
        <tr class="foot-cont"></tr>
      </tfoot>
    </table>
  </div>
</ng-template>

<ng-template #tableRelatorio #tableRelatorio1>
  <thead>
    <tr>
      <th class="header-5-porcento" >Dias Armaz</th>
      <th class="header-5-porcento" >Contêiner</th>
      <th class="header-5-porcento" >Navio</th>
      <th class="header-5-porcento" >Viagem</th>
      <th class="header-5-porcento" >Data Entrada</th>
      <th class="header-5-porcento" >Data Saída</th>
      <th class="header-5-porcento" >Canal</th>
      <th class="header-5-porcento" >Nº do CE</th>
      <th class="header-5-porcento" >Mercadoria</th>
      <th class="header-5-porcento" >BL Master</th>
      <th class="header-5-porcento" >BL House</th>
    </tr>
  </thead>
  <tr *ngFor="let relatorio of dados">
    <td class="header-5-porcento">{{relatorio?.diasArmazenados ? relatorio.diasArmazenados : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.conteiner ? relatorio.conteiner : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.navio ? relatorio.navio : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.viagem ? relatorio.viagem : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.dataEntrada ?  (relatorio.dataEntrada | date : 'dd/MM/YYYY HH:mm:ss' : getTimezone(relatorio.dataEntrada?.toString())) : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.dataSaida ? (relatorio.dataSaida | date : 'dd/MM/YYYY HH:mm:ss' : getTimezone(relatorio.dataSaida?.toString())) : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.canal ? relatorio.canal : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.numCE ? relatorio.numCE : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.mercadoria ? relatorio.mercadoria : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.blMaster ? relatorio.blMaster : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.blHouse ? relatorio.blHouse : ''}}</td>
  </tr>
</ng-template>

<ng-template #tableRelatorio #tableRelatorio2>
  <thead>
    <tr>
      <th class="header-5-porcento">Consignatário</th>
      <th class="header-5-porcento">Data Cadastro</th>
      <th class="header-5-porcento">Data Registro</th>
      <th class="header-5-porcento">Data Liberação</th>
      <th class="header-5-porcento">Tipo Doc</th>
      <th class="header-5-porcento">Nº Doc</th>
      <th class="header-5-porcento">Matrícula</th>
      <th class="header-5-porcento">Justificativa</th>
    </tr>
  </thead>
  <tr *ngFor="let relatorio of dados">
    <td class="header-5-porcento">{{relatorio?.consignatario ? relatorio.consignatario : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.dataCadastro ? (relatorio.dataCadastro | date : 'dd/MM/YYYY HH:mm:ss' : getTimezone(relatorio.dataCadastro?.toString())) : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.dataRegistro ? (relatorio.dataRegistro | date :'dd/MM/YYYY HH:mm:ss' : getTimezone(relatorio.dataRegistro?.toString())) : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.dataLiberacao ? (relatorio.dataLiberacao | date : 'dd/MM/YYYY HH:mm:ss' : getTimezone(relatorio.dataLiberacao?.toString())) : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.tipoDoc ? relatorio.tipoDoc : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.numDoc ? relatorio.numDoc : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.matriculaUsuario ? relatorio.matriculaUsuario : ''}}</td>
    <td class="header-5-porcento">{{relatorio?.justificativa ? relatorio.justificativa : ''}}</td>
  </tr>
</ng-template>
