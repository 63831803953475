import { RelacaoConteinerCCTDocumentoTransporte, RelacaoConteinerN4DocumentoTransporte } from '../../documento-transporte/LacreConteinerRecepcaoDocumentoTransporte';
export class DadosEntregaDocumentoTransporte {
  dadosEntregaDocumentoTransporteCCT: DadosEntregaDocumentoTransporteCCTDTO = new DadosEntregaDocumentoTransporteCCTDTO();
  dadosEntregaDocumentoTransporteN4: DadosEntregaDocumentoTransporteN4DTO = new DadosEntregaDocumentoTransporteN4DTO();
  tipoDocumento?: string;
  numeroDocumento?: string;
  identificador?: string;
}

export class DadosEntregaDocumentoTransporteCCTDTO {
  dataEmissao?: Date;
  situacao?: string;
  cnpjCpfTransportador?: string;
  cpfCondutor?: string;
  nomeCondutor?: string;
  localDestinoPrevisto?: string
  placaReboque1?: string;
  placaReboque2?: string;
  placaReboque3?: string;
  placaCavalo?: string;
  relacaoConteinerLacresCCT?: RelacaoConteinerCCTDocumentoTransporte[] = [];
  dadosVeiculoConferem?: string;
  dadosConteinerConferem?: string;
  dadosEmbalagemConferem?: string;
  dadosUnidadeTransporteConferem?: string;
  pesoAferido?: string;
  codigoURFDestino?:string;
  cnpjRespDestino?:string;
  codigoRADestino?:string;
}

export class DadosEntregaDocumentoTransporteN4DTO {
  cpfCondutor?: string;
  nomeCondutor?: string;
  cpfCnpjTransportador?: string;
  placaReboque1?: string;
  placaReboque2?: string;
  placaReboque3?: string;
  placaCavalo?: string;
  relacaoConteinerLacresN4?: RelacaoConteinerN4DocumentoTransporte[] = [];
  lacresN4?: RelacaoConteinerCCTDocumentoTransporte[] = [];
  pesagem?: boolean;
  recepcaoRealizadaComSucesso?: boolean;
  statusEntrega?: string;
  dataEnvio?: Date;
  usuario?: string;
  statusRecepcao?: string;
  entregaRealizadaComSucesso?: boolean;
  numeroConteiner?: string;
}
