import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiltrosPesquisaRelatorioReprovacaoAprovacaoLiberacao } from '../../models/relatorios/RelatorioReprovacaoAprovacaoLiberacao/FiltrosPesquisaRelatorioReprovacaoAprovacaoLiberacao';
import { RelatorioReprovacaoAprovacaoLiberacao } from '../../models/relatorios/RelatorioReprovacaoAprovacaoLiberacao/RelatorioReprovacaoAprovacaoLiberacao';
import { StatusSolicitacaoDocumental } from '../../models/StatusSolicitacaoDocumental';
import { SuccessResponse } from '../../models/HttpResponses/SuccessResponse';
import { MaskPipe } from 'ngx-mask';

@Injectable({
  providedIn: 'root'
})
export class RelatorioReprovacaoAprovacaoLiberacaoService {
  private logo = '../../assets/images/logoSeptibaTecon.png';

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private maskPipe: MaskPipe
  ) { }

  public searchReprovacaoAprovacaoLiberacao(solicitation: FiltrosPesquisaRelatorioReprovacaoAprovacaoLiberacao): Observable<SuccessResponse> {
    return this.http.get<SuccessResponse>(`${environment.apiUrlForRelatorios}/liberacao/ReprovacoesAprovacoes?${this.setQueryStringForSearchReprovacaoAprovacaoLiberacaoSolicitation(solicitation)}`);
  }

  private setQueryStringForSearchReprovacaoAprovacaoLiberacaoSolicitation(solicitation: FiltrosPesquisaRelatorioReprovacaoAprovacaoLiberacao): string{
    let query: string[] = [];

    if(solicitation.periodoDe){
      query.push(`PeriodoDe=${encodeURIComponent(new Date(solicitation.periodoDe).toLocaleDateString('PT-BR'))}`);
    }
    if(solicitation.periodoAte){
      query.push(`PeriodoAte=${encodeURIComponent(new Date(solicitation.periodoAte).toLocaleDateString('PT-BR'))}`);
    }
    if(solicitation.status){
      query.push(`Status=${encodeURIComponent(solicitation.status)}`);
    }
    return query.join('&');
  }

  public getStatus(): Observable<StatusSolicitacaoDocumental[]>{
    return this.http.get<StatusSolicitacaoDocumental[]>(`${environment.apiUrlForRelatorios}/Liberacao/Status`);
  }
}

