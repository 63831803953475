<section [ngClass]="{'hide-on-print': isPrinting}">
  <header>
    <app-nav *ngIf="isLogged && !isRedirectDocumental"></app-nav>
  </header>

  <main id="main-container" class="container">
    <app-toast aria-live="polite" aria-atomic="true"></app-toast>
    <!-- <app-ParametrizacaodeInspecao></app-ParametrizacaodeInspecao> -->
    <!-- <app-formulario-relatorio-inspecoes></app-formulario-relatorio-inspecoes> -->
    <router-outlet></router-outlet>
  </main>

  <footer *ngIf="isLogged && !isRedirectDocumental" class="footer">
    <!-- <div class="container-footer"> -->
      <span>© {{ this.currentYearLong() }} CSN | Todos os direitos reservados.</span>
      <p>Versão {{this.versao}}</p>
    <!-- </div> -->
  </footer>
</section>

<ng-container [ngTemplateOutlet]="componentToPrint"></ng-container>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="small"
  color="#FFF"
  type="square-jelly-box"
  [fullScreen]="true"
  template="<img src='assets/images/csnLogo.svg'/>"
>
</ngx-spinner>
