<div [auth]="this.visualizacao">

    <div class="mt-5">
        <app-title [title]='title' [subtitle]='subtitle'></app-title>
        <app-filtro-relatorio-entrega-da-carga-navio (onSendFilter)="searchFilteredEntregaCargasNavios($event)">
        </app-filtro-relatorio-entrega-da-carga-navio>
        <app-grid-relatorio-entrega-da-carga-navio [entregaCargaNavio]="receivedRelatorioEntregaCargaNavio">
        </app-grid-relatorio-entrega-da-carga-navio>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-5 w-100">
        <div class="row w-100">
            <div class="col-5 action-button-group action-double-buttons">
                <button (click)="downloadExcel()" class="action-tertiary-button" [disabled]="!receivedRelatorioEntregaCargaNavio.length">
          <img src="assets/images/SaveDoc.png" alt="">
          <span class="ml-2">SALVAR PLANILHA</span>
        </button>
                <button (click)="downloadPDF()" class="action-tertiary-button" [disabled]="!receivedRelatorioEntregaCargaNavio.length">
          <img src="assets/images/SaveRasc.png" alt="">
          <span class="ml-2">IMPRIMIR</span>
        </button>
            </div>
            <div class="col-4"></div>
            <div class="solicitation-action-buttons action-button-group col-3">
                <button class="action-tertiary-button" (click)="clean()">LIMPAR CAMPOS</button>
            </div>
        </div>
        <!-- <div *ngIf="divValid" class="solicitation-action-buttons">
        <button class="btnCancelar" (click)="clearFormFields()">LIMPAR CAMPOS</button>
        <button *ngIf="isSolicitationReceived" class="btnCancelar" (click)="cancelSolicitation()">CANCELAR</button>
        <button [class]="checkIfAllFormsAreValid() ? 'btnConcluir' : 'btnConcluirDisabled'" [attr.disabled]="checkIfAllFormsAreValid() ? null : 'disabled'" (click)="save()">CONCLUIR</button>
      </div> -->
    </div>

    <ng-template #template>
        <div class="modal-header">
            <button class="btn-close close pull right"><span class="visualli-hidden"
          style="font-size: 18px;">&times;</span></button>
        </div>
        <div class="modal-body text-center">
            <span> Rascunho salvo com sucesso! </span>
        </div>
    </ng-template>

</div>
<app-impressao-relatorio-entrega-da-carga-navio *ngIf="isPrinting"></app-impressao-relatorio-entrega-da-carga-navio>
