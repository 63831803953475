<ng-template #modal>
  <div class="modal-content" *ngIf="retornoBloqueios?.length">
    <div class="modal-header">
      <span class="modal-title" id="exampleModalLabel">Consulta de bloqueio</span>
      <button type="button" class="btn close btn-close" data-bs-dismiss="modal" aria-label="close" (click)="onClose()">&times;</button>
    </div>
    <div class="modal-body">
      <form>
        <table>
          <tr>
            <th>Consulta</th>
            <th>Status</th>
            <th>Descrição</th>
          </tr>
          <tr *ngFor="let retornoBloqueio of retornoBloqueios">
            <td>
              <div class="input-group">
                <label class="form-control" aria-describedby="basic-addon2" *ngIf="retornoBloqueio.tipoConsulta">
                    {{retornoBloqueio.tipoConsulta}}
                </label>
              </div>
            </td>
            <td>
              <div class="input-group d-flex align-items-center justify-content-center">
                <label class="d-flex align-items-center justify-content-center" aria-describedby="basic-addon2" *ngIf="retornoBloqueio.status; else loadingData">
                  <svg width="36" height="36" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="6" *ngIf="retornoBloqueio.status == 1" fill="#2DCC70"/>
                    <circle cx="6" cy="6" r="6" *ngIf="retornoBloqueio.status == 2" fill="#F53636"/>
                    <circle cx="6" cy="6" r="6" *ngIf="retornoBloqueio.status == 3" fill="#EBC501"/>
                  </svg>
                </label>
              </div>
            </td>
            <td>
              <div class="input-group">
                <label class="form-control" aria-describedby="basic-addon2" *ngIf="retornoBloqueio.descricao; else loadingData">
                    {{retornoBloqueio.descricao}}
                </label>
              </div>
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #loadingData>
  <div class="w-100 d-flex align-items-center justify-content-center p-1">
    <img src="assets/images/loading-circles.svg" alt="">
  </div>
</ng-template>
