<div [auth]="this.visualizacao">

    <div class="mt-5">
        <app-title [title]='title' [subtitle]='subtitle'>
        </app-title>

        <app-documento
          (onSendDocument)="searchSolicitationByButton($event)"
          (onChangeDocumentTypeOnSelect)="changeDocumentType($event)"
          (onSendMask)="setMaskFromDocumentSelected($event)"
          [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
          [camposFormulario]="camposFormularios"
          [isInsideModal]="isRequesting"
        ></app-documento>
        <app-xml
          [hidden]="documentTypeSelected != 'DI'"
          (xmlToJson)="getXmlData($event)"
          [isBlocked]="isRequesting"
        ></app-xml>
        <div [hidden]="!isSearchDone">
            <app-cliente [camposFormulario]="camposFormularios" (onSearchClient)="searchClient($event)"
              [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
              (onEndEditClient)="searchNomesDespachantes()"
            ></app-cliente>
            <app-dados-complementares [camposFormulario]="camposFormularios" (dataFormOut)="{}" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
              [Canais]="canais" [Moedas]="moedas" [Modalidades]="modalidades" [ModalidadesDTA]="modalidadesDTA"
            ></app-dados-complementares>
            <app-relacao-conteiner-importacao
              [camposFormulario]="camposFormularios"
              [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
              [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
              (onUpdateSelectedContainers)="updateArvorePartes()"
              (onEraseConteiner)="eraseConteiner($event)"
              (onSearchShipAndTripByContainerNumber)="searchShipAndTripByContainerNumber($event)"
            ></app-relacao-conteiner-importacao>
            <app-arvore-de-partes
              [mascara]="maskForArvorePartes" [camposFormulario]="camposFormularios"
              (dataFormOut)="{}" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
              [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
            ></app-arvore-de-partes>
            <app-documentos-anexados (onDownloadFileToDisplay)="downloadFileToDisplay($event)" (onDownloadFileToSave)="downloadFileToSave($event)"></app-documentos-anexados>
        </div>


    </div>

    <div class="d-flex justify-content-between align-items-center mt-5 row">
        <div [hidden]="!isSearchDone" class="solicitation-action-buttons action-double-buttons col-5">
            <button [auth]="salvarDocParte"
            *ngIf="!shouldBlockPartQuantityEditionByDocumentType"
              class="action-tertiary-button d-flex align-items-center justify-content-center"
              [disabled]="!isSalvarDocumentoParteEnabled"
              (click)="savePartDocument()"
              [hidden]="shouldBlockPartQuantityEditionByDocumentType"
            >
              <img src="assets/images/SaveDoc.png" alt="">
              <span class="ml-2">SALVAR DOCUMENTO PARTE</span>
        </button>
            <button [auth]="this.salvarRascunho" class="action-tertiary-button d-flex align-items-center justify-content-center" (click)="saveDraft()" [disabled]="!isSaveDraftButtonEnabled">
          <img src="assets/images/SaveRasc.png" alt="">
          <span class="ml-2">SALVAR RASCUNHO</span>
        </button>
        </div>
        <div class="col-1"></div>
        <div [hidden]="!isSearchDone" class="solicitation-action-buttons action-triple-buttons col-6">
            <button [auth]="this.cancelar" [hidden]="!isSolicitationReceived" class="action-secondary-button" (click)="cancelSolicitation()" [disabled]="isRequesting">CANCELAR</button>
            <button class="action-tertiary-button" (click)="clean()">LIMPAR CAMPOS</button>
            <button [auth]="this.concluir" class="action-primary-button" [disabled]="!isConcludeButtonEnabled" (click)="save()">CONCLUIR</button>
        </div>
    </div>

    <app-extrair-dados-siscomex (onCloseModal)="displayIntegrationErrors()"
      (onOpenModal)="isRequesting = false"
    ></app-extrair-dados-siscomex>
    <app-integracao-documento-parte
      (onOpenModal)="isRequesting = false"
    ></app-integracao-documento-parte>

</div>

<ng-template #modalAlertaErrosIntegracao>
    <div class="modal-content">
        <div class="modal-header">
            <span class="modal-title" id="exampleModalLabel"></span>
            <button type="button" class="btn close btn-close" data-bs-dismiss="modal" aria-label="close" (click)="closeModal()">&times;</button>
        </div>
        <div class="modal-body">
            <ul>
                <li *ngFor="let error of integrationErrors">{{error}}</li>
            </ul>
        </div>
    </div>
</ng-template>

<app-modal-ok #modalSolicitacaoPesquisadaExistente (onOkAction)="resetPage()">
  <p>{{textoSolicitacaoExistente}}</p>
</app-modal-ok>
