import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "./services/authentication/authentication.service";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root' // ADDED providedIn root here.
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if(this.authService.isAuthenticated()){
     if (!this.authService.isUsuarioRoot()) {
        if (!this.authService.verificarAcessoModulo()) {
          this.router.navigate(['/acesso-negado']);
          return false;
        }
      }
    }

    if (this.authService.isAuthenticated()) {
      if (this.authService.isUsuarioRoot()) {
        return true;
      }
      let data = route.data as any;
      console.log(data)
      if (data?.tela) {
        if (!this.verificaTela(data.tela)) {
          return false;
        }
      }

      return true;
    }
    
    this.authService.login();
    return false;
  }



  verificaTela(tela: any) {
    return this.authService.verificarAcessoTela(tela)
  }
}