<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Dados do Documento de Transporte no CCT</span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-3">
                    <div class="col-3">
                        <label>Data da Emissão:</label>
                        <input type="text" class="form-control" formControlName="dataEmissao" bsDatepicker #dpSolicitacao="bsDatepicker" aria-describedby="basic-addon2" placeholder="dd/mm/aaaa" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; ">
                    </div>
                    <div class="col">
                        <label for="">Situação:</label>
                        <input type="text" class="form-control" formControlName="situacao" placeholder="Digite aqui..." (change)="removeSpacesFromExtremities('situacao')" [required]="checkIfFieldIsRequired('situacao')" [readonly]="checkIfFieldIsReadonly('situacao')">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label>CPF/CNPJ Transportador:</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Digite aqui..." formControlName="cnpjCpfTransportador"
                            [mask]="'00.000.000/0000-00||000.000.000-00'" [required]="checkIfFieldIsRequired('cnpjCpfTransportador')"
                              [readonly]="checkIfFieldIsReadonly('cnpjCpfTransportador')"
                              [ngClass]="{'border border-danger': form.get('cnpjCpfTransportador')?.errors && form.get('cnpjCpfTransportador')?.touched }"
                            >
                        </div>
                    </div>
                    <div class="col">
                        <label>CPF Condutor: </label>
                        <input type="text" class="form-control" formControlName="cpfCondutor"
                          [ngClass]="{'border border-danger': form.get('cpfCondutor')?.errors && form.get('cpfCondutor')?.touched }"
                          mask="000.000.000-00" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
                          [required]="checkIfFieldIsRequired('cpfCondutor')" [readonly]="checkIfFieldIsReadonly('cpfCondutor')"
                        >
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-6">
                        <label for="">Local Destino Previsto:</label>
                        <input type="text" class="form-control" formControlName="localDestinoPrevisto" placeholder="Digite aqui..." [readonly]="checkIfFieldIsReadonly('localPrevisto')">
                    </div>
                    <div class="col-6">
                        <label>Tipo de Veículo: </label>
                        <select class="custom-select" formControlName="tipoVeiculo" [ngClass]="{'border border-danger': form.get('tipoVeiculo')?.invalid && !form.get('tipoVeiculo')?.pristine && form.get('tipoVeiculo')?.value == null} " id="tipoVeiculo" [attr.disabled]="checkIfFieldIsReadonly('tipoVeiculo') ? '' : null">
                          <option [ngValue]="null" selected disabled>Selecione</option>
                          <option *ngFor="let tipoVeiculo of TipoVeiculos" [ngValue]="tipoVeiculo">{{tipoVeiculo.nome}}</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-3">
                        <label for="">Placa Cavalo:</label>
                        <input type="text" class="form-control" formControlName="placaCavalo" placeholder="Digite aqui..." [readonly]="checkIfFieldIsReadonly('placaCavalo')">
                    </div>
                    <div class="col-3" *ngIf="quantidadeReboques >= 1">
                        <label for="">Placa Reboque 1:</label>
                        <input type="text" class="form-control" formControlName="placaReboque1" placeholder="Digite aqui..." [readonly]="checkIfFieldIsReadonly('placaReboque1')">
                    </div>
                    <div class="col-3" *ngIf="quantidadeReboques >= 2">
                        <label for="">Placa Reboque 2:</label>
                        <input type="text" class="form-control" formControlName="placaReboque2" placeholder="Digite aqui..." [readonly]="checkIfFieldIsReadonly('placaReboque2')">
                    </div>
                    <div class="col-3" *ngIf="quantidadeReboques >= 3">
                        <label for="">Placa Reboque 3:</label>
                        <input type="text" class="form-control" formControlName="placaReboque3" placeholder="Digite aqui..." [readonly]="checkIfFieldIsReadonly('placaReboque3')">
                    </div>
                </div>
        <h3>Relação de Contêineres CCT:</h3>
        <div class="overflow-auto my-3">
          <table class="grid-table">
            <thead>
              <tr>
                <th>Contêiner</th>
                <th>Lacres</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let lacre of lacresCCT"
                app-linha-grid-relacao-conteiner-cct-documento-transporte
                [relacaoConteiner]="lacre"
              >
              </tr>
            </tbody>
          </table>
        </div>
            </div>
        </form>
    </div>
</div>
