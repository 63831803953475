import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PesquisaExpansaoDocumentoParte } from 'src/app/models/LiberacaoDocumental/PesquisaExpansaoDocumentoParte';
import { SolicitacaoDTO } from 'src/app/models/LiberacaoDocumental/SolicitacaoDTO';
import { environment } from 'src/environments/environment';

import { SuccessResponse } from '../models/HttpResponses/SuccessResponse';
import { ConfirmacaoLiberacaoDocumentalDTO } from '../models/LiberacaoDocumental/ConfirmacaoLiberacaoDocumentalDTO';
import { FiltroLiberacaoDocumental } from './../models/FiltroLiberacaoDocumental';
import { AuthenticationService } from './authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})

export class LiberacaoDocumentalService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  public DocumentaryLiberation(solicitation: SolicitacaoDTO, confirm : ConfirmacaoLiberacaoDocumentalDTO, justificativa?: string): Observable<SuccessResponse>{
    let query: string[] = [];
    query.push(`usuarioInterno=${this.authenticationService.userName}`);
    if(justificativa?.length){
      query.push(`justificativa=${encodeURIComponent(justificativa)}`);
    }
    return this.http.post<SuccessResponse>(`${environment.apiUrlForLiberacaoDocumental}/LiberacaoDocumental/LiberarDocumento/${solicitation.id}/${solicitation.regimeCirculacao?.idRegimeCirculacao}?${query.join('&')}`, confirm).pipe();
  }

  public CancelDocumentaryLiberation(solicitation: SolicitacaoDTO, justificativa: string): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForLiberacaoDocumental}/LiberacaoDocumental/Cancelar/${solicitation.id}/${justificativa}/${this.authenticationService.userName}`, null).pipe();
  }

  public RecusaDocumentaryLiberation(solicitation: SolicitacaoDTO, motivoRecusa: string): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForLiberacaoDocumental}/LiberacaoDocumental/Recusar/${solicitation.id}/${this.authenticationService?.name}/${motivoRecusa}`, null).pipe();
  }

  public AutorizationSupervisor(solicitation: SolicitacaoDTO,  justificativa:string): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForLiberacaoDocumental}/LiberacaoDocumental/AutorizacaoSupervisor/${solicitation.id}/${justificativa}/${this.authenticationService.userName}`, null).pipe();
  }

  public RecusaSupervisor(solicitation: SolicitacaoDTO, justificativa:string): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForLiberacaoDocumental}/LiberacaoDocumental/RecusaSupervisor/${solicitation.id}/${justificativa}/${this.authenticationService.userName}`, null).pipe();
  }


  public searchLiberationSolicitation(solicitation: FiltroLiberacaoDocumental): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(
      `${environment.apiUrlForLiberacaoDocumental}/api/SolicitacaoLiberacao?${this.setQueryStringForSearchLiberationSolicitation(solicitation)}`
      );
  }

  private setQueryStringForSearchLiberationSolicitation(solicitation: FiltroLiberacaoDocumental): string{
    let query: string[] = [];
    if(solicitation?.documento?.id){
      query.push(`Documento.Id=${solicitation.documento.id}`);
    }
    if(solicitation?.documento?.tipoDocumento){
      query.push(`Documento.TipoDocumento=${solicitation.documento.tipoDocumento}`);
    }
    if(solicitation?.documento?.numeroDocumento){
      query.push(`Documento.NumeroDocumento=${solicitation.documento?.numeroDocumento}`);
    }
    if(solicitation?.navio){
      query.push(`Navio=${solicitation.navio}`);
    }
    if(solicitation?.viagem){
      query.push(`Viagem=${solicitation.viagem}`);
    }
    if(solicitation?.cliente?.tipoCliente){
      query.push(`Cliente.TipoCliente=${solicitation.cliente.tipoCliente}`);
    }
    if(solicitation?.cliente?.nomeCliente){
      query.push(`Cliente.NomeCliente=${solicitation.cliente.nomeCliente}`);
    }
    if(solicitation?.cliente?.cpfCnpj){
      query.push(`Cliente.CpfCnpj=${solicitation.cliente.cpfCnpj}`);
    }
    if(solicitation?.regimeCirculacao?.idRegimeCirculacao){
      query.push(`RegimeCirculacao.IdRegimeCirculacao=${solicitation.regimeCirculacao.idRegimeCirculacao}`);
    }
    if(solicitation?.regimeCirculacao?.nomeRegimeCirculacao){
      query.push(`RegimeCirculacao.NomeRegimeCirculacao=${solicitation.regimeCirculacao.nomeRegimeCirculacao}`);
    }
    if(solicitation?.status?.idStatus){
      query.push(`Status.IdStatus=${solicitation.status.idStatus}`);
    }
    if(solicitation?.status?.nomeStatus){
      query.push(`Status.NomeStatus=${solicitation.status?.nomeStatus}`);
    }
    if(solicitation?.dataSolicitacao){
      query.push(`DataSolicitacao=${solicitation.dataSolicitacao}`);
    }
    if(solicitation?.dataLiberacao){
      query.push(`DataLiberacao=${solicitation.dataLiberacao}`);
    }
    if(solicitation?.solicitante){
      query.push(`Solicitante=${solicitation.solicitante}`);
    }
    return query.join('&');
  }

  public getConsultaBloqueio(solicitacaoLiberacao : SolicitacaoDTO, correlation_id: string): Observable<SuccessResponse> | void{
    const documento = solicitacaoLiberacao.documento;
    const headers = new HttpHeaders().append('correlation_id', correlation_id);
    return this.http.get<SuccessResponse>(`${environment.apiUrlForLiberacaoDocumental}/ConsultaBloqueio?idSolicitacao=${solicitacaoLiberacao.id}&numeroDocumento=${documento?.numeroDocumento}&tipoDocumento=${documento?.tipoDocumento}`, {headers});
  }

  public getLiberationImpediments(solicitation: SolicitacaoDTO): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForLiberacaoDocumental}/LiberacaoDocumental/ImpedimentosLiberacao/${solicitation.id}/${solicitation.regimeCirculacao?.idRegimeCirculacao}`);
  }

  public getLiberationImpedimentsForJustificationDisplay(solicitation: SolicitacaoDTO): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForLiberacaoDocumental}/LiberacaoDocumental/ImpedimentosRecusaSolicitacao/${solicitation.id}`);
  }

  public searchPartDocumentToExpand(pesquisa: PesquisaExpansaoDocumentoParte): Observable<SuccessResponse>{
    let query: string[] = [];
    if(pesquisa?.tipoDocumento){
      query.push(`Documento.TipoDocumento=${pesquisa?.tipoDocumento}`);
    }
    if(pesquisa?.numeroDocumento){
      query.push(`Documento.NumeroDocumento=${pesquisa?.numeroDocumento}`);
    }
    if(pesquisa?.idRegimeCirculacao){
      query.push(`RegimeCirculacao.IdRegimeCirculacao=${pesquisa.idRegimeCirculacao}`);
    }
    if(pesquisa?.nomeRegimeCirculacao){
      query.push(`RegimeCirculacao.NomeRegimeCirculacao=${pesquisa.nomeRegimeCirculacao}`);
    }
    if(pesquisa?.status){
      query.push(`Status.NomeStatus=${pesquisa?.status}`);
    }
    return this.http.get<SuccessResponse>(
      `${environment.apiUrlForLiberacaoDocumental}/api/SolicitacaoLiberacao?${query.join('&')}`
    );
  }
}

