import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiltroRelatorioConteineresLiberados } from '../../models/relatorios/RelatorioConteineresLiberados/FiltroRelatorioConteineresLiberados';
import { RelatorioConteineresLiberados } from '../../models/relatorios/RelatorioConteineresLiberados/RelatorioConteineresLiberados';
import { DatePipe } from '@angular/common';
import { SuccessResponse } from '../../models/HttpResponses/SuccessResponse';
import { TimezoneService } from '../../shared/utils/timezone.service';

@Injectable({
  providedIn: 'root'
})
export class RelatorioConteineresLiberadosService {
  private logo = '../../assets/images/logoSeptibaTecon.png';

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private timezoneService: TimezoneService
  ) { }

  public searchConteineresLiberados(filtro: FiltroRelatorioConteineresLiberados): Observable<SuccessResponse> {
    return this.http.get<SuccessResponse>(`${environment.apiUrlForRelatorios}/liberacao/Conteineres?${this.setQueryStringForSearchConteineresLiberadosSolicitation(filtro)}`);
  }

  private setQueryStringForSearchConteineresLiberadosSolicitation(filtro: FiltroRelatorioConteineresLiberados): string{
    let query: string[] = [];
    if(filtro.navio){
      query.push(`Navio=${encodeURIComponent(filtro.navio)}`);
    }
    if(filtro.viagem){
      query.push(`Viagem=${encodeURIComponent(filtro.viagem)}`);
    }
    if(filtro.regime){
      query.push(`Regime=${encodeURIComponent(filtro.regime)}`);
    }
    if(filtro.periodo){
      query.push(`Periodo=${encodeURIComponent(filtro.periodo)}`);
    }
    if(filtro.periodoDe){
      query.push(`PeriodoDe=${encodeURIComponent(new Date(filtro.periodoDe).toLocaleDateString('PT-BR'))}`);
    }
    if(filtro.periodoAte){
      query.push(`PeriodoAte=${encodeURIComponent(new Date(filtro.periodoAte).toLocaleDateString('PT-BR'))}`);
    }
    return query.join('&');
  }

}
