import { MaskPipe } from 'ngx-mask';
import { ClienteFaturarDTO } from './../../../../models/EstimativaCalculo/CadastroDocumento/ClienteFaturarDTO';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ToastService } from 'src/app/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CadastroDocumentoEstimativaCalculoService } from '../../../../services/EstimativaCalculo/cadastro-documento-estimativa-calculo.service';
import { Cliente } from './../../../../models/Cliente';
import { VerificacaoDocumentosService } from './../../../../shared/utils/verificacao-documentos.service';
import { ClienteEstimativaCalculo } from './../../../../models/EstimativaCalculo/CadastroDocumento/ClienteEstimativaCalculo';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { DocumentCustomPattern } from 'src/app/shared/utils/constantes-documentos';
import { Campo } from 'src/app/models/Campo';
import { debounceTime } from 'rxjs';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-cliente-estimativa-calculo',
  templateUrl: './cliente-estimativa-calculo.component.html',
  styleUrls: ['./cliente-estimativa-calculo.component.scss']
})
export class ClienteEstimativaCalculoComponent implements OnInit {

  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;
  public CodigosClientes!: Cliente[];
  public CodigosClientesFaturar!: ClienteFaturarDTO[];
  public CodigosClientesAux!: Cliente[];
  public CodigosClientesFaturarAux!: ClienteFaturarDTO[];
  public codigoClienteSelecionado: string = '';
  public codigoClienteFaturarSelecionado: string = '';
  public clienteVindoBack: boolean = false;

  public readonly namePattern = DocumentCustomPattern.NAME_PATTERN;

  @Output() public onUpdateClient: EventEmitter<{}> = new EventEmitter<{}>();
  @Input() fieldSettings!: Campo[];
  @Input() public visualizar?: boolean = false
  @Input() doesSolicitationHaveIntegration!: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private changeDetector: ChangeDetectorRef,
    private CadastroDocumentoEstimativaCalculoService: CadastroDocumentoEstimativaCalculoService,
    private toastService: ToastService,
    private authenticationService: AuthenticationService,
    private maskPipe: MaskPipe,
    private spinner: NgxSpinnerService,
  ) { }

  public ngOnInit() {
    this.validation();
    this.CodigosClientes = [];
    this.CodigosClientesAux = [];
    this.CodigosClientesFaturar = [];
    this.CodigosClientesFaturarAux = [];
    console.log('on init acionado.. cnpj cliente:' + this.form.get('cpfCnpjCliente')?.value)
    this.SearchClientesByUser();
    this.SearchClientesFaturarByUser();
  }
  public SetClientControls(index: number): void{
    this.codigoClienteSelecionado = this.CodigosClientes[index]?.codigoCliente;
    console.log('Cliente clicado: '+this.CodigosClientes[index]?.cpfCnpj )
    this.form.get('cpfCnpjCliente')?.setValue(this.CodigosClientes[index]?.cpfCnpj);
    if(this.CodigosClientes[index]?.cpfCnpj != undefined){
      this.setClientTypeByDocumentNumber('Cliente');
    }else{
      this.searchCliente();
    }
  }
  public SetClienFaturarControls(index: number): void{
    this.codigoClienteFaturarSelecionado = this.CodigosClientesFaturar[index]?.codigoClienteFaturar as string;
    console.log('Cliente clicado FATURAR: '+this.CodigosClientesFaturar[index]?.cpfCnpjFaturar )
    this.form.get('cpfCnpjClienteFaturar')?.setValue(this.CodigosClientesFaturar[index]?.cpfCnpjFaturar);

    if(this.CodigosClientesFaturar[index]?.cpfCnpjFaturar != undefined)
          this.setClientTypeByDocumentNumber('ClienteFaturar');
    // }else{
    //   this.searchClienteFaturar();
    // }
    //this.searchClienteFaturar();

  }
  public CleanClients(){
    console.log('limpar clientes..');
    this.form.get('cpfCnpjCliente')?.setValue('');
    this.form.get('nomeCliente')?.setValue('');
    this.form.get('cpfCnpjClienteFaturar')?.setValue('');
    this.form.get('nomeClienteFaturar')?.setValue('');
    this.CodigosClientes = [];
    this.CodigosClientesAux = [];
    this.CodigosClientesFaturar = [];
    this.CodigosClientesFaturarAux = [];
   //this.SearchClientesByUser();
  }

  public SearchClientesByUser(pesquisou:boolean = false):void{
    if(this.CodigosClientes.length > 0 && pesquisou == false) return;
    console.log('buscou codigos clientes..')
    this.spinner.show();
    this.CadastroDocumentoEstimativaCalculoService.getClientesVinculados()
    .subscribe({
      next: (result) => {
        let clienteSalvo: Cliente[] = result.data as Cliente[];
        this.spinner.hide();
        this.CodigosClientes = [];

        if(!clienteSalvo[0]) return;

        if (!clienteSalvo[0]?.cpfCnpj && !clienteSalvo[0]?.nomeCliente) {
          return;
        }
          if(this.CodigosClientesAux?.length > 0){
          this.CodigosClientes = this.CodigosClientesAux;
        }else{
          this.CodigosClientes = clienteSalvo;
        }
        this.form.patchValue({
          codigoCliente : clienteSalvo
        }, { emitEvent: false });
        // this.setClientTypeByDocumentNumber('Cliente');
        this.form?.updateValueAndValidity();
        //this.updateClient();
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        (err.error as ErrorResponse)?.errors?.forEach(
          (error) => {
            if (error && error.length) {
              this.toastService.error(error);
              this.CodigosClientes = []
            }
          this.spinner.hide();
          }
        );
      }});
  }

  public SearchClientesFaturarByUser(pesquisou:boolean = false):void{
    if(this.CodigosClientesFaturar.length > 0 && pesquisou == false) return;
    console.log('buscou codigos clientes faturar..')
    this.spinner.show();
    this.CadastroDocumentoEstimativaCalculoService.getClientesFaturarVinculados()
    .subscribe({
      next: (result) => {
        let clienteSalvo: ClienteFaturarDTO[] = result.data as ClienteFaturarDTO[];
        this.spinner.hide();
        this.CodigosClientesFaturar = [];
        if(!clienteSalvo[0]) return;
        if (!clienteSalvo[0]?.cpfCnpjFaturar && !clienteSalvo[0]?.nomeClienteFaturar) {
          return;
        }
        if(this.CodigosClientesFaturarAux?.length > 0){
          this.CodigosClientesFaturar = this.CodigosClientesFaturarAux;
        }else{
          this.CodigosClientesFaturar = clienteSalvo;
        }
        this.form.patchValue({codigoClienteFaturar: clienteSalvo}, { emitEvent: false });
        // this.setClientTypeByDocumentNumber('Cliente');
        this.form?.updateValueAndValidity();
        //this.updateClient();
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        (err.error as ErrorResponse)?.errors?.forEach(
          (error) => {
            if (error && error.length) {
              this.toastService.error(error);
              this.CodigosClientesFaturar = []
            }
            this.spinner.hide();
          }
        );
      }});
  }

  private validation(): void {
    this.form = this.fb.group({
      nomeCliente: [""],
      cpfCnpjCliente: ["", [this.isDocumentValid.bind(this)]],
      tipoCliente: [""],
      nomeClienteFaturar: [""],
      cpfCnpjClienteFaturar: ["", [this.isDocumentValid.bind(this)]],
      tipoClienteFaturar: [""],
      clienteFaturarAPrazo: [false],
      temRestricao: [false],
      codCliente: [''],
      codClienteFaturar: ['']
    });
    this.form.valueChanges
      .pipe(
        debounceTime(100)
      )
      .subscribe(
        () => {
          this.updateClient();
        }
      );
    this.form.get('tipoCliente')?.valueChanges
      .pipe(
        debounceTime(100)
      )
      .subscribe(
        () => {
          this.setDocumentMaskByClientType('Cliente');
        }
      );
    this.form.get('tipoClienteFaturar')?.valueChanges
      .pipe(
        debounceTime(100)
      )
      .subscribe(
        () => {
          this.setDocumentMaskByClientType('ClienteFaturar');
        }
      );
    this.form.get('cpfCnpjCliente')?.valueChanges
      .pipe(
        debounceTime(1000)
      )
      .subscribe(
        (value) => {
          if (!value?.length) {
            return;
          }
          if (this.form.get('cpfCnpjCliente')?.valid) {
            this.searchCliente();
          }
        }
      );
    this.form.get('cpfCnpjClienteFaturar')?.valueChanges
      .pipe(
        debounceTime(1000)
      )
      .subscribe(
        (value) => {
          if (!value?.length) {
            return;
          }
          if (this.form.get('cpfCnpjClienteFaturar')?.valid) {
            this.searchClienteFaturar();
          }
        }
      );
    this.form.get('nomeCliente')?.valueChanges
      .pipe(
        debounceTime(1000)
      )
      .subscribe(
        (value) => {
          if (!value?.length) {
          return;
        }
          this.searchCliente();
        }
      );
    this.form.get('nomeClienteFaturar')?.valueChanges
      .pipe(
        debounceTime(1000)
      )
      .subscribe(
        (value) => {
          if (!value?.length) {
            return;
          }
          this.searchClienteFaturar();
        }
      );
      this.form.get('codCliente')?.valueChanges
      .pipe(
        debounceTime(100)
      )
      .subscribe(
        () => {
          if(this.form.get('codCliente')?.value<0 || this.form.get('codCliente')?.value == null){
            return;
          }
          let indexCodSap = this.form.get('codCliente')?.value == null ? 0 : this.form.get('codCliente')?.value as number;
            this.codigoClienteSelecionado = this.CodigosClientes[indexCodSap]?.codigoCliente;
            if(this.verificacaoDocumentosService.eraseMask(this.form.get('cpfCnpjCliente')?.value) !==
               this.CodigosClientes[indexCodSap]?.cpfCnpj){
              this.SetClientControls(indexCodSap);
            }
          }
      );
      this.form.get('codClienteFaturar')?.valueChanges
      .pipe(
        debounceTime(100)
      )
      .subscribe(
        () => {
          if(this.form.get('codClienteFaturar')?.value<0 || this.form.get('codClienteFaturar')?.value == null){
            return;
          }
          let indexCodSap = this.form.get('codClienteFaturar')?.value == null ? 0 : this.form.get('codClienteFaturar')?.value as number;
            this.codigoClienteFaturarSelecionado = this.CodigosClientesFaturar[indexCodSap]?.codigoClienteFaturar as string;
            if(this.verificacaoDocumentosService.eraseMask(this.form.get('cpfCnpjClienteFaturar')?.value) !==
             this.CodigosClientesFaturar[indexCodSap]?.cpfCnpjFaturar){
              this.SetClienFaturarControls(indexCodSap);
            }
        }
      );
    this.form.get('cpfCnpjCliente')?.setValue(this.authenticationService.userName ?? "");
    this.updateClient();

  }

  public setFormValuesFromReceivedClient(client: ClienteEstimativaCalculo | undefined): void {
    if (!client) {
      return;
    }
    let cpfCnpjCliente = this.verificacaoDocumentosService.eraseMask(client.cpfCnpjCliente ?? '');
    let cpfCnpjClienteFaturar = this.verificacaoDocumentosService.eraseMask(client.cpfCnpjClienteFaturar ?? '');
    this.form.get('nomeCliente')?.setValue(client?.nomeCliente ? client?.nomeCliente : this.form.value.nomeCliente);
    this.form.get('cpfCnpjCliente')?.setValue(cpfCnpjCliente ? cpfCnpjCliente : this.form.value.cpfCnpjCliente);
    let tipoCliente = cpfCnpjCliente?.length == 14 ? 'juridica' : cpfCnpjCliente?.length == 11 ? 'fisica' : '';
    this.form.get('tipoCliente')?.setValue(tipoCliente);
    this.form.get('nomeClienteFaturar')?.setValue(client?.nomeClienteFaturar ? client?.nomeClienteFaturar : this.form.value.nomeClienteFaturar);
    this.form.get('cpfCnpjClienteFaturar')?.setValue(cpfCnpjClienteFaturar ? cpfCnpjClienteFaturar : this.form.value.cpfCnpjClienteFaturar);
    let tipoClienteFaturar = cpfCnpjClienteFaturar?.length == 14 ? 'juridica' : cpfCnpjClienteFaturar?.length == 11 ? 'fisica' : '';
    this.form.get('tipoClienteFaturar')?.setValue(tipoClienteFaturar);
    this.form.get('clienteFaturarAPrazo')?.setValue(client?.clienteFaturarAPrazo ? client?.clienteFaturarAPrazo : this.form.value.clienteFaturarAPrazo);
    this.form.get('temRestricao')?.setValue(client?.temRestricao ? client?.temRestricao : this.form.value.temRestricao);
    //this.form.get('codCliente')?.setValue(client?.codCliente ? client?.codCliente : this.form.value.codCliente);
    //this.form.get('codClienteFaturar')?.setValue(client?.codClienteFaturar ? client?.codClienteFaturar : this.form.value.codClienteFaturar);
    this.CodigosClientes = [];
    this.CodigosClientesFaturar = [];
    if((client.nomeCliente != null || client.cpfCnpjCliente != null)&&
       (client.nomeCliente != '' || client.cpfCnpjCliente != '')){
        this.clienteVindoBack = true;
    this.CodigosClientesAux = [{nomeCliente: client.nomeCliente, cpfCnpj: client.cpfCnpjCliente, codigoCliente: client.codCliente ?? '', tipoCliente: ''}];
    }
    //this.CodigosClientes = codCliente;
    console.log('cliente vindo do back: ')
    console.log(this.CodigosClientesAux)
    this.searchCliente()
    if((client.nomeClienteFaturar != null || client.cpfCnpjClienteFaturar != null)&&
       (client.nomeClienteFaturar != '' || client.cpfCnpjClienteFaturar != '')){
      this.CodigosClientesFaturarAux = [{nomeClienteFaturar: client.nomeClienteFaturar, cpfCnpjFaturar: client.cpfCnpjClienteFaturar,clienteFaturarAPrazo: false, codigoClienteFaturar: client.codClienteFaturar ?? '', temRestricao: false}];
    }
    //this.CodigosClientesFaturar = codClienteFaturar;
    this.searchClienteFaturar()

    this.changeDetector.detectChanges();
    this.updateClient();
  }

  public validateCpfOrCnpj(document: string): string | null {
    let documentNumber = this.verificacaoDocumentosService.eraseMask(document);
    if (documentNumber.length == 0) {
      return null;
    }
    if (documentNumber.length == 11) {
      return this.verificacaoDocumentosService.calculateValidCPF(document);
    }
    if (documentNumber.length == 14) {
      return this.verificacaoDocumentosService.calculateValidCNPJ(document);
    }
    return "O nº do CNPJ/CPF informado é inválido. Favor verificar!";
  }

  public isDocumentValid(control: AbstractControl): { invalidDocument: string } | null {
    if (!control) {
      return null;
    }
    const documentNumber = control.value as string;
    if (!documentNumber || !documentNumber.length) {
      return null;
    }
    let error = this.validateCpfOrCnpj(documentNumber);
    if (error && error.length) {
      return { invalidDocument: error as string }
    }
    return null;
  }

  public updateClient(): void {
    this.onUpdateClient.emit({ 'cliente': this.getClientFromForm() });
  }

  private getClientFromForm(): ClienteEstimativaCalculo {
    let cliente = this.form.getRawValue() as ClienteEstimativaCalculo;
    cliente.cpfCnpjCliente = this.verificacaoDocumentosService.eraseMask(cliente.cpfCnpjCliente);
    cliente.cpfCnpjClienteFaturar = this.verificacaoDocumentosService.eraseMask(cliente.cpfCnpjClienteFaturar);
    cliente.codCliente = this.codigoClienteSelecionado;
    cliente.codClienteFaturar = this.codigoClienteFaturarSelecionado;
    return cliente;
  }

  public isClientValid(): boolean {
    return !this.form.invalid;
  }

  public updateValidators(): void {
    if (this.visualizar) {
      this.form.disable({ emitEvent: false });
      this.changeDetector.detectChanges();
      return;
    }
    if (!this.fieldSettings?.length) {
      this.enableAllFormFields();
      return;
    }
    for (let field in this.form.controls) {
      let fieldSetting: Campo = this.fieldSettings.find(
        (setting) => setting.nome == field
      ) as Campo;
      if (fieldSetting) {
        if (fieldSetting.obrigatorio) {
          this.form.get(field)?.addValidators(Validators.required);
        }
        else {
          this.form.get(field)?.removeValidators(Validators.required);
        }
        if (this.doesSolicitationHaveIntegration) {
          if (fieldSetting.leitura && this.form.get(field)?.value) {
            this.form.get(field)?.disable();
          }
          else {
            this.form.get(field)?.enable();
          }
        }
        else {
          if (fieldSetting.leitura) {
            this.form.get(field)?.disable();
          }
          else {
            this.form.get(field)?.enable();
          }
        }
        if (fieldSetting.bloqueado) {
          this.form.get(field)?.disable();
        }
        if (!this.form.get(field)?.value && fieldSetting.obrigatorio) {
          this.form.get(field)?.enable();
        }
      }
      else {
        this.form.get(field)?.removeValidators(Validators.required);
        this.form.get(field)?.enable();
      }
      this.changeDetector.detectChanges();
      this.showFieldAsRequired(field);
    }
  }

  public enableAllFormFields(): void {
    this.changeDetector.detectChanges();
    for (let field in this.form.controls) {
      this.form.get(field)?.enable();
      this.form.get(field)?.removeValidators(Validators.required);
      this.showFieldAsRequired(field);
      this.changeDetector.detectChanges();
    }
  }

  private showFieldAsRequired(field: string): void {

    if (!!this.form.get(field)?.hasValidator(Validators.required)) {
      document.getElementById(`${field}-required-label`)?.classList?.remove('invisible');
      document.getElementById(`${field}-required-label`)?.classList?.add('visible');
    }
    else {
      document.getElementById(`${field}-required-label`)?.classList?.remove('visible');
      document.getElementById(`${field}-required-label`)?.classList?.add('invisible');
    }
    this.changeDetector.detectChanges();
  }

  public setClientTypeByDocumentNumber(clientType: string): void {
    let documentoCliente = this.verificacaoDocumentosService.eraseMask(this.form.get(`cpfCnpj${clientType}`)?.value ?? '');
    if (!documentoCliente?.length) {
      return;
    }
    let cliente: any = {};
    cliente[`cpfCnpj${clientType}`] = this.maskPipe.transform(
      documentoCliente ?? '',
      '00.000.000/0000-00||000.000.000-00'
    );
    cliente[`tipo${clientType}`] = documentoCliente?.length == 14 ? 'juridica' : documentoCliente?.length == 11 ? 'fisica' : '';
    this.form.patchValue(cliente);
    this.updateClient();
  }

  public setDocumentMaskByClientType(clientType: string): void {
    let documento = this.verificacaoDocumentosService.eraseMask(this.form.get(`cpfCnpj${clientType}`)?.value ?? '');
    if (!documento?.length) {
      return;
    }
    let tipoCliente: string = this.form.get(`tipo${clientType}`)?.value ?? '';
    if(!tipoCliente?.length){
      return;
    }
    if(tipoCliente == 'fisica'){
      this.form?.get(`cpfCnpj${clientType}`)?.setValue(this.maskPipe.transform(documento, '000.000.000-00'));
    }
    else if(tipoCliente == 'juridica'){
      this.form?.get(`cpfCnpj${clientType}`)?.setValue(this.maskPipe.transform(documento, '00.000.000/0000-00'));
    }
    this.updateClient();
  }

  public clearFields(): void {
    this.form.reset();
  }

  public setValuesFromCTEXML(xml: Partial<Cliente>): void {
    console.log(xml)
    this.form.patchValue({
      cpfCnpjCliente: this.maskPipe.transform(
        xml.cpfCnpj ?? '',
        '00.000.000/0000-00||000.000.000-00'
      ),
      tipoCliente: 'juridica'
    }, { emitEvent: true });
    console.log(this.getClientFromForm())
    // this.setClientTypeByDocumentNumber('Cliente');
    // this.setClientTypeByDocumentNumber('ClienteFaturar');
  }

  public setValuesFromDIXML(xml: any): void {
    this.form.reset();
    this.form.patchValue({
      cpfCnpjCliente: this.maskPipe.transform(
        xml.ListaDeclaracoes.declaracaoImportacao.importadorNumero._text ?? '',
        '00.000.000/0000-00||000.000.000-00'
      ),
      tipoCliente: 'juridica'
    }, { emitEvent: true });
    // this.setClientTypeByDocumentNumber('Cliente');
    // this.setClientTypeByDocumentNumber('ClienteFaturar');
  }

  public setValuesFromNFXML(xml: any): void {
    this.form.reset();
    this.form.patchValue({
      cpfCnpjCliente: this.maskPipe.transform(
        xml.nfeProc.NFe.infNFe.dest.CNPJ._text ?? '',
        '00.000.000/0000-00||000.000.000-00'
      ),
      tipoCliente: 'juridica'
    }, { emitEvent: true });
    // this.setClientTypeByDocumentNumber('Cliente');
    // this.setClientTypeByDocumentNumber('ClienteFaturar');
  }

  private searchCliente(): void {
    console.log('buscou cliente:' + this.form.get('cpfCnpjCliente')?.value)
    let cliente: Cliente = {
      nomeCliente: this.form.get(`nomeCliente`)?.value,
      tipoCliente: '',
      cpfCnpj: this.form.get(`cpfCnpjCliente`)?.value,
      codigoCliente : '',
    };
    this.CadastroDocumentoEstimativaCalculoService.getCliente(cliente)
      .subscribe({
        next: (result) => {
          let clienteSalvo: Cliente[] = result.data as Cliente[];

          if(!clienteSalvo[0]) return;

          if (!clienteSalvo[0]?.cpfCnpj && !clienteSalvo[0]?.nomeCliente) {
            return;
          }
          if(!this.clienteVindoBack)
            this.CodigosClientes = clienteSalvo;

          this.CodigosClientesAux = clienteSalvo;
          let cpfCnpj = this.verificacaoDocumentosService.eraseMask(clienteSalvo[0]?.cpfCnpj);
          let tipoCliente = cpfCnpj.length == 14 ? 'juridica' : cpfCnpj.length == 11 ? 'fisica' : ''
          if (cpfCnpj.length == 11) {
            cpfCnpj = this.verificacaoDocumentosService.setMaskForCPF(cpfCnpj)
          }
          else {
            cpfCnpj = this.verificacaoDocumentosService.setMaskForCNPJ(cpfCnpj)
          }
          this.form.patchValue({
            nomeCliente: clienteSalvo[0]?.nomeCliente,
            cpfCnpjCliente: cpfCnpj,
            tipoCliente: tipoCliente,
            codigoCliente : clienteSalvo[0]?.codigoCliente
          }, { emitEvent: false });
          // this.setClientTypeByDocumentNumber('Cliente');
          this.form?.updateValueAndValidity();
          this.updateClient();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          (err.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              if (error && error.length) {
                this.toastService.error(error);
              }
              this.CleanClients();
              this.clearFields();
            }
          );
        }
      });
  }

  private searchClienteFaturar(): void {
    console.log('buscou cliente faturar:' + this.form.get('cpfCnpjClienteFaturar')?.value)

    let cliente: Cliente = {
      nomeCliente: this.form.get(`nomeClienteFaturar`)?.value,
      tipoCliente: '',
      cpfCnpj: this.form.get(`cpfCnpjClienteFaturar`)?.value,
      codigoCliente: '',
    };
    this.CadastroDocumentoEstimativaCalculoService.getClienteFaturar(cliente)
      .subscribe({
        next: (result) => {
          let clienteSalvo: ClienteFaturarDTO[] = result.data as ClienteFaturarDTO[];

          if(!clienteSalvo[0]) return;
          if (!clienteSalvo[0]?.cpfCnpjFaturar && !clienteSalvo[0]?.nomeClienteFaturar) {
            return;
          }
          //this.CodigosClientesFaturar = clienteSalvo;
          this.CodigosClientesFaturarAux = clienteSalvo;
          let cpfCnpjFaturar = this.verificacaoDocumentosService.eraseMask(clienteSalvo[0]?.cpfCnpjFaturar);
          let tipoClienteFaturar = cpfCnpjFaturar.length == 14 ? 'juridica' : cpfCnpjFaturar.length == 11 ? 'fisica' : ''
          if (cpfCnpjFaturar.length == 11) {
            cpfCnpjFaturar = this.verificacaoDocumentosService.setMaskForCPF(cpfCnpjFaturar)
          }
          else {
            cpfCnpjFaturar = this.verificacaoDocumentosService.setMaskForCNPJ(cpfCnpjFaturar)
          }
          this.form.patchValue({
            nomeClienteFaturar: clienteSalvo[0]?.nomeClienteFaturar,
            cpfCnpjClienteFaturar: cpfCnpjFaturar,
            tipoClienteFaturar: tipoClienteFaturar,
            clienteFaturarAPrazo: !!clienteSalvo[0]?.clienteFaturarAPrazo,
            temRestricao: !!clienteSalvo[0]?.temRestricao,
            codigoClienteFaturar: clienteSalvo[0]?.codigoClienteFaturar
          }, { emitEvent: false });
          // this.setClientTypeByDocumentNumber('Cliente');
          this.form?.updateValueAndValidity();
          this.updateClient();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          (err.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              if (error && error.length) {
                this.toastService.error(error);
              }
              this.CleanClients();
              this.clearFields();
            }
          );
        }
      });
  }

  public getClientFromFormFields(): ClienteEstimativaCalculo {
    let cliente = this.form.getRawValue() as ClienteEstimativaCalculo;

    cliente.codCliente = this.codigoClienteSelecionado;
    cliente.codClienteFaturar = this.codigoClienteFaturarSelecionado;
    return cliente;
  }

}
