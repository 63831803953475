import { ErrorResponse } from './../../models/HttpResponses/ErrorResponse';
import { SuccessResponse } from '../../models/HttpResponses/SuccessResponse';
import { ModalRespostaPositivaNegativaComponent } from './../../shared/components/modais/modal-resposta-positiva-negativa/modal-resposta-positiva-negativa.component';
import { CabotagemEmbarqueService } from './../../services/cabotagem-embarque.service';
import { BookingDTOPlain } from './../../models/CabotagemEmbarque/BookingDTOPlain';
import { IncluirBookingComponent } from './../../components/modais/incluir-booking/incluir-booking.component';
import { RelacaoBookingsComponent } from './../../components/cabotagem-embarque/relacao-bookings/relacao-bookings.component';
import { BookingDTO } from './../../models/CabotagemEmbarque/BookingDTO';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-cabotagem-embarque',
  templateUrl: './cabotagem-embarque.component.html',
  styleUrls: ['./cabotagem-embarque.component.scss']
})
export class CabotagemEmbarqueComponent implements OnInit {

  public title: string = 'Cabotagem de Embarque';
  public isBookingRelationFromUploadOrSearch: boolean = false;
  public retrievedBookingNumbersForConfirmation!: string[];

  @ViewChild(RelacaoBookingsComponent) relacaoBookings!: RelacaoBookingsComponent;
  @ViewChild(IncluirBookingComponent) incluirBooking!: IncluirBookingComponent;
  @ViewChild(ModalRespostaPositivaNegativaComponent) confirmarSalvarBooking!: ModalRespostaPositivaNegativaComponent;
  public readonly visualizacao = new AuthRoles(Telas.cabotagemEmbarque, Permissoes.visualizacao)
  public readonly incluir = new AuthRoles(Telas.cabotagemEmbarque, Permissoes.incluir)
  public readonly salvar = new AuthRoles(Telas.cabotagemEmbarque, Permissoes.salvar)

  constructor(
    private changeDetector: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private cabotagemEmbarqueService: CabotagemEmbarqueService,
    private toastService:ToastService,
    private authenticationService: AuthenticationService
  ) { }

  public ngOnInit(): void {

  }

  public openModalForBookingInclusion(): void {
    this.incluirBooking.openModalForBookingInclusion();
  }

  public includeBooking(booking: BookingDTO): void {
    // if(this.isBookingRelationFromUploadOrSearch){
    //   this.relacaoBookings.eraseBookingRelation();
    // }
    this.relacaoBookings.includeBooking(booking);
    this.isBookingRelationFromUploadOrSearch = false;
    this.changeDetector.detectChanges();
  }

  public insertMultipleBookingsIntoBookingsRelation(bookings: BookingDTOPlain[]): void {
    this.clearData();
    let multipleBookings: BookingDTO[] = bookings.map(
      (booking) => {
        return {
          viagem: {
            nomeNavio: booking.navio.toString(),
            numeroViagem: booking.viagem.toString()
          },
          armador: booking.armador.toString(),
          numeroBooking: booking.booking.toString(),
          modalidade: booking.modalidade.toString(),
          dataHoraCadastro: new Date(),
          usuario: this.authenticationService.name
        };
      }
    );
    this.relacaoBookings.includeMultipleBookings(multipleBookings);
    this.isBookingRelationFromUploadOrSearch = true;
    this.changeDetector.detectChanges();
  }

  public insertMultipleBookingsFromSearch(bookings: BookingDTO[]): void {
    this.relacaoBookings.includeMultipleBookings(bookings);
    this.isBookingRelationFromUploadOrSearch = true;
    this.changeDetector.detectChanges();
  }

  public clearData(): void {
    this.spinner.show('limpar');
    setTimeout(() => {
      this.spinner.hide('limpar');
    }, 2000);
    this.relacaoBookings.clear();
  }

  public get isBookingRelationDataEmpty(): boolean {
    return !!(!this.relacaoBookings?.getBookingRelationsFromGrid()?.length);
  }

  public get isSelectedBookingRelationDataEmpty(): boolean {
    return !!(!this.relacaoBookings?.getSelectedBookingRelationsFromGrid()?.length);
  }

  public saveData(): void {
    if (this.isSelectedBookingRelationDataEmpty || !this.relacaoBookings?.areSelectedBookingsValid()) {
      return;
    }
    let bookingRelations: BookingDTO[] = this.relacaoBookings?.getSelectedBookingRelationsFromGrid();
    this.cabotagemEmbarqueService.getBookingsByBookingNumberArray(bookingRelations)
      .subscribe({
        next: (retrievedBookings) => {
          if (retrievedBookings && retrievedBookings.length) {
            this.retrievedBookingNumbersForConfirmation = retrievedBookings.map(
              (booking) => booking.numeroBooking
            );
            this.openModalForSavingBookingsConfirmation();
          }
          else {
            // setTimeout(() => {
              this.confirmSavingOfBookingRelation();
            // }, 200);
          }
          // this.spinner.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          (err?.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              this.toastService.error(error);
            }
          );
        }
      });
  }

  private openModalForSavingBookingsConfirmation(): void {
    this.confirmarSalvarBooking.openModalForConfirmOrRefuse();
  }

  public confirmSavingOfBookingRelation(): void {
    this.spinner.show();
    let bookingRelation: BookingDTO[] = this.relacaoBookings?.getSelectedBookingRelationsFromGrid();
    this.cabotagemEmbarqueService.saveBookingRelation(bookingRelation)
      .subscribe({
        next: (event: SuccessResponse) => {
           this.spinner.hide();
          (event as SuccessResponse)?.message?.forEach(
            (message) => {
              if (message.length)
                this.toastService.success(message);
            }
          );
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this.spinner.hide();
          (err.error as ErrorResponse)?.errors?.forEach(
            (error: string) => {
              this.toastService.error(error);
            }
          );
        }
      });
    this.retrievedBookingNumbersForConfirmation = [];
  }

  public cancelSavingOfBookingRelation(): void {
    this.spinner.show('cancelar');
    setTimeout(() => {
      this.spinner.hide('cancelar');
    }, 1000);
    this.retrievedBookingNumbersForConfirmation = [];
  }
}
