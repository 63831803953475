import { Modalidade } from './../../../models/Modalidade';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { BookingDTO } from './../../../models/CabotagemEmbarque/BookingDTO';
import { Component, Input, OnInit, AfterViewInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { AnyARecord } from 'dns';
import { CabotagemEmbarqueService } from 'src/app/services/cabotagem-embarque.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from 'oidc-client-ts';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'tr[app-linha-grid-relacao-bookings-cabotagem-embarque]',
  templateUrl: './linha-grid-relacao-bookings-cabotagem-embarque.component.html',
  styleUrls: ['./linha-grid-relacao-bookings-cabotagem-embarque.component.scss']
})
export class LinhaGridRelacaoBookingsCabotagemEmbarqueComponent implements AfterViewInit {

  @Input() relacaoBooking!: BookingDTO;
  @Output() onSendBooking: EventEmitter<BookingDTO> = new EventEmitter<BookingDTO>();
  @Output() onCheckLineValidness: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onManualSelection: EventEmitter<void> = new EventEmitter<void>();
  @Output() onEraseLine: EventEmitter<void> = new EventEmitter<void>();
  public selecionado!: UntypedFormControl;
  public numeroViagem!: UntypedFormControl;
  public nomeNavio!: UntypedFormControl;
  public armador!: UntypedFormControl;
  public numeroBooking!: UntypedFormControl;
  public modalidade!: UntypedFormControl;
  public dataHoraCadastro!: UntypedFormControl;
  public usuario!: UntypedFormControl;
  public id!: UntypedFormControl;

  public formBooking!: UntypedFormGroup;
  public readonly modalidades: string[] = ['Porto', 'Porta']

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private cabotagemEmbarqueService: CabotagemEmbarqueService,
    private spinner: NgxSpinnerService,
    private toastService:ToastService

  ) { }

  public ngAfterViewInit(): void {
    this.validation();
  }

  private validation(): void {
    this.selecionado = new UntypedFormControl(false, Validators.compose([]));
    this.numeroViagem = new UntypedFormControl(this.relacaoBooking.viagem.numeroViagem, Validators.compose([Validators.required]));
    this.nomeNavio = new UntypedFormControl(this.relacaoBooking.viagem.nomeNavio, Validators.compose([Validators.required]));
    this.armador = new UntypedFormControl(this.relacaoBooking.armador, Validators.compose([Validators.required]));
    this.numeroBooking = new UntypedFormControl(this.relacaoBooking.numeroBooking, Validators.compose([Validators.required]));
    this.modalidade = new UntypedFormControl(
      (this.validaModalidade(this.relacaoBooking.modalidade)) ?? '',
      Validators.compose([Validators.required])
    );
    this.id = new UntypedFormControl(this.relacaoBooking.id, Validators.compose([]));
    this.dataHoraCadastro = new UntypedFormControl(this.relacaoBooking.dataHoraCadastro, Validators.compose([Validators.required]));
    this.usuario = new UntypedFormControl(this.relacaoBooking.usuario, Validators.compose([Validators.required]));

    this.formBooking = this.fb.group({
      numeroViagem: this.numeroViagem,
      nomeNavio: this.nomeNavio,
      armador: this.armador,
      numeroBooking: this.numeroBooking,
      modalidade: this.modalidade,
      dataHoraCadastro: new Date(this.dataHoraCadastro.value),
      usuario: this.usuario,
      id:this.id
    })
  }

  public validaModalidade(modalidade: string): any {
    if (modalidade.includes('/')) {
      modalidade = modalidade.split('/')[1];
      if (modalidade == 'PA') {
        return this.modalidades.find(modal => modal.toLowerCase() == 'Porta'.toLowerCase()) ?? '';
      }
      if (modalidade == 'PO') {
        return this.modalidades.find(modal => modal.toLowerCase() == 'Porto'.toLowerCase()) ?? '';
      }
    }
    if (modalidade == "" || modalidade == undefined) {
      return ""
    }
    else {
      return this.modalidades.find(modal => modal.toLowerCase() == modalidade.toLowerCase()) ?? '';
    }
  }

  public getBookingFromForm(): BookingDTO {
    let booking: BookingDTO = {
      viagem: {
        numeroViagem: this.numeroViagem?.value,
        nomeNavio: this.nomeNavio?.value
      },
      armador: this.armador?.value,
      numeroBooking: this.numeroBooking?.value,
      modalidade: this.modalidade?.value,
      dataHoraCadastro: this.dataHoraCadastro?.value,
      usuario: this.usuario?.value,
      id:this.id.value

    };
    // console.log(booking)
    return booking;
  }

  public isBookingValid(): boolean {
    return this.formBooking?.valid;
  }

  public getTimezoneFromDate(date: Date): string {
    if (!date) {
      return '';
    }
    let timezoneStart = date.toString().indexOf('+') ?? date.toString().lastIndexOf('-');
    return `UTC${date.toString().substring(timezoneStart)}`
  }

  public eraseLine(): void{
    this.spinner.show('carregando');
    console.log('teste entrou: ' + this.id.value)
    if (this.id.value !=null) {
      this.cabotagemEmbarqueService.DeleteBookingsRelation(this.id.value).subscribe({
        next: (bookingRelation: any) => {
          this.toastService.success(bookingRelation.message);
          this.spinner.hide('carregando');
        },
        error: (err: HttpErrorResponse) => {
          this.toastService.error(err.message);
          this.spinner.hide('carregando');
        }
      });
    }
    if (!this.selecionado?.value) {
      return;
    }
    this.onEraseLine.emit();
  }
}
