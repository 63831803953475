import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Modulo } from 'src/app/contants/modulo';
import { Telas } from 'src/app/contants/telas';

import { Menu } from 'src/app/models/Menu';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  public isCollapsed = false;
  public translateXSub = '-130px';
  public translateXSubItem = '365px';
  public translateYSubItem = '-16px';
  name: string | undefined;

  public menuList: Menu[] = [
    {
      Id: 1,
      Name: 'monitoramento',
      Path: '',
      Role: [Telas.monitoramentoRpaApi],
      SubMenu: [
        {
          Id: 1,
          Name: 'RPA/API',
          Path: 'monitoramento-rpa-api',
          Role: [Telas.monitoramentoRpaApi]
        }
      ],
    },
    {
      Id: 2,
      Name: 'solicitações',
      Path: '',
      Role: [Telas.estimativaCalculo,
      Telas.solicitacaoLiberacaoExportacao,
      Telas.liberacaoDocumentalExportacao,
      Telas.liberacaoDocumentalImportacao,
      Telas.liberacaoDocumentalCabotagem,
      Telas.recepcaoConteiner,
      Telas.entregaDueConteiner,
      Telas.bloqueioDesbloqueioConteinerEmbarque,
      Telas.emissaoTicketPesagemExportacao,
      Telas.solicitacaoLiberacaoImportacao,
      Telas.solicitacaoLiberacaoCabotagem,
      Telas.recepcaoNotaFiscal,
      Telas.cabotagemEmbarque,
      Telas.recepcaoConteinerTransship,
      Telas.recepcaoDocumentoTransporte],
      SubMenu: [
        {
          Id: 1,
          Name: 'Liberação Financeira',
          Path: 'estimativa-calculo',
          Role: [Telas.estimativaCalculo, Telas.solicitacaoFaturamentoEstimativaCalculo],
        },
        {
          Id: 2,
          Name: 'Liberação Documental',
          Path: '',
          Role: [Telas.solicitacaoLiberacaoExportacao,
          Telas.liberacaoDocumentalExportacao,
          Telas.liberacaoDocumentalImportacao,
          Telas.liberacaoDocumentalCabotagem,

          Telas.recepcaoConteiner,
          Telas.entregaDueConteiner,
          Telas.bloqueioDesbloqueioConteinerEmbarque,
          Telas.emissaoTicketPesagemExportacao,
          Telas.solicitacaoLiberacaoImportacao,
          Telas.solicitacaoLiberacaoCabotagem,
          Telas.recepcaoNotaFiscal,
          Telas.recepcaoConteinerTransship,
          Telas.recepcaoDocumentoTransporte],
          SubMenu: [
            {
              Id: 1,
              Name: 'Exportação',
              Path: '',
              Role: [Telas.recepcaoConteinerTransship,
              Telas.recepcaoConteiner,
              Telas.recepcaoDocumentoTransporte,
              Telas.recepcaoNotaFiscal,
              Telas.solicitacaoLiberacaoExportacao,
              Telas.liberacaoDocumentalExportacao,
              Telas.recepcaoConteiner,
              Telas.entregaDueConteiner,
              Telas.bloqueioDesbloqueioConteinerEmbarque,
              Telas.emissaoTicketPesagemExportacao],
              SubMenu: [
                {
                  Id: 1,
                  Name: 'Solicitação de Liberação',
                  Path: 'solicitacao-liberacao-exportacao',
                  Role: [Telas.solicitacaoLiberacaoExportacao],
                },
                {
                  Id: 2,
                  Name: 'Liberação Documental',
                  Path: 'liberacao-documental-exportacao',
                  Role: [Telas.liberacaoDocumentalExportacao]
                },
                {
                  Id: 3,
                  Name: 'Recepção de Contêiner',
                  Path: 'recepcao-conteiner',
                  Role: [Telas.recepcaoConteiner,
                  Telas.recepcaoConteinerTransship,
                  Telas.recepcaoDocumentoTransporte,
                  Telas.recepcaoNotaFiscal]
                },
                {
                  Id: 4,
                  Name: 'Entrega de Contêiner',
                  Path: 'entrega-due-contingencia',
                  Role: [Telas.entregaDueConteiner,
                  Telas.entregaDueDocumentoTransporte]
                },
                {
                  Id: 5,
                  Name: 'Bloqueio/Desbloqueio de Contêiner para Embarque',
                  Path: 'bloqueio-desbloqueio-conteiner-embarque',
                  Role: [Telas.bloqueioDesbloqueioConteinerEmbarque]
                },
                {
                  Id: 6,
                  Name: 'Emissão de Ticket de Pesagem para Contêiner de Exportação',
                  Path: 'emissao-ticket-pesagem-exportacao',
                  Role: [Telas.emissaoTicketPesagemExportacao],

                }
              ],
            },
            {
              Id: 2,
              Name: 'Importação',
              Path: '',
              Role: [Telas.solicitacaoLiberacaoImportacao,
                    Telas.liberacaoDocumentalImportacao],
              SubMenu: [
                {
                  Id: 2,
                  Name: 'Solicitação de Liberação',
                  Path: '/solicitacao-liberacao-importacao',
                  Role: [Telas.solicitacaoLiberacaoImportacao]
                },
                {
                  Id: 2,
                  Name: 'Liberação Documental',
                  Path: 'liberacao-documental-importacao',
                  Role: [Telas.liberacaoDocumentalImportacao]
                },
              ],
            },
            {
              Id: 3,
              Name: 'Cabotagem',
              Path: '',
              Role: [Telas.solicitacaoLiberacaoCabotagem,
              Telas.liberacaoDocumentalCabotagem],
              SubMenu: [
                {
                  Id: 3,
                  Name: 'Solicitação de Liberação',
                  Path: 'solicitacao-liberacao-cabotagem',
                  Role: [Telas.solicitacaoLiberacaoCabotagem]
                },
                {
                  Id: 2,
                  Name: 'Liberação Documental',
                  Path: 'liberacao-documental-cabotagem',
                  Role: [Telas.liberacaoDocumentalCabotagem]
                },
              ],
            },
          ],
        },
        {
          Id: 3,
          Name: 'Outros',
          Path: '',
          Role: [Telas.cabotagemEmbarque],
          SubMenu: [
            {
              Id: 3,
              Name: 'Cabotagem para Embarque',
              Path: 'cabotagem-embarque',
              Role: [Telas.cabotagemEmbarque]
            }
          ]
        }
      ]
    },
    {
      Id: 3,
      Name: 'relatórios',
      Path: '/relatorios',
      Role: [Telas.relatorioConteineresLiberados,
      Telas.relatorioEntregaDaCargaNavio,
      Telas.relatorioMovimentacaoClientes,
      Telas.relatorioReprovacaoAprovacaoLiberacao,
      Telas.relatoioRecepcaoNfDatConteiner],
      SubMenu: [
        {
          Id: 1,
          Name: 'Contêineres Liberados',
          Path: 'relatorio-conteineres-liberados',
          Role: [Telas.relatorioConteineresLiberados]
        },
        {
          Id: 2,
          Name: 'Movimentação por Cliente',
          Path: 'relatorio-movimentacao-clientes',
          Role: [Telas.relatorioMovimentacaoClientes]
        },
        {
          Id: 3,
          Name: 'Recepção de NF, DAT, Contêiner',
          Path: 'recepcao-nf-dat-conteiner',
          Role: [Telas.relatoioRecepcaoNfDatConteiner]
        },
        {
          Id: 4,
          Name: 'Entrega da Carga por Navio',
          Path: 'relatorio-entrega-da-carga-navio',
          Role: [Telas.relatorioEntregaDaCargaNavio]
        },
        {
          Id: 5,
          Name: 'Reprovações e Aprovações de Liberações',
          Path: 'reprovacao-aprovacao-liberacao',
          Role: [Telas.relatorioReprovacaoAprovacaoLiberacao],
        },
      ],
    }];
  public showMenu: boolean = true;
  isLogged: boolean = false;
  subscription: Subscription | undefined;

  constructor(private authService: AuthenticationService, private router: Router) {

  }

  ngOnInit() {
    this.updateInfo();
  }


  async signout() {
    this.isLogged = false;
    this.showMenu = false;
    await this.authService.signout();
  }


  public showMenuItem(roles: string[]): boolean {
    if (this.authService.isUsuarioRoot()) {
      return true;
    }
    const userRoles = this.authService.getRoles().find((r: any) => r.Nome.toLocaleUpperCase() == Modulo.documental.toLocaleUpperCase());
    let hasPermission = false;

    const telas = userRoles?.Telas.map((t: any) => t.Nome);
    roles.forEach(r => {
      if (telas?.find((t: any) => t == r))
        hasPermission = true;
      return;
    })
    return hasPermission;
  }

  async updateInfo() {
    if (this.isRedirectDocumental()) return;

    this.name = this.authService?.name ?? '';
    if (!this.authService.isAuthenticated() || !this.authService.verificarAcessoModulo()) {
      this.isLogged = false;
      this.showMenu = false;
    }
    else {
      this.isLogged = true;
      this.showMenu = true;

    }

      if (this.authService.isUsuarioRoot()) {
        this.isLogged = true;
        this.showMenu = true;
      }
    }
  
    public isRedirectDocumental() : boolean
    {
      if(this.authService.isRedirectDocumental()){
        this.showMenu = false;
        this.isLogged = false;
        return true;
      }
      return false;
    }

  public routerHome() {
    this.router.navigate(['home'])
  }

}

