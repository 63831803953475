<div *ngIf="numeroDUE">
    <div class="input-button mt-1">
        <input type="text" [formControl]="numeroDUE" class="form-control" [mask]="maskForDUE" [specialCharacters]="specialCharsForDUE" [ngClass]="{'border border-danger': numeroDUE.errors && numeroDUE.touched}">
        <button *ngIf="index === 0; else minusButton" type="button" [disabled]="visualizar" (click)="onAddNumeroDUE.emit()">
      &plus;
    </button>
        <ng-template #minusButton>
            <button type="button" [disabled]="visualizar" (click)="onRemoveNumeroDUE.emit()">
        &minus;
      </button>
        </ng-template>
    </div>
    <div *ngIf="numeroDUE.errors && numeroDUE.touched">
        <p class="text-danger" *ngIf="numeroDUE.errors?.['required']">
            Campo de preenchimento obrigatório!
        </p>
        <p class="text-danger" *ngIf="numeroDUE.errors?.['mask']">
            Preencher no formato correto!
        </p>
    </div>
</div>