import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private haveUpdateSource: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public haveUpdateStatus = this.haveUpdateSource.asObservable();;
  constructor(public updates: SwUpdate) {
    if (updates.isEnabled) {
      interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate()
        .then((res) => { this.haveUpdateSource.next(res) })
        .catch((err) => { this.haveUpdateSource.next(false) }))
    }
  }
  public checkForUpdates(): void {

    this.updates.checkForUpdate().then((res) => {
      this.haveUpdateSource.next(res);
      if (res) {
        this.promptUser();
      }
    })
      .catch((err) => {
        this.haveUpdateSource.next(false);
      })



    this.updates.available.subscribe(event => {
      this.promptUser();
    }
    );
  }

  private promptUser(): void {
    console.log('updating to new version');
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}