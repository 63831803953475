<td *ngIf="conteiner">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="conteiner">
  </div>
</td>
<td *ngIf="pesoApurado">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="pesoApurado" currencyMask [options]="{ prefix: '',align:'left',   thousands: '.', decimal: ',' }">
  </div>
</td>
<td *ngIf="tara">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="tara" currencyMask [options]="{ prefix: '',align:'left',   thousands: '.', decimal: ',' }">
  </div>
</td>
<td *ngIf="lacres">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="lacres">
  </div>
</td>
