<div [auth]="this.visualizacao">

    <div class="mt-5">
        <app-title [title]='title' [subtitle]='subtitle'>
        </app-title>
        <app-documento (onSendDocument)="searchSolicitation($event)" (btnValid)="btnValid($event)" (onChangeDocumentTypeOnSelect)="changeDocumentType($event)" (onSendMask)="setMaskFromDocumentSelected($event)"
          [isInsideModal]="isRequesting"
        >
        </app-documento>
        <div [hidden]="!isSearchDone">
            <app-cliente [camposFormulario]="camposFormularios" (onSearchClient)="searchClient($event)"></app-cliente>
            <app-dados-complementares-exportacao [camposFormulario]="camposFormularios"
              (dataFormOut)="{}"
              [Canais]="canais" [Moedas]="moedas"
            ></app-dados-complementares-exportacao>
            <div *ngIf="!isDocumentDAT; else gridForDATSolicitation">
              <app-relacao-conteiner-exportacao (dataFormOut)="updateArvorePartes()"
                [camposformulario]="camposFormularios" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
                [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
                (onUpdateSelectedContainers)="updateArvorePartes()"
                (onEraseConteiner)="eraseConteiner($event)"
                (onSearchShipAndTripByContainerNumber)="searchShipAndTripByContainerNumber($event)"
              ></app-relacao-conteiner-exportacao>
              <app-arvore-partes-exportacao [mascara]="maskForArvorePartes"
                [camposFormulario]="camposFormularios" (dataFormOut)="{}"
                [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
              ></app-arvore-partes-exportacao>
            </div>
            <ng-template #gridForDATSolicitation>
              <app-grid-due-conteiner-exp [canais]="canais"></app-grid-due-conteiner-exp>
            </ng-template>
            <app-documentos-anexados (onDownloadFileToDisplay)="downloadFileToDisplay($event)" (onDownloadFileToSave)="downloadFileToSave($event)" (onSendAttachedFiles)="{}"></app-documentos-anexados>
        </div>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-5 row" *ngIf="isSearchDone">
        <div class="solicitation-action-buttons action-double-buttons col-5">
            <button [auth]="salvarDocParte"
              *ngIf="!shouldBlockPartQuantityEditionByDocumentType"
              class="action-tertiary-button d-flex align-items-center justify-content-center"
              [disabled]="!isSalvarDocumentoParteEnabled"
              (click)="savePartDocument()"
            >
                <img src="assets/images/SaveDoc.png" alt="">
                <span class="ml-2">SALVAR DOCUMENTO PARTE</span>
            </button>
            <button [auth]="this.salvarRascunho" class="action-tertiary-button d-flex align-items-center justify-content-center" (click)="saveDraft()" [disabled]="!isSaveDraftButtonEnabled">
                <img src="assets/images/SaveRasc.png" alt="">
                <span class="ml-2">SALVAR RASCUNHO</span>
            </button>
        </div>
        <div class="col-1"></div>
        <div [hidden]="!divValid" class="solicitation-action-buttons action-triple-buttons col-6">
            <button class="action-tertiary-button" (click)="clearFormFields()">LIMPAR CAMPOS</button>
            <button [auth]="this.cancelar" [hidden]="!isSolicitationReceived" [disabled]="isRequesting" class="action-secondary-button" (click)="cancelSolicitation()">CANCELAR</button>
            <button [auth]="this.concluir" class="action-primary-button" [disabled]="!isConcludeButtonEnabled" (click)="save()">CONCLUIR</button>
        </div>
    </div>

    <app-integracao-documento-parte
      (onOpenModal)="isRequesting = false"
    ></app-integracao-documento-parte>

    <app-modal-ok #modalSolicitacaoPesquisadaExistente (onOkAction)="resetPage()">
      <p>{{textoSolicitacaoExistente}}</p>
    </app-modal-ok>

</div>
