<div class="mt-5">
  <app-subpage-tabs [tabs]="subpageTabs" [activeTabPath]="selectedPage" (onSelectTab)="changeSelectedPage($event)">
  </app-subpage-tabs>
  <div [ngSwitch]="selectedPage">
    <app-recepcao-nota-fiscal [auth]="visualizacaoRecepcaoNotaFiscal" *ngSwitchCase="'recepcao-nota-fiscal'">
    </app-recepcao-nota-fiscal>
    <app-recepcao-conteiner [auth]="visualizacaorecepcaoConteinerTransship" *ngSwitchCase="'recepcao-conteiner'">
    </app-recepcao-conteiner>
    <app-recepcao-documento-transporte [auth]="visualizacaorecepcaoDocumentoTransporte"
      *ngSwitchCase="'recepcao-documento-transporte'"></app-recepcao-documento-transporte>
    <app-recepcao-conteiner-transship [auth]="visualizacaorecepcaoConteinerTransship"
      *ngSwitchCase="'recepcao-conteiner-transship'"></app-recepcao-conteiner-transship>
  </div>
</div>