import { ModalDetalhesEventosRequestResponseComponent } from './../modal-detalhes-eventos-request-response/modal-detalhes-eventos-request-response.component';
import { RetornoDetalheRpaApi } from './../../../models/MonitoramentoRpaApi/RetornoDetalheRpaApi';
import { ToastService } from 'src/app/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MonitoramentoRpaApiService } from './../../../services/monitoramento-rpa-api.service';
import { GridRpaApi } from './../../../models/MonitoramentoRpaApi/GridRpaApi';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DetalheRpaApi } from 'src/app/models/MonitoramentoRpaApi/DetalheRpaApi';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import  DataLabels from 'chartjs-plugin-datalabels';
import { PesquisaRpaApi } from 'src/app/models/MonitoramentoRpaApi/PesquisaRpaApi';

@Component({
  selector: 'app-modal-detalhes-rpa-api',
  templateUrl: './modal-detalhes-rpa-api.component.html',
  styleUrls: ['./modal-detalhes-rpa-api.component.scss']
})
export class ModalDetalhesRpaApiComponent implements OnInit {

  @ViewChild('modal', {static: true}) public modal!: TemplateRef<any>;
  @ViewChild(ModalDetalhesEventosRequestResponseComponent) public eventosRequestResponse!: ModalDetalhesEventosRequestResponseComponent;
  public nomeRpaApi!: string;
  public detailsList!: DetalheRpaApi[];

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public readonly chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        onClick: () => {},
      },
      datalabels:  {
        color: '#000000',
        formatter: (value, ctx) => {
         var perc = ((value * 100) / this.detailsList.length).toFixed(0) + "%";
         return perc;
        },
        anchor: 'end',
        align: 'end'
      }
    }
  };
  public chartData!: ChartData<'pie', number[], string | string[]>;
  public readonly chartType: ChartType = 'pie';
  public chartPlugins = [DataLabels];

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private monitoramentoRpaApiService: MonitoramentoRpaApiService,
    private toastService:ToastService
  ) { }

  public ngOnInit(): void {
    this.detailsList = [];
  }

  public closeModal(): void{
    this.bsModalRef.hide();
  }

  public openModal(rpaApi: PesquisaRpaApi): void{
    this.nomeRpaApi = rpaApi.nome.split('QUEUE_TECON_')[1] ?? '';
    this.getRpaOrApiDetails(rpaApi);
    this.bsModalRef = this.bsModalService.show(this.modal, Object.assign({}, {
      class: 'modal-lg',
      ignoreBackdropClick: true
    }));
  }

  private getRpaOrApiDetails(rpaApi: PesquisaRpaApi): void{
    this.detailsList = [];
    this.monitoramentoRpaApiService.getDetailsApi(rpaApi)
      .subscribe({
        next: (result) => {
          let details = result.data as RetornoDetalheRpaApi;
          this.detailsList = details.infoDetalhes;
          this.setChartInfo(details);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = err?.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  private setChartInfo(details: RetornoDetalheRpaApi): void{
    let colors: string[] = details.graficoDetalhes.map(info => info.corStatus);
    this.chartData = {
      labels: details.graficoDetalhes.map(info => info.nomeStatus.length <= 20 ? info.nomeStatus : info.nomeStatus.substring(0, 17) + '...'),
      datasets: [{
        data: this.filterChartDataByStatus(details),
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        hoverBorderColor: colors,
        animation: false
      }]
    };
    this.chart?.update();
  }

  private filterChartDataByStatus(details: RetornoDetalheRpaApi): number[]{
    let statusNames: string[] = details.graficoDetalhes.map(info => info.nomeStatus);
    let chartData: number[] = [];
    for(let status: number = 0; status < statusNames.length; status++){
      chartData.push(details.infoDetalhes.filter(
        (detail) => detail.status == statusNames[status]
      ).length);
    }
    return chartData;
  }

  public openModalForRequestAndresponseEvents(rpaApi: DetalheRpaApi): void{
    this.eventosRequestResponse.openModal(rpaApi);
    // this.monitoramentoRpaApiService.getDetailsForRpaOrApi(rpaApi)
    //   .subscribe({
    //     next: (result) => {
    //     },
    //     error: (err: HttpErrorResponse) => {
    //       console.log(err);
    //       let errorMessage = err?.error as ErrorResponse;
    //       if(errorMessage?.errors?.length){
    //         errorMessage?.errors.forEach(
    //           (error) => {
    //             if(error?.length){
    //               this.toastService.error(error);
    //             }
    //           }
    //         );
    //       }
    //     }
    //   });
  }

}
