<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Solicitações</span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>

    <app-modal-confirmar-liberacao #modalConfirmarLiberacaoJustificativa
      [modalTitle]="'Confirmar'"
      [negativeText]="'Cancelar'"
      [positiveText]="'Confirmar'"
      (onChoosePositiveAction)="confirmarLiberacao($event)"
    ></app-modal-confirmar-liberacao>

    <app-modal-resposta-positiva-negativa #modalConfirmarLiberacaoAlternativa
      [modalTitle]="'Confirmar'"
      [negativeText]="'Cancelar'"
      [positiveText]="'Confirmar'"
      (onChoosePositiveAction)="confirmarLiberacao('')"
    >
      <p>{{textForConfirmation}}</p>
    </app-modal-resposta-positiva-negativa>

    <app-modal-recusaAutorizacao-supervisor #modalRecusaSupervisor
      [modalTitle]="'Confirmar Recusa Supervisor'"
      [negativeText]="'Cancelar'"
      [positiveText]="'Confirmar'"
      (onChoosePositiveAction)="RecusarSupervisorEndpoint($event)"
    >
    </app-modal-recusaAutorizacao-supervisor>

    <app-modal-recusaAutorizacao-supervisor #modalAutorizacaoSupervisor [modalTitle]="'Confirmar Autorização Supervisor'" [negativeText]="'Cancelar'" [positiveText]="'Confirmar'" (onChoosePositiveAction)="AutorizarSupervisorEndpoint($event)">
    </app-modal-recusaAutorizacao-supervisor>

    <app-modal-recusa-liberacao #modalConfirmarRecusa [modalTitle]="'Confirmar Recusa'" [negativeText]="'Cancelar'" [positiveText]="'Confirmar'" [optionalParam]="'Liberacaodocumental'" (onChoosePositiveAction)="EndpointRecusa($event)">
        <div *ngIf="ImpedimentosLiberacao?.length">
            <label>Impedimentos Encontrados:</label>
            <ul>
                <li *ngFor="let impedimento of ImpedimentosLiberacao">{{impedimento}}</li>
            </ul>
        </div>
    </app-modal-recusa-liberacao>

    <app-modal-cancelar-liberacao #modalCancelarLiberacao [modalTitle]="'Cancelar Liberação'" [negativeText]="'Cancelar'" [positiveText]="'Confirmar'" (onChoosePositiveAction)="CancelarEndpoint($event)"></app-modal-cancelar-liberacao>

    <div id="cardCollapse" class="card-body zindex-tooltip" [collapse]="isCollapsed" [isAnimated]="true">
        <div class="table-container">
            <table class="table table-bordered text-nowrap">
                <thead class="thead-light">
                    <tr>
                        <th>Documento</th>
                        <th>Navio/Viagem</th>
                        <th>Cliente</th>
                        <th>Regime de Circulação</th>
                        <th>Status</th>
                        <th>Data da Solicitação</th>
                        <th>Data da Liberação</th>
                        <th>Data da Recusa</th>
                        <th>Data da Autorização</th>
                        <th>justificativa</th>
                        <th>Usuário interno</th>
                        <th>Solicitante</th>
                        <th>Ciclo da Solicitação</th>
                        <th>CPF do Recebedor</th>
                        <th>Nome do Recebedor</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let solicitation of solicitations; index as i" (click)="trClick(solicitation.id)" [ngClass]="selectedRowId == solicitation.id ? 'selected-row' : ''">
                        <td>{{solicitation.documento?.tipoDocumento}} - {{solicitation.documento?.numeroDocumento}}</td>
                        <td>{{solicitation.dadosComplementares?.navio}} - {{solicitation.dadosComplementares?.viagem}}</td>
                        <td>{{solicitation.cliente?.cpfCnpj ?? '' | mask : '00.000.000/0000-00||000.000.000-00'}} - {{solicitation.cliente?.nomeCliente}}
                        </td>
                        <td>{{solicitation.regimeCirculacao?.nomeRegimeCirculacao}}</td>
                        <td>{{solicitation.status}}</td>
                        <td>{{solicitation?.propriedadesGridLiberacao?.dataSolicitacao | date: 'dd/MM/yyyy HH:mm:ss' : 'UTC-3'}}</td>
                        <td>{{solicitation?.propriedadesGridLiberacao?.dataLiberacao | date: 'dd/MM/yyyy HH:mm:ss' : 'UTC-3'}}</td>
                        <td>{{solicitation?.propriedadesGridLiberacao?.dataRecusa | date: 'dd/MM/yyyy HH:mm:ss' : 'UTC-3'}}</td>
                        <td>{{solicitation?.propriedadesGridLiberacao?.dataAutorizacao | date: 'dd/MM/yyyy HH:mm:ss' : 'UTC-3'}}</td>
                        <td>
                            {{shortenTextForJustificationDisplay(solicitation)}}
                            <a class="see-more-link" *ngIf="getJustificationText(solicitation).length" (click)="openModalForJustificationDisplay(solicitation)">
              Ver mais
            </a>
                        </td>
                        <td>{{solicitation.propriedadesGridLiberacao?.usuarioInterno}}</td>
                        <td>{{solicitation?.propriedadesGridLiberacao?.solicitante}}</td>
                        <td>{{solicitation?.propriedadesGridLiberacao?.cicloSolicitacao}}</td>
                        <td>{{solicitation.propriedadesGridLiberacao?.cpfRecebedor ?? '' | mask : '000.000.000-00'}}</td>
                        <td>{{solicitation.propriedadesGridLiberacao?.nomeRecebedor}}</td>
                        <td>
                            <div dropdown [dropup]="true" [placement]="'top'" class="iframe-fix" [container]="'body'">
                                <a bsDropdownToggle><i class="fa fa-ellipsis-v"></i></a>
                                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                                    <li [auth]="this.visualizacao" class="menu-item">
                                        <a class="dropdown-item" (click)="visualizar(solicitation)">Visualizar</a>
                                    </li>
                                    <li *ngIf="isConsultaDeBloqueioButtonEnabled(solicitation)" [auth]="this.consultaBloqueio" class="menu-item">
                                        <a class="dropdown-item" (click)="abrirConsultaBloqueio(solicitation)">Consulta de Bloqueio</a>
                                    </li>
                                    <li *ngIf="isLiberarButtonEnabled(solicitation) && this.ehUsuarioInterno" [auth]="this.liberacao" class="menu-item">
                                        <a class="dropdown-item" (click)="liberar(solicitation)">Liberar</a>
                                    </li>
                                    <li *ngIf="isRecusarButtonEnabled(solicitation) && this.ehUsuarioInterno" [auth]="this.recusar" class="menu-item">
                                        <a class="dropdown-item" (click)="Recusar(solicitation)">Recusar</a>
                                    </li>
                                    <li *ngIf="isCancelarLiberacaoButtonEnabled(solicitation) && this.ehUsuarioInterno" [auth]="this.cancelar" class="menu-item">
                                        <a class="dropdown-item" (click)="Cancelar(solicitation)">Cancelar Liberação</a>
                                    </li>
                                    <li *ngIf="isAutorizacaoSupervisorButtonEnabled(solicitation) && this.ehUsuarioInterno" [auth]="this.autorizacaoSurpevisor" class="menu-item">
                                        <a class="dropdown-item" (click)="AutorizarSupervisor(solicitation)">Autorização Supervisor</a>
                                    </li>
                                    <li *ngIf="isRecusaSupervisorButtonEnabled(solicitation) && this.ehUsuarioInterno" [auth]="this.recusaSurpervisor" class="menu-item">
                                        <a class="dropdown-item" (click)="RecusaSupervisor(solicitation)">Recusa Supervisor</a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<app-consulta-bloqueio #modalConsultaBloqueio
  (onErroIntegracao)="displayIntegrationError($event)"
></app-consulta-bloqueio>

<ng-template #modalVisualizarJustificativa>
    <div class="modal-header">
        <h4>Justificativa</h4>
        <button class="btn-close close pull right"><span class="visualli-hidden"
      (click)="closeModal()">&times;</span></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <p class="text-justify">{{justificativaSalva}}</p>
            </div>
        </div>
        <div *ngIf="ImpedimentosLiberacao?.length" class="row">
            <div class="col">
                <label>Impedimentos Encontrados:</label>
                <ul>
                    <li *ngFor="let impedimento of ImpedimentosLiberacao">{{impedimento}}</li>
                </ul>
            </div>
        </div>
    </div>
</ng-template>

<app-modal-mensagem #modalErroIntegracao></app-modal-mensagem>
