import { GridRpaApi } from './../../../models/MonitoramentoRpaApi/GridRpaApi';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TipoRpaApi } from 'src/app/models/MonitoramentoRpaApi/TipoRpaApi.enum';
import { StatusRPA } from 'src/app/models/MonitoramentoRpaApi/StatusRPA.enum';
import { MonitoramentoRpaApiService } from 'src/app/services/monitoramento-rpa-api.service';
import { race } from 'rxjs';
import { PesquisaRpaApi } from 'src/app/models/MonitoramentoRpaApi/PesquisaRpaApi';

@Component({
  selector: 'app-grid-monitoramento-rpa-api',
  templateUrl: './grid-monitoramento-rpa-api.component.html',
  styleUrls: ['./grid-monitoramento-rpa-api.component.scss']
})
export class GridMonitoramentoRpaApiComponent implements OnInit {

  public rpaApiPesquisa!: PesquisaRpaApi;
  public rpaApiList!:GridRpaApi[]
  @Output() public onClickForDetails: EventEmitter<PesquisaRpaApi> = new EventEmitter<PesquisaRpaApi>();


  constructor(
    private monitoramentoRpaApiService: MonitoramentoRpaApiService,

  ) { }

  public ngOnInit(): void {
    this.rpaApiList = [];
  }

  public setRpaApiList(rpaApiList: GridRpaApi[], rpaApiPesquisa: PesquisaRpaApi): void {
    this.rpaApiList = [];
    if(!rpaApiList || !rpaApiList.length){
      return;
    }
    this.rpaApiList = rpaApiList;
    this.rpaApiPesquisa = rpaApiPesquisa;
  }

  public searchDetails(rpaApi: GridRpaApi): void {
    this.rpaApiPesquisa.nome = rpaApi.nomeFila;
    this.onClickForDetails.emit(this.rpaApiPesquisa);
  }

  public rpaIsAtive(value: GridRpaApi): boolean {
    return value.status === StatusRPA.ATIVO ? true : false;
  }

  public isRpa(value: GridRpaApi): boolean {
    return value.tipo === TipoRpaApi.RPA;
  }

  public getTipoDesc(value: GridRpaApi): string {
    return TipoRpaApi[value.tipo];
  }

  public getStatusDesc(value: GridRpaApi): string {
    return StatusRPA[value.status];
  }


}
