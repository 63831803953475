import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Campo } from 'src/app/models/Campo';
import { ConteinerEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/ConteinerEstimativaCalculo';
import { DocumentoParteEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/DocumentoParteEstimativaCalculo';
import { Mascara } from 'src/app/models/Mascara';
import { DocumentosService } from 'src/app/services/documentos.service';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { LinhaGridArvorePartesEstimativaCalculoComponent } from '../linha-grid-arvore-partes-estimativa-calculo/linha-grid-arvore-partes-estimativa-calculo.component';
import { documentTypesToBlockPartQuantityExp, documentTypesToBlockPartQuantityImp } from 'src/app/shared/utils/document-types-to-block-part-quantity';

@Component({
  selector: 'app-arvore-partes-estimativa-calculo',
  templateUrl: './arvore-partes-estimativa-calculo.component.html',
  styleUrls: ['./arvore-partes-estimativa-calculo.component.scss']
})
export class ArvorePartesEstimativaCalculoComponent implements OnInit {

  public isCollapsed = false;
  public read: string = '';
  public arvorePartesList!: DocumentoParteEstimativaCalculo[];
  public storedPartDocuments!: DocumentoParteEstimativaCalculo[] | undefined;
  public arePartDocumentsSameAsStored!: boolean;
  public arvoreParteReadonly: boolean = false;

  @Input() mascara!: Mascara;
  @Input() camposFormulario!: Campo[];
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() isImpDocument!: boolean;
  @Input() isExpDocument!: boolean;
  @Input() shouldBlockPartQuantityEditionByDocumentType?: boolean;
  @Output() dataFormOut = new EventEmitter<{}>();
  @Output() onArvorePartesLoad: EventEmitter<void> = new EventEmitter<void>();

  @ViewChildren(LinhaGridArvorePartesEstimativaCalculoComponent) documentosPartes!: QueryList<LinhaGridArvorePartesEstimativaCalculoComponent>;

  constructor(
    private fb: UntypedFormBuilder,
    private documentosService: DocumentosService,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngAfterContentInit(): void {
    this.onArvorePartesLoad.emit();
  }

  ngOnInit() {
    this.arvorePartesList = [];
    this.dataFormOut.emit({ "arvorePartes": [] });
  }


  // ngOnChanges(changes: SimpleChanges) {
  //   for (const change in changes) {
  //     const item = changes[change];
  //     if (item['currentValue'] != undefined && this.form != undefined) {
  //       const itemForm = item['currentValue'];
  //       this.form.patchValue(itemForm);
  //       if (this.form.value.numDoc != '') this.read = 'readonly';
  //     }
  //   }
  // }

  public clean() {
    this.arvorePartesList = [];
  }

  public setFormValuesFromArvorePartes(arvorePartesList: DocumentoParteEstimativaCalculo[] | undefined): void {
    if (!arvorePartesList) {
      return;
    }
    if(this.arvoreParteReadonly){
      return;
    }
    this.arvorePartesList = [...arvorePartesList];
    this.sendArvorePartesData();
    this.changeDetector.detectChanges();
    this.checkIfPartDocumentsSameAsStored();
  }

  public setArvorePartesValuesFromContainers(conteineres: ConteinerEstimativaCalculo[] | undefined): void {
    this.arvorePartesList = [];
    if (!conteineres?.length) {
      return;
    }
    let documentosParte: DocumentoParteEstimativaCalculo[] = [];
    conteineres.forEach(
      (conteiner) => {
        if(conteiner.documentos?.length){
          conteiner.documentos.forEach(
            (documento) => {
              documentosParte.push({
                id: documento.id,
                numeroDocumentoParte: documento.numeroDocumentoParte,
                conteiner: documento.conteiner ? documento.conteiner : conteiner.conteiner,
                numeroCE: documento.numeroCE,
                parteCadastrada: !!documento.parteCadastrada || !!documento.numeroDocumentoParte?.length,
                quantidadePartes: documento.quantidadePartes
              });
            }
          );
        }
      }
    );
    this.arvorePartesList = [...documentosParte] ?? [];
    this.storedPartDocuments = documentosParte?.length ? [...documentosParte] : undefined;
    this.sendArvorePartesData();
    this.changeDetector.detectChanges();
    this.checkIfPartDocumentsSameAsStored();
  }

  public addPartDocumentsToGrid(arvorePartesList: DocumentoParteEstimativaCalculo[] | undefined): void {
    if (!arvorePartesList?.length) {
      return;
    }
    this.arvorePartesList.push(...arvorePartesList);
    this.sendArvorePartesData();
    this.changeDetector.detectChanges();
  }

  public removePartDocumentsFromGrid(itemsToRemove: number | undefined): void {
    if (!itemsToRemove) {
      return;
    }
    while(itemsToRemove > 0){
      this.arvorePartesList.pop();
      --itemsToRemove;
    }
    this.sendArvorePartesData();
    this.changeDetector.detectChanges();
  }

  public sendArvorePartesData(): void {
    this.checkIfPartDocumentsSameAsStored();
    if (!this.documentosPartes?.length) {
      this.dataFormOut.emit({ "arvorePartes": [] });
      return;
    }
    this.dataFormOut.emit({ "arvorePartes": this.getArvorePartesFromForm() });
  }

  public updateArvoreParte(documentoParte: DocumentoParteEstimativaCalculo | undefined, index: number): void {
    this.sendArvorePartesData();
  }

  public getArvorePartesFromForm(): DocumentoParteEstimativaCalculo[] {
    return this.documentosPartes?.map(
      (parte) => parte.getDocumentoParteFromFormFields()
    ) ?? [];
  }

  public get areAllDocumentoParteLinesValid(): boolean {
    if (!this.documentosPartes?.length) {
      return true;
    }
    return this.documentosPartes?.map(
      (documentoParte) => documentoParte.isDocumentoParteValid()
    ).every(line => line);
  }

  public erasePartDocumentByFailedIntegrationNumber(documentNumber: string): void {
    this.documentosPartes.forEach((documentoParte, index) => {
      if (this.verificacaoDocumentosService.eraseMask(documentoParte?.getDocumentoParteFromFormFields()?.numeroDocumentoParte) == this.verificacaoDocumentosService.eraseMask(documentNumber)) {
        this.arvorePartesList.splice(index, 1);
      }
    }

    );
  }

  public setSettingsfromFields(): void{
    if(!this.camposFormulario?.length){
      this.documentosPartes.forEach(
        (documentoParte) => {
          documentoParte.enableAllFormFields();
        }
      );
      return;
    }
    let arvoreParteReadonly: Campo | undefined = this.camposFormulario?.find(campo => campo.nome == 'arvoreParte');
    this.arvoreParteReadonly = !!arvoreParteReadonly?.leitura || !!arvoreParteReadonly?.bloqueado;
    this.changeDetector.detectChanges();
    this.documentosPartes.forEach(
      (documentoParte) => {
        documentoParte.setSettingsfromFields();
      }
    );
    this.changeDetector.detectChanges();
  }

  public markEveryLineAstouched(): void{
    this.documentosPartes?.forEach(
      (documentoParte) => {
        documentoParte?.formDocumentoParte?.markAllAsTouched();
      }
    );
  }

  public checkIfPartDocumentsSameAsStored(): void{
    let savedPartDocuments = this.storedPartDocuments ? [...this.storedPartDocuments] : [];
    let currentPartDocuments = this.getArvorePartesFromForm() ?? [];
    this.arePartDocumentsSameAsStored = savedPartDocuments.every(
      saved => currentPartDocuments?.find(
        current => {
          return (saved.id ?? '') == (current.id ?? '') &&
            (saved.parteCadastrada ?? '') == (current.parteCadastrada ?? '') &&
            (saved.numeroDocumentoParte ?? '') == (current.numeroDocumentoParte ?? '') &&
            (saved.conteiner ?? '') == (current.conteiner ?? '') &&
            (saved.quantidadePartes ?? '') == (current.quantidadePartes ?? '') &&
            (saved.numeroCE ?? '') == (current.numeroCE ?? '');
        }
      )
    ) && currentPartDocuments?.every(
      current => savedPartDocuments.find(
        saved => {
          return (saved.id ?? '') == (current.id ?? '') &&
            (saved.parteCadastrada ?? '') == (current.parteCadastrada ?? '') &&
            (saved.numeroDocumentoParte ?? '') == (current.numeroDocumentoParte ?? '') &&
            (saved.conteiner ?? '') == (current.conteiner ?? '') &&
            (saved.quantidadePartes ?? '') == (current.quantidadePartes ?? '') &&
            (saved.numeroCE ?? '') == (current.numeroCE ?? '');
        }
      )
    );
    this.changeDetector.detectChanges();
  }


}
