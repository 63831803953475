<div [auth]="this.visualizacao">

    <div class="mt-5">
        <app-title [title]='title'></app-title>
        <app-documento-transporte (onSearchContainer)="searchContainerByContainerNumber($event)"></app-documento-transporte>
        <app-dados-documento-transporte-n4></app-dados-documento-transporte-n4>
        <app-dados-documento-transporte-cct></app-dados-documento-transporte-cct>
        <app-dados-envio-transporte></app-dados-envio-transporte>
    </div>
    <div class="d-flex justify-content-center align-content-center mt-5">
        <div class="solicitation-action-buttons action-double-buttons">
            <button class="action-secondary-button" (click)="clean()">LIMPAR CAMPOS</button>
            <button [auth]="this.enviarCCT" (click)="checkDataSendingToCCT()" class="action-primary-button" [disabled]="blockSending || !areFormsValid">ENVIAR AO CCT</button>
            <button [auth]="this.salvar" class="action-primary-button" (click)="save()" [disabled]="blockSending || !areFormsValid" style="display: none;">SALVAR</button>
        </div>
    </div>

</div>

<app-modal-resposta-positiva-negativa #modalConfirmarEnvioComDivergencias [modalTitle]="'Divergências encontradas'" [negativeText]="'Recusar'" [positiveText]="'Confirmar'" (onChoosePositiveAction)="sendDataToCCT()">
    <div class="row mb-3">
        <div class="col">
          <p>Foi(ram) encontrada(s) a(s) seguinte(s) divergência(s):</p>
          <ul>
            <li *ngFor="let mensagem of mensagensDivergencia">{{mensagem}}</li>
          </ul>
          <p>Confirma o envio ao CCT?</p>
        </div>
    </div>
</app-modal-resposta-positiva-negativa>
