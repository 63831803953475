import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as signalR from '@microsoft/signalr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  ExtrairDadosSiscomexComponent,
} from 'src/app/components/modais/extrair-dados-siscomex/extrair-dados-siscomex.component';
import {
  IntegracaoDocumentoParteComponent,
} from 'src/app/components/modais/integracao-documento-parte/integracao-documento-parte.component';
import { Permissoes } from 'src/app/contants/permissoes';
import { Telas } from 'src/app/contants/telas';
import { AuthRoles } from 'src/app/models/authRoles';
import { Campo } from 'src/app/models/Campo';
import { Cliente } from 'src/app/models/Cliente';
import { DocumentoCabotagem } from 'src/app/models/DocumentoCabotagem';
import { ArquivoDocumentoAnexado } from 'src/app/models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { IntegracaoSiscomex } from 'src/app/models/IntegracaoSignalR/IntegracaoSiscomex';
import { RetornoStatusDocumentoParteDTO } from 'src/app/models/IntegracaoSignalR/RetornoStatusDocumentoParteDTO';
import { NomeDespachante } from 'src/app/models/LiberacaoDocumental/NomeDespachante';
import { Mascara } from 'src/app/models/Mascara';
import {
  CadastroDocumentoParteCabotagemDTO,
} from 'src/app/models/SolicitacaoLiberacaoCabotagem/CadastroDocumentoParteCabotagemDTO';
import { ConteinerCabotagem } from 'src/app/models/SolicitacaoLiberacaoCabotagem/ConteinerCabotagem';
import { DadosNavioVigemDTO } from 'src/app/models/SolicitacaoLiberacaoCabotagem/DadosNavioVigemDTO';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ToastService } from 'src/app/services/toast.service';
import { ClienteComponent } from 'src/app/shared/components/cliente/cliente.component';
import { DocumentoCabotagemComponent } from 'src/app/shared/components/documento-cabotagem/documento-cabotagem.component';
import { ModalOKComponent } from 'src/app/shared/components/modais/modal-ok/modal-ok.component';
import { documentTypesToBlockPartQuantityCab } from 'src/app/shared/utils/document-types-to-block-part-quantity';
import { TimeConstants } from 'src/app/shared/utils/time-constants';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';

import { Moeda } from 'src/app/models/Moeda';
import { ErrorResponse } from '../../models/HttpResponses/ErrorResponse';
import { SolicitacaoLiberacaoCabotagem } from '../../models/SolicitacaoLiberacaoCabotagem/SolicitacaoLiberacaoCabotagem';
import { XmlComponent } from '../../shared/components/xml/xml.component';
import {
  ArvorePartesCabotagemComponent,
} from './../../components/solicitacao-liberacao-cabotagem/arvore-partes-cabotagem/arvore-partes-cabotagem.component';
import {
  DadosComplementaresCabotagemComponent,
} from './../../components/solicitacao-liberacao-cabotagem/dados-complementares-cabotagem/dados-complementares-cabotagem.component';
import {
  RelacaoConteinerCabotagemComponent,
} from './../../components/solicitacao-liberacao-cabotagem/relacao-conteiner-cabotagem/relacao-conteiner-cabotagem.component';
import {
  RelacaoNotasFiscaisComponent,
} from './../../components/solicitacao-liberacao-cabotagem/relacao-notas-fiscais/relacao-notas-fiscais.component';
import { DocumentoParteCabotagem } from './../../models/SolicitacaoLiberacaoCabotagem/DocumentoParteCabotagem';
import { SolicitacaoLiberacaoCabotagemService } from './../../services/solicitacao-liberacao-cabotagem.service';
import {
  DocumentosAnexadosComponent,
} from './../../shared/components/DocumentosAnexados/documentos-anexados/documentos-anexados.component';

@Component({
  selector: 'app-solicitacao-liberacao-cabotagem',
  templateUrl: './solicitacao-liberacao-cabotagem.component.html',
  styleUrls: ['./solicitacao-liberacao-cabotagem.component.scss']
})
export class SolicitacaoLiberacaoCabotagemComponent implements OnInit, AfterViewInit {

  public title: string = 'Solicitação de Liberação de Cabotagem';
  public subtitle: string = 'Adicione abaixo os dados necessários para o documento de solicitação.';
  public modalRef!: BsModalRef;
  public isSearchDone: boolean = false;
  public formOut!: UntypedFormGroup;
  public modelSolicitacao!: SolicitacaoLiberacaoCabotagem;
  public camposFormularios: Campo[] = [];
  public isSolicitationReceived = false;
  public integrationErrors: string[] = [];
  public doesSolicitationHaveIntegration: boolean = true;
  public blockSending: boolean = true;
  public isPartDocumentSaved: boolean = false;
  public textoSolicitacaoExistente!: string;
  public documentTypeSelected: string = '';
  protected moedas: Moeda[] = [];

  private readonly documentTypesToBlockPartQuantityEdition: string[] = [...documentTypesToBlockPartQuantityCab];

  @ViewChild(DocumentoCabotagemComponent) documento!: DocumentoCabotagemComponent;
  @ViewChild(XmlComponent) xml!: XmlComponent;
  @ViewChild(ClienteComponent) cliente!: ClienteComponent;
  @ViewChild(DadosComplementaresCabotagemComponent) dados!: DadosComplementaresCabotagemComponent;
  @ViewChild(RelacaoConteinerCabotagemComponent) relacao!: RelacaoConteinerCabotagemComponent;
  @ViewChild(RelacaoNotasFiscaisComponent) notasFiscais!: RelacaoNotasFiscaisComponent;
  @ViewChild(ArvorePartesCabotagemComponent) arvore!: ArvorePartesCabotagemComponent;
  @ViewChild(DocumentosAnexadosComponent) documentosAnexosLiberacao!: DocumentosAnexadosComponent;
  @ViewChild(ExtrairDadosSiscomexComponent) modalExtrairDadosSiscomex!: ExtrairDadosSiscomexComponent;
  @ViewChild(IntegracaoDocumentoParteComponent) modalIntegracaoDocumentoParte!: IntegracaoDocumentoParteComponent;
  @ViewChild('modalAlertaErrosIntegracao') modalAlertaErrosIntegracao!: TemplateRef<any>;
  @ViewChild('modalSolicitacaoPesquisadaExistente') modalSolicitacaoPesquisadaExistente!: ModalOKComponent;

  public maskForArvorePartes!: Mascara;
  private arvorePartesSalva: DocumentoParteCabotagem[] = [];
  private numerosConteineresSalvos: string[] = [];

  public isRequesting: boolean = false;


  
    private signalRConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${environment.apiUrlForSolicitacaoLiberacaoCabotagemHub}/solicitacaoCabotagemHub`)
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Debug)
    .build();
  
  private timeoutForSignalRConnection!: any;

  public get isSalvarDocumentoParteEnabled(): boolean {
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      return false;
    }
    return !this.isRequesting &&
      !!this.relacao?.getSelectedContainersFromForms()?.length &&
      !!this.arvore?.getPartDocumentsFromForm()?.length &&
      this.arvore?.areAllDocumentoParteLinesValid() &&
      !this.arvore.arePartDocumentsSameAsStored;
  }
  public readonly visualizacao = new AuthRoles(Telas.solicitacaoLiberacaoCabotagem, Permissoes.visualizacao)
  public readonly salvarRascunho = new AuthRoles(Telas.solicitacaoLiberacaoCabotagem, Permissoes.salvarRascunho)
  public readonly concluir = new AuthRoles(Telas.solicitacaoLiberacaoCabotagem, Permissoes.concluir)
  public readonly salvarDocParte = new AuthRoles(Telas.solicitacaoLiberacaoCabotagem, Permissoes.salvarDocParte)
  public readonly cancelar = new AuthRoles(Telas.solicitacaoLiberacaoCabotagem, Permissoes.cancelar)
  constructor(
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private changeDetector: ChangeDetectorRef,
    private modalService: BsModalService,
    private toastService: ToastService,
    private solicitacaoLiberacaoCabotagemService: SolicitacaoLiberacaoCabotagemService,
    private documentosService: DocumentosService,
    private authentication: AuthenticationService
  ) { }

  public ngOnInit(): void {
    this.validation();
    this.getSelectFieldsForFormComponents();
  }

  public ngAfterViewInit(): void {
    this.getDocuments();
  }

  private validation(): void {
    this.formOut = this.fb.group({
      id: [null],
      documento: [null, [Validators.required]],
      cliente: [null],
      dadosComplementares: [null],
      relacaoConteineres: [null],
      relacaoNotasFiscais: [null],
      arvorePartes: [null],
      documentosAnexosLiberacao: [null]
    });
  }

  public hideForms(): void {
    this.isSearchDone = false;
  }

  public getXmlData(event: any): void {
    let documento = this.documento?.getCabotageDocumentFromForm();
    this.clearForms(true);
    let numeroConteiner: string = '';
    if (event.cteProc) {
      let xmlValue = this.extractValuesFromCTEXml(event);
      xmlValue.numeroCE = documento?.numeroCE;
      this.documento?.setFormValuesFromCTEXML(xmlValue);
      this.cliente.setFormValuesFromCTEXML(xmlValue);
      this.dados?.setFormValuesFromCTEXML(xmlValue);
      numeroConteiner = this.extractContainerNumberFromThreeDotZeroCTEXml(event);
    }
    else if (event.nfeProc) {
      this.documento?.setFormValuesFromNFXML(event);
      this.cliente.setFormValuesFromNFXML(event);
      this.dados?.setFormValuesFromNFXML(event);
    }
    else {
      this.toastService.error("Arquivo XML é inválido. Favor verificar!");
      return;
    }
    this.isSearchDone = true;
    this.blockSending = false;
    let documentoPreenchido = this.documento?.getCabotageDocumentFromForm();
    if (documentoPreenchido?.tipoDocumento === 'CTE') {
      if (numeroConteiner?.length) {
        this.searchNumeroCEByContainer(numeroConteiner);
      }
      else {
        this.toastService.error("Número de contêiner não encontrado no XML!");
        this.clearFormFields();
        this.hideForms();

      }
    }
    else {
      this.getIntegrationsFromXML();
    }
  }

  private getTelefoneUsuarioExterno() {
    this.solicitacaoLiberacaoCabotagemService.getTelefoneUsuarioExterno() .subscribe({
      next: (result) => {
        console.log(result.data);
        this.dados.setTelefoneContatoEmail(result.data);
        this.changeDetector.detectChanges();
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      }
    });
  }

  private getIntegrationsFromXML(): void {
    this.isRequesting = true;
    let correlationId: string = uuid.v4();
    this.spinner.show();
    this.documentosAnexosLiberacao.setAttachedDocumentTypes(this.documento?.getCabotageDocumentFromForm()?.id!, [], this.solicitacaoLiberacaoCabotagemService.getAttachmentDocumentsForCabotageLiberationByDocumentType());
    this.solicitacaoLiberacaoCabotagemService.getIntegrationsFromXML(this.getSolicitationFromForms(), correlationId)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          let solicitation: SolicitacaoLiberacaoCabotagem = result.data as SolicitacaoLiberacaoCabotagem;
          this.doesSolicitationHaveIntegration = !!solicitation?.statusIntegracao?.length;
          this.changeDetector.detectChanges();
          if (solicitation) {
            this.setFormValuesFromReceivedCabotageSolicitation(solicitation);
          }
          this.setFieldSettings();
          this.getTelefoneUsuarioExterno();
          this.isRequesting = false;
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse)?.errors ?? [];
          if (errorMessage?.length) {
            (err?.error as ErrorResponse)?.errors?.forEach(
              (error) => {
                this.toastService.error(error);
              }
            );
          }
          if (err.status == 404) {
            let error = err.error as string;
            if (typeof (error) === 'string') {
              this.textoSolicitacaoExistente = error;
              this.modalSolicitacaoPesquisadaExistente.openModalForOk();
            }
            else {
              let integrations: IntegracaoSiscomex[] = (error as ErrorResponse)?.data as IntegracaoSiscomex[];
              if (integrations?.length) {
                console.log('Método de anexo xml')
                console.log(integrations)
                this.doesSolicitationHaveIntegration = true;
                this.changeDetector.detectChanges();
                this.openSiscomexDataModal(integrations, integrations[0].correlationId);
              }
              else {
                this.doesSolicitationHaveIntegration = false;
                this.changeDetector.detectChanges();
                this.isRequesting = false;
              }
              this.setFieldSettings();
            }
          }
          this.setFieldSettings();
        }
      });
    this.getFormSettingsByDocumentType(this.documento?.getCabotageDocumentFromForm()?.tipoDocumento);
    this.getTelefoneUsuarioExterno();
  }

  public confirmCreateCabotageSolicitationFromExistingDocumentXml(): void {
    let correlationId: string = uuid.v4();
    this.isRequesting = true;
    this.spinner.show();
    this.documentosAnexosLiberacao.setAttachedDocumentTypes(this.documento?.getCabotageDocumentFromForm()?.id!, [], this.solicitacaoLiberacaoCabotagemService.getAttachmentDocumentsForCabotageLiberationByDocumentType());
    this.solicitacaoLiberacaoCabotagemService.confirmCreateSolicitationFromXML(this.getSolicitationFromForms(), correlationId)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          let solicitation: SolicitacaoLiberacaoCabotagem = result.data as SolicitacaoLiberacaoCabotagem;
          this.doesSolicitationHaveIntegration = !!solicitation?.statusIntegracao?.length;
          this.changeDetector.detectChanges();
          if (solicitation) {
            this.setFormValuesFromReceivedCabotageSolicitation(solicitation);
          }
          this.setFieldSettings();
          this.getTelefoneUsuarioExterno();
          this.isRequesting = false;
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse)?.errors ?? [];
          if (errorMessage?.length) {
            (err?.error as ErrorResponse)?.errors?.forEach(
              (error) => {
                this.toastService.error(error);
              }
            );
          }
          if (err.status == 404) {
            let data = (err.error as ErrorResponse)?.data;
            let integrations: IntegracaoSiscomex[] = data as IntegracaoSiscomex[];
            this.getTelefoneUsuarioExterno();
            if (integrations?.length) {
              this.doesSolicitationHaveIntegration = true;
              this.changeDetector.detectChanges();
              this.openSiscomexDataModal(integrations, integrations[0].correlationId);
            }
            else {
              this.doesSolicitationHaveIntegration = false;
              this.changeDetector.detectChanges();
              this.isRequesting = false;
            }
            this.setFieldSettings();
          }
          this.setFieldSettings();
        }
      });
    this.getFormSettingsByDocumentType(this.documento?.getCabotageDocumentFromForm()?.tipoDocumento);
    this.getTelefoneUsuarioExterno();
  }

  private searchNumeroCEByContainer(numeroConteiner: string): void {
    this.isRequesting = true;
    this.solicitacaoLiberacaoCabotagemService?.getNumeroCEByContainerNumber(numeroConteiner)
      .subscribe({
        next: (result) => {
          let numeroCE: string = result.data;
          if (numeroCE?.length) {
            this.documento?.setFormValuesFromCTEXML({ numeroCE });
            this.changeDetector?.detectChanges();
            this.getIntegrationsFromXML();
          }
          else {
            this.toastService.error("Número de contêiner não encontrado no N4!");
            this.clearFormFields();
            this.hideForms();
            this.isRequesting = false;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if (errorMessage?.errors?.length) {
            errorMessage?.errors?.forEach(
              (error) => {
                if (error?.length) {
                  this.toastService.error(error);
                }
              }
            );
          }
          this.isRequesting = false;
          this.clearFormFields();
          this.hideForms();
        }
      })
  }

  public searchCabotageSolicitationByButton(documento: DocumentoCabotagem): void {
    this.formOut.reset();
    this.clearForms(false);
    this.getCabotageSolicitationIfExists(documento);
  }

  public getCabotageSolicitationIfExists(documento: DocumentoCabotagem): void {
    if (!documento) {
      return;
    }
    this.isRequesting = true;
    this.closeSignalRConnection();
    this.blockSending = false;
    this.spinner.show();
    const correlation_id = uuid.v4();
    this.isSearchDone = true;
    this.changeDetector.detectChanges();
    this.isSolicitationReceived = false;
    this.solicitacaoLiberacaoCabotagemService.getCabotageLiberationSolicitationDataByDocument(documento, correlation_id)
      .subscribe({
        next: (result) => {
          const solicitation: SolicitacaoLiberacaoCabotagem = result.data as SolicitacaoLiberacaoCabotagem;
          this.doesSolicitationHaveIntegration = !!solicitation?.statusIntegracao?.length;
          this.changeDetector.detectChanges();
          if (solicitation) {
            this.setFormValuesFromReceivedCabotageSolicitation(solicitation);
          }
          else {
            this.documentosAnexosLiberacao?.setAttachedDocumentTypes(documento?.id!, [], this.solicitacaoLiberacaoCabotagemService.getAttachmentDocumentsForCabotageLiberationByDocumentType());
          }
          this.spinner.hide();
          this.setFieldSettings();
          this.getTelefoneUsuarioExterno();
          this.isRequesting = false;
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 404) {
            let {error} = err
            if (typeof (error) === 'string') {
              this.textoSolicitacaoExistente = error;
              this.modalSolicitacaoPesquisadaExistente.openModalForOk();
              this.spinner.hide();
            }
            else {
              let integracoes: IntegracaoSiscomex[] = (error as ErrorResponse)?.data;
              if (integracoes?.length) {
                console.log('Método de pesquisa')
                console.log(integracoes)
                this.doesSolicitationHaveIntegration = true;
                this.changeDetector.detectChanges();
                this.openSiscomexDataModal(integracoes, integracoes[0].correlationId);
                this.spinner.hide();
              }
              else {
                this.doesSolicitationHaveIntegration = false;
                this.changeDetector.detectChanges();
                let errorMessage = (err?.error as ErrorResponse);
                if (errorMessage?.errors?.length) {
                  errorMessage?.errors?.forEach(
                    (error) => {
                      if (error?.length) {
                        this.toastService.error(error);
                      }
                    }
                  );
                }
                this.spinner.hide();
                this.isRequesting = false;
              }
              this.setFieldSettings();
            }
          }
          else {
            console.log(err);
            let errorMessage = (err?.error as ErrorResponse);
            if (errorMessage?.errors?.length) {
              errorMessage?.errors?.forEach(
                (error) => {
                  if (error?.length) {
                    this.toastService.error(error);
                  }
                }
              );
            }
            this.spinner.hide();
          }
          this.documentosAnexosLiberacao?.setAttachedDocumentTypes(documento?.id!, [], this.solicitacaoLiberacaoCabotagemService.getAttachmentDocumentsForCabotageLiberationByDocumentType());
          this.setFieldSettings();
        }
      }
      );
    this.getFormSettingsByDocumentType(documento?.tipoDocumento);
    this.getTelefoneUsuarioExterno();
  }

  public confirmCreateCabotageSolicitationFromExistingDocumentSearch(): void {
    let documento = this.documento?.getCabotageDocumentFromForm();
    if (!documento) {
      return;
    }
    this.isRequesting = true;
    this.blockSending = false;
    this.spinner.show();
    const correlation_id = uuid.v4();
    this.isSearchDone = true;
    this.changeDetector.detectChanges();
    this.isSolicitationReceived = false;
    this.solicitacaoLiberacaoCabotagemService.confirmCreateSolicitationFromSearch(documento, correlation_id)
      .subscribe({
        next: (result) => {
          const solicitation: SolicitacaoLiberacaoCabotagem = result.data as SolicitacaoLiberacaoCabotagem;
          this.doesSolicitationHaveIntegration = !!solicitation?.statusIntegracao?.length;
          this.changeDetector.detectChanges();
          if (solicitation) {
            this.setFormValuesFromReceivedCabotageSolicitation(solicitation);
          }
          else {
            this.documentosAnexosLiberacao?.setAttachedDocumentTypes(documento?.id!, [], this.solicitacaoLiberacaoCabotagemService.getAttachmentDocumentsForCabotageLiberationByDocumentType());
          }
          this.spinner.hide();
          this.isRequesting = false;
          this.setFieldSettings();
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 404) {
            let integracoes: IntegracaoSiscomex[] = (err.error as ErrorResponse)?.data;
            if (integracoes?.length) {
              this.doesSolicitationHaveIntegration = true;
              this.changeDetector.detectChanges();
              this.openSiscomexDataModal(integracoes, integracoes[0].correlationId);
              this.spinner.hide();
            }
            else {
              this.doesSolicitationHaveIntegration = false;
              this.changeDetector.detectChanges();
              let errorMessage = (err?.error as ErrorResponse);
              if (errorMessage?.errors?.length) {
                errorMessage?.errors?.forEach(
                  (error) => {
                    if (error?.length) {
                      this.toastService.error(error);
                    }
                  }
                );
              }
              this.spinner.hide();
              this.isRequesting = false;
            }
            this.setFieldSettings();
          }
          else {
            console.log(err);
            let errorMessage = (err?.error as ErrorResponse);
            if (errorMessage?.errors?.length) {
              errorMessage?.errors?.forEach(
                (error) => {
                  if (error?.length) {
                    this.toastService.error(error);
                  }
                }
              );
            }
            this.spinner.hide();
            this.isRequesting = false;
          }
          this.documentosAnexosLiberacao?.setAttachedDocumentTypes(documento?.id!, [], this.solicitacaoLiberacaoCabotagemService.getAttachmentDocumentsForCabotageLiberationByDocumentType());
          this.setFieldSettings();
        }
      }
      );
    this.getFormSettingsByDocumentType(documento?.tipoDocumento);
  }

  // public allDataForm(event: any) {
  //   if (event != undefined && event != '' && event != null) {
  //     this.formOut.patchValue(event);
  //     this.modelSolicitacao = {
  //       id: this.formOut.value.id,
  //       documento: this.formOut.value.documento,
  //       cliente: this.formOut.value.cliente,
  //       dadosComplementares: this.formOut.value.dadosComplementares,
  //       relacaoConteineres: this.formOut.value.relacaoConteineres,
  //       //relacaoNotasFiscais: [this.formOut.value.relacaoNotasFiscais],
  //       arvorePartes: this.formOut.value.arvorePartes
  //       // documentosAnexosLiberacao: [this.formOut.value.documentosAnexosLiberacao]
  //     };
  //     if(event.arvorePartes && this.arvore){
  //       this.arvorePartesSalva = [...this.arvore?.getArvorePartesFromForm()];
  //       this.numerosConteineresSalvos = [...this.relacao.selectedContainerNumbers];
  //     };
  //   }
  // }

  private setFormValuesFromReceivedCabotageSolicitation(solicitation: SolicitacaoLiberacaoCabotagem): void {
    this.formOut.get('id')?.setValue(solicitation.id);
    //this.documento.setFormValuesFromDocument(solicitation.documento);
    this.cliente.setFormValuesFromReceivedClient(solicitation.cliente, true);
    this.dados.setFormValuesFromReceivedComplementaryData(solicitation.dadosComplementares);
    this.relacao.setFormValuesFromReceivedContainerRelation(solicitation.relacaoConteineres);
    this.notasFiscais.setFormValuesFromReceivedRelacaoNotaFiscal(solicitation.relacaoNotasFiscais);
    this.arvore.setArvorePartesFromReceivedContainers(solicitation.relacaoConteineres);
    this.documentosAnexosLiberacao?.setAttachedDocumentTypes(solicitation.documento?.id!, solicitation.documentosAnexosLiberacao!, this.solicitacaoLiberacaoCabotagemService.getAttachmentDocumentsForCabotageLiberationByDocumentType());
    this.isSolicitationReceived = true;
    this.setFieldSettings();
  }

  public changeDocumentType(documentType: string): void {
    this.documentTypeSelected = documentType
    this.hideForms();
    this.changeDetector.detectChanges();
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
  }

  public closeModal() {
    this.modalService.hide();
    this.integrationErrors = [];
  }

  public saveDraft() {
    if (!this.isSaveDraftButtonEnabled) {
      return;
    }
    console.log(this.getSolicitationFromForms());
    this.spinner.show()
    this.isRequesting = true;
    this.solicitacaoLiberacaoCabotagemService.saveDraft(this.getSolicitationFromForms())
      .subscribe(
        {
          next: (result) => {
            result.message?.forEach(
              (message) => {
                if (message && message.length) {
                  this.toastService.success(message);
                }
              }
            );
            let solicitationId: number = result.data.id;
            this.saveAttachedFiles(solicitationId);
          },
          error: (error: HttpErrorResponse) => {
            this.spinner.hide();
            console.log(error);
            let errorMessage = (error?.error as ErrorResponse);
            if (errorMessage?.errors?.length) {
              errorMessage?.errors?.forEach(
                (error) => {
                  if (error?.length) {
                    this.toastService.error(error);
                  }
                }
              );
            }
          },
          complete: () => this.spinner.hide()
        }
      );
  }

  public clearFormFields(): void {
    this.spinner.show();
    setTimeout(() => {
      this.clearForms(true);
      this.spinner.hide();
    }, 1500);
  }

  private clearForms(clearDocument: boolean): void {
    this.cliente?.clean();
    this.dados?.cancel();
    this.relacao?.cancel();
    this.notasFiscais?.cancel();
    this.arvore?.cancel();
    this.blockSending = true;
    this.isPartDocumentSaved = false;
    this.documentosAnexosLiberacao?.clearFields();
    if (clearDocument) {
      this.documento?.clean();
      this.camposFormularios = [];
      this.formOut?.reset();
    }
  }

  public cancelSolicitation(): void {
    this.spinner.show();
    let solicitation: SolicitacaoLiberacaoCabotagem = this.getSolicitationFromForms();
    if (solicitation.id) {
      this.isRequesting = true;
      this.solicitacaoLiberacaoCabotagemService.cancelCabotageLiberationSolicitationBySolicitationId(solicitation.id)
        .subscribe(
          {
            next: (result) => {
              this.spinner.hide();
              result?.message?.forEach(
                (msg) => {
                  if (msg?.length) {
                    this.toastService.success(msg);
                  }
                }
              );
              this.resetPage();
              this.isRequesting = true;
            },
            error: (err: HttpErrorResponse) => {
              this.spinner.hide();
              console.log(err);
              let errorMessage = (err?.error as ErrorResponse);
              if (errorMessage?.errors?.length) {
                errorMessage?.errors?.forEach(
                  (error) => {
                    if (error?.length) {
                      this.toastService.error(error);
                    }
                  }
                );
              }
              this.isRequesting = false;
            }
          }
        );
    }
  }

  protected resetPage(): void {
    this.clearForms(true);
    this.isSolicitationReceived = false;
    this.isSearchDone = false;
    this.isRequesting = false;
    this.changeDetector.detectChanges();
  }

  public checkIfAllFormsAreValid(): boolean {
    let documentType = this.documento?.getCabotageDocumentFromForm()?.tipoDocumento;
    let modalidadeValide = true
    if (documentType == 'CTE') {
      modalidadeValide = this.dados?.modalidadeIsValid()
    }

    return this.documento?.isDocumentValid() &&
      this.cliente?.isClientValid() &&
      this.dados?.areComplementaryDataValid() &&
      modalidadeValide &&
      this.relacao?.areAllGridLinesValid() &&
      this.notasFiscais?.areAllGridLinesValid() &&
      this.arvore?.areAllGridLinesValid() &&
      this.documentosAnexosLiberacao?.areAllFilesValid
  }

  public save(): void {
    if(!this.isConcludeButtonEnabled){
      return;
    }
    this.spinner.show();
    this.isRequesting = true;
    console.log(this.getSolicitationFromForms())
    this.solicitacaoLiberacaoCabotagemService.saveCabotageLiberationSolicitation(this.getSolicitationFromForms())
      .subscribe({
        next: (event) => {
          event?.message?.forEach(
            (msg) => {
              if (msg?.length) {
                this.toastService.success(msg);
              }
            }
          );
          let solicitationId: number = (event.data as SolicitacaoLiberacaoCabotagem).id as number;
          this.saveAttachedFiles(solicitationId);
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          let aggregateId = ((err?.error as ErrorResponse)?.errors ?? []).find(error => uuid.validate(error));
          if (aggregateId?.length) {
            this.setIntegrationForDTCDocument(aggregateId);
          }
          else {
            console.log(err);
            let errorMessage = (err?.error as ErrorResponse);
            if (errorMessage?.errors?.length) {
              errorMessage?.errors?.forEach(
                (error) => {
                  if (error?.length) {
                    this.toastService.error(error);
                  }
                }
              );
            }
          this.isRequesting = false;
          }
        }
      });
  }

  public getDocuments(): void {
    this.documento.setDocumentTypes(this.documentosService.GetDocumentsForSolicitacaoLiberacaoCabotagem());
  }

  private openSiscomexDataModal(integracoes: IntegracaoSiscomex[], correlation_id: string): void {
    console.log('openSiscomexDataModal')
    console.log('Integrações')
    console.log(integracoes)
    console.log('correlationId:')
    console.log(correlation_id)
    clearTimeout(this.timeoutForSignalRConnection);
    this.setSignalRConnectionForSiscomexIntegration(correlation_id, integracoes);
  }

  private setSignalRConnectionForSiscomexIntegration(correlation_id: string, integracoes: IntegracaoSiscomex[]): void {
    this.signalRConnection.on('IntegracaoCabotagemDTO', (integracao: IntegracaoSiscomex) => {
      this.modalExtrairDadosSiscomex.concludeIntegration(integracao);
    });
    this.signalRConnection.on('DivergenciaConteineres', (resposta: string) => {
      this.integrationErrors = [];
      this.integrationErrors.push(resposta);
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      this.blockSending = true;
    });
    this.signalRConnection.on('MensagemEventoErro', (resposta: string) => {
      this.integrationErrors = [];
      this.integrationErrors.push(resposta);
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      this.resetPage();
    });
    this.signalRConnection.on('SolicitacaoCabotagemPreenchida', (solicitacao: SolicitacaoLiberacaoCabotagem) => {
      console.log(solicitacao)
      this.setFormValuesFromReceivedCabotageSolicitation(solicitacao);
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
    });
    this.signalRConnection.start()
      .then(
        () => {
          this.signalRConnection.invoke('CriarConexaoSolicitacaoCabotagem', correlation_id)
            .then(() => {
              this.modalExtrairDadosSiscomex.openModal(integracoes);
            })
            .catch((err) => {
              this.closeSignalRConnection();
              console.log(err)
            });
          console.log('Conexão iniciada com correlatonId: ' + correlation_id)
        }
      )
      .catch(
        (err) => {
          this.closeSignalRConnection();
          console.log(err);
        }
      );
    this.timeoutForSignalRConnection = setTimeout(() => {
      this.closeSignalRConnection();
      if (this.modalExtrairDadosSiscomex.areAllIntegrationsDone) {
        return;
      }
      this.modalExtrairDadosSiscomex.clearIntegrationDisplay();
    }, TimeConstants.TIMEOUT_FOR_SISCOMEX_INTEGRATION);
  }

  public closeSignalRConnection(): void {
    this.signalRConnection.stop();
    clearTimeout(this.timeoutForSignalRConnection);
  }

  public setMaskFromDocumentSelected(mask: Mascara): void {
    this.maskForArvorePartes = mask;
  }

  public saveArvorePartes(): void {
    if (!this.arvore && !this.relacao) {
      return;
    }
    let arvoreParteForm: DocumentoParteCabotagem[] = this.arvore?.getPartDocumentsFromForm();
    if (arvoreParteForm) {
      this.arvorePartesSalva = arvoreParteForm;
    }
    let numerosConteineresForm = this.relacao.selectedContainerNumbers;
    if (numerosConteineresForm) {
      this.numerosConteineresSalvos = numerosConteineresForm ?? [];
    }
  }

  public updateArvorePartes(): void {
    if (!this.relacao) {
      return;
    }
    let containers = this.relacao.getSelectedContainersFromForms();
    if (!containers?.length) {
      this.arvore?.clean();
      return;
    }
    if (!containers.find(container => container.parte && container.quantidadePartes)) {
      this.arvore?.clean();
      return;
    }
    let documentosPartesGrid = this.arvore?.getPartDocumentsFromForm() ?? [];
    let arvorePartes: DocumentoParteCabotagem[] = [];
    containers.forEach(
      (conteiner) => {
        let partesConteiner = documentosPartesGrid.filter(parte => parte.numeroConteiner == conteiner.numeroConteiner) ?? [];
        let qtdPartes: number = conteiner?.quantidadePartes ?? 0;
        if (partesConteiner.length < (qtdPartes - 1)) {
          while (partesConteiner.length < (qtdPartes - 1)) {
            partesConteiner.push({
              numeroConteiner: conteiner.numeroConteiner,
              numeroDocumento: '',
              parteCadastrada: false
            });
          }
        }
        if (partesConteiner.length > (qtdPartes - 1)) {
          while (partesConteiner.length > (qtdPartes - 1) && partesConteiner.length > 0) {
            partesConteiner.pop();
          }
        }
        arvorePartes.push(...partesConteiner);
      }
    );
    this.arvore.setFormValuesFromReceivedDocumentNumbers(arvorePartes);
  }

  public setAttachedFilesForSavingPartDocument(): void {
    if(!this.isSalvarDocumentoParteEnabled){
      return;
    }
    this.spinner.show();
    this.closeSignalRConnection();
    this.isRequesting = true;
    if (this.documentosAnexosLiberacao?.isAnyFileUploaded()) {
      let attachedDocuments: ArquivoDocumentoAnexado[] = this.documentosAnexosLiberacao?.getAllDocumentsWithAttachedFiles() ?? [];
      if (attachedDocuments?.length) {
        this.solicitacaoLiberacaoCabotagemService.arquivoUploadSolicitacaoLiberacaoCabotagem(attachedDocuments).subscribe(
          {
            next: (document) => {
              document?.message?.forEach(
                (msg) => {
                  if (msg.length) {
                    this.toastService.success(msg);
                  }
                }
              );
              let updatedFiles: ArquivoDocumentoAnexado[] = document.data as ArquivoDocumentoAnexado[];
              this.documentosAnexosLiberacao?.updateDocumentsFromSavedFiles(updatedFiles);
              this.savePartDocuments();
            },
            error: (err: HttpErrorResponse) => {
              this.spinner.hide();
              console.log(err);
              let errorMessage = err?.error as ErrorResponse;
              if (errorMessage?.errors?.length) {
                errorMessage?.errors?.forEach(
                  (error) => {
                    this.toastService.error(error);
                  }
                );
              }
              this.isRequesting = false;
            },
            complete: () => {
              this.spinner.hide();
            }
          }
        );
      }
      else {
        this.savePartDocuments();
      }
    }
    else {
      this.savePartDocuments();
    }
  }

  private savePartDocuments(): void {
    const correlationId = uuid.v4();
    this.isRequesting = true;
    this.solicitacaoLiberacaoCabotagemService.savePartDocument(this.setSolicitationForSavingPartDocument(), correlationId)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          let integrations: RetornoStatusDocumentoParteDTO[] = result.data as RetornoStatusDocumentoParteDTO[];
          if (integrations && integrations.length) {
            this.openPartDocumentIntegrationModal(integrations, correlationId);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if (errorMessage?.errors?.length) {
            errorMessage?.errors?.forEach(
              (error) => {
                if (error?.length) {
                  this.toastService.error(error);
                }
              }
            );
          }
          this.isRequesting = false;
        }
      });
  }

  private openPartDocumentIntegrationModal(integrations: RetornoStatusDocumentoParteDTO[], correlationId: string): void {
    this.modalIntegracaoDocumentoParte.openModal(integrations);
    this.setSignalRConnectionForPartDocument(correlationId);
  }

  private setSolicitationForSavingPartDocument(): CadastroDocumentoParteCabotagemDTO {
    let solicitation: SolicitacaoLiberacaoCabotagem = this.getSolicitationFromForms();
    solicitation.documentosAnexosLiberacao?.forEach(
      (arquivo) => {
        arquivo.arquivoDocumento = undefined;
      }
    )
    let cadastroDocumentoParte: CadastroDocumentoParteCabotagemDTO = {
      conteineres:
        this.relacao.getSelectedContainersFromForms().filter(
          (conteiner) => conteiner.parte
        ) ?? [],
      solicitacaoLiberacaoCabotagemDTO: solicitation
    };
    let arvorePartes: DocumentoParteCabotagem[] = this.arvore.getPartDocumentsFromForm() ?? [];
    cadastroDocumentoParte.conteineres.forEach(
      (conteiner) => {
        conteiner.documentos = arvorePartes.filter(
          (documentoParte) => documentoParte.numeroConteiner == conteiner.numeroConteiner
        ) ?? [];
      }
    );
    return cadastroDocumentoParte;
  }

  private setSignalRConnectionForPartDocument(correlationId: string): void {
    this.signalRConnection.on('StatusDocumentoParteDTO', (integration: RetornoStatusDocumentoParteDTO) => {
      console.log('retorno integração documento parte cabotagem')
      console.log(integration)
      if (this.modalIntegracaoDocumentoParte.areAllIntegrationsConcluded()) {
        this.isPartDocumentSaved = true;
        let integrations: RetornoStatusDocumentoParteDTO[] = this.modalIntegracaoDocumentoParte.getIntegrations();
        this.erasePartDocumentsFromArvorePartesOnIntegrationFail(integrations);
      }
      this.modalIntegracaoDocumentoParte.updateIntegrationStatus(integration);
      this.alertPartDocumentIntegrationResult(integration);
      if (this.modalIntegracaoDocumentoParte.areAllIntegrationsConcluded()) {
        this.isPartDocumentSaved = true;
        this.closeSignalRConnection();
        let integrations: RetornoStatusDocumentoParteDTO[] = this.modalIntegracaoDocumentoParte.getIntegrations();
        this.erasePartDocumentsFromArvorePartesOnIntegrationFail(integrations);
      }
    });
    this.signalRConnection.on('DivergenciaConteineres', (resposta: string) => {
      this.integrationErrors = [];
      this.integrationErrors.push(resposta);
      this.closeSignalRConnection();
      this.modalIntegracaoDocumentoParte.closeModal();
      this.displayIntegrationErrors();
      this.blockSending = true;
    });
    this.signalRConnection.on('MensagemEventoErro', (resposta: string) => {
      this.integrationErrors = [];
      this.integrationErrors.push(resposta);
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      this.resetPage();
    });
    this.signalRConnection.start()
      .then(() => {
        this.signalRConnection.invoke('CriarConexaoSolicitacaoCabotagem', correlationId)
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      this.closeSignalRConnection();
      if (!this.modalIntegracaoDocumentoParte.areAllIntegrationsConcluded()) {
        this.modalIntegracaoDocumentoParte.setIntegrationsAsTimedOut();
      }
    }, TimeConstants.TIMEOUT_FOR_SISCOMEX_INTEGRATION);
  }

  private erasePartDocumentsFromArvorePartesOnIntegrationFail(integrations: RetornoStatusDocumentoParteDTO[]): void {
    for (let index: number = 0; index < integrations.length; index++) {
      if (!integrations[index].sucesso && integrations[index].concluido) {
        this.arvore?.erasePartDocumentByFailedIntegrationNumber(integrations[index].numeroDocumento);
      }
    }
  }

  private alertPartDocumentIntegrationResult(integration: RetornoStatusDocumentoParteDTO): void {
    if (integration.sucesso) {
      this.toastService.success(integration.descricao);
      return;
    }
    this.toastService.error(integration.descricao);
  }

  private getSolicitationFromForms(): SolicitacaoLiberacaoCabotagem {
    let solicitation: SolicitacaoLiberacaoCabotagem = {};
    solicitation.id = this.formOut.get('id')?.value;
    solicitation.documento = this.documento?.getCabotageDocumentFromForm();
    solicitation.cliente = this.cliente?.getClientFromForm();
    solicitation.dadosComplementares = this.dados?.getCabotageComplementaryDataFromForm();
    solicitation.relacaoConteineres = solicitation?.documento?.tipoDocumento === 'CTE' ?
      this.relacao?.getSelectedContainersFromForms() :
      this.relacao?.getContainersListFromForm();
    solicitation.arvorePartes = this.arvore?.getPartDocumentsFromForm();
    solicitation.relacaoNotasFiscais = this.notasFiscais?.getInvoiceRelationFromForm();
    solicitation.documentosAnexosLiberacao = this.documentosAnexosLiberacao?.getAllDocumentsWithAttachedFiles();
    solicitation.dadosComplementares.numeroCE = solicitation.documento.numeroCE;
    return solicitation;
  }

  public searchClient(cliente: Cliente): void {
    this.solicitacaoLiberacaoCabotagemService.getCliente(cliente)
      .subscribe({
        next: (result) => {
          this.cliente?.setSearchedClientData(result.data as Cliente);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = err?.error as ErrorResponse;
          if (errorMessage?.errors?.length) {
            errorMessage?.errors.forEach(
              (error) => {
                if (error && error.length) {
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public saveAttachedFiles(solicitationId: number): void {
    var documentos = this.documentosAnexosLiberacao?.getAllDocumentsWithAttachedFiles();
    if (!documentos || !documentos.length) {
      this.spinner.hide();
      this.resetPage();
      this.isRequesting = false;
      return;
    }
    this.solicitacaoLiberacaoCabotagemService.arquivoUploadSolicitacaoLiberacaoCabotagem(documentos, solicitationId).subscribe(
      {
        next: (document) => {
      this.spinner.hide();
          document?.message?.forEach(
            (msg) => {
              if (msg.length) {
                this.toastService.success(msg);
              }
            }
          );
          this.resetPage();
          this.isRequesting = false;
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          let errorMessage = (err?.error as ErrorResponse);
          if (errorMessage?.errors?.length) {
            errorMessage?.errors?.forEach(
              (error) => {
                if (error?.length) {
                  this.toastService.error(error);
                }
              }
            );
          }
          this.isRequesting = false;
        },
        complete: () => {
          this.spinner.hide();
        }
      }
    );
  }

  public downloadFileToDisplay(arquivo: { arquivoDocumentoAnexado: ArquivoDocumentoAnexado, index: number }): void {
    this.documentosAnexosLiberacao?.downloadFileToDisplay(this.solicitacaoLiberacaoCabotagemService.downloadFileForAttachedDocumentGrid(arquivo.arquivoDocumentoAnexado), arquivo.index);
  }

  public downloadFileToSave(arquivo: { arquivoDocumentoAnexado: ArquivoDocumentoAnexado, index: number }): void {
    this.documentosAnexosLiberacao?.downloadFileToSave(this.solicitacaoLiberacaoCabotagemService.downloadFileForAttachedDocumentGrid(arquivo.arquivoDocumentoAnexado), arquivo.index);
  }

  public displayIntegrationErrors(): void {
    this.isRequesting = false;
    if (this.integrationErrors && this.integrationErrors.length) {
      this.modalRef = this.modalService.show(this.modalAlertaErrosIntegracao, { class: 'modal-lg' });
    }
  }

  public get isPartDocumentAbsentOrSaved(): boolean {
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      return true;
    }
    if (!this.arvore?.getPartDocumentsFromForm()?.length) {
      return true;
    }
    if (this.arvore?.arePartDocumentsSameAsStored) {
      return true;
    }
    return this.isPartDocumentSaved;
  }

  public get isConcludeButtonEnabled(): boolean {
    return this.isSearchDone &&
      !this.blockSending &&
      !this.isRequesting &&
      this.checkIfAllFormsAreValid() &&
      this.isPartDocumentAbsentOrSaved;
  }

  private setIntegrationForDTCDocument(correlationId: string): void {
    let integration: IntegracaoSiscomex = {
      correlationId: correlationId,
      concluido: false,
      nomeEvento: 'ConsultaBloqueioRequestedEvent',
      descricao: 'Extraindo dados do documento na Consulta Bloqueio CE',
      integracaoPucomex: false
    };
    this.modalExtrairDadosSiscomex.openModal([integration]);
    this.integrationErrors = [];
    this.signalRConnection.on('IntegracaoDTO', (integracao: IntegracaoSiscomex) => {
      this.modalExtrairDadosSiscomex.concludeIntegration(integracao);
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      this.saveIntegratedDTC();
    });
    this.signalRConnection.on('MensagemEventoErro', (resposta: string) => {
      this.integrationErrors = [];
      this.integrationErrors.push(resposta);
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      this.resetPage();
    });
    this.signalRConnection.start()
      .then(
        () => {
          this.signalRConnection.invoke('CriarConexaoSolicitacaoCabotagem', correlationId)
            .catch((err) => {
              this.closeSignalRConnection();
              console.log(err)
            });
        }
      )
      .catch(
        (err) => {
          this.closeSignalRConnection();
          console.log(err);
        }
      );
    this.timeoutForSignalRConnection = setTimeout(() => {
      this.closeSignalRConnection();
      if (this.modalExtrairDadosSiscomex.areAllIntegrationsDone) {
        this.modalExtrairDadosSiscomex.closeModal();
        return;
      }
      this.modalExtrairDadosSiscomex.clearIntegrationDisplay();
    }, TimeConstants.TIMEOUT_FOR_SISCOMEX_INTEGRATION);
  }

  private saveIntegratedDTC() {
    let solicitation: SolicitacaoLiberacaoCabotagem = this.getSolicitationFromForms();
    console.log(solicitation)
    this.spinner.show();
    this.solicitacaoLiberacaoCabotagemService.saveCabotageLiberationSolicitationFromIntegratedDTC(solicitation)
      .subscribe({
        next: (msg) => {
          console.log(msg)
          let solicitationId: number = msg.data.id;
          console.log(msg)
          msg?.message?.forEach(
            (message) => {
              this.toastService.success(message);
            }
          );
          this.saveAttachedFiles(solicitationId);
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if (errorMessage?.errors?.length) {
            errorMessage?.errors?.forEach(
              (error) => {
                if (error?.length) {
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  private setFieldSettings(): void {
    this.changeDetector.detectChanges();
    this.documento?.setSettingsfromFields();
    this.cliente?.setSettingsfromFields();
    this.dados?.setSettingsfromFields();
    this.relacao?.setSettingsfromFields();
    this.arvore?.setSettingsfromFields();
    this.notasFiscais?.setSettingsfromFields();
    this.changeDetector.detectChanges();
  }

  private getFormSettingsByDocumentType(documentType: string): void {
    this.camposFormularios = [];
    this.documentosService.getFormFieldsByDocumentTypeInSolicitacaoLiberacaoCabotagem(documentType)
      .subscribe({
        next: (event) => {
          this.camposFormularios = event.data as Campo[];
          this.changeDetector.detectChanges();
          this.setFieldSettings();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = err?.error as ErrorResponse;
          if (errorMessage?.errors?.length) {
            errorMessage?.errors?.forEach(
              (error) => {
                this.toastService.error(error);
              }
            );
          }
          this.changeDetector.detectChanges();
        }
      });
  }

  public get isSaveDraftButtonEnabled(): boolean {
    if(this.isRequesting){
      return false;
    }
    if (this.arvore?.storedPartDocuments) {
      if (this.arvore?.arePartDocumentsSameAsStored) {
        return true && this.relacao?.areAllContainersValid;
      }
      else {
        return this.isPartDocumentAbsentOrSaved && this.relacao?.areAllContainersValid;
      }
    }
    else {
      return this.isPartDocumentAbsentOrSaved && this.relacao?.areAllContainersValid;
    }
  }

  public checkRequiredAttachedDocumentsForCTESolicitation(): void {
    let documentType = this.documento?.getCabotageDocumentFromForm()?.tipoDocumento;
    if (documentType != 'CTE') {
      return;
    }
    let modalidade = this.dados?.getCabotageComplementaryDataFromForm()?.modalidade;
    this.documentosAnexosLiberacao?.switchRequiredDocumentsByCabotageModalidade(modalidade);
  }

  public eraseConteiner(conteiner: ConteinerCabotagem): void {
    const { id } = this.getSolicitationFromForms();
    if (!id || !conteiner.numeroConteiner) return this.relacao.eraseConteinerFromGrid(conteiner);
    this.spinner.show();
    this.solicitacaoLiberacaoCabotagemService.postCancelarSolicitacao(this.getSolicitationFromForms(), conteiner)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          this.relacao.eraseConteinerFromGrid(conteiner);
          result?.message?.forEach(
            (message) => {
              this.toastService.success(message);
            }
          );
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if (errorMessage?.errors?.length) {
            errorMessage?.errors?.forEach(
              (error) => {
                if (error?.length) {
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public searchNomesDespachantes(): void {

    if (this.authentication.ehUsuarioInterno) {
      this.solicitacaoLiberacaoCabotagemService.searchNomesDespachantes(this.getSolicitationFromForms())
        .subscribe({
          next: (result) => {
            this.dados?.setNomesDespachantes(result.data);
          },
          error: (err: HttpErrorResponse) => {
            console.log(err);
            let errorMessage = (err?.error as ErrorResponse);
            if (errorMessage?.errors?.length) {
              errorMessage?.errors?.forEach(
                (error) => {
                  if (error?.length) {
                    this.toastService.error(error);
                  }
                }
              );
            }
          }
        });
    }
    else {
      var despachante: NomeDespachante []=[{nomeDespachante: this.authentication.fullName??''}]
      this.dados?.setNomesDespachantes(despachante);
    }
  }

  private extractValuesFromCTEXml(xml: any): any {
    let versaoModal: string = xml.cteProc.CTe.infCte.infCTeNorm.infModal._attributes.versaoModal;
    let values: any = {};
    if (parseInt(versaoModal) === 3.00) {
      values = this.extractValuesFromThreeDotZeroCTEXml(xml);
    }
    // console.log(values)
    return values;
  }

  private extractValuesFromThreeDotZeroCTEXml(xml: any): any {
    let values: any = {};
    let modal = xml?.cteProc?.CTe?.infCte?.infCTeNorm?.infModal;
    let cnpj: string = '';
    if (xml?.cteProc?.CTe?.infCte?.ide?.toma4?.toma?._text == '4') {
      cnpj = xml?.cteProc.CTe?.infCte?.ide?.toma4?.CNPJ?._text;
    }
    else if (xml?.cteProc?.CTe?.infCte?.ide?.toma3) {
      switch (xml?.cteProc?.CTe?.infCte?.ide?.toma3?.toma?._text) {
        case '0':
          cnpj = xml?.cteProc?.CTe?.infCte?.rem?.CNPJ?._text;
          break;
        case '1':
          cnpj = xml?.cteProc?.CTe?.infCte?.emit?.CNPJ?._text;
          break;
        case '2':
          cnpj = xml?.cteProc?.CTe?.infCte?.receb?.CNPJ?._text;
          break;
        case '3':
          cnpj = xml?.cteProc?.CTe?.infCte?.dest?.CNPJ?._text;
          break;
      }
    }
    values = Object.assign(values, {
      numeroDocumento: xml?.cteProc.CTe.infCte.ide.nCT._text,
      dataRegistro: new Date(xml.cteProc.CTe.infCte.ide.dhEmi._text),
      valorMercadoria: xml?.cteProc?.CTe.infCte.infCTeNorm.infCarga.vCarga._text,
      cpfCnpj: cnpj
    });
    if (modal.aquav) {
      //
    }
    else if (modal.rodo) {
      //
    }
    else if (modal.ferrov) { // ???
      //
    }
    else if (modal.multimodal) {
      //
    }
    return values;
  }

  private extractContainerNumberFromThreeDotZeroCTEXml(xml: any): string {
    let containerNumber: string = '';
    if (xml?.cteProc?.CTe?.infCte?.ide?.toma4?.toma?._text == '4') {
      let detCont = xml?.cteProc.CTe?.infCte?.infCTeNorm?.infModal?.aquav?.detCont;
      if (detCont?.length) {
        containerNumber = detCont?.[0]?.nCont?._text;
      }
      else {
        containerNumber = detCont?.nCont?._text;
      }
    }
    else {
      let infNFe = xml?.cteProc.CTe?.infCte?.infCTeNorm?.infDoc?.infNFe;
      if (infNFe?.length) {
        containerNumber = infNFe?.[0]?.infUnidTransp?.infUnidCarga?.idUnidCarga?._text;
      }
      else {
        containerNumber = infNFe?.infUnidTransp?.infUnidCarga?.idUnidCarga?._text;
      }
    }
    return containerNumber ?? '';
  }

  public searchShipAndTripByContainerNumber(containerNumber: string): void {
    if (!containerNumber?.length) {
      return;
    }
    this.solicitacaoLiberacaoCabotagemService.searchShipAndTripByContainerNumber(containerNumber)
      .subscribe({
        next: (result) => {
          this.dados?.setShipAndTripFromSearchByContainerNumber(result.data as DadosNavioVigemDTO);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if (errorMessage?.errors?.length) {
            errorMessage?.errors?.forEach(
              (error) => {
                if (error?.length) {
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public get shouldBlockPartQuantityEditionByDocumentType(): boolean{
    return this.documentTypesToBlockPartQuantityEdition.includes(this.documentTypeSelected ?? '');
  }

  private getSelectFieldsForFormComponents(): void{
    //Combobox moeda
    this.documentosService.getMoedas().subscribe({
      next: (moedas) =>{
        this.moedas = moedas;
        this.changeDetector.detectChanges();
      }
    });
  }
}
