<td>
  <div class="input-group" *ngIf="numeroConteiner">
    <input type="text" class="form-control" readonly
    [formControl]="numeroConteiner"
    >
  </div>
</td>
<td>
  <div class="input-group" *ngIf="numeroDocumento">
    <input *ngIf="mascara?.modelo; else numeroDocumentoNoMask" type="text" class="form-control" readonly
      [formControl]="numeroDocumento"
      [mask]="mascara?.modelo ?? ''"
      [specialCharacters]="mascara?.caracteresEspeciais ?? []"
    >
    <ng-template #numeroDocumentoNoMask>
      <input type="text" class="form-control" readonly
        [formControl]="numeroDocumento"
      >
    </ng-template>
    <button (click)="expandPartDocument()">
      <img src="assets/images/expand.svg" alt="">
    </button>
  </div>
</td>
<td [ngSwitch]="isExpDocument">
  <div class="input-group" *ngSwitchCase="true" >
    <input *ngIf="quantidadePartes" type="text" class="form-control" readonly
      [formControl]="quantidadePartes"
    >
  </div>
  <div class="input-group" *ngSwitchCase="false" >
    <input *ngIf="numeroCE" type="text" class="form-control" readonly
      [formControl]="numeroCE"
    >
  </div>
</td>
<td>
  <div class="input-group" *ngIf="status">
    <input type="text" class="form-control" readonly
      [formControl]="status"
    >
  </div>
</td>

<!-- <ng-template #numeroCEField>
  <div class="input-group" *ngIf="numeroCE">
    <input type="text" class="form-control" readonly
      [formControl]="numeroCE"
    >
  </div>
</ng-template>

<ng-template #quantidadePartesField>
  <div class="input-group" *ngIf="quantidadePartes">
    <input type="text" class="form-control" readonly
      [formControl]="quantidadePartes"
    >
  </div>
</ng-template> -->
