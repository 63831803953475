import { RelacaoConteinerN4DocumentoTransporte, RelacaoConteinerCCTDocumentoTransporte } from '../documento-transporte/LacreConteinerRecepcaoDocumentoTransporte';
import { RecepcaoConteinerComponent } from 'src/app/pages/recepcao-due-contigencia/recepcao-conteiner/recepcao-conteiner.component';
import { LacreConteiner } from './../RecepcaoDueContingencia/RecepcaoNotaFiscal/LacreConteiner';
export class ConteinerRecepcaoDocumentoTransporteDTO{
  dadosRecepcaoDocumentoTransporteCCT: ConteinerRecepcaoDocumentoTransporteCCTDTO = new ConteinerRecepcaoDocumentoTransporteCCTDTO();
  dadosRecepcaoDocumentoTransporteN4: ConteinerRecepcaoDocumentoTransporteN4DTO = new ConteinerRecepcaoDocumentoTransporteN4DTO();
  tipoDocumento?: string;
  numeroDocumento?: string;
  identificador?: string;


}

export class ConteinerRecepcaoDocumentoTransporteCCTDTO {
  placaReboque1?: string;
  placaReboque2?: string;
  placaReboque3?: string;
  placaCavalo?: string;
  dataEmissao?: Date;
  situacao?: string;
  cnpjCpfTransportador?: string;
  cpfCondutor?: string;
  localDestinoPrevisto?:string
  numeroConteiner?: string
  relacaoConteinerLacresCCT?: RelacaoConteinerCCTDocumentoTransporte[] = [];
  dadosVeiculoConferem?: string;
  dadosConteinerConferem?: string;
  dadosEmbalagemConferem?: string;
  dadosUnidadeTransporteConferem?: string;
  identificador?: string;
}

export class ConteinerRecepcaoDocumentoTransporteN4DTO {
  numeroConteiner?:string
  cnpjCpfTransportador?: string;
  cpfCondutor?: string;
  nomeCondutor?: string;
  peso?: string;
  placaReboque1?: string;
  placaReboque2?: string;
  placaReboque3?: string;
  placaCavalo?: string;
  relacaoConteinerLacresN4?: RelacaoConteinerN4DocumentoTransporte[] = [];
  pesagem?: boolean;
  tara?: number;
  recepcaoRealizadaComSucesso?: boolean;
  dataEnvio?: Date;
  usuario?: string;
  statusRecepcao?: string;
  identificador?: string;
}
