import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { DadosEnvioEntregaDocumentoTransporte } from 'src/app/models/EntregaDueContingencia/EntregaDocumentoTransporte/DadosEnvioEntregaDocumentoTransporte';
import { DocumentCustomPattern } from 'src/app/shared/utils/constantes-documentos';

@Component({
  selector: 'app-dados-envio-edt',
  templateUrl: './dados-envio-edt.component.html',
  styleUrls: ['./dados-envio-edt.component.scss']
})
export class DadosEnvioEDTComponent implements OnInit {

  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;
  private colorTheme = 'theme-dark-blue';
  public bsConfig?: Partial<BsDatepickerConfig>;
  private locale = 'pt-br';
  public namePattern = DocumentCustomPattern.NAME_PATTERN;
  @Output() public onSendData: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(
    private fb: UntypedFormBuilder,
    private localeService: BsLocaleService
  ) { }

  public ngOnInit(): void {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.validation();
  }

  private validation(): void{
    this.form = this.fb.group({
      dataEnvio: [''],
      usuario: [''],
      statusEntrega: ['']
    });
  }

  public getDadosEnvioFromForm(): DadosEnvioEntregaDocumentoTransporte{
    return this.form.getRawValue() as DadosEnvioEntregaDocumentoTransporte;
  }

  public clear(): void{
    this.form.reset();
  }

  public setFormValuesFromReceivedShippingData(dadosEnvio: DadosEnvioEntregaDocumentoTransporte | undefined | null): void{
    if(!dadosEnvio){
      return;
    }
    this.form.get('dataEnvio')?.setValue(new Date(dadosEnvio.dataEnvio).toLocaleDateString('PT-br'));
    this.form.get('usuario')?.setValue(dadosEnvio.usuario);
    this.form.get('statusEntrega')?.setValue(dadosEnvio.status);
  }
}
