import { ArquivoDocumentoAnexado } from 'src/app/models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { CadastroDocumentoEstimativaCalculoService } from '../../../../services/EstimativaCalculo/cadastro-documento-estimativa-calculo.service';
import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-exibir-anexos',
  templateUrl: './modal-exibir-anexos.component.html',
  styleUrls: ['./modal-exibir-anexos.component.scss']
})
export class ModalExibirAnexosComponent implements OnInit {

  @ViewChild('modalExibirAnexos') private modalExibirAnexos!: TemplateRef<any>;
  public fileToDisplay!: ArquivoDocumentoAnexado | undefined;
  public fileExtension!: string;
  public fileStringUrl!: SafeUrl;
  public readonly allowedFileExtensions: string[] = ['bmp', 'jpg', 'jpeg', 'pdf', 'tif', 'tiff'];

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private domSanitizer: DomSanitizer,
    private cadastroDocumentoEstimativaCalculoService: CadastroDocumentoEstimativaCalculoService,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
  }

  public closeModal(): void{
    this.bsModalRef.hide();
  }

  public displayFile(file: ArquivoDocumentoAnexado): void{
    this.fileToDisplay = file;
    this.setFileExtension();
    this.getFileUrl();
  }

  private openModalForFileDisplay(): void{
    this.bsModalRef = this.bsModalService.show(this.modalExibirAnexos, Object.assign({}, {
      class: 'modal-xl modal-dialog-centered',
      ignoreBackdropClick: true,
    }));
    this.bsModalRef.onHidden?.subscribe(
      () => {
        this.fileToDisplay = undefined;
        this.fileExtension = '';
        this.fileStringUrl = '';
      }
    );
    this.changeDetector.detectChanges();
  }

  public setFileExtension(): void{
    let extension: string = this.fileToDisplay?.nomeArquivo?.split('.')?.pop()?.toLowerCase() ?? '';
    if(!this.allowedFileExtensions.includes(extension)){
      return;
    }
    this.fileExtension = extension;
  }

  public getFileUrl(): void{
    const reader = new FileReader();
    reader.onload = ((file) => {
      this.fileStringUrl = file?.target?.result as string ?? '';//this.domSanitizer.bypassSecurityTrustResourceUrl(file?.target?.result as string ?? '');
      if(this.fileExtension === 'tif' || this.fileExtension === 'tiff'){
        this.cadastroDocumentoEstimativaCalculoService.downloadFileFromAttachedFiles(this.fileToDisplay!);
        return;
      }
      this.openModalForFileDisplay();
      this.changeDetector.detectChanges();
    });
    reader.readAsDataURL(this.fileToDisplay?.arquivoDocumento as Blob);
  }

}
