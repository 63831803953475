import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pluck, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SolicitacaoLiberacaoExportacao } from '../models/SolicitacaoLiberacaoExportacao/SolicitacaoLiberacaoExportacao';

@Injectable({
  providedIn: 'root'
})
export class SalvarRascunhoExportacaoService {

  constructor(private http: HttpClient) { }

  public postSalvarRascunhoExp(SolicitacaoLiberacaoExportacao: SolicitacaoLiberacaoExportacao): Observable<SolicitacaoLiberacaoExportacao>{
    return this.http.post<SolicitacaoLiberacaoExportacao>(`${environment.apiUrlForSolicitacaoLiberacaoExp}/SolicitacaoLiberacaoExp/Rascunho`, SolicitacaoLiberacaoExportacao).pipe(take(1));
  }
}
