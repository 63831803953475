<ng-template #templateImpressao>
  <div class="display-on-print">
    <table *ngFor="let relatorio of relatorios" class="relatorio">
      <thead>
        <tr class="header borderBottom">
          <td colspan="4">
            <img class="logo" src="{{this.logo}}" alt="logo" />
          </td>
          <td colspan="4" >
            <span class="header-nomeRelatorio"> {{nomeRelatorio}} </span>
          </td>
          <td colspan="4">
            <span class="header-now-date">
              {{currentDate.toLocaleString('pt-BR')}}
            </span>
          </td>
        </tr>
        <tr class="header borderBottom">
        </tr>
      </thead>
      <tbody *ngTemplateOutlet="relatorio"></tbody>
      <tfoot>
        <tr class="foot-cont"></tr>
      </tfoot>
    </table>
  </div>
</ng-template>

<ng-template #tableRelatorio #tableRelatorio1>
  <thead>
    <tr>
      <th class="header-10-porcento" >Cliente</th>
      <th class="header-10-porcento" >CNPJ</th>
      <th class="header-10-porcento" >Navio</th>
      <th class="header-10-porcento" >Viagem</th>
      <th class="header-10-porcento">Serviço</th>
      <th class="header-10-porcento">Qtde de Contêiner</th>
      <th class="header-10-porcento">Canal</th>
      <th class="header-10-porcento">Escritório</th>
    </tr>
  </thead>
  <tr *ngFor="let relatorio of dados">
    <td class="header-10-porcento">{{relatorio?.cliente ? relatorio.cliente : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.cnpjCliente ? relatorio.cnpjCliente : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.navio ? relatorio.navio : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.viagem ? relatorio.viagem : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.servicoNavio ? relatorio.servicoNavio : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.quantidadeConteineres ? relatorio.quantidadeConteineres : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.canal ? relatorio.canal : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.escritorio ? relatorio.escritorio : ''}}</td>
  </tr>
  <tr class="total-row">
    <td colspan="5">Total de contêineres:</td>
    <td class="header-10-porcento">{{totalConteineres}}</td>
  </tr>
</ng-template>
