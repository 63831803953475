import { LacreConteiner } from '../../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/LacreConteiner';
import { ConteinerRecepcaoNotaFiscal } from './../../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/ConteinerRecepcaoNotaFiscal';
import { ToastService } from 'src/app/services/toast.service';
import { RelacaoConteinerRNF } from './../../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/RelacaoConteinerRNF';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RetornoPesquisaConteinerNFE } from 'src/app/models/RecepcaoDueContingencia/RecepcaoNotaFiscal/RetornoPesquisaConteinerNFE';
import { RetornoPesquisaRecepcaoNotaFiscal } from 'src/app/models/RecepcaoDueContingencia/RecepcaoNotaFiscal/RetornoPesquisaRecepcaoNotaFiscal';

@Component({
  selector: 'app-componente-conteiner',
  templateUrl: './componente-conteiner.component.html',
  styleUrls: ['./componente-conteiner.component.scss']
})
export class ComponenteConteinerComponent implements OnInit {

  public isCollapsed = false;
  public maskForClientDocument: string = '0*';
  public conteineresNaoVinculados!: RetornoPesquisaConteinerNFE[];
  public conteineresVinculados!: RetornoPesquisaConteinerNFE[];
  public selecionarTodosNaoVinculados: boolean = false;
  public selecionarTodosVinculados: boolean = false;

  @Output() onUpdateContainer: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private toastService:ToastService
  ) {
  }

  public ngOnInit() {
    this.conteineresNaoVinculados = [];
    this.conteineresVinculados = [];
  }

  public changeSelectedFromUnlinkedContainers(conteiner: RetornoPesquisaConteinerNFE): void{
    this.conteineresNaoVinculados[this.conteineresNaoVinculados.indexOf(conteiner)].selecionado = !conteiner.selecionado;
    this.selecionarTodosNaoVinculados = false;
  }

  public changeSelectedFromLinkedContainers(conteiner: RetornoPesquisaConteinerNFE): void{
    this.conteineresVinculados[this.conteineresVinculados.indexOf(conteiner)].selecionado = !conteiner.selecionado;
    this.selecionarTodosVinculados = false;
  }

  public switchAllUnlinkedContainersSelectedOrNot(): void{
    this.selecionarTodosNaoVinculados = !this.selecionarTodosNaoVinculados;
    this.conteineresNaoVinculados.forEach(
      (conteiner) => {
        conteiner.selecionado = this.selecionarTodosNaoVinculados;
      }
    );
  }

  public switchAllLinkedContainersSelectedOrNot(): void{
    this.selecionarTodosVinculados = !this.selecionarTodosVinculados;
    this.conteineresVinculados.forEach(
      (conteiner) => {
        conteiner.selecionado = this.selecionarTodosVinculados;
      }
    );
  }

  private unselectAllConteiners(): void{
    this.selecionarTodosNaoVinculados = false;
    this.conteineresNaoVinculados.forEach(
      (conteiner) => {
        conteiner.selecionado = false;
      }
    );
    this.selecionarTodosVinculados = false;
    this.conteineresVinculados.forEach(
      (conteiner) => {
        conteiner.selecionado = false;
      }
    );
  }

  public linkContainers(): void{
    let containersToLink: RetornoPesquisaConteinerNFE[] = this.conteineresNaoVinculados.filter(
      conteiner => conteiner.selecionado
    );
    if(!containersToLink.length){
      return;
    }
    containersToLink.forEach(
      conteiner => {
        this.conteineresNaoVinculados.splice((this.conteineresNaoVinculados.indexOf(conteiner)), 1);
        this.conteineresVinculados.push(conteiner);
      }
    );
    this.unselectAllConteiners();
    this.onUpdateContainer.emit();
  }

  public unlinkContainers(): void{
    let containersToUnlink: RetornoPesquisaConteinerNFE[] = this.conteineresVinculados.filter(
      conteiner => conteiner.selecionado
    );
    if(!containersToUnlink.length){
      return;
    }
    containersToUnlink.forEach(
      conteiner => {
          this.conteineresVinculados.splice((this.conteineresNaoVinculados.indexOf(conteiner)), 1);
          this.conteineresNaoVinculados.push(conteiner);
        }
    );
    this.unselectAllConteiners();
    this.onUpdateContainer.emit();
  }

  public get isAnyUnlinkedConteinerSelected(): boolean{
    return !!this.conteineresNaoVinculados.filter(
      conteiner => conteiner.selecionado
    ).length;
  }

  public get isAnyLinkedConteinerSelected(): boolean{
    return !!this.conteineresVinculados.filter(
      conteiner => conteiner.selecionado
    ).length;
  }

  public getConcatenatedSealNumbers(seal: {id: number, numero: number}[]): string{
    return seal.map((lacre) => lacre.numero).join(', ');
  }

  //public setReceivedContainerIntoGrid(conteiner: ConteinerRecepcaoNotaFiscal): void{
    // this.clear();
    //let conteinerToSet: RelacaoConteinerRNF = {
      //numeroConteiner: conteiner.numeroConteiner,
      //dataChegada: conteiner.dataChegada,
      //numeroBooking: conteiner.numeroBooking ?? '',
      //peso: conteiner.peso as number,
      //chaveNFE: conteiner.chaveNFE ?? '',
      //lacresN4: conteiner.lacresN4,
      //lacresPortalTecon: conteiner.lacresPortalTecon,
      //recepcaoRealizadaComSucesso: conteiner.recepcaoRealizadaComSucesso,
      //selecionado: false
    //};
    //conteinerToSet.recepcaoRealizadaComSucesso ? this.conteineresVinculados.push(conteinerToSet) : this.conteineresNaoVinculados.push(conteinerToSet);
   // this.onUpdateContainer.emit();
  //}

  public setSearchedContainerIntoGrid(conteiner: RetornoPesquisaRecepcaoNotaFiscal): void{
    this.clear();
    let containersToSet: RetornoPesquisaConteinerNFE[] = []
    for(let index = 0; index < (conteiner.conteinerNFE?.length ?? 0); index++){
      if(conteiner?.conteinerNFE?.[index]){
        containersToSet.push({
          numeroConteiner: conteiner.conteinerNFE[index].numeroConteiner ?? '',
          dataChegada: conteiner.conteinerNFE[index].dataChegada ?? undefined,
          numeroBooking: conteiner.conteinerNFE[index].numeroBooking ?? '',
          peso: conteiner.conteinerNFE[index].peso ?? 0,
          tara: conteiner.conteinerNFE[index].tara ?? 0,
          chaveNFE: conteiner.conteinerNFE[index].chaveNFE ?? '',
          lacresN4: conteiner.conteinerNFE[index].lacresN4 ?? [],
          lacresPortalTecon: conteiner.conteinerNFE[index].lacresPortalTecon ?? [],
          vinculado: !!conteiner.conteinerNFE[index].vinculado,
          pesagem: !!conteiner.conteinerNFE[index].pesagem,
          selecionado: false
        })
      }
    }
    for(let index = 0; index < containersToSet.length; index++){
      if(containersToSet[index].vinculado){
        this.conteineresVinculados.push(containersToSet[index])
      }
      else{
        this.conteineresNaoVinculados.push(containersToSet[index])
      }
    }
    this.onUpdateContainer.emit();
  }

  public clear(): void{
    this.unselectAllConteiners();
    this.conteineresNaoVinculados = [];
    this.conteineresVinculados = [];
    this.onUpdateContainer.emit();
  }

  public checkIfSealNumbersAreEqual(): boolean{
    let lacresN4: number[] = [];
    let lacresPortalTecon: number[] = [];
    this.conteineresNaoVinculados.forEach(
      (conteiner) => {
        lacresN4.push(...conteiner.lacresN4.map(
          (lacre) => lacre.numero
        ));
        lacresPortalTecon.push(...conteiner.lacresPortalTecon.map(
          (lacre) => lacre.numero
        ));
      }
    );
    this.conteineresVinculados.forEach(
      (conteiner) => {
        lacresN4.push(...conteiner.lacresN4.map(
          (lacre) => lacre.numero
        ));
        lacresPortalTecon.push(...conteiner.lacresPortalTecon.map(
          (lacre) => lacre.numero
        ));
      }
    );
    if(lacresN4.length != lacresPortalTecon.length){
      return false;
    }
    return lacresN4.every(
      (lacre) => lacresPortalTecon.includes(lacre)
    ) && lacresPortalTecon.every(
      (lacre) => lacresN4.includes(lacre)
    );
  }

  public sendUnlinkedContainer(): RetornoPesquisaConteinerNFE | null{
    if(!this.conteineresNaoVinculados.length){
      return null;
    }
    const conteiner: RetornoPesquisaConteinerNFE = this.conteineresNaoVinculados[0];
    if(!conteiner){
      return null;
    }
    return {
      numeroConteiner: conteiner.numeroConteiner,
      dataChegada: conteiner.dataChegada,
      numeroBooking: conteiner.numeroBooking,
      lacresN4: conteiner.lacresN4,
      lacresPortalTecon: conteiner.lacresPortalTecon,
      peso: conteiner.peso,
      tara: conteiner.tara,
      chaveNFE: conteiner.chaveNFE,
      vinculado: conteiner.vinculado,
      pesagem: conteiner.pesagem,
    };
  }

  public sendLinkedContainer(): RetornoPesquisaConteinerNFE | null{
    if(!this.conteineresVinculados.length){
      return null;
    }
    const conteiner: RetornoPesquisaConteinerNFE = this.conteineresVinculados[0];
    if(!conteiner){
      return null;
    }
    return {
      numeroConteiner: conteiner.numeroConteiner,
      dataChegada: conteiner.dataChegada,
      numeroBooking: conteiner.numeroBooking,
      lacresN4: conteiner.lacresN4,
      lacresPortalTecon: conteiner.lacresPortalTecon,
      peso: conteiner.peso,
      tara: conteiner.tara,
      chaveNFE: conteiner.chaveNFE,
      vinculado: conteiner.vinculado,
      pesagem: conteiner.pesagem,
    };
  }

  public getAllConteineresVinculados() {
    if (!this.conteineresVinculados.length)
      return null;
    return this.conteineresVinculados
  }

  public getAllConteineresDesvinculados() {
    if (!this.conteineresNaoVinculados.length)
      return null;
    return this.conteineresNaoVinculados
  }
}
