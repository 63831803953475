import { ArquivoDocumentoAnexado } from './../../../models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { SolicitacaoFaturamentoEstimativaCalculoService } from './../../../services/EstimativaCalculo/solicitacao-faturamento-estimativa-calculo.service';
import { ToastService } from 'src/app/services/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GridSolicitacaoFaturamento } from './../../../models/EstimativaCalculo/SolicitacaoFaturamento/GridSolicitacaoFaturamento';
import { Component, EventEmitter, Output, TemplateRef, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, Input, AfterViewInit, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { LinhaGridDocumentosAnexadosComponent } from '../../../shared/components/DocumentosAnexados/linha-grid-documentos-anexados/linha-grid-documentos-anexados.component';
import { TipoDocumentoAnexado } from 'src/app/models/DocumentosAnexados/TipoDocumentoAnexado';
import { UntypedFormGroup } from '@angular/forms';
import { DocumentosAnexadosComponent } from 'src/app/shared/components/DocumentosAnexados/documentos-anexados/documentos-anexados.component';
import { ModalOkCancelComponent } from 'src/app/shared/components/modais/modal-ok-cancel/modal-ok-cancel.component';

@Component({
  selector: 'app-modal-documentos-anexados-estimativa-calculo',
  templateUrl: './modal-documentos-anexados-estimativa-calculo.component.html',
  styleUrls: ['./modal-documentos-anexados-estimativa-calculo.component.scss']
})
export class ModalDocumentosAnexadosEstimativaCalculoComponent implements OnInit {

  public arquivosAnexados!: ArquivoDocumentoAnexado[];
  public arquivosAnexadosData!: ArquivoDocumentoAnexado[];
  public minPositionForAdding?: number;
  public form!: UntypedFormGroup;
  public solicitacao!: GridSolicitacaoFaturamento;
  public areAttachedFilesValid: boolean = false;
  public isSolicitationFinished: boolean = false;
  public enviarModalDocumentoAnexo: boolean = false;
  @ViewChild("modal",{static: false}) modal!: TemplateRef<any>;
  @ViewChild('modalOkCancel') public modalOkCancel!: ModalOkCancelComponent;
  @Input() public usuarioInterno!: boolean;
  @Output() public onDisplayAttachedFile: EventEmitter<ArquivoDocumentoAnexado> = new EventEmitter<ArquivoDocumentoAnexado>();
  @Output() public onFinalizeSolicitation: EventEmitter<GridSolicitacaoFaturamento> = new EventEmitter<GridSolicitacaoFaturamento>();
  @Output() public onCloseModal: EventEmitter<void> = new EventEmitter<void>();
  modalRef ?: BsModalRef ;

  constructor(
    private solicitacaoFaturamentoEstimativaCalculoService: SolicitacaoFaturamentoEstimativaCalculoService,
    private spinner: NgxSpinnerService,
    private toastService:ToastService,
    private changeDetector: ChangeDetectorRef,
    private modalService: BsModalService,

  ) { }

  ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    this.arquivosAnexados = [];
    this.arquivosAnexadosData = [];
    // this.openModalForAttachedDocuments();
  }

  public openModal(solicitacao: GridSolicitacaoFaturamento) {
    // this.openModalForAttachedDocuments();
    this.enviarModalDocumentoAnexo = false;
    this.solicitacao = solicitacao;
    if(!this.solicitacao?.documentosAnexados){
      return;
    }
    if(this.solicitacao.documentosAnexados.length >= 2){
      this.isSolicitationFinished = true;
    }
    this.setAttachedDocumentTypes();
    this.changeDetector.detectChanges();
    this.modalRef = this.modalService.show(this.modal, Object.assign({}, {
      class: 'modal-xl modal-dialog-centered',
      ignoreBackdropClick: true,
    }));
    this.changeDetector.detectChanges();
  }

  public closeModal(): void{
    this.modalRef?.hide();
    this.onCloseModal.emit();
  }

  public isAnyFileUploaded(): boolean{
    return !!this.arquivosAnexadosData?.filter(
      (arquivo) => this.isFileUploaded(arquivo)
    )?.length;
  }

  private isFileUploaded(arquivo: ArquivoDocumentoAnexado): boolean{
    return (!!arquivo?.arquivoDocumento?.size || !!arquivo?.filePath?.length) && !!arquivo?.nomeArquivo?.length && !!arquivo?.dataHoraInclusao;
  }

  private isAttachedFileValid(arquivo: ArquivoDocumentoAnexado): boolean{
    if(arquivo?.tipoDocumento?.obrigatorio){
      return (!!arquivo?.arquivoDocumento?.size || !!arquivo?.filePath?.length) && !!arquivo?.nomeArquivo?.length && !!arquivo?.dataHoraInclusao;
    }
    return !!arquivo?.tipoDocumento;
  }

  public displayAttachedFile(documentoAnexado: LinhaGridDocumentosAnexadosComponent, fileIndex: number): void{
    let arquivo: ArquivoDocumentoAnexado = documentoAnexado?.getAttachedDocument();
    if(arquivo?.arquivoDocumento?.size){
      this.onDisplayAttachedFile.emit(arquivo);
      return;
    }
    this.solicitacaoFaturamentoEstimativaCalculoService.downloadFileForBillingSolicitation(arquivo)
      .subscribe({
        next: async (result) => {
          let mimeType = await documentoAnexado.getAttachedDocument().contentType;
          let download = await this.convertBase64ToFile(`data:${mimeType};base64,${result.data as string}`, documentoAnexado?.getAttachedDocument().nomeArquivo!);
          documentoAnexado?.setFileDataFromDownloadedDocument(download);
          this.arquivosAnexados[fileIndex].arquivoDocumento = download;
          this.arquivosAnexadosData[fileIndex].arquivoDocumento = download;
          this.changeDetector?.detectChanges();
          this.onDisplayAttachedFile.emit( documentoAnexado?.getAttachedDocument());
          this.updateValidity();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public getAttachedDocuments(): ArquivoDocumentoAnexado[]{
    return [...this.arquivosAnexadosData] ?? [];
  }
  public OpenmodalOkCancel(){
    this.enviarModalDocumentoAnexo = true;
    console.log('mudou enviar pra true')
    //this.modalOkCancel.openModalForConfirmOrRefuse();
  }
  public CancelEnvio(){
    this.enviarModalDocumentoAnexo = false;
    //this.modalOkCancel.openModalForConfirmOrRefuse();
  }

  public sendAttachedDocuments(): void{
    this.spinner.show();
    this.solicitacaoFaturamentoEstimativaCalculoService.sendBillingSolicitationDocuments(this.solicitacao, !this.usuarioInterno, this.getAttachedDocuments())
      .subscribe({
        next: (result) => {
          result?.message?.forEach(
            (message) => {
              if(message && message.length){
                this.toastService.success(message);
              }
            }
          );
          this.spinner.hide('carregando');
          this.onFinalizeSolicitation.emit();
          this.closeModal();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide('carregando');
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public areAllFilesValid(): boolean{
    if(!this.arquivosAnexadosData?.length){
      return false;
    }
    if(this.solicitacao.documentosAnexados?.length ?? 0 < 2){
      if(this.solicitacao.documentosAnexados?.length == 1){
        if(!this.solicitacao.documentosAnexados[0]?.nomeArquivo)this.isSolicitationFinished = false;
      }
    }
    return !!this.arquivosAnexadosData?.map(
      (arquivo) => this.isAttachedFileValid(arquivo)
    )?.every(fileValid => fileValid) ?? false;
  }

  private setAttachedDocumentTypes(): void{
    this.arquivosAnexados = [];
    console.log('É user interno: '+this.usuarioInterno)

    let userExterno = false;
    if(!this.usuarioInterno) userExterno = true

    this.solicitacaoFaturamentoEstimativaCalculoService.getFilesToAttach(userExterno)
      .subscribe({
        next: (tiposArquivo) => {
          let listaArquivos = tiposArquivo
            .filter(
              arquivo => arquivo.idTipoDocumento == this.solicitacao.documento.id
            )
            .map(
              arquivo => {
                return {
                  tipoDocumento: arquivo
                }
              }
            );
            console.log('filtros:')
            console.log(listaArquivos)
          this.setAttachedDocumentFiles(this.solicitacao.documentosAnexados!, listaArquivos);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  private setAttachedDocumentFiles(listaArquivos: ArquivoDocumentoAnexado[], listaBase: ArquivoDocumentoAnexado[]): void{
    let listaBaseAjustada: ArquivoDocumentoAnexado[] = [];
    for(let arquivoBase of listaBase){
      let qtdArquivos = listaArquivos.filter(arquivo => arquivo?.tipoDocumento?.idDocumento == arquivoBase?.tipoDocumento?.idDocumento).length || 1;
        for(let qtdBase = 1; qtdBase <= qtdArquivos; qtdBase++){
          listaBaseAjustada.push(arquivoBase);
        }
    }
    for(let arquivoBase of listaBase){
      let indexArquivo = listaArquivos.findIndex(arquivo => arquivo?.tipoDocumento?.idDocumento == arquivoBase?.tipoDocumento?.idDocumento);
      let indexBase = listaBaseAjustada.findIndex(arquivo => arquivo?.tipoDocumento?.idDocumento == arquivoBase?.tipoDocumento?.idDocumento);
      if(indexArquivo > -1 && indexBase > -1){
        let qtdArquivos = listaArquivos.filter(arquivo => arquivo?.tipoDocumento?.idDocumento == arquivoBase?.tipoDocumento?.idDocumento).length || 0;
        while(qtdArquivos > 0){
          listaBaseAjustada[indexBase].nomeArquivo = listaArquivos[indexArquivo].nomeArquivo;
          listaBaseAjustada[indexBase].dataHoraInclusao = listaArquivos[indexArquivo].dataHoraInclusao;
          listaBaseAjustada[indexBase].filePath = listaArquivos[indexArquivo].filePath;
          listaBaseAjustada[indexBase].contentType = listaArquivos[indexArquivo].contentType;
          listaBaseAjustada[indexBase].id = listaArquivos[indexArquivo].id ?? 0;
          indexArquivo++;
          indexBase++;
          qtdArquivos--;
        }
      }
    }
    let firstRowForAddingButton = listaBaseAjustada.findIndex(arquivo => arquivo.tipoDocumento?.nomeDocumento?.toUpperCase()?.includes('OUTRO'));
    if(firstRowForAddingButton > -1){
      this.minPositionForAdding = firstRowForAddingButton;
    }
    this.arquivosAnexados = [...listaBaseAjustada]
    if(listaArquivos?.length >= 2){
      this.arquivosAnexados = this.solicitacao.documentosAnexados!;
    }
    if(this.solicitacao.documentosAnexados?.length ?? 0 < 2){
      if(this.solicitacao.documentosAnexados?.length == 1){
        if(this.solicitacao.documentosAnexados[0]?.nomeArquivo && this.usuarioInterno){
          this.arquivosAnexados = this.solicitacao.documentosAnexados!;
          this.isSolicitationFinished = true;
        }
      }
    }
    this.arquivosAnexadosData = [...this.arquivosAnexados];
    this.changeDetector.detectChanges();
    this.updateValidity();
  }

  private convertBase64ToFile(base64String: any, fileName: any) {
    let arr = base64String.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: mime });
    return file;

  }

  public downloadAttachedFile(documentoAnexado: LinhaGridDocumentosAnexadosComponent, fileIndex: number): void{
    let arquivo: ArquivoDocumentoAnexado = documentoAnexado?.getAttachedDocument();
    if(arquivo?.arquivoDocumento?.size){
      this.solicitacaoFaturamentoEstimativaCalculoService.downloadFileFromAttachedFiles(arquivo);
      return;
    }
    this.solicitacaoFaturamentoEstimativaCalculoService.downloadFileForBillingSolicitation(arquivo)
      .subscribe({
        next: async (result) => {
          let mimeType = await documentoAnexado.getAttachedDocument().contentType;
          let download = await this.convertBase64ToFile(`data:${mimeType};base64,${result.data as string}`, documentoAnexado?.getAttachedDocument().nomeArquivo!);
          documentoAnexado?.setFileDataFromDownloadedDocument(download);
          this.arquivosAnexados[fileIndex].arquivoDocumento = download;
          this.arquivosAnexadosData[fileIndex].arquivoDocumento = download;
          this.changeDetector?.detectChanges();
          this.solicitacaoFaturamentoEstimativaCalculoService.downloadFileFromAttachedFiles( documentoAnexado?.getAttachedDocument());
          this.updateValidity();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public addFileRow(tipoDocumentoAnexado: TipoDocumentoAnexado, index: number): void{
    this.arquivosAnexadosData?.splice((index+1), 0, {
      tipoDocumento: tipoDocumentoAnexado
    });
    this.arquivosAnexados?.splice((index+1), 0, {
      tipoDocumento: tipoDocumentoAnexado
    });
    this.changeDetector.detectChanges();
  }

  public removeFileRow(index: number): void{
    this.arquivosAnexadosData?.splice(index, 1);
    this.arquivosAnexados?.splice(index, 1);
    this.changeDetector.detectChanges();
  }

  public erase (arquivo: ArquivoDocumentoAnexado): void{
    if (!arquivo.filePath) return;
      this.solicitacaoFaturamentoEstimativaCalculoService.deleteFiles(arquivo)
        .subscribe({
          next: (result) => {
            result?.message?.forEach(
              (message) => {
                this.toastService.success(message);
              }
            );
            this.updateValidity();
          },
          error: (err: HttpErrorResponse) => {
            this.spinner.hide('carregando');
            console.log(err);
            let errorMessage = (err?.error as ErrorResponse);
            if(errorMessage?.errors?.length){
              errorMessage?.errors?.forEach(
                (error) => {
                  if(error?.length){
                    this.toastService.error(error);
                  }
                }
              );
            }
            this.updateValidity();
          }
        });
    }

  public updateAttachedGridLine(arquivo: ArquivoDocumentoAnexado, index: number): void{
    this.arquivosAnexadosData[index] = arquivo;
    this.changeDetector.detectChanges();
    this.updateValidity();
  }

  public updateValidity(): void{
    this.areAttachedFilesValid = this.areAllFilesValid();
    this.changeDetector.detectChanges();
  }
}
