<ng-template #templateImpressao>
  <div class="display-on-print">
    <table *ngFor="let relatorio of relatorios" class="relatorio">
      <thead>
        <tr class="header borderBottom">
          <td colspan="4">
            <img class="logo" src="{{this.logo}}" alt="logo" />
          </td>
          <td colspan="4" >
            <span class="header-nomeRelatorio"> {{nomeRelatorio}} </span>
          </td>
          <td colspan="4">
            <span class="header-now-date">
              {{currentDate.toLocaleString('pt-BR')}}
            </span>
          </td>
        </tr>
        <tr class="header borderBottom" *ngIf="dataInicio && dataFim">
          <td colspan="24">
            <span>
              Período De {{dataInicio ? dataInicio.toLocaleDateString('pt-BR') : ""}} Até {{dataFim ? dataFim.toLocaleDateString('pt-BR') : ''}}
            </span>
          </td>
        </tr>
      </thead>
      <tbody *ngTemplateOutlet="relatorio"></tbody>
      <tfoot>
        <tr class="foot-cont"></tr>
      </tfoot>
    </table>
  </div>
</ng-template>

<ng-template #tableRelatorio #tableRelatorio1>
  <thead>
    <tr>
      <th class="header-10-porcento" >Data/Hora</th>
      <th class="header-10-porcento" >Tipo de Documento</th>
      <th class="header-10-porcento" >Nº de Documento</th>
      <th class="header-10-porcento" >Matrícula Usuário</th>
      <th class="header-10-porcento">Status</th>
      <th class="header-10-porcento">Cliente</th>
      <th class="header-10-porcento">CNPJ/CPF</th>
      <th class="header-10-porcento">Motivo</th>
    </tr>
  </thead>
  <tr *ngFor="let relatorio of dados">
    <td class="header-10-porcento">{{relatorio?.dataHora ? (relatorio.dataHora | date : 'dd/MM/YYYY HH:mm:ss') : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.tipoDoc ? relatorio.tipoDoc : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.numDoc ? relatorio.numDoc : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.matricula ? relatorio.matricula : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.status ? relatorio.status : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.cliente ? relatorio.cliente : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.cnpjCliente ? (relatorio.cnpjCliente | mask : '00.000.000/0000-00||000.000.000-00') : ''}}</td>
    <td class="header-10-porcento">{{relatorio?.motivo ? relatorio.motivo : ''}}</td>
  </tr>
</ng-template>
