<div class="card mt-4" *ngIf="entregaCargaNavio?.length">
    <div id="cardCollapse" class="card-body">
        <div class="table-container">
            <table class="table table-bordered text-nowrap">
                <thead class="thead-light">
                    <tr>
                        <th>Tipo de Documento</th>
                        <th>Nº do Documento</th>
                        <th>Contêiner</th>
                        <th>Data/Hora da Entrega</th>
                        <th>Navio</th>
                        <th>Viagem</th>
                        <th>Booking</th>
                        <th>Status</th>
                        <th>Armador</th>
                        <th>CNPJ Amador</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let entregaCargaNavio of entregaCargaNavio">
                        <td>{{entregaCargaNavio?.tipoDocumento}}</td>
                        <td>{{entregaCargaNavio?.numeroDocumento}}</td>

                        <td>{{entregaCargaNavio.conteiner}}</td>
                        <!-- <td>{{entregaCargaNavio.dataHoraEntrega | date: 'dd/MM/yyyy - HH:MM'}}</td> -->
                        <td>{{entregaCargaNavio.dataHoraEntrega| date: 'dd/MM/yyyy - HH:mm:ss ' : 'UTC+0'}}</td>

                        <td>{{entregaCargaNavio.navio}}</td>
                        <td>{{entregaCargaNavio.viagem}}</td>
                        <td>{{entregaCargaNavio.booking}}</td>
                        <td>{{entregaCargaNavio.status}}</td>
                        <td>{{entregaCargaNavio.armador}}</td>
                        <td>{{entregaCargaNavio.cnpjArmador}}</td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- <app-visualizar-solicitacoes #visualizarSolicitacoes></app-visualizar-solicitacoes> -->
