<ng-template #modal>
    <div class="modal-content">
      <div class="modal-header">
            <span class="modal-title" id="exampleModalLabel">Documentos Anexados</span>
            <button type="button" class="btn close btn-close" data-bs-dismiss="modal" aria-label="close"
                (click)="closeModal()">&times;</button>
              </div>
              <div class="modal-body" *ngIf="solicitacao">
                <table class="grid-table">
                <tbody *ngIf="arquivosAnexados">
                    <tr class="readonly-grid-line" *ngFor="let arquivo of arquivosAnexados; let i = index"
                        app-linha-grid-documentos-anexados [receivedDocument]="arquivo"
                        [finishedSolicitation]="isSolicitationFinished"
                        [isSolicitationFinished]="isSolicitationFinished"
                        (onDisplayFile)="displayAttachedFile(documentoAnexado, i)"
                        (onUpdateAttachedDocument)="updateAttachedGridLine(documentoAnexado.getAttachedDocument(), i)"
                        (onDownloadFile)="downloadAttachedFile(documentoAnexado, i)"
                        (onDeleteFile)="erase($event)"
                        #documentoAnexado
                        >
                        <td *ngIf="!isSolicitationFinished && minPositionForAdding! > -1 && arquivo?.tipoDocumento?.nomeDocumento?.toUpperCase()?.includes('OUTRO')"
                            class="text-center add-or-remove-button">
                            <button *ngIf="i === minPositionForAdding; else buttonForRemovingRow"
                                (click)="addFileRow(arquivo.tipoDocumento, i)">
                                &plus;
                            </button>
                            <ng-template #buttonForRemovingRow>
                                <button (click)="removeFileRow(i)">
                                    &minus;
                                </button>
                            </ng-template>
                        </td>
                    </tr>
                  </tbody>
                </table>
            <div class="row" *ngIf="!isSolicitationFinished">
              <div class="col-4"></div>
              <div class="col-4 action-button-group">
                <button class="action-primary-button" (click)="OpenmodalOkCancel()"
                *ngIf="!enviarModalDocumentoAnexo"
                  [disabled]="!areAttachedFilesValid">Enviar</button>
                    <div class="container" *ngIf="enviarModalDocumentoAnexo">
                      <label *ngIf="enviarModalDocumentoAnexo" class="box-label">Deseja enviar documentos anexados?</label>
                      <div class="box-button">
                        <button class="action-primary-button button-ok-cancel" (click)="CancelEnvio()">Cancelar</button>
                        <button class="action-primary-button button-ok-cancel" (click)="sendAttachedDocuments()">Enviar</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-4"></div>
                </div>
              </div>
            </div>
      </ng-template>

          <app-modal-ok-cancel #modalOkCancel
          [modalTitle]="'Deseja Confirmar Envio?'"
          [positiveText]="'Enviar'"
          [negativeText]="'Cancelar'"
    (onChoosePositiveAction)="sendAttachedDocuments()"
    >
    </app-modal-ok-cancel>

