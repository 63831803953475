<div [auth]="this.visualizacao">
  <div class="mt-5">
    <app-title [title]="title"></app-title>
    <app-reprovacao-aprovacao-liberacao></app-reprovacao-aprovacao-liberacao>
  </div>
  <div class="d-flex justify-content-between align-items-center mt-5 w-100">
    <div class="row w-100">
      <div class="col-5 action-button-group action-double-buttons">
        <button class="action-tertiary-button" (click)="salvarExcel()" [disabled]="!isLiberationListNotEmpty()">
          <img src="assets/images/SaveDoc.png" alt="">
          <span class="ml-2">SALVAR PLANILHA</span>
        </button>
        <button class="action-tertiary-button" (click)="imprimirPdf()" [disabled]="!isLiberationListNotEmpty()">
          <img src="assets/images/PrintDoc.png" alt="">
          <span class="ml-2">IMPRIMIR</span>
        </button>
      </div>
      <div class="col-4"></div>
      <div class="solicitation-action-buttons action-button-group col-3">
        <button class="action-tertiary-button" (click)="clean()">LIMPAR CAMPOS</button>
      </div>
    </div>
  </div>
</div>
