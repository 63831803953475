<td *ngIf="conteiner">
  <div class="input-group">
    <input type="text" class="form-control" readonly
      [formControl]="conteiner"
    >
  </div>
</td>
<td>
  <ng-container [ngTemplateOutlet]="shouldBlockPartQuantityEditionByDocumentType ? numeroCEField : numeroDocumentoParteField"></ng-container>
</td>
<td>
  <ng-container [ngTemplateOutlet]="shouldBlockPartQuantityEditionByDocumentType ? numeroDocumentoParteField : canalDocumentoParteField"></ng-container>
</td>

<ng-template #numeroCEField>
  <div class="input-group" *ngIf="numeroCE">
    <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="numeroCE" [mask]="'000000000000000'"
      (change)="onFirstEdit.emit()" [ngClass]="{'border border-danger': numeroCE.touched && numeroCE.errors}"
    >
  </div>
</ng-template>

<ng-template #numeroDocumentoParteField>
  <div class="input-group" *ngIf="numeroDocumentoParte">
    <input *ngIf="mascara?.modelo; else numeroDocumentoParteFieldNomask" type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
      [formControl]="numeroDocumentoParte" [mask]="mascara?.modelo ?? ''" [specialCharacters]="mascara?.caracteresEspeciais ?? []"
      (change)="onFirstEdit.emit()" [ngClass]="{'border border-danger': numeroDocumentoParte.touched && numeroDocumentoParte.errors}"
    ></div>
    <ng-template #numeroDocumentoParteFieldNomask>
      <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
        [formControl]="numeroDocumentoParte"
        (change)="onFirstEdit.emit()" [ngClass]="{'border border-danger': numeroDocumentoParte.touched && numeroDocumentoParte.errors}">
    </ng-template>
</ng-template>

<ng-template #canalDocumentoParteField>
  <select class="custom-select" [formControl]="canalDocumentoParte" *ngIf="canalDocumentoParte"
    (change)="onFirstEdit.emit()" [ngClass]="{'border border-danger': canalDocumentoParte.touched && canalDocumentoParte.errors}"
  >
    <option value="" disabled selected>Selecione</option>
    <option *ngFor="let canal of canais" [ngValue]="canal.nome">{{canal.nome}}</option>
  </select>
</ng-template>
