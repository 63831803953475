import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { AuthRoles } from '../models/authRoles';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Directive({
    selector: '[auth]'
})
export class AuthDirective implements OnInit {
    @Input('auth') permission!: AuthRoles; // Required permission passed in

    constructor(private el: ElementRef, private authService: AuthenticationService) { }

    ngOnInit() {
        if (!this.authService.isUsuarioRoot()) {

            if (this.permission.tela && this.permission.permissao) {
                if (!this.authService.verificarAcessoPermissao(this.permission.tela, this.permission.permissao)) {
                    this.el.nativeElement.style.display = 'none';
                }
            }
        }
    }
}