<ng-template #modalExibirAnexos>
  <div class="modal-content">
    <div class="modal-header">
      <span class="modal-title" id="exampleModalLabel">Exibir Documento</span>
      <button type="button" class="btn close btn-close" data-bs-dismiss="modal" aria-label="close"
      (click)="closeModal()">&times;</button>
    </div>
    <div class="modal-body" *ngIf="fileStringUrl">
      <div class="file-container" [ngSwitch]="fileExtension">
        <!-- <object [data]="fileStringUrl" type="application/pdf" *ngSwitchCase="'pdf'"></object> -->
        <!-- <iframe [src]="fileStringUrl" *ngSwitchCase="'pdf'" type="application/pdf"></iframe> -->
        <pdf-viewer
          #pdfViewer
          *ngSwitchCase="'pdf'"
          [src]="fileStringUrl"
          [render-text]="true"
          [original-size]="false"
        ></pdf-viewer>
        <img [src]="fileStringUrl" *ngSwitchDefault>
      </div>
    </div>
  </div>
</ng-template>
