import { DocumentosService } from './../../../services/documentos.service';
import { DocumentCustomPattern } from './../../../shared/utils/constantes-documentos';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Campo } from 'src/app/models/Campo';
import { Moeda } from 'src/app/models/Moeda';
import { debounceTime } from 'rxjs';
import { DadosComplementaresCabotagem } from 'src/app/models/SolicitacaoLiberacaoCabotagem/DadosComplementaresCabotagem';
import { Modalidade } from 'src/app/models/Modalidade';
import { formatDate } from '@angular/common';
import { NomeDespachante } from 'src/app/models/LiberacaoDocumental/NomeDespachante';
import { DadosNavioVigemDTO } from 'src/app/models/SolicitacaoLiberacaoCabotagem/DadosNavioVigemDTO';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { MaskPipe } from 'ngx-mask';
@Component({
  selector: 'app-dados-complementares-cabotagem',
  templateUrl: './dados-complementares-cabotagem.component.html',
  styleUrls: ['./dados-complementares-cabotagem.component.scss']
})
export class DadosComplementaresCabotagemComponent implements OnInit, AfterViewInit, AfterContentInit{
  public isCollapsed: boolean = false;
  @Input() public Moedas: Moeda[] = [];
  public form!: UntypedFormGroup;
  private colorTheme = 'theme-dark-blue';
  public bsConfig?: Partial<BsDatepickerConfig>;
  public namePattern = DocumentCustomPattern.NAME_PATTERN;
  public dadosComplementares!: DadosComplementaresCabotagem;
  public locale = 'pt-br';
  public readonly modalidades: Modalidade[] = [
    {id: 1, nome: 'Porta', descricao: 'Porta'},
    {id: 2, nome: 'Porto', descricao: 'Porto'}
  ];
  public nomesDespachantes: NomeDespachante[] = [];
  public usuarioExterno!: boolean;
  public nomeUsuarioLogado: string = '';

  @Input() visualizar?: boolean = false;
  @Input() camposFormulario!: Campo[];
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Output() dataFormOut: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() onComplementaryDataLoad: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private localeService: BsLocaleService,
    private authentication: AuthenticationService,
    private maskPipe: MaskPipe
  ) { }

  public ngOnInit(): void {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.validation();
  }

  public ngAfterViewInit(): void {
    if(!this.camposFormulario || !this.camposFormulario.length){
      return;
    }
    this.camposFormulario.forEach(
      (campo) => {
        if(campo.obrigatorio){
          this.form.get(campo.nome)?.addValidators(Validators.required);
        }
      }
    );
  }

  public ngAfterContentInit(): void {
    this.onComplementaryDataLoad.emit();
  }

  private validation(): void{
    this.form = this.fb.group({
      navio: [''],
      viagem: [''],
      valorMercadoria: [''],
      moeda: [this.Moedas[0],Validators.compose([])],

      dataRegistro: [null,Validators.compose([
        Validators.minLength(3),
        Validators.maxLength(10)
      ])],
      modalidade: [''],
      nomeDespachante: [''],
      telefoneContato: [''],
      emailContato: ['', [Validators.email]],
      descargaDireta: [''],
      comentario: [''],
      statusDocumento: [''],
      numeroCE: ['']
    });
    this.form.valueChanges
      .subscribe({
        next: () => {
          this.sendComplementaryData();
        }
      });
    this.sendComplementaryData();
    // this.validateBlockAndDataOnSolicitante();
  }

  public checkIfFieldIsRequired(fieldName: string): boolean{
    return !!this.camposFormulario?.find(
      campo => campo.nome == fieldName
    )?.obrigatorio;
  }

  public checkIfFieldIsReadonly(fieldName: string): boolean{
    return !!this.camposFormulario?.find(
      campo => campo.nome == fieldName
    )?.leitura;
  }

  // public validateBlockAndDataOnSolicitante(): void{
  //   this.usuarioExterno = !this.authentication.ehUsuarioInterno;
  //   let nomeDespachante = this.form.get('nomeDespachante');
  //   if(this.visualizar){

  //   }
  //   else{
  //     if(this.usuarioExterno){
  //       this.nomeUsuarioLogado = this.authentication.fullName ?? '';
  //     }
  //   }
  // }

  public getCabotageComplementaryDataFromForm(): DadosComplementaresCabotagem{
    let solicitacao: DadosComplementaresCabotagem = this.form.getRawValue() as DadosComplementaresCabotagem;
    solicitacao.modalidade = this.modalidades.find(modalidade => modalidade.id == this.form.value.modalidade)!;
    return solicitacao;
  }

  public removeSpacesFromExtremities(inputName: string): void{
    this.form.get(inputName)?.setValue((this.form.get(inputName)?.value as string).trim());
  }

  public sendComplementaryData(): void {
    this.dadosComplementares = {
      navio: this.form.value.navio,
      viagem: this.form.value.viagem,
      valorMercadoria: this.form.value.valorMercadoria,
      moeda: this.Moedas.find(moeda => moeda.id === this.form.value.moeda)!,
      dataRegistro: this.form.value.dataRegistro,
      modalidade: this.form.value.modalidade,
      nomeDespachante: this.form.value.nomeDespachante,
      telefoneContato: this.form.value.telefoneContato,
      emailContato: this.form.value.emailContato,
      descargaDireta: this.form.value.descargaDireta,
      comentario: this.form.value.comentario,
      statusDocumento: this.form.value.statusDocumento,
      numeroCE: this.form.value.numeroCE
    }
    this.dataFormOut.emit({'dadosComplementares': this.dadosComplementares});
  }

  public setFormValuesFromReceivedComplementaryData(complementaryData: DadosComplementaresCabotagem | undefined): void{
    if(!complementaryData){
      return;
    }
    this.form.get('numeroCE')?.setValue(complementaryData?.numeroCE ? complementaryData?.numeroCE : this.form.value.numeroCE);
    this.form.get('navio')?.setValue(complementaryData?.navio ? complementaryData?.navio : this.form.value.navio);
    this.form.get('viagem')?.setValue(complementaryData?.viagem ? complementaryData?.viagem : this.form.value.viagem);
    this.form.get('valorMercadoria')?.setValue(complementaryData?.valorMercadoria ? complementaryData?.valorMercadoria : this.form.value.valorMercadoria);
    if(complementaryData?.moeda){
      this.form.get('moeda')?.setValue(complementaryData?.moeda?.id)
    }
    if( complementaryData?.dataRegistro){
      this.form.get('dataRegistro')?.setValue(new Date(complementaryData?.dataRegistro));
    }
    if(complementaryData?.modalidade){
      this.form.get('modalidade')?.setValue(
        this.modalidades.find(modalidade => modalidade?.id == complementaryData?.modalidade?.id ?? 0)?.id ?? 0
      );
    }
    this.form.get('nomeDespachante')?.setValue(complementaryData?.nomeDespachante ? complementaryData?.nomeDespachante : this.form.value.nomeDespachante);
    var despachante: NomeDespachante []=[{nomeDespachante: this.form.get('nomeDespachante')?.value ?? ''}]
    this.setNomesDespachantes(despachante);
    this.form.get('telefoneContato')?.setValue(complementaryData?.telefoneContato ? complementaryData?.telefoneContato : this.form.value.telefoneContato);
    this.form.get('emailContato')?.setValue(complementaryData?.emailContato ? complementaryData?.emailContato : this.form.value.emailContato);
    this.form.get('descargaDireta')?.setValue(complementaryData?.descargaDireta ? complementaryData?.descargaDireta : this.form.value.descargaDireta);
    this.form.get('comentario')?.setValue(complementaryData?.comentario ? complementaryData?.comentario : this.form.value.comentario);
    this.form.get('statusDocumento')?.setValue(complementaryData?.statusDocumento ? complementaryData?.statusDocumento : this.form.value.statusDocumento);
    this.changeDetector.detectChanges();
    // this.validateBlockAndDataOnSolicitante();
  }

  public cancel(){
    this.form.reset();
  }

  public areComplementaryDataValid(): boolean{
    return !this.form?.invalid;
  }

  public modalidadeIsValid(): boolean{
    return this.form.get('modalidade')?.value == 0 ? false : true
  }
  public setTelefoneContatoEmail(telefoneContato: string = "") {
    console.log(telefoneContato);
    let telefoneFormatado = this.maskPipe.transform(telefoneContato, '(00) 0 0000-0000')
   this.form?.patchValue({
    telefoneContato: !!telefoneContato ? telefoneFormatado : this.form?.value?.telefoneContato,
    emailContato: this.authentication.userEmail != null ? this.authentication.userEmail : this.form?.value?.emailContato,
   });

   this.changeDetector.detectChanges();
  }

  public setFormValuesFromCTEXML(xml: Partial<DadosComplementaresCabotagem>){
    this.form.patchValue({
      ...xml,
      moeda: this.Moedas.find(
        moeda => moeda.nome == 'Real'
      )
    }, {emitEvent: false});
  }

  public setFormValuesFromNFXML(xml: any){
    this.form.patchValue({
      valorMercadoria: xml.nfeProc.NFe.infNFe.total.ICMSTot.vNF._text,
      dataRegistro: xml.nfeProc.NFe.infNFe.ide.dhEmi._text ? new Date(xml.nfeProc.NFe.infNFe.ide.dhEmi._text): "",
      moeda: this.Moedas.find(
        moeda => moeda.nome == 'Real'
      )
    }, {emitEvent: false});
  }

  public setSettingsfromFields(): void{
    if(!this.camposFormulario?.length){
      if(this.visualizar){
        this.form.disable();
        this.changeDetector.detectChanges();
        // this.validateBlockAndDataOnSolicitante();
      }
      else{
        this.enableAllFormFields();
      }
      return;
    }
    for(let control in this.form.controls){
      let fieldIndex = this.camposFormulario.findIndex(
        (field) => field.nome == control
      );
      if(fieldIndex >= 0){
        if(this.camposFormulario[fieldIndex].obrigatorio){
          this.form.get(control)?.addValidators(Validators.required);
        }
        else{
          this.form.get(control)?.removeValidators(Validators.required);
        }
        if(this.doesSolicitationHaveIntegration){
          if(this.camposFormulario[fieldIndex].leitura && !!this.form.get(control)?.value){
            this.form.get(control)?.disable();
          }
          else{
            this.form.get(control)?.enable();
          }
        }
        else{
          if(this.camposFormulario[fieldIndex].leitura){
            this.form.get(control)?.disable();
          }
          else{
            this.form.get(control)?.enable();
          }
        }
        if(!!this.camposFormulario[fieldIndex]?.bloqueado){
          this.form.get(control)?.disable();
        }
        if(!this.form.get(control)?.value && this.camposFormulario[fieldIndex].obrigatorio){
          this.form.get(control)?.enable();
        }
      }
      else{
        this.form.get(control)?.enable();
        this.form.get(control)?.removeValidators(Validators.required);
      }
      this.changeDetector.detectChanges();
      this.showFieldAsRequired(control);
    }
    this.changeDetector.detectChanges();
    // this.validateBlockAndDataOnSolicitante();
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.form.controls){
      this.form.get(field)?.enable();
      this.form.get(field)?.removeValidators(Validators.required);
      this.showFieldAsRequired(field);
      this.changeDetector.detectChanges();
    }
  }

  private showFieldAsRequired(field: string): void{
    if(!!this.form.get(field)?.hasValidator(Validators.required)){
      document.getElementById(`${field}-required-label`)?.classList?.remove('invisible');
      document.getElementById(`${field}-required-label`)?.classList?.add('visible');
    }
    else{
      document.getElementById(`${field}-required-label`)?.classList?.remove('visible');
      document.getElementById(`${field}-required-label`)?.classList?.add('invisible');
    }
    this.changeDetector.detectChanges();
  }

  public setNomesDespachantes(nomesDespachantes: NomeDespachante[]): void{
    this.nomesDespachantes = nomesDespachantes ?? [];
    if (!this.authentication.ehUsuarioInterno) {
      this.form.get('nomeDespachante')?.setValue(this.nomesDespachantes[0].nomeDespachante? this.nomesDespachantes[0].nomeDespachante : this.form.value.nomeDespachante);
    }
    this.changeDetector.detectChanges();
  }

  public setShipAndTripFromSearchByContainerNumber(shipAndTrip: DadosNavioVigemDTO): void{
    this.form?.patchValue({
      navio: shipAndTrip?.navio ? shipAndTrip.navio : this.form?.value?.navio,
      viagem: shipAndTrip?.viagem ? shipAndTrip.viagem : this.form?.value?.viagem
    });
  }

}
