<ng-template #modal>
  <div class="modal-content">
    <div class="modal-header">
      <span class="modal-title" id="exampleModalLabel">Extraindo dados do Siscomex</span>
      <button type="button" class="btn close btn-close" data-bs-dismiss="modal" aria-label="close"
      (click)="closeModal()" [disabled]="integracoes.length && !areAllIntegrationsDone && !areIntegrationsTimedOut">&times;</button>
    </div>
    <div class="modal-body">
      <div *ngIf="!areIntegrationsTimedOut; else integrationTimeout">
        <div class="integration-item" *ngFor="let integracao of integracoes">
          <p>{{integracao.descricao}}</p>
          <img src="assets/images/{{integracao.concluido ? 'check' : 'loading'}}.svg">
        </div>
      </div>
      <ng-template #integrationTimeout>
        <div class="integration-item">
          <p>Os serviços do Siscomex estão apresentando lentidão, tente realizar essa consulta dentro de alguns minutos</p>
          <img src="assets/images/cross.svg">
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
