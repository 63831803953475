import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pluck, take } from 'rxjs';
import { SolicitacaoLiberacaoIMP } from '../models/SolicitacaoLiberacaoIMP/SolicitacaoLiberacaoIMP';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalvarRascunhoService {

  constructor(private http: HttpClient) { }

  public postSalvarRascunho(solicitacaoLiberacaoImp: SolicitacaoLiberacaoIMP): Observable<SolicitacaoLiberacaoIMP>{
    return this.http.post<SolicitacaoLiberacaoIMP>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP/Rascunho`, solicitacaoLiberacaoImp).pipe(take(1));
  }
}
