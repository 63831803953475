<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Documento <span class="text-danger" *ngIf="!isDocumentValid()">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
            <!--fa fa-chevron-down-->
        </div>
    </div>

    <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-3">
                    <div class="col">
                        <label>Tipo do Documento: *</label>
                        <select formControlName="tipoDocumento" class="custom-select" #docselect (change)="changeDocumentTypeOnSelect(docselect.value)" [ngClass]="{'border border-danger': form.get('tipoDocumento')?.invalid && form.get('tipoDocumento')?.touched && visualizar == false}"
                            [attr.disabled]="visualizar ? '': null">
                    <option value="" disabled selected>Selecione</option>
                    <option *ngFor="let item of tipoDocumento" [value]="item.nome">{{item.nome}}</option>
                  </select>
                        <div *ngIf="form.get('tipoDocumento')?.errors && form.get('tipoDocumento')?.touched">
                            <p class="text-danger" *ngIf="form.get('tipoDocumento')?.errors?.['required']" [hidden]="visualizar == true">
                                Campo de preenchimento obrigatório!
                            </p>
                        </div>
                    </div>
                    <div class="col">
                        <label>N° Documento: *</label>
                        <div class="input-group">
                            <input *ngIf="!documentPattern; else inputForPatternUse" formControlName="numeroDocumento" [maxlength]="50" #numDoc type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." [mask]="maskDoc" [specialCharacters]="specialCharsForMask"
                                [ngClass]="{'border border-danger': form.get('numeroDocumento')?.invalid && form.get('numeroDocumento')?.touched && visualizar == false}" [attr.disabled]="visualizar ? '': null">
                            <ng-template #inputForPatternUse>
                                <input formControlName="numeroDocumento" [maxlength]="50" #numDoc type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." [mask]="maskDoc" [specialCharacters]="specialCharsForMask" [patterns]="documentPattern" [ngClass]="{'border border-danger': form.get('numeroDocumento')?.invalid && form.get('numeroDocumento')?.touched}">
                            </ng-template>
                            <button (click)="getData()" class="action-primary-button" [disabled]="form.invalid" [hidden]="isInsideModal" type="submit">PESQUISAR</button>
                        </div>
                        <div *ngIf="form.get('numeroDocumento')?.errors && form.get('numeroDocumento')?.touched">
                            <p class="text-danger" *ngIf="form.get('numeroDocumento')?.errors?.['required']" [hidden]="visualizar == true">
                                Campo de preenchimento obrigatório!
                            </p>
                            <p class="text-danger" *ngIf="form.get('numeroDocumento')?.errors?.['mask']" [hidden]="visualizar == true">
                                Preencher no formato correto!
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div *ngIf="isNumeroCEVisible">
                            <label>Nº CE: *</label>
                            <input type="text" class="form-control" [mask]="maskForCE" required formControlName="numeroCE" [ngClass]="{'border border-danger': form.get('numeroCE')?.touched && form.get('numeroCE')?.errors && visualizar == false}" [attr.disabled]="visualizar ? '': null">
                            <div *ngIf="form.get('numeroCE')?.errors && form.get('numeroCE')?.touched">
                                <p class="text-danger" *ngIf="form.get('numeroCE')?.errors?.['required']" [hidden]="visualizar == true">
                                    Campo de preenchimento obrigatório!
                                </p>
                                <p class="text-danger" *ngIf="form.get('numeroCE')?.errors?.['mask']" [hidden]="visualizar == true">
                                    Preencher no formato correto!
                                </p>
                            </div>
                        </div>
                        <div *ngIf="form.get('tipoDocumento')?.value == 'DAT'">
                            <label>Nº DUE: *</label>
                            <app-campo-numero-due *ngFor="let numeroDUE of numerosDUE; let i = index" [index]="i" (onAddNumeroDUE)="addNumeroDUE()" (onRemoveNumeroDUE)="removeNumeroDUE(i)" [numeroDUESalvo]="numeroDUE" (onUpdateNumeroDUE)="updateNumerosDUE()" [attr.disabled]="visualizar ? '': null"></app-campo-numero-due>
                        </div>
                    </div>
                    <div class="col d-flex">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
