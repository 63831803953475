<div class="my-5">
    <div class="table-container" *ngIf="rpaApiList && rpaApiList.length">
        <table class="grid-table">
            <thead class="text-nowrap">
                <tr>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th>Tipo</th>
                    <th>Status</th>
                    <th>Dt/Hr Último Processamento</th>
                    <th>Dt/Hr Última Falha</th>
                    <th>Qtd Total Execuções</th>
                    <th>Qtd Total de Sucessos</th>
                    <th>Qtd Total de Falhas</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rpaApi of rpaApiList" class="readonly-grid-line">
                    <td>{{rpaApi.id}}</td>
                    <td><span class="rpa-api-details" (click)="searchDetails(rpaApi)">{{rpaApi.nome}}</span></td>
                    <td>{{rpaApi.descricao}}</td>
                    <td>{{this.getTipoDesc(rpaApi)}}</td>
                    <td class="{{this.isRpa(rpaApi) === false ? '' : (this.rpaIsAtive(rpaApi) ? 'status-active' : 'status-inactive')}}">{{this.isRpa(rpaApi) === false ? '' : this.getStatusDesc(rpaApi)}}</td>
                    <td>{{rpaApi.dataHoraUltimoProcessamento | date : "dd/MM/yyyy HH:mm"}}</td>
                    <td>{{rpaApi.quantidadeTotalFalhas == 0 ? '': rpaApi.dataHoraUltimaFalha | date : "dd/MM/yyyy HH:mm"}}</td>
                    <td>{{rpaApi.quantidadeTotalExecucoes}}</td>
                    <td>{{rpaApi.quantidadeTotalSucesso}}</td>
                    <td>{{rpaApi.quantidadeTotalFalhas}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>