import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ConteinerEstimativaCalculo } from './../../../../models/EstimativaCalculo/CadastroDocumento/ConteinerEstimativaCalculo';
import { Component, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Campo } from 'src/app/models/Campo';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'tr[app-linha-grid-relacao-conteiner-estimativa-calculo]',
  templateUrl: './linha-grid-relacao-conteiner-estimativa-calculo.component.html',
  styleUrls: ['./linha-grid-relacao-conteiner-estimativa-calculo.component.scss']
})
export class LinhaGridRelacaoConteinerEstimativaCalculoComponent implements AfterViewInit {

  @Input() public conteinerReceived!: ConteinerEstimativaCalculo;
  @Input() fieldSettings!: Campo[];
  @Input() public visualizar?: boolean = false
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() shouldBlockPartQuantityEditionByDocumentType?: boolean;
  @Input() shouldQuebraLoteAppear?: boolean;
  @Output() public onSendContainer: EventEmitter<ConteinerEstimativaCalculo> = new EventEmitter<ConteinerEstimativaCalculo>();
  @Output() public onCheckValidness: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onEraseLine: EventEmitter<void> = new EventEmitter<void>();
  @Output() onManualSelection: EventEmitter<void> = new EventEmitter<void>();
  @Output() onManualContainerNumberEdition: EventEmitter<string> = new EventEmitter<string>();
  public isConteinerFocused: boolean = false;
  public minPartQuantity: number = 2;

  public selecionado!: UntypedFormControl;
  public id!: UntypedFormControl;
  public conteiner!: UntypedFormControl;
  public reefer!: UntypedFormControl;
  public imo!: UntypedFormControl;
  public oog!: UntypedFormControl;
  public conteinerTransload!: UntypedFormControl;
  public quebraLote!: UntypedFormControl;
  public liberadoAte!: UntypedFormControl;
  public parte!: UntypedFormControl;
  public quantidadePartes!: UntypedFormControl;
  public qtdPartesFront!: UntypedFormControl;
  public form!: UntypedFormGroup;

  private readonly colorTheme = 'theme-dark-blue';
  public bsConfig?: Partial<BsDatepickerConfig>;
  private readonly locale = 'pt-br';

  constructor(
    private localeService: BsLocaleService,
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,

  ) { }

  public ngAfterViewInit(): void {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.validation();
  }

  private validation(): void{
    this.minPartQuantity = this.conteinerReceived.quantidadePartes ? this.conteinerReceived.quantidadePartes : 2
    this.selecionado = new UntypedFormControl(false, Validators.compose([]));
    this.id = new UntypedFormControl(this.conteinerReceived?.id ?? 0, Validators.compose([Validators.required]));
    this.conteiner = new UntypedFormControl(this.conteinerReceived?.conteiner, Validators.compose([Validators.required]));
    this.conteinerTransload = new UntypedFormControl(this.conteinerReceived?.conteinerTransload, Validators.compose([]));
    this.reefer = new UntypedFormControl(!!this.conteinerReceived?.reefer, Validators.compose([]));
    this.imo = new UntypedFormControl(!!this.conteinerReceived?.imo, Validators.compose([]));
    this.oog = new UntypedFormControl(!!this.conteinerReceived?.oog, Validators.compose([]));
    this.quebraLote = new UntypedFormControl(!!this.conteinerReceived?.quebraLote, Validators.compose([]));
    this.liberadoAte = new UntypedFormControl(
      (this.conteinerReceived?.liberadoAte ? new Date(this.conteinerReceived?.liberadoAte) : null),
      Validators.compose([])
    );
    this.parte = new UntypedFormControl(!!this.conteinerReceived.parte, Validators.compose([Validators.required]));
    this.quantidadePartes = new UntypedFormControl(this.conteinerReceived.quantidadePartes, Validators.compose([]));
    this.qtdPartesFront = new UntypedFormControl(this.conteinerReceived.quantidadePartes, Validators.compose([Validators.required]));
    this.form = this.fb.group({
      id: this.id,
      conteiner: this.conteiner,
      reefer: this.reefer,
      imo: this.imo,
      oog: this.oog,
      conteinerTransload: this.conteinerTransload,
      quebraLote: this.quebraLote,
      liberadoAte: this.liberadoAte,
      parte: this.parte,
      quantidadePartes: this.quantidadePartes,
    });
    this.conteinerTransload?.valueChanges
    .pipe(
      debounceTime(100)
    )
    .subscribe(
      (valConteinerTransload: string) => {
        valConteinerTransload = valConteinerTransload ?? '';
        this.form.patchValue({conteinerTransload: valConteinerTransload.toUpperCase()}, {emitEvent: false});
        }
  );
    this.conteiner?.valueChanges
    .pipe(
      debounceTime(100)
    )
    .subscribe(
      (valConteiner: string) => {
        valConteiner = valConteiner ?? '';
        this.form.patchValue({conteiner: valConteiner.toUpperCase()}, {emitEvent: false});
        }
  );
    this.form.valueChanges.subscribe(
      () => {
        this.updateContainer();
        this.setValidatorForQuantidadePartes(!!this.form?.get('parte')?.value);
      }
    );
    this.selecionado.valueChanges.subscribe(
      () => {this.updateContainer();}
    );
    this.changeDetector.detectChanges();
    this.updateContainer();
    this.updateValidators();
    this.sendContainerNumberToSearchIfNoIntegration();
  }

  public sendContainer(): ConteinerEstimativaCalculo{
    return this.form.getRawValue() as ConteinerEstimativaCalculo;
  }

  public isFormValid(): boolean{
    if(this.isConteinerFocused){
      return false;
    }
    return !this.form?.invalid;
  }

  private updateContainer(): void{
    this.onSendContainer.emit();
  }

  public updateValidators(): void{
    this.validateBlocks();
    if (this.visualizar) {
      return;
    }
    if(!this.fieldSettings?.length){
      return;
    }
    for(let field in this.form.controls){
      let fieldSetting: Campo = this.fieldSettings.find(
        (setting) => setting.nome == field
      ) as Campo;
      if(fieldSetting){
        if(fieldSetting.obrigatorio){
          this.form.get(field)?.addValidators(Validators.required);
        }
        else{
          this.form.get(field)?.removeValidators(Validators.required);
        }
        if(field == 'conteiner' || field == 'conteinerTransload'){
          if(this.doesSolicitationHaveIntegration){
            this.conteiner?.disable({emitEvent: false});
            this.conteinerTransload?.disable({emitEvent: false});
            // this.selecionado?.disable({emitEvent: false});
          }
          else{
            this.form.get(field)?.enable({emitEvent: false});
          }
        }
        else{
          if(this.doesSolicitationHaveIntegration){
            if(fieldSetting.leitura){
              this.form.get(field)?.disable({emitEvent: false});
            }
            else{
              this.form.get(field)?.enable({emitEvent: false});
            }
          }
        }
        if(fieldSetting.bloqueado){
          this.form.get(field)?.disable();
        }
        if(!this.form.get(field)?.value && fieldSetting.obrigatorio){
          this.form.get(field)?.enable();
        }
      }
      else{
        this.form.get(field)?.removeValidators(Validators.required);
        this.form.get(field)?.enable();
      }
    }
    this.validateBlocks();
  }

  public enableAllFormFields(): void{
    for(let field in this.form.controls){
      this.form.get(field)?.enable({emitEvent: false});
      this.form.get(field)?.removeValidators(Validators.required);
    }
  }

   public disableAllFormFields(): void{
    this.form.disable({emitEvent: false});
  }

  private validateBlocks(): void{
    if (this.visualizar) {
      this.disableAllFormFields();
      this.selecionado.disable({ emitEvent: false });
      this.changeDetector.detectChanges();
      return;
    }
    if(!this.fieldSettings?.length){
      this.enableAllFormFields();
      return;
    }
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      this.parte?.disable({onlySelf: true, emitEvent: false});
      this.quantidadePartes?.disable({onlySelf: true, emitEvent: false});
      this.qtdPartesFront?.disable({onlySelf: true, emitEvent: false});
    }
  }

  public setContainerAsIgnorable(): void{
    this.quebraLote?.setValue(true);
  }

  public eraseLine(): void{
    if(this.doesSolicitationHaveIntegration || !this.selecionado?.value){
      return;
    }
    this.onEraseLine.emit();
  }

  public isContainerValid(): boolean{
    return !this.form?.invalid;
  }

  public switchSelectionFromParent(selected: boolean): void{
    this.selecionado?.setValue(selected);
    // this.switchEdition();
  }

  public sendContainerNumberToSearchIfNoIntegration(): void{
    if(!this.conteiner?.value?.length){
      return;
    }
    this.onManualContainerNumberEdition.emit(this.conteiner.value)
  }

  public setValidValueForArvorePartes(parte: boolean): void{
    if(parte){
      this.form.patchValue({
        quantidadePartes:
          this.quantidadePartes.value ?
          (this.quantidadePartes.value < this.minPartQuantity ? this.minPartQuantity : this.quantidadePartes.value) :
          this.minPartQuantity
      }, {emitEvent: true});
      this.qtdPartesFront.setValue(
        this.quantidadePartes.value ?
        (this.quantidadePartes.value < this.minPartQuantity ? this.minPartQuantity : this.quantidadePartes.value) :
        this.minPartQuantity
      );
    }
    else{
      this.form.patchValue({
        quantidadePartes: 0
      }, {emitEvent: true});
      this.qtdPartesFront.setValue(0);
    }
    this.changeDetector.detectChanges();
  }

  private setValidatorForQuantidadePartes(validate: boolean): void{
    if(validate){
      this.quantidadePartes.addValidators([Validators.required, Validators.min(this.minPartQuantity)]);
      this.qtdPartesFront.addValidators([Validators.required, Validators.min(this.minPartQuantity)]);
    }
    else{
      this.quantidadePartes.clearValidators();
      this.qtdPartesFront.removeValidators([Validators.required, Validators.min(this.minPartQuantity)]);
    }
    this.quantidadePartes?.updateValueAndValidity({emitEvent: false});
    this.changeDetector.detectChanges();
  }
}
