import { debounceTime } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterContentInit, Input, ViewChildren, QueryList } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, FormControl, FormGroup, Validators, UntypedFormGroup } from '@angular/forms';
import { Campo } from 'src/app/models/Campo';
import { ConteinerEXP } from 'src/app/models/SolicitacaoLiberacaoExportacao/ConteinerEXP';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { LinhaGridRelacaoConteinerExportacaoComponent } from '../linha-grid-relacao-conteiner-exportacao/linha-grid-relacao-conteiner-exportacao.component';
@Component({
  selector: 'app-relacao-conteiner-exportacao',
  templateUrl: './relacao-conteiner-exportacao.component.html',
  styleUrls: ['./relacao-conteiner-exportacao.component.scss']
})
export class RelacaoConteinerExportacaoComponent implements OnInit, AfterContentInit{
  isCollapsed = false;

  @Input() camposformulario!: Campo[];
  @Input() isInsideModal!: boolean;
  @Input() camposFormulario!: Campo[];
  @Input() visualizar?: boolean = false;
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() shouldBlockPartQuantityEditionByDocumentType?: boolean;
  @Output() dataFormOut = new EventEmitter<{}>();
  @Output() onRelacaoConteinerLoad: EventEmitter<void> = new EventEmitter<void>();
  @Output() onUpdateSelectedContainers: EventEmitter<void> = new EventEmitter<void>();
  @Output() onEraseConteiner: EventEmitter<ConteinerEXP> = new EventEmitter<ConteinerEXP>();
  @Output() onSearchShipAndTripByContainerNumber: EventEmitter<string> = new EventEmitter<string>();

  public containerRelationList!: ConteinerEXP[];
  public areAllItemsSelected!: boolean;
  public selectedContainerNumbers!: string[];
  public form!: UntypedFormGroup;
  public relacaoConteinerReadonly: boolean = false;
  public relacaoConteinerRequired: boolean = false;
  public arvoreParteReadonly?: boolean;


  @ViewChildren(LinhaGridRelacaoConteinerExportacaoComponent) containerGrid!: QueryList<LinhaGridRelacaoConteinerExportacaoComponent>;

  constructor(
    private fb: UntypedFormBuilder,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngAfterContentInit(): void {
    this.onRelacaoConteinerLoad.emit();
  }

  public ngOnInit() {
    this.containerRelationList = [];
    this.areAllItemsSelected = false;
    this.selectedContainerNumbers = [];
    this.sendContainerRelationData();
  }

  public sendContainerRelationData(): void {
    if(!this.containerRelationList || !this.containerRelationList.length){
      this.dataFormOut.emit({"relacaoConteineres": []});
      return;
    }
    this.dataFormOut.emit({"relacaoConteineres": this.getContainersFromForms()});
    this.changeDetector.detectChanges();
  }

  public clean(){
    this.containerRelationList = [];
  }

  public setFormValuesFromReceivedContainerRelationList(containerRelation: ConteinerEXP[] | undefined){
    if(!containerRelation){
      return;
    }
    this.containerRelationList = [...containerRelation];
    this.sendContainerRelationData();
    this.changeDetector.detectChanges();
  }

  public get areAllContainersValid(): boolean{
    if(this.relacaoConteinerRequired && !this.getContainersFromForms()?.length){
      return false;
    }
    if(!this.containerGrid?.length){
      return true;
    }
    return this.containerGrid?.map(
      (container) => container.isContainerRelationLineValid
    )?.every(line => line);
  }

  public getContainersFromForms(): ConteinerEXP[]{
    return this.containerGrid?.map(
      (container) => container.getContainerFromFormFields()
    ) ?? [];
  }

  public getSelectedContainersFromForms(): ConteinerEXP[]{
    return this.containerGrid?.filter(
      (conteiner) => !!conteiner.selecionado?.value
    ).map(
      (conteiner) => conteiner.getContainerFromFormFields()
    ) ?? [];
  }

  public switchSelectAll(): void{
    this.areAllItemsSelected = !this.areAllItemsSelected;
    this.updateSelectedForAll();
  }

  private updateSelectedForAll(): void{
    this.containerGrid.forEach(
      (container) => {
        container.switchSelectionFromParent(this.areAllItemsSelected);
      }
    );
  }

  public addSelectedContainerNumber(containerNumber: string): void{
    if(this.selectedContainerNumbers.includes(containerNumber)){
      return;
    }
    this.selectedContainerNumbers.push(containerNumber);
  }

  public removeSelectedContainerNumber(containerNumber: string): void{
    if(!this.selectedContainerNumbers.includes(containerNumber)){
      return;
    }
    this.selectedContainerNumbers.splice(this.selectedContainerNumbers.indexOf(containerNumber), 1);
  }

  public addManualContainer(): void{
    if(this.isInsideModal){
      return;
    }
    this.containerRelationList.push({
      conteiner: '',
      conteinerTransload: '',
      parte: false,
      quantidadePartes: 0,
      quebraLote: false
    });
    this.changeDetector.detectChanges();
  }

  public updateContainerInfo(container: ConteinerEXP): void{
    if(!container){
      return;
    }
    if(container.selecionado){
      this.addSelectedContainerNumber(container.conteiner);
    }
    else{
      this.removeSelectedContainerNumber(container.conteiner);
    }
    this.deleteNonexistentContainerNumbers();
    this.changeDetector.detectChanges();
    if(!this.getSelectedContainersFromForms()?.length){
      this.selectedContainerNumbers = [];
    }
    this.containerGrid.forEach(
      (container) => {
        container.switchEdition();
      }
    );
    this.onUpdateSelectedContainers.emit();
    this.changeDetector.detectChanges();
    if(this.doesSolicitationHaveIntegration){
      // this.visualizar = true;
    }
  }

  private deleteNonexistentContainerNumbers(): void{
    let existentcontainerNumbers: string[] = this.getContainersFromForms()
      ?.map((container) => container.conteiner);
    for(let containerIndex: number = 0; containerIndex < this.selectedContainerNumbers?.length ?? 0; containerIndex++){
      if(!existentcontainerNumbers.includes(this.selectedContainerNumbers[containerIndex])){
        this.selectedContainerNumbers.splice(containerIndex, 1);
      }
    }
  }

  public setSettingsfromFields(): void{
    if(!this.camposFormulario?.length){
      this.containerGrid.forEach(
        (conteiner) => {
          conteiner.enableAllFormFields();
        }
      );
      return;
    }
    let campoRelacaoConteiner: Campo | undefined = this.camposFormulario?.find(campo => campo.nome == 'relacaoConteiner');
    this.relacaoConteinerReadonly = !!campoRelacaoConteiner?.leitura || !!campoRelacaoConteiner?.bloqueado;
    this.relacaoConteinerRequired = !!campoRelacaoConteiner?.obrigatorio;
    let campoArvoreParte: Campo | undefined = this.camposFormulario?.find(campo => campo.nome == 'arvoreParte');
    this.arvoreParteReadonly = campoArvoreParte ?
      (!!campoArvoreParte?.leitura || !!campoArvoreParte?.bloqueado) :
      false;
    this.changeDetector.detectChanges();
    this.containerGrid.forEach(
      (conteiner) => {
        conteiner.setSettingsfromFields();
      }
    );
    this.changeDetector.detectChanges();
  }

  public clear(): void{
    this.form.reset();
    this.eraseConteinerRelation();
  }

  public eraseConteinerRelation(): void{
    this.containerRelationList = [];
  }

  public eraseConteinerFromGrid(conteiner: ConteinerEXP): void{
    let index = this.containerGrid.toArray().findIndex(conteinerGrid => conteinerGrid.conteiner.value == conteiner.conteiner)
    if(index >= 0 ){
      this.containerRelationList.splice(index, 1);
          if(!this.containerRelationList?.length){
            this.areAllItemsSelected = false;
          }
          this.changeDetector.detectChanges();
          this.onUpdateSelectedContainers.emit();
    }
    }

    public sendConteinerToErase(index: number): void{
      if(this.doesSolicitationHaveIntegration){
        return;
      }
      this.onEraseConteiner.emit(this.containerGrid.get(index)?.getContainerFromFormFields());
    }

    public sendContainerToSearchShipAndTrip(containerNumber: string, index: number): void{
      if(index !== 0){
        return;
      }
      this.onSearchShipAndTripByContainerNumber.emit(containerNumber);
    }
}
