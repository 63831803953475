import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Canal } from 'src/app/models/Canal';
import { GridDueConteinerExp } from 'src/app/models/SolicitacaoLiberacaoExportacao/GridDueConteinerExp';

import { LinhaGridDueConteinerExpComponent } from '../linha-grid-due-conteiner-exp/linha-grid-due-conteiner-exp.component';

@Component({
  selector: 'app-grid-due-conteiner-exp',
  templateUrl: './grid-due-conteiner-exp.component.html',
  styleUrls: ['./grid-due-conteiner-exp.component.scss']
})
export class GridDueConteinerExpComponent implements OnInit, AfterContentInit{

  public isCollapsed = false;
  public dueConteinerList: GridDueConteinerExp[] = [];
  @Input() public canais!: Canal[];
  @Input() public blockEdition!: boolean;
  @Output() onGridDueConteinerLoad: EventEmitter<void> = new EventEmitter<void>();
  @ViewChildren(LinhaGridDueConteinerExpComponent) dueConteinerRelation!: QueryList<LinhaGridDueConteinerExpComponent>;

  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
  }

  public ngAfterContentInit(): void {
    this.onGridDueConteinerLoad.emit();
  }

  public setFormValuesFromReceivedDueConteiner(dueConteiner: GridDueConteinerExp[] | undefined): void{
    if(!dueConteiner){
      return;
    }
    this.dueConteinerList = [...dueConteiner];
    this.changeDetector.detectChanges();
    this.setSettingsfromFields();
  }

  public getDueConteinerRelation(): GridDueConteinerExp[]{
    return this.dueConteinerRelation?.map(
      (dueConteiner) => dueConteiner.getDueConteiner()
    ) ?? [];
  }

  public get isDueConteinerRelationValid(): boolean{
    return !!this.dueConteinerRelation?.map(
      (dueConteiner) => dueConteiner.isDueConteinerValid
    )?.every(valid => valid);
  }

  public clean(): void{
    this.dueConteinerList = [];
  }

  public setSettingsfromFields(): void{
    this.dueConteinerRelation.forEach((dueConteiner) => {
      dueConteiner?.enableOrDisableCanal();
    })
    this.changeDetector.detectChanges();
  }

}
