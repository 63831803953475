export class Permissoes {
    static readonly criacao: string = "criacao"
    static readonly edicao: string = "edicao"
    static readonly delecao: string = "delecao"
    static readonly visualizacao: string = "visualizar"
    static readonly concluir: string = "concluir"
    static readonly bloquear: string = "bloquear"
    static readonly desbloquear: string = "desbloquear"
    static readonly cancelar: string = "cancelar"
    static readonly solicitar: string = "solicitar"
    static readonly salvarRascunho: string = "salvarRascunho"
    static readonly salvarDocParte: string = "salvarDocParte"
    static readonly liberar: string = "liberar"
    static readonly atualizacao: string = "atualizacao"
    static readonly memoriaCalculo: string = "memoriaCalculo"
    static readonly enviarCCT: string = "enviarCCT"
    static readonly incluir: string = "incluir"
    static readonly salvar: string = "salvar"
    static readonly recusar: string = "recusar"
    static readonly autorizacaoSurpervisor: string = "autorizacaoSurpervisor"
    static readonly recusaSurpervisor: string = "recusaSurpervisor"
    static readonly consultaBloqueio: string = "consultaBloqueio"
}