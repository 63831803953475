<td *ngIf="selecionado" class="text-center">
    <input type="checkbox" [formControl]="selecionado" [checked]="selecionado.value" (click)="onManualSelection.emit()">
</td>
<td *ngIf="conteiner">
    <div class="input-group">
        <input type="text" class="form-control" aria-label="Digite aqui..." aria-describedby="basic-addon2"
          [formControl]="conteiner" [ngClass]="{'border border-danger': conteiner.invalid && !conteiner.pristine}"
          (change)="sendContainerNumberToSearchIfNoIntegration()"
          (focus)="isConteinerFocused = true" (blur)="isConteinerFocused = false"
        >
    </div>
</td>
<td *ngIf="reefer">
    <select class="custom-select" [formControl]="reefer">
    <option value="" disabled selected>Selecione</option>
    <option [value]="true" [ngValue]="true">Sim</option>
    <option [value]="false" [ngValue]="false">Não</option>
  </select>
</td>
<td *ngIf="imo">
    <select class="custom-select" [formControl]="imo">
    <option value="" disabled selected>Selecione</option>
    <option [value]="true" [ngValue]="true">Sim</option>
    <option [value]="false" [ngValue]="false">Não</option>
  </select>
</td>
<td *ngIf="oog">
    <select class="custom-select" [formControl]="oog">
    <option value="" disabled selected>Selecione</option>
    <option [value]="true" [ngValue]="true">Sim</option>
    <option [value]="false" [ngValue]="false">Não</option>
  </select>
</td>
<td *ngIf="conteinerTransload">
    <div class="input-group d-flex">
        <input type="text" class="form-control" aria-label="Digite aqui..." aria-describedby="basic-addon2" [formControl]="conteinerTransload">
    </div>
</td>
<td *ngIf="shouldQuebraLoteAppear && quebraLote">
    <select class="custom-select" [formControl]="quebraLote">
    <option value="" disabled selected>Selecione</option>
    <option [value]="true" [ngValue]="true">Sim</option>
    <option [value]="false" [ngValue]="false">Não</option>
  </select>
</td>
<td *ngIf="liberadoAte">
    <div class="input-group">
        <input type="text" class="form-control" placeholder="__/__/____" aria-label="Digite aqui..." aria-describedby="basic-addon2" [formControl]="liberadoAte" bsDatepicker #dp="bsDatepicker" [bsConfig]="bsConfig">
    </div>
</td>
<td *ngIf="shouldBlockPartQuantityEditionByDocumentType && parte">
  <select [ngClass]="{'border border-danger': parte.invalid && !parte.pristine}" class="custom-select" [formControl]="parte" (change)="setValidValueForArvorePartes(!!parte.value)">
  <option value="" disabled selected>Selecione</option>
  <option [value]="true" [ngValue]="true">Sim</option>
  <option [value]="false" [ngValue]="false">Não</option>
</select>
</td>
<td *ngIf="shouldBlockPartQuantityEditionByDocumentType && quantidadePartes && qtdPartesFront">
  <div class="input-group">
      <input type="number" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
        aria-describedby="basic-addon2" [formControl]="qtdPartesFront" [readonly]="!parte.value"
        [min]="minPartQuantity" [ngClass]="{'no-up-down-button': shouldBlockPartQuantityEditionByDocumentType}"
        (change)="form.patchValue({quantidadePartes: qtdPartesFront.value ? qtdPartesFront.value : 0}, {emitEvent: false});setValidValueForArvorePartes(!!parte.value)"
      >
  </div>
</td>
<td *ngIf="selecionado">
    <button class="erase-line-button" *ngIf="!doesSolicitationHaveIntegration && !!selecionado.value" (click)="eraseLine()">
<img src="assets/images/red-trashcan.svg" alt="">
</button>
</td>
