import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-exibir-json',
  templateUrl: './exibir-json.component.html',
  styleUrls: ['./exibir-json.component.scss']
})
export class ExibirJsonComponent implements OnInit {

  public json!: any;
  @ViewChild('modalJSON', {static: true}) public modalJSON!: TemplateRef<any>;

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService
  ) { }

  public ngOnInit(): void {
    this.json = undefined;
  }

  public displayJSON(json: any): void{
    if(typeof(json) === 'string'){
      this.json = JSON.parse(json);
    }
    else{
      this.json = json;
    }
    this.bsModalRef = this.bsModalService.show(this.modalJSON, Object.assign({}, {
      class: 'modal-md'
    }));
  }

  public closeModal(): void{
    this.bsModalRef.hide();
  }

}
