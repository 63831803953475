<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Documento <span class="text-danger" *ngIf="!isDocumentValid()">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
            <!--fa fa-chevron-down-->
        </div>
    </div>

    <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-3">
                    <div class="col">
                        <label>Tipo do Documento: *</label>
                        <select formControlName="tipoDocumento" class="custom-select" #docselect (change)="changeDocumentType(docselect.value)" [ngClass]="{'border border-danger': !form.get('tipoDocumento')?.pristine && form.get('tipoDocumento')?.invalid && visualizar == false}"
                            [attr.disabled]="visualizar ? '': null">
                  <option value="" disabled selected>Selecione</option>
                  <option *ngFor="let item of tipoDocumento" [value]="item.nome">{{item.nome}}</option>
          </select>
                        <div *ngIf="form.get('tipoDocumento')?.errors && !form.get('tipoDocumento')?.pristine">
                            <p class="text-danger" *ngIf="form.get('tipoDocumento')?.errors?.['required']" [hidden]="visualizar == true">
                                Campo de preenchimento obrigatório!
                            </p>
                        </div>
                    </div>
                    <div class="col">
                        <label>N° Documento: *</label>
                        <div class="input-group">
                            <input formControlName="numeroDocumento" [maxlength]="50" #numDoc type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2" [mask]="maskDoc" [specialCharacters]="specialCharsForMask" [ngClass]="{'border border-danger': !form.get('numeroDocumento')?.pristine && form.get('numeroDocumento')?.invalid && visualizar == false}"
                                [attr.disabled]="visualizar ? '': null">
                            <button (click)="getData()" class="action-primary-button" [disabled]="!isDocumentValid()" [hidden]="isInsideModal">PESQUISAR</button>
                        </div>
                        <div *ngIf="form.get('numeroDocumento')?.errors && !form.get('numeroDocumento')?.pristine">
                            <p class="text-danger" *ngIf="form.get('numeroDocumento')?.errors?.['required']" [hidden]="visualizar == true">
                                Campo de preenchimento obrigatório!
                            </p>
                            <p class="text-danger" *ngIf="form.get('numeroDocumento')?.errors?.['mask']" [hidden]="visualizar == true">
                                Preencher no formato correto!
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-6" *ngIf="form?.get('tipoDocumento')?.value === 'CTE'">
                        <label for="">N° do CE:</label>
                        <input type="text" class="form-control" formControlName="numeroCE" [mask]="'000000000000000'" [ngClass]="{'border border-danger': !form.get('numeroCE')?.pristine && form.get('numeroCE')?.invalid && visualizar == false}" [attr.disabled]="visualizar ? '': null">
                        <div *ngIf="form.get('numeroCE')?.errors && !form.get('numeroCE')?.pristine">
                            <p class="text-danger" *ngIf="form.get('numeroCE')?.errors?.['required']" [hidden]="visualizar == true">
                                Campo de preenchimento obrigatório!
                            </p>
                            <p class="text-danger" *ngIf="form.get('numeroCE')?.errors?.['mask']" [hidden]="visualizar == true">
                                Preencher no formato correto!
                            </p>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="form?.get('tipoDocumento')?.value === 'NF'">
                      <label for="">Chave Nota Fiscal: </label>
                      <input type="text" class="form-control" formControlName="chaveNotaFiscal" [mask]="maskForChaveNotaFiscal" [ngClass]="{'border border-danger': !form.get('chaveNotaFiscal')?.pristine && form.get('chaveNotaFiscal')?.invalid && visualizar == false}" [attr.disabled]="visualizar ? '': null">
                      <div *ngIf="form.get('chaveNotaFiscal')?.errors && !form.get('chaveNotaFiscal')?.pristine">
                          <p class="text-danger" *ngIf="form.get('chaveNotaFiscal')?.errors?.['mask']" [hidden]="visualizar == true">
                              Preencher no formato correto!
                          </p>
                      </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
