import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  isRedirectDocumental: boolean = false;
  constructor(public toastService: ToastService,public authenticationService:AuthenticationService) {}

  ngOnInit(): void {
    this.isRedirectDocumental = this.authenticationService.isRedirectDocumental() 
  }
  isTemplate(toast:any) { return toast.textOrTpl instanceof TemplateRef; }


}
