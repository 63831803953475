import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from "../services/toast.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private toastrService: ToastService,
     private spinner: NgxSpinnerService
   ) {}

  handleError(error: any) {
    console.log('Global Error Handler')
    console.log(error)
    if(error?.errors || error?.error?.errors){
      return;
    }
    if (!(error instanceof HttpErrorResponse)) {
      if(!error.url?.includes('Pesquisar')){
        this.toastrService.error(`Erro ao realizar a operação. Por favor abra um chamado anexando um print com essa mensagem. - ${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`);
        this.spinner.hide();
      }
   }
}
}
