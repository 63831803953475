import { RetornoDetalheRpaApi } from './../models/MonitoramentoRpaApi/RetornoDetalheRpaApi';
import { GridRpaApi } from './../models/MonitoramentoRpaApi/GridRpaApi';
import { SuccessResponse } from './../models/HttpResponses/SuccessResponse';
import { Observable, of } from 'rxjs';
import { PesquisaRpaApi } from './../models/MonitoramentoRpaApi/PesquisaRpaApi';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { environment } from './../../environments/environment';
import { TipoRpaApi } from '../models/MonitoramentoRpaApi/TipoRpaApi.enum';
import { StatusRPA } from '../models/MonitoramentoRpaApi/StatusRPA.enum';
import { DetalheRpaApi } from '../models/MonitoramentoRpaApi/DetalheRpaApi';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MonitoramentoRpaApiService {

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe
  ) { }

  public searchForRpaAndApiByNameOrPeriod(searchFilters: PesquisaRpaApi): Observable<SuccessResponse>{
  let query: string[] = [];

    if(searchFilters.nome){
      query.push(`Processo=${searchFilters.nome}`);
    }
    if(searchFilters.periodoDe){
      query.push(`PeriodoDe=${this.datePipe.transform(searchFilters.periodoDe, "yyyy-MM-dd")}`);
    }
    if(searchFilters.periodoAte){
      query.push(`PeriodoAte=${this.datePipe.transform(searchFilters.periodoAte, "yyyy-MM-dd")}`);
    }
    return this.http.get<SuccessResponse>(`${environment.apiUrlForMonitoramento}/api/Integracao?${query.join('&')}`);
  }

  public getDetailsApi(rpaApi: PesquisaRpaApi): Observable<SuccessResponse>{
    let query: string[] = [];

    if(rpaApi.nome){
      query.push(`Processo=${rpaApi.nome}`);
    }
    if(rpaApi.periodoDe){
      query.push(`PeriodoDe=${this.datePipe.transform(rpaApi.periodoDe, "yyyy-MM-dd")}`);
    }
    if(rpaApi.periodoAte){
      query.push(`PeriodoAte=${this.datePipe.transform(rpaApi.periodoAte, "yyyy-MM-dd")}`);
    }
    return this.http.get<SuccessResponse>(`${environment.apiUrlForMonitoramento}/api/Integracao/Grid/NomeProcesso?${query.join('&')}`);
  }

  public getDetailsForRpaOrApi(rpaApi: DetalheRpaApi): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForMonitoramento}/api/Integracao/Grid/IdProcesso?IdProcesso=${rpaApi.idProcesso}`);
  }

  public downloadEvidenceFromResponseEvent(evidencias: string[]):  Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForMonitoramento}/api/Integracao/BaixarArquivo?${this.getQuerydownloadEvidence(evidencias)}`);
  }

  private getQuerydownloadEvidence(evidencias: string[]): string{
    return evidencias.map(
      (evidencias) => `listPaths=${evidencias}`
    ).join('&');
  }
}
