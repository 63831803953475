import { ToastService } from 'src/app/services/toast.service';
import { Component, HostListener, ViewChild, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './components/home/home.component';
import { AuthenticationService } from './services/authentication/authentication.service';
import { UpdateService } from './services/update-service.service';
import { NavComponent } from './shared/nav/nav.component';
import { setInterval } from 'timers';
import { PrintService } from './services/relatorios/print.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CSN-Documental-Portal';
  isLogged: boolean = false;
  isRedirectDocumental: boolean = false;
  isDocumental: boolean = false;
  protected isPrinting: boolean = false;
  protected componentToPrint: TemplateRef<any> | null = null;

  @ViewChild(NavComponent)
  private navComponent: NavComponent | undefined;

  versao: string = environment.version;
  @ViewChild(HomeComponent)
  private homeComponent: HomeComponent | undefined;

  currentYearLong(): number {
    return new Date().getFullYear();
  }

  subscription: Subscription | undefined;
  constructor(
    private authService: AuthenticationService,
    private updateService: UpdateService,
    private printService: PrintService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.subscription = this.authService.authNavStatus$.subscribe(async status => {
      this.isLogged = status;

      this.navComponent?.ngOnInit();

      this.homeComponent?.ngOnInit();

      this.isLogged = this.authService.isAuthenticated() && this.authService.verificarAcessoModulo();

      this.isRedirectDocumental = this.authService.isAuthenticated() && this.authService.isRedirectDocumental() && this.authService.verificarAcessoModulo();
    });



  }
  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.subscription?.unsubscribe();

  }
  async ngOnInit() {
    this.updateService.checkForUpdates();
    window.addEventListener('storage', this.handleStorageEvent);

    this.printService.isPrintingActive$.subscribe(print => {
      this.isPrinting = !!print;
      this.componentToPrint = print;
      this.changeDetector.detectChanges();
    })
  }
  handleStorageEvent = (event: StorageEvent) => {
    if (event.key === 'Portal') {
      if (event.oldValue == 'Documental' && event.newValue == 'Tecon')
          this.authService.setRedirectDocumental();    
      }
    }
  

  // validaToken() {
  //   if (this.authService.isTimeToRefreshToken()) {
  //     console.log('token expired')
  //     this.authService.clearLocalStorage();
  //     this.authService.login();
  //   }
  //   console.log('token valido')
  // }
}
