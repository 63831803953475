<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Relação de Notas Fiscais <span class="text-danger" *ngIf="numerosNotasFiscaisDisplay?.length && !areAllGridLinesValid()">*</span></span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}"
        data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false"
        aria-label="Toggle navigation"></i>
    </div>
  </div>
  <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
      <div class="table-container">
        <table class="grid-table">
          <thead class="">
            <tr>
              <th>N° da Nota Fiscal</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let numeroNotaFiscal of numerosNotasFiscaisDisplay; let indexNota = index"
              app-linha-grid-relacao-notas-fiscais [notaFiscal]="numeroNotaFiscal"
              [camposFormulario]="camposFormulario" [visualizar]="listaNotasFiscaisReadonly"
            ></tr>
          </tbody>
        </table>
      </div>
  </div>
</div>
