import { ChangeDetectorRef, Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { RelatorioMovimentacaoClientes } from 'src/app/models/relatorios/RelatorioMovimentacaoClientes/RelatorioMovimentacaoClientes';

@Component({
  selector: 'app-impressao-relatorio-movimentacao-clientes',
  templateUrl: './impressao-relatorio-movimentacao-clientes.component.html',
  styleUrls: ['./impressao-relatorio-movimentacao-clientes.component.scss']
})
export class ImpressaoRelatorioMovimentacaoClientesComponent implements OnInit {

  protected dataInicio?: Date;
  protected dataFim?: Date;
  protected nomeRelatorio: string = '';
  protected dados: RelatorioMovimentacaoClientes[] = [];
  protected logo: string = '../../assets/images/logoSeptibaTecon.png';
  protected currentDate: Date = new Date();
  protected totalConteineres: number = 0;
  @ViewChildren('tableRelatorio') protected relatorios!: QueryList<TemplateRef<any>>;
  @ViewChild('templateImpressao') private templateImpressao!: TemplateRef<any>;

  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
  }

  public setPrintingData(data: {dataInicio: Date, dataFim: Date, nomeRelatorio: string, dados: RelatorioMovimentacaoClientes[]}): void{
    this.dataInicio = data.dataInicio;
    this.dataFim = data.dataFim;
    this.nomeRelatorio = data.nomeRelatorio;
    this.dados = data.dados;
    this.totalConteineres = data.dados.map(el => el?.quantidadeConteineres || 0)?.reduce((a, b) => a+b) || 0;
    this.changeDetector.detectChanges();
  }

  public getTemplate(): TemplateRef<any>{
    return this.templateImpressao;
  }

}
