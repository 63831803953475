<div class="card mt-4">

    <div class="card-body" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-3">
                    <div class="col-3">
                        <label>Período de:</label>
                        <input type="text" formControlName="de" bsDatepicker #dpSolicitacao="bsDatepicker"
                            class="form-control" aria-describedby="basic-addon2" [bsConfig]="bsConfig"
                            placeholder="dd/mm/aaaa"
                            [mask]="'00/00/0000'"
                            style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [maxDate]="form.get('ate')?.value ? form.get('ate')?.value : currentDate ? currentDate : undefined"
                          >
                    </div>
                    <div class="col-3">
                        <label>Até:</label>
                        <input type="text" formControlName="ate" bsDatepicker #dpLiberacao="bsDatepicker"
                            class="form-control" aria-describedby="basic-addon2" [bsConfig]="bsConfig"
                            placeholder="dd/mm/aaaa"
                            [mask]="'00/00/0000'"
                            style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [minDate]="form.get('de')?.value ?? undefined" [maxDate]="currentDate ? currentDate : undefined"
                          >
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col">
                        <label>Navio:</label>
                        <input formControlName="navio" type="text" class="form-control"
                            placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2">
                    </div>
                    <div class="col">
                        <label>Viagem:</label>
                        <input formControlName="viagem" type="text" class="form-control"
                            placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-9"></div>
                    <div class="col-3 action-button-group">
                        <button class="action-primary-button" (click)="sendFilter()" [disabled]="form.invalid">PESQUISAR</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
