import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { throwIfEmpty } from 'rxjs';
import { RelacaoConteinerTransitoSimplificado } from 'src/app/models/RecepcaoDueContingencia/ConteinerTransitoSimplificado/RelacaoConteinerTransitoSimplificado';
import { DocumentCustomPattern, DocumentMaskEnum } from 'src/app/shared/utils/constantes-documentos';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';

@Component({
  selector: 'app-transporte',
  templateUrl: './transporte.component.html',
  styleUrls: ['./transporte.component.scss']
})
export class TransporteComponent implements OnInit {
  public form!: UntypedFormGroup;
  public isCollapsed = false;
  public maskForClientDocument: string = '0*';
  public maskForNames: string = DocumentMaskEnum.NAME_MASK;
  public patternForNames = DocumentCustomPattern.NAME_PATTERN;
  public quantidadeReboques: number = 0;

  @Output() onSearchForInvoice: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnInit() {
    this.validation();
  }

  public validation(): void {
    this.form = this.fb.group({
      cpfCondutor: [''],
      nome: [''],
      tipoVeiculo: [''],
      placaCavalo: [''],
      placaReboque1: [''],
      placaReboque2: [''],
      placaReboque3: ['']
    });
    this.form.get('tipoVeiculo')?.valueChanges.subscribe(
      (tipoVeiculo) => {
        this.changeQuantidadeReboques(tipoVeiculo);
      }
    );
  }

  public validateClientDocument(clientDocument: string): string | null {
    if (!clientDocument) {
      return null;
    }
    if (clientDocument.length === 11) {
      return this.verificacaoDocumentosService.checkCPFOrCNPJ(clientDocument, 'fisica');
    }
    else if (clientDocument.length === 14) {
      return this.verificacaoDocumentosService.checkCPFOrCNPJ(clientDocument, 'juridica');
    }
    if (clientDocument.length != 11 && clientDocument.length != 14) {
      return 'Documento inválido. Favor verificar!';
    }
    return 'Documento inválido. Favor verificar!';
  }

  private changeQuantidadeReboques(textoQuantidadeReboques: string): void{
    if(!textoQuantidadeReboques || !textoQuantidadeReboques?.length){
      this.quantidadeReboques = 0;
      return;
    }
    this.quantidadeReboques = parseInt(textoQuantidadeReboques.split('').find(
      (qtd) => qtd.includes('1') || qtd.includes('2') || qtd.includes('3')
    ) as string) ?? 0;
    for(let plate: number = 1; plate < 3; plate++){
      if(plate > this.quantidadeReboques){
        this.form.get(`placaReboque${plate}`)?.setValue('');
      }
    }
  }


  public setConteinerDataFromResivedConteiner(conteiners: RelacaoConteinerTransitoSimplificado): void {

    this.form.get("cpfCondutor")?.setValue(conteiners.cpfCondutor)
    this.form.get("nome")?.setValue(conteiners.nomeCondutor)
    this.form.get("placaCavalo")?.setValue(conteiners.placaCavalo)

    if(conteiners.placaReboque1 && conteiners.placaReboque2 && conteiners.placaReboque3)
    {
      this.form.get("tipoVeiculo")?.setValue("Cavalo com 3 Reboques")
    }
    else if(conteiners.placaReboque1 && conteiners.placaReboque2)
    {
      this.form.get("tipoVeiculo")?.setValue("Cavalo com 2 Reboques")
    }
    else if(conteiners.placaReboque1)
    {
      this.form.get("tipoVeiculo")?.setValue("Cavalo com 1 Reboque")
    }

    this.form.get("placaReboque1")?.setValue(conteiners.placaReboque1)
    this.form.get("placaReboque2")?.setValue(conteiners.placaReboque2)
    this.form.get("placaReboque3")?.setValue(conteiners.placaReboque3)

    

  }
  public clean(){
    this.form.reset();
  }
}
