import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from '../../../models/HttpResponses/ErrorResponse';
import { CabotagemEmbarqueService } from './../../../services/cabotagem-embarque.service';
import { debounceTime, Observable, of } from 'rxjs';
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BookingDTO } from 'src/app/models/CabotagemEmbarque/BookingDTO';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-incluir-booking',
  templateUrl: './incluir-booking.component.html',
  styleUrls: ['./incluir-booking.component.scss']
})
export class IncluirBookingComponent implements OnInit {

  public modalRef!: BsModalRef;
  public formIncludeBooking!: UntypedFormGroup;
  public nomesNavios!: string[];
  public numerosViagens!: string[];
  public numerosBookings!: string[];

  @Output() onSaveBooking: EventEmitter<BookingDTO> = new EventEmitter<BookingDTO>();

  @ViewChild('modalIncluirBooking', {static: false}) modal!: TemplateRef<any>;

  constructor(
    private modalService: BsModalService,
    private fb: UntypedFormBuilder,
    private cabotagemEmbarqueService: CabotagemEmbarqueService,
    private toastService:ToastService,
    private spinner: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private changeDetector: ChangeDetectorRef

  ) { }

  public ngOnInit(): void {
    this.nomesNavios = [];
    this.numerosViagens = [];
    this.numerosBookings = [];
    this.generateFormForIncludingBooking();
  }

  private generateFormForIncludingBooking(): void{
    this.formIncludeBooking = this.fb.group({
      navio: ['', [Validators.required, this.checkIfNavioIsValid.bind(this)]],
      viagem: ['', [Validators.required, this.checkIfViagemIsValid.bind(this)]],
      booking: ['', [Validators.required, this.checkIfBookingIsValid.bind(this)]],
      armador: ['', [Validators.required]],
      modalidade: ['', [Validators.required]]
    });
    this.formIncludeBooking.get('navio')?.valueChanges
      .pipe(
        debounceTime(1000)
      ).subscribe({
        next: (navio) => {
          this.getRelatedShipNames(navio)
        }
      });
    this.formIncludeBooking.get('viagem')?.valueChanges
      .pipe(
        debounceTime(1000)
      ).subscribe({
        next: (viagem) => {
          this.getRelatedTripNumbers(viagem)
        }
      });
    this.formIncludeBooking.get('booking')?.valueChanges
      .pipe(
        debounceTime(1000)
      ).subscribe({
        next: (booking) => {
          this.getRelatedBookingNumbers(booking)
        }
      });
  }

  private getRelatedShipNames(shipName: string): void{
    if(!(shipName?.trim()?.length)){
      this.nomesNavios = [];
      this.spinner.hide();
      return;
    }
    this.cabotagemEmbarqueService.getRelatedShipNames(shipName)
      .subscribe({
        next: (shipNames) => {
          this.nomesNavios = shipNames;
          let nomeNavio = this.nomesNavios.find(nomeNavio => nomeNavio.toLowerCase() == shipName.toLowerCase());
          if(nomeNavio?.length){
            this.formIncludeBooking.patchValue({
              navio: nomeNavio
            }, {emitEvent: false});
          }
          this.changeDetector.detectChanges();
        },
        error: (err: ErrorResponse) => {
          console.log(err);
          err?.errors?.forEach(
            (error) => {
              this.toastService.error(error);
            }
          );
        },
        complete: () => {
          this.spinner.hide();
        }
      });
  }

  private getRelatedTripNumbers(tripNumber: string): void{
    if(!(tripNumber?.trim()?.length)){
      this.numerosViagens = [];
      this.spinner.hide();
      return;
    }
    this.cabotagemEmbarqueService.getRelatedTripNumbers(tripNumber)
    .subscribe({
      next: (tripNumbers) => {
        this.numerosViagens = tripNumbers;
        let numeroViagem = this.numerosViagens.find(numeroViagem => numeroViagem.toLowerCase() == tripNumber.toLowerCase());
        if(numeroViagem?.length){
          this.formIncludeBooking.patchValue({
            viagem: numeroViagem
          }, {emitEvent: false});
        }
        this.changeDetector.detectChanges();
      },
      error: (err: ErrorResponse) => {
        console.log(err);
        err?.errors?.forEach(
          (error) => {
            this.toastService.error(error);
          }
        );
      },
      complete: () => {
        this.spinner.hide();
      }
    });

  }

  private getRelatedBookingNumbers(bookingNumber: string): void{
    if(!(bookingNumber?.trim()?.length)){
      this.numerosBookings = [];
      this.spinner.hide();
      return;
    }
    this.cabotagemEmbarqueService.getRelatedBookingNumbers(bookingNumber)
      .subscribe({
        next: (bookingNumbers) => {
          this.numerosBookings = bookingNumbers;
          let numeroBooking = this.numerosBookings.find(numeroBooking => numeroBooking.toLowerCase() == bookingNumber.toLowerCase());
          if(numeroBooking?.length){
            this.formIncludeBooking.patchValue({
              booking: numeroBooking
            }, {emitEvent: false});
          }
          this.changeDetector.detectChanges();
        },
        error: (err: ErrorResponse) => {
          console.log(err);
          err?.errors?.forEach(
            (error) => {
              this.toastService.error(error);
            }
          );
        },
        complete: () => {
          this.spinner.hide();
        }
      });
  }

  public openModalForBookingInclusion(): void{
    this.formIncludeBooking.reset();
    this.modalRef = this.modalService.show(this.modal, {class: 'modal-lg'});
  }

  public closeModal(): void{
    this.formIncludeBooking.reset();
    this.modalService.hide();
  }

  public saveBooking(): void{
    let booking: BookingDTO = {
      viagem: {
        nomeNavio: this.formIncludeBooking.value.navio,
        numeroViagem: this.formIncludeBooking.value.viagem
      },
      armador: this.formIncludeBooking.value.armador,
      numeroBooking: this.formIncludeBooking.value.booking,
      modalidade: this.formIncludeBooking.value.modalidade,
      dataHoraCadastro: new Date(),
      usuario: this.authenticationService.name
    };
    this.onSaveBooking.emit(booking);
    this.closeModal();
  }

  public get areFormTextfieldsFilled(): boolean{
    return (
      !!(this.formIncludeBooking.get('navio')?.value as string) &&
      !!(this.formIncludeBooking.get('viagem')?.value as string) &&
      !!(this.formIncludeBooking.get('armador')?.value as string) &&
      !!(this.formIncludeBooking.get('booking')?.value as string)
    );
  }

  private checkIfNavioIsValid(control: AbstractControl): {navioInvalido: string} | null{
    let navio = control.value as String ?? '';
    if(!navio?.trim()?.length){
      return null;
    }
    if(!this.nomesNavios?.length){
      return {navioInvalido: 'Navio não encontrado!'};
    }
    if(!this.nomesNavios?.find(nomeNavio => nomeNavio.toLowerCase() == navio.toLowerCase())?.length){
      return {navioInvalido: 'Navio não encontrado!'};
    }
    return null;
  }

  private checkIfViagemIsValid(control: AbstractControl): {viagemInvalida: string} | null{
    let viagem = control.value as String ?? '';
    if(!viagem?.trim()?.length){
      return null;
    }
    if(!this.numerosViagens?.length){
      return {viagemInvalida: 'Viagem não encontrada!'};
    }
    if(!this.numerosViagens?.find(numeroViagem => numeroViagem.toLowerCase() == viagem.toLowerCase())?.length){
      return {viagemInvalida: 'Viagem não encontrada!'};
    }
    return null;
  }

  private checkIfBookingIsValid(control: AbstractControl): {bookingInvalido: string} | null{
    let booking = control.value as String ?? '';
    if(!booking?.trim()?.length){
      return null;
    }
    if(!this.numerosBookings?.length){
      return {bookingInvalido: 'Booking não encontrado!'};
    }
    if(!this.numerosBookings?.find(numeroBooking => numeroBooking.toLowerCase() == booking.toLowerCase())?.length){
      return {bookingInvalido: 'Booking não encontrado!'};
    }
    return null;
  }

}
