import { query } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from 'inspector';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SecureContext } from 'tls';
import { BloqueioDesbloqueioConteinerEmbarque } from '../models/BloqueioDesbloqueioConteinerEmbarque/bloqueio-desbloqueio-conteiner-embarque';
import { FiltroPesquisaBloqueioDesbloqueio } from '../models/BloqueioDesbloqueioConteinerEmbarque/filtro-pesquisa-bloqueio-desbloqueio';
import { SuccessResponse } from '../models/HttpResponses/SuccessResponse';

@Injectable({
  providedIn: 'root'
})
export class BloqueioDesbloqueioConteinerEmbarqueService {

  constructor(
    private http: HttpClient
  ) { }

  public pesquisarConteiner(filtro: FiltroPesquisaBloqueioDesbloqueio): Observable<SuccessResponse>
  {
    return this.http.get<any>(`${environment.apiUrlForBloqueioDesbloqueioConteinerEmbarque}/api/SolicitarBloqueioEmbarque/PesquisaConteinerBloqueio?${this.CriarQueryPesquisaBloquearConteiner(filtro)}`);
  }

  public bloquearConteiner(conteineres: BloqueioDesbloqueioConteinerEmbarque[]): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForBloqueioDesbloqueioConteinerEmbarque}/SolicitarBloqueioEmbarque?${this.CriarQueryBloquearConteiner(conteineres)}`, null);
  }

  public desbloquearConteiner(conteineres: BloqueioDesbloqueioConteinerEmbarque[]): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForBloqueioDesbloqueioConteinerEmbarque}/DesbloqueioEmbarque?${this.CriarQueryBloquearConteiner(conteineres)}`, null);
  }

  private CriarQueryBloquearConteiner(conteineres: BloqueioDesbloqueioConteinerEmbarque[]): string{
    return conteineres.map(
      (conteiner: BloqueioDesbloqueioConteinerEmbarque) => `Conteineres=${conteiner.conteiner}`
      ).join("&");
  }

  private CriarQueryPesquisaBloquearConteiner(filtro: FiltroPesquisaBloqueioDesbloqueio): string{
     let query = []

     if(filtro.numeroDUE?.length)
     {
      query.push(`numeroDUE=${filtro.numeroDUE}`)
     }

     if(filtro.conteiner?.length)
     {
      query.push(`numeroConteiner=${filtro.conteiner}`)
     }

    return query.join("&");
  }
}
