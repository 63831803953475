import { ToastService } from 'src/app/services/toast.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import * as xmlJs from 'xml-js';

@Component({
  selector: 'app-xml',
  templateUrl: './xml.component.html',
  styleUrls: ['./xml.component.scss']
})
export class XmlComponent implements OnInit {

  @Input() isBlocked!: boolean;
  @Input() public isInsideModal!: boolean;
  @Input() public documentType!: string;
  @Output() xmlToJson = new EventEmitter<any>();
  public xml!: string
  public toJson!: any
  public form!: UntypedFormGroup;
  public isCollapsed = false;
  public readonly allowedFileExtensions: string[] = ['xml'];

  constructor(
    private fb: UntypedFormBuilder,
    private toastService:ToastService
    ) { }

  ngOnInit() {
    this.form = this.fb.group({
      xml: ['']
    })
  }

  onChange(event: any){
    if (!(this.checkIfExtensionIsValid(event.target.files[0].name))) return this.toastService.error('Arquivo inválido!');
    const reader = new FileReader();
    reader.onload = (e: any) => {
      let result = JSON.parse(xmlJs.xml2json(e.target.result, {compact: true, ignoreComment: true}));
      if(result.ListaDeclaracoes)
      {
        //let baseResult = result.ListaDeclaracoes.declaracaoImportacao
        return this.xmlToJson.emit(result);
      }
      else if(result.cteProc)
      {
        //let baseResult = result.cteProc.CTe.infCte
        if(this.documentType?.length && this.documentType !== 'CTE'){
          return this.toastService.error("Arquivo XML é inválido. Favor verificar!");
        }
        return this.xmlToJson.emit(result);
      }
      else if(result.nfeProc)
      {
        //let baseResult = result.nfeProc.NFe.infNFe
        if(this.documentType?.length && !this.documentType?.includes('NF')){
          return this.toastService.error("Arquivo XML é inválido. Favor verificar!");
        }
        return this.xmlToJson.emit(result);
      }
      else

        return this.toastService.error("Arquivo XML é inválido. Favor verificar!");
      }

    reader.readAsText(event.target.files[0])
    return event.target.value = null;
  }

    private checkIfExtensionIsValid(fileName: string): boolean{
      let extension: string = fileName.split('.').pop() ?? '';
      return this.allowedFileExtensions.includes(extension.toLowerCase());
    }
}
