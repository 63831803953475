import { ExibirJsonComponent } from './../../modais/exibir-json/exibir-json.component';
import { MonitoramentoRpaApiService } from './../../../services/monitoramento-rpa-api.service';
import { DetalheRpaApi } from 'src/app/models/MonitoramentoRpaApi/DetalheRpaApi';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as FileSaver from 'file-saver';
import { ToastService } from 'src/app/services/toast.service';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-modal-detalhes-eventos-request-response',
  templateUrl: './modal-detalhes-eventos-request-response.component.html',
  styleUrls: ['./modal-detalhes-eventos-request-response.component.scss']
})
export class ModalDetalhesEventosRequestResponseComponent implements OnInit {

  public rpaApi!: DetalheRpaApi;
  @ViewChild('modal', {static: true}) public modal!: TemplateRef<any>;
  @ViewChild(ExibirJsonComponent) public detalheJSON!: ExibirJsonComponent;
  public json!: any;

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private monitoramentoRpaApiService: MonitoramentoRpaApiService,
    private toastService: ToastService,
    private utilService:UtilService
  ) { }

  public ngOnInit(): void {
    this.json = undefined;
  }

  public closeModal(): void{
    this.bsModalRef.hide();
  }

  public openModal(rpaApi: DetalheRpaApi): void{
    this.monitoramentoRpaApiService.getDetailsForRpaOrApi(rpaApi)
      .subscribe({
        next: (result) => {
          rpaApi.eventoRequest = result.data.requestProcesso[0]
          rpaApi.eventoResponse = result.data.responseProcesso;
          this.rpaApi = rpaApi
          this.bsModalRef = this.bsModalService.show(this.modal, Object.assign({}, {
            class: 'modal-xl',
            ignoreBackdropClick: true

          }));
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = err?.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public displayJSON(json: any): void{
    this.detalheJSON.displayJSON(json);
  }

  public downloadEvidence(evidencias: string[]): void{
    this.monitoramentoRpaApiService.downloadEvidenceFromResponseEvent(evidencias)
      .subscribe({
        next: (result) => {
          result.data.forEach(async (element: any,i: any) => {
            let mimeType = await this.utilService.detectMimeType(element)
            element = await `data:${mimeType};base64,${element}`;
            this.utilService.baixarArquivo(`evidencia-${i}.png`, element);
          });
          // result.data.map((res: any, i: any) => res.blob().then((blob: any) => FileSaver.saveAs(blob, `evidencia-${i}.png`)))
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = err?.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

}
