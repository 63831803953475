import { PesquisaRpaApi } from './../../../models/MonitoramentoRpaApi/PesquisaRpaApi';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-pesquisa-monitoramento-rpa-api',
  templateUrl: './pesquisa-monitoramento-rpa-api.component.html',
  styleUrls: ['./pesquisa-monitoramento-rpa-api.component.scss']
})
export class PesquisaMonitoramentoRpaApiComponent implements OnInit {

  public form!: UntypedFormGroup;
  private colorTheme = 'theme-dark-blue';
  public bsConfig?: Partial<BsDatepickerConfig>;
  public blockSending: boolean = true;
  public locale = 'pt-br';
  public currentDate: Date = new Date();
  @Output() public onSearchRpaApi: EventEmitter<PesquisaRpaApi> = new EventEmitter<PesquisaRpaApi>();

  constructor(
    private fb: UntypedFormBuilder,
    private localeService: BsLocaleService
  ) { }

  public ngOnInit(): void {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.validation();
  }

  private validation(): void{
    this.form = this.fb.group({
      nome: [''],
      periodoDe: [''],
      periodoAte: [''],
    },
    {
      validators: [this.checkIfFormIsFilled.bind(this)]
    });
  }

  public searchRpaApi(): void{
    this.onSearchRpaApi.emit(this.form.getRawValue() as PesquisaRpaApi);
  }

  private checkIfFormIsFilled(group: UntypedFormGroup): {invalidForm: boolean} | null{
    if(!group){
      return null;
    }
    let periodoDe = group.get('periodoDe')?.value;
    let periodoAte = group.get('periodoAte')?.value;
    let nome = group.get('nome')?.value;
    if((periodoDe && !periodoAte) || (!periodoDe && periodoAte)){
      return {invalidForm: true};
    }
    if(!periodoDe && !periodoAte && !nome){
      return {invalidForm: true};
    }
    return null;
  }

}
