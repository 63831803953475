<ng-template #modalRespostaPositivaNegativa>
  <div class="modal-header">
    <h4>{{modalTitle}}</h4>
    <button class="btn-close close pull right"><span class="visualli-hidden"
      (click)="closeModal()">&times;</span></button>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
    <div class="action-button-group action-double-buttons">
      <button class="action-secondary-button" (click)="negativeAction()">{{negativeText}}</button>
      <button class="action-primary-button" (click)="positiveAction()">{{positiveText}}</button>
    </div>
  </div>
</ng-template>
