<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Dados Complementares <span class="text-danger" *ngIf="!isComplementaryDataValid()">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-4">
                    <div class="col">
                        <input formControlName="desembaracoAntecipado" type="checkbox">
                        <label>Desembaraço Antecipado <span id="desembaracoAntecipado-required-label" class="invisible">*</span></label>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-3">
                        <label>Previsão de Retirada/Chegada: <span id="previsaoRetiradaChegada-required-label" class="invisible">*</span></label><br>
                        <input type="text" formControlName="previsaoRetiradaChegada" class="form-control" aria-describedby="basic-addon2" placeholder="dd/mm/aaaa" bsDatepicker #dp="bsDatepicker" [bsConfig]="bsConfig" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [ngClass]="{'border border-danger': form.get('previsaoRetiradaChegada')?.invalid && !form.get('previsaoRetiradaChegada')?.pristine}">
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-3">
                        <label>Valor da Mercadoria: <span id="valorMercadoria-required-label" class="invisible">*</span></label>
                        <input formControlName="valorMercadoria" type="text" class="form-control" aria-describedby="basic-addon2" maxlength="30" placeholder="Digite aqui..." currencyMask [options]="{prefix: '', align: 'left', thousands: '.', decimal: ','}" [ngClass]="{'border border-danger': form.get('valorMercadoria')?.invalid && !form.get('valorMercadoria')?.pristine}">
                    </div>
                    <div class="col-3">
                        <label>Moeda: <span id="moeda-required-label" class="invisible">*</span></label>
                        <select class="custom-select" formControlName="moeda" [ngClass]="{'border border-danger': form.get('moeda')?.invalid && !form.get('moeda')?.pristine && form.get('moeda')?.value == null} " [required]="checkIfFieldIsRequired('moeda')" [attr.disabled]="checkIfFieldIsReadonly('moeda') ? '' : null">
              <option [ngValue]="null" selected>Selecione</option>
              <option *ngFor="let moeda of moedas" [ngValue]="moeda">{{ moeda.nome }}</option>
            </select>
                    </div>
                    <div class="col-6">
                        <label>Modalidade da Cabotagem: <span id="modalidadeCabotagem-required-label" class="invisible">*</span></label>
                        <div class="row">
                            <div class="col">
                                <div class="radio">
                                    <input formControlName="modalidadeCabotagem" type="radio" value="Porta">
                                    <label>Porta</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="radio">
                                    <input formControlName="modalidadeCabotagem" type="radio" value="Porto">
                                    <label>Porto</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-3">
                        <label>Unid. Despacho: <span id="unidadeDespacho-required-label" class="invisible">*</span></label>
                        <input formControlName="unidadeDespacho" type="text" class="form-control" aria-describedby="basic-addon2" [mask]="'0000000'" placeholder="Digite aqui..." [ngClass]="{'border border-danger': form.get('unidadeDespacho')?.invalid && !form.get('unidadeDespacho')?.pristine}">
                    </div>
                    <div class="col-3">
                        <label>Unid. Embarque: <span id="unidadeEmbarque-required-label" class="invisible">*</span></label>
                        <input formControlName="unidadeEmbarque" type="text" class="form-control" aria-describedby="basic-addon2" [mask]="'0000000'" placeholder="Digite aqui..." [ngClass]="{'border border-danger': form.get('unidadeEmbarque')?.invalid && !form.get('unidadeEmbarque')?.pristine}">
                    </div>
                    <div class="col-3">
                        <label>Recinto: <span id="recinto-required-label" class="invisible">*</span></label>
                        <input formControlName="recinto" type="text" class="form-control" aria-describedby="basic-addon2" [mask]="'0000000'" placeholder="Digite aqui..." [ngClass]="{'border border-danger': form.get('recinto')?.invalid && !form.get('recinto')?.pristine}">
                    </div>
                    <div class="col-3">
                        <label>Valor CIF: <span id="valorCIF-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="valorCIF" [ngClass]="{'border border-danger': form.get('valorCIF')?.invalid && !form.get('valorCIF')?.pristine}" currencyMask [options]="{prefix: '', align:'left', thousands: '.', decimal: ','}" class="form-control"
                            placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2" [maxlength]="50">
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-6">
                        <label>Solicitante: <span id="solicitante-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="solicitante" [ngClass]="{'border border-danger': !form.get('solicitante')?.pristine && form.get('solicitante')?.errors}" class="form-control" placeholder="Digite aqui..." aria-describedby="basic-addon2" maxlength="300">
                    </div>
                    <div class="col-6">
                        <label>Email solicitante: <span id="emailSolicitante-required-label" class="invisible">*</span></label>
                        <input formControlName="emailSolicitante" type="text" class="form-control" aria-describedby="basic-addon2" maxlength="200" placeholder="Digite aqui..." [ngClass]="{'border border-danger': form.get('emailSolicitante')?.invalid && !form.get('emailSolicitante')?.pristine}">
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-6" *ngIf="form.get('tipoDocumento')?.value != 'BOOKING'">
                        <label>Booking:</label>
                        <input formControlName="booking" type="text" class="form-control" aria-describedby="basic-addon2" maxlength="30" placeholder="Digite aqui..." [ngClass]="{'border border-danger': form.get('booking')?.errors && form.get('booking')?.touched}">
                    </div>
                    <div class="col-6">
                        <label>BL:</label>
                        <input formControlName="bl" type="text" class="form-control" aria-describedby="basic-addon2" maxlength="30" placeholder="Digite aqui..." [ngClass]="{'border border-danger': form.get('bl')?.errors && form.get('bl')?.touched}">
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-6">
                        <label>Telefone: <span id="telefone-required-label" class="invisible">*</span></label>
                        <input formControlName="telefone" type="text" class="form-control" aria-describedby="basic-addon2" [mask]="'(00) 0 0000-0000||(00) 0000-0000'" placeholder="Digite aqui..." [ngClass]="{'border border-danger': form.get('telefone')?.invalid && !form.get('telefone')?.pristine}">
                    </div>
                    <div class="col-3">
                        <label>Leilão: <span id="leilao-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="leilao" [ngClass]="{'border border-danger': !form.get('leilao')?.pristine && form.get('leilao')?.errors}" class="form-control" mask="0000000/000000/0000" placeholder="Digite aqui..." aria-describedby="basic-addon2">
                    </div>
                    <div class="col-3">
                        <label>Lote: <span id="lote-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="lote" [ngClass]="{'border border-danger': form.get('lote')?.invalid && !form.get('lote')?.pristine} " class="form-control" mask="999" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col d-flex">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
