<div [auth]="this.visualizacao">

  <div class="mt-5">
    <app-title [title]="title"></app-title>
    <div class="border rounded-top mt-4">
      <app-pesquisa-emissao-ticket-pesagem-exportacao (onSearch)="searchForContainerRelations($event)">
      </app-pesquisa-emissao-ticket-pesagem-exportacao>
      <div class="grid-title-strip">
        <h3>Dados do Contêiner</h3>
      </div>
      <app-grid-emissao-ticket-pesagem-exportacao></app-grid-emissao-ticket-pesagem-exportacao>
    </div>
    <div class="row my-3">
      <div class="col-4 action-button-group action-double-buttons" *ngIf="grid">
        <button class="action-tertiary-button d-flex align-items-center justify-content-center" (click)="printTicket()"
          [disabled]="!grid.sendSelectedContainerRelations().length">
          <img src="assets/images/Imprimir.png" alt="">
          <span class="ml-1">IMPRIMIR</span>
        </button>
        <button class="action-secondary-button" (click)="clearData()">LIMPAR</button>
      </div>
    </div>
  </div>

</div>
