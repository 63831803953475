import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ConteinerRecepcaoNotaFiscal } from 'src/app/models/RecepcaoDueContingencia/RecepcaoNotaFiscal/ConteinerRecepcaoNotaFiscal';
import { DadosEnvioRNF } from 'src/app/models/RecepcaoDueContingencia/RecepcaoNotaFiscal/DadosEnvioRNF';

@Component({
  selector: 'app-dados-envio-rnf',
  templateUrl: './dados-envio-rnf.component.html',
  styleUrls: ['./dados-envio-rnf.component.scss']
})
export class DadosEnvioRNFComponent implements OnInit {

  public form!: UntypedFormGroup;
  public isCollapsed: boolean = false;
  private colorTheme = 'theme-dark-blue';
  public bsConfig?: Partial<BsDatepickerConfig>;
  private locale = 'pt-br';

  @Output() onSendData: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(
    private fb: UntypedFormBuilder,
    private localeService: BsLocaleService
  ) { }

  public ngOnInit() {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.validation();
  }

  private validation(): void{
    this.form = this.fb.group({
      dataEnvio: [''],
      usuario: [''],
      statusRecepcao: ['']
    });
    this.form.valueChanges.subscribe(
      () => {
        this.onSendData.emit({dadosEnvioRNF: this.form.getRawValue()});
      }
    );
  }

  public setSendingDataFromReceivedConteinerData(dadosEnvio: DadosEnvioRNF): void{
    if(dadosEnvio.dataEnvio){
      this.form.get('dataEnvio')?.setValue(new Date(dadosEnvio.dataEnvio).toLocaleDateString("pt-br"));
    }
    this.form.get('usuario')?.setValue(dadosEnvio.usuario);
    this.form.get('statusRecepcao')?.setValue(dadosEnvio.status);
  }

  public clear(): void{
    this.form.reset();
  }
}
