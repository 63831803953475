<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Relação de Contêiner <span class="text-danger" *ngIf="!areAllContainersValid">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <div class="p-3 d-flex action-button-group row">
            <div class="col-4">
              <button class="action-primary-button" (click)="addManualContainer()" [disabled]="isInsideModal || doesSolicitationHaveIntegration || visualizar || relacaoConteinerReadonly">
                Incluir contêiner
              </button>
            </div>
        </div>
        <div class="table-container">
            <table class="grid-table">
                <thead>
                    <tr>
                        <th>
                            <input class="form-chek-input" type="checkbox" value="" id="incluir" [checked]="areAllItemsSelected" (click)="switchSelectAll()" [disabled]="visualizar || (relacaoConteinerReadonly && !arvoreParteReadonly)">
                        </th>
                        <th>Contêiner</th>
                        <th>Contêiner Transload</th>
                        <th>Parte?</th>
                        <th>Quantidade de Partes</th>
                        <th>Quebra de Lote</th>
                        <th>...</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let relacaoConteiner of containerRelationList; let indexRelacaoConteiner = index" #conteiner app-linha-grid-relacao-conteiner-exportacao [relacaoConteiner]="relacaoConteiner" (onEditFields)="updateContainerInfo($event)" (onManualSelection)="areAllItemsSelected = false"
                        [camposFormulario]="camposFormulario" (onEraseLine)="sendConteinerToErase(indexRelacaoConteiner)" [visualizar]="visualizar || relacaoConteinerReadonly" [isParteBlocked]="arvoreParteReadonly"
                        [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration" (onManualContainerNumberEdition)="sendContainerToSearchShipAndTrip($event, indexRelacaoConteiner)"
                        [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
                    ></tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
