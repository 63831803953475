import { ToastService } from 'src/app/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Campo } from 'src/app/models/Campo';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { PesquisaDocumento } from 'src/app/models/PesquisaDocumento';
import { RelatorioReprovacaoAprovacaoLiberacao } from 'src/app/models/relatorios/RelatorioReprovacaoAprovacaoLiberacao/RelatorioReprovacaoAprovacaoLiberacao';
import { ExcelService } from 'src/app/services/excel.service';
import { PesquisaDocumentoService } from 'src/app/services/pesquisa-documento.service';
import { RelatorioReprovacaoAprovacaoLiberacaoService } from 'src/app/services/relatorios/relatorio-reprovacao-aprovacao-liberacao.service';
import { StatusSolicitacaoDocumental } from 'src/app/models/StatusSolicitacaoDocumental';
import { DatePipe } from '@angular/common';
import { MaskPipe } from 'ngx-mask';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrintService } from 'src/app/services/relatorios/print.service';
import { ImpressaoReprovacaoAprovacaoLiberacaoComponent } from '../impressao-reprovacao-aprovacao-liberacao/impressao-reprovacao-aprovacao-liberacao.component';

@Component({
  selector: 'app-reprovacao-aprovacao-liberacao',
  templateUrl: './reprovacao-aprovacao-liberacao.component.html',
  styleUrls: ['./reprovacao-aprovacao-liberacao.component.scss']
})
export class ReprovacaoAprovacaoLiberacaoComponent implements OnInit {
  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;
  public relatorioReprovacaoAprovacaoLiberacaoGrid!: RelatorioReprovacaoAprovacaoLiberacao[];
  // public relatorioReprovacaoAprovacaoLiberacaoForm!: RelatorioReprovacaoAprovacaoLiberacao[];
  public bsConfig?: Partial<BsDatepickerConfig>;
  public colorTheme: string = 'theme-dark-blue';
  private readonly locale: string = 'pt-br';
  public readonly currentDate: Date = new Date();
  // @Input() camposformulario!: Campo[];
  protected isPrinting: boolean = false;
  @ViewChild(ImpressaoReprovacaoAprovacaoLiberacaoComponent) protected impressao!: ImpressaoReprovacaoAprovacaoLiberacaoComponent;

  public listaStatus!: StatusSolicitacaoDocumental[];

  constructor(
    private pesquisar: PesquisaDocumentoService,
    private fb: UntypedFormBuilder,
    private relatorioReprovacaoAprovacaoLiberacaoService: RelatorioReprovacaoAprovacaoLiberacaoService,
    private excelService: ExcelService,
    private localeService: BsLocaleService,
    private toastService:ToastService,
    private datePipe: DatePipe,
    private changeDetector: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private maskPipe: MaskPipe,
    private printService: PrintService
  ) { }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.localeService.use(this.locale);
    this.getStatus();
    this.validation();
  }

  public validation(): void {
    this.form = this.fb.group({
      periodoDe: ['', [Validators.required]],
      periodoAte: ['', [Validators.required]],
      status: ['']

    })
  }
  private getStatus() {
    this.listaStatus = [];
    this.relatorioReprovacaoAprovacaoLiberacaoService.getStatus()
      .subscribe({
        next: (result) => {
          this.listaStatus = result;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = err?.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                this.toastService.error(error);
              }
            );
          }
        }
      });
  }

  public searchReprovacaoAprovacaoLiberacao(): void {
    this.clearReprovacaoAprovacaoLiberacao();
    this.spinner.show();
    this.relatorioReprovacaoAprovacaoLiberacaoService.searchReprovacaoAprovacaoLiberacao(this.form.getRawValue())
      .subscribe({
        next: (response) => {
          this.spinner.hide();
          let relatorio = response.data as RelatorioReprovacaoAprovacaoLiberacao[];
          if(relatorio?.length){
            this.setReprovacaoAprovacaoLiberacao(relatorio);
          }
          response?.message?.forEach(
            (msg) => {
              if(msg?.length){
                this.toastService.error(msg);
              }
            }
          );
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = err?.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                this.toastService.error(error);
              }
            );
          }
        }
      })
  }

  public downloadPDF(title: string) {
    this.isPrinting = true;
    this.changeDetector.detectChanges();
    this.impressao.setPrintingData({
      dataInicio: this.form.value.periodoDe,
      dataFim: this.form.value.periodoAte,
      nomeRelatorio: title,
      dados: [...this.relatorioReprovacaoAprovacaoLiberacaoGrid]
    });
    this.printService.setPrinting(this.impressao.getTemplate());
    this.changeDetector.detectChanges();
    window.onafterprint = () => {
    this.isPrinting = false;
    this.printService.unsetPrinting();
    }
    setTimeout(() => {
      window.print();
    }, 500);
  }

  public downloadExcel(title: string) {
    let header = [
      { nome: 'Data/Hora', key: 'dataHora', width: 25 },
      { nome: 'Tipo de Documento', key: 'tipoDoc', width: 25 },
      { nome: 'Nº de Documento', key: 'numDoc', width: 25 },
      { nome: 'Matricula', key: 'matricula', width: 30 },
      { nome: 'Status', key: 'status', width: 30 },
      { nome: 'Cliente', key: 'cliente', width: 25 },
      { nome: 'CNPJ/CPF', key: 'cnpjCliente', width: 25 },
      { nome: 'Motivo', key: 'motivo', width: 30 },
    ];
    let data: any[] = this.relatorioReprovacaoAprovacaoLiberacaoGrid.map(
      (liberacao) => {
        return{
          ...liberacao,
          dataHora: this.datePipe.transform(liberacao.dataHora, 'dd/MM/YYYY HH:mm:ss'),
          cnpjCliente: this.maskPipe.transform(liberacao.cnpjCliente, '00.000.000/0000-00||000.000.000-00')
        }
      }
    );
    this.excelService.exportAsExcelFileCustom(data,'reprovação-aprovação-liberação',header,title)
  }


  private setReprovacaoAprovacaoLiberacao(relatorioConteineresLiberadosGrid: RelatorioReprovacaoAprovacaoLiberacao[]): void {
    this.relatorioReprovacaoAprovacaoLiberacaoGrid = [...relatorioConteineresLiberadosGrid];
    this.form.get('relatorioReprovacaoAprovacaoLiberacao')?.setValue(this.relatorioReprovacaoAprovacaoLiberacaoGrid);
  }

  public clearFields(): void{
    this.validation();
    this.relatorioReprovacaoAprovacaoLiberacaoGrid = [];
  }

  private clearReprovacaoAprovacaoLiberacao(): void {
    this.relatorioReprovacaoAprovacaoLiberacaoGrid = [];
    this.form.get('relatorioReprovacaoAprovacaoLiberacao')?.setValue([]);
  }

  public isLiberationListNotEmpty(): boolean{
    return !!this.relatorioReprovacaoAprovacaoLiberacaoGrid?.length;
  }

}
