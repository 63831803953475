<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Árvore de Partes <span class="text-danger" *ngIf="arvorePartesList?.length && !areAllDocumentoParteLinesValid()">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
      <div class="table-container">
        <table class="grid-table">
          <thead>
            <tr>
              <th>Nº do Contêiner</th>
              <th>{{shouldBlockPartQuantityEditionByDocumentType ? 'Nº do CE' : 'Nº do Documento'}}</th>
              <th>{{shouldBlockPartQuantityEditionByDocumentType ? 'Nº do Documento' : 'Canal do Documento'}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let arvorePartes of arvorePartesList; let indexArvorePartes = index"
              app-linha-grid-arvore-de-partes [documentoParte]="arvorePartes" [canais]="canais" [mascara]="mascara"
              (onSendArvoreParte)="updateArvoreParte($event, indexArvorePartes)" [camposFormulario]="camposFormulario"
              (onFirstEdit)="markEveryLineAstouched()" [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration"
              [visualizar]="arvoreParteReadonly" [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
            >
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
