import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastService } from 'src/app/services/toast.service';
import { FiltroRelatorioEntregaCargaNavio } from 'src/app/models/relatorios/RelatorioEntregaCargaNavio/FiltroRelatorioEntregaCargaNavio';

@Component({
  selector: 'app-filtro-relatorio-entrega-da-carga-navio',
  templateUrl: './filtro-relatorio-entrega-da-carga-navio.component.html',
  styleUrls: ['./filtro-relatorio-entrega-da-carga-navio.component.scss']
})
export class FiltroRelatorioEntregaDaCargaNavioComponent implements OnInit {
  public form!: UntypedFormGroup;
  public bsConfig?: Partial<BsDatepickerConfig>;
  public colorTheme: string = 'theme-dark-blue';
  private readonly locale: string = 'pt-br';
  public readonly currentDate: Date = new Date();

  @Output() onSendFilter: EventEmitter<FiltroRelatorioEntregaCargaNavio> = new EventEmitter<FiltroRelatorioEntregaCargaNavio>();

  constructor
    (private fb: UntypedFormBuilder,
      private localeService: BsLocaleService,
      private toastService:ToastService
    ) { }

  ngOnInit(): void {
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.localeService.use(this.locale);
    this.validation();
  }

  private validation(): void {
    this.form = this.fb.group(
      {
        navio: [''],
        viagem: [''],
        de: [''],
        ate: [''],
      },
      {
        validators: [this.checkIfFieldsAreFilled.bind(this)]
      }
    );
  }

  public clearFields(): void {
    this.validation();
  }

  public sendFilter(): void {
    const filter: FiltroRelatorioEntregaCargaNavio = this.form.getRawValue();
    this.onSendFilter.emit(filter);
  }

  private checkIfFieldsAreFilled(group: UntypedFormGroup): {unfilledForm: boolean} | null{
    if(!group){
      return null;
    }
    let de = group.get('de')?.value ?? '';
    let ate = group.get('ate')?.value ?? '';
    let navio = group.get('navio')?.value ?? '';
    let viagem = group.get('viagem')?.value ?? '';
    if(!de && !ate && !navio && !viagem){
      return {unfilledForm: true};
    }
    if((de && !ate) || (!de && ate)){
      return {unfilledForm: true};
    }
    return null;
  }
}
