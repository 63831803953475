<div class="card mt-4">
  <div class="card-header p-3">
      <div class="d-flex justify-content-between">
          <span>Relação de números de DUE e Contêineres <span class="text-danger" *ngIf="!isDueConteinerRelationValid">*</span></span>
          <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
          <!--fa fa-chevron-down-->
      </div>
  </div>
  <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <div class="table-container">
      <table class="grid-table">
        <thead>
          <th>Nº de DUE</th>
          <th>Nº de Contêiner</th>
          <th>CNPJ</th>
          <th>Canal</th>
        </thead>
        <tbody>
          <tr *ngFor="let dueConteiner of dueConteinerList" app-linha-grid-due-conteiner-exp
            [dueConteiner]="dueConteiner" [canais]="canais" [blockEdition]="blockEdition"
          ></tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
