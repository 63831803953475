import { MaskPipe } from 'ngx-mask';
import { RelacaoConteinerN4DocumentoTransporte } from '../../../models/documento-transporte/LacreConteinerRecepcaoDocumentoTransporte';
import { DocumentosService } from './../../../services/documentos.service';
import { DocumentCustomPattern } from '../../../shared/utils/constantes-documentos';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Component, Input, OnInit, AfterViewInit, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { Campo } from 'src/app/models/Campo';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { TipoVeiculoDocumentoTransporte } from 'src/app/models/TipoVeiculoDocumentoTransporte';
import { DadosDocumentoTransporteN4 } from 'src/app/models/RecepcaoDocumentoTransporte/DadosDocumentoTransporteN4';
import { ConteinerRecepcaoDocumentoTransporteN4DTO } from 'src/app/models/RecepcaoDocumentoTransporte/ConteinerRecepcaoDocumentoTransporteDTO';
import { LinhaGridRelacaoConteinerN4DocumentoTransporteComponent } from '../../../shared/components/documento-transporte/linha-grid-relacao-conteiner-n4-documento-transporte/linha-grid-relacao-conteiner-n4-documento-transporte.component';
@Component({
  selector: 'app-dados-documento-transporte-n4',
  templateUrl: './dados-documento-transporte-n4.component.html',
  styleUrls: ['./dados-documento-transporte-n4.component.scss']
})
export class DadosDocumentoTransporteN4Component implements OnInit, AfterViewInit {
  public dataRegistroValue?: Date;
  public dataDesembaracoValue?: Date;
  public isCollapsed: boolean = false;
  public TipoVeiculos: TipoVeiculoDocumentoTransporte[] = [];
  public form!: UntypedFormGroup;
  public namePattern = DocumentCustomPattern.NAME_PATTERN;
  public dadosdocumentotransporten4!: DadosDocumentoTransporteN4;
  public lacresN4!: RelacaoConteinerN4DocumentoTransporte[];
  public quantidadeReboques!: number;

  @Input() camposformulario!: Campo[];
  @ViewChildren(LinhaGridRelacaoConteinerN4DocumentoTransporteComponent) gridConteineres!: QueryList<LinhaGridRelacaoConteinerN4DocumentoTransporteComponent>;

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private documentosService: DocumentosService,
    private maskPipe: MaskPipe
  ) {
  }

  public ngOnInit(): void {
    this.getVehicleTypes();
    this.validation();
    }

  public ngAfterViewInit(): void {
    if(!this.camposformulario || !this.camposformulario.length){
      return;
    }
    this.camposformulario.forEach(
      (campo) => {
        if(campo.obrigatorio){
          this.form.get(campo.nome)?.addValidators(Validators.required);
        }
      }
    );
  }


  private validation(): void{
    this.form = this.fb.group({
      nomeCondutor: [''],
      cpfCondutor: ['', [this.checkValidConductorCpf.bind(this)]],
      tipoVeiculo: [''],
      placaCavalo: [''],
      placaReboque1: [''],
      placaReboque2: [''],
      placaReboque3: ['']
    });
    this.form.get('tipoVeiculo')?.valueChanges.subscribe(
      (value) => this.changeQuantidadeReboques(value)
    );
    this.lacresN4 = [];
    this.quantidadeReboques = 0;
  }

  private getVehicleTypes(): void{
    this.TipoVeiculos = [];
    this.documentosService.getVehicleTypesForDocumentoEntregaTransporte()
      .subscribe({
        next: (tiposVeiculos) => {
          this.TipoVeiculos = [...tiposVeiculos];
        }
      });
  }

  public checkValidConductorCpf(control: AbstractControl): {invalidDocument: string} | null{
    let controlValue = control.value;
    if(!controlValue?.length){
      return null;
    }
    let error: string | null = this.verificacaoDocumentosService.calculateValidCPF(controlValue);
    if(error?.length){
      return {invalidDocument: error};
    }
    return null;
  }

  public removeSpacesFromExtremities(inputName: string): void{
    this.form.get(inputName)?.setValue((this.form.get(inputName)?.value as string).trim(), {emitEvent: false});
  }

  // public setFormValuesFromReceivedDocumentoTransporteN4(documentoTransporteN4: DadosDocumentoTransporteN4 | undefined): void{
  //   if(!documentoTransporteN4){
  //     return;
  //   }
  //   this.form.get('nomeCondutor')?.setValue(documentoTransporteN4?.nomeCondutor);
  //   this.form.get('cpfCondutor')?.setValue(documentoTransporteN4?.cpfCondutor);
  //   // if(documentoTransporteN4.placaReboque1 && documentoTransporteN4.placaReboque2 && documentoTransporteN4.placaReboque3){
  //   //   this.form.get('tipoVeiculo')?.setValue('Cavalo com 3 Reboques');
  //   // }
  //   // else if(documentoTransporteN4.placaReboque1 && documentoTransporteN4.placaReboque2){
  //   //   this.form.get('tipoVeiculo')?.setValue('Cavalo com 2 Reboques');
  //   // }
  //   // else if(documentoTransporteN4.placaReboque1){
  //   //   this.form.get('tipoVeiculo')?.setValue('Cavalo com 1 Reboque');
  //   // }
  //   this.form.get('tipoVeiculo')?.setValue(
  //     this.TipoVeiculos.find(
  //       (tipoVeiculo) => tipoVeiculo.quantidadeReboque == (
  //         (documentoTransporteN4.placaReboque1?.length ? 1 : 0) +
  //         (documentoTransporteN4.placaReboque2?.length ? 1 : 0) +
  //         (documentoTransporteN4.placaReboque3?.length ? 1 : 0)
  //       )
  //     )
  //   );
  //   this.form.get('placaCavalo')?.setValue(documentoTransporteN4?.placaCavalo);
  //   this.form.get('placaReboque1')?.setValue(documentoTransporteN4?.placaReboque1);
  //   this.form.get('placaReboque2')?.setValue(documentoTransporteN4?.placaReboque2);
  //   this.form.get('placaReboque3')?.setValue(documentoTransporteN4?.placaReboque3);
  //   this.changeDetector.detectChanges();
  // }

  public checkIfFieldIsRequired(fieldName: string): boolean{
    return !!this.camposformulario?.find(
      campo => campo.nome == fieldName
    )?.obrigatorio;
  }

  public checkIfFieldIsReadonly(fieldName: string): boolean{
    return !!this.camposformulario?.find(
      campo => campo.nome == fieldName
    )?.leitura;
  }

  public clean(){
    this.form.reset();
    this.lacresN4 = [];
  }

  private changeQuantidadeReboques(tipoVeiculo: TipoVeiculoDocumentoTransporte): void{
    if(!tipoVeiculo){
      return;
    }
    this.quantidadeReboques = tipoVeiculo.quantidadeReboque ?? 0;
    for(let plate: number = 1; plate < 3; plate++){
      if(plate > this.quantidadeReboques){
        this.form.get(`placaReboque${plate}`)?.setValue('');
      }
    }
  }

  public setFormValuesFromReceivedConteinerRecepcaoDocumentoTransporteDTO(conteiner: ConteinerRecepcaoDocumentoTransporteN4DTO){
    if(!conteiner){
      return;
    }
    this.form.get('nomeCondutor')?.setValue(conteiner?.nomeCondutor);
    this.form.get('peso')?.setValue(conteiner?.peso);
    this.form.get('tara')?.setValue(conteiner?.tara);
    this.form.get('cpfCondutor')?.setValue(this.maskPipe.transform(conteiner?.cpfCondutor!, '000.000.000-00'));
    // if(conteiner.placaReboque1 && conteiner.placaReboque2 && conteiner.placaReboque3){
    //   this.form.get('tipoVeiculo')?.setValue('Cavalo com 3 Reboques');
    // }
    // else if(conteiner.placaReboque1 && conteiner.placaReboque2){
    //   this.form.get('tipoVeiculo')?.setValue('Cavalo com 2 Reboques');
    // }
    // else if(conteiner.placaReboque1){
    //   this.form.get('tipoVeiculo')?.setValue('Cavalo com 1 Reboque');
    // }
    this.form.get('tipoVeiculo')?.setValue(
      this.TipoVeiculos.find(
        (tipoVeiculo) => tipoVeiculo.quantidadeReboque == (
          (conteiner.placaReboque1?.length ? 1 : 0) +
          (conteiner.placaReboque2?.length ? 1 : 0) +
          (conteiner.placaReboque3?.length ? 1 : 0)
        )
      )
    );
    this.form.get('placaCavalo')?.setValue(conteiner?.placaCavalo);
    this.form.get('placaReboque1')?.setValue(conteiner?.placaReboque1);
    this.form.get('placaReboque2')?.setValue(conteiner?.placaReboque2);
    this.form.get('placaReboque3')?.setValue(conteiner?.placaReboque3);
    this.lacresN4 = conteiner.relacaoConteinerLacresN4 ?? [];
    this.form.markAllAsTouched();
    this.changeDetector.detectChanges();
  }

  public getContainers(): RelacaoConteinerN4DocumentoTransporte[]{
    return this.gridConteineres?.map(
      (container) => container.getContainer()
    ) ?? [];
  }

  public areAllContainersValid(): boolean{
    if(!this.gridConteineres?.length){
      return true;
    }
    return this.gridConteineres?.map(
      (container) => container.isContainerValid()
    )?.every(line => line);
  }

  public isFormN4Valid(): boolean{
    return !this.form.invalid && this.areAllContainersValid();
  }

  public getDocumentoN4FromForm(): ConteinerRecepcaoDocumentoTransporteN4DTO{
    let documentoCCT: ConteinerRecepcaoDocumentoTransporteN4DTO = this.form.getRawValue();
    documentoCCT.relacaoConteinerLacresN4 = this.getContainers();
    return documentoCCT;
  }

  public getUnidadeTransporte(): string{
    return this.form?.get('tipoVeiculo')?.value?.nome || '';
  }

}
