import { ModalRespostaPositivaNegativaComponent } from './../../../shared/components/modais/modal-resposta-positiva-negativa/modal-resposta-positiva-negativa.component';
import { VerificacaoDocumentosService } from './../../../shared/utils/verificacao-documentos.service';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { RecepcaoDueContingenciaService } from './../../../services/recepcao-due-contingencia.service';
import { DocumentoTransporte } from 'src/app/models/RecepcaoDocumentoTransporte/DocumentoTransporte';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { Campo } from 'src/app/models/Campo';
import { ToastService } from 'src/app/services/toast.service';
import { DocumentoTransporteComponent } from 'src/app/components/recepcao-documento-transporte/documento-transporte/documento-transporte.component';
import { DadosDocumentoTransporteCctComponent } from 'src/app/components/recepcao-documento-transporte/dados-documento-transporte-cct/dados-documento-transporte-cct.component';
import { DadosDocumentoTransporteN4Component } from 'src/app/components/recepcao-documento-transporte/dados-documento-transporte-n4/dados-documento-transporte-n4.component';
import { DadosEnvioTransporteComponent } from 'src/app/components/recepcao-documento-transporte/dados-envio-transporte/dados-envio-transporte.component';
import { RecepcaoDocumentoTransporte } from 'src/app/models/RecepcaoDocumentoTransporte/RecepcaoDocumentoTransporte';
import { ConteinerRecepcaoDocumentoTransporteCCTDTO, ConteinerRecepcaoDocumentoTransporteDTO, ConteinerRecepcaoDocumentoTransporteN4DTO } from 'src/app/models/RecepcaoDocumentoTransporte/ConteinerRecepcaoDocumentoTransporteDTO';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';
import { DadosEnvioTransporte } from 'src/app/models/RecepcaoDocumentoTransporte/DadosEnvioTransporte';


@Component({
  selector: 'app-recepcao-documento-transporte',
  templateUrl: './recepcao-documento-transporte.component.html',
  styleUrls: ['./recepcao-documento-transporte.component.scss'],
})
export class RecepcaoDocumentoTransporteComponent implements OnInit, AfterViewInit {
  public title: string = 'Documento Transporte';
  public disabled = 'disabled';
  public disabledCancelar = true;
  public modalRef!: BsModalRef;
  public isSearchDone: boolean = false;
  public camposFormularios: Campo[] = [];
  public isSolicitationReceived: boolean = false;
  public modelRecepcao!: RecepcaoDocumentoTransporte;
  public blockSending: boolean = true;
  public mensagensDivergencia: string[] = [];
  public identificador: UntypedFormControl = new UntypedFormControl('');
  //@Output() dataFormIn = new EventEmitter<FormGroup>();
  @ViewChild(DocumentoTransporteComponent) documento!: DocumentoTransporteComponent;
  @ViewChild(DadosDocumentoTransporteCctComponent) dadosCct!: DadosDocumentoTransporteCctComponent;
  @ViewChild(DadosDocumentoTransporteN4Component) dadosN4!: DadosDocumentoTransporteN4Component;
  @ViewChild(DadosEnvioTransporteComponent) dadosEnvio!: DadosEnvioTransporteComponent;
  @ViewChild('modalConfirmarEnvioComDivergencias') modalConfirmarEnvioComDivergencias!: ModalRespostaPositivaNegativaComponent;
  public readonly visualizacao = new AuthRoles(Telas.recepcaoDocumentoTransporte, Permissoes.visualizacao)
  public readonly enviarCCT = new AuthRoles(Telas.recepcaoDocumentoTransporte, Permissoes.enviarCCT)
  public readonly salvar = new AuthRoles(Telas.recepcaoDocumentoTransporte, Permissoes.salvar)


  constructor(
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private recepcaoDueContingenciaService: RecepcaoDueContingenciaService,
    private toastService:ToastService,
    private changeDetector: ChangeDetectorRef,
    private verificacaoDocumentosService: VerificacaoDocumentosService
  ) { }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {

  }

  //Traz o tipodocumento e o numero para enviar ao endpoit
  //recebe os dados da integração em emit para os componentes filhos
  public searchContainerByContainerNumber(documento: any) {
    if (!documento) {
      return;
    }
    this.blockSending = false;
    let documentoTransporte: DocumentoTransporte = documento.documento as DocumentoTransporte;
    this.spinner.show();
    this.recepcaoDueContingenciaService.searchForContainerOnForRecepcaoDocumentoTransporte(documentoTransporte)
      .subscribe({
        next: (conteiner) => {
          this.spinner.hide();
          console.log(conteiner);
          let conteinerData = conteiner.data as ConteinerRecepcaoDocumentoTransporteDTO;
          this.identificador.setValue(conteinerData.identificador);
          let conteinerN4 = conteinerData.dadosRecepcaoDocumentoTransporteN4;
          if (conteinerN4.recepcaoRealizadaComSucesso) {
            this.dadosEnvio.setFormValuesFromReceivedComplementaryData({
              dataEnvio: conteinerN4.dataEnvio as Date,
              usuario: conteinerN4.usuario as string,
              status: conteinerN4.statusRecepcao as string
            });
          this.blockSending = true;
          }

          if (!conteinerN4.pesagem) {
            conteinerN4.relacaoConteinerLacresN4?.forEach((c => {
              if (c.pesoApurado === 0) {
                this.toastService.error("Não foi encontrado valor de pesagem para o contêiner: " + c.conteiner);
              }
            }))
            this.blockSending = true;
          }

          this.dadosN4.setFormValuesFromReceivedConteinerRecepcaoDocumentoTransporteDTO(conteinerN4);


          let conteinerCCT = conteinerData.dadosRecepcaoDocumentoTransporteCCT;

          this.dadosCct.setFormValuesFromReceivedConteinerRecepcaoDocumentoTransporteDTO(conteinerCCT);
          if (!this.checkIfContainerNumbersAndSealsAreEqual(conteinerN4, conteinerCCT)) {
            this.toastService.error("Existe(m) divergência(s) entre o(s) Lacre(s) do N4 x CCT!");
            this.blockSending = true;
          }
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        },
        complete: () => {
          this.changeDetector.detectChanges();
        }
      });
    this.isSearchDone = true;
  }

  public clean() {
    this.spinner.show('limpar');
    setTimeout(() => {
      this.spinner.hide('limpar');
    }, 1500);
    this.dadosCct.clean();
    this.documento.clean();
    this.dadosN4.clean();
    this.dadosEnvio.clean();
  }

  public checkIfVehiclePlatesAndConductorDocumentAreEqual(dadosN4: ConteinerRecepcaoDocumentoTransporteN4DTO, dadosCct: ConteinerRecepcaoDocumentoTransporteCCTDTO): boolean{
    let placasN4: string[] = [
      (dadosN4?.placaCavalo ?? '').trim(),
      (dadosN4?.placaReboque1 ?? '').trim(),
      (dadosN4?.placaReboque2 ?? '').trim(),
      (dadosN4?.placaReboque3 ?? '').trim()
    ];
    let placasCCT: string[] = [
      (dadosCct?.placaCavalo ?? '').trim(),
      (dadosCct?.placaReboque1 ?? '').trim(),
      (dadosCct?.placaReboque2 ?? '').trim(),
      (dadosCct?.placaReboque3 ?? '').trim()
    ];
    if(!placasN4?.every(placaN4 => placasCCT.includes(placaN4))){
      return false;
    }
    if(!placasCCT?.every(placaCCT => placasN4.includes(placaCCT))){
      return false;
    }
    //if(this.verificacaoDocumentosService.eraseMask(dadosN4.cpfCondutor ?? '') !== this.verificacaoDocumentosService.eraseMask(dadosCct.cpfCondutor ?? '')){
   //   return false;
    //}
    return true;
  }
  public checkIfContainerNumbersAreEqual(dadosN4: ConteinerRecepcaoDocumentoTransporteN4DTO, dadosCct: ConteinerRecepcaoDocumentoTransporteCCTDTO): boolean{
    let conteineresN4 = dadosN4?.relacaoConteinerLacresN4?.map(conteiner => conteiner.conteiner.trim()) ?? [];
    let conteineresCCT = dadosCct?.relacaoConteinerLacresCCT?.map(conteiner => conteiner.conteiner.trim()) ?? [];
    if(
      !conteineresN4.every(conteiner => conteineresCCT.includes(conteiner)) ||
      !conteineresCCT.every(conteiner => conteineresN4.includes(conteiner))
    ){
      return false;
    }
    return true;
  }

  public checkIfContainerNumbersAndSealsAreEqual(dadosN4: ConteinerRecepcaoDocumentoTransporteN4DTO, dadosCct: ConteinerRecepcaoDocumentoTransporteCCTDTO): boolean{
    let conteineresN4 = dadosN4?.relacaoConteinerLacresN4?.map(conteiner => conteiner.conteiner.trim()) ?? [];
    let conteineresCCT = dadosCct?.relacaoConteinerLacresCCT?.map(conteiner => conteiner.conteiner.trim()) ?? [];
    if(
      !conteineresN4.every(conteiner => conteineresCCT.includes(conteiner)) ||
      !conteineresCCT.every(conteiner => conteineresN4.includes(conteiner))
    ){
      return false;
    }
    if(conteineresN4?.length != conteineresCCT?.length){
      return false;
    }
    if(!dadosN4?.relacaoConteinerLacresN4?.length && !dadosCct?.relacaoConteinerLacresCCT?.length){
      return true;
    }
    let lacreValido = true;
    dadosN4?.relacaoConteinerLacresN4?.forEach(
      (n4) => {
        let index = dadosCct?.relacaoConteinerLacresCCT?.findIndex(conteiner => conteiner.conteiner.trim() == n4.conteiner.trim()) ?? -1;
        if(index < 0){
          lacreValido = false;
        }
        let lacresN4 = n4.lacres.split(',').map(lacre => lacre.trim()) ?? [];
        let lacresCCT = dadosCct?.relacaoConteinerLacresCCT?.[index].lacres.split(',').map(lacre => lacre.trim()) ?? [];
        if(lacresN4?.length != lacresCCT?.length){
          lacreValido = false;
        }
        if(!lacresN4?.length || !lacresCCT?.length){
          lacreValido = false;
        }
        if(
          !lacresN4.every(lacreN4 => lacresCCT.includes(lacreN4)) ||
          !lacresCCT.every(lacreCCT => lacresN4.includes(lacreCCT))
        ){
          lacreValido = false;
        }
      }
    );
    return lacreValido;
  }

  private checkIfTransportUnitsAreEqual(): boolean{
    let unidadeTransporteN4 = this.dadosN4?.getUnidadeTransporte();
    let unidadeTransporteCCT = this.dadosCct?.getUnidadeTransporte();
    if(!unidadeTransporteN4?.length || !unidadeTransporteCCT?.length){
      return false;
    }
    return unidadeTransporteN4?.toUpperCase()?.trim() === unidadeTransporteCCT?.toUpperCase()?.trim();
  }

  public checkDataSendingToCCT(): void{
    this.mensagensDivergencia = [];
    let dadosVeiculoConferem: boolean;
    let dadosConteinerConferem: boolean;
    let dadosEmbalagemConferem: boolean;
    let dadosUnidadeTransporteConferem: boolean;
    let formN4 = this.dadosN4.getDocumentoN4FromForm();
    let formCct = this.dadosCct.getDocumentoCCTFromForm();
    dadosVeiculoConferem = this.checkIfVehiclePlatesAndConductorDocumentAreEqual(formN4, formCct);
    if(!dadosVeiculoConferem){
      this.mensagensDivergencia?.push('Divergência(s) entre o(s) veículo(s) do N4 x CCT');
    }
    dadosConteinerConferem = this.checkIfContainerNumbersAreEqual(formN4, formCct);
    if(!dadosConteinerConferem){
      this.mensagensDivergencia?.push('Divergência(s) entre o(s) contêiner(es) do N4 x CCT');
    }
    dadosEmbalagemConferem = this.checkIfContainerNumbersAndSealsAreEqual(formN4, formCct);
    if(!dadosEmbalagemConferem){
      this.mensagensDivergencia?.push('Divergência(s) entre o(s) lacre(s) do N4 x CCT');
    }
    dadosUnidadeTransporteConferem = this.checkIfTransportUnitsAreEqual();
    if(!dadosUnidadeTransporteConferem){
      this.mensagensDivergencia?.push('Divergência(s) entre a(s) unidade(s) de transporte do N4 x CCT');
    }
    this.dadosCct.setDivergency(dadosVeiculoConferem, dadosConteinerConferem, dadosEmbalagemConferem, dadosUnidadeTransporteConferem);
    if(!this.mensagensDivergencia?.length){
      this.sendDataToCCT();
    }
    else{
      this.modalConfirmarEnvioComDivergencias.openModalForConfirmOrRefuse();
    }
  }

  public async sendDataToCCT() {
    if (this.blockSending || !this.areFormsValid) {
      return;
    }
    this.spinner.show();
    this.recepcaoDueContingenciaService.sendDataToCCTForRecepcaoDocumentoTransporte(this.getConteinerRecepcaoDocumentoTransporteFromForm())
      .subscribe({
        next: (response) => {
          response.message?.forEach(
            (message) => {
              if (message && message.length) {
                this.toastService.success(message);
              }
            }
          )
          this.dadosEnvio.setFormValuesFromReceivedComplementaryData(response.data as DadosEnvioTransporte);
          this.blockSending = true;
          this.spinner.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public async save()  {
    this.spinner.show('carregando');
    this.recepcaoDueContingenciaService.saveDataToCCTForRecepcaoDocumentoTransporte(this.getConteinerRecepcaoDocumentoTransporteFromForm())
      .subscribe({
        next: (response) => {
          response.message?.forEach(
            (message) => {
              if (message && message.length) {
                this.toastService.success(message);
              }
            }
          )
          this.documento.searchContainer();
          // this.dadosEnvio.setFormValuesFromReceivedComplementaryData(response.data as DadosEnvioTransporte);
          this.blockSending = false;
          this.spinner.hide('carregando');
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide('carregando');
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public getConteinerRecepcaoDocumentoTransporteFromForm(): ConteinerRecepcaoDocumentoTransporteDTO{
    let documentoTransporte: ConteinerRecepcaoDocumentoTransporteDTO = new ConteinerRecepcaoDocumentoTransporteDTO();
    documentoTransporte.dadosRecepcaoDocumentoTransporteCCT = this.dadosCct.getDocumentoCCTFromForm();
    documentoTransporte.dadosRecepcaoDocumentoTransporteN4 = this.dadosN4.getDocumentoN4FromForm();
    documentoTransporte.numeroDocumento = this.documento.getDocumentFromForm().numeroDocumento;
    documentoTransporte.tipoDocumento = this.documento.getDocumentFromForm().tipoDocumento;
    documentoTransporte.identificador = this.identificador.value;
    return documentoTransporte
  }

  public get areFormsValid(): boolean{
    return this.documento?.isDocumentoValid() &&
      this.dadosN4?.isFormN4Valid() &&
      this.dadosCct?.isFormCCTValid();
  }

}

