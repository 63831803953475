<div [auth]="this.visualizacao">

    <div class="mt-5">
        <app-title [title]='title' [subtitle]='subtitle'></app-title>
        <app-nota-fiscal-nfe (onSearchForInvoice)="searchForConteinerByInvoice($event)" (onValidaNotaFiscal)="searchChaveNFE($event)"></app-nota-fiscal-nfe>
        <app-componente-conteiner (onUpdateContainer)="getDataFromSubforms($event)"></app-componente-conteiner>
        <app-transportador (onSendTransporterData)="getDataFromSubforms($event)"></app-transportador>
        <app-condutor (onSendConductorData)="getDataFromSubforms($event)"></app-condutor>
        <app-informacoes-cargas (onSendCargoInfoData)="getDataFromSubforms($event)"></app-informacoes-cargas>
        <app-dados-envio-rnf (onSendData)="getDataFromSubforms($event)"></app-dados-envio-rnf>

    </div>

    <div class="d-flex justify-content-center align-content-center mt-5">
        <div class="solicitation-action-buttons action-double-buttons">
            <button class="action-secondary-button" (click)="clear()">LIMPAR CAMPOS</button>
            <button [auth]="this.enviarCCT" (click)="checkForSealDivergences()" class="action-primary-button" [disabled]="blockSending || !hasConteinerVinculado">ENVIAR AO CCT</button>
            <button [auth]="this.salvar" class="action-primary-button" (click)="save()" [disabled]="blockSending || !hasConteinerVinculado" style="display: none;">SALVAR</button>
        </div>
    </div>

    <app-modal-resposta-positiva-negativa [modalTitle]=" 'Divergência encontrada!' " [positiveText]=" 'Sim' " [negativeText]=" 'Não' " (onChoosePositiveAction)="sendDataToCCT() ">
        <div class="row mb-3 ">
            <div class="col ">
                <p>Contêiner {{containerNumber}} possui divergência de Lacre. Confirma o envio ao CCT ?</p>
            </div>
        </div>
    </app-modal-resposta-positiva-negativa>

</div>
