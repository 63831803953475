<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Arquivo XML</span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}"
        data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false"
        aria-label="Toggle navigation"></i>
      <!--fa fa-chevron-down-->
    </div>
  </div>

  <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <form [formGroup]="form">
      <div class="p-3 file-component" [ngClass]="{'disabled-xml': isBlocked}">
        <div class="row">
          <div class="col">
            <label>Arquivo XML</label>
            <div class="input-group mb-3">
              <label class='arquivo' for='arquivoXml'>
                <img src="assets/images/Vector.png" alt="">
                ENVIAR ARQUIVO
              </label>
              <input type="file" accept="text/xml" id="arquivoXml" (change)="onChange($event)" [disabled]="isBlocked">
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>


<!-- <ng-template #template>
  <div class="modal-header">
    <button class="btn-close close pull right" (click)="closeModel()"><span class="visualli-hidden"
        style="font-size: 18px;">&times;</span></button>
  </div>
  <div class="modal-body text-center">
    <span> Arquivo XML é inválido. Favor verificar! </span>
  </div>
</ng-template> -->
