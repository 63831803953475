import { LinhaGridRelacaoConteinerEstimativaCalculoComponent } from './../linha-grid-relacao-conteiner-estimativa-calculo/linha-grid-relacao-conteiner-estimativa-calculo.component';
import { ConteinerEstimativaCalculo } from './../../../../models/EstimativaCalculo/CadastroDocumento/ConteinerEstimativaCalculo';
import { Component, Input, OnInit, ViewChildren, QueryList, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Campo } from 'src/app/models/Campo';

@Component({
  selector: 'app-relacao-conteiner-estimativa-calculo',
  templateUrl: './relacao-conteiner-estimativa-calculo.component.html',
  styleUrls: ['./relacao-conteiner-estimativa-calculo.component.scss']
})
export class RelacaoConteinerEstimativaCalculoComponent implements OnInit {

  public isCollapsed: boolean = false;
  public containerRelationList!: ConteinerEstimativaCalculo[];
  public form!: UntypedFormGroup;
  public areAllItemsSelected: boolean = false;
  public relacaoConteinerReadonly: boolean = false;
  public relacaoConteinerRequired: boolean = false;
  @ViewChildren(LinhaGridRelacaoConteinerEstimativaCalculoComponent) containersGrid!: QueryList<LinhaGridRelacaoConteinerEstimativaCalculoComponent>;
  @Output() public onUpdateContainerRelation: EventEmitter<{}> = new EventEmitter<{}>();
  @Input() fieldSettings!: Campo[];
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() public visualizar?: boolean = false
  @Input() public selectedDocumentType?: string;
  @Input() shouldBlockPartQuantityEditionByDocumentType?: boolean;
  @Input() isExpDocument?: boolean;
  @Output() onEraseConteiner: EventEmitter<ConteinerEstimativaCalculo> = new EventEmitter<ConteinerEstimativaCalculo>()
  @Output() onUpdateSelectedContainers: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSearchShipAndTripByContainerNumber: EventEmitter<string> = new EventEmitter<string>()

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit() {
    this.containerRelationList = [];
  }

  public isContainersRelationValid(): boolean{
    if(this.relacaoConteinerRequired && !this.getContainers()?.length){
      return false;
    }
    if(
      this.selectedDocumentType === 'CTE' &&
      !this.getSelectedContainers()?.length
    ){
      return false;
    }
    if(!this.containersGrid?.length){
      return true;
    }
    return this.containersGrid?.map(
      (container) => container.isFormValid()
    ).every(containerValid => containerValid);
  }

  public getContainers(): ConteinerEstimativaCalculo[]{
    return this.containersGrid?.map(
      (container) => container.sendContainer()
    ) ?? [];
  }

  public getSelectedContainers(): ConteinerEstimativaCalculo[]{
    return this.containersGrid?.filter(
      (container) => !!container?.selecionado?.value
    )?.map(
      (container) => container.sendContainer()
    ) ?? [];
  }

  public setFormValuesFromReceivedContainersRelationList(containerRelation: ConteinerEstimativaCalculo[] | undefined): void{
    if(!containerRelation){
      return;
    }
    this.containerRelationList = [...containerRelation];
    this.changeDetector.detectChanges();
  }

  public updateContainerRelation(): void{
    this.onUpdateContainerRelation.emit({'relacaoConteineres': this.getContainers()});
  }

  public updateValidators(): void{
    let campoRelacaoConteiner: Campo | undefined = this.fieldSettings?.find(campo => campo.nome == 'relacaoConteiner');
    this.relacaoConteinerReadonly = !!campoRelacaoConteiner?.leitura || !!campoRelacaoConteiner?.bloqueado;
    this.relacaoConteinerRequired = !!campoRelacaoConteiner?.obrigatorio;
    this.containersGrid.forEach(
      (container) => {
        container.updateValidators();
      }
    );
    this.changeDetector.detectChanges();
    if(this.doesSolicitationHaveIntegration){
      // this.visualizar = true;
    }
  }

  public clearFields(): void{
    this.containerRelationList = [];
  }

  public setContainerAsIgnorable(containerNumber: string): void{
    this.containersGrid.find(
      (container) => container.conteiner.value == containerNumber
    )?.setContainerAsIgnorable();
  }

  public addManualContainer(): void{
    if(this.doesSolicitationHaveIntegration){
      return;
    }
    this.containerRelationList.push({
      id: 0,
      conteiner: '',
      conteinerTransload: '',
      reefer: false,
      oog: false,
      imo: false,
      quebraLote: false,
      liberadoAte: null
    });
    this.changeDetector.detectChanges();
  }

  public clear(): void{
    this.form.reset();
    this.eraseConteinerRelation();
  }

  public eraseConteinerRelation(): void{
    this.containerRelationList = [];
  }

  public eraseConteinerFromGrid(conteiner: ConteinerEstimativaCalculo): void{
    let index = this.containersGrid.toArray().findIndex(containersGrid => containersGrid.conteiner.value == conteiner.conteiner)
    if(index >= 0 ){
      this.containerRelationList.splice(index, 1);
          if(!this.containerRelationList?.length){
            this.areAllItemsSelected = false;
          }
          this.changeDetector.detectChanges();
          this.onUpdateSelectedContainers.emit();
    }
    }

    public sendConteinerToErase(index: number): void{
      if(this.doesSolicitationHaveIntegration){
        return;
      }
      this.onEraseConteiner.emit(this.containersGrid.get(index)?.sendContainer());
    }

    public areAllContainersValid(): boolean{
      if(this.relacaoConteinerRequired && !this.getContainers()?.length){
        return false;
      }
      if(
        this.selectedDocumentType === 'CTE' &&
        !this.getSelectedContainers()?.length
      ){
        return false;
      }
      if(!this.containersGrid?.length){
        return true;
      }
      return this.containersGrid?.map(
        (conteiner) => conteiner.isContainerValid()
      )?.every(line => line);
    }

  public switchSelectionForAll(): void{
    this.areAllItemsSelected = !this.areAllItemsSelected;
    this.containersGrid?.forEach(
      (container) => {
        container.switchSelectionFromParent(this.areAllItemsSelected);
      }
    );
  }

  public sendContainerToSearchShipAndTrip(containerNumber: string, index: number): void{
    if(index !== 0){
      return;
    }
    this.onSearchShipAndTripByContainerNumber.emit(containerNumber);
  }

  protected get shouldQuebraLoteAppear(): boolean{
    if(!this.shouldBlockPartQuantityEditionByDocumentType){
      return true;
    }
    if(this.isExpDocument){
      return true;
    }
    return false;
  }
}
