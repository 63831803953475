export enum DocumentMaskEnum{
  CPF_MASK = '000.000.000-00',
  CNPJ_MASK = '00.000.000/0000-00',
  NAME_MASK = 'N*',
  DI_MASK = '00/0000000-0',
  DUE_MASK = '00BR0000000000',
  CE_MASK = '000000000000000',
  CHAVE_NOTA_FISCAL_MASK = '00000000000000000000000000000000000000000000'
}

export const DocumentRegex = {
  CPF_REGEX: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
  CNPJ_REGEX: /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/,
  DI_REGEX: /^\d{2}\/\d{7}\-\d{2}$/,
  DUE_REGEX: /^\d{2}BR{1}\d{10}$/
}

export const DocumentCustomPattern = {
  NAME_PATTERN: {
    'N': { pattern: new RegExp('\[a-zA-Z \'\.áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ\-\]') }
  },
  DSIM_MASK_PATTERN: {
    'D': {pattern: new RegExp('\[0-9\/\]')}
  }
};

export const DefaultMaskPattern = {
  DEFAULT_MASK_CHARS: {
    '0': { pattern: new RegExp('\[0-9\]') },
    '9': { pattern: new RegExp('\[0-9\]') },
    'A': { pattern: new RegExp('\[a-zA-Z0-9\]') },
    'S': { pattern: new RegExp('\[a-zA-Z\]') },
    'B': { pattern: new RegExp('\[B\]') },
    'R': { pattern: new RegExp('\[R\]') }
  }
};

export const DocumentSpecialCharactersForMask = {
  DI_SPECIAL_CHARS: ['/', '-'],
  DUE_SPECIAL_CHARS: ['B', 'R'],
}
