<div [auth]="this.visualizacao">

  <div class="mt-5">
    <app-title [title]="title"></app-title>
    <app-relacao-bookings (onSearchBookings)="insertMultipleBookingsFromSearch($event)"></app-relacao-bookings>
    <app-anexos-cabotagem-embarque (onSendBookings)="insertMultipleBookingsIntoBookingsRelation($event)"></app-anexos-cabotagem-embarque>
    <div class="d-flex justify-content-center align-content-center mt-5">
      <div class="solicitation-action-buttons" *ngIf="relacaoBookings">
        <button [auth]="this.incluir" class="action-secondary-button" (click)="openModalForBookingInclusion()">INCLUIR</button>
        <button class="action-secondary-button" (click)="clearData()">LIMPAR DADOS</button>
        <button [auth]="this.salvar" class="action-primary-button" [disabled]="isSelectedBookingRelationDataEmpty || !relacaoBookings.areSelectedBookingsValid()" (click)="saveData()">SALVAR</button>

      </div>
    </div>
</div>

<app-incluir-booking (onSaveBooking)="includeBooking($event)"></app-incluir-booking>
<app-modal-resposta-positiva-negativa
[modalTitle]="'Confirmar'"
[negativeText]="'Cancelar'"
[positiveText]="'Salvar'"
(onChooseNegativeAction)="cancelSavingOfBookingRelation()"
(onChoosePositiveAction)="confirmSavingOfBookingRelation()"
>
<div class="row mb-3">
  <div class="col">
    <p>Foram encontrados registros de Modalidades para os seguintes Booking’s: </p>
    <ul>
      <li *ngFor="let bookingNumber of retrievedBookingNumbersForConfirmation">{{bookingNumber}}</li>
    </ul>
    <p>Deseja efetuar atualização da modalidade e enviar e-mail ao faturamento?</p>
  </div>
</div>
</app-modal-resposta-positiva-negativa>

</div>
