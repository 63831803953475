import { RelacaoConteinerCCTDocumentoTransporte } from '../../../models/documento-transporte/LacreConteinerRecepcaoDocumentoTransporte';
import { DocumentosService } from './../../../services/documentos.service';
import { DocumentCustomPattern } from '../../../shared/utils/constantes-documentos';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Component, Input, OnInit, AfterViewInit, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Campo } from 'src/app/models/Campo';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { TipoVeiculoDocumentoTransporte } from 'src/app/models/TipoVeiculoDocumentoTransporte';
import { ConteinerRecepcaoDocumentoTransporteCCTDTO } from 'src/app/models/RecepcaoDocumentoTransporte/ConteinerRecepcaoDocumentoTransporteDTO';
import { MaskPipe } from 'ngx-mask';
import { LinhaGridRelacaoConteinerCctDocumentoTransporteComponent } from 'src/app/shared/components/documento-transporte/linha-grid-relacao-conteiner-cct-documento-transporte/linha-grid-relacao-conteiner-cct-documento-transporte.component';
@Component({
  selector: 'app-dados-documento-transporte-cct',
  templateUrl: './dados-documento-transporte-cct.component.html',
  styleUrls: ['./dados-documento-transporte-cct.component.scss']
})
export class DadosDocumentoTransporteCctComponent implements OnInit, AfterViewInit {
  public dataRegistroValue?: Date;
  public dataDesembaracoValue?: Date;
  public isCollapsed: boolean = false;
  public TipoVeiculos: TipoVeiculoDocumentoTransporte[] = [];
  public form!: UntypedFormGroup;
  private colorTheme = 'theme-dark-blue';
  public bsConfig?: Partial<BsDatepickerConfig>;
  public namePattern = DocumentCustomPattern.NAME_PATTERN;
  public locale = 'pt-br';
  public lacresCCT!: RelacaoConteinerCCTDocumentoTransporte[];
  public quantidadeReboques!: number;


  @Input() camposformulario!: Campo[];
  @ViewChildren(LinhaGridRelacaoConteinerCctDocumentoTransporteComponent) gridConteineres!: QueryList<LinhaGridRelacaoConteinerCctDocumentoTransporteComponent>;

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private localeService: BsLocaleService,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private documentosService: DocumentosService,
    private maskPipe: MaskPipe
  ) {
  }

  public ngOnInit(): void {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.getVehicleTypes();
    this.validation();
  }

  public ngAfterViewInit(): void {
    if (!this.camposformulario || !this.camposformulario.length) {
      return;
    }
    this.camposformulario.forEach(
      (campo) => {
        if (campo.obrigatorio) {
          this.form.get(campo.nome)?.addValidators(Validators.required);
        }
      }
    );
  }


  private validation(): void {
    this.form = this.fb.group({
      dataEmissao: [''],
      situacao: [''],
      cnpjCpfTransportador: ['', [this.validateCpfOrCnpj.bind(this)]],
      cpfCondutor: ['', [this.validateCpfOrCnpj.bind(this)]],
      localDestinoPrevisto: [''],
      tipoVeiculo: [''],
      placaCavalo: [''],
      placaReboque1: [''],
      placaReboque2: [''],
      placaReboque3: [''],
      dadosVeiculoConferem :[''],
      dadosConteinerConferem :[''],
      dadosEmbalagemConferem :[''],
      dadosUnidadeTransporteConferem :[''],
    });
    this.form.get('tipoVeiculo')?.valueChanges.subscribe(
      (value) => this.changeQuantidadeReboques(value)
    );
    this.lacresCCT = [];
    this.quantidadeReboques = 0;
  }

  private getVehicleTypes(): void {
    this.TipoVeiculos = [];
    this.documentosService.getVehicleTypesForDocumentoEntregaTransporte()
      .subscribe({
        next: (tiposVeiculos) => {
          this.TipoVeiculos = [...tiposVeiculos];
        }
      });
  }

  public validateCpfOrCnpj(control: AbstractControl): { invalidDocument: string } | null {
    let controlValue: string = control?.value;
    if (!controlValue?.length) {
      return null;
    }
    let error: string | null;
    let clearDocument: string = this.verificacaoDocumentosService.eraseMask(controlValue);
    if (clearDocument.length == 11) {
      error = this.verificacaoDocumentosService.calculateValidCPF(clearDocument);
    }
    else if (clearDocument.length == 14) {
      error = this.verificacaoDocumentosService.calculateValidCNPJ(clearDocument);
    }
    else {
      error = 'O nº do CNPJ/CPF informado é inválido. Favor verificar!';
    }
    if (error?.length) {
      return { invalidDocument: error };
    }
    return null;
  }

  public removeSpacesFromExtremities(inputName: string): void {
    this.form.get(inputName)?.setValue((this.form.get(inputName)?.value as string).trim(), { emitEvent: false });
  }

  public checkIfFieldIsRequired(fieldName: string): boolean {
    return !!this.camposformulario?.find(
      campo => campo.nome == fieldName
    )?.obrigatorio;
  }

  public checkIfFieldIsReadonly(fieldName: string): boolean {
    return !!this.camposformulario?.find(
      campo => campo.nome == fieldName
    )?.leitura;
  }

  public clean() {
    this.form.reset();
    this.lacresCCT = [];
  }

  private changeQuantidadeReboques(tipoVeiculo: TipoVeiculoDocumentoTransporte): void {
    if (!tipoVeiculo) {
      return;
    }
    this.quantidadeReboques = tipoVeiculo.quantidadeReboque ?? 0;
    for (let plate: number = 1; plate < 3; plate++) {
      if (plate > this.quantidadeReboques) {
        this.form.get(`placaReboque${plate}`)?.setValue('');
      }
    }
  }

  public setFormValuesFromReceivedConteinerRecepcaoDocumentoTransporteDTO(conteiner: ConteinerRecepcaoDocumentoTransporteCCTDTO): void {
    if (!conteiner) {
      return;
    }
    this.form.get('dataEmissao')?.setValue(new Date(conteiner?.dataEmissao!));
    this.form.get('situacao')?.setValue(conteiner?.situacao);
    this.form.get('cnpjCpfTransportador')?.setValue(this.maskPipe.transform(conteiner?.cnpjCpfTransportador!, '00.000.000/0000-00||000.000.000-00'));
    this.form.get('cpfCondutor')?.setValue(this.maskPipe.transform(conteiner?.cpfCondutor!, '000.000.000-00'));
    this.form.get('localDestinoPrevisto')?.setValue(conteiner?.localDestinoPrevisto);
    this.form.get('tipoVeiculo')?.setValue(
      this.TipoVeiculos.find(
        (tipoVeiculo) => tipoVeiculo.quantidadeReboque == (
          (conteiner.placaReboque1?.length ? 1 : 0) +
          (conteiner.placaReboque2?.length ? 1 : 0) +
          (conteiner.placaReboque3?.length ? 1 : 0)
        )
      )
    );
    this.form.get('placaCavalo')?.setValue(conteiner?.placaCavalo);
    this.form.get('placaReboque1')?.setValue(conteiner?.placaReboque1);
    this.form.get('placaReboque2')?.setValue(conteiner?.placaReboque2);
    this.form.get('placaReboque3')?.setValue(conteiner?.placaReboque3);
    this.form.get('dadosConteinerConferem')?.setValue(conteiner?.dadosConteinerConferem?.toUpperCase() === 'S' ? 'S' : 'N');
    this.form.get('dadosEmbalagemConferem')?.setValue(conteiner?.dadosEmbalagemConferem?.toUpperCase() === 'S' ? 'S' : 'N');
    this.form.get('dadosUnidadeTransporteConferem')?.setValue(conteiner?.dadosUnidadeTransporteConferem?.toUpperCase() === 'S' ? 'S' : 'N');
    this.form.get('dadosVeiculoConferem')?.setValue(conteiner?.dadosVeiculoConferem?.toUpperCase() === 'S' ? 'S' : 'N');



    this.lacresCCT = conteiner.relacaoConteinerLacresCCT ?? [];
    this.form.markAllAsTouched();
    this.changeDetector.detectChanges();
  }

  public getContainers(): RelacaoConteinerCCTDocumentoTransporte[] {
    return this.gridConteineres?.map(
      (container) => container.getContainer()
    ) ?? [];
  }

  public areAllContainersValid(): boolean {
    if (!this.gridConteineres?.length) {
      return true;
    }
    return this.gridConteineres?.map(
      (container) => container.isContainerValid()
    )?.every(line => line);
  }

  public isFormCCTValid(): boolean {
    return !this.form.invalid && this.areAllContainersValid();
  }

  public getDocumentoCCTFromForm(): ConteinerRecepcaoDocumentoTransporteCCTDTO {
    let documentoCCT: ConteinerRecepcaoDocumentoTransporteCCTDTO = this.form.getRawValue();
    documentoCCT.relacaoConteinerLacresCCT = this.getContainers();
    return documentoCCT;
  }

  public getUnidadeTransporte(): string{
    return this.form?.get('tipoVeiculo')?.value?.nome || '';
  }

  public setDivergency(dadosVeiculoConferem: boolean, dadosConteinerConferem: boolean, dadosEmbalagemConferem: boolean, dadosUnidadeTransporteConferem: boolean): void{
    this.form.get('dadosVeiculoConferem')?.setValue(dadosVeiculoConferem ? 'S' : 'N');
    this.form.get('dadosConteinerConferem')?.setValue(dadosConteinerConferem ? 'S' : 'N');
    this.form.get('dadosEmbalagemConferem')?.setValue(dadosEmbalagemConferem ? 'S' : 'N');
    this.form.get('dadosUnidadeTransporteConferem')?.setValue(dadosUnidadeTransporteConferem ? 'S' : 'N');
  }

  // isCPF(): boolean {
  //   var cpf_cnpj = this.form.get('cnpjCpfTransportador')?.value ?? '';
  //   return this.verificacaoDocumentosService.eraseMask(cpf_cnpj)?.length< 12 ? true : false;
  // }

  // getCpfCnpjMask(): string {
  //   return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
  // }
}
