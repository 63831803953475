import { ChangeDetectorRef, Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { RelatorioEntregaCargaNavio } from 'src/app/models/relatorios/RelatorioEntregaCargaNavio/RelatorioEntregaCargaNavio';

@Component({
  selector: 'app-impressao-relatorio-entrega-da-carga-navio',
  templateUrl: './impressao-relatorio-entrega-da-carga-navio.component.html',
  styleUrls: ['./impressao-relatorio-entrega-da-carga-navio.component.scss']
})
export class ImpressaoRelatorioEntregaDaCargaNavioComponent implements OnInit {

  protected dataInicio?: Date;
  protected dataFim?: Date;
  protected nomeRelatorio: string = '';
  protected dados: RelatorioEntregaCargaNavio[] = [];
  protected logo: string = '../../assets/images/logoSeptibaTecon.png';
  protected currentDate: Date = new Date();
  @ViewChildren('tableRelatorio') protected relatorios!: QueryList<TemplateRef<any>>;
  @ViewChild('templateImpressao') private templateImpressao!: TemplateRef<any>;

  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
  }

  public setPrintingData(data: {dataInicio: Date | undefined, dataFim: Date | undefined, nomeRelatorio: string, dados: RelatorioEntregaCargaNavio[]}): void{
    this.dataInicio = data.dataInicio;
    this.dataFim = data.dataFim;
    this.nomeRelatorio = data.nomeRelatorio;
    this.dados = data.dados;
    this.changeDetector.detectChanges();
  }

  public getTemplate(): TemplateRef<any>{
    return this.templateImpressao;
  }

}
