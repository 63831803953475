import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { DadosComplementaresEstimativaCalculoComponent } from 'src/app/components/estimativa-calculo/cadastro-documento-estimativa-calculo/dados-complementares-estimativa-calculo/dados-complementares-estimativa-calculo.component';
import { DocumentoEstimativaCalculoComponent } from 'src/app/components/estimativa-calculo/cadastro-documento-estimativa-calculo/documento-estimativa-calculo/documento-estimativa-calculo.component';
import { ExtrairDadosSiscomexComponent } from 'src/app/components/modais/extrair-dados-siscomex/extrair-dados-siscomex.component';
import { IntegracaoDocumentoParteComponent } from 'src/app/components/modais/integracao-documento-parte/integracao-documento-parte.component';
import { Permissoes } from 'src/app/contants/permissoes';
import { Telas } from 'src/app/contants/telas';
import { Campo } from 'src/app/models/Campo';
import { CadastroDocumentoParteCadastroDocumentoDTO } from 'src/app/models/EstimativaCalculo/CadastroDocumento/CadastroDocumentoParteCadastroDocumentoDTO';
import { ClienteEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/ClienteEstimativaCalculo';
import { ConteinerEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/ConteinerEstimativaCalculo';
import { DocumentoEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/DocumentoEstimativaCalculo';
import { DocumentoParteEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/DocumentoParteEstimativaCalculo';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { IntegracaoSiscomex } from 'src/app/models/IntegracaoSignalR/IntegracaoSiscomex';
import { Mascara } from 'src/app/models/Mascara';
import { DadosNavioVigemDTO } from 'src/app/models/SolicitacaoLiberacaoCabotagem/DadosNavioVigemDTO';
import { AuthRoles } from 'src/app/models/authRoles';
import { ToastService } from 'src/app/services/toast.service';
import { ModalOKComponent } from 'src/app/shared/components/modais/modal-ok/modal-ok.component';
import { documentTypesToBlockPartQuantityCab, documentTypesToBlockPartQuantityExp, documentTypesToBlockPartQuantityImp } from 'src/app/shared/utils/document-types-to-block-part-quantity';
import { TimeConstants } from 'src/app/shared/utils/time-constants';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';
import { CadastroDocumentoEstimativaCalculoService } from '../../../services/EstimativaCalculo/cadastro-documento-estimativa-calculo.service';
import { DocumentosAnexadosComponent } from '../../../shared/components/DocumentosAnexados/documentos-anexados/documentos-anexados.component';
import { ArvorePartesEstimativaCalculoComponent } from './../../../components/estimativa-calculo/cadastro-documento-estimativa-calculo/arvore-partes-estimativa-calculo/arvore-partes-estimativa-calculo.component';
import { ClienteEstimativaCalculoComponent } from './../../../components/estimativa-calculo/cadastro-documento-estimativa-calculo/cliente-estimativa-estimativa-calculo/cliente-estimativa-calculo.component';
import { RelacaoConteinerEstimativaCalculoComponent } from './../../../components/estimativa-calculo/cadastro-documento-estimativa-calculo/relacao-conteiner-estimativa-calculo/relacao-conteiner-estimativa-calculo.component';
import { SelecaoConteineresDivergentesComponent } from './../../../components/estimativa-calculo/cadastro-documento-estimativa-calculo/selecao-conteineres-divergentes/selecao-conteineres-divergentes.component';
import { ModalMensagemComponent } from './../../../components/modais/modal-mensagem/modal-mensagem.component';
import { ArquivoDocumentoAnexado } from './../../../models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { CadastroDocumentoEstimativaCalculoDTO } from './../../../models/EstimativaCalculo/CadastroDocumento/CadastroDocumentoEstimativaCalculoDTO';
import { AuthenticationService } from './../../../services/authentication/authentication.service';

@Component({
  selector: 'app-cadastro-documento-estimativa-calculo',
  templateUrl: './cadastro-documento-estimativa-calculo.component.html',
  styleUrls: ['./cadastro-documento-estimativa-calculo.component.scss']
})
export class CadastroDocumentoEstimativaCalculoComponent implements OnInit {

  public title: string = 'Cadastro de Documento';
  public subtitle: string = 'Adicione abaixo os dados necessários para o documento de solicitação.';
  public form!: UntypedFormGroup;
  public fieldSettings!: Campo[];
  public isSearchDoneOrXMLUploaded: boolean = false;
  public doesSolicitationHaveIntegration: boolean = true;
  public usuarioInterno!: boolean;
  public visualizar?: boolean = false;
  public selectedDocumentType?: string;
  public maskForArvorePartes!: Mascara;


  private readonly documentTypesForContainerDivergency: string[] = ['DUE', 'DAT'];
  public integrationErrors: string[] = [];
  public blockSending: boolean = false;

  public textoConfirmarCriarSolicitacaoExistente!: string;

  @ViewChild(DocumentoEstimativaCalculoComponent) private documento!: DocumentoEstimativaCalculoComponent;
  @ViewChild(ClienteEstimativaCalculoComponent) private cliente!: ClienteEstimativaCalculoComponent;
  @ViewChild(DadosComplementaresEstimativaCalculoComponent) private dadosComplementares!: DadosComplementaresEstimativaCalculoComponent;
  @ViewChild(RelacaoConteinerEstimativaCalculoComponent) private relacaoConteineres!: RelacaoConteinerEstimativaCalculoComponent;
  @ViewChild(ArvorePartesEstimativaCalculoComponent) private arvorePartes!: ArvorePartesEstimativaCalculoComponent;
  @ViewChild(DocumentosAnexadosComponent) private documentosAnexados!: DocumentosAnexadosComponent;
  @ViewChild(ExtrairDadosSiscomexComponent) private modalExtrairDadosSiscomex!: ExtrairDadosSiscomexComponent;
  @ViewChild(SelecaoConteineresDivergentesComponent) private modalDivergenciaConteineres!: SelecaoConteineresDivergentesComponent;
  @ViewChild(ModalMensagemComponent) private modalMensagemErrosIntegracao!: ModalMensagemComponent;
  @ViewChild('modalAlertaErrosIntegracao') private modalAlertaErrosIntegracao!: TemplateRef<any>;
  @ViewChild('modalOkPesquisa') public modalOkPesquisa!: ModalOKComponent;
  @ViewChild('modalOkXml') public modalOkXml!: ModalOKComponent;
  @ViewChild(IntegracaoDocumentoParteComponent) modalIntegracaoDocumentoParte!: IntegracaoDocumentoParteComponent;


    private signalRConnection: HubConnection = new signalR.HubConnectionBuilder()
  .withUrl(`${environment.apiUrlForCadastroDocumentoHub}/cadastroDocumentoHub`)
  .withAutomaticReconnect()
  .configureLogging(signalR.LogLevel.Debug)
  .build();

  private timeoutForSignalRConnection!: any;
  public isPartDocumentSaved: boolean = false;
  public modalRef!: BsModalRef;

  private readonly documentTypesToBlockPartQuantityEdition: string[] = [...documentTypesToBlockPartQuantityImp, ...documentTypesToBlockPartQuantityExp, ...documentTypesToBlockPartQuantityCab];
  protected documentsForImp: string[] = [...documentTypesToBlockPartQuantityImp];
  protected documentsForExp: string[] = [...documentTypesToBlockPartQuantityExp];

  public readonly visualizacao = new AuthRoles(Telas.cadastroDocumentoEstimativaCalculo, Permissoes.visualizacao)
  public readonly salvarRascunho = new AuthRoles(Telas.cadastroDocumentoEstimativaCalculo, Permissoes.salvarRascunho)
  public readonly liberacaoFinanceira = new AuthRoles(Telas.cadastroDocumentoEstimativaCalculo, Permissoes.liberar)
  public readonly memoriaCalculo = new AuthRoles(Telas.cadastroDocumentoEstimativaCalculo, Permissoes.memoriaCalculo)
  public readonly cancelar = new AuthRoles(Telas.cadastroDocumentoEstimativaCalculo, Permissoes.cancelar)
  public readonly salvarDocParte = new AuthRoles(Telas.cadastroDocumentoEstimativaCalculo, Permissoes.salvarDocParte)

  constructor(
    private authService: AuthenticationService,

    private cadastroDocumentoEstimativaCalculoService: CadastroDocumentoEstimativaCalculoService,
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private toastService:ToastService,
    private changeDetector: ChangeDetectorRef,
    private modalService: BsModalService,
    private authenticationService: AuthenticationService
  ) { }

  public get isAnyFileAttached(): boolean{
    if(this?.documento?.getDocumentFromFormFields()?.tipoDocumento == 'CE' && !this.usuarioInterno){
      return true;
    }
    if(this.documentosAnexados?.getAllDocuments()?.length === 0){
      return true;
    }
    return this.documentosAnexados?.isAnyFileUploaded() ?? false;
  }

  public get isClienteFaturarAPrazo(): boolean{
    return !!this.cliente?.getClientFromFormFields().clienteFaturarAPrazo;
  }

  public ngOnInit() {
    this.usuarioInterno = this.authenticationService.ehUsuarioInterno;
    this.fieldSettings = [];
    this.validation();
    this.changeDetector.detectChanges();
  }

  private validation(): void{
    this.form = this.fb.group({
      id: [""],
      numeroSolicitacao: [''],
      documento: [{}, [Validators.required]],
      cliente: [{}],
      dadosComplementares: [{}],
      relacaoConteineres: [[]],
      arvorePartes: [[]],
      documentosAnexados: [[]]
    });
  }

  // public updateDocumentRegister(event: {}): void{
  //   if(!event){
  //     return;
  //   }
  //   this.form.patchValue(event);
  //   this.cadastroDocumento = {
  //     id: this.form.get('id')?.value,
  //     documento: this.form.get('documento')?.value,
  //     cliente: this.form.get('cliente')?.value,
  //     dadosComplementares: this.form.get('dadosComplementares')?.value,
  //     relacaoConteineres: this.form.get('relacaoConteineres')?.value,
  //     documentosAnexados: this.form.get('documentosAnexados')?.value
  //   };
  // }

  public searchDocumentRegisterByButton(documentRegister: DocumentoEstimativaCalculo): void{
    this.clearFormFieldsButDocument();
    this.searchDocumentRegister(documentRegister);
  }

  public searchDocumentRegister(documentRegister: DocumentoEstimativaCalculo, visualizar?: boolean): void{
    if(!documentRegister){
      return;
    }
    this.spinner.show();
    this.closeSignalRConnection();
    this.isSearchDoneOrXMLUploaded = true;
    this.visualizar = !!visualizar;
    let correlationId: string = uuid.v4();
    this.cadastroDocumentoEstimativaCalculoService.searchDocumentRegister(documentRegister, correlationId)
      .subscribe(
        {
          next: (result) => {
            let receivedDocumentRegister: CadastroDocumentoEstimativaCalculoDTO = result.data as CadastroDocumentoEstimativaCalculoDTO;
            if(receivedDocumentRegister){
              this.doesSolicitationHaveIntegration = !!receivedDocumentRegister.statusIntegracao?.length;
              this.changeDetector.detectChanges();
              this.setFormValuesFromReceivedDocumentRegister(receivedDocumentRegister);
            }
            else{
              this.updateValidationOnComponents();
              this.documentosAnexados.setAttachedDocumentTypes(documentRegister?.id!, [], this.cadastroDocumentoEstimativaCalculoService.getFilesToAttach());
            }
            this.spinner.hide();
            this.updateValidationOnComponents();
          },
          error: (err: HttpErrorResponse) => {
            this.updateValidationOnComponents();
            console.log(err);
            this.spinner.hide();
            let errorMessage: ErrorResponse = (err.error as ErrorResponse);
            if(err.status === 404){
              if(typeof(errorMessage.data) === 'string'){
                this.textoConfirmarCriarSolicitacaoExistente = errorMessage.data;
                this.modalOkPesquisa.openModalForOk();
              }
              else{
                let integrations: IntegracaoSiscomex[] = errorMessage?.data ?? [];
                if(integrations?.length){
                  this.doesSolicitationHaveIntegration = true;
                  this.changeDetector.detectChanges();
                  if(integrations[0].integracaoPucomex){
                    if(integrations[0].cadastroDocumentoDTO){
                      this.setFormValuesFromReceivedDocumentRegister(integrations[0].cadastroDocumentoDTO);
                    }
                    else{
                      if(errorMessage?.errors?.length){
                        errorMessage?.errors?.forEach(
                          (error) => {
                            if(error?.length){
                              this.toastService.error(error);
                            }
                          }
                        );
                      }
                      this.isSearchDoneOrXMLUploaded = false;
                      this.clearFormFields();
                    }
                  }
                  else{
                    this.openIntegrationDataModal(integrations);
                  }
                }
                else{
                  this.doesSolicitationHaveIntegration = false;
                  this.changeDetector.detectChanges();
                }
                this.documentosAnexados.setAttachedDocumentTypes(documentRegister?.id!, [], this.cadastroDocumentoEstimativaCalculoService.getFilesToAttach());
              }
            }
            else{
              if(errorMessage?.errors?.length){
                errorMessage?.errors?.forEach(
                  (error) => {
                    if(error?.length){
                      this.toastService.error(error);
                    }
                  }
                );
              }
            }
            this.updateValidationOnComponents();
          }
        }
      );
    this.getFieldSettingsByDocumentType(documentRegister.tipoDocumento);
  }

  public confirmCreateDocumentRegister(documento?: DocumentoEstimativaCalculo): void{
    this.visualizar = true;
    this.changeDetector.detectChanges();
    let documentRegister: DocumentoEstimativaCalculo = documento ? documento : this.documento?.getDocumentFromFormFields();
    if(!documentRegister){
      return;
    }
    this.spinner.show();
    this.closeSignalRConnection();
    this.isSearchDoneOrXMLUploaded = true;
    let correlationId: string = uuid.v4();
    this.cadastroDocumentoEstimativaCalculoService.confirmCreateDocumentRegister(documentRegister, correlationId)
      .subscribe(
        {
          next: (result) => {
            let receivedDocumentRegister: CadastroDocumentoEstimativaCalculoDTO = result.data as CadastroDocumentoEstimativaCalculoDTO;

            if(receivedDocumentRegister){
              this.doesSolicitationHaveIntegration = !!receivedDocumentRegister.statusIntegracao?.length;
              this.changeDetector.detectChanges();
              this.setFormValuesFromReceivedDocumentRegister(receivedDocumentRegister);
            }
            else{
              this.updateValidationOnComponents();
              this.documentosAnexados.setAttachedDocumentTypes(documentRegister?.id!, [], this.cadastroDocumentoEstimativaCalculoService.getFilesToAttach());
            }
            this.spinner.hide();
            this.updateValidationOnComponents();
          },
          error: (err: HttpErrorResponse) => {
            this.updateValidationOnComponents();
            console.log(err);
            this.spinner.hide();
            let errorMessage: ErrorResponse = (err.error as ErrorResponse);
            if(err.status === 404){
              let integrations: IntegracaoSiscomex[] = (err.error as ErrorResponse)?.data ?? [];
              if(integrations?.length && typeof(integrations) !== 'string'){
                this.doesSolicitationHaveIntegration = true;
                this.changeDetector.detectChanges();
                if(integrations[0].integracaoPucomex){
                  if(integrations[0].integracaoPucomex){
                    if(integrations[0].cadastroDocumentoDTO){
                      this.setFormValuesFromReceivedDocumentRegister(integrations[0].cadastroDocumentoDTO);
                    }
                    else{
                      if(errorMessage?.errors?.length){
                        errorMessage?.errors?.forEach(
                          (error) => {
                            if(error?.length){
                              this.toastService.error(error);
                            }
                          }
                        );
                      }
                      this.isSearchDoneOrXMLUploaded = false;
                      this.clearFormFields();
                    }
                  }
                }
                else{
                  this.openIntegrationDataModal(integrations);
                }
              }
              else{
                this.doesSolicitationHaveIntegration = false;
                this.changeDetector.detectChanges();
              }
              this.documentosAnexados.setAttachedDocumentTypes(documentRegister?.id!, [], this.cadastroDocumentoEstimativaCalculoService.getFilesToAttach());
            }
            else{
              if(errorMessage?.errors?.length){
                errorMessage?.errors?.forEach(
                  (error) => {
                    if(error?.length){
                      this.toastService.error(error);
                    }
                  }
                );
              }
            }
            this.updateValidationOnComponents();
          }
        }
      );
    this.getFieldSettingsByDocumentType(documentRegister.tipoDocumento);
  }

  private setFormValuesFromReceivedDocumentRegister(documentRegister: CadastroDocumentoEstimativaCalculoDTO): void{
    this.changeDetector.detectChanges();
    if(documentRegister.dadosComplementares && documentRegister.documento && documentRegister.dadosComplementares?.chaveNotaFiscal && !documentRegister.documento?.chaveNotaFiscal){
      documentRegister.documento.chaveNotaFiscal = documentRegister.dadosComplementares.chaveNotaFiscal;
    }
    this.documentosAnexados.setAttachedDocumentTypes(documentRegister.documento?.id!, documentRegister.documentosAnexados!, this.cadastroDocumentoEstimativaCalculoService.getFilesToAttach());
    this.form.get('id')?.setValue(documentRegister.id);
    this.form.get('numeroSolicitacao')?.setValue(documentRegister.numeroSolicitacao);
    this.documento?.setFormValuesFromReceivedDocument(documentRegister.documento);
    this.cliente?.CleanClients();
    this.cliente?.SearchClientesByUser(true);
    this.cliente?.SearchClientesFaturarByUser(true);
    this.cliente?.setFormValuesFromReceivedClient(documentRegister.cliente);
    this.dadosComplementares?.setFormFieldsFromReceivedComplementaryData(documentRegister.dadosComplementares);
    this.relacaoConteineres?.setFormValuesFromReceivedContainersRelationList(documentRegister.relacaoConteineres);
    this.arvorePartes?.setArvorePartesValuesFromContainers(documentRegister.relacaoConteineres);
    this.updateValidationOnComponents();
    this.changeDetector.detectChanges();
  }

  private updateValidationOnComponents(): void{
    this.documento?.updateValidators();
    this.cliente?.updateValidators();
    this.dadosComplementares?.updateValidators();
    this.relacaoConteineres?.updateValidators();
    this.arvorePartes?.setSettingsfromFields();
    this.setAttachedDocumentAsRequiredOrNotByClientDivergencyAndUserType();
  }

  private getFieldSettingsByDocumentType(documentType: string): void{
    //this.isSearchDoneOrXMLUploaded = false;
    this.fieldSettings = [];
    this.cadastroDocumentoEstimativaCalculoService.getFieldSettingForDocumentRegisterByDocumentType(documentType)
      .subscribe({
        next: (result) => {
          this.fieldSettings = result.data as Campo[];
          this.changeDetector.detectChanges();
          this.updateValidationOnComponents();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = err?.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                this.toastService.error(error);
              }
            );
          }
          this.changeDetector.detectChanges();
        },
        complete: () => {
          this.updateValidationOnComponents();
        }
      });
  }

  private openIntegrationDataModal(integracoes: IntegracaoSiscomex[]): void{
    clearTimeout(this.timeoutForSignalRConnection);
    console.log('CorrelationId das integração:')
    console.log(integracoes[0].correlationId)
    this.setSignalRConnectionForSiscomexIntegration(integracoes);
  }

  private setSignalRConnectionForSiscomexIntegration(integracoes: IntegracaoSiscomex[]): void{
    this.signalRConnection.on('IntegracaoDTO', (integracao: IntegracaoSiscomex) => {
      this.modalExtrairDadosSiscomex.concludeIntegration(integracao);
    });
    this.signalRConnection.on('SolicitacaoPreenchida', (cadastroDocumento: CadastroDocumentoEstimativaCalculoDTO) => {
      this.setFormValuesFromReceivedDocumentRegister(cadastroDocumento);
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
    });
    this.signalRConnection.on('DivergenciaConteineresDUEDAT', (conteineres: string[]) => {
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      this.modalDivergenciaConteineres.openModalForContainerDivergency(conteineres);
    });
    this.signalRConnection.on('DivergenciaConteineres', (mensagem: string) => {
      this.modalExtrairDadosSiscomex.closeModal();
      this.closeSignalRConnection();
      this.modalMensagemErrosIntegracao.displaySingleMessage(mensagem);
    });
    this.signalRConnection.on('MensagemEventoErro', (mensagem: string) => {
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      this.modalMensagemErrosIntegracao.displayMessage(mensagem);
    });


    this.signalRConnection.start()
    .then(
      () => {
        this.signalRConnection.invoke('CriarConexaoCadastroDocumento', integracoes[0].correlationId)
          .then(() => {
            this.modalExtrairDadosSiscomex.openModal(integracoes);
          })
          .catch((err) => {
            this.closeSignalRConnection();
            console.log(err)
          });
      }
    )
    .catch(
      (err) => {
        this.closeSignalRConnection();
        console.log(err);
      }
    );
  this.timeoutForSignalRConnection = setTimeout(() => {
    this.closeSignalRConnection();
    if(this.modalExtrairDadosSiscomex.areAllIntegrationsDone){
      this.modalExtrairDadosSiscomex.closeModal();
      return;
    }
    this.modalExtrairDadosSiscomex.clearIntegrationDisplay();
  }, TimeConstants.TIMEOUT_FOR_SISCOMEX_INTEGRATION);
}



public closeSignalRConnection(): void{
  this.signalRConnection.stop();
  clearTimeout(this.timeoutForSignalRConnection);
}

  public clearFormFields(): void{
    this.documento?.clearFields();
    this.cliente?.clearFields();
    this.dadosComplementares?.clearFields();
    this.relacaoConteineres?.clearFields();
    this.arvorePartes?.clean();
    this.documentosAnexados?.clearFields();
    this.form.reset();
    this.isPartDocumentSaved = false;
  }

  public clearFormFieldsButDocument(): void{
    this.cliente?.clearFields();
    this.dadosComplementares?.clearFields();
    this.relacaoConteineres?.clearFields();
    this.arvorePartes?.clean();
    this.documentosAnexados?.clearFields();
  }

  public cancelDocumentRegister(): void{
    this.spinner.show();
    let solicitationId: number = this.form.value.id;
    if(!solicitationId){
      setTimeout(() => {
        this.clearFormFields();
        this.spinner.hide();
      }, 1500);
      return;
    }
    this.cadastroDocumentoEstimativaCalculoService.cancelDocumentRegister(solicitationId)
      .subscribe({
        next: (result) => {
          this.clearFormFields();
          result.message?.forEach(
            (message) => {
              if(message && message.length){
                this.toastService.success(message);
              }
            }
          );
          this.spinner.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.clearFormFields();
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public saveDraft(): void{
    this.spinner.show();
    if(!this.isSaveDraftButtonEnabled){
      return;
    }
    this.cadastroDocumentoEstimativaCalculoService.saveDocumentRegisterDraft(this.getSolicitationFromForms())
      .subscribe({
        next: (result) => {
          result.message?.forEach(
            (message) => {
              if(message && message.length){
                this.toastService.success(message);
              }
            }
          );
          let solicitation: CadastroDocumentoEstimativaCalculoDTO = result.data as CadastroDocumentoEstimativaCalculoDTO;
          this.form.get('id')?.setValue(solicitation.id);
          this.form.get('numeroSolicitacao')?.setValue(solicitation.numeroSolicitacao);
          this.saveDraftFiles();
          this.spinner.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          (err?.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              if(error && error.length){
                this.toastService.error(error);
              }
            }
          );
        }
      });
  }

  public get isSaveDraftButtonEnabled(): boolean{
    if(this.arvorePartes?.storedPartDocuments){
      if(this.arvorePartes?.arePartDocumentsSameAsStored){
        return true && this.relacaoConteineres?.areAllContainersValid();
      }
      else{
        return this.isPartDocumentAbsentOrSaved && this.relacaoConteineres?.areAllContainersValid();
      }
    }
    else{
      return this.isPartDocumentAbsentOrSaved && this.relacaoConteineres?.areAllContainersValid();
    }
  }

  public saveDraftFiles(): void{
    if(!this.isAnyFileAttached){
      return;
    }
    this.cadastroDocumentoEstimativaCalculoService.uploadFilesForDocumentRegister(this.getSolicitationFromForms())
    .subscribe({
      next: (result) => {
        result.message?.forEach(
          (message) => {
            if(message && message.length){
              this.toastService.success(message);
            }
          }
        );
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      }
    });
  }

  public sendMemoriaCalculo(): void{
    if(!this.documentosAnexados?.areAllFilesValid){
      this.toastService.error("Favor anexar o documento para cálculo!");
      return;
    }
    this.spinner.show();
    this.cadastroDocumentoEstimativaCalculoService.sendMemoriaCalculo(this.getSolicitationFromForms())
      .subscribe({
        next: (result) => {
          result.message?.forEach(
            (message) => {
              if(message && message.length){
                this.toastService.success(message);
              }
            },
          );
          let solicitation: CadastroDocumentoEstimativaCalculoDTO = result.data as CadastroDocumentoEstimativaCalculoDTO;
          this.form?.get('id')?.setValue(solicitation.id);
          this.form?.get('numeroSolicitacao')?.setValue(solicitation.numeroSolicitacao);
          this.sendMemoriaCalculoAttachedFiles();
          this.spinner.hide();
          this.clearFormFields();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  private sendMemoriaCalculoAttachedFiles(): void{
    this.cadastroDocumentoEstimativaCalculoService.uploadFilesForDocumentRegister(this.getSolicitationFromForms())
      .subscribe({
        next: (result) => {
          result.message?.forEach(
            (message) => {
              if(message && message.length){
                this.toastService.success(message);
              }
            }
          );
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public sendFinancialLiberation(): void{
    let clienteDocumento = this.cliente?.getClientFromFormFields();
    if(!clienteDocumento){
      return;
    }
    if(!clienteDocumento.clienteFaturarAPrazo){
      return;
    }
    if(!!clienteDocumento.temRestricao){
      this.toastService.error("Entrar em contato com o SAC TECON referente à pendência financeira!");
      return;
    }
    this.sendLiberation();
  }

  private sendLiberation(): void{
    this.spinner.show();
    this.cadastroDocumentoEstimativaCalculoService.sendFinancialLiberation(this.getSolicitationFromForms())
      .subscribe({
        next: (result) => {
          result.message?.forEach(
            (message) => {
              if(message && message.length){
                this.toastService.success(message);
              }
            }
          );
          let solicitation: CadastroDocumentoEstimativaCalculoDTO = result.data as CadastroDocumentoEstimativaCalculoDTO;
          this.form?.get('id')?.setValue(solicitation.id);
          this.form?.get('numeroSolicitacao')?.setValue(solicitation.numeroSolicitacao);
          this.sendLiberationFiles();
          this.clearFormFields();
          this.spinner.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  private sendLiberationFiles(): void{
    if(!this.isAnyFileAttached){
      return;
    }
    this.cadastroDocumentoEstimativaCalculoService.uploadFilesForDocumentRegister(this.getSolicitationFromForms())
      .subscribe({
        next: (result) => {
          result.message?.forEach(
            (message) => {
              if(message && message.length){
                this.toastService.success(message);
              }
            }
          );
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public confirmContainerPreviousShipping(): void{
    let conteineresComEmbarquePrevio: string [] = this.modalDivergenciaConteineres.getSelectedContainers() ?? [];
    let conteineresSemEmbarquePrevio: string [] = this.modalDivergenciaConteineres.getUnselectedContainers() ?? [];
    this.modalDivergenciaConteineres.closeModal();
    if(conteineresSemEmbarquePrevio && conteineresSemEmbarquePrevio.length){
      this.toastService.error("Favor entrar em contato com o terminal!");
    }
    conteineresComEmbarquePrevio?.forEach(
      (container) => this.relacaoConteineres?.setContainerAsIgnorable(container)
    );
  }

  public getValuesFromXML(xml: any): void{
    this.isSearchDoneOrXMLUploaded = true;
    this.changeDetector.detectChanges();
    let numeroCE: string = this.documento?.getDocumentFromFormFields()?.numeroCE ?? '';
    let chaveNotaFiscal: string = this.documento?.getDocumentFromFormFields()?.chaveNotaFiscal ?? '';
    this.clearFormFields();
    let numeroConteiner = '';
    if(xml.ListaDeclaracoes){
      this.getValuesFromDIXML(xml);
    }
    else if(xml.cteProc){
      this.getValuesFromCTEXML(xml, numeroCE);
      numeroConteiner = this.extractContainerNumberFromThreeDotZeroCTEXml(xml);
    }
    else if(xml.nfeProc){
      this.getValuesFromNFXML(xml);
    }
    else{
      return;
    }
    this.changeDetector.detectChanges();
    this.isSearchDoneOrXMLUploaded = true;
    this.changeDetector.detectChanges();
    let documentoPreenchido = this.documento?.getDocumentFromFormFields();
    if(documentoPreenchido?.tipoDocumento === 'CTE'){
      if(numeroConteiner?.length){
        this.searchNumeroCEByContainer(numeroConteiner);
      }
      else{
        this.toastService.error("Número de contêiner não encontrado no XML!");
        this.clearFormFields();
        this.hideForms();
      }
    }
    else{
      this.getIntegrationsFromXML();
    }
  }

  private getValuesFromCTEXML(xml: any, numeroCE: string): void{
    let xmlValue = this.extractValuesFromCTEXml(xml);
    xmlValue.numeroCE = numeroCE ?? '';
    this.documento?.setValuesFromCTEXML(xmlValue);
    this.cliente?.setValuesFromCTEXML(xmlValue);
    this.dadosComplementares?.setValuesFromCTEXML(xmlValue);
  }

  private getValuesFromDIXML(xml: any): void{
    this.documento?.setValuesFromDIXML(xml);
    this.cliente?.setValuesFromDIXML(xml);
    this.dadosComplementares?.setValuesFromDIXML(xml);
  }

  private getValuesFromNFXML(xml: any): void{
    this.documento?.setValuesFromNFXML(xml);
    this.cliente?.setValuesFromNFXML(xml);
    this.dadosComplementares?.setValuesFromNFXML(xml);
  }

  public downloadFileToDisplay(arquivo: {arquivoDocumentoAnexado: ArquivoDocumentoAnexado, index: number}): void{
    this.documentosAnexados?.downloadFileToDisplay(this.cadastroDocumentoEstimativaCalculoService.downloadFileForDocumentRegister(arquivo.arquivoDocumentoAnexado), arquivo.index);
  }

  public downloadFileToSave(arquivo: {arquivoDocumentoAnexado: ArquivoDocumentoAnexado, index: number}): void{
    this.documentosAnexados?.downloadFileToSave(this.cadastroDocumentoEstimativaCalculoService.downloadFileForDocumentRegister(arquivo.arquivoDocumentoAnexado), arquivo.index);
  }

  public getSolicitationFromForms(): CadastroDocumentoEstimativaCalculoDTO{
    let solicitation: CadastroDocumentoEstimativaCalculoDTO = {};
    solicitation.id = this.form.get('id')?.value;
    solicitation.numeroSolicitacao = this.form.get('numeroSolicitacao')?.value;
    solicitation.documento = this.documento?.getDocumentFromFormFields();
    solicitation.cliente = this.cliente?.getClientFromFormFields();
    solicitation.dadosComplementares = this.dadosComplementares?.getComplementaryDataFromFormFields();
    solicitation.relacaoConteineres = this.relacaoConteineres?.getContainers();
    solicitation.arvorePartes = this.arvorePartes?.getArvorePartesFromForm();
    solicitation.documentosAnexados = this.documentosAnexados?.getAllDocumentsWithAttachedFiles();
    return solicitation;
  }

  private getIntegrationsFromXML(): void{
    let correlationId: string = uuid.v4();
    this.spinner.show();
    this.documentosAnexados?.setAttachedDocumentTypes(this.documento?.getDocumentFromFormFields()?.id!, [], this.cadastroDocumentoEstimativaCalculoService.getFilesToAttach());
    this.cadastroDocumentoEstimativaCalculoService.getIntegrationsFromXML(this.getSolicitationFromForms(), correlationId)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          let solicitation: CadastroDocumentoEstimativaCalculoDTO = result.data as CadastroDocumentoEstimativaCalculoDTO;
          if(solicitation){
            this.doesSolicitationHaveIntegration = !!solicitation.statusIntegracao?.length;
            this.changeDetector.detectChanges();
            this.setFormValuesFromReceivedDocumentRegister(solicitation);
          }
          this.updateValidationOnComponents();
        },
        error: (err: HttpErrorResponse) => {
          this.updateValidationOnComponents();
          this.spinner.hide();
          let errorMessage: ErrorResponse = (err?.error as ErrorResponse);
          console.log(err);
          if(err.status == 404){
            if(typeof(errorMessage?.data) === 'string'){
              this.textoConfirmarCriarSolicitacaoExistente = errorMessage.data;
              this.modalOkXml.openModalForOk();
            }
            else{
              let integrations: IntegracaoSiscomex[] = (err?.error as ErrorResponse)?.data as IntegracaoSiscomex[];
              if(integrations?.length){
                this.doesSolicitationHaveIntegration = true;
                this.changeDetector.detectChanges();
                this.openIntegrationDataModal(integrations);
              }
              else{
                this.doesSolicitationHaveIntegration = false;
                this.changeDetector.detectChanges();
              }
            }
          }
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
    this.getFieldSettingsByDocumentType(this.documento?.getDocumentFromFormFields()?.tipoDocumento);
  }

  public confirmCreateIntegrationsFromXML(): void{
    let correlationId: string = uuid.v4();
    this.spinner.show();
    this.documentosAnexados?.setAttachedDocumentTypes(this.documento?.getDocumentFromFormFields()?.id!, [], this.cadastroDocumentoEstimativaCalculoService.getFilesToAttach());
    this.cadastroDocumentoEstimativaCalculoService.confirmCreateIntegrationsFromXML(this.getSolicitationFromForms(), correlationId)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          let solicitation: CadastroDocumentoEstimativaCalculoDTO = result.data as CadastroDocumentoEstimativaCalculoDTO;
          if(solicitation){
            this.doesSolicitationHaveIntegration = !!solicitation.statusIntegracao?.length;
            this.changeDetector.detectChanges();
            this.setFormValuesFromReceivedDocumentRegister(solicitation);
          }
          this.updateValidationOnComponents();
        },
        error: (err: HttpErrorResponse) => {
          this.updateValidationOnComponents();
          this.spinner.hide();
          console.log(err);
          let errorMessage: ErrorResponse = (err?.error as ErrorResponse);
          if(err.status == 404){
            let integrations: IntegracaoSiscomex[] = errorMessage?.data as IntegracaoSiscomex[];
            if(integrations && integrations.length){
              this.doesSolicitationHaveIntegration = true;
              this.changeDetector.detectChanges();
              this.openIntegrationDataModal(integrations);
            }
            else{
              this.doesSolicitationHaveIntegration = false;
              this.changeDetector.detectChanges();
            }
          }
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
    this.getFieldSettingsByDocumentType(this.documento?.getDocumentFromFormFields()?.tipoDocumento);
  }

  public areAllFormsValid(): boolean{
    return this.documento?.isDocumentoValid() &&
      this.cliente?.isClientValid() &&
      this.dadosComplementares?.isComplementaryDataValid() &&
      this.relacaoConteineres?.isContainersRelationValid() &&
      (this.arvorePartes ? this.arvorePartes.areAllDocumentoParteLinesValid : true) &&
      this.documentosAnexados?.areAllFilesValid;
  }

  public setAttachedDocumentAsRequiredOrNotByClientDivergencyAndUserType(): void{
    let cliente: ClienteEstimativaCalculo = this.cliente?.getClientFromFormFields();
    this.documentosAnexados?.setValidatorForTermoAndCEAttachmentsInCadastroDocumento(
      (cliente.cpfCnpjCliente?.trim() != cliente.cpfCnpjClienteFaturar?.trim()),
      this.usuarioInterno
    );
  }

  public eraseConteiner (conteiner: ConteinerEstimativaCalculo): void{
    const {id} = this.getSolicitationFromForms()
    if (!id || !conteiner.conteiner) return this.relacaoConteineres.eraseConteinerFromGrid(conteiner);
    this.cadastroDocumentoEstimativaCalculoService.postCancelarSolicitacao(this.getSolicitationFromForms(), conteiner)
      .subscribe({
        next: (result) => {
          this.relacaoConteineres.eraseConteinerFromGrid(conteiner);
          result?.message?.forEach(
            (message) => {
              this.toastService.success(message);
            }
          );
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public changeDocumentType(documentType: string): void{
    this.clearFormFieldsButDocument();
    this.isSearchDoneOrXMLUploaded = false;
    this.selectedDocumentType = documentType;
    this.maskForArvorePartes = this.documento?.getMaskFromSelectedDocumentType(documentType);
    this.changeDetector.detectChanges();
  }

  public hideForms(): void{
    this.isSearchDoneOrXMLUploaded = false;
  }

  private extractValuesFromCTEXml(xml: any): any{
    let versaoModal: string = xml.cteProc.CTe.infCte.infCTeNorm.infModal._attributes.versaoModal;
    let values: any = {};
    if(parseInt(versaoModal) === 3.00){
      values = this.extractValuesFromThreeDotZeroCTEXml(xml);
    }
    // console.log(values)
    return values;
  }

  private extractValuesFromThreeDotZeroCTEXml(xml: any): any{
    let values: any = {};
    let modal = xml?.cteProc?.CTe?.infCte?.infCTeNorm?.infModal;
    let cnpj: string = '';
    if(xml?.cteProc?.CTe?.infCte?.ide?.toma4?.toma?._text == '4'){
      cnpj = xml?.cteProc.CTe?.infCte?.ide?.toma4?.CNPJ?._text;
    }
    else if(xml?.cteProc?.CTe?.infCte?.ide?.toma3){
      switch(xml?.cteProc?.CTe?.infCte?.ide?.toma3?.toma?._text){
        case '0':
          cnpj = xml?.cteProc?.CTe?.infCte?.rem?.CNPJ?._text;
          break;
        case '1':
          cnpj = xml?.cteProc?.CTe?.infCte?.emit?.CNPJ?._text;
          break;
        case '2':
          cnpj = xml?.cteProc?.CTe?.infCte?.receb?.CNPJ?._text;
          break;
        case '3':
          cnpj = xml?.cteProc?.CTe?.infCte?.dest?.CNPJ?._text;
          break;
      }
    }
    values = Object.assign(values, {
      numeroDocumento: xml?.cteProc.CTe.infCte.ide.nCT._text,
      dataRegistro: new Date(xml.cteProc.CTe.infCte.ide.dhEmi._text),
      valorMercadoria: xml?.cteProc?.CTe.infCte.infCTeNorm.infCarga.vCarga._text,
      cpfCnpj: cnpj
    });
    if(modal.aquav){
      //
    }
    else if(modal.rodo){
      //
    }
    else if(modal.ferrov){ // ???
      //
    }
    else if(modal.multimodal){
      //
    }
    return values;
  }

  private searchNumeroCEByContainer(numeroConteiner: string): void{
    this.cadastroDocumentoEstimativaCalculoService?.getNumeroCEByContainerNumber(numeroConteiner)
      .subscribe({
        next: (result) => {
          let numeroCE: string = result.data;
          if(numeroCE?.length){
            this.documento?.setValuesFromCTEXML({numeroCE});
            this.changeDetector?.detectChanges();
            this.getIntegrationsFromXML();
          }
          else{
            this.toastService.error("Número de contêiner não encontrado no N4!");
            this.clearFormFields();
            this.hideForms();
          }
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
          this.clearFormFields();
          this.hideForms();
        }
      })
  }

  private extractContainerNumberFromThreeDotZeroCTEXml(xml: any): string{
    let containerNumber: string = '';
    if(xml?.cteProc?.CTe?.infCte?.ide?.toma4?.toma?._text == '4'){
      let detCont = xml?.cteProc.CTe?.infCte?.infCTeNorm?.infModal?.aquav?.detCont;
      if(detCont?.length){
        containerNumber = detCont?.[0]?.nCont?._text;
      }
      else{
        containerNumber = detCont?.nCont?._text;
      }
    }
    else{
      let infNFe = xml?.cteProc.CTe?.infCte?.infCTeNorm?.infDoc?.infNFe;
      if(infNFe?.length){
        containerNumber = infNFe?.[0]?.infUnidTransp?.infUnidCarga?.idUnidCarga?._text;
      }
      else{
        containerNumber = infNFe?.infUnidTransp?.infUnidCarga?.idUnidCarga?._text;
      }
    }
    return containerNumber ?? '';
  }

  public searchShipAndTripByContainerNumber(containerNumber: string): void{
    if(!containerNumber?.length){
      return;
    }
    if(this.doesSolicitationHaveIntegration){
      if(!this.documentTypesForContainerDivergency.includes(this.selectedDocumentType?.toUpperCase() ?? '')){
        return;
      }
    }
    this.cadastroDocumentoEstimativaCalculoService.searchShipAndTripByContainerNumber(containerNumber)
      .subscribe({
        next: (result) => {
          this.dadosComplementares?.setShipAndTripFromSearchByContainerNumber(result.data as DadosNavioVigemDTO);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public expandSolicitation(cadastroDocumentoId: number): void{
    this.visualizar = true;
    this.changeDetector.detectChanges();
    this.spinner?.show();
    this.cadastroDocumentoEstimativaCalculoService?.expandSolicitation(cadastroDocumentoId)
      .subscribe({
        next: (result) => {
          this.spinner?.hide();
          result?.message?.forEach(
            (message) => {
              this.toastService.success(message);
            }
          );
          let solicitation: CadastroDocumentoEstimativaCalculoDTO = result?.data as CadastroDocumentoEstimativaCalculoDTO;
          if(solicitation){
            this.isSearchDoneOrXMLUploaded = true;
            this.selectedDocumentType = solicitation?.documento?.tipoDocumento;
            this.setFormValuesFromReceivedDocumentRegister(solicitation);
          }
          this.changeDetector.detectChanges();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner?.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public get shouldBlockPartQuantityEditionByDocumentType(): boolean{
    return this.documentTypesToBlockPartQuantityEdition.includes(this.selectedDocumentType ?? '');
  }

  public updateArvorePartes(): void{
    if(!this.relacaoConteineres){
      return;
    }
    let containers = this.relacaoConteineres.getSelectedContainers();
    if(!containers?.length){
      this.arvorePartes?.clean();
      return;
    }
    if(!containers.find(container => container.parte && container.quantidadePartes)){
      this.arvorePartes?.clean();
      return;
    }
    let documentosPartesGrid = this.arvorePartes?.getArvorePartesFromForm() ?? [];
    let arvorePartes: DocumentoParteEstimativaCalculo[] = [];
    containers.forEach(
      (conteiner) => {
        let partesConteiner = documentosPartesGrid.filter(parte => parte.conteiner == conteiner.conteiner) ?? [];
        let qtdPartes: number = conteiner?.quantidadePartes ?? 0;
        if(partesConteiner.length < (qtdPartes - 1)){
          while(partesConteiner.length < (qtdPartes - 1)){
            partesConteiner.push({
              conteiner: conteiner.conteiner,
              numeroDocumentoParte: '',
              parteCadastrada: false,
              numeroCE: ''
            });
          }
        }
        if(partesConteiner.length > (qtdPartes - 1)){
          while(partesConteiner.length > (qtdPartes - 1) && partesConteiner.length > 0){
            partesConteiner.pop();
          }
        }
        arvorePartes.push(...partesConteiner);
      }
    );
    this.arvorePartes.setFormValuesFromArvorePartes(arvorePartes);
  }

  public get isSalvarDocumentoParteEnabled(): boolean{
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      return false;
    }
    return !!this.relacaoConteineres?.getSelectedContainers()?.length &&
      !!this.arvorePartes?.documentosPartes?.length &&
      this.arvorePartes?.areAllDocumentoParteLinesValid &&
      !this.arvorePartes?.arePartDocumentsSameAsStored;
  }

  public savePartDocument(): void{
    if(!this.isSalvarDocumentoParteEnabled){
      return;
    }
    this.spinner.show();
    this.closeSignalRConnection();
    const tipoDocumentoId = this.documento?.getDocumentTypeId().toString();
    this.cadastroDocumentoEstimativaCalculoService.savePartDocument(this.setSolicitationForSavingPartDocument(), tipoDocumentoId)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          this.isPartDocumentSaved = true;
          result?.message?.forEach((msg) => {
            if(msg?.length){
              this.toastService.success(msg);
            }
          });
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  private setSolicitationForSavingPartDocument(): CadastroDocumentoParteCadastroDocumentoDTO[]{
    const partes = this.arvorePartes.getArvorePartesFromForm()
    const cadastroDocumentoParte: CadastroDocumentoParteCadastroDocumentoDTO[] = partes.map((parte) => {
      return {
        numeroCE: parte.numeroCE,
        numeroConteiner: parte.conteiner,
        numeroDocumento: parte.numeroDocumentoParte
      }
    });
    return cadastroDocumentoParte;
  }

  public closeModal() {
    this.modalService.hide();
  }

  public displayIntegrationErrors(): void{
    if(this.integrationErrors?.length){
      this.modalRef = this.modalService.show(this.modalAlertaErrosIntegracao, { class: 'modal-lg' });
      this.modalRef.onHidden?.subscribe(
        () => {
          this.integrationErrors = [];
        }
      );
    }
  }

  public get isPartDocumentAbsentOrSaved(): boolean{
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      return true;
    }
    if(!this.arvorePartes?.getArvorePartesFromForm()?.length){
      return true;
    }
    if(this.arvorePartes?.arePartDocumentsSameAsStored){
      return true;
    }
    return this.isPartDocumentSaved;
  }

  public get isImpDocument(): boolean{
    return this.documentsForImp.includes(this.selectedDocumentType ?? '');
  }

  public get isExpDocument(): boolean{
    return this.documentsForExp.includes(this.selectedDocumentType ?? '');
  }
}
