<ng-template #modalIncluirBooking>
  <div class="modal-header">
    <h4>Incluir Booking</h4>
    <button class="btn-close close pull right"><span class="visualli-hidden"
      (click)="closeModal()">&times;</span></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formIncludeBooking">
      <div class="row mb-3">
        <div class="col">
          <label for="">Navio:</label>
          <input formControlName="navio" type="text" class="form-control" list="nomesNavios"
          [ngClass]="{'border border-danger': formIncludeBooking.get('navio')?.touched && formIncludeBooking.get('navio')?.errors}">
          <datalist id="nomesNavios">
            <option *ngFor="let nomeNavio of nomesNavios" [value]="nomeNavio">
          </datalist>
          <p class="text-danger" *ngIf="formIncludeBooking.get('navio')?.touched && formIncludeBooking.get('navio')?.errors?.['navioInvalido']">
            {{formIncludeBooking.get('navio')?.errors?.['navioInvalido']}}
          </p>
        </div>
        <div class="col">
          <label for="">Viagem:</label>
          <input formControlName="viagem" type="text" class="form-control" list="numerosViagens"
          [ngClass]="{'border border-danger': formIncludeBooking.get('viagem')?.touched && formIncludeBooking.get('viagem')?.errors}">
          <datalist id="numerosViagens">
            <option *ngFor="let numeroViagem of numerosViagens" [value]="numeroViagem">
          </datalist>
          <p class="text-danger" *ngIf="formIncludeBooking.get('viagem')?.touched && formIncludeBooking.get('viagem')?.errors?.['viagemInvalida']">
            {{formIncludeBooking.get('viagem')?.errors?.['viagemInvalida']}}
          </p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label for="">Armador:</label>
          <input formControlName="armador" type="text" class="form-control"
          [ngClass]="{'border border-danger': formIncludeBooking.get('armador')?.touched && formIncludeBooking.get('armador')?.errors}">
        </div>
        <div class="col">
          <label for="">Booking:</label>
          <input formControlName="booking" type="text" class="form-control" list="numerosBookings"
          [ngClass]="{'border border-danger': formIncludeBooking.get('booking')?.touched && formIncludeBooking.get('booking')?.errors}">
          <datalist id="numerosBookings">
            <option *ngFor="let numeroBooking of numerosBookings" [value]="numeroBooking">
          </datalist>
          <p class="text-danger" *ngIf="formIncludeBooking.get('booking')?.touched && formIncludeBooking.get('booking')?.errors?.['bookingInvalido']">
            {{formIncludeBooking.get('booking')?.errors?.['bookingInvalido']}}
          </p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label for="">Modalidade:</label>
          <select formControlName="modalidade" class="custom-select"
          [ngClass]="{'border border-danger': formIncludeBooking.get('modalidade')?.touched && formIncludeBooking.get('modalidade')?.errors}"
          [attr.disabled]="areFormTextfieldsFilled ? null : 'disabled'">
            <option value="" disabled selected>Selecione</option>
            <option [ngValue]="'Porta'" [value]="'Porta'">Porta</option>
            <option [ngValue]="'Porto'" [value]="'Porto'">Porto</option>
          </select>
        </div>
        <div class="col">
          <div class="action-button-group action-solo-button">
            <button class="action-primary-button" [disabled]="formIncludeBooking.invalid" (click)="saveBooking()">SALVAR</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
