<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Contêiners</span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <div class="p-3">

            <h3>Contêineres não vinculados à NFe:</h3>
            <div class="overflow-auto my-3">
                <table class="grid-table">
                    <thead>
                        <tr>
                            <th>
                                <input class="form-chek-input" type="checkbox" [checked]="selecionarTodosNaoVinculados" (change)="switchAllUnlinkedContainersSelectedOrNot()">
                            </th>
                            <th>Contêiner</th>
                            <th>Data Chegada</th>
                            <th>Booking</th>
                            <th>Peso</th>
                            <th>Tara</th>
                            <th>Lacre N4</th>
                            <th>Lacre Portal TC</th>
                            <th>Nota Fiscal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="readonly-grid-line" *ngFor="let conteiner of conteineresNaoVinculados">
                            <td>
                                <div class="input-group d-flex">
                                    <input class="form-chek-input m-auto" type="checkbox" [checked]="conteiner.selecionado" (click)="changeSelectedFromUnlinkedContainers(conteiner)">
                                </div>
                            </td>
                            <td>{{conteiner.numeroConteiner}}</td>
                            <td>{{conteiner.dataChegada | date : 'dd/MM/yyyy' : 'UTC+0'}}</td>
                            <td>{{conteiner.numeroBooking}}</td>
                            <td>{{conteiner.peso}}</td>
                            <td>{{conteiner.tara}}</td>
                            <td>{{getConcatenatedSealNumbers(conteiner.lacresN4)}}</td>
                            <td>{{getConcatenatedSealNumbers(conteiner.lacresPortalTecon)}}</td>
                            <td>{{conteiner.chaveNFE}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="d-flex justify-content-center align-content-center my-4">
                <div class="action-double-buttons action-button-group">
                    <button class="action-tertiary-button" (click)="linkContainers()" [disabled]="!isAnyUnlinkedConteinerSelected">
            <img src="assets/images/blue-arrow-down.png" alt="">
            <span class=""> VINCULAR CONTEINER</span>
          </button>
                    <button class="action-tertiary-button" (click)="unlinkContainers()" [disabled]="!isAnyLinkedConteinerSelected">
            <img src="assets/images/blue-arrow-up.png" alt="">
            <span class=""> DESVINCULAR CONTEINER</span>
          </button>
                </div>
            </div>

            <h3>Contêineres vinculados à NFe:</h3>
            <div class="overflow-auto my-3">
                <table class="grid-table">
                    <thead>
                        <tr>
                            <th>
                                <input class="form-chek-input" type="checkbox" [checked]="selecionarTodosVinculados" (click)="switchAllLinkedContainersSelectedOrNot()">
                            </th>
                            <th>Contêiner</th>
                            <th>Data Chegada</th>
                            <th>Booking</th>
                            <th>Peso</th>
                            <th>Tara</th>
                            <th>Lacre N4</th>
                            <th>Lacre Portal TC</th>
                            <th>Nota Fiscal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="readonly-grid-line" *ngFor="let conteiner of conteineresVinculados">
                            <td>
                                <div class="input-group d-flex">
                                    <input class="form-chek-input m-auto" type="checkbox" [checked]="conteiner.selecionado" (change)="changeSelectedFromLinkedContainers(conteiner)">
                                </div>
                            </td>
                            <td>{{conteiner.numeroConteiner}}</td>
                            <td>{{conteiner.dataChegada | date : 'dd/MM/yyyy' : 'UTC+0'}}</td>
                            <td>{{conteiner.numeroBooking}}</td>
                            <td>{{conteiner.peso}}</td>
                            <td>{{conteiner.tara}}</td>
                            <td>{{getConcatenatedSealNumbers(conteiner.lacresN4)}}</td>
                            <td>{{getConcatenatedSealNumbers(conteiner.lacresPortalTecon)}}</td>
                            <td>{{conteiner.chaveNFE}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>