import { LinhaGridRelacaoNotasFiscaisComponent } from './../linha-grid-relacao-notas-fiscais/linha-grid-relacao-notas-fiscais.component';
import { NotaFiscalCabotagemDTO } from './../../../models/SolicitacaoLiberacaoCabotagem/NotaFiscalCabotagemDTO';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output, ChangeDetectorRef, ViewChild, ViewChildren, QueryList, Input } from '@angular/core';
import { debounceTime } from 'rxjs';
import { Campo } from 'src/app/models/Campo';

@Component({
  selector: 'app-relacao-notas-fiscais',
  templateUrl: './relacao-notas-fiscais.component.html',
  styleUrls: ['./relacao-notas-fiscais.component.scss']
})
export class RelacaoNotasFiscaisComponent implements OnInit {

  public isCollapsed: boolean = false;
  public numerosNotasFiscaisDisplay!: NotaFiscalCabotagemDTO[];
  public listaNotasFiscaisReadonly: boolean = false;
  @Input() camposFormulario!: Campo[];
  @Input() doesSolicitationHaveIntegration!: boolean;
  @ViewChildren(LinhaGridRelacaoNotasFiscaisComponent) private notasFiscaisGrid!: QueryList<LinhaGridRelacaoNotasFiscaisComponent>;
  @Output() dataFormOut: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.numerosNotasFiscaisDisplay = [];
    this.sendNotasFiscaisData();
  }

  public sendNotasFiscaisData(): void {
    this.dataFormOut.emit({'notasFiscais': this.getInvoiceRelationFromForm()});
    this.changeDetector.detectChanges();
  }

  public setFormValuesFromReceivedRelacaoNotaFiscal(relacaoNotaFiscal: NotaFiscalCabotagemDTO[] | undefined){
    if(!relacaoNotaFiscal){
      return;
    }
    this.numerosNotasFiscaisDisplay = [...relacaoNotaFiscal];
    this.sendNotasFiscaisData();
  }

  public cancel(){
    this.numerosNotasFiscaisDisplay = [];
  }

  public getInvoiceRelationFromForm(): NotaFiscalCabotagemDTO[]{
    return this.notasFiscaisGrid?.map(
      (notaFiscal) => notaFiscal.getInvoicefromFormFields()
    );
  }

  public areAllGridLinesValid(): boolean{
    return this.notasFiscaisGrid?.map(
      (notaFiscal) => notaFiscal.isInvoiceLineValid()
    ).every(line => line);
  }

  public setSettingsfromFields(): void{
    if(!this.camposFormulario?.length){
      this.notasFiscaisGrid.forEach(
        (notaFiscal) => {
          notaFiscal.enableAllFormFields();
        }
      );
      return;
    }
    let campoListaNotasFiscais: Campo | undefined = this.camposFormulario?.find(campo => campo.nome == 'listaNotasFicais');
    this.listaNotasFiscaisReadonly = !!campoListaNotasFiscais?.leitura || !!campoListaNotasFiscais?.bloqueado;
    this.changeDetector.detectChanges();
    this.notasFiscaisGrid.forEach(
      (notaFiscal) => {
        notaFiscal.setSettingsfromFields();
      }
    );
    this.changeDetector.detectChanges();
  }
}
