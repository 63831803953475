<td *ngIf="selecionado" class="text-center">
    <input type="checkbox" (click)="onManualSelection.emit()" [formControl]="selecionado">
</td>
<td *ngIf="numeroConteiner">
    <div class="input-group">
        <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
          aria-describedby="basic-addon2" [formControl]="numeroConteiner"
          (change)="sendContainerNumberToSearchIfNoIntegration()"
        >
    </div>
</td>
<td *ngIf="numeroConteinerTransload">
    <div class="input-group d-flex">
        <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2" [formControl]="numeroConteinerTransload">
    </div>
</td>
<td *ngIf="parte">
    <select class="custom-select" [formControl]="parte" (change)="setValidValueForArvorePartes(!!parte.value)">
      <option value="" disabled selected>Selecione</option>
      <option [value]="true" [ngValue]="true">Sim</option>
      <option [value]="false" [ngValue]="false">Não</option>
    </select>
</td>
<td *ngIf="quantidadePartes && parte && qtdPartesFront">
    <div class="input-group">
        <input type="number" class="form-control" placeholder="Digite aqui..."
          aria-label="Digite aqui..." aria-describedby="basic-addon2" [formControl]="qtdPartesFront"
          [readonly]="!parte.value" [min]="minPartQuantity"
          (change)="formConteiner.patchValue({quantidadePartes: qtdPartesFront.value ? qtdPartesFront.value : 0}, {emitEvent: false});setValidValueForArvorePartes(!!parte.value)"
          [ngClass]="{'no-up-down-button': shouldBlockPartQuantityEditionByDocumentType}"
        >
    </div>
</td>
<td *ngIf="selecionado">
  <button class="erase-line-button" *ngIf="!doesSolicitationHaveIntegration && !!selecionado.value" (click)="eraseLine()">
    <img src="assets/images/red-trashcan.svg" alt="">
  </button>
</td>
