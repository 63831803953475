import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse, HttpEventType } from '@angular/common/http';
import { Observable, throwError, from, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { ToastService } from '../services/toast.service';
import { ErrorResponse } from '../models/HttpResponses/ErrorResponse';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private isRefreshing = false;

    constructor(
        private authenticationService: AuthenticationService,
        private spinner: NgxSpinnerService,
        private toastService: ToastService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      //this.spinner.show();

        var token = this.authenticationService.authorizationHeaderValue;

        const reqClone = req.clone({
            setHeaders: {
                'Authorization': token
            }
        });

        return next.handle(reqClone).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // setTimeout(() => {
                        //this.spinner.hide();
                    // }, (200));
                }
                return event;
            }),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    return this.handle401Error(reqClone,next);
                }

                // if (err.error.errors) {
                //     console.log(err.error.errors)
                //     err.error.errors.forEach((element: any) => {
                //         this.toastService.error(element)
                //     });
                // }else{
                  if(!err.url?.includes('Pesquisar') && !(err?.error as ErrorResponse)?.errors){
                    this.toastService.error(`Erro ao realizar a operação. Por favor abra um chamado anexando um print com essa mensagem. - ${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`);
                  }
                //}

                setTimeout(() => {
                    //this.spinner.hide();
                }, (200));
                return throwError(err);
            }));
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        let regConnect = /(\connect)+/g;
        let reqIsConnect = regConnect.test(request.url)

        if (!this.isRefreshing && !reqIsConnect) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authenticationService.refreshToken().pipe(
                switchMap((res: any) => {
                    this.isRefreshing = false;

                    this.authenticationService.storageDataAfterRefreshToken(res);
                   // this.spinner.hide();
                    return next.handle(this.addTokenHeader(request, res.accessToken));
                }),
                catchError((err) => {
                    this.isRefreshing = false;

                    this.authenticationService.signout();
                    //this.spinner.hide();
                    return throwError(err);
                })
            );
        }

        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({ headers: request.headers.set('Authorization', token) });
      }
}
