import { Documento } from './../../../models/Documento';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { TipoDocumentoTransporte } from '../../../models/RecepcaoDocumentoTransporte/TipoDocumentoTransporte';
import { DocumentCustomPattern } from '../../../shared/utils/constantes-documentos';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Campo } from 'src/app/models/Campo';
import { debounceTime } from 'rxjs';
import { DocumentoTransporte } from 'src/app/models/RecepcaoDocumentoTransporte/DocumentoTransporte';
import { RecepcaoDueContingenciaService } from 'src/app/services/recepcao-due-contingencia.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from 'oidc-client-ts';
@Component({
  selector: 'app-documento-transporte',
  templateUrl: './documento-transporte.component.html',
  styleUrls: ['./documento-transporte.component.scss']
})
export class DocumentoTransporteComponent implements OnInit, AfterViewInit {
  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;
  public locale = 'pt-br';
  public documentTypes!: TipoDocumentoTransporte[];
  public mask!: string;
  public specialCharsForMask!: string[];


  @Input() camposformulario!: Campo[];
  @Output() onSearchContainer: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private recepcaoDueContingenciaService: RecepcaoDueContingenciaService,
    private verificacaoDocumentosService: VerificacaoDocumentosService
  ) {
   }

  public ngOnInit(): void {
    this.mask = '';
    this.specialCharsForMask = [];
    this.validation();
    this.getDocumentTypes();
    }

  public ngAfterViewInit(): void {
    if(!this.camposformulario || !this.camposformulario.length){
      return;
    }
    this.camposformulario.forEach(
      (campo) => {
        if(campo.obrigatorio){
          this.form.get(campo.nome)?.addValidators(Validators.required);
        }
      }
    );
  }


  private validation(): void{
    this.form = this.fb.group({
      id: ['', [Validators.required]],
      tipoDocumento: ['', [Validators.required]],
      numeroDocumento: ['', [Validators.required]],
    });
    this.form.get('tipoDocumento')?.valueChanges.subscribe(
      (documentType: string) => {
        this.getSettingsFromDocumentType(documentType);
      }
    );
  }

  public removeSpacesFromExtremities(inputName: string): void{
    this.form.get(inputName)?.setValue((this.form.get(inputName)?.value as string).trim());
  }

  public setFormValuesFromReceivedConteinerData(containerData: DocumentoTransporte | undefined): void{
    if(!containerData){
      return;
    }
    this.form.get('tipoDocumento')?.setValue(containerData?.tipoDocumento);
    this.form.get('numeroDocumento')?.setValue(containerData?.numeroDocumento);
    this.changeDetector.detectChanges();
  }

  public clean(): void{
    this.form.reset();
  }

  public searchContainer(): void{
    let conteiner: DocumentoTransporte = this.form.getRawValue();
    this.onSearchContainer.emit({'documento': conteiner});
  }

  private getDocumentTypes(): void{
    this.documentTypes = [];
    this.recepcaoDueContingenciaService.getDocumentTypes()
      .subscribe({
        next: (result) => {
          this.documentTypes = result.data as TipoDocumentoTransporte[];
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
        }
      });
  }

  private getSettingsFromDocumentType(documentType: string): void{
    this.form.get('id')?.setValue(
      this.documentTypes.find(
        (documento) => documento.nome == documentType
      )?.id
    );
    this.mask = this.documentTypes.find(
      (documento) => documento.nome == documentType
    )?.mascara ?? '';
    this.specialCharsForMask = this.verificacaoDocumentosService.getSpecialCharsFromMask(this.mask) ?? [];
  }

  public getDocumentFromForm(): Documento{
    return this.form.getRawValue() as Documento;
  }

  public isDocumentoValid(): boolean{
    return !this.form.invalid;
  }

}
