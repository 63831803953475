import { ConfirmacaoLiberacaoDocumentalDTO } from './../../../../models/LiberacaoDocumental/ConfirmacaoLiberacaoDocumentalDTO';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirmar-liberacao',
  templateUrl: './modal-confirmar-liberacao.component.html',
  styleUrls: ['./modal-confirmar-liberacao.component.scss']
})
export class ModalConfirmarLiberacaoComponent implements OnInit {

  public modalRef!: BsModalRef;
  public justificativas = ['Motivo desbloqueio 1', 'Motivo desbloqueio 2', 'Outros'];
  public confirmacao!: ConfirmacaoLiberacaoDocumentalDTO;

  @Input() public modalTitle!: string;
  @Input() public negativeText!: string;
  @Input() public positiveText!: string;

  @Output() public onChoosePositiveAction: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onChooseNegativeAction: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('modalConfirmarLiberacao', {static: false}) modal!: TemplateRef<any>;

  constructor(
    private modalService: BsModalService,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {

  }

  public openModalForLiberationConfirm(confirmation: ConfirmacaoLiberacaoDocumentalDTO): void{
    this.confirmacao = confirmation;
    this.modalRef = this.modalService.show(this.modal, {
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }

  public closeModal(): void{
    this.modalService.hide();
  }

  public negativeAction(): void{
    this.onChooseNegativeAction.emit();
    this.closeModal();
  }

  public positiveAction(justificativa: string): void{
    if(!justificativa?.length){
      return;
    }
    console.log(justificativa)
    // this.onChoosePositiveAction.emit(justificativa);
    // this.closeModal();
  }

}
