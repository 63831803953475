import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { TipoDocumentoAnexado } from 'src/app/models/DocumentosAnexados/TipoDocumentoAnexado';
import { ArquivoDocumentoAnexado } from '../../models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { VerificacaoDocumentosService } from '../../shared/utils/verificacao-documentos.service';
import { DocumentoEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/DocumentoEstimativaCalculo';
import { CadastroDocumentoEstimativaCalculoDTO } from '../../models/EstimativaCalculo/CadastroDocumento/CadastroDocumentoEstimativaCalculoDTO';
import { environment } from 'src/environments/environment';
import { filter, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SuccessResponse } from '../../models/HttpResponses/SuccessResponse';
import * as FileSaver from 'file-saver';
import { Cliente } from '../../models/Cliente';
import { DatePipe } from '@angular/common';
import { ConteinerEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/ConteinerEstimativaCalculo';
import { CadastroDocumentoParteCadastroDocumentoDTO } from 'src/app/models/EstimativaCalculo/CadastroDocumento/CadastroDocumentoParteCadastroDocumentoDTO';

@Injectable({
  providedIn: 'root'
})
export class CadastroDocumentoEstimativaCalculoService {

  constructor(
    private http: HttpClient,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private authenticationService: AuthenticationService,
    public datePipe: DatePipe
  ) { }

  public getDocumentTypesForDocumentRegister(): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/api/ConfiguracaoCampoTela/TipoDocumento`);
  }

  public getFieldSettingForDocumentRegisterByDocumentType(documentType: string): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/api/ConfiguracaoCampoTela?tipoDocumento=${encodeURIComponent(documentType)}`);
  }

  public searchDocumentRegister(documentRegister: DocumentoEstimativaCalculo, correlationId: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlationId);
    let query: string[] = [];
    query.push('confirmado=false');
    if(documentRegister?.numeroCE?.length){
      query.push(`numeroCE=${encodeURIComponent(documentRegister?.numeroCE)}`);
    }
    if(documentRegister?.chaveNotaFiscal?.length){
      query.push(`chaveNotaFiscal=${encodeURIComponent(documentRegister?.chaveNotaFiscal)}`);
    }
    if(documentRegister?.duEs?.length){
      query.push(
        documentRegister.duEs.map(
          (duEs) => `numeroDUE=${encodeURIComponent(duEs.numeroDUE!)}`
        )?.join('&')
      );
    }
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`)

    return this.http.get<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/CadastroDocumento/Pesquisar/${documentRegister.id}/${this.verificacaoDocumentosService.eraseMask(documentRegister.numeroDocumento!)}?${query.join('&')}`, {headers});
  }

  public confirmCreateDocumentRegister(documentRegister: DocumentoEstimativaCalculo, correlationId: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlationId);
    let query: string[] = [];
    query.push('confirmado=true');
    if(documentRegister?.numeroCE?.length){
      query.push(`numeroCE=${encodeURIComponent(documentRegister?.numeroCE)}`);
    }
    if(documentRegister?.chaveNotaFiscal?.length){
      query.push(`chaveNotaFiscal=${encodeURIComponent(documentRegister?.chaveNotaFiscal)}`);
    }
    if(documentRegister?.duEs?.length){
      query.push(
        documentRegister.duEs.map(
          (duEs) => `numeroDUE=${encodeURIComponent(duEs.numeroDUE!)}`
        )?.join('&')
      );
    }
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`)

    return this.http.get<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/CadastroDocumento/Pesquisar/${documentRegister.id}/${this.verificacaoDocumentosService.eraseMask(documentRegister.numeroDocumento!)}?${query.join('&')}`, {headers});
  }

  public downloadFileFromAttachedFiles(file: ArquivoDocumentoAnexado): void{
    FileSaver.saveAs(file.arquivoDocumento as File, file.nomeArquivo);
  }

  public cancelDocumentRegister(idSolicitacao: number): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/CadastroDocumento/${idSolicitacao}/Cancelar`, undefined);
  }

  public saveDocumentRegisterDraft(cadastroDocumento: CadastroDocumentoEstimativaCalculoDTO): Observable<SuccessResponse>{
    if(cadastroDocumento?.documentosAnexados?.length){
      cadastroDocumento.documentosAnexados?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    return this.http.post<SuccessResponse>(
      `${environment.apiUrlForCadastroDocumento}/CadastroDocumento/Rascunho`,
      cadastroDocumento
    );
  }

  public sendMemoriaCalculo(cadastroDocumento: CadastroDocumentoEstimativaCalculoDTO): Observable<SuccessResponse>{
    if(cadastroDocumento?.documentosAnexados?.length){
      cadastroDocumento.documentosAnexados?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    let query: string[] = [];
    if(cadastroDocumento.numeroSolicitacao){
      query.push(`numeroSolicitacao=${encodeURIComponent(cadastroDocumento.numeroSolicitacao)}`);
    }
    return this.http.post<SuccessResponse>(
      `${environment.apiUrlForCadastroDocumento}/CadastroDocumento/MemoriaCalculo/${this.verificacaoDocumentosService.eraseMask(cadastroDocumento.documento?.numeroDocumento!)}/${cadastroDocumento.documento?.id}/${encodeURIComponent(cadastroDocumento.cliente?.nomeCliente!)}/${this.authenticationService.userName}?${query.join('&')}`,
      cadastroDocumento
    );
  }

  public sendFinancialLiberation(cadastroDocumento: CadastroDocumentoEstimativaCalculoDTO): Observable<SuccessResponse>{
    if(cadastroDocumento?.documentosAnexados?.length){
      cadastroDocumento.documentosAnexados?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    let query: string[] = [];
    if(cadastroDocumento.numeroSolicitacao){
      query.push(`numeroSolicitacao=${encodeURIComponent(cadastroDocumento.numeroSolicitacao)}`);
    }
    return this.http.post<SuccessResponse>(
      `${environment.apiUrlForCadastroDocumento}/CadastroDocumento/LiberacaoFinanceira/${this.verificacaoDocumentosService.eraseMask(cadastroDocumento.documento?.numeroDocumento!)}/${cadastroDocumento.documento?.id}/${this.authenticationService.userName}?${query.join('&')}`,
      cadastroDocumento
    );
  }

  public getCliente(client: Cliente): Observable<SuccessResponse>{
    let query: string[] = [];
    if(client.cpfCnpj?.length){
      query.push(`CpfCnpj=${encodeURIComponent(this.verificacaoDocumentosService.eraseMask(client.cpfCnpj))}`);
    }
    if(client.nomeCliente?.length){
      query.push(`nome=${encodeURIComponent(client.nomeCliente)}`);
    }
    query.push(`email=${this.authenticationService.userEmail}`);
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`);
    return this.http.get<SuccessResponse>(
      `${environment.apiUrlForCadastroDocumento}/api/Cliente?${query.join('&')}`
    );
  }

  public getClientesVinculados(): Observable<SuccessResponse>{
    let query: string[] = [];

    query.push(`email=${this.authenticationService.userEmail}`);
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`);
    return this.http.get<SuccessResponse>(
      `${environment.apiUrlForCadastroDocumento}/api/Cliente/ClientesVinculados?${query.join('&')}`
    );
  }
  public getClientesFaturarVinculados(): Observable<SuccessResponse>{
    let query: string[] = [];

    query.push(`email=${this.authenticationService.userEmail}`);
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`);
    return this.http.get<SuccessResponse>(
      `${environment.apiUrlForCadastroDocumento}/api/Cliente/ClientesFaturarVinculados?${query.join('&')}`
    );
  }

  public getClienteFaturar(client: Cliente): Observable<SuccessResponse>{
    let query: string[] = [];
    if(client.cpfCnpj?.length){
      query.push(`CpfCnpj=${encodeURIComponent(this.verificacaoDocumentosService.eraseMask(client.cpfCnpj))}`);
    }
    if(client.nomeCliente?.length){
      query.push(`nome=${encodeURIComponent(client.nomeCliente)}`);
    }

    query.push(`email=${this.authenticationService.userEmail}`);
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`);
    return this.http.get<SuccessResponse>(
      `${environment.apiUrlForCadastroDocumento}/api/Cliente/Faturar?${query.join('&')}`
    );
  }

  public uploadFilesForDocumentRegister(solicitation: CadastroDocumentoEstimativaCalculoDTO): Observable<SuccessResponse>{
    const formData = new FormData();
    solicitation.documentosAnexados?.forEach(
      (documento) => {
        if(documento?.arquivoDocumento?.size){
          formData.append('id', documento.tipoDocumento.idDocumento.toString());
          formData.append('files', documento.arquivoDocumento!);
        }
      }
    );
    return this.http.post<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/UploadArquivosSolicitacao/uploadfiles/${solicitation.id}`, formData);;
  }

  public downloadFileForDocumentRegister(arquivo: ArquivoDocumentoAnexado): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/UploadArquivosSolicitacao/BaixarArquivo?filePath=${encodeURIComponent(arquivo.filePath!)}&fileName=${encodeURIComponent(arquivo.nomeArquivo!)}&contentType=${encodeURIComponent(arquivo.contentType!)}`);
  }

  public getFilesToAttach(): Observable<TipoDocumentoAnexado[]>{
    return this.http.get<TipoDocumentoAnexado[]>(`${environment.apiUrlForCadastroDocumento}/DocumentosAnexo`);
  }

  public getIntegrationsFromXML(solicitation: CadastroDocumentoEstimativaCalculoDTO, correlationId: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlationId);
    let query: string[] = [];
    query.push('confirmado=false');
    if(solicitation?.documento?.numeroCE?.length){
      query.push(`numeroCE=${encodeURIComponent(solicitation?.documento?.numeroCE)}`);
    }
    if(solicitation.documento?.chaveNotaFiscal?.length){
      query.push(`chaveNotaFiscal=${encodeURIComponent(solicitation.documento?.chaveNotaFiscal)}`);
    }
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`)

    return this.http.post<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/Cadastrodocumento/UploadXml/${solicitation?.documento?.id}/${solicitation?.documento?.numeroDocumento}?${query.join('&')}`, solicitation, {headers});
  }

  public confirmCreateIntegrationsFromXML(solicitation: CadastroDocumentoEstimativaCalculoDTO, correlationId: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlationId);
    let query: string[] = [];
    query.push('confirmado=true');
    if(solicitation?.documento?.numeroCE?.length){
      query.push(`numeroCE=${encodeURIComponent(solicitation?.documento?.numeroCE)}`);
    }
    if(solicitation.documento?.chaveNotaFiscal?.length){
      query.push(`chaveNotaFiscal=${encodeURIComponent(solicitation.documento?.chaveNotaFiscal)}`);
    }
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`)

    return this.http.post<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/Cadastrodocumento/UploadXml/${solicitation?.documento?.id}/${solicitation?.documento?.numeroDocumento}?${query.join('&')}`, solicitation, {headers});
  }

  public postCancelarSolicitacao(solicitacao: CadastroDocumentoEstimativaCalculoDTO, conteiner: ConteinerEstimativaCalculo): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/Cadastrodocumento/ExcluirConteiner/${solicitacao.id}/${conteiner.conteiner}`, null);
  }

  public searchShipAndTripByContainerNumber(containerNumber: string): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/CadastroDocumento/BuscarNavioViagem/${containerNumber}`);
  }

  public getNumeroCEByContainerNumber(containerNumber: string): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/CadastroDocumento/BuscarNumeroCEPeloConteiner/${containerNumber}`);
  }

  public expandSolicitation(cadastroDocumentoId: number): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/CadastroDocumento/Expandir/${cadastroDocumentoId}`);
  }

  public savePartDocument(cadastroDocumentoParte: CadastroDocumentoParteCadastroDocumentoDTO[], tipoDocumentoId: string): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForCadastroDocumento}/CadastroDocumento/DocumentoParte?tipoDocumentoId=${tipoDocumentoId}`, cadastroDocumentoParte);
  }
}
