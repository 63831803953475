import { MaskPipe } from 'ngx-mask';
import { debounceTime } from 'rxjs';
import { VerificacaoDocumentosService } from './../../../../shared/utils/verificacao-documentos.service';
import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from './../../../../models/HttpResponses/ErrorResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { TipoDocumentoEstimativaCalculo } from './../../../../models/EstimativaCalculo/CadastroDocumento/TipoDocumentoEstimativaCalculo';
import { CadastroDocumentoEstimativaCalculoService } from '../../../../services/EstimativaCalculo/cadastro-documento-estimativa-calculo.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { DocumentoEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/DocumentoEstimativaCalculo';
import { Campo } from 'src/app/models/Campo';
import * as xmlJs from 'xml-js';
import { DefaultMaskPattern, DocumentCustomPattern, DocumentMaskEnum } from 'src/app/shared/utils/constantes-documentos';
import { CampoNumeroDueComponent } from 'src/app/shared/components/campo-numero-due/campo-numero-due.component';
import { NumeroDue } from 'src/app/models/EstimativaCalculo/CadastroDocumento/NumeroDue';
import { Mascara } from 'src/app/models/Mascara';

@Component({
  selector: 'app-documento-estimativa-calculo',
  templateUrl: './documento-estimativa-calculo.component.html',
  styleUrls: ['./documento-estimativa-calculo.component.scss']
})
export class DocumentoEstimativaCalculoComponent implements OnInit {

  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;
  public documentTypes!: TipoDocumentoEstimativaCalculo[];
  public maskForDocumentNumber!: string;
  public specialCharsForMask!: string[];
  public xmlFile?: File;
  public wasDocumentNumberSearched?: boolean;
  private readonly fieldsNotToBlock: string[] = ['tipoDocumento', 'numeroDocumento'];
  public documentPattern?: any | null;
  public maskForCE: string = DocumentMaskEnum.CE_MASK;
  public maskForChaveNotaFiscal: string = DocumentMaskEnum.CHAVE_NOTA_FISCAL_MASK;
  public numerosDUE: NumeroDue[] = [];
  private readonly documentTypesForUploadingXml: string[] = ['DI', 'CTE', 'NF'];

  public readonly documentTypesForShowingNumeroCE: string[] = ['CTE', 'DSIm', 'DTC'];
  public isNumeroCEVisible: boolean = false;

  @Input() fieldSettings!: Campo[];
  @Input() public visualizar?: boolean = false
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Output() public onSearch: EventEmitter<DocumentoEstimativaCalculo> = new EventEmitter<DocumentoEstimativaCalculo>();
  @Output() public onUpdateDocument: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() public onChangeDocumentType: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onChangeDocumentTypeOnSelect: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onUploadXml: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onEraseXml: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('numDoc') pesquisar!: ElementRef;
  @ViewChildren(CampoNumeroDueComponent) camposNumeroDUE!: QueryList<CampoNumeroDueComponent>;

  constructor(
    private fb: UntypedFormBuilder,
    private cadastroDocumentoEstimativaCalculoService: CadastroDocumentoEstimativaCalculoService,
    private toastService:ToastService,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private changeDetector: ChangeDetectorRef,
    private maskPipe: MaskPipe
    ) { }

  public ngOnInit() {
    this.documentTypes = [];
    this.maskForDocumentNumber = '';
    this.specialCharsForMask = [];
    this.getDocumentTypes();
    this.validation();
  }

  private validation(): void {
    this.form = this.fb.group({
      id: [""],
      tipoDocumento: ["", [Validators.required]],
      numeroDocumento: ["", [Validators.required]],
      numeroCE: [""],
      numeroDUE: [""],
      chaveNotaFiscal: ['']
    }, {
      validators: [this.validateNumeroDUE.bind(this), this.validateIfNumeroCEIsPresentForCTE.bind(this)]
  });
    this.form.get('tipoDocumento')?.valueChanges
      .pipe(
        debounceTime(100)
      )
      .subscribe(
        (documentTypeName) => {
          this.changeDocumentType(documentTypeName);
        }
      );
    this.form.valueChanges
      .pipe(
        debounceTime(100)
      )
      .subscribe(
        () => {
          this.updateDocument();
        }
      );
  }

  private getDocumentTypes(): void{
    this.cadastroDocumentoEstimativaCalculoService.getDocumentTypesForDocumentRegister()
      .subscribe({
        next: (result) => {
          this.documentTypes = result.data as TipoDocumentoEstimativaCalculo[];
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          (err.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              if(error && error.length){
                this.toastService.error(error);
              }
            }
          );
        }
      });
  }

  private changeDocumentType(documentTypeName: string): void{
    if(!documentTypeName?.length){
      return;
    }
    if(!this.wasDocumentNumberSearched){
      // this.form.get('numeroDocumento')?.setValue('');
    }
    else{
      this.wasDocumentNumberSearched = false;
    }
    this.setMaskForReceivedDocument(documentTypeName);
    this.onChangeDocumentType.emit(documentTypeName);
    this.form.get('id')?.setValue(this.getDocumentTypeId());
    this.changeDetector.detectChanges();
    this.switchNumeroDUE();
  }

  public changeDocumentTypeOnSelect(): void{
    this.form?.get('numeroDocumento')?.setValue('');
    this.switchChaveNotaFiscal();
    this.switchNumeroCE();
    this.switchNumeroDUE();
    this.changeDetector.detectChanges();
    this.onChangeDocumentTypeOnSelect.emit(this.form.get('tipoDocumento')?.value);
    this.form?.updateValueAndValidity();
  }

  public search(): void{
    this.onSearch.emit(this.getDocumentFromFormFields());
  }

  public updateDocument(): void{
    this.onUpdateDocument.emit({'documento': this.getDocumentFromFormFields()});
  }

  public switchEditionForNumeroDue(enable: boolean): void{
    this.camposNumeroDUE?.forEach(numeroDue => {
      enable ? numeroDue.enable(): numeroDue.disable();
    })
  }

  public updateValidators(): void{
    if (this.visualizar) {
      this.form.disable({ emitEvent: false });
      this.switchEditionForNumeroDue(false);
      this.changeDetector.detectChanges();
      return;
    }
    if (!this.fieldSettings?.length) {
      this.enableAllFormFields();
      return;
    }
    for(let field in this.form.controls){
      let fieldSetting: Campo = this.fieldSettings.find(
        (setting) => setting.nome == field
      ) as Campo;
      if(fieldSetting){
        if(fieldSetting.obrigatorio){
          this.form.get(field)?.addValidators(Validators.required);
        }
        else{
          this.form.get(field)?.removeValidators(Validators.required);
        }
        if(this.doesSolicitationHaveIntegration){
          if(fieldSetting.leitura && this.form.get(field)?.value){
            this.form.get(field)?.disable();
          }
          else{
            this.form.get(field)?.enable();
          }
        }
        else{
          if(fieldSetting.leitura){
            this.form.get(field)?.disable();
          }
          else{
            this.form.get(field)?.enable();
          }
        }
        if(fieldSetting.bloqueado){
          this.form.get(field)?.disable();
        }
        if(!this.form.get(field)?.value && fieldSetting.obrigatorio){
          this.form.get(field)?.enable();
        }
      }
      else{
        if(!this.fieldsNotToBlock.includes(field)){
          this.form.get(field)?.removeValidators(Validators.required);
          this.form.get(field)?.enable();
        }
      }
      if(this.fieldsNotToBlock.includes(field)){
        this.form.get(field)?.addValidators(Validators.required);
      }
    }
    this.switchChaveNotaFiscal();
    this.switchNumeroCE();
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.form.controls){
      this.form.get(field)?.enable();
      if(!this.fieldsNotToBlock.includes(field)){
        this.form.get(field)?.removeValidators(Validators.required);
      }
      else{
        this.form.get(field)?.addValidators(Validators.required);
      }
      this.changeDetector.detectChanges();
    }
    this.switchChaveNotaFiscal();
    this.switchNumeroCE();
  }

  public setFormValuesFromReceivedDocument(document: DocumentoEstimativaCalculo | undefined): void{
    if(!document){
      return;
    }
    this.setMaskForReceivedDocument(document?.tipoDocumento);
    document.numeroDocumento = document.numeroDocumento ?
      (this.maskForDocumentNumber?.length ?
        this.maskPipe.transform(document.numeroDocumento, [this.maskForDocumentNumber, document?.tipoDocumento == 'DSIm' ? DocumentCustomPattern.DSIM_MASK_PATTERN : DefaultMaskPattern.DEFAULT_MASK_CHARS]) :
        document.numeroDocumento) :
      this.form.value.numeroDocumento;
    this.wasDocumentNumberSearched = true;
    this.form.patchValue({
      id: document.id ? document.id : this.form.value.id,
      tipoDocumento: document.tipoDocumento ? document.tipoDocumento : this.form.value.tipoDocumento,
      numeroDocumento: document.numeroDocumento,
      numeroCE: document?.numeroCE ? document.numeroCE : this.form.value.numeroCE,
      numeroDUE: document?.duEs ? document.duEs : this.form.value.numeroDUE,
      chaveNotaFiscal: document?.chaveNotaFiscal ? document.chaveNotaFiscal : this.form.value.chaveNotaFiscal
    }, {emitEvent: false});
    this.numerosDUE = document.duEs ?? [];
    this.form.markAllAsTouched();
    this.switchChaveNotaFiscal();
    this.switchNumeroCE();
    this.switchNumeroDUE();
  }

  public switchNumeroCE(): void{
    this.isNumeroCEVisible = !!this.documentTypesForShowingNumeroCE?.includes(this.form.get('tipoDocumento')?.value);
    this.changeDetector.detectChanges();
    if(this.isNumeroCEVisible){
      this.form?.get('numeroCE')?.enable();
    }
    else{
      this.form?.get('numeroCE')?.reset('');
      this.form?.get('numeroCE')?.disable();
    }
    this.changeDetector.detectChanges();
  }

  public switchNumeroDUE(): void{
    if(this.form.get('tipoDocumento')?.value == 'DAT'){
      this.form?.get('numeroDUE')?.addValidators(Validators.required);
      if(!this.numerosDUE?.length){
        this.numerosDUE = [{numeroDUE: ''}];
    }
  }
    else{
      this.form?.get('numeroDUE')?.removeValidators(Validators.required);
      this.form?.get('numeroDUE')?.reset('');
      this.numerosDUE = [];
    }
    this.changeDetector.detectChanges();

  }

  public switchChaveNotaFiscal(): void{
    if(this.form.get('tipoDocumento')?.value == 'NF'){
      this.form?.get('chaveNotaFiscal')?.enable();
    }
    else{
      this.form?.get('chaveNotaFiscal')?.reset('');
      this.form?.get('chaveNotaFiscal')?.disable();
    }
    this.changeDetector.detectChanges();
  }

  private setMaskForReceivedDocument(documentTypeName: string){
    let numeroDocumento = this.form?.get('numeroDocumento')?.value;
    if(documentTypeName == 'DSIm'){
      this.maskForDocumentNumber = 'D*';
      this.specialCharsForMask = [];
      this.documentPattern = DocumentCustomPattern.DSIM_MASK_PATTERN;
    }
    else{
      this.maskForDocumentNumber = this.documentTypes.find(
        (documentType) => documentType.nome === documentTypeName
      )?.mascara ?? '';
      this.specialCharsForMask = this.verificacaoDocumentosService.getSpecialCharsFromMask(this.maskForDocumentNumber) ?? [];
      this.documentPattern = null;
    }
    this.changeDetector.detectChanges();
    if(!this.maskForDocumentNumber?.length){
      this.form?.get('numeroDocumento')?.setValue(numeroDocumento);
    }
  }

  public clearFields(): void{
    this.form.reset();
    if(this.xmlFile){
      this.eraseXmlFile();
    }
    this.setMaskForReceivedDocument('');
    this.pesquisar?.nativeElement?.focus();
  }

  public getDocumentTypeId(): number{
    return this.documentTypes.find(
      (documentType) => documentType.nome == this.form.get('tipoDocumento')?.value
    )?.id!;
  }

  public uploadXmlFile(upload: any): void{
    const [file] = upload?.files as File[];
    if(!file){
      return;
    }
    upload.value = '';
    let extension: string = file.name.split('.').pop() ?? '';
    if(!extension || extension.toLowerCase() != 'xml'){
      this.toastService.error('Arquivo inválido!');
      return;
    }
    this.xmlFile = file;
    this.getDataFromXml();
  }

  public eraseXmlFile(): void{
    this.xmlFile = undefined;
    this.onEraseXml.emit();
  }

  private getDataFromXml(): void{
    if(!this.xmlFile){
      return;
    }
    let tipoDocumento: string = this.form.get('tipoDocumento')?.value ?? '';
    const reader = new FileReader();
    reader.onload = (file: any) => {
      let json = JSON.parse(xmlJs.xml2json(file.target.result, {compact: true, ignoreComment: true}));
      if(json.cteProc){
        if(tipoDocumento !== 'CTE'){
          this.notifyInvalidXml();
          return;
        }
      }
      else if(json.ListaDeclaracoes){
        if(tipoDocumento !== 'DI'){
          this.notifyInvalidXml();
          return;
        }
      }
      else if(json.nfeProc){
        if(tipoDocumento !== 'NF'){
          this.notifyInvalidXml();
          return;
        }
      }
      else{
        this.notifyInvalidXml();
        return;
      }
      this.onUploadXml.emit(json);
    };
    reader.readAsText(this.xmlFile);
  }

  private notifyInvalidXml(): void{
    this.toastService.error("Arquivo XML é inválido. Favor verificar!");
    this.xmlFile = undefined;
  }

  public setValuesFromDIXML(xml: any): void{
    this.setMaskForReceivedDocument('DI');
    this.form.patchValue({
      tipoDocumento: 'DI',
      numeroDocumento: this.maskForDocumentNumber ?
        this.maskPipe.transform(xml.ListaDeclaracoes.declaracaoImportacao.numeroDI._text, this.maskForDocumentNumber) :
        xml.ListaDeclaracoes.declaracaoImportacao.numeroDI._text,
      numeroCE: xml.ListaDeclaracoes.declaracaoImportacao.conhecimentoCargaIdMaster._text,
      chaveNotaFiscal: '',
      id: this.documentTypes?.find(documentType => documentType?.nome == 'DI')?.id
    }, {emitEvent: false});
    // this.updateDocument();
    this.onChangeDocumentType.emit('DI');
    this.form.markAllAsTouched();

  }

  public setValuesFromCTEXML(xml: Partial<DocumentoEstimativaCalculo>): void{
    this.setMaskForReceivedDocument('CTE');
    if(!xml.numeroDocumento){
      xml.numeroDocumento = this.form?.get('numeroDocumento')?.value;
    }
    this.form.patchValue({
      tipoDocumento: 'CTE',
      numeroDocumento: this.maskForDocumentNumber?.length ?
        this.maskPipe.transform(xml.numeroDocumento ?? '', this.maskForDocumentNumber) :
        xml.numeroDocumento,
      numeroCE: xml.numeroCE ?? '',
      chaveNotaFiscal: '',
      id: this.documentTypes?.find(documentType => documentType?.nome == 'CTE')?.id
    }, {emitEvent: false});
    // this.updateDocument();
    this.onChangeDocumentType.emit('CTE');
    this.form.markAllAsTouched();
  }

  public setValuesFromNFXML(xml: any): void{
    this.setMaskForReceivedDocument('NF');
    this.form.patchValue({
      tipoDocumento: 'NF',
      numeroDocumento: this.maskForDocumentNumber?.length ?
        this.maskPipe.transform(xml.nfeProc.NFe.infNFe.ide.nNF._text, this.maskForDocumentNumber) :
        xml.nfeProc.NFe.infNFe.ide.nNF._text,
      numeroCE: '',
      chaveNotaFiscal: xml.nfeProc.protNFe.infProt.chNFe._text,
      id: this.documentTypes?.find(documentType => documentType?.nome == 'NF')?.id
    }, {emitEvent: false});
    // this.updateDocument();
    this.onChangeDocumentType.emit('NF');
    this.form.markAllAsTouched();
  }

  public getDocumentFromFormFields(): DocumentoEstimativaCalculo{
    let documento = this.form.getRawValue() as DocumentoEstimativaCalculo;
    documento.numeroDocumento = this.verificacaoDocumentosService.eraseMask(documento.numeroDocumento);
    documento.numeroCE = this.verificacaoDocumentosService.eraseMask(documento.numeroCE ?? '');
    if(documento.tipoDocumento === 'DAT'){
      documento.duEs = this.getNumerosDUE();
    }
    return documento;
  }

  public isDocumentoValid(): boolean{
    return !this.form?.invalid;
  }

  public addNumeroDUE(): void{
    this.numerosDUE?.push();
    this.changeDetector.detectChanges();
  }

  public removeNumeroDUE(index: number): void{
    let numerosDUESalvos = this.getNumerosDUE();
    numerosDUESalvos.splice(index, 1);
    this.numerosDUE = [...numerosDUESalvos];
    this.changeDetector.detectChanges();
    this.form.updateValueAndValidity();
  }

  public getNumerosDUE(): NumeroDue[]{
    return this.camposNumeroDUE?.map(
      (campoNumeroDUE) => ({numeroDUE: campoNumeroDUE.getNumeroDUE()})
    ) ?? [];
  }

  public areAllNumerosDUEValid(): boolean{
    return this.camposNumeroDUE?.map(
      (campoNumeroDUE) => campoNumeroDUE.isNumeroDUEValid()
    )?.every(validDUE => validDUE);
  }

  private validateNumeroDUE(group: UntypedFormGroup): {invalidDUE: boolean} | null{
    if(group.value.tipoDocumento !== 'DAT'){
      return null;
    }
    if(this.areAllNumerosDUEValid()){
      return null;
    }
    return {invalidDUE: true};
  }

  public get isXmlUploadBlocked(): boolean{
    let tipoDocumento: string = this.form.get('tipoDocumento')?.value ?? '';
    if(!this.documentTypesForUploadingXml.includes(tipoDocumento)){
      return true;
    }
    if(tipoDocumento === 'CTE' && this.form.get('numeroCE')?.invalid){
      return true;
    }
    if(tipoDocumento === 'NF' && this.form.get('chaveNotaFiscal')?.invalid){
      return true;
    }
    return false;
  }

  private validateIfNumeroCEIsPresentForCTE(group: UntypedFormGroup): {invalidNumeroCE: boolean} | null{
    if(!group){
      return null;
    }
    let tipoDocumento = group.get('tipoDocumento')?.value ?? '';
    if(!tipoDocumento?.length){
      return null;
    }
    if(tipoDocumento !== 'CTE'){
      return null;
    }
    let numeroCE = group.get('numeroCE')?.value ?? '';
    if(!numeroCE?.length){
      return {invalidNumeroCE: true};
    }
    return null;
  }

  public getMaskFromSelectedDocumentType(selectedDocumentType: string): Mascara{
    return {
      modelo: this.maskForDocumentNumber,
      caracteresEspeciais: this.specialCharsForMask
    }
  }
}
