<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Anexos</span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}"
        data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false"
        aria-label="Toggle navigation"></i>
    </div>
  </div>
  <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <div class="p-3 file-component">
      <div class="row">
        <div class="col">
          <label>Planilha:</label>
          <div class="input-group mb-3">
            <label class='arquivo' for='arquivoExcel'>
              <img src="assets/images/Vector.png" alt="">
              ENVIAR ARQUIVO
            </label>
            <input type="file" [accept]="allowedFileExtensions" id="arquivoExcel" (change)="checkExcelFileToSend($event)">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
