<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Nota Fiscal (NFe)</span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
            <!--fa fa-chevron-down-->
        </div>
    </div>

    <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form *ngIf="form" [formGroup]="form">
            <div class="p-3">
                <div class="row mb-3">
                    <div class="col">
                        <label>Chave da NFe: *</label>
                        <input formControlName="chaveNFE" type="text" (input)="validarCaracteres($event)" class="form-control" placeholder="Digite aqui..." onkeypress="return event.charCode >= 48 && event.charCode <= 57" aria-label="Digite aqui..." aria-describedby="basic-addon2"
                            maxlength="44" (change)="validateNotaFiscal()" [ngClass]="{'border border-danger': form.get('chaveNFE')?.invalid && !form.get('chaveNFE')?.pristine}">
                        <div *ngIf="form.get('chaveNFE')?.errors && !form.get('chaveNFE')?.pristine">
                            <p class="text-danger" *ngIf="form.get('chaveNFE')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                            <p class="text-danger" *ngIf="form.get('chaveNFE')?.errors?.['minlength']">
                                Preencher este campo com 44 caracteres!
                            </p>
                        </div>
                    </div>
                    <div class="col">
                        <label>Contêiner: *</label>
                        <div class="input-group">
                            <input formControlName="numeroConteiner" class="form-control" placeholder="Digite aqui..." [ngClass]="{'border border-danger': form.get('numeroConteiner')?.invalid && !form.get('numeroConteiner')?.pristine}">
                            <button class="action-primary-button" [disabled]="form.invalid || notaFiscalInvalida || conteierInvalido" (click)="searchInvoice()">PESQUISAR</button>
                        </div>
                        <div *ngIf="form.get('numeroConteiner')?.errors && !form.get('numeroConteiner')?.pristine">
                            <p class="text-danger" *ngIf="form.get('numeroConteiner')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
