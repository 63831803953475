<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Relação de Contêineres</span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
            <!--fa fa-chevron-down-->
        </div>
    </div>

    <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row mb-3">
                    <div class="col">
                        <label>Navio: *</label>
                        <input formControlName="navio" type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2" maxlength="44" [ngClass]="{'border border-danger': form.get('navio')?.errors && !form.get('navio')?.pristine}">
                        <div *ngIf="form.get('navio')?.errors && !form.get('navio')?.pristine">
                            <p class="text-danger" *ngIf="form.get('navio')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                        </div>
                    </div>
                    <div class="col">
                        <label>Viagem: *</label>
                        <div class="input-group">
                            <input formControlName="viagem" class="form-control" placeholder="Digite aqui..." [ngClass]="{'border border-danger': form.get('viagem')?.errors && !form.get('viagem')?.pristine}">
                            <button (click)=null class="action-primary-button" [disabled]="form.invalid" (click)="searchConteinerData()">PESQUISAR</button>
                        </div>
                        <div *ngIf="form.get('viagem')?.errors && !form.get('viagem')?.pristine">
                            <p class="text-danger" *ngIf="form.get('viagem')?.errors?.['required']">
                                Campo de preenchimento obrigatório!
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col table-container my-3" id="cardCollapse" [collapse]="isCollapsed" [isAnimated]="true">
                        <div class="table-container">
                            <table class="table table-bordered text-nowrap" *ngIf="form.get('conteineres')?.value?.length">
                                <thead class="thead-light">
                                    <tr>
                                        <th>
                                            <input class="form-chek-input" type="checkbox" value="" id="incluir" [checked]="areAllItemsSelected" (click)="switchSelectionForAll()" >
                                        </th>
                                        <th>Contêiner</th>
                                        <th>Peso Bruto</th>
                                        <th>Lacre(s)</th>
                                        <th>CNPJ Responsável</th>
                                        <th>Nome Armador</th>
                                        <th>Nº da DUE</th>
                                        <th>Retorno da API CCT</th>
                                        <th>Data do Envio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let relacaoConteiner of form.get('conteineres')?.value; let i = index">
                                        <td>
                                            <div class="input-group" id="check1">
                                                <input class="form-chek-input" type="checkbox" value="" (click)="switchSingleSelection(i)" [checked]="selectedContainers[i]" [disabled]="(relacaoConteiner.retornoApiCct !== null)">
                                            </div>
                                        </td>
                                        <td> {{ relacaoConteiner.conteiner }} </td>
                                        <td> {{ relacaoConteiner.peso }} </td>
                                        <td> {{ relacaoConteiner.lacres }}</td>
                                        <td> {{ relacaoConteiner.cnpjReponsavel }} </td>
                                        <td> {{ relacaoConteiner.nomeArmador }} </td>
                                        <td> {{ relacaoConteiner.numeroDUE }} </td>
                                        <td> {{ relacaoConteiner.retornoApiCct }} </td>
                                        <td> {{ relacaoConteiner.dataEnvio | date: 'dd/MM/yyyy HH:mm:ss'}} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div><br>
                <div class="row">
                    <div class="col">
                        <label>Quantidade Contêiner(es): </label>
                        <input readonly formControlName="qtdConteineres" type="number" class="form-control" aria-describedby="basic-addon2">
                    </div>
                    <div class="col">
                        <label>Quantidade Contêiner(es) Enviado(s): </label>
                        <div class="input-group mb-3">
                            <input readonly formControlName="qtdConteineresEnviados" type="number" class="form-control">
                        </div>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-6">
                        <label>Quantidade de Erros Encontrados: </label>
                        <input readonly formControlName="qtdConteineresErrosEnviados" type="number" class="form-control" aria-describedby="basic-addon2">
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="action-button-group action-double-buttons mt-4 w-75 ml-auto mr-auto">
    <button class="action-secondary-button" (click)="clear()">LIMPAR DADOS</button>
    <button class="action-primary-button" [auth]="this.enviarCCT" [disabled]="!isEnviarCCTButtonEnabled" (click)="sendDataToCCT()">ENVIAR AO CCT</button>
    <!-- <button class="action-primary-button" [auth]="this.salvar" [disabled]="!isSalvarButtonEnabled" (click)="saveEntregaDUE()">SALVAR</button> -->
</div>
<app-modal-resposta-positiva-negativa [modalTitle]="'Divergências encontradas'" [negativeText]="'Recusar'" [positiveText]="'Confirmar'">
    <div class="row mb-3">
        <div class="col">
            <p>“Foi(ram) encontrada(s) divergência(s) dos Dados de Transporte entre N4 x CCT. Confirma envio com dados do físico?”</p>
        </div>
    </div>
</app-modal-resposta-positiva-negativa>
