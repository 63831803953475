import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UpdateService } from 'src/app/services/update-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  public loginForm: UntypedFormGroup;
  public haveUpdateStatus: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    public updateService: UpdateService
    ) {
    this.loginForm = this.formBuilder.group({
      matricula: new UntypedFormControl({ value: '', disabled: false }),
      senha: new UntypedFormControl({ value: '', disabled: false }),

    });
  }

  ngOnInit() {
    this.updateService.haveUpdateStatus.subscribe(async status => {
      this.haveUpdateStatus = status;
    });
    if (this.authService.isAuthenticated())
      this.router.navigate(['home']);
  }


  async signin() {
    var success = await this.authService.signin(this.loginForm.value['matricula'].toString(), this.loginForm.value['senha'].toString())
    if (success)
      this.router.navigate(['home'])

  }
}
