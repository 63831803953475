import { ModalidadeDTA } from 'src/app/models/ModalidadeDTA';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Permissoes } from 'src/app/contants/permissoes';
import { AuthRoles } from 'src/app/models/authRoles';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { ConfirmacaoLiberacaoDocumentalDTO } from 'src/app/models/LiberacaoDocumental/ConfirmacaoLiberacaoDocumentalDTO';
import { PesquisaExpansaoDocumentoParte } from 'src/app/models/LiberacaoDocumental/PesquisaExpansaoDocumentoParte';
import { SolicitacaoDTO } from 'src/app/models/LiberacaoDocumental/SolicitacaoDTO';
import { SolicitacaoLiberacaoDTO } from 'src/app/models/LiberacaoDocumental/SolicitacaoLiberacaoDTO';
import { LiberacaoDocumentalService } from 'src/app/services/liberacao-documental.service';
import { ToastService } from 'src/app/services/toast.service';
import {
  ModalCancelarLiberacaoComponent,
} from 'src/app/shared/components/modais/modal-cancelar-liberacao/modal-cancelar-liberacao.component';
import {
  ModalRecusaLiberacaoComponent,
} from 'src/app/shared/components/modais/modal-recusa-liberacao/modal-recusa-liberacao.component';
import {
  ModalRecusaAutorizacaoSupervisorComponent,
} from 'src/app/shared/components/modais/modal-recusaAutorizacao-supervisor/modal-recusaAutorizacao-supervisor.component';
import {
  ModalRespostaPositivaNegativaComponent,
} from 'src/app/shared/components/modais/modal-resposta-positiva-negativa/modal-resposta-positiva-negativa.component';

import { StatusSolicitacaoLiberacao } from '../../../models/LiberacaoDocumental/StatusSolicitacaoLiberacao.enum';
import { ModalMensagemComponent } from '../../modais/modal-mensagem/modal-mensagem.component';
import {
  VisualizarSolicitacoesCabotagemComponent,
} from '../../modais/visualizar-solicitacoes-cabotagem/visualizar-solicitacoes-cabotagem.component';
import {
  VisualizarSolicitacoesExpComponent,
} from '../../modais/visualizar-solicitacoes-exp/visualizar-solicitacoes-exp.component';
import {
  VisualizarSolicitacoesImpComponent,
} from '../../modais/visualizar-solicitacoes-imp/visualizar-solicitacoes-imp.component';
import { AuthenticationService } from './../../../services/authentication/authentication.service';
import {
  ModalConfirmarLiberacaoComponent,
} from './../../../shared/components/modais/modal-confirmar-liberacao/modal-confirmar-liberacao.component';
import { VerificacaoDocumentosService } from './../../../shared/utils/verificacao-documentos.service';
import { ConsultaBloqueioComponent } from './../consulta-de-bloqueio/consulta-de-bloqueio.component';
import { Moeda } from 'src/app/models/Moeda';
import { Canal } from 'src/app/models/Canal';
import { Modalidade } from 'src/app/models/Modalidade';
import { DocumentosService } from 'src/app/services/documentos.service';

@Component({
  selector: 'app-grid-solicitacoes-liberacao-documental',
  templateUrl: './grid-solicitacoes-liberacao-documental.component.html',
  styleUrls: ['./grid-solicitacoes-liberacao-documental.component.scss']
})
export class GridSolicitacoesLiberacaoDocumentalComponent implements OnInit {

  @Input() public solicitations!: SolicitacaoDTO[];
  @Input() public AuthRole!: AuthRoles;
  @Output() public onConfirmActionForSearching: EventEmitter<void> = new EventEmitter<void>();

  public isCollapsed: boolean = false;
  public confirmacaoUsuario!: ConfirmacaoLiberacaoDocumentalDTO;
  public solicitacaoSalva!: SolicitacaoDTO | undefined;
  public title: string = 'Entrega por Base Documento de Transporte';
  public ImpedimentosLiberacao: string[] = [];
  public justificativaSalva?: string;
  public modalRef!: BsModalRef;
  public selectedRowId?: number;
  public textForConfirmation: string = '';
  public ehUsuarioInterno :boolean= false;
  private readonly statusForDTCLiberation: string[] = [
    StatusSolicitacaoLiberacao.AG_AUTOR_SUPERVISOR,
    StatusSolicitacaoLiberacao.AUTORIZADO_SUPERVISOR
  ];
  private readonly statusToDisableRecusaAndRecusaSupervisor: string[] = [
    StatusSolicitacaoLiberacao.RASCUNHO,
    StatusSolicitacaoLiberacao.RECUSADO,
    StatusSolicitacaoLiberacao.RECUSADO_SUPERVISOR,
  ];


  protected moedas: Moeda[] = [];
  protected canais: Canal[] = [];
  protected modalidades: Modalidade[] = [];
  protected modalidadesDTA: ModalidadeDTA[] = [];

  @ViewChild("visualizarSolicitacoesImp",{static: false}) visualizarSolicitacoesImp!: VisualizarSolicitacoesImpComponent;
  @ViewChild("visualizarSolicitacoesExp",{static: false}) visualizarSolicitacoesExp!: VisualizarSolicitacoesExpComponent;
  @ViewChild("visualizarSolicitacoesCabotagem",{static: false}) visualizarSolicitacoesCabotagem!: VisualizarSolicitacoesCabotagemComponent;
  @ViewChild("modalConsultaBloqueio",{static: false}) modalConsultaBloqueio!: ConsultaBloqueioComponent;
  @ViewChild("modalConfirmarLiberacaoJustificativa") modalConfirmarLiberacaoJustificativa!: ModalConfirmarLiberacaoComponent;
  @ViewChild("modalConfirmarLiberacaoAlternativa") modalConfirmarLiberacaoAlternativa!: ModalRespostaPositivaNegativaComponent;
  @ViewChild("modalConfirmarRecusa") modalConfirmarRecusa!: ModalRecusaLiberacaoComponent;
  @ViewChild("modalRecusaSupervisor") modalRecusaSupervisor!: ModalRecusaAutorizacaoSupervisorComponent;
  @ViewChild("modalAutorizacaoSupervisor") modalAutorizacaoSupervisor!: ModalRecusaAutorizacaoSupervisorComponent;
  @ViewChild("modalVisualizarJustificativa") modalVisualizarJustificativa!: TemplateRef<any>;
  @ViewChild("modalCancelarLiberacao") modalCancelarLiberacao!: ModalCancelarLiberacaoComponent;
  @ViewChild("modalErroIntegracao") modalErroIntegracao!: ModalMensagemComponent;


  public readonly visualizacao = new AuthRoles(this.AuthRole?.tela, Permissoes.visualizacao);
  public readonly liberacao = new AuthRoles(this.AuthRole?.tela, Permissoes.liberar)
  public readonly recusar = new AuthRoles(this.AuthRole?.tela, Permissoes.recusar)
  public readonly cancelar = new AuthRoles(this.AuthRole?.tela, Permissoes.cancelar)
  public readonly autorizacaoSurpevisor = new AuthRoles(this.AuthRole?.tela, Permissoes.autorizacaoSurpervisor)
  public readonly recusaSurpervisor = new AuthRoles(this.AuthRole?.tela, Permissoes.recusaSurpervisor)
  public readonly consultaBloqueio = new AuthRoles(this.AuthRole?.tela, Permissoes.consultaBloqueio)

  constructor(
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private liberacaoDocumentalService : LiberacaoDocumentalService,
    private spinner: NgxSpinnerService,
    private toastService:ToastService,
    private changeDetector: ChangeDetectorRef,
    private modalService: BsModalService,
    private authService: AuthenticationService,
    private documentosService: DocumentosService
  ) { }

  public ngOnInit(): void {
    this.ehUsuarioInterno = this.authService.ehUsuarioInterno;
    this.visualizacao.tela = this.AuthRole?.tela;
    this.liberacao.tela = this.AuthRole?.tela;
    this.recusar.tela = this.AuthRole?.tela;
    this.cancelar.tela = this.AuthRole?.tela;
    this.autorizacaoSurpevisor.tela = this.AuthRole?.tela;
    this.recusaSurpervisor.tela = this.AuthRole?.tela;
    this.consultaBloqueio.tela = this.AuthRole?.tela;
    this.getSelectFieldsForViewModals();
  }

  public setMaskForPersonDocument(document: string | undefined): string{
    if(!document){
      return '';
    }
    if(document.length == 11){
      return this.verificacaoDocumentosService.setMaskForCPF(document);
    }
    if(document.length == 14){
      return this.verificacaoDocumentosService.setMaskForCNPJ(document);
    }
    return document;
  }

  public shortenTextForJustificationDisplay(solicitation: SolicitacaoDTO): string{
    let justification = this.getJustificationText(solicitation) ?? '';
    if(!justification?.length){
      return '';
    }
    let shortenedText = justification.substring(0, 25);
    if(justification.length > 25){
      shortenedText += '...';
    }
    return shortenedText;
  }

  public getJustificationText(solicitation: SolicitacaoDTO): string{
    if(solicitation?.status == StatusSolicitacaoLiberacao.PENDENTE){
      if(solicitation?.propriedadesGridLiberacao?.justificativaRecusa?.length){
        return solicitation?.propriedadesGridLiberacao?.justificativaRecusa
      }else if(solicitation?.propriedadesGridLiberacao?.justificativaRecusaSupervisor?.length){
        return solicitation?.propriedadesGridLiberacao?.justificativaRecusaSupervisor
      }else
      return '';
    }
    if(solicitation?.status == StatusSolicitacaoLiberacao.AUTORIZADO_SUPERVISOR){
      return solicitation?.propriedadesGridLiberacao?.justificativaAutorizacaoSupervisor?.length ? solicitation?.propriedadesGridLiberacao?.justificativaAutorizacaoSupervisor : '';
    }
    return '';
  }

  public visualizar(solicitation: SolicitacaoDTO): void{
    if(solicitation?.regimeCirculacao?.nomeRegimeCirculacao.toLocaleLowerCase() == 'Imp'.toLocaleLowerCase()){
      const modalRef = this.modalService.show(VisualizarSolicitacoesImpComponent, {
        class: 'modal-xl',
        ignoreBackdropClick: true
      });
      const modalComponent = modalRef.content as VisualizarSolicitacoesImpComponent;
      modalComponent.setSelectOptions(this.moedas, this.canais, this.modalidades, this.modalidadesDTA);
      modalComponent.openModal(solicitation);
      modalComponent.onExpandPartDocument.subscribe((event) => this.expandPartDocument(event));
      modalComponent.onCloseModal.subscribe((event) => modalRef.hide());
      modalComponent.onSaveSolicitation.subscribe(() => {
        modalRef.hide();
        this.onConfirmActionForSearching.emit();
      });
    }
    if(solicitation?.regimeCirculacao?.nomeRegimeCirculacao.toLocaleLowerCase() == 'Exp'.toLocaleLowerCase()){
      const modalRef = this.modalService.show(VisualizarSolicitacoesExpComponent, {
        class: 'modal-xl',
        ignoreBackdropClick: true
      });
      const modalComponent = modalRef.content as VisualizarSolicitacoesExpComponent;
      modalComponent.setSelectOptions(this.moedas, this.canais);
      modalComponent.openModal(solicitation);
      modalComponent.onExpandPartDocument.subscribe((event) => this.expandPartDocument(event));
      modalComponent.onCloseModal.subscribe((event) => modalRef.hide());
      modalComponent.onSaveSolicitation.subscribe(() => {
        modalRef.hide();
        this.onConfirmActionForSearching.emit();
      });
    }
    if(solicitation?.regimeCirculacao?.nomeRegimeCirculacao.toLocaleLowerCase() == 'Cabotagem'.toLocaleLowerCase()){
      const modalRef = this.modalService.show(VisualizarSolicitacoesCabotagemComponent, {
        class: 'modal-xl',
        ignoreBackdropClick: true
      });
      const modalComponent = modalRef.content as VisualizarSolicitacoesCabotagemComponent;
      modalComponent.setSelectOptions(this.moedas);
      modalComponent.openModal(solicitation);
      modalComponent.onExpandPartDocument.subscribe((event) => this.expandPartDocument(event));
      modalComponent.onCloseModal.subscribe((event) => modalRef.hide());
      modalComponent.onSaveSolicitation.subscribe(() => {
        modalRef.hide();
        this.onConfirmActionForSearching.emit();
      });
    }
  }

  public trClick(id?: number): void{
    this.selectedRowId = id
  }

  public abrirConsultaBloqueio(solicitation: SolicitacaoDTO): void{
    this.modalConsultaBloqueio.openModal(solicitation);
  }

  public EndpointRecusa(motivo: string){
    this.spinner.show();
    this.liberacaoDocumentalService.RecusaDocumentaryLiberation(this.solicitacaoSalva as SolicitacaoDTO, motivo)?.subscribe({
      next: (result) => {
        this.spinner.hide();
        result?.message?.forEach(
          (message) => {
            if(message?.length){
              this.toastService.success(message);
            }
          }
        );
        this.onConfirmActionForSearching.emit();
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              this.toastService.error(error);
            }
          );
        }
      },
      complete: () => {
        this.solicitacaoSalva = undefined;
      }
    });
  }

  public Recusar(solicitation: SolicitacaoDTO): void{
    this.spinner.show();
    this.solicitacaoSalva = solicitation;
    this.ImpedimentosLiberacao = [];
    this.liberacaoDocumentalService.getLiberationImpediments(this.solicitacaoSalva)
      .subscribe({
        next: (result) => {
          this.ImpedimentosLiberacao = result.data ?? [];
          result?.message?.forEach(
            (message) => {
              if(message?.length){
                this.toastService.success(message);
              }
            }
          );
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorResponse = err.error as ErrorResponse;
          (errorResponse)?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        },
        complete: () => {
          this.spinner.hide();
          this.modalConfirmarRecusa.openModalForConfirmOrRefuse();
        }
      });
  }
  public Cancelar(solicitation: SolicitacaoDTO): void{
    this.solicitacaoSalva = solicitation;
    this.modalCancelarLiberacao.openModalForConfirmOrRefuse();
  }

  public CancelarEndpoint(justificativa: string): void{
    this.spinner.show();
    this.solicitacaoSalva as SolicitacaoDTO;
    if(this.solicitacaoSalva?.status?.toUpperCase() != StatusSolicitacaoLiberacao.LIBERADO.toUpperCase()){
      return;
    }
    this.liberacaoDocumentalService.CancelDocumentaryLiberation(this.solicitacaoSalva as SolicitacaoDTO, justificativa)?.subscribe({
      next: (result) => {
        this.spinner.hide();
        result?.message?.forEach(
          (message) => {
            if(message?.length){
              this.toastService.success(message);
            }
          }
        );
        this.onConfirmActionForSearching.emit();
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorResponse = err.error as ErrorResponse;
        (errorResponse)?.errors?.forEach(
          (error) => {
            if(error?.length){
              this.toastService.error(error);
            }
          }
        );
      }
    });
  }

  RecusarSupervisorEndpoint(justificativa:string){
    this.spinner.show();
    this.liberacaoDocumentalService.RecusaSupervisor(this.solicitacaoSalva as SolicitacaoDTO, justificativa)?.subscribe({
      next: (result) => {
        this.spinner.hide();
        result.message.forEach(
          (message) => {
            if(message?.length){
              this.toastService.success(message);
            }
          }
        );
        this.onConfirmActionForSearching.emit();
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorResponse = err.error as ErrorResponse;
        (errorResponse)?.errors?.forEach(
          (error) => {
            if(error?.length){
              this.toastService.error(error);
            }
          }
        );
      }
    });
  }

  public RecusaSupervisor(solicitation: SolicitacaoDTO): void{
    this.solicitacaoSalva = solicitation;
    this.modalRecusaSupervisor.openModalForConfirmOrRefuse();
  }

  public AutorizarSupervisor(solicitation: SolicitacaoDTO): void{
    this.solicitacaoSalva = solicitation;
    this.modalAutorizacaoSupervisor.openModalForConfirmOrRefuse();
  }

  public AutorizarSupervisorEndpoint(justificativa:string): void{
    this.spinner.show();
    this.liberacaoDocumentalService.AutorizationSupervisor(this.solicitacaoSalva as SolicitacaoDTO, justificativa)?.subscribe({
      next: (result) => {
        this.spinner.hide();
        result.message.forEach(
          (message) => {
            if(message?.length){
              this.toastService.success(message);
            }
          }
        );
        this.onConfirmActionForSearching.emit();
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorResponse = err.error as ErrorResponse;
        (errorResponse)?.errors?.forEach(
          (error) => {
            if(error?.length){
              this.toastService.error(error);
            }
          }
        );
      }
    });
  }

  public liberar(solicitation: SolicitacaoDTO): void{
    if(!this.isLiberarButtonEnabled(solicitation)){
      return;
    }
    this.spinner.show();
    let confirmacao: ConfirmacaoLiberacaoDocumentalDTO = {
      necessitaConfirmacao: false,
      mensagem: '',
      confirmacaoUsuario: false,
      tipoConfirmacao: 0
    }
    this.liberacaoDocumentalService.DocumentaryLiberation(solicitation, confirmacao)?.subscribe({
      next: (result) => {
        this.spinner.hide();
        result?.message?.forEach(
          (message) => {
            if(message?.length){
              this.toastService.success(message);
            }
          }
        );
        let confirmacao = result.data as ConfirmacaoLiberacaoDocumentalDTO;
        if(confirmacao){
          this.confirmacaoUsuario = confirmacao;
          if(confirmacao.necessitaConfirmacao){
            this.solicitacaoSalva = solicitation;
            this.changeDetector.detectChanges();
            if(confirmacao.tipoConfirmacao === 0){
              this.modalConfirmarLiberacaoJustificativa.openModalForLiberationConfirm(this.confirmacaoUsuario);
            }
            else{
              this.textForConfirmation = confirmacao.mensagem ?? '';
              this.modalConfirmarLiberacaoAlternativa.openModalForConfirmOrRefuse();
            }
          }
          else{
            this.onConfirmActionForSearching.emit();
          }
        }
        else{
          this.onConfirmActionForSearching.emit();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorResponse = err.error as ErrorResponse;
        (errorResponse)?.errors?.forEach(
          (error) => {
            if(error?.length){
              this.toastService.error(error);
              this.ImpedimentosLiberacao.push(error)
            }
          }
        );
        let confirmacao = errorResponse?.data as ConfirmacaoLiberacaoDocumentalDTO;
        if(confirmacao){
          console.log(confirmacao)
          this.confirmacaoUsuario = confirmacao;
          if(confirmacao.necessitaConfirmacao){
            this.solicitacaoSalva = solicitation;
            this.changeDetector.detectChanges();
            if(confirmacao.tipoConfirmacao === 0){
              this.modalConfirmarLiberacaoJustificativa.openModalForLiberationConfirm(this.confirmacaoUsuario);
            }
            else{
              this.textForConfirmation = confirmacao.mensagem ?? '';
              this.modalConfirmarLiberacaoAlternativa.openModalForConfirmOrRefuse();
            }
          }
        }
      }
    });
  }

  public confirmarLiberacao(justificativa: string): void{
    this.spinner.show();
    this.confirmacaoUsuario.confirmacaoUsuario = true;
    this.liberacaoDocumentalService.DocumentaryLiberation(this.solicitacaoSalva as SolicitacaoDTO, this.confirmacaoUsuario, justificativa)?.subscribe({
      next: (result) => {
        this.spinner.hide();
        result?.message?.forEach(
          (message) => {
            if(message?.length){
              this.toastService.success(message);
            }
          }
        );
        this.onConfirmActionForSearching.emit();
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorResponse = err.error as ErrorResponse;
        (errorResponse)?.errors?.forEach(
          (error) => {
            if(error?.length){
              this.toastService.error(error);
            }
          }
        );
      }
    });
  }

  public openModalForJustificationDisplay(solicitation: SolicitacaoDTO): void{
    let justification = this.getJustificationText(solicitation) ?? '';
    if(!justification?.length){
      return;
    }
    this.justificativaSalva = justification;
    this.ImpedimentosLiberacao = [];
    this.spinner.show();
    this.liberacaoDocumentalService.getLiberationImpedimentsForJustificationDisplay(solicitation)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          this.ImpedimentosLiberacao = result.data as string[] ?? [];
        },
        error: (err) => {
          this.spinner.hide();
          console.log(err);
          let errorResponse = err.error as ErrorResponse;
          (errorResponse)?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        },
        complete: () => {
          this.modalRef = this.modalService.show(this.modalVisualizarJustificativa, {
            class: 'modal-md'
          });
          this.modalRef.onHidden?.subscribe(
            () => {
              this.justificativaSalva = '';
              this.ImpedimentosLiberacao = [];
            }
          );
        }
      });
  }

  public closeModal(): void{
    this.modalService.hide();
    this.justificativaSalva = '';
  }

  public isLiberarButtonEnabled(solicitation: SolicitacaoDTO): boolean{
    if((solicitation?.status?.toUpperCase() == StatusSolicitacaoLiberacao.PENDENTE) ||
    (solicitation?.status?.toUpperCase() == StatusSolicitacaoLiberacao.CANCELADO) ){
      return true;
    }
    if(solicitation?.documento?.tipoDocumento == 'DTC'){
      if(this.statusForDTCLiberation.find(status => status.toUpperCase() == solicitation?.status?.toUpperCase())){
        return true;
      }
    }
    return false;
  }

  public isConsultaDeBloqueioButtonEnabled(solicitation: SolicitacaoDTO): boolean{
    if(solicitation?.status?.toUpperCase() == StatusSolicitacaoLiberacao.RASCUNHO){
      return false;
    }
    if(solicitation?.status?.toUpperCase() == StatusSolicitacaoLiberacao.RECUSADO){
      return false;
    }
    if(solicitation?.status?.toUpperCase() == StatusSolicitacaoLiberacao.RECUSADO_SUPERVISOR){
      return false;
    }
    return true;
  }

  public isRecusarButtonEnabled(solicitation: SolicitacaoDTO): boolean{
    if(this.statusToDisableRecusaAndRecusaSupervisor.includes(solicitation?.status?.toUpperCase()!)){
      return false;
    }
    return true;
  }

  public isCancelarLiberacaoButtonEnabled(solicitation: SolicitacaoDTO): boolean{
    if(solicitation?.status?.toUpperCase() == StatusSolicitacaoLiberacao.LIBERADO){
      return true;
    }
    return false;
  }

  public isAutorizacaoSupervisorButtonEnabled(solicitation: SolicitacaoDTO): boolean{
    if(solicitation?.status?.toUpperCase() == StatusSolicitacaoLiberacao.RASCUNHO){
      return false;
    }
    if(solicitation?.status?.toUpperCase() == StatusSolicitacaoLiberacao.RECUSADO){
      return false;
    }
    if(solicitation?.status?.toUpperCase() == StatusSolicitacaoLiberacao.RECUSADO_SUPERVISOR){
      return false;
    }
    return true;
  }

  public isRecusaSupervisorButtonEnabled(solicitation: SolicitacaoDTO): boolean{
    if(this.statusToDisableRecusaAndRecusaSupervisor.includes(solicitation?.status?.toUpperCase()!)){
      return false;
    }
    return true;
  }

  public displayIntegrationError(message: string): void{
    if(message?.length){
      this.modalErroIntegracao?.displaySingleMessage(message);
    }
  }

  protected expandPartDocument(documentoParte: PesquisaExpansaoDocumentoParte): void{
    // let solicitacaoParte = this.solicitations?.find((solicitation) =>
    //   solicitation.documento?.numeroDocumento === documentoParte.numeroDocumento &&
    //   solicitation.relacaoConteineres?.find((conteiner) => ((conteiner.conteiner ?? '') === documentoParte.numeroConteiner) || ((conteiner.numeroConteiner ?? '') === documentoParte.numeroConteiner))
    // );
    // if(!solicitacaoParte){
    //   return;
    // }
    // this.visualizarSolicitacoesImp?.closeModal();
    // this.visualizarSolicitacoesExp?.closeModal();
    // this.visualizarSolicitacoesCabotagem?.closeModal();
    // this.changeDetector.detectChanges();
    // this.visualizar(solicitacaoParte);
    this.spinner.show();
    this.liberacaoDocumentalService.searchPartDocumentToExpand(documentoParte)
      .subscribe({
        next: (result) => {
          let solicitations: SolicitacaoLiberacaoDTO = result.data;
          solicitations.solicitacaoLiberacaoImpDTO = solicitations?.solicitacaoLiberacaoImpDTO?.length ? solicitations?.solicitacaoLiberacaoImpDTO : [];
          solicitations.solicitacaoLiberacaoExpDTO = solicitations?.solicitacaoLiberacaoExpDTO?.length ? solicitations?.solicitacaoLiberacaoExpDTO : [];
          solicitations.solicitacaoLiberacaoCabotagemDTO = solicitations?.solicitacaoLiberacaoCabotagemDTO?.length ? solicitations?.solicitacaoLiberacaoCabotagemDTO : [];
          let filteredSolicitations: SolicitacaoDTO[] = [
            ...solicitations?.solicitacaoLiberacaoImpDTO,
            ...solicitations?.solicitacaoLiberacaoExpDTO,
            ...solicitations?.solicitacaoLiberacaoCabotagemDTO
          ];
          let solicitacaoParte = filteredSolicitations?.find((solicitation) =>
            solicitation.documento?.numeroDocumento === documentoParte.numeroDocumento &&
            solicitation.relacaoConteineres?.find((conteiner) => ((conteiner.conteiner ?? '') === documentoParte.numeroConteiner) || ((conteiner.numeroConteiner ?? '') === documentoParte.numeroConteiner))
          );
          this.spinner.hide();
          if(!solicitacaoParte){
            this.toastService.error('Solicitação parte não encontrada!');
            return;
          }
          this.visualizarSolicitacoesImp?.closeModal();
          this.visualizarSolicitacoesExp?.closeModal();
          this.visualizarSolicitacoesCabotagem?.closeModal();
          this.visualizar(solicitacaoParte);
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      })
  }

  private getSelectFieldsForViewModals(): void{
    //Combobox canal
    this.documentosService.getCanais().subscribe({
      next: (canais) =>{
        this.canais = canais;
        this.changeDetector.detectChanges();
      }
    });
    //Combobox moeda
    this.documentosService.getMoedas().subscribe({
      next: (moedas) =>{
        this.moedas = moedas;
        this.changeDetector.detectChanges();
      }
    });
    //Combobox modalidade
   this.documentosService.getModalidades().subscribe({
    next: (modalidade) => {
      this.modalidades = modalidade;
      this.changeDetector.detectChanges();
    }
    });
    //Combobox modalidadeDTA
    this.documentosService.getModalidadesDTA().subscribe({
      next: (modalidadesDTA) => {
        this.modalidadesDTA = modalidadesDTA;
        this.changeDetector.detectChanges();
      }
    });
  }
}

