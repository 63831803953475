import { LinhaGridRelacaoConteinerImportacaoComponent } from './../linha-grid-relacao-conteiner-importacao/linha-grid-relacao-conteiner-importacao.component';
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterContentInit, Input, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Campo } from 'src/app/models/Campo';
import { ConteinerIMP } from 'src/app/models/SolicitacaoLiberacaoIMP/ConteinerIMP';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { SolicitacaoLiberacaoImpService } from 'src/app/services/solicitacao-liberacao-imp.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast.service';
@Component({
  selector: 'app-relacao-conteiner-importacao',
  templateUrl: './relacao-conteiner-importacao.component.html',
  styleUrls: ['./relacao-conteiner-importacao.component.scss']
})
export class RelacaoConteinerImportacaoComponent implements OnInit, AfterContentInit{
  isCollapsed = false;

  @Input() camposFormulario!: Campo[];
  @Input() visualizar?: boolean = false;
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() isInsideModal?: boolean;
  @Input() blockAddition?: boolean;
  @Input() shouldBlockPartQuantityEditionByDocumentType?: boolean;
  @Output() dataFormOut = new EventEmitter<{}>();
  @Output() onRelacaoConteinerLoad: EventEmitter<void> = new EventEmitter<void>();
  @Output() onUpdateSelectedContainers: EventEmitter<void> = new EventEmitter<void>();
  @Output() onEraseConteiner: EventEmitter<ConteinerIMP> = new EventEmitter<ConteinerIMP>();
  @Output() onSearchShipAndTripByContainerNumber: EventEmitter<string> = new EventEmitter<string>();

  public containerRelationList!: ConteinerIMP[];
  public areAllItemsSelected!: boolean;
  public selectedContainerNumbers!: string[];
  public form!: UntypedFormGroup;
  public relacaoConteinerReadonly: boolean = false;
  public relacaoConteinerRequired: boolean = false;
  public arvoreParteReadonly?: boolean;


  @ViewChildren(LinhaGridRelacaoConteinerImportacaoComponent) containerGrid!: QueryList<LinhaGridRelacaoConteinerImportacaoComponent>;

  constructor(
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private solicitacaoLiberacaoImpService: SolicitacaoLiberacaoImpService,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private changeDetector: ChangeDetectorRef,
    private toastService:ToastService
  ) {}

  public ngAfterContentInit(): void {
    this.onRelacaoConteinerLoad.emit();
  }

  public ngOnInit() {
    this.containerRelationList = [];
    this.areAllItemsSelected = false;
    this.selectedContainerNumbers = [];
    this.dataFormOut.emit({"relacaoConteineres": []});
  }

  public clean(){
    this.containerRelationList = [];
  }

  public setFormValuesFromReceivedContainerRelationList(containerRelation: ConteinerIMP[] | undefined){
    if(!containerRelation){
      return;
    }
    this.containerRelationList = [...containerRelation];
    this.changeDetector.detectChanges();
  }

  public switchSelectAll(): void{
    this.areAllItemsSelected = !this.areAllItemsSelected;
    this.updateSelectedForAll();
  }

  private updateSelectedForAll(): void{
    this.containerGrid?.forEach(
      (container) => {
        container.switchSelectionFromParent(this.areAllItemsSelected);
      }
    );
  }

  public addSelectedContainerNumber(containerNumber: string): void{
    if(this.selectedContainerNumbers.includes(containerNumber)){
      return;
    }
    this.selectedContainerNumbers.push(containerNumber);
  }

  public removeSelectedContainerNumber(containerNumber: string): void{
    if(!this.selectedContainerNumbers.includes(containerNumber)){
      return;
    }
    this.selectedContainerNumbers.splice(this.selectedContainerNumbers.indexOf(containerNumber), 1);
  }

  public getContainerRelationFromForm(): ConteinerIMP[]{
    return this.containerGrid?.map(
      (conteiner) => conteiner.getContainerFromFormFields()
    ) ?? [];
  }

  public getSelectedContainerRelationFromForm(): ConteinerIMP[]{
    return this.containerGrid?.filter(
      (conteiner) => !!conteiner.selecionado?.value
    )?.map(
      (conteiner) => conteiner.getContainerFromFormFields()
    ) ?? [];
  }

  public areAllContainersValid(): boolean{
    if(this.relacaoConteinerRequired && !this.getContainerRelationFromForm()?.length){
      return false;
    }
    if(!this.containerGrid?.length){
      return true;
    }
    return this.containerGrid?.map(
      (conteiner) => conteiner.isContainerValid()
    )?.every(line => line);
  }

  public addContainerInCaseOfNoIntegration(): void{
    if(this.doesSolicitationHaveIntegration){
      return;
    }
    this.containerRelationList.push({
      conteiner: '',
      conteinerTransload: '',
      parte: false,
      quantidadePartes: 0,
      quebraLote: false
    });
    this.changeDetector.detectChanges();
  }

  public updateContainerInfo(container: ConteinerIMP): void{
    if(!container){
      return;
    }
    if(container.selecionado){
      this.addSelectedContainerNumber(container.conteiner);
    }
    else{
      this.removeSelectedContainerNumber(container.conteiner);
    }
    this.deleteNonexistentContainerNumbers();
    this.changeDetector.detectChanges();
    if(!this.getSelectedContainerRelationFromForm()?.length){
      this.selectedContainerNumbers = [];
    }
    this.containerGrid?.forEach(
      (container) => {
        container.switchEdition();
      }
    )
    this.onUpdateSelectedContainers.emit();
    this.changeDetector.detectChanges();
    if(this.doesSolicitationHaveIntegration){
      // this.visualizar = true;
    }
  }

  private deleteNonexistentContainerNumbers(): void{
    let existentcontainerNumbers: string[] = this.getContainerRelationFromForm()
      ?.map((container) => container.conteiner);
    for(let containerIndex: number = 0; containerIndex < this.selectedContainerNumbers?.length ?? 0; containerIndex++){
      if(!existentcontainerNumbers.includes(this.selectedContainerNumbers[containerIndex])){
        this.selectedContainerNumbers.splice(containerIndex, 1);
      }
    }
  }

  public setSettingsfromFields(): void{
    if(!this.containerGrid){
      return
    }
    if(!this.camposFormulario?.length){
      this.containerGrid?.forEach(
        (conteiner) => {
          conteiner.enableAllFormFields();
        }
      );
      return;
    }
    let campoRelacaoConteiner: Campo | undefined = this.camposFormulario?.find(campo => campo.nome == 'relacaoConteiner');
    this.relacaoConteinerReadonly = !!campoRelacaoConteiner?.leitura || !!campoRelacaoConteiner?.bloqueado;
    this.relacaoConteinerRequired = !!campoRelacaoConteiner?.obrigatorio;
    let campoArvoreParte: Campo | undefined = this.camposFormulario?.find(campo => campo.nome == 'arvoreParte');
    this.arvoreParteReadonly = campoArvoreParte ?
      (!!campoArvoreParte?.leitura || !!campoArvoreParte?.bloqueado) :
      false;
    this.changeDetector.detectChanges();
    this.containerGrid?.forEach(
      (conteiner) => {
        conteiner.setSettingsfromFields();
      }
    );
    this.changeDetector.detectChanges();
  }

  public clear(): void{
    this.form.reset();
    this.eraseConteinerRelation();
  }

  public eraseConteinerRelation(): void{
    this.containerRelationList = [];
  }

  public eraseConteinerFromGrid(conteiner: ConteinerIMP): void{
    let index = this.containerGrid?.toArray().findIndex(conteinerGrid => conteinerGrid.conteiner.value == conteiner.conteiner)
    if(index >= 0 ){
      this.containerRelationList.splice(index, 1);
          if(!this.containerRelationList?.length){
            this.areAllItemsSelected = false;
          }
          this.changeDetector.detectChanges();
          this.onUpdateSelectedContainers.emit();
    }
    }

    public sendConteinerToErase(index: number): void{
      if(this.doesSolicitationHaveIntegration){
        return;
      }
      this.onEraseConteiner.emit(this.containerGrid?.get(index)?.getContainerFromFormFields());
    }

    public sendContainerToSearchShipAndTrip(containerNumber: string, index: number): void{
      if(index !== 0){
        return;
      }
      this.onSearchShipAndTripByContainerNumber.emit(containerNumber);
    }


}
