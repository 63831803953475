import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ArquivoDocumentoAnexado } from 'src/app/models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { VerificacaoDocumentosService } from './../../shared/utils/verificacao-documentos.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cliente } from '../../models/Cliente';
import { SuccessResponse } from '../../models/HttpResponses/SuccessResponse';
import { FiltroPesquisaSolicitacaoFaturamento } from '../../models/EstimativaCalculo/SolicitacaoFaturamento/FiltroPesquisaSolicitacaoFaturamento';
import { GridSolicitacaoFaturamento } from '../../models/EstimativaCalculo/SolicitacaoFaturamento/GridSolicitacaoFaturamento';
import * as FileSaver from 'file-saver';
import { TipoDocumentoAnexado } from 'src/app/models/DocumentosAnexados/TipoDocumentoAnexado';

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoFaturamentoEstimativaCalculoService {

  constructor(
    private http: HttpClient,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    public authenticationService: AuthenticationService
  ) {

  }

  public getDocumentTypesForBillingSolicitation(): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaofaturamento}/api/ConfiguracaoCampoTela/TipoDocumento`);
  }

  public getFieldSettingForBillingSolicitationByDocumentType(documentType: string): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaofaturamento}/api/ConfiguracaoCampoTela?tipoDocumento=${encodeURIComponent(documentType)}`);
  }

  public getCliente(client: Cliente): Observable<SuccessResponse>{
    let query: string[] = [];
    if(client.cpfCnpj && client.cpfCnpj.length){
      query.push(`CpfCnpj=${this.verificacaoDocumentosService.eraseMask(client.cpfCnpj)}`);
    }
    if(client.nomeCliente && client.nomeCliente.length){
      query.push(`nome=${encodeURIComponent(client.nomeCliente)}`);
    }
    return this.http.get<SuccessResponse>(
      `${environment.apiUrlForSolicitacaofaturamento}/api/Cliente?${query.join('&')}`
    );
  }

  public getClienteFaturar(client: Cliente): Observable<SuccessResponse>{
    let query: string[] = [];
    if(client.cpfCnpj && client.cpfCnpj.length){
      query.push(`CpfCnpj=${this.verificacaoDocumentosService.eraseMask(client.cpfCnpj)}`);
    }
    if(client.nomeCliente && client.nomeCliente.length){
      query.push(`nome=${encodeURIComponent(client.nomeCliente)}`);
    }
    return this.http.get<SuccessResponse>(
      `${environment.apiUrlForCadastroDocumento}/api/Cliente/Faturar?${query.join('&')}`
    );
  }

  public searchBillingSolicitationsForInnerUser(filter: FiltroPesquisaSolicitacaoFaturamento): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaofaturamento}/SolicitacaoFaturamento/Atualizar`, filter);
  }

  public sendRpsNumber(solicitation: GridSolicitacaoFaturamento, numeroRPS: string): Observable<SuccessResponse>{
    let query: string[] = [];
    solicitation?.documentosAnexados?.forEach(
      (documento) => {
        if(documento?.filePath?.length){
          query.push(`anexos=${encodeURIComponent(documento?.filePath)}`);
        }
      }
    );
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaofaturamento}/SolicitacaoFaturamento/Enviar/RPS/${solicitation.id}/${numeroRPS}?${query.join('&')}`, null);
  }

  public confirmFinancialLiberation(solicitation: GridSolicitacaoFaturamento): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaofaturamento}/SolicitacaoFaturamento/Confirmar/${solicitation.id}`, solicitation);
  }

  public refuseFinancialLiberation(solicitation: GridSolicitacaoFaturamento, motivoRecusa: string): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaofaturamento}/SolicitacaoFaturamento/Recusar/${solicitation.id}/${this.authenticationService?.name}/${encodeURIComponent(motivoRecusa)}`, null);
  }

  public sendBillingSolicitationDocuments(solicitation: GridSolicitacaoFaturamento, usuarioExterno: boolean, arquivos: ArquivoDocumentoAnexado[]): Observable<SuccessResponse>{
    const formData = new FormData();
    arquivos?.forEach(
      (documento) => {
        if(documento.arquivoDocumento?.size){
          formData.append('id', documento.tipoDocumento.idDocumento.toString());
          formData.append('files', documento.arquivoDocumento!);
        }
      }
    );
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaofaturamento}/SolicitacaoFaturamento/Enviar/ModalDocumentosAnexados/${solicitation.id}?usuarioExterno=${usuarioExterno}`, formData);
  }

  public downloadFileForBillingSolicitation(arquivo: ArquivoDocumentoAnexado): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaofaturamento}/UploadArquivosSolicitacao/BaixarArquivo?filePath=${encodeURIComponent(arquivo.filePath!)}&fileName=${encodeURIComponent(arquivo.nomeArquivo!)}&contentType=${encodeURIComponent(arquivo.contentType!)}`);
  }

  public getFilesToAttach(usuarioExterno: boolean): Observable<TipoDocumentoAnexado[]>{
    return this.http.get<TipoDocumentoAnexado[]>(`${environment.apiUrlForSolicitacaofaturamento}/DocumentosLiberacao?usuarioExterno=${usuarioExterno}`);
  }

  public deleteFiles(arquivo: ArquivoDocumentoAnexado): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaofaturamento}/UploadArquivosSolicitacao/Deletar/${encodeURIComponent(arquivo.nomeArquivo!)}`, null);
  }

  public downloadFileFromAttachedFiles(file: ArquivoDocumentoAnexado): void{
    FileSaver.saveAs(file.arquivoDocumento as File, file.nomeArquivo);
  }
}
