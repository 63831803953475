import { IntegracaoSiscomex } from '../../../models/IntegracaoSignalR/IntegracaoSiscomex';
import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-extrair-dados-siscomex',
  templateUrl: './extrair-dados-siscomex.component.html',
  styleUrls: ['./extrair-dados-siscomex.component.scss']
})
export class ExtrairDadosSiscomexComponent implements OnInit {

  public integracoes!: IntegracaoSiscomex[];
  @ViewChild('modal', {static: true}) public modal!: TemplateRef<any>;
  @Output() onConcludeAllIntegrations: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() onOpenModal: EventEmitter<void> = new EventEmitter<void>();
  public areIntegrationsTimedOut!: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
  ) { }

  public get areAllIntegrationsDone(): boolean{
    return !!this.integracoes?.every((integracao) => integracao.concluido);
  }

  public ngOnInit(): void {

  }

  public closeModal(): void{
    this.bsModalRef.hide();
  }

  public openModal(integracoes: IntegracaoSiscomex[]): void{
    this.areIntegrationsTimedOut = false;
    this.integracoes = integracoes;
    this.bsModalRef = this.bsModalService.show(this.modal, Object.assign({}, {
      class: 'modal-lg',
      ignoreBackdropClick: true
    }));
    this.bsModalRef.onHidden?.subscribe(
      () => {this.onCloseModal.emit()}
    );
    this.onOpenModal.emit();
  }

  public clearIntegrationDisplay(): void{
    this.areIntegrationsTimedOut = true;
  }

  public concludeIntegration(integracao: IntegracaoSiscomex): void{
    this.integracoes.forEach(
      (itemIntegracao) => {
        if(itemIntegracao.nomeEvento === integracao.nomeEvento){
          itemIntegracao.concluido = true;
        }
      }
    );
    this.checkIfAllIntegrationsAreDone();
  }

  private checkIfAllIntegrationsAreDone(): void{
    if(this.areAllIntegrationsDone){
      this.onConcludeAllIntegrations.emit();
    }
  }

}
