import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { Campo } from 'src/app/models/Campo';
import { Canal } from 'src/app/models/Canal';
import { TipoDocumentoAnexado } from 'src/app/models/DocumentosAnexados/TipoDocumentoAnexado';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { SuccessResponse } from 'src/app/models/HttpResponses/SuccessResponse';
import { DocumentoParteLiberacaoDocumental } from 'src/app/models/LiberacaoDocumental/DocumentoParteLiberacaoDocumental';
import { PesquisaExpansaoDocumentoParte } from 'src/app/models/LiberacaoDocumental/PesquisaExpansaoDocumentoParte';
import { Mascara } from 'src/app/models/Mascara';
import { ConteinerEXP } from 'src/app/models/SolicitacaoLiberacaoExportacao/ConteinerEXP';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ToastService } from 'src/app/services/toast.service';
import {
  DocumentosAnexadosComponent,
} from 'src/app/shared/components/DocumentosAnexados/documentos-anexados/documentos-anexados.component';
import { documentTypesToBlockPartQuantityExp } from 'src/app/shared/utils/document-types-to-block-part-quantity';

import { ClienteComponent } from '../../../shared/components/cliente/cliente.component';
import { DocumentoComponent } from '../../../shared/components/documento/documento.component';
import {
  ArvorePartesLiberacaoDocumentalComponent,
} from '../../liberacao-documental/arvore-partes/arvore-partes-liberacao-documental/arvore-partes-liberacao-documental.component';
import {
  DadosComplementaresExportacaoComponent,
} from '../../solicitacao-liberacao-exportacao/dados-complementares-exportacao/dados-complementares-exportacao.component';
import {
  GridDueConteinerExpComponent,
} from '../../solicitacao-liberacao-exportacao/grid-due-conteiner-exp/grid-due-conteiner-exp.component';
import {
  RelacaoConteinerExportacaoComponent,
} from '../../solicitacao-liberacao-exportacao/relacao-conteiner-exportacao/relacao-conteiner-exportacao.component';
import {
  SolicitacaoLiberacaoExportacao,
} from './../../../models/SolicitacaoLiberacaoExportacao/SolicitacaoLiberacaoExportacao';
import { SolicitacaoLiberacaoExportacaoService } from './../../../services/solicitacao-liberacao-exportacao.service';
import { Moeda } from 'src/app/models/Moeda';

@Component({
  selector: 'app-visualizar-solicitacoes-exp',
  templateUrl: './visualizar-solicitacoes-exp.component.html',
  styleUrls: ['./visualizar-solicitacoes-exp.component.scss']
})
export class VisualizarSolicitacoesExpComponent implements OnInit {

  @ViewChild(DocumentoComponent) documento!: DocumentoComponent;
  @ViewChild(ClienteComponent) cliente!: ClienteComponent;
  @ViewChild(DadosComplementaresExportacaoComponent) dadosComplementares!: DadosComplementaresExportacaoComponent;
  @ViewChild(RelacaoConteinerExportacaoComponent) relacaoConteineres!: RelacaoConteinerExportacaoComponent;
  @ViewChild(ArvorePartesLiberacaoDocumentalComponent) arvoreDePartes!: ArvorePartesLiberacaoDocumentalComponent;
  @ViewChild(GridDueConteinerExpComponent) gridDueConteiner!: GridDueConteinerExpComponent;
  @ViewChild(DocumentosAnexadosComponent) documentosAnexados!: DocumentosAnexadosComponent;
  @Output() onExpandPartDocument: EventEmitter<PesquisaExpansaoDocumentoParte> = new EventEmitter<PesquisaExpansaoDocumentoParte>();
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSaveSolicitation: EventEmitter<void> = new EventEmitter<void>();

  public isDocumentoDAT: boolean = false;
  protected mascara!: Mascara;
  private readonly documentTypesToBlockPartQuantityEdition: string[] = [...documentTypesToBlockPartQuantityExp];
  protected blockEdition: boolean = true;
  protected solicitationId: number = 0;
  protected shouldBlockPartQuantityEditionByDocumentType: boolean = false;
  protected camposFormulario: Campo[] = [];

  protected moedas: Moeda[] = [];
  protected canais: Canal[] = [];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private documentosService: DocumentosService,
    public solicitacaoLiberacaoExportacaoService: SolicitacaoLiberacaoExportacaoService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  public openModal(solicitacaoLiberacao : SolicitacaoLiberacaoExportacao) {
    this.solicitationId = solicitacaoLiberacao.id ?? 0;
    this.blockEdition = solicitacaoLiberacao.status?.toUpperCase() !== 'PENDENTE';
    this.shouldBlockPartQuantityEditionByDocumentType = this.documentTypesToBlockPartQuantityEdition.includes(solicitacaoLiberacao.documento?.tipoDocumento ?? '');
    this.isDocumentoDAT = (solicitacaoLiberacao?.documento?.tipoDocumento == 'DAT');
    this.documento.setDocumentTypes(this.getDocumentTypes());
    if(!this.blockEdition){
      this.setFormConfigurations(solicitacaoLiberacao.documento?.tipoDocumento!!);
    }
    this.changeDetector.detectChanges();
    this.setFormvaluesFromSolicitation(solicitacaoLiberacao);
    this.changeDetector.detectChanges();
  }

  public getDocumentTypes(): Observable<SuccessResponse>{
    return this.documentosService.GetDocumentsForSolicitacaoLiberacaoExp();
  }

  public getAttachedFilesTypes(): Observable<TipoDocumentoAnexado[]>{
    return this.solicitacaoLiberacaoExportacaoService.getAttachmentDocumentsForExpLiberationByDocumentType();
  }

  public closeModal(): void{
    this.onCloseModal.emit();
  }

  protected getPartDocumentsFromContainers(containers: ConteinerEXP[]): DocumentoParteLiberacaoDocumental[]{
    return containers
      .filter((container) => container.documentos?.length)
      .flatMap((container) => {
        return container.documentos!.map((documentoParte) => {
          return {
            numeroConteiner: container.conteiner,
            numeroDocumento: documentoParte.numeroDocumentoParte,
            status: documentoParte.status ?? '',
            quantidadePartes: documentoParte.quantidadePartes ?? ''
          };
        });
    });
  }

  protected expandPartDocument(documentoParte: PesquisaExpansaoDocumentoParte): void{
    this.onExpandPartDocument.emit({
      ...documentoParte,
      tipoDocumento: this.documento?.getDocumentFromFormFields()?.tipoDocumento,
      nomeRegimeCirculacao: 'Exp',
      idRegimeCirculacao: 2
    });
  }

  private setFormConfigurations(tipoDocumento: string): void{
    this.camposFormulario = [];
    this.documentosService
      .getFormFieldsByDocumentTypeInSolicitacaoLiberacaoExp(tipoDocumento)
      .subscribe({
        next: (result) => {
          this.camposFormulario = result.data;
          this.changeDetector.detectChanges();
          this.setFormSettings();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
          this.changeDetector.detectChanges();
        }
      })
  }

  protected getSolicitationFromForms(): SolicitacaoLiberacaoExportacao{
    let solicitation: SolicitacaoLiberacaoExportacao = {};
    solicitation.id = this.solicitationId;
    solicitation.documento = this.documento?.getDocumentFromFormFields();
    solicitation.cliente = this.cliente?.getClientFromForm();
    solicitation.dadosComplementares = this.dadosComplementares?.getComplementaryDataFromForm();
    solicitation.relacaoConteineres = this.relacaoConteineres?.getContainersFromForms();
    solicitation.arvorePartes = this.arvoreDePartes?.getDocumentosParteFromFormFields() as any;
    solicitation.relacaoDueConteiner = this.gridDueConteiner?.getDueConteinerRelation();
    solicitation.documentosAnexosLiberacao = this.documentosAnexados.getAllDocumentsWithAttachedFiles();
    return solicitation;
  }

  protected setFormvaluesFromSolicitation(solicitation: SolicitacaoLiberacaoExportacao): void{
    this.documento?.setFormValuesForModal(solicitation.documento);
    this.cliente?.setFormValuesFromReceivedClient(solicitation.cliente);
    this.dadosComplementares?.setFormValuesFromReceivedComplementaryData(solicitation.dadosComplementares);
    this.relacaoConteineres?.setFormValuesFromReceivedContainerRelationList(solicitation.relacaoConteineres);
    this.gridDueConteiner?.setFormValuesFromReceivedDueConteiner(solicitation.relacaoDueConteiner);
    this.documentosAnexados.setAttachedDocumentTypes(
      solicitation?.documento?.id!,
      solicitation?.documentosAnexosLiberacao ?? [],
      this.getAttachedFilesTypes()
    );
  }

  protected setFormSettings(): void{
    this.cliente?.setSettingsfromFields();
    this.dadosComplementares?.setSettingsfromFields();
    this.relacaoConteineres?.setSettingsfromFields();
    this.gridDueConteiner?.setSettingsfromFields();
  }

  protected get areAllFormsValid(): boolean{
    return this.documento?.isDocumentValid() &&
      this.cliente?.isClientValid() &&
      this.documentosAnexados?.areAllFilesValid &&
      this.dadosComplementares?.areComplementaryDataValid &&
      (this.isDocumentoDAT ? this.gridDueConteiner?.isDueConteinerRelationValid : ( this.relacaoConteineres?.areAllContainersValid && this.arvoreDePartes?.areAllPartDocumentsValid))
  }

  protected saveSolicitation(): void{
    if(!this.areAllFormsValid){
      return;
    }
    const solicitation = this.getSolicitationFromForms();
    this.spinner.show();
    this.solicitacaoLiberacaoExportacaoService.saveSolicitationOnLiberationModal(solicitation)
      .subscribe({
        next: (result) => {
          result.message?.forEach((msg) => {
            this.toastService.success(msg)
          });
          this.uploadFiles();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  protected uploadFiles(): void{
    var documentos = this.documentosAnexados?.getAllDocumentsWithAttachedFiles();
    if(!documentos || !documentos.length){
      this.spinner.hide();
      this.onSaveSolicitation.emit();
      return;
    }
    this.solicitacaoLiberacaoExportacaoService.arquivoUploadSolicitacaoLiberacaoExp(documentos, this.solicitationId).subscribe(
    {
      next: (msg) => {
        this.spinner.hide();
        msg?.message?.forEach(
          (message) => {
            this.toastService.success(message);
          }
        );
        this.onSaveSolicitation.emit();
      },
      error: (err: HttpErrorResponse) =>{
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      }
    }
  );
  }

  public setSelectOptions(moedas: Moeda[], canais: Canal[]): void{
    this.moedas = moedas;
    this.canais = canais;
    this.changeDetector.detectChanges();
  }
}
