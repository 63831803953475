<td *ngIf="selecionado" class="text-center">
    <input type="checkbox" (click)="onManualSelection.emit()" [checked]="selecionado.value" [formControl]="selecionado">
</td>
<td *ngIf="conteiner">
    <div class="input-group">
        <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
          aria-describedby="basic-addon2" [formControl]="conteiner"
          (change)="sendContainerNumberToSearchIfNoIntegration()"
        >
    </div>
</td>
<td *ngIf="conteinerTransload">
    <div class="input-group d-flex">
        <input type="text" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2" [formControl]="conteinerTransload">
    </div>
</td>
<td *ngIf="parte">
    <select [ngClass]="{'border border-danger': parte.invalid && !parte.pristine}" class="custom-select" [formControl]="parte" (change)="setValidValueForArvorePartes(!!parte.value)">
    <option value="" disabled selected>Selecione</option>
    <option [value]="true" [ngValue]="true">Sim</option>
    <option [value]="false" [ngValue]="false">Não</option>
  </select>
</td>
<td *ngIf="quantidadePartes && qtdPartesFront">
    <div class="input-group">
        <input type="number" class="form-control" placeholder="Digite aqui..." aria-label="Digite aqui..."
          aria-describedby="basic-addon2" [formControl]="qtdPartesFront" [readonly]="!parte.value"
          [min]="minPartQuantity" [ngClass]="{'no-up-down-button': shouldBlockPartQuantityEditionByDocumentType}"
          (change)="formConteiner.patchValue({quantidadePartes: qtdPartesFront.value ? qtdPartesFront.value : 0}, {emitEvent: false});setValidValueForArvorePartes(!!parte.value)"
        >
    </div>
</td>
<td *ngIf="!shouldBlockPartQuantityEditionByDocumentType && quebraLote">
    <select class="custom-select" [formControl]="quebraLote">
    <option value="" disabled selected>Selecione</option>
    <option [value]="true" [ngValue]="true">Sim</option>
    <option [value]="false" [ngValue]="false">Não</option>
  </select>
</td>
<td *ngIf="selecionado">
  <button class="erase-line-button" *ngIf="!doesSolicitationHaveIntegration && !blockAddition && !!selecionado.value" (click)="eraseLine()">
    <img src="assets/images/red-trashcan.svg" alt="">
  </button>
</td>
