import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CadastroDocumentoParteIMPDTO } from './../models/SolicitacaoLiberacaoIMP/CadastroDocumentoParteIMPDTO';
import { SuccessResponse } from './../models/HttpResponses/SuccessResponse';
import { Observable } from 'rxjs';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { SolicitacaoLiberacaoIMP } from 'src/app/models/SolicitacaoLiberacaoIMP/SolicitacaoLiberacaoIMP';
import { Injectable } from '@angular/core';
import { Documento } from '../models/Documento';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cliente } from '../models/Cliente';
import { TipoDocumentoAnexado } from '../models/DocumentosAnexados/TipoDocumentoAnexado';
import { ArquivoDocumentoAnexado } from '../models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { ConteinerIMP } from '../models/SolicitacaoLiberacaoIMP/ConteinerIMP';

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoLiberacaoImpService {

  constructor(
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  public getImportLiberationSolicitationDataByDocument(document: Documento, correlation_id: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlation_id);
    let query: string[] = [];
    query.push('confirmado=false');
    if(document?.numeroCE?.length){
      query.push(`numeroCE=${document?.numeroCE}`);
    }
    query.push(`emailUserLogado=${this.authenticationService.userEmail}`)
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`)
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP/Pesquisar/${document.id}/${document.numeroDocumento}?${query.join('&')}`, {headers});
  }

  public confirmCreateImportLiberationSolicitationDataByDocument(document: Documento, correlation_id: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlation_id);
    let query: string[] = [];
    query.push('confirmado=true');
    if(document?.numeroCE?.length){
      query.push(`numeroCE=${document?.numeroCE}`);
    }
    query.push(`emailUserLogado=${this.authenticationService.userEmail}`)
    query.push(`userInterno=${this.authenticationService.ehUsuarioInterno}`)
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP/Pesquisar/${document.id}/${document.numeroDocumento}?${query.join('&')}`, {headers});
  }

  public cancelImportLiberationSolicitationBySolicitationId(solicitationId: number): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP/${solicitationId}/Cancelar`, solicitationId);
  }


  public saveImportLiberationSolicitation(solicitation: SolicitacaoLiberacaoIMP): Observable<SuccessResponse>{
    if(solicitation.documentosAnexosLiberacao?.length){

      solicitation.documentosAnexosLiberacao?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP?solicitante=${this.authenticationService.userName}&emailUserLogado=${this.authenticationService.userEmail}&userInterno=${this.authenticationService.ehUsuarioInterno}`, solicitation);
  }

  public saveImportLiberationSolicitationFromIntegratedDTC(solicitation: SolicitacaoLiberacaoIMP): Observable<SuccessResponse>{
    if(solicitation.documentosAnexosLiberacao?.length){
      solicitation.documentosAnexosLiberacao?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP?solicitante=${this.authenticationService.userName}&emailUserLogado=${this.authenticationService.userEmail}&bloqueioDTCverificado=true&userInterno=${this.authenticationService.ehUsuarioInterno}`, solicitation);
  }

  public savePartDocument(cadastroDocumentoParte: CadastroDocumentoParteIMPDTO, correlationId: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlationId);
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP/DocumentoParte`, cadastroDocumentoParte, {headers});
  }

  public getCliente(client: Cliente): Observable<SuccessResponse>{
    let query: string[] = [];
    if(client.cpfCnpj && client.cpfCnpj.length){
      query.push(`CpfCnpj=${this.verificacaoDocumentosService.eraseMask(client.cpfCnpj)}`);
    }
    if(client.nomeCliente && client.nomeCliente.length){
      query.push(`nome=${client.nomeCliente}`);
    }
    return this.http.get<SuccessResponse>(
      `${environment.apiUrlForSolicitacaoLiberacaoImp}/api/Cliente?${query.join('&')}`
    );
  }

  public saveDraft(solicitacaoLiberacaoImp: SolicitacaoLiberacaoIMP): Observable<SuccessResponse>{
    if(solicitacaoLiberacaoImp.documentosAnexosLiberacao && solicitacaoLiberacaoImp.documentosAnexosLiberacao.length){

      solicitacaoLiberacaoImp.documentosAnexosLiberacao?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP/Rascunho`, solicitacaoLiberacaoImp);
  }

  public getAttachmentDocumentsForImpLiberationByDocumentType(): Observable<TipoDocumentoAnexado[]>{
    return this.http.get<TipoDocumentoAnexado[]>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/DocumentosLiberacao`);
  }

  public arquivoUploadSolicitacaoLiberacaoImp(dados: ArquivoDocumentoAnexado[], solicitacaoId: number): Observable<SuccessResponse>{
    const formData = new FormData();
    dados.forEach(e => {
      var arq = e.arquivoDocumento as File;
      var id = e.tipoDocumento?.idDocumento.toString() ? e.tipoDocumento?.idDocumento.toString() : '';
      if(arq?.size){
        formData.append('files', arq);
        formData.append('id', id);
      }
    });
    return this.http
      .post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/UploadArquivosSolicitacao/uploadfiles/${solicitacaoId}`, formData);
  }

  public downloadFileForAttachedDocumentGrid(arquivo: ArquivoDocumentoAnexado): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/UploadArquivosSolicitacao/BaixarArquivo?filePath=${arquivo.filePath}&fileName=${arquivo.nomeArquivo}&contentType=${arquivo.contentType}`);
  }

  public downloadFileForAttachedDocumentGridOnModal(arquivo: ArquivoDocumentoAnexado): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/UploadArquivosSolicitacao/BaixarArquivo?idSolicitacao=${arquivo.idSolicitacao}&filePath=${arquivo.filePath}&fileName=${arquivo.nomeArquivo}&contentType=${arquivo.contentType}`);
  }

  public getIntegrationsFromDIXML(solicitation: SolicitacaoLiberacaoIMP, correlationId: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlationId);
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoImp/UploadXml/${solicitation?.documento?.id}/${solicitation?.documento?.numeroDocumento}/${solicitation?.documento?.numeroCE}?confirmado=false?userInterno=${this.authenticationService.ehUsuarioInterno}`, solicitation, {headers});
  }

  public getTelefone(solicitation: SolicitacaoLiberacaoIMP, correlationId: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlationId);
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoImp/UploadXml/${solicitation?.documento?.id}/${solicitation?.documento?.numeroDocumento}/${solicitation?.documento?.numeroCE}?confirmado=true?userInterno=${this.authenticationService.ehUsuarioInterno}`, solicitation, {headers});
  }

  public getTelefoneUsuarioExterno(): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP/ObterInformacoesUsuarioLogado/`+this.authenticationService.userEmail);
  }

  public confirmCreateSolicitationFromDIXML(solicitation: SolicitacaoLiberacaoIMP, correlationId: string): Observable<SuccessResponse>{
    const headers = new HttpHeaders().append('CorrelationId', correlationId);
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoImp/UploadXml/${solicitation?.documento?.id}/${solicitation?.documento?.numeroDocumento}/${solicitation?.documento?.numeroCE}?confirmado=true?userInterno=${this.authenticationService.ehUsuarioInterno}`, solicitation, {headers});
  }

  public postCancelarSolicitacao(solicitacaoLiberacaoImp: SolicitacaoLiberacaoIMP, conteiner: ConteinerIMP): Observable<SuccessResponse>{
    return this.http.post<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP/ExcluirConteiner/${solicitacaoLiberacaoImp.id}/${conteiner.conteiner}`, null);
  }

  public searchNomesDespachantes(solicitation: SolicitacaoLiberacaoIMP): Observable<SuccessResponse>{
    let query: string[] = [];
    console.log(this.authenticationService.ehUsuarioInterno);
    console.log(this.authenticationService.userName);
    if(this.authenticationService.ehUsuarioInterno){
      query.push(`userType=1`);
      query.push(`cnpjCliente=${solicitation?.cliente?.cpfCnpj}`);
    }
    else{
      query.push(`userType=0`);
      query.push(`matricula=${this.authenticationService?.userName}`);
    }
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP/ObterDadosDespachante?${query.join('&')}`);
  }

  public searchShipAndTripByContainerNumber(containerNumber: string): Observable<SuccessResponse>{
    return this.http.get<SuccessResponse>(`${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP/BuscarNavioViagem/${containerNumber}`);
  }

  public saveSolicitationOnLiberationModal(solicitation: SolicitacaoLiberacaoIMP): Observable<SuccessResponse>{
    if(solicitation.documentosAnexosLiberacao?.length){
      solicitation.documentosAnexosLiberacao?.forEach(
        (documento) => {
          documento.arquivoDocumento = undefined;
        }
      );
    }
    return this.http.put<SuccessResponse>(
      `${environment.apiUrlForSolicitacaoLiberacaoImp}/SolicitacaoLiberacaoIMP/Imp`,
      solicitation
    );
  }
}
