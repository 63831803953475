import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  IntegracaoDocumentoParteComponent,
} from 'src/app/components/modais/integracao-documento-parte/integracao-documento-parte.component';
import {
  ArvorePartesExportacaoComponent,
} from 'src/app/components/solicitacao-liberacao-exportacao/arvore-partes-exportacao/arvore-partes-exportacao.component';
import {
  DadosComplementaresExportacaoComponent,
} from 'src/app/components/solicitacao-liberacao-exportacao/dados-complementares-exportacao/dados-complementares-exportacao.component';
import {
  GridDueConteinerExpComponent,
} from 'src/app/components/solicitacao-liberacao-exportacao/grid-due-conteiner-exp/grid-due-conteiner-exp.component';
import {
  RelacaoConteinerExportacaoComponent,
} from 'src/app/components/solicitacao-liberacao-exportacao/relacao-conteiner-exportacao/relacao-conteiner-exportacao.component';
import { Permissoes } from 'src/app/contants/permissoes';
import { Telas } from 'src/app/contants/telas';
import { AuthRoles } from 'src/app/models/authRoles';
import { Campo } from 'src/app/models/Campo';
import { Canal } from 'src/app/models/Canal';
import { Cliente } from 'src/app/models/Cliente';
import { ArquivoDocumentoAnexado } from 'src/app/models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { RetornoStatusDocumentoParteDTO } from 'src/app/models/IntegracaoSignalR/RetornoStatusDocumentoParteDTO';
import { Mascara } from 'src/app/models/Mascara';
import { DadosNavioVigemDTO } from 'src/app/models/SolicitacaoLiberacaoCabotagem/DadosNavioVigemDTO';
import { CadastroDocumentoParteEXPDTO } from 'src/app/models/SolicitacaoLiberacaoExportacao/CadastroDocumentoParteEXPDTO';
import { ConteinerEXP } from 'src/app/models/SolicitacaoLiberacaoExportacao/ConteinerEXP';
import { DocumentoParteEXP } from 'src/app/models/SolicitacaoLiberacaoExportacao/DocumentoParteEXP';
import {
  SolicitacaoLiberacaoExportacao,
} from 'src/app/models/SolicitacaoLiberacaoExportacao/SolicitacaoLiberacaoExportacao';
import { DocumentosService } from 'src/app/services/documentos.service';
import { SolicitacaoLiberacaoExportacaoService } from 'src/app/services/solicitacao-liberacao-exportacao.service';
import { ToastService } from 'src/app/services/toast.service';
import { ClienteComponent } from 'src/app/shared/components/cliente/cliente.component';
import { DocumentoComponent } from 'src/app/shared/components/documento/documento.component';
import { ModalOKComponent } from 'src/app/shared/components/modais/modal-ok/modal-ok.component';
import { documentTypesToBlockPartQuantityExp } from 'src/app/shared/utils/document-types-to-block-part-quantity';
import * as uuid from 'uuid';

import { SuccessResponse } from '../../models/HttpResponses/SuccessResponse';
import { CancelarSolicitacaoService } from '../../services/cancelar-solicitacao.service';
import { SalvarRascunhoExportacaoService } from '../../services/salvar-rascunho-exportacao.service';
import { SalvarRascunhoService } from '../../services/salvar-rascunho.service';
import { Documento } from './../../models/Documento';
import {
  DocumentosAnexadosComponent,
} from './../../shared/components/DocumentosAnexados/documentos-anexados/documentos-anexados.component';
import { Moeda } from 'src/app/models/Moeda';

@Component({
  selector: 'app-solicitacao-liberacao-exportacao',
  templateUrl: './solicitacao-liberacao-exportacao.component.html',
  styleUrls: ['./solicitacao-liberacao-exportacao.component.scss'],
})
export class SolicitacaoLiberacaoExportacaoComponent implements OnInit, AfterViewInit {
  public title: string = 'Solicitação de Liberação de Exportação';
  public subtitle: string =
    'Adicione abaixo os dados necessários para o documento de solicitação.';
  //public formIn!: FormGroup;
  public formOut!: UntypedFormGroup;
  public divValid: boolean = false;
  public modalRef!: BsModalRef;
  public isSearchDone: boolean = false;
  public camposFormularios: Campo[] = [];
  public isSolicitationReceived: boolean = false;
  public isPartDocumentSaved: boolean = false;
  public isDocumentDAT: boolean = false;
  public doesSolicitationHaveIntegration: boolean = true;
  public isRequesting: boolean = false;
  protected selectedDocumentType!: string;
  private readonly documentTypesToBlockPartQuantityEdition: string[] = [...documentTypesToBlockPartQuantityExp];

  //@Output() dataFormIn = new EventEmitter<FormGroup>();
  @ViewChild(DocumentoComponent) documento!: DocumentoComponent;
  @ViewChild(ClienteComponent) cliente!: ClienteComponent;
  @ViewChild(DadosComplementaresExportacaoComponent) dados!: DadosComplementaresExportacaoComponent;
  @ViewChild(RelacaoConteinerExportacaoComponent) relacao!: RelacaoConteinerExportacaoComponent;
  @ViewChild(ArvorePartesExportacaoComponent) arvore!: ArvorePartesExportacaoComponent;
  @ViewChild(GridDueConteinerExpComponent) gridDueConteiner!: GridDueConteinerExpComponent;
  @ViewChild(DocumentosAnexadosComponent) documentosAnexosLiberacao!: DocumentosAnexadosComponent;
  @ViewChild(IntegracaoDocumentoParteComponent) modalIntegracaoDocumentoParte!: IntegracaoDocumentoParteComponent;

  public maskForArvorePartes!: Mascara;
  private arvorePartesSalva: DocumentoParteEXP[] = [];
  private numerosConteineresSalvos: string[] = [];

  protected moedas: Moeda[] = [];
  protected canais: Canal[] = [];

  public textoSolicitacaoExistente!: string;
  @ViewChild('modalSolicitacaoPesquisadaExistente') modalSolicitacaoPesquisadaExistente!: ModalOKComponent;

  public get isSalvarDocumentoParteEnabled(): boolean{
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      return false;
    }
    return !this.isRequesting &&
      !!this.relacao?.getSelectedContainersFromForms()?.length &&
      !!this.arvore?.getArvorePartesFromForm()?.length &&
      this.arvore?.areAllDocumentoParteLinesValid &&
      !this.arvore?.arePartDocumentsSameAsStored;
  }


  public readonly visualizacao = new AuthRoles(Telas.solicitacaoLiberacaoExportacao, Permissoes.visualizacao)
  public readonly salvarRascunho = new AuthRoles(Telas.solicitacaoLiberacaoExportacao, Permissoes.salvarRascunho)
  public readonly concluir = new AuthRoles(Telas.solicitacaoLiberacaoExportacao, Permissoes.concluir)
  public readonly salvarDocParte = new AuthRoles(Telas.solicitacaoLiberacaoExportacao, Permissoes.salvarDocParte)
  public readonly cancelar = new AuthRoles(Telas.solicitacaoLiberacaoExportacao, Permissoes.cancelar)

  constructor(
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private SalvarRascunhoService: SalvarRascunhoService,
    public datepipe: DatePipe,
    private solicitacaoLiberacaoExportacaoService: SolicitacaoLiberacaoExportacaoService,
    private changeDetector: ChangeDetectorRef,
    private documentosService: DocumentosService,
    private SalvarRascunhoExportacaoService: SalvarRascunhoExportacaoService,
    private toastService:ToastService,
    private CancelarSolicitacaoService: CancelarSolicitacaoService,
  ) {}

  public ngOnInit(): void{
    this.validation();
    this.getSelectFieldsForFormComponents();
  }

  public ngAfterViewInit(): void {
    this.getDocuments();
  }

  public get shouldBlockPartQuantityEditionByDocumentType(): boolean{
    return this.documentTypesToBlockPartQuantityEdition.includes(this.selectedDocumentType ?? '');
  }

  public validation(): void {
    this.formOut = this.fb.group({
      id: [null],
      documento: [null, [Validators.required]],
      cliente: [null],
      dadosComplementares: [null],
      relacaoConteineres: [null],
      arvorePartes: [null],
      relacaoDueConteiner: [null]
    });
  }

  //Traz o tipodocumento e o numero para enviar ao endpoit
  //recebe os dados da integração em emit para os componentes filhos
  public searchSolicitation(documento: Documento) {
    if(!documento){
      return;
    }
    this.isSearchDone = false;
    this.isRequesting = true;
    this.clearForms(false);
    this.doesSolicitationHaveIntegration = documento?.tipoDocumento !== 'DSE';
    this.changeDetector.detectChanges();
    this.spinner.show();
    this.formOut.reset();
    this.isPartDocumentSaved = false;
    this.isDocumentDAT = (documento.tipoDocumento == 'DAT');
    this.changeDetector.detectChanges();
    this.solicitacaoLiberacaoExportacaoService.getExportacaoLiberationSolicitationDataByDocument(documento)
    .subscribe({
      next: (result) => {
        let solicitation = result.data as SolicitacaoLiberacaoExportacao;
        this.isSearchDone = true;
        if(solicitation){
          this.setSolicitationValuesToForms(solicitation);
        }
        else{
          this.documentosAnexosLiberacao?.setAttachedDocumentTypes(this?.documento?.getDocumentFromFormFields()?.id!, [], this.solicitacaoLiberacaoExportacaoService?.getAttachmentDocumentsForExpLiberationByDocumentType());
        }
        //this.documento.setFormValuesFromDocument(documento);
        this.spinner.hide();
        this.setFieldSettings();
        this.getTelefoneUsuarioExterno();
        this.isRequesting = false;
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        let errorMessage = (err?.error as ErrorResponse);
        if(err?.status == 404 && typeof(err.error) == 'string'){
          this.textoSolicitacaoExistente = err.error;
          this.modalSolicitacaoPesquisadaExistente?.openModalForOk();
        }
        else{
          console.log(err);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                this.toastService.error(error);
              }
            );
          }
          this.isRequesting = false;
        }
        this.setFieldSettings();
        this.documentosAnexosLiberacao?.setAttachedDocumentTypes(this?.documento?.getDocumentFromFormFields()?.id!, [], this.solicitacaoLiberacaoExportacaoService?.getAttachmentDocumentsForExpLiberationByDocumentType());
      }
    });
    this.getFormSettingsByDocumentType(documento?.tipoDocumento);
    this.getTelefoneUsuarioExterno();
  }

  private getTelefoneUsuarioExterno() {
    this.solicitacaoLiberacaoExportacaoService.getTelefoneUsuarioExterno() .subscribe({
      next: (result) => {
        console.log(result.data);
        this.dados.setTelefoneContatoEmail(result.data);
        this.changeDetector.detectChanges();
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      }
    });
  }

  public confirmCreateSolicitationFromExistingDocument() {
    let documento = this.documento?.getDocumentFromFormFields();
    if(!documento){
      return;
    }
    this.doesSolicitationHaveIntegration = documento?.tipoDocumento !== 'DSE';
    this.changeDetector.detectChanges();
    this.isSearchDone = false;
    this.isRequesting = true;
    this.spinner.show();
    this.solicitacaoLiberacaoExportacaoService.confirmCreateExportacaoLiberationSolicitation(documento)
    .subscribe({
      next: (result) => {
        let solicitation = result.data as SolicitacaoLiberacaoExportacao;
        this.isSearchDone = true;
        if(solicitation){
          this.setSolicitationValuesToForms(solicitation);
        }
        else{
          this.documentosAnexosLiberacao?.setAttachedDocumentTypes(this?.documento?.getDocumentFromFormFields()?.id!, [], this.solicitacaoLiberacaoExportacaoService?.getAttachmentDocumentsForExpLiberationByDocumentType());
        }
        //this.documento.setFormValuesFromDocument(documento);
        this.spinner.hide();
        this.setFieldSettings();
        this.isRequesting = false;
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              this.toastService.error(error);
            }
          );
        }
        this.setFieldSettings();
        this.isRequesting = false;
        this.documentosAnexosLiberacao?.setAttachedDocumentTypes(this?.documento?.getDocumentFromFormFields()?.id!, [], this.solicitacaoLiberacaoExportacaoService?.getAttachmentDocumentsForExpLiberationByDocumentType());
      }
    });
    this.getFormSettingsByDocumentType(documento?.tipoDocumento);
  }

  private setSolicitationValuesToForms(solicitation: SolicitacaoLiberacaoExportacao): void{
    this.documentosAnexosLiberacao?.setAttachedDocumentTypes(solicitation?.documento?.id!, solicitation?.documentosAnexosLiberacao ?? [], this.solicitacaoLiberacaoExportacaoService?.getAttachmentDocumentsForExpLiberationByDocumentType());
    this.formOut?.get('id')?.setValue(solicitation.id);
    this.relacao?.setFormValuesFromReceivedContainerRelationList(solicitation.relacaoConteineres);
    // this.documento.setFormValuesFromDocument(solicitation.documento);
    this.cliente?.setFormValuesFromReceivedClient(solicitation.cliente, true);
    this.dados?.setFormValuesFromReceivedComplementaryData(solicitation.dadosComplementares);
    this.arvore?.setArvorePartesValuesFromContainers(solicitation.relacaoConteineres);
    this.gridDueConteiner?.setFormValuesFromReceivedDueConteiner(solicitation.relacaoDueConteiner);
    this.isSolicitationReceived = true;
    this.setFieldSettings();
  }

  public btnValid(event: any) {
    if (event) {
      this.divValid = true;
      return;
    }
    this.divValid = false;
  }

  // public allDataForm(event: any) {
  //   if (event != undefined) {
  //     this.formOut.patchValue(event);
  //     this.modelSolicitacao = {
  //       id: this.formOut.value.id,
  //       documento: this.formOut.value.documento,
  //       cliente: this.formOut.value.cliente,
  //       dadosComplementares: this.formOut.value.dadosComplementares,
  //       //relacaoConteineres: [this.formOut.value.relacaoConteineres],
  //       relacaoConteineres: this.formOut.value.relacaoConteineres,
  //       //arvorePartes: [this.formOut.value.arvorePartes]
  //       arvorePartes: this.formOut.value.arvorePartes
  //       //documentosAnexosLiberacao: [this.formOut.value.documentosAnexosLiberacao]
  //     };
  //     if(event.arvorePartes && this.arvore){
  //       this.arvorePartesSalva = [...this.arvore?.getArvorePartesFromForm()];
  //       this.numerosConteineresSalvos = [...this.relacao.selectedContainerNumbers];
  //     }
  //   }
  // }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
    this.isRequesting = false;
  }

  public closeModel() {
    this.modalService.hide();
  }

  public saveDraft() {
    if(!this.isSaveDraftButtonEnabled){
      return;
    }
    console.log(this.getSolicitationFromForms());
    this.spinner.show()
    this.isRequesting = true;
    this.solicitacaoLiberacaoExportacaoService.saveDraft(this.getSolicitationFromForms())
    .subscribe(
      {
        next: (result) => {
          result.message?.forEach(
            (message) => {
              if(message && message.length){
                this.toastService.success(message);
              }
            }
          );
          let solicitation: SolicitacaoLiberacaoExportacao = result.data;
          this.formOut?.get('id')?.setValue(solicitation.id);
          this.saveAttachedFiles(solicitation.id!);
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = err?.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                this.toastService.error(error);
              }
            );
          }
          this.isRequesting = false;
        },
        complete: () => this.spinner.hide()
      }
      );
  }

  public clearFormFields(): void{
    this.spinner.show();
    setTimeout(() => {
      this.clearForms(true);
      this.spinner.hide();
    });
  }

  // ngAfterContentChecked() {
  //   if (this.formOut.valid) {
  //     this.styleBtnConcluir = 'btnConcluir';
  //     this.disabled = '';
  //   }
  // }

  public clean() {
    this.spinner.show();
    setTimeout(() => {
      this.cliente?.clean();
      this.documento?.clean();
      this.dados?.clean();
      this.relacao?.clean();
      this.arvore?.clean();
      this.gridDueConteiner?.clean();
      this.documentosAnexosLiberacao?.clearFields();
      this.spinner.hide();
    }, 1500);
  }

  private clearForms(clearDocument: boolean): void{
    this.cliente?.clean();
    this.dados?.clean();
    this.relacao?.clean();
    this.arvore?.clean();
    this.gridDueConteiner?.clean();
    this.documentosAnexosLiberacao?.clearFields();
    if(clearDocument){
      this.documento?.clean();
      this.camposFormularios = [];
      this.formOut?.reset();
    }
  }

  public cancelSolicitation(): void{
    this.spinner.show();
    if(this.formOut.value.id){
          this.isRequesting = true;
      this.solicitacaoLiberacaoExportacaoService.cancelExportacaoLiberationSolicitationBySolicitationId(this.getSolicitationFromForms())
        .subscribe(
          {
            next: (result) => {
              this.spinner.hide();
              result?.message?.forEach(
                (msg) => {
                  if(msg?.length){
                    this.toastService.success(msg);
                  }
                }
              );
              this.resetPage();
              this.isRequesting = false;
            },
            error: (err: HttpErrorResponse) => {
              this.spinner.hide();
              console.log(err);
              let errorMessage = (err?.error as ErrorResponse);
              if(errorMessage?.errors?.length){
                errorMessage?.errors?.forEach(
                  (error) => {
                    if(error?.length){
                      this.toastService.error(error);
                    }
                  }
                );
              }
              this.isRequesting = false;
            }
          }
        );
    }
    else{
      setTimeout(() => {
        this.spinner.hide();
      }, 1500);
      this.resetPage();
    }
  }

  protected resetPage(): void{
    this.clearForms(true);
    this.divValid = false;
    this.isSolicitationReceived = false;
    this.isSearchDone = false;
    this.isRequesting = false;
  }

  public save(): void{
    if(!this.isConcludeButtonEnabled){
      return;
    }
    this.spinner.show();
    this.isRequesting = true;
    this.solicitacaoLiberacaoExportacaoService.saveExportacaoLiberationSolicitation(this.getSolicitationFromForms())
      .subscribe({
        next: (event: SuccessResponse) => {
          event?.message?.forEach(
            (msg) => {
              if(msg?.length){
                this.toastService.success(msg);
              }
            }
          );
          let solicitation: SolicitacaoLiberacaoExportacao = event.data;
          this.formOut?.get('id')?.setValue(solicitation.id);
          this.saveAttachedFiles(solicitation.id!);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = err?.error as ErrorResponse;
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                this.toastService.error(error);
              }
            );
          }
          this.spinner.hide();
          this.isRequesting = false;
        }
      });
  }

  public hideForms(): void{
    this.isSearchDone = false;
    this.divValid = false;
  }


  public changeDocumentType(documentType: string): void{
    this.hideForms();
    this.selectedDocumentType = documentType;
    this.changeDetector.detectChanges();
  }

  private getFormSettingsByDocumentType(documentType: string): void{
    this.camposFormularios = [];
    this.documentosService.getFormFieldsByDocumentTypeInSolicitacaoLiberacaoExp(documentType)
    .subscribe({
      next: (event) => {
        this.camposFormularios = event.data as Campo[];
        this.changeDetector.detectChanges();
        this.setFieldSettings();
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        let errorMessage = err?.error as ErrorResponse;
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              this.toastService.error(error);
            }
          );
        }
        this.changeDetector.detectChanges();
      }
    });
  }

  public get checkIfAllFormsAreValid(): boolean{
    return !!this.documento?.isDocumentValid() &&
      !!this.cliente?.isClientValid() &&
      !!this.dados?.areComplementaryDataValid &&
      !!this.documentosAnexosLiberacao?.areAllFilesValid &&
      (this.isDocumentDAT ?
        this.gridDueConteiner?.isDueConteinerRelationValid :
        (
          this.relacao?.areAllContainersValid &&
          this.arvore?.areAllDocumentoParteLinesValid
        ));
  }

  public getDocuments(): void{
    this.documento.setDocumentTypes(this.documentosService.GetDocumentsForSolicitacaoLiberacaoExp());
  }

  public setMaskFromDocumentSelected(mask: Mascara): void{
    // console.log(mask)
    this.maskForArvorePartes = mask;
    this.changeDetector.detectChanges();
  }

  public updateArvorePartes(): void{
    // this.changeDetector?.detectChanges();
    console.log('att')
    if(!this.relacao){
      return;
    }
    let containers = this.relacao.getSelectedContainersFromForms();
    if(!containers?.length){
      this.arvore?.clean();
      return;
    }
    if(!containers.find(container => container.parte && container.quantidadePartes)){
      this.arvore?.clean();
      return;
    }
    let documentosPartesGrid = this.arvore?.getArvorePartesFromForm() ?? [];
    let arvorePartes: DocumentoParteEXP[] = [];
    containers.forEach(
      (conteiner) => {
        let partesConteiner = documentosPartesGrid.filter(parte => parte.conteiner == conteiner.conteiner) ?? [];
        let qtdPartes: number = conteiner?.quantidadePartes ?? 0;
        if(partesConteiner.length < (qtdPartes - 1)){
          while(partesConteiner.length < (qtdPartes - 1)){
            partesConteiner.push({
              conteiner: conteiner.conteiner,
              numeroDocumentoParte: '',
              canalDocumentoParte: '',
              situacaoAduaneiraParte: '',
              parteCadastrada: false
            });
          }
        }
        if(partesConteiner.length > (qtdPartes - 1)){
          while(partesConteiner.length > (qtdPartes - 1) && partesConteiner.length > 0){
            partesConteiner.pop();
          }
        }
        arvorePartes.push(...partesConteiner);
      }
    );
    this.arvore.setFormValuesFromArvorePartes(arvorePartes);
  }

  public savePartDocument(): void{
    if(!this.isSalvarDocumentoParteEnabled){
      return;
    }
    this.spinner.show();
    this.isRequesting = true;
    const correlationId = uuid.v4();
    this.solicitacaoLiberacaoExportacaoService.savePartDocument(this.setSolicitationForSavingPartDocument(), correlationId)
      .subscribe({
        next: (result) => {
          this.isPartDocumentSaved = true;
          this.isRequesting = false;
          this.spinner.hide();
          let integrations: RetornoStatusDocumentoParteDTO[] = result.data as RetornoStatusDocumentoParteDTO[];
          if(integrations && integrations.length){
            this.modalIntegracaoDocumentoParte.openModal(integrations);
            this.erasePartDocumentsFromArvorePartesOnIntegrationFail(integrations);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
          this.isRequesting = false;
        }
      });
  }

  private setSolicitationForSavingPartDocument(): CadastroDocumentoParteEXPDTO{
    let solicitation: SolicitacaoLiberacaoExportacao = this.getSolicitationFromForms();
    let cadastroDocumentoParte: CadastroDocumentoParteEXPDTO = {
      numeroDocumentoSolicitacaoOriginal: solicitation.documento?.numeroDocumento as string,
      tipoDocumentoSolicitacaoOriginal: solicitation.documento?.tipoDocumento as string,
      conteineres:
        this.relacao.getSelectedContainersFromForms().filter(
          (conteiner) => conteiner.parte
        ) ?? []
    };
    let arvorePartes: DocumentoParteEXP[] = this.arvore.getArvorePartesFromForm() ?? [];
    cadastroDocumentoParte.conteineres.forEach(
      (conteiner) => {
        conteiner.documentos = arvorePartes.filter(
          (documentoParte) => documentoParte.conteiner == conteiner.conteiner
        ) ?? [];
      }
    );
    return cadastroDocumentoParte;
  }

  private erasePartDocumentsFromArvorePartesOnIntegrationFail(integrations: RetornoStatusDocumentoParteDTO[]): void{
    for(let index: number = 0; index < integrations.length; index++){
      if(!integrations[index].sucesso && integrations[index].concluido){
        this.arvore?.erasePartDocumentByFailedIntegrationNumber(integrations[index].numeroDocumento);
      }
    }
  }

  public searchClient(cliente: Cliente): void{
    this.solicitacaoLiberacaoExportacaoService.getCliente(cliente)
      .subscribe({
        next: (result) => {
          this.cliente?.setSearchedClientData(result.data as Cliente);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public getSolicitationFromForms(): SolicitacaoLiberacaoExportacao{
    let solicitation: SolicitacaoLiberacaoExportacao = {};
    solicitation.id = this.formOut.value.id;
    solicitation.documento = this.documento?.getDocumentFromFormFields();
    solicitation.cliente = this.cliente?.getClientFromForm();
    solicitation.dadosComplementares = this.dados?.getComplementaryDataFromForm();
    solicitation.relacaoConteineres = this.relacao?.getContainersFromForms();
    solicitation.arvorePartes = this.arvore?.getArvorePartesFromForm();
    solicitation.relacaoDueConteiner = this.gridDueConteiner?.getDueConteinerRelation();
    solicitation.documentosAnexosLiberacao = this.documentosAnexosLiberacao?.getAllDocumentsWithAttachedFiles();
    return solicitation;
  }

  public get isPartDocumentAbsentOrSaved(): boolean{
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      return true;
    }
    if(!this.arvore?.getArvorePartesFromForm()?.length){
      return true;
    }
    if(this.arvore?.arePartDocumentsSameAsStored){
      return true;
    }
    return this.isPartDocumentSaved;
  }

  public get isConcludeButtonEnabled(): boolean{
    return this.isSearchDone &&
      !this.isRequesting &&
      this.checkIfAllFormsAreValid &&
      this.isPartDocumentAbsentOrSaved;
  }

  private setFieldSettings(): void{
    this.documento?.setSettingsfromFields();
    this.cliente?.setSettingsfromFields();
    this.dados?.setSettingsfromFields();
    this.relacao?.setSettingsfromFields();
    this.arvore?.setSettingsfromFields();
    this.changeDetector.detectChanges();
  }

  public get isSaveDraftButtonEnabled(): boolean{
    if(this.isRequesting){
      return false;
    }
    if(this.arvore?.storedPartDocuments){
      if(this.arvore?.arePartDocumentsSameAsStored){
        return true && this.relacao?.areAllContainersValid;
      }
      else{
        return this.isPartDocumentAbsentOrSaved && this.relacao?.areAllContainersValid;
      }
    }
    else{
      return this.isPartDocumentAbsentOrSaved && this.relacao?.areAllContainersValid;
    }
  }

  public downloadFileToDisplay(arquivo: {arquivoDocumentoAnexado: ArquivoDocumentoAnexado, index: number}): void{
    this.documentosAnexosLiberacao?.downloadFileToDisplay(this.solicitacaoLiberacaoExportacaoService.downloadFileForAttachedDocumentGrid(arquivo.arquivoDocumentoAnexado), arquivo.index);
  }

  public downloadFileToSave(arquivo: {arquivoDocumentoAnexado: ArquivoDocumentoAnexado, index: number}): void{
    this.documentosAnexosLiberacao?.downloadFileToSave(this.solicitacaoLiberacaoExportacaoService.downloadFileForAttachedDocumentGrid(arquivo.arquivoDocumentoAnexado), arquivo.index);
  }

  private saveAttachedFiles(solicitationId: number): void{
    var documentos = this.documentosAnexosLiberacao?.getAllDocumentsWithAttachedFiles();
    if(!documentos?.length){
      this.spinner.hide();
      this.resetPage();
      this.isRequesting = false;
      return;
    }
    this.solicitacaoLiberacaoExportacaoService.arquivoUploadSolicitacaoLiberacaoExp(documentos, solicitationId).subscribe(
    {
      next: (msg) => {
        this.spinner.hide();
        console.log(msg)
        msg?.message?.forEach(
          (message) => {
            this.toastService.success(message);
          }
        );
        this.resetPage();
        this.isRequesting = false;
      },
      error: (err: HttpErrorResponse) =>{
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
        this.isRequesting = false;
      },
      complete: () => {
        this.spinner.hide();
      }
    }
  );
  }

  public eraseConteiner (conteiner: ConteinerEXP): void{
    const {id} = this.getSolicitationFromForms()
    if (!id || !conteiner.conteiner) return this.relacao.eraseConteinerFromGrid(conteiner);
    this.spinner.show();
    this.solicitacaoLiberacaoExportacaoService.postCancelarSolicitacao(this.getSolicitationFromForms(), conteiner)
      .subscribe({
        next: (result) => {
          this.relacao.eraseConteinerFromGrid(conteiner);
          result?.message?.forEach(
            (message) => {
              this.toastService.success(message);
            }
          );
          this.spinner.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
}

  public searchShipAndTripByContainerNumber(containerNumber: string): void{
    if(!containerNumber?.length){
      return;
    }
    this.solicitacaoLiberacaoExportacaoService.searchShipAndTripByContainerNumber(containerNumber)
      .subscribe({
        next: (result) => {
          this.dados?.setShipAndTripFromSearchByContainerNumber(result.data as DadosNavioVigemDTO);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  private getSelectFieldsForFormComponents(): void{
    //Combobox canal
    this.documentosService.getCanais().subscribe({
      next: (canais) =>{
        this.canais = canais;
        this.changeDetector.detectChanges();
      }
    });
    //Combobox moeda
    this.documentosService.getMoedas().subscribe({
      next: (moedas) =>{
        this.moedas = moedas;
        this.changeDetector.detectChanges();
      }
    });
  }
}

