<div class="mt-5">
  <app-subpage-tabs [activeTabPath]="selectedPage" [tabs]="subpageTabs" (onSelectTab)="changeSelectedPage($event)"></app-subpage-tabs>
  <div [ngSwitch]="selectedPage">
    <app-cadastro-documento-estimativa-calculo
      [auth]="this.visualizacaoCadastroDocumentoEstimativaCalculo"
      *ngSwitchCase="'cadastro-documento-estimativa-calculo'"
    ></app-cadastro-documento-estimativa-calculo>
    <app-solicitacao-faturamento-estimativa-calculo
      [auth]="this.visualizacaoSolicitacaoFaturamentoEstimativaCalculo"
      *ngSwitchCase="'solicitacao-faturamento-estimativa-calculo'"
      (onExpandSolicitation)="openSolicitationFromGrid($event)"
    ></app-solicitacao-faturamento-estimativa-calculo>
  </div>
</div>
