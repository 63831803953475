import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { managerTokenService } from "src/app/services/managerToken/manager-token.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: 'portal-tecon-redirect',
  templateUrl: './portal-tecon-redirect.component.html',
  styleUrls: ['./portal-tecon-redirect.component.scss']
})

export class PortalTeconRedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private managerTokenService: managerTokenService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
  ) {}

  public ngOnInit(): void {
    this.spinner.show("Carregando");
    this.route.queryParams
      .subscribe(async params => {

          this.getTokenFromServer(params);
          this.spinner.hide();
      })}


    getTokenFromServer(params: any) {
      this.managerTokenService.consultarMatricula(params['accessToken']).subscribe(
        resp => {

          var isTokenExpired = this.authenticationService.getTokenExpirationDate();

          if(isTokenExpired == null || isTokenExpired < new Date()) 
          {
             this.authenticationService.refreshToken();
          }

          this.authenticationService.decodeToken(resp.data.token);
          this.redirectPage(params);
        },
        err => {
          console.log(err);
          this.toastService.error("Não foi possível recuperar o TOKEN");
          
        });
    }

    getTokenFromStorage(getLocalStorageToken: any, params: any) {
      var user = JSON.parse(getLocalStorageToken);
        this.redirectPage(params);
    }

    redirectPage(params: any)
    {
      
      if (params['controllerName'] == 'CadastroDocumento')
        this.router.navigate(['estimativa-calculo']);

      if (params['controllerName'] == 'SolicitacaoLiberacaoExportacao')
        this.router.navigate(['solicitacao-liberacao-exportacao']);

      if (params['controllerName'] == 'RecepcaoConteiner')
        this.router.navigate(['recepcao-conteiner']);

      if (params['controllerName'] == 'EntregaDueContingencia')
        this.router.navigate(['entrega-due-contingencia']);

      if (params['controllerName'] == 'BloqueioDesbloqueioConteinerEmbarque')
        this.router.navigate(['bloqueio-desbloqueio-conteiner-embarque']);

      if (params['controllerName'] == 'EmissaoTicketPesagemExportacao')
        this.router.navigate(['emissao-ticket-pesagem-exportacao']);

      if (params['controllerName'] == 'SolicitacaoLiberacaoImportacao')
        this.router.navigate(['solicitacao-liberacao-importacao']);

      if (params['controllerName'] == 'SolicitacaoLiberacaoCabotagem')
        this.router.navigate(['solicitacao-liberacao-cabotagem']);
      
      if (params['controllerName'] == 'LiberacaoDocumental')
        this.router.navigate(['liberacao-documental']);
    
      if (params['controllerName'] == 'MonitoramentoRpaApi')
        this.router.navigate(['monitoramento-rpa-api']);

      if (params['controllerName'] == 'CabotagemEmbarque')
        this.router.navigate(['cabotagem-embarque']);

      if (params['controllerName'] == 'RelatorioConteineresLiberados')
        this.router.navigate(['relatorio-conteineres-liberados']);

      if (params['controllerName'] == 'RelatorioMovimentacaoCliente')
        this.router.navigate(['relatorio-movimentacao-clientes']);

      if (params['controllerName'] == 'RelatorioRecepcaoNFDatConteiner')
        this.router.navigate(['recepcao-nf-dat-conteiner']);

      if (params['controllerName'] == 'RelatorioEntregaDaCargaNavio')
        this.router.navigate(['relatorio-entrega-da-carga-navio']);
      
      if (params['controllerName'] == 'RelatorioReprovacaoAprovacaoLiberacao')
        this.router.navigate(['reprovacao-aprovacao-liberacao']);
    }

}