import { VerificacaoDocumentosService } from './../../../shared/utils/verificacao-documentos.service';
import { DocumentoParteIMP } from './../../../models/SolicitacaoLiberacaoIMP/DocumentoParteIMP';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Canal } from 'src/app/models/Canal';
import { Mascara } from 'src/app/models/Mascara';
import { Campo } from 'src/app/models/Campo';
import { MaskPipe } from 'ngx-mask';
import { DefaultMaskPattern } from 'src/app/shared/utils/constantes-documentos';

@Component({
  selector: 'tr[app-linha-grid-arvore-de-partes]',
  templateUrl: './linha-grid-arvore-de-partes.component.html',
  styleUrls: ['./linha-grid-arvore-de-partes.component.scss']
})
export class LinhaGridArvoreDePartesComponent implements AfterViewInit {

  public numeroDocumentoParte!: UntypedFormControl;
  public canalDocumentoParte!: UntypedFormControl;
  public numeroCE!: UntypedFormControl;
  public conteiner!: UntypedFormControl;
  public id!: UntypedFormControl;
  public parteCadastrada!: UntypedFormControl;
  public formDocumentoParte!: UntypedFormGroup;
  @Input() documentoParte!: DocumentoParteIMP;
  @Input() canais!: Canal[];
  @Input() mascara!: Mascara | undefined;
  @Input() camposFormulario!: Campo[];
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() visualizar?: boolean = false;
  @Input() shouldBlockPartQuantityEditionByDocumentType?: boolean;
  @Output() onSendArvoreParte: EventEmitter<DocumentoParteIMP> = new EventEmitter<DocumentoParteIMP>();
  @Output() onFirstEdit: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private maskPipe: MaskPipe
  ) { }

  public ngAfterViewInit(): void {
    this.validation();
    if(this.visualizar){
      this.disableAllFormFields();
    }
  }

  private validation(): void{
    this.numeroDocumentoParte = new UntypedFormControl(
      (
        !this.mascara?.modelo?.length ?
        this.documentoParte?.numeroDocumentoParte :
        this.maskPipe.transform(this.documentoParte?.numeroDocumentoParte, [this.mascara?.modelo, DefaultMaskPattern.DEFAULT_MASK_CHARS])
      ),
      Validators.compose([this.canDocumentNumberBeEmptyBecauseOfType.bind(this)])
    );
    this.canalDocumentoParte = new UntypedFormControl(this.canais.find(canal => canal.nome === this.documentoParte?.canalDocumentoParte)?.nome ?? this.documentoParte?.canalDocumentoParte, Validators.compose([Validators.required]));
    this.numeroCE = new UntypedFormControl(this.documentoParte?.numeroCE ?? this.documentoParte?.canalDocumentoParte, Validators.compose([Validators.required]));
    this.conteiner = new UntypedFormControl(this.documentoParte?.conteiner, Validators.compose([Validators.required]));
    this.id = new UntypedFormControl(this.documentoParte?.id ?? undefined, Validators.compose([]));
    this.parteCadastrada = new UntypedFormControl(!!this.documentoParte?.parteCadastrada, Validators.compose([Validators.required]));
    this.formDocumentoParte = this.fb.group({
      numeroDocumentoParte: this.numeroDocumentoParte,
      canalDocumentoParte: this.canalDocumentoParte,
      numeroCE: this.numeroCE,
      conteiner: this.conteiner,
      id: this.id,
      parteCadastrada: this.parteCadastrada
    });
    if(!!this.parteCadastrada?.value){
      this.numeroDocumentoParte.disable();
      this.canalDocumentoParte.disable();
    }
    this.formDocumentoParte.valueChanges.subscribe(
      () => {
        this.sendArvoreParte();
      }
    );
    this.sendArvoreParte();
    this.setSettingsfromFields();
  }

  private sendArvoreParte(): void{
    let arvorePartes: DocumentoParteIMP = this.formDocumentoParte.getRawValue();
    this.onSendArvoreParte.emit(arvorePartes);
    this.changeDetector.detectChanges();
  }

  public isDocumentoParteValid(): boolean{
    return !this.formDocumentoParte.invalid;
  }

  public getDocumentoParteFromFormFields(): DocumentoParteIMP{
    let documentoParte = this.formDocumentoParte.getRawValue() as DocumentoParteIMP;
    documentoParte.numeroDocumentoParte = this.verificacaoDocumentosService.eraseMask(documentoParte.numeroDocumentoParte);
    return documentoParte;
  }

  public setSettingsfromFields(): void{
    if(!this.formDocumentoParte){
      return;
    }
    if(this.visualizar || this.shouldBlockPartQuantityEditionByDocumentType){
      this.disableAllFormFields();
      return;
    }
    if(!this.camposFormulario?.length){
      this.enableAllFormFields();
      return;
    }
    for(let control in this.formDocumentoParte.controls){
      let fieldIndex = this.camposFormulario.findIndex(
        (field) => field.nome == control
      );
      if(fieldIndex >= 0){
        if(this.camposFormulario[fieldIndex].obrigatorio){
          this.formDocumentoParte.get(control)?.addValidators(Validators.required);
        }
        else{
          this.formDocumentoParte.get(control)?.removeValidators(Validators.required);
        }
        if(this.doesSolicitationHaveIntegration){
          if(this.camposFormulario[fieldIndex].leitura && !!this.formDocumentoParte.get(control)?.value){
            this.formDocumentoParte.get(control)?.disable();
          }
          else{
            this.formDocumentoParte.get(control)?.enable();
          }
        }
        else{
          if(this.camposFormulario[fieldIndex].leitura){
            this.formDocumentoParte.get(control)?.disable();
          }
          else{
            this.formDocumentoParte.get(control)?.enable();
          }
        }
        if(!!this.camposFormulario[fieldIndex]?.bloqueado){
          this.formDocumentoParte.get(control)?.disable();
        }
        if(!this.formDocumentoParte.get(control)?.value && this.camposFormulario[fieldIndex].obrigatorio){
          this.formDocumentoParte.get(control)?.enable();
        }
      }
      else{
        this.formDocumentoParte.get(control)?.enable();
        this.formDocumentoParte.get(control)?.removeValidators(Validators.required);
      }
    }
    if(!!this.parteCadastrada?.value){
      this.numeroDocumentoParte.disable();
    }
    this.changeDetector.detectChanges();
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.formDocumentoParte.controls){
      this.formDocumentoParte.get(field)?.enable();
      this.formDocumentoParte.get(field)?.removeValidators(Validators.required);
      this.changeDetector.detectChanges();
    }
  }

  public disableAllFormFields(): void{
    this.formDocumentoParte.disable({emitEvent: false});
    this.changeDetector.detectChanges();
  }

  private canDocumentNumberBeEmptyBecauseOfType(control: AbstractControl): {invalid: boolean} | null{
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      return null;
    }
    if(!control?.value?.length){
      return {invalid: true};
    }
    return null;
  }

}
