<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Condutor</span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}"
        data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false"
        aria-label="Toggle navigation"></i>
      <!--fa fa-chevron-down-->
    </div>
  </div>

  <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <form *ngIf="form" [formGroup]="form">
      <div class="p-3">
        <div class="row">
          <div class="col">
            <label>CPF:</label>
            <input formControlName="cpfCondutor" type="text" class="form-control"
              placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
              [mask]="maskForClientDocument"
              [ngClass]="{'border border-danger': !form.get('cpfCondutor')?.pristine && (form.get('cpfCondutor')?.errors || validateClientDocument(form.get('cpfCondutor')?.value))}">
            <p *ngIf="!form.get('cpfCondutor')?.pristine && (form.get('cpfCondutor')?.errors || validateClientDocument(form.get('cpfCondutor')?.value))?.length"
              class="text-danger">
              {{validateClientDocument(form.get('cpfCondutor')?.value)}}
            </p>
          </div>
          <div class="col">
            <label>Nome: </label>
            <div class="input-group mb-3">
              <input formControlName="nomeCondutor" type="text" class="form-control"
                placeholder="Digite aqui..." aria-label="Digite aqui..." aria-describedby="basic-addon2"
                [mask]="maskForNames" [patterns]="patternForNames" maxlength="100">
            </div>

          </div>
        </div>
      </div>
    </form>
  </div>
</div>
