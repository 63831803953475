import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MaskPipe } from 'ngx-mask';
import { Campo } from 'src/app/models/Campo';
import { DocumentoParteEstimativaCalculo } from 'src/app/models/EstimativaCalculo/CadastroDocumento/DocumentoParteEstimativaCalculo';
import { Mascara } from 'src/app/models/Mascara';
import { DefaultMaskPattern } from 'src/app/shared/utils/constantes-documentos';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';

@Component({
  selector: 'tr[app-linha-grid-arvore-partes-estimativa-calculo]',
  templateUrl: './linha-grid-arvore-partes-estimativa-calculo.component.html',
  styleUrls: ['./linha-grid-arvore-partes-estimativa-calculo.component.scss']
})
export class LinhaGridArvorePartesEstimativaCalculoComponent implements AfterViewInit {

  public numeroDocumentoParte!: UntypedFormControl;
  public numeroCE!: UntypedFormControl;
  public conteiner!: UntypedFormControl;
  public id!: UntypedFormControl;
  public quantidadePartes!: UntypedFormControl;
  public parteCadastrada!: UntypedFormControl;
  public formDocumentoParte!: UntypedFormGroup;
  @Input() documentoParte!: DocumentoParteEstimativaCalculo;
  @Input() mascara!: Mascara | undefined;
  @Input() camposFormulario!: Campo[];
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() visualizar?: boolean = false;
  @Input() shouldBlockPartQuantityEditionByDocumentType?: boolean;
  @Input() isImpDocument?: boolean;
  @Input() isExpDocument?: boolean;
  @Output() onSendArvoreParte: EventEmitter<DocumentoParteEstimativaCalculo> = new EventEmitter<DocumentoParteEstimativaCalculo>();
  @Output() onFirstEdit: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private maskPipe: MaskPipe
  ) { }

  public ngAfterViewInit(): void {
    this.validation();
    if(this.visualizar){
      this.disableAllFormFields();
    }
  }

  private validation(): void{
    this.numeroDocumentoParte = new UntypedFormControl(
      (
        !this.mascara?.modelo?.length ?
        this.documentoParte?.numeroDocumentoParte :
        this.maskPipe.transform(this.documentoParte?.numeroDocumentoParte, [this.mascara?.modelo, DefaultMaskPattern.DEFAULT_MASK_CHARS])
      ),
      Validators.compose([this.canDocumentNumberBeEmptyBecauseOfType.bind(this)])
    );
    this.numeroCE = new UntypedFormControl(this.documentoParte?.numeroCE ?? this.documentoParte?.numeroCE, Validators.compose([Validators.required]));
    this.conteiner = new UntypedFormControl(this.documentoParte?.conteiner, Validators.compose([Validators.required]));
    this.id = new UntypedFormControl(this.documentoParte?.id ?? undefined, Validators.compose([]));
    this.quantidadePartes = new UntypedFormControl(this.documentoParte?.quantidadePartes ?? undefined, Validators.compose([]));
    this.parteCadastrada = new UntypedFormControl(!!this.documentoParte?.parteCadastrada, Validators.compose([Validators.required]));
    this.formDocumentoParte = this.fb.group({
      numeroDocumentoParte: this.numeroDocumentoParte,
      numeroCE: this.numeroCE,
      conteiner: this.conteiner,
      id: this.id,
      quantidadePartes: this.quantidadePartes,
      parteCadastrada: this.parteCadastrada
    });
    if(!!this.parteCadastrada?.value){
      this.numeroDocumentoParte.disable();
      this.numeroCE.disable();
    }
    this.formDocumentoParte.valueChanges.subscribe(
      () => {
        this.sendArvoreParte();
      }
    );
    this.sendArvoreParte();
    this.setSettingsfromFields();
  }

  private sendArvoreParte(): void{
    let arvorePartes: DocumentoParteEstimativaCalculo = this.formDocumentoParte.getRawValue();
    this.onSendArvoreParte.emit(arvorePartes);
    this.changeDetector.detectChanges();
  }

  public isDocumentoParteValid(): boolean{
    return !this.formDocumentoParte.invalid;
  }

  public getDocumentoParteFromFormFields(): DocumentoParteEstimativaCalculo{
    let documentoParte = this.formDocumentoParte.getRawValue() as DocumentoParteEstimativaCalculo;
    documentoParte.numeroDocumentoParte = this.verificacaoDocumentosService.eraseMask(documentoParte.numeroDocumentoParte);
    return documentoParte;
  }

  public setSettingsfromFields(): void{
    if(this.visualizar || this.shouldBlockPartQuantityEditionByDocumentType){
      this.disableAllFormFields();
      return;
    }
    if(!this.camposFormulario?.length){
      this.enableAllFormFields();
      return;
    }
    for(let control in this.formDocumentoParte.controls){
      let fieldIndex = this.camposFormulario.findIndex(
        (field) => field.nome == control
      );
      if(fieldIndex >= 0){
        if(this.camposFormulario[fieldIndex].obrigatorio){
          this.formDocumentoParte.get(control)?.addValidators(Validators.required);
        }
        else{
          this.formDocumentoParte.get(control)?.removeValidators(Validators.required);
        }
        if(this.doesSolicitationHaveIntegration){
          if(this.camposFormulario[fieldIndex].leitura && !!this.formDocumentoParte.get(control)?.value){
            this.formDocumentoParte.get(control)?.disable();
          }
          else{
            this.formDocumentoParte.get(control)?.enable();
          }
        }
        else{
          if(this.camposFormulario[fieldIndex].leitura){
            this.formDocumentoParte.get(control)?.disable();
          }
          else{
            this.formDocumentoParte.get(control)?.enable();
          }
        }
        if(!!this.camposFormulario[fieldIndex]?.bloqueado){
          this.formDocumentoParte.get(control)?.disable();
        }
        if(!this.formDocumentoParte.get(control)?.value && this.camposFormulario[fieldIndex].obrigatorio){
          this.formDocumentoParte.get(control)?.enable();
        }
      }
      else{
        this.formDocumentoParte.get(control)?.enable();
        this.formDocumentoParte.get(control)?.removeValidators(Validators.required);
      }
    }
    if(!!this.parteCadastrada?.value){
      this.numeroDocumentoParte.disable();
    }
    this.changeDetector.detectChanges();
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.formDocumentoParte.controls){
      this.formDocumentoParte.get(field)?.enable();
      this.formDocumentoParte.get(field)?.removeValidators(Validators.required);
      this.changeDetector.detectChanges();
    }
  }

  public disableAllFormFields(): void{
    this.formDocumentoParte.disable({emitEvent: false});
    this.changeDetector.detectChanges();
  }

  private canDocumentNumberBeEmptyBecauseOfType(control: AbstractControl): {invalid: boolean} | null{
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      return null;
    }
    if(!control?.value?.length){
      return {invalid: true};
    }
    return null;
  }

}
