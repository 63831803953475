<ng-template #modalSelecaoConteineresDivergentes>
  <div class="modal-header">
    <h4>Divergências</h4>
  </div>
  <div class="modal-body">
    <p>A(s) unidade(s) abaixo já embarcou(aram) anteriormente em outro navio? (S/N)</p>
    <div class="row my-3" *ngFor="let container of containers">
      <div class="col-11 text-left">
        <label>{{container}}</label>
      </div>
      <div class="col-1 text-right">
        <input type="checkbox" class="form-control" [value]="container" [checked]="selectedContainers.includes(container)" (change)="switchSelected(container)">
      </div>
    </div>
    <div class="action-button-group">
      <button class="action-primary-button" (click)="confirmSelection()">Confirmar</button>
    </div>
  </div>
</ng-template>
