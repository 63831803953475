import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { RelatorioEntregaCargaNavio } from 'src/app/models/relatorios/RelatorioEntregaCargaNavio/RelatorioEntregaCargaNavio';
import { FiltroRelatorioEntregaCargaNavio } from 'src/app/models/relatorios/RelatorioEntregaCargaNavio/FiltroRelatorioEntregaCargaNavio';
import { RelatorioEntregaCargaNavioService } from 'src/app/services/relatorios/relatorio-entrega-carga-navio.service';
import { ExcelService } from 'src/app/services/excel.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { ToastService } from 'src/app/services/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FiltroRelatorioEntregaDaCargaNavioComponent } from 'src/app/components/relatorios/relatorio-entrega-da-carga-navio/filtro-relatorio-entrega-da-carga-navio/filtro-relatorio-entrega-da-carga-navio.component';
import { GridRelatorioEntregaDaCargaNavioComponent } from 'src/app/components/relatorios/relatorio-entrega-da-carga-navio/grid-relatorio-entrega-da-carga-navio/grid-relatorio-entrega-da-carga-navio.component';
import { ImpressaoRelatorioEntregaDaCargaNavioComponent } from 'src/app/components/relatorios/relatorio-entrega-da-carga-navio/impressao-relatorio-entrega-da-carga-navio/impressao-relatorio-entrega-da-carga-navio.component';
import { PrintService } from 'src/app/services/relatorios/print.service';


@Component({
  selector: 'app-relatorio-entrega-da-carga-navio',
  templateUrl: './relatorio-entrega-da-carga-navio.component.html',
  styleUrls: ['./relatorio-entrega-da-carga-navio.component.scss']
})
export class RelatorioEntregaDaCargaNavioComponent implements OnInit {

  public title: string = 'Entrega da Carga por Navio e por Período ';
  public subtitle: string = '';
  // public displayDocumentalLiberationSolicitations!: SolicitacaoLiberacaoDocumental[];
  public receivedRelatorioEntregaCargaNavio: RelatorioEntregaCargaNavio[] = [];
  public modalRef!: BsModalRef;
  public filtroRelatorioEntregaCargaNavio!: FiltroRelatorioEntregaCargaNavio;
  protected isPrinting: boolean = false;
  @ViewChild(ImpressaoRelatorioEntregaDaCargaNavioComponent) protected impressao!: ImpressaoRelatorioEntregaDaCargaNavioComponent;
  @ViewChild(FiltroRelatorioEntregaDaCargaNavioComponent) private filtroRelatorioEntregaDaCargaNavioComponent!: FiltroRelatorioEntregaDaCargaNavioComponent;
  @ViewChild(GridRelatorioEntregaDaCargaNavioComponent) private gridRelatorioEntregaDaCargaNavioComponent!: GridRelatorioEntregaDaCargaNavioComponent;
  public readonly visualizacao = new AuthRoles(Telas.relatorioEntregaDaCargaNavio, Permissoes.visualizacao)

  constructor(
    private relatorioEntregaCargaNavioService: RelatorioEntregaCargaNavioService,
    private excelService:ExcelService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private toastService:ToastService,
    private changeDetector: ChangeDetectorRef,
    private printService: PrintService
  ) { }

  public ngOnInit(): void {
    // this.displayDocumentalLiberationSolicitations = this.liberacaoDocumentalService.getDocumentalLiberationSolicitations();
  }

  public searchFilteredEntregaCargasNavios(filtro: FiltroRelatorioEntregaCargaNavio): void {
    this.filtroRelatorioEntregaCargaNavio = filtro;
    this.clearEntregaCargasNavios();
    this.spinner.show();
    this.relatorioEntregaCargaNavioService.searchEntregaCarga(filtro).subscribe({
      next:(response) => {
        this.spinner.hide();
        let relatorio = response.data as RelatorioEntregaCargaNavio[];
        if(relatorio?.length){
          this.setEntregaCargasNavios(relatorio);
        }
        response?.message?.forEach(
          (msg) => {
            if(msg?.length){
              this.toastService.error(msg);
            }
          }
        );
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      }
    })
  }


  private setEntregaCargasNavios(relatorioConteineresLiberadosGrid: RelatorioEntregaCargaNavio[]): void{
    this.receivedRelatorioEntregaCargaNavio = [...relatorioConteineresLiberadosGrid];
  }

  private clearEntregaCargasNavios(): void{
    this.receivedRelatorioEntregaCargaNavio = [];
  }

  public downloadPDF(){
    this.isPrinting = true;
    this.changeDetector.detectChanges();
    this.impressao.setPrintingData(
      {
        dataInicio: this.filtroRelatorioEntregaCargaNavio?.de ? this.filtroRelatorioEntregaCargaNavio.de : undefined,
        dataFim: this.filtroRelatorioEntregaCargaNavio?.ate ? this.filtroRelatorioEntregaCargaNavio.ate : undefined,
        nomeRelatorio: this.title,
        dados: this.receivedRelatorioEntregaCargaNavio,
      }
    );
    this.printService.setPrinting(this.impressao.getTemplate());
    this.changeDetector.detectChanges();
    window.onafterprint = () => {
      this.isPrinting = false;
      this.printService.unsetPrinting();
    }
    setTimeout(() => {
      window.print();
    }, 500);
  }

  public downloadExcel() {
    let header = [
      { nome: 'Tipo de Documento', key: 'tipoDocumento', width: 25 },
      { nome: 'Numero', key: 'numeroDocumento', width: 25 },
      { nome: 'Contêiner', key: 'conteiner', width: 25 },
      { nome: 'Data de Entrega', key: 'dataHoraEntrega', width: 30 },
      { nome: 'Navio', key: 'navio', width: 25 },
      { nome: 'Viagem', key: 'viagem', width: 30 },
      { nome: 'Booking', key: 'booking', width: 25 },
      { nome: 'Status', key: 'status', width: 25 },
      { nome: 'Armador', key: 'armador', width: 25 },
      { nome: 'CNPJ Armador', key: 'cnpjArmador', width: 25 },

    ];
    let data = this.receivedRelatorioEntregaCargaNavio?.map(
      (relatorio) => {
        return {
          ...relatorio,
          dataHoraEntrega: this.datePipe.transform(relatorio.dataHoraEntrega, 'dd/MM/YYYY HH:mm:ss')
        }
      }
    ) ?? [];
   this.excelService.exportAsExcelFileCustom(data,'Entrega-Carga',header,this.title)
  }

  public clean() {
      this.gridRelatorioEntregaDaCargaNavioComponent.clearFields();
      this.filtroRelatorioEntregaDaCargaNavioComponent.clearFields();
  }
}

