<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Dados do Envio</span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
    </div>
  </div>
  <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <form [formGroup]="form">
      <div class="p-3">
        <div class="row mb-3">
          <div class="col-3">
            <label>Data do Envio:</label>
            <input type="text" formControlName="dataEnvio"  readonly [attr.disabled]="'disabled'"
              bsDatepicker #dp="bsDatepicker" class="form-control" aria-describedby="basic-addon2" [bsConfig]="bsConfig"
              placeholder="dd/mm/aaaa"
              style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; ">
          </div>
          <div class="col-3"></div>
          <div class="col-6">
            <label>Usuário:</label>
            <input type="text" class="form-control" formControlName="usuario" placeholder="Digite aqui..."
            [mask]="'N*'" [patterns]="namePattern" readonly>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <label>Status Entrega:</label>
            <input  readonly type="text" class="form-control" formControlName="statusEntrega" placeholder="Digite aqui...">
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
