import { RelacaoConteinerEmissaoTicketPesagemExportacao } from './../../models/EmissaoTicketPesagemExportacao/RelacaoConteinerEmissaoTicketPesagemExportacao';
import { GridEmissaoTicketPesagemExportacaoComponent } from './../../components/emissao-ticket-pesagem-exportacao/grid-emissao-ticket-pesagem-exportacao/grid-emissao-ticket-pesagem-exportacao.component';
import { PesquisaEmissaoTicketPesagemExportacaoComponent } from './../../components/emissao-ticket-pesagem-exportacao/pesquisa-emissao-ticket-pesagem-exportacao/pesquisa-emissao-ticket-pesagem-exportacao.component';
import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from './../../models/HttpResponses/ErrorResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmissaoTicketPesagemExportacaoService } from './../../services/emissao-ticket-pesagem-exportacao.service';
import { PesquisaEmissaoTicketPesagemExportacao } from './../../models/EmissaoTicketPesagemExportacao/PesquisaEmissaoTicketPesagemExportacao';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';
import { RetornoPesquisaTicket } from 'src/app/models/EmissaoTicketPesagemExportacao/RetornoPesquisaTicket';

@Component({
  selector: 'app-emissao-ticket-pesagem-exportacao',
  templateUrl: './emissao-ticket-pesagem-exportacao.component.html',
  styleUrls: ['./emissao-ticket-pesagem-exportacao.component.scss']
})
export class EmissaoTicketPesagemExportacaoComponent implements OnInit {

  public title: string = "Emissão de Ticket de Pesagem para Contêiner de Exportação";
  @ViewChild(PesquisaEmissaoTicketPesagemExportacaoComponent) pesquisa!: PesquisaEmissaoTicketPesagemExportacaoComponent;
  @ViewChild(GridEmissaoTicketPesagemExportacaoComponent) grid!: GridEmissaoTicketPesagemExportacaoComponent;

  public readonly visualizacao = new AuthRoles(Telas.emissaoTicketPesagemExportacao, Permissoes.visualizacao)

  constructor(
    private spinner: NgxSpinnerService,
    private toastService:ToastService,
    private emissaoTicketPesagemExportacaoService: EmissaoTicketPesagemExportacaoService,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
  }

  public searchForContainerRelations(pesquisaRelacao: PesquisaEmissaoTicketPesagemExportacao): void {
    if(!pesquisaRelacao){
      return;
    }
    this.grid.clearContainerRelations();
    this.spinner.show();
    this.emissaoTicketPesagemExportacaoService.searchContainerRelationsByConteinerBookingOrDUE(pesquisaRelacao)
      .subscribe({
        next: (result) => {
          const relacoesConteiner = result.data as RelacaoConteinerEmissaoTicketPesagemExportacao[];
          this.grid.setContainerRelations(relacoesConteiner);
          this.spinner.hide();
          result?.message?.forEach(
            (msg) => {
              if(msg?.length){
                this.toastService.error(msg);
              }
            }
          );
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public printTicket(): void{
    let relacaoConteiner = this.grid.sendSelectedContainerRelations();
    if(!relacaoConteiner?.length){
      return;
    }
    let gkeyList: number[] = relacaoConteiner.map(
      (conteiner) => conteiner.gkey!
    ) ?? [];
    if(!gkeyList?.length){
      return;
    }
    this.emissaoTicketPesagemExportacaoService.searchContainerTicket(gkeyList)
      .subscribe({
        next: (result) => {
          let tickets: RetornoPesquisaTicket[] = result.data;
          if(!tickets?.length){
            result?.message?.forEach(
              (msg) => {
                if(msg?.length){
                  this.toastService.success(msg);
                }
              }
            );
            return;
          }
          result?.message?.forEach(
            (msg) => {
              if(msg?.length){
                if(tickets.find(ticket => ticket.emitido && msg.includes(ticket.gkey.toString()))){
                  this.toastService.error(msg);
                }
                else{
                  this.toastService.success(msg);
                }
              }
            }
          );
          relacaoConteiner.forEach(
            (conteiner) => {
              let ticket = tickets.find(ticketItem => ticketItem.gkey == conteiner.gkey);
              if(ticket){
                conteiner.ticket = ticket.ticket;
              }
            }
          );
          this.emissaoTicketPesagemExportacaoService.printTickets(relacaoConteiner);
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide('carregando');
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public clearData(): void {
    this.spinner.show('limpar');
    setTimeout(() => {
      this.spinner.hide('limpar');
    }, 1500);
    this.grid.clearContainerRelations();
    this.pesquisa.clear();
  }
}
