import { debounceTime } from 'rxjs';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ConteinerCabotagem } from 'src/app/models/SolicitacaoLiberacaoCabotagem/ConteinerCabotagem';
import { Campo } from 'src/app/models/Campo';

@Component({
  selector: 'tr[app-linha-grid-relacao-conteiner-cabotagem]',
  templateUrl: './linha-grid-relacao-conteiner-cabotagem.component.html',
  styleUrls: ['./linha-grid-relacao-conteiner-cabotagem.component.scss']
})
export class LinhaGridRelacaoConteinerCabotagemComponent implements AfterViewInit {

  @Input() relacaoConteiner!: ConteinerCabotagem;
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() camposFormulario!: Campo[];
  @Input() visualizar?: boolean = false;
  @Input() shouldBlockPartQuantityEditionByDocumentType?: boolean;
  @Input() isParteBlocked?: boolean;
  @Output() onEraseLine: EventEmitter<void> = new EventEmitter<void>();
  @Output() onEditFields: EventEmitter<ConteinerCabotagem> = new EventEmitter<ConteinerCabotagem>();
  @Output() onManualSelection: EventEmitter<void> = new EventEmitter<void>();
  @Output() onManualContainerNumberEdition: EventEmitter<string> = new EventEmitter<string>();
  public id!: UntypedFormControl;
  public selecionado!: UntypedFormControl;
  public numeroConteiner!: UntypedFormControl;
  public numeroConteinerTransload!: UntypedFormControl;
  public parte!: UntypedFormControl;
  public quantidadePartes!: UntypedFormControl;
  public qtdPartesFront!: UntypedFormControl;
  public formConteiner!: UntypedFormGroup;
  public minPartQuantity: number = 2;
  public isPartDocumentSaved: boolean = false;
  public isContainerSavedInBase: boolean = false;
  private readonly partFields: string[] = ['parte', 'quantidadePartes'];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fb: UntypedFormBuilder
  ) { }

  public ngAfterViewInit(): void {
    this.validation();
    if(this.visualizar){
      this.disableAllFormFields();
      return;
    }
  }

  private validation(): void{
    this.minPartQuantity = this.relacaoConteiner.quantidadePartes ? this.relacaoConteiner.quantidadePartes : 2
    this.isPartDocumentSaved = !!this.relacaoConteiner?.documentos?.length;
    this.isContainerSavedInBase = !!this.relacaoConteiner?.numeroConteiner?.length;
    this.id = new UntypedFormControl(this.relacaoConteiner.id);
    this.selecionado = new UntypedFormControl(!!this.isPartDocumentSaved);
    this.numeroConteiner = new UntypedFormControl(this.relacaoConteiner.numeroConteiner, Validators.compose([Validators.required]));
    this.numeroConteinerTransload = new UntypedFormControl(this.relacaoConteiner.numeroConteinerTransload, Validators.compose([]));
    this.parte = new UntypedFormControl(this.relacaoConteiner.parte, Validators.compose([Validators.required]));
    this.quantidadePartes = new UntypedFormControl(this.relacaoConteiner.quantidadePartes, Validators.compose([]));
    this.qtdPartesFront = new UntypedFormControl(this.relacaoConteiner.quantidadePartes, Validators.compose([]));
    if(this.doesSolicitationHaveIntegration){
      this.numeroConteiner.disable();
      this.numeroConteinerTransload.disable();
    }
    this.formConteiner = this.fb.group({
      id: this.id,
      selecionado: this.selecionado,
      numeroConteiner: this.numeroConteiner,
      numeroConteinerTransload: this.numeroConteinerTransload,
      parte: this.parte,
      quantidadePartes: this.quantidadePartes
    });
    if(this.isPartDocumentSaved){
      this.selecionado.disable();
    }
    this.numeroConteinerTransload?.valueChanges
    .pipe(
      debounceTime(100)
    )
    .subscribe(
      (valConteinerTransload: string) => {
        valConteinerTransload = valConteinerTransload ?? '';
        this.formConteiner.patchValue({numeroConteinerTransload: valConteinerTransload.toUpperCase()}, {emitEvent: false});
        }
  );
    this.numeroConteiner?.valueChanges
    .pipe(
      debounceTime(100)
    )
    .subscribe(
      (valConteiner: string) => {
        valConteiner = valConteiner ?? '';
        this.formConteiner.patchValue({numeroConteiner: valConteiner.toUpperCase()}, {emitEvent: false});
        }
  );
    this.formConteiner.valueChanges.pipe(
      debounceTime(100)
    ).subscribe(
      () => {
        this.setValidatorForQuantidadePartes(this.formConteiner.get('parte')?.value);
        this.sendContainerRelationData();
      }
    );
    this.changeDetector.detectChanges();
    this.sendContainerRelationData();
    this.setSettingsfromFields();
    this.sendContainerNumberToSearchIfNoIntegration();
  }

  private sendContainerRelationData(): void{
    this.onEditFields.emit(this.getContainerRelationFromFormFields());
    this.changeDetector.detectChanges();
  }

  private setValidatorForQuantidadePartes(validate: boolean): void{
    if(validate){
      if(!this.quantidadePartes.hasValidator(Validators.required)){
        this.quantidadePartes.addValidators([Validators.required, Validators.min(this.minPartQuantity)]);
        this.qtdPartesFront.addValidators([Validators.required, Validators.min(this.minPartQuantity)]);
      }
    }
    else{
      if(this.quantidadePartes.hasValidator(Validators.required)){
        this.quantidadePartes.clearValidators();
        this.qtdPartesFront.removeValidators([Validators.required, Validators.min(this.minPartQuantity)]);
      }
    }
    this.quantidadePartes.updateValueAndValidity({emitEvent: false});
    this.changeDetector.detectChanges();
  }

  public setValidValueForArvorePartes(parte: boolean): void{
    if(parte){
      this.formConteiner.patchValue({
        quantidadePartes:
          this.quantidadePartes.value ?
          (this.quantidadePartes.value < this.minPartQuantity ? this.minPartQuantity : this.quantidadePartes.value) :
          this.minPartQuantity
      }, {emitEvent: true});
      this.qtdPartesFront.setValue(
        this.quantidadePartes.value ?
        (this.quantidadePartes.value < this.minPartQuantity ? this.minPartQuantity : this.quantidadePartes.value) :
        this.minPartQuantity
      );
    }
    else{
      this.formConteiner.patchValue({
        quantidadePartes: 0
      }, {emitEvent: true});
      this.qtdPartesFront.setValue(0);
    }
    this.changeDetector.detectChanges();
  }

  public enableEdition(): void{
    if(this.isContainerSavedInBase || this.isPartDocumentSaved){
      this.numeroConteiner.disable({onlySelf: true, emitEvent: false});
    }
    else{
      this.numeroConteiner.enable({onlySelf: true, emitEvent: false});
    }
    if(this.isParteBlocked){
      this.disablePartFields();
    }
    else{
      this.enablePartFields();
    }
    if(this.isPartDocumentSaved){
      this.parte.disable({onlySelf: true, emitEvent: false});
    }
    else if(!this.isParteBlocked){
      this.parte.enable({onlySelf: true, emitEvent: false});
    }
  }

  public disableEdition(): void{
    this.parte.disable({onlySelf: true, emitEvent: false});
    this.quantidadePartes.disable({onlySelf: true, emitEvent: false});
    this.qtdPartesFront.disable({onlySelf: true, emitEvent: false});
  }

  public isContainerRelationLineValid(): boolean{
    return !this.formConteiner.invalid;
  }

  public getContainerRelationFromFormFields(): ConteinerCabotagem{
    let container = this.formConteiner?.getRawValue() as ConteinerCabotagem;
    if(!container?.quantidadePartes){
      container.quantidadePartes = 0;
    }
    return container;
  }

  public switchSelectionFromParent(selected: boolean): void{
    if(this.isPartDocumentSaved){
      return;
    }
    if(this.selecionado?.disabled){
      return;
    }
    this.formConteiner.patchValue({
      selecionado: selected
    });
    this.switchEdition();
  }

  public switchEdition(): void{
    if(!!this.selecionado.value){
      this.enableEdition();
    }
    else{
      this.formConteiner.patchValue({
        parte: false,
        quantidadePartes: 0,
        // quebraLote: false
      }, {emitEvent: false});
      this.qtdPartesFront.setValue(0);
      this.disableEdition();
    }
    this.changeDetector.detectChanges();
  }

  public setSettingsfromFields(): void{
    if(this.visualizar && !this.isParteBlocked){
      this.disableFormFieldsButPart();
      return;
    }
    if(this.visualizar){
      this.disableAllFormFields();
      return;
    }
    if(!this.camposFormulario?.length){
      this.enableAllFormFields();
      return;
    }
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      this.disablePartFields();
      return;
    }
    for(let control in this.formConteiner.controls){
      let fieldIndex = this.camposFormulario.findIndex(
        (field) => field.nome == control
      );
      if(fieldIndex >= 0){
        if(this.camposFormulario[fieldIndex].obrigatorio){
          this.formConteiner.get(control)?.addValidators(Validators.required);
        }
        else{
          this.formConteiner.get(control)?.removeValidators(Validators.required);
        }
        if(this.camposFormulario[fieldIndex].leitura && !!this.formConteiner.get(control)?.value){
          this.formConteiner.get(control)?.disable();
        }
        else{
          if(control == 'conteiner' || control == 'conteinerTransload'){
            if(this.doesSolicitationHaveIntegration){
              this.numeroConteiner?.disable({emitEvent: false});
              this.numeroConteinerTransload?.disable({emitEvent: false});
              // this.selecionado?.disable({emitEvent: false});
            }
            else{
              this.formConteiner.get(control)?.enable({emitEvent: false});
            }
          }
          else{
            if(this.doesSolicitationHaveIntegration){
              if(this.camposFormulario[fieldIndex].leitura){
                this.formConteiner.get(control)?.disable({emitEvent: false});
              }
              else{
                this.formConteiner.get(control)?.enable({emitEvent: false});
              }
            }
          }
        }
        if(!!this.camposFormulario[fieldIndex]?.bloqueado){
          this.formConteiner.get(control)?.disable();
        }
        if(!this.formConteiner.get(control)?.value && this.camposFormulario[fieldIndex].obrigatorio){
          this.formConteiner.get(control)?.enable();
        }
      }
      else{
        this.formConteiner.get(control)?.enable();
        this.formConteiner.get(control)?.removeValidators(Validators.required);
      }
    }
    if(this.isPartDocumentSaved){
      this.numeroConteiner.disable({onlySelf: true, emitEvent: false});
      this.parte.disable({onlySelf: true, emitEvent: false});
    }
    if(this.isContainerSavedInBase){
      this.numeroConteiner.disable({onlySelf: true, emitEvent: false});
    }
    this.changeDetector.detectChanges();
    this.validateBlocks();
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.formConteiner.controls){
      this.formConteiner.get(field)?.enable();
      this.formConteiner.get(field)?.removeValidators(Validators.required);
      this.changeDetector.detectChanges();
    }
    this.validateBlocks();
  }

  public disableAllFormFields(): void{
    this.formConteiner.disable({emitEvent: false});
    this.changeDetector.detectChanges();
    this.validateBlocks();
  }

  public disableFormFieldsButPart(): void{
    this.formConteiner?.get('selecionado')?.enable({emitEvent: false});
    this.formConteiner?.get('conteiner')?.disable({emitEvent: false});
    this.formConteiner?.get('conteinerTransload')?.disable({emitEvent: false});
    this.changeDetector.detectChanges();
    this.validateBlocks();
  }

  public disablePartFields(): void{
    for(let field of this.partFields){
      this.formConteiner?.get(field)?.disable({emitEvent: false})
    }
    this.qtdPartesFront?.disable({emitEvent: false});
    this.changeDetector.detectChanges();
    this.validateBlocks();
  }

  public enablePartFields(): void{
    for(let field of this.partFields){
      this.formConteiner?.get(field)?.enable({emitEvent: false})
    }
    this.qtdPartesFront?.enable({emitEvent: false});
    this.changeDetector.detectChanges();
    this.validateBlocks();
  }

  public eraseLine(): void{
    if(this.doesSolicitationHaveIntegration || !this.selecionado?.value){
      return;
    }
    this.onEraseLine.emit();
  }

  public get isContainerValid(): boolean{
    return !this.formConteiner?.invalid;
  }

  private validateBlocks(): void{
    if(this.isPartDocumentSaved){
      this.numeroConteiner?.disable({onlySelf: true, emitEvent: false});
      this.parte?.disable({onlySelf: true, emitEvent: false});
    }
    if(this.isContainerSavedInBase){

      this.numeroConteiner?.disable({onlySelf: true, emitEvent: false});
    }

  }

  public sendContainerNumberToSearchIfNoIntegration(): void{
    if(this.doesSolicitationHaveIntegration){
      return;
    }
    if(!this.numeroConteiner?.value?.length){
      return;
    }
    this.onManualContainerNumberEdition.emit(this.numeroConteiner.value)
  }
}
