import { SolicitacaoFaturamentoEstimativaCalculoService } from './../../../../services/EstimativaCalculo/solicitacao-faturamento-estimativa-calculo.service';
import { ModalRespostaPositivaNegativaComponent } from 'src/app/shared/components/modais/modal-resposta-positiva-negativa/modal-resposta-positiva-negativa.component';
import { ToastService } from 'src/app/services/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormControl } from '@angular/forms';
import { GridSolicitacaoFaturamento } from './../../../../models/EstimativaCalculo/SolicitacaoFaturamento/GridSolicitacaoFaturamento';
import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { ModalRecusaLiberacaoComponent } from 'src/app/shared/components/modais/modal-recusa-liberacao/modal-recusa-liberacao.component';
import { ModalMensagemComponent } from 'src/app/components/modais/modal-mensagem/modal-mensagem.component';
import { DefaultMaskPattern, DocumentMaskEnum } from 'src/app/shared/utils/constantes-documentos';

@Component({
  selector: 'tr[app-linha-grid-solicitacoes-usuario-interno]',
  templateUrl: './linha-grid-solicitacoes-usuario-interno.component.html',
  styleUrls: ['./linha-grid-solicitacoes-usuario-interno.component.scss']
})
export class LinhaGridSolicitacoesUsuarioInternoComponent implements AfterViewInit {

  @Input() solicitation!: GridSolicitacaoFaturamento;
  @Input() mask!: string;
  @Output() public onExpandSolicitation: EventEmitter<GridSolicitacaoFaturamento> = new EventEmitter<GridSolicitacaoFaturamento>();
  @Output() public onShowAttachedDocuments: EventEmitter<GridSolicitacaoFaturamento> = new EventEmitter<GridSolicitacaoFaturamento>();
  @Output() public onFinishActionForUpdatingGrid: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('modalConfirmarLiberacaoFinanceira') public modalConfirmarLiberacaoFinanceira!: ModalRespostaPositivaNegativaComponent;
  @ViewChild('modalRecusaLiberacao') public modalRecusaLiberacao!: ModalRecusaLiberacaoComponent;
  @ViewChild('modalJustificativaRecusa') private modalJustificativaRecusa!: ModalMensagemComponent;
  public numeroRps!: UntypedFormControl;
  public maskPattern: any = DefaultMaskPattern.DEFAULT_MASK_CHARS;

  constructor(
    private solicitacaoFaturamentoEstimativaCalculoService: SolicitacaoFaturamentoEstimativaCalculoService,
    private spinner: NgxSpinnerService,
    private toastService:ToastService,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngAfterViewInit(): void {
    this.numeroRps = new UntypedFormControl(this.solicitation.numeroRPS ?? '');
    if(!this.isNumeroRPSEnabled){
      this.numeroRps?.disable();
    }
    this.changeDetector.detectChanges();
  }

  public get isNumeroRPSEnabled() : boolean{
    return this.solicitation.tipoSolicitacao === "Confirmar Pagamento";
  }

  public get isEnviarNumeroRPSEnabled() : boolean{
    return !!this.numeroRps?.value && this.isNumeroRPSEnabled;
  }

  public get isConfirmarLiberacaoFinanceiraEnabled() : boolean{
    return this.solicitation.tipoSolicitacao === "Liberação Financeira"
      // &&
      // this.solicitation.status == "FINALIZADO";
  }

  public get isRecusarLiberacaoFinanceiraEnabled() : boolean{
    return this.solicitation.status !== "RECUSADO";
  }

  public get isAnexarCalculoEnabled() : boolean{
    return this.solicitation.tipoSolicitacao === "Memória de Cálculo" &&
      this.solicitation.status !== 'FINALIZADO';
  }

  public expandSolicitation(): void{
    this.onExpandSolicitation.emit(this.solicitation);
  }

  public sendRpsNumber(): void{
    if(!this.isEnviarNumeroRPSEnabled){
      return;
    }
    this.spinner.show();
    this.solicitacaoFaturamentoEstimativaCalculoService.sendRpsNumber(this.solicitation, this.numeroRps?.value)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          result.message.forEach(
            (message) => {
              if(message?.length){
                this.toastService.success(message);
              }
            }
          );
          this.onFinishActionForUpdatingGrid.emit();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public setFinancialLiberation(): void{
    if(!this.isConfirmarLiberacaoFinanceiraEnabled){
      return;
    }
    this.modalConfirmarLiberacaoFinanceira.openModalForConfirmOrRefuse();
  }

  public confirmFinancialLiberation(): void{
    this.spinner.show();
    this.solicitacaoFaturamentoEstimativaCalculoService.confirmFinancialLiberation(this.solicitation)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          // this.solicitation.status = 'Finalizado';
          result.message.forEach(
            (message) => {
              if(message?.length){
                this.toastService.success(message);
              }
            }
          );
          this.onFinishActionForUpdatingGrid.emit();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public showAttachedDocuments(): void{
    // if(!this.isAnexarCalculoEnabled){
    //   return;
    // }
    this.onShowAttachedDocuments.emit(this.solicitation);
  }

  public showAttachedDocumentsOnFinished(): void{
    this.onShowAttachedDocuments.emit(this.solicitation);
  }

  public openModalForRefusingSolicitation(): void{
    this.modalRecusaLiberacao.openModalForConfirmOrRefuse();
  }

  public confirmRefusingSolicitation(motivoRecusa: string): void{
    this.spinner.show();
    this.solicitacaoFaturamentoEstimativaCalculoService.refuseFinancialLiberation(this.solicitation, motivoRecusa)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          result?.message?.forEach(
            (message) => {
              if(message?.length){
                this.toastService.success(message);
              }
            }
          );
          this.onFinishActionForUpdatingGrid.emit();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      })
  }

  public shortenTextForJustificationDisplay(): string{
    return (this.solicitation?.justificativaRecusa ?? '')?.substring(0, 25) + '...';
  }

  public openModalForJustificationDisplay(): void{
    this.modalJustificativaRecusa?.displayMessage(this.solicitation?.justificativaRecusa ?? '', 'Justificativa');
  }
}
