import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BloqueioDesbloqueioConteinerEmbarque } from 'src/app/models/BloqueioDesbloqueioConteinerEmbarque/bloqueio-desbloqueio-conteiner-embarque';

@Component({
  selector: 'app-grid-conteineres-bloqueio-desbloqueio-conteiner-embarque',
  templateUrl: './grid-conteineres-bloqueio-desbloqueio-conteiner-embarque.component.html',
  styleUrls: ['./grid-conteineres-bloqueio-desbloqueio-conteiner-embarque.component.scss']
})
export class GridConteineresBloqueioDesbloqueioConteinerEmbarqueComponent implements OnInit {

  public listaBloqueioDesbloqueio!: BloqueioDesbloqueioConteinerEmbarque[];
  public areAllSelected: boolean = false;
  public selectedContainerChecks!: boolean[];

  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.listaBloqueioDesbloqueio = [];
    this.updateContainerChecksForSelection();
  }

  public setListaBloqueioDesbloqueio(bloqueioDesbloqueio: BloqueioDesbloqueioConteinerEmbarque[]): void{
    if(!bloqueioDesbloqueio?.length){
      return;
    }
    this.areAllSelected = false;
    this.listaBloqueioDesbloqueio = [...bloqueioDesbloqueio];
    this.updateContainerChecksForSelection();
  }

  public clear(): void{
    this.listaBloqueioDesbloqueio = [];
    this.updateContainerChecksForSelection();
  }

  public switchSelectedForAll(): void{
    this.areAllSelected = !this.areAllSelected;
    this.listaBloqueioDesbloqueio.forEach(
      (conteiner, index) => {
        this.selectedContainerChecks[index] = this.areAllSelected;
      }
    );
    this.changeDetector.detectChanges();

  }

  public switchSingleSelected(containerIndex: number): void{
    this.selectedContainerChecks[containerIndex] = !this.selectedContainerChecks[containerIndex];
    this.areAllSelected = false;
    this.changeDetector.detectChanges();
  }

  public getContainers(): BloqueioDesbloqueioConteinerEmbarque[]{
    return [...this.listaBloqueioDesbloqueio] ?? [];
  }

  public getSelectedContainers(): BloqueioDesbloqueioConteinerEmbarque[]{
    return this.listaBloqueioDesbloqueio.filter(
      (container, index) => !!this.selectedContainerChecks[index]
    ) ?? [];
  }

  public updateContainerChecksForSelection(): void{
    this.selectedContainerChecks = [];
    this.listaBloqueioDesbloqueio.forEach(
      (container) => {
        this.selectedContainerChecks.push(false);
      }
    );
  }

  public get isAnyContainerSelected(): boolean{
    if(!this.listaBloqueioDesbloqueio?.length || !this.selectedContainerChecks?.length){
      return false;
    }
    return !!this.selectedContainerChecks.filter(
      (check) => check
    )?.length;
  }

}
