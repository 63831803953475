<div class="p-5">
    <div class="my-3 overflow-auto">
        <table class="grid-table" *ngIf="relacoesConteiner?.length">
            <thead class="text-nowrap">
                <tr>
                    <th>
                        <input type="checkbox" [checked]="areAllContainerRelationsSelected" [disabled]="!relacoesConteiner.length" (click)="switchSelectedForAll()">
                    </th>
                    <th>Numero Contêiner</th>
                    <th>Data da Entrada</th>
                    <th>Tara</th>
                    <th>Peso Bruto</th>
                    <th>Peso Líquido</th>
                    <th>Data da Pesagem</th>
                    <th>Nº do Booking</th>
                    <th>Nº da DUE</th>
                </tr>
            </thead>
            <tbody>
                <tr class="readonly-grid-line" *ngFor="let relacaoConteiner of relacoesConteiner; let i = index">
                    <td>
                        <input type="checkbox" [checked]="relacaoConteiner.selecionado" (click)="switchSelectedForSingleItem(i)">
                    </td>
                    <td>{{relacaoConteiner.numeroConteiner}}</td>
                    <td>{{relacaoConteiner.dataEntrada | date : "dd/MM/yyyy"}}</td>
                    <td>{{relacaoConteiner.tara}}</td>
                    <td>{{relacaoConteiner.pesoBruto}}</td>
                    <td>{{relacaoConteiner.pesoLiquido}}</td>
                    <td>{{relacaoConteiner.dataPesagem | date : "dd/MM/yyyy"}}</td>
                    <td>{{relacaoConteiner.numeroBooking}}</td>
                    <td>{{relacaoConteiner.numeroDUE}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>