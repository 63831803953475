import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { Campo } from 'src/app/models/Campo';
import { TipoDocumentoAnexado } from 'src/app/models/DocumentosAnexados/TipoDocumentoAnexado';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { SuccessResponse } from 'src/app/models/HttpResponses/SuccessResponse';
import { DocumentoParteLiberacaoDocumental } from 'src/app/models/LiberacaoDocumental/DocumentoParteLiberacaoDocumental';
import { PesquisaExpansaoDocumentoParte } from 'src/app/models/LiberacaoDocumental/PesquisaExpansaoDocumentoParte';
import { Mascara } from 'src/app/models/Mascara';
import { ConteinerIMP } from 'src/app/models/SolicitacaoLiberacaoIMP/ConteinerIMP';
import { SolicitacaoLiberacaoIMP } from 'src/app/models/SolicitacaoLiberacaoIMP/SolicitacaoLiberacaoIMP';
import { ToastService } from 'src/app/services/toast.service';
import { documentTypesToBlockPartQuantityImp } from 'src/app/shared/utils/document-types-to-block-part-quantity';

import { ClienteComponent } from '../../../shared/components/cliente/cliente.component';
import { DocumentoComponent } from '../../../shared/components/documento/documento.component';
import {
  ArvorePartesLiberacaoDocumentalComponent,
} from '../../liberacao-documental/arvore-partes/arvore-partes-liberacao-documental/arvore-partes-liberacao-documental.component';
import {
  DadosComplementaresComponent,
} from '../../solicitacao-liberacao-importacao/dados-complementares/dados-complementares.component';
import {
  RelacaoConteinerImportacaoComponent,
} from '../../solicitacao-liberacao-importacao/relacao-conteiner-importacao/relacao-conteiner-importacao.component';
import { DocumentosService } from './../../../services/documentos.service';
import { SolicitacaoLiberacaoImpService } from './../../../services/solicitacao-liberacao-imp.service';
import {
  DocumentosAnexadosComponent,
} from './../../../shared/components/DocumentosAnexados/documentos-anexados/documentos-anexados.component';
import { Moeda } from 'src/app/models/Moeda';
import { Canal } from 'src/app/models/Canal';
import { Modalidade } from 'src/app/models/Modalidade';
import { ModalidadeDTA } from 'src/app/models/ModalidadeDTA';

@Component({
  selector: 'app-visualizar-solicitacoes-imp',
  templateUrl: './visualizar-solicitacoes-imp.component.html',
  styleUrls: ['./visualizar-solicitacoes-imp.component.scss']
})
export class VisualizarSolicitacoesImpComponent implements OnInit {

  @ViewChild(DocumentoComponent) documento!: DocumentoComponent;
  @ViewChild(ClienteComponent) cliente!: ClienteComponent;
  @ViewChild(DadosComplementaresComponent) dadosComplementares!: DadosComplementaresComponent;
  @ViewChild(RelacaoConteinerImportacaoComponent) relacaoConteineres!: RelacaoConteinerImportacaoComponent;
  @ViewChild(ArvorePartesLiberacaoDocumentalComponent) arvoreDePartes!: ArvorePartesLiberacaoDocumentalComponent;
  @ViewChild(DocumentosAnexadosComponent) documentosAnexados!: DocumentosAnexadosComponent;
  @Output() onExpandPartDocument: EventEmitter<PesquisaExpansaoDocumentoParte> = new EventEmitter<PesquisaExpansaoDocumentoParte>();
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSaveSolicitation: EventEmitter<void> = new EventEmitter<void>();

  // public solicitation!: SolicitacaoLiberacaoIMP | undefined;
  modalRef ?: BsModalRef ;
  protected mascara!: Mascara;
  protected solicitationId: number = 0;
  protected camposFormulario: Campo[] = [];
  protected shouldBlockPartQuantityEditionByDocumentType: boolean = false;
  protected blockEdition: boolean = true;

  protected moedas: Moeda[] = [];
  protected canais: Canal[] = [];
  protected modalidades: Modalidade[] = [];
  protected modalidadesDTA: ModalidadeDTA[] = [];

  private readonly documentTypesToBlockPartQuantityEdition: string[] = [...documentTypesToBlockPartQuantityImp];

  constructor(
    private modalService: BsModalService,
    private changeDetector: ChangeDetectorRef,
    private documentosService: DocumentosService,
    protected solicitacaoLiberacaoImpService: SolicitacaoLiberacaoImpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {

  }

  public openModal(solicitacaoLiberacao : SolicitacaoLiberacaoIMP) {
    this.solicitationId = solicitacaoLiberacao.id ?? 0;
    this.blockEdition = solicitacaoLiberacao.status?.toUpperCase() !== 'PENDENTE';
    this.documento.setDocumentTypes(this.getDocumentTypes());
    if(!this.blockEdition){
      this.setFormConfigurations(solicitacaoLiberacao.documento?.tipoDocumento!!);
    }
    this.changeDetector.detectChanges();
    if(!solicitacaoLiberacao.documento?.numeroCE){
      solicitacaoLiberacao.documento!.numeroCE = solicitacaoLiberacao.dadosComplementares?.numeroCE;
    }
    this.setFormvaluesFromSolicitation(solicitacaoLiberacao);
    this.shouldBlockPartQuantityEditionByDocumentType = this.documentTypesToBlockPartQuantityEdition.includes(solicitacaoLiberacao.documento?.tipoDocumento ?? '');
  }

  public getDocumentTypes(): Observable<SuccessResponse>{
    return this.documentosService.GetDocumentsForSolicitacaoLiberacaoImp();
  }

  public getAttachedFilesTypes(): Observable<TipoDocumentoAnexado[]>{
    return this.solicitacaoLiberacaoImpService.getAttachmentDocumentsForImpLiberationByDocumentType();
  }

  public closeModal(): void{
    this.onCloseModal.emit();
  }

  protected getPartDocumentsFromContainers(containers: ConteinerIMP[]): DocumentoParteLiberacaoDocumental[]{
    return containers
      .filter((container) => container.documentos?.length)
      .flatMap((container) => {
        return container.documentos!.map((documentoParte) => {
          return {
            numeroConteiner: container.conteiner,
            numeroDocumento: documentoParte.numeroDocumentoParte,
            status: documentoParte.status ?? '',
            numeroCE: documentoParte.numeroCE ?? ''
          };
        });
    });
  }

  protected expandPartDocument(documentoParte: PesquisaExpansaoDocumentoParte): void{
    this.onExpandPartDocument.emit({
      ...documentoParte,
      tipoDocumento: this.documento?.getDocumentFromFormFields()?.tipoDocumento,
      nomeRegimeCirculacao: 'Imp',
      idRegimeCirculacao: 1
  });
  }

  private setFormConfigurations(tipoDocumento: string): void{
    this.camposFormulario = [];
    this.documentosService
      .getFormFieldsByDocumentTypeInSolicitacaoLiberacaoImp(tipoDocumento)
      .subscribe({
        next: (result) => {
          this.camposFormulario = result.data;
          this.changeDetector.detectChanges();
          this.setFormSettings();
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
          this.changeDetector.detectChanges();
        }
      })
  }

  private setFormvaluesFromSolicitation(solicitation: SolicitacaoLiberacaoIMP): void{
    this.documento.setFormValuesForModal(solicitation.documento);
    this.cliente.setFormValuesFromReceivedClient(solicitation.cliente);
    this.dadosComplementares.setFormValuesFromReceivedComplementaryData(solicitation.dadosComplementares);
    this.relacaoConteineres.setFormValuesFromReceivedContainerRelationList(solicitation.relacaoConteineres);
    this.arvoreDePartes.setFormvaluesFromReceivedPartDocuments(this.getPartDocumentsFromContainers(solicitation.relacaoConteineres ?? []));
    this.documentosAnexados.setAttachedDocumentTypes(
      solicitation?.documento?.id!,
      solicitation?.documentosAnexosLiberacao ?? [],
      this.getAttachedFilesTypes()
    );
  }

  private setFormSettings(): void{
    // this.documento.setSettingsfromFields();
    this.cliente.setSettingsfromFields();
    this.dadosComplementares.setSettingsfromFields();
    this.relacaoConteineres.setSettingsfromFields();
  }

  protected get areAllFormsValid(): boolean{
    return this.documento?.isDocumentValid() &&
      this.cliente?.isClientValid() &&
      this.dadosComplementares?.areComplementaryDataValid() &&
      this.relacaoConteineres?.areAllContainersValid() &&
      this.arvoreDePartes?.areAllPartDocumentsValid &&
      this.documentosAnexados?.areAllFilesValid;
  }

  protected saveSolicitation(): void{
    if(!this.areAllFormsValid){
      return;
    }
    const solicitation = this.getSolicitationFromForms();
    this.spinner.show();
    this.solicitacaoLiberacaoImpService.saveSolicitationOnLiberationModal(solicitation)
      .subscribe({
        next: (result) => {
          result.message?.forEach((msg) => {
            this.toastService.success(msg)
          });
          this.uploadFiles();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  protected uploadFiles(): void{
    var documentos = this.documentosAnexados?.getAllDocumentsWithAttachedFiles();
    if(!documentos || !documentos.length){
      this.spinner.hide();
      this.onSaveSolicitation.emit();
      return;
    }
    this.solicitacaoLiberacaoImpService.arquivoUploadSolicitacaoLiberacaoImp(documentos, this.solicitationId).subscribe(
    {
      next: (msg) => {
        this.spinner.hide();
        msg?.message?.forEach(
          (message) => {
            this.toastService.success(message);
          }
        );
        this.onSaveSolicitation.emit();
      },
      error: (err: HttpErrorResponse) =>{
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      }
    }
  );
  }

  protected getSolicitationFromForms(): SolicitacaoLiberacaoIMP{
    let solicitation: SolicitacaoLiberacaoIMP = {};
    solicitation.id = this.solicitationId;
    solicitation.documento = this.documento?.getDocumentFromFormFields();
    solicitation.cliente = this.cliente?.getClientFromForm();
    solicitation.dadosComplementares = this.dadosComplementares?.getComplementaryDataFromFormFields();
    solicitation.relacaoConteineres = this.relacaoConteineres?.getContainerRelationFromForm();
    solicitation.arvoreDePartes = this.arvoreDePartes?.getDocumentosParteFromFormFields() as any;
    solicitation.documentosAnexosLiberacao = this.documentosAnexados.getAllDocumentsWithAttachedFiles();
    return solicitation;
  }

  public setSelectOptions(moedas: Moeda[], canais: Canal[], modalidades: Modalidade[], modalidadesDTA: ModalidadeDTA[]): void{
    this.moedas = moedas;
    this.canais = canais;
    this.modalidades = modalidades;
    this.modalidadesDTA = modalidadesDTA;
    this.changeDetector.detectChanges();
  }

}
