<div class="card mt-4">
  <div class="card-header p-3">
    <div class="d-flex justify-content-between">
      <span>Dados do Documento de Transporte no N4</span>
      <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
    </div>
  </div>
  <div class="card-body" [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
    <form [formGroup]="form">
      <div class="p-3">
        <div class="row mb-3">
          <div class="col">
            <label>CPF Condutor:</label>
            <input type="text" class="form-control" formControlName="cpfCondutor" placeholder="Digite aqui..."
            [mask]="'000.000.000-00'"
            [ngClass]="{'border border-danger': form.get('cpfCondutor')?.errors && form.get('cpfCondutor')?.touched}">
          </div>
          <div class="col">
            <label>Nome Condutor:</label>
            <input type="text" class="form-control" formControlName="nomeCondutor" placeholder="Digite aqui..."
            [mask]="'N*'" [patterns]="namePattern">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <label>Tipo de Veículo:</label>
            <select [formControl]="tipoVeiculo" class="custom-select">
              <option value="" disabled selected>Selecione</option>
              <option *ngFor="let tipoVeiculo of vehicleTypes" [ngValue]="tipoVeiculo">{{tipoVeiculo.nome}}</option>
            </select>
          </div>
          <div class="col-3">
            <label>Placa Cavalo:</label>
            <input type="text" class="form-control" formControlName="placaCavalo" placeholder="Digite aqui...">
          </div>
          <div class="col-3" *ngIf="quantidadeReboques >= 1">
            <label>Placa Reboque 1:</label>
            <input type="text" class="form-control" formControlName="placaReboque1" placeholder="Digite aqui..."
            >
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-3" *ngIf="quantidadeReboques >= 2">
            <label>Placa Reboque 2:</label>
            <input type="text" class="form-control" formControlName="placaReboque2" placeholder="Digite aqui..."
            >
          </div>
          <div class="col-3" *ngIf="quantidadeReboques >= 3">
            <label>Placa Reboque 3:</label>
            <input type="text" class="form-control" formControlName="placaReboque3" placeholder="Digite aqui..."
            >
          </div>
        </div>
        <h4>Relação de Contêineres N4:</h4>
        <table class="my-3 grid-table">
          <thead>
            <tr>
              <th>Contêiner</th>
              <th>Peso Apurado</th>
              <th>Tara</th>
              <th>Lacres</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let relacaoConteiner of relacaoConteinerLacresN4Display; let indexRelacao = index"
              app-linha-grid-relacao-conteiner-n4-documento-transporte [relacaoConteiner]="relacaoConteiner"
            >
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</div>
