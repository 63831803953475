export class Telas {
    static readonly solicitacaoLiberacaoImportacao: string = "solicitacaoLiberacaoImportacao"
    static readonly liberacaoDocumentalImportacao: string = "liberacaoDocumentalImportacao"
    static readonly solicitacaoLiberacaoCabotagem: string = "solicitacaoLiberacaoCabotagem"
    static readonly liberacaoDocumentalCabotagem: string = "liberacaoDocumentalCabotagem"
    static readonly solicitacaoLiberacaoExportacao: string = "solicitacaoLiberacaoExportacao"
    static readonly liberacaoDocumentalExportacao: string = "liberacaoDocumentalExportacao"
    static readonly cabotagemEmbarque: string = "cabotagemEmbarque"
    // static readonly entregaDueContingencia: string = "entregaDueContingencia"
    static readonly estimativaCalculo: string = "estimativaCalculo"
    static readonly bloqueioDesbloqueioConteinerEmbarque:string="bloqueioDesbloqueioConteinerEmbarque"
    static readonly emissaoTicketPesagemExportacao:string="emissaoTicketPesagemExportacao"
    static readonly monitoramentoRpaApi:string ="monitoramentoRpaApi"
    static readonly cadastroDocumentoEstimativaCalculo:string ="cadastroDocumentoEstimativaCalculo"
    static readonly solicitacaoFaturamentoEstimativaCalculo:string ="solicitacaoFaturamentoEstimativaCalculo"
    static readonly recepcaoConteiner: string = "recepcaoConteiner"
    static readonly recepcaoNotaFiscal:string ="recepcaoNotaFiscal"
    static readonly recepcaoTransitoSimplificado:string ="recepcaoTransitoSimplificado"
    static readonly recepcaoDocumentoTransporte:string ="recepcaoDocumentoTransporte"
    static readonly recepcaoConteinerTransship:string ="recepcaoConteinerTransship"
    static readonly entregaDueConteiner:string ="entregaDueConteiner"
    static readonly entregaDueDocumentoTransporte:string ="entregaDueDocumentoTransporte"
    static readonly relatorioEntregaDaCargaNavio: string = "relatorioEntregaDaCargaNavio"
    static readonly relatorioConteineresLiberados: string = "relatorioConteineresLiberados"
    static readonly relatorioMovimentacaoClientes: string = "relatorioMovimentacaoClientes"
    // static readonly relatorioAprovacaoLiberacao: string = "relatorioAprovacaoLiberacao"
    static readonly relatorioReprovacaoAprovacaoLiberacao: string = "relatorioReprovacaoAprovacaoLiberacao"
    static readonly relatoioRecepcaoNfDatConteiner: string = "relatorioRecepcaoNfDatConteiner"
}