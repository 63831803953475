<div class="container-menu" container="body" *ngIf="this.isLogged">
  <div class="">
    <img class="cursor-pointer" src="assets/images/imageLogo.png" alt="" (click)="routerHome()">
  </div>
  <div class="d-flex flex-column justify-content-between px-4">
    <div class="d-flex justify-content-end  text-white mb-3">
      <div>
        <span style="color:#fff"> USUÁRIO LOGADO: {{name | uppercase}}</span>
        <span class="span-sair" (click)="signout()"><a> SAIR </a></span>
      </div>
    </div>
    <div *ngIf="this.showMenu">
      <ul class="navbar-nav d-flex flex-row pb-2">

        <li class="nav-item" style="cursor: pointer;" *ngFor="let item of menuList; let i=index" dropdown
          [ngClass]="{'display-none':showMenuItem(item.Role)===false}">

          <div dropdownToggle class="itemMenu" *ngIf="item.SubMenu == undefined && showMenuItem(item.Role)">
            <a class="nav-link text-white" [routerLink]="item.Path">{{item.Name | uppercase}}</a>
          </div>

          <div ngbDropdown (click)="false" class="itemMenu"
            *ngIf="item.SubMenu != undefined && showMenuItem(item.Role)">

            <a ngbDropdownToggle class="nav-link text-white" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">{{item.Name | uppercase}}</a>

            <div *ngIf=" item.SubMenu != undefined && showMenuItem(item.Role)">

              <ul ngbDropdownMenu class="dropdown-menu" role="menu" aria-labelledby="navbarDropdown">

                <div *ngFor="let subItemMenu of item.SubMenu">

                  <li *ngIf="subItemMenu.SubMenu == undefined && showMenuItem(subItemMenu.Role)" role="menuitem"
                    dropdown triggers="mouseover" placement="right" container="body">
                    <a ngbDropdownItem class="dropdown-item" [routerLink]="subItemMenu.Path"
                      (click)="false">{{subItemMenu.Name | uppercase}}</a>
                  </li>

                  <div ngbDropdown (click)="false" class="itemMenuSubMenu" display="menuitem"
                    *ngIf="subItemMenu.SubMenu != undefined && showMenuItem(subItemMenu.Role)">

                    <a ngbDropdownToggle class="nav-link text-white hover" id="navbarDropdown"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{subItemMenu.Name |
                      uppercase}}</a>

                    <div *ngIf="subItemMenu.SubMenu != undefined && showMenuItem(subItemMenu.Role)">

                      <ul ngbDropdownMenu class="dropdown-subMenu1" role="menu" aria-labelledby="navbarDropdown">

                        <div *ngFor="let subItemMenu1 of subItemMenu.SubMenu">
                          <li *ngIf="subItemMenu1.SubMenu == undefined  && showMenuItem(subItemMenu1.Role)"
                            role="menuitem" dropdown triggers="mouseover" placement="right" container="body">
                            <a ngbDropdownItem class="dropdown-item" [routerLink]="subItemMenu1.Path"
                              (click)="false">{{subItemMenu1.Name | uppercase}}</a>
                          </li>

                          <div *ngIf="subItemMenu1.SubMenu != undefined && showMenuItem(subItemMenu1.Role)">
                            <li role="menuitem" dropdown triggers="mouseover" placement="right" container="body">
                              <a ngbDropdownItem class="dropdown-item"
                                [ngClass]="{'noClick':subItemMenu1.Path===''} " [routerLink]="subItemMenu1.Path"
                                (click)="false">{{subItemMenu1.Name | uppercase}}</a>
                            </li>

                            <div *ngFor="let subItemMenu2 of subItemMenu1.SubMenu">
                              <li *ngIf="showMenuItem(subItemMenu2.Role)" role="menuitem" dropdown triggers="mouseover"
                                placement="right" container="body">

                                <a ngbDropdownItem class="dropdown-item  p-2 noBorder" [routerLink]="subItemMenu2.Path"
                                  
                                  (click)="false">{{subItemMenu2.Name | uppercase}}</a>
                              </li>
                            </div>

                          </div>
                        </div>

                      </ul>
                    </div>
                  </div>

                </div>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>