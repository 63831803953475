import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RelacaoConteinerTransitoSimplificado } from 'src/app/models/RecepcaoDueContingencia/ConteinerTransitoSimplificado/RelacaoConteinerTransitoSimplificado';
import { ConteinerTransitoSimplificadoService } from 'src/app/services/conteiner-transito-simplificado.service';
import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { ConteinerComponent } from 'src/app/components/recepcao-due-contigencia/recepcao-conteiner/conteiner/conteiner.component';
import { DadosEnvioComponent } from 'src/app/components/dados-envio/dados-envio.component';
import { TransporteComponent } from 'src/app/components/recepcao-due-contigencia/recepcao-conteiner/transporte/transporte.component';
import { RecepcaoDueContigenciaComponent } from '../recepcao-due-contigencia.component';
import { AuthRoles } from 'src/app/models/authRoles';
import { Telas } from 'src/app/contants/telas';
import { Permissoes } from 'src/app/contants/permissoes';
import { NgxSpinnerService } from 'ngx-spinner';
import { DadosEnvioTransporte } from 'src/app/models/RecepcaoDocumentoTransporte/DadosEnvioTransporte';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-recepcao-conteiner',
  templateUrl: './recepcao-conteiner.component.html',
  styleUrls: ['./recepcao-conteiner.component.scss']
})
export class RecepcaoConteinerComponent implements OnInit, AfterViewInit {

  public title: string = 'Contêiner (Trânsito Simplificado)';
  public subtitle: string = '';
  public blockSending: boolean = true;
  public conteinerEnvioCCT: RelacaoConteinerTransitoSimplificado = new RelacaoConteinerTransitoSimplificado();
  public identificador: UntypedFormControl = new UntypedFormControl('');

  public formOut!: UntypedFormGroup;

  @ViewChild(ConteinerComponent) conteiner!: ConteinerComponent;
  @ViewChild(DadosEnvioComponent) dados!: DadosEnvioComponent;
  @ViewChild(TransporteComponent) transporte!: TransporteComponent;
  public readonly visualizacao = new AuthRoles(Telas.recepcaoTransitoSimplificado, Permissoes.visualizacao)
  public readonly enviarCCT = new AuthRoles(Telas.recepcaoTransitoSimplificado, Permissoes.enviarCCT)
  public readonly salvar = new AuthRoles(Telas.recepcaoTransitoSimplificado, Permissoes.salvar)

  constructor(
    private fb: UntypedFormBuilder,
    private cont: ConteinerTransitoSimplificadoService,
    private toastService:ToastService,
    private spinner: NgxSpinnerService,

  ) { }

  public ngOnInit(): void {
    this.validation();
  }

  public ngAfterViewInit(): void {

  }

  public validation(): void {
    this.formOut = this.fb.group({
      id: [null],
    });

  }

  public SearchForInvoiceByConteinerNumber(conteinerNumber: string) {
    this.spinner.show();
    this.blockSending = false;

    this.cont.getConteiners(conteinerNumber).subscribe({
      next: (success) => {
        this.spinner.hide();
        let relacaoConteinerTransitoSimplificado: RelacaoConteinerTransitoSimplificado = success.data;
        console.log(success);
        console.log(relacaoConteinerTransitoSimplificado)
        this.conteiner.setConteinerDataFromResivedConteiner(success.data);
        this.transporte.setConteinerDataFromResivedConteiner(success.data);
        this.identificador.setValue(relacaoConteinerTransitoSimplificado.identificador ?? '');
        if(relacaoConteinerTransitoSimplificado.recepcaoRealizadaComSucesso)
        {
          this.dados.setConteinerDataFromResivedConteiner({
            dataEnvio: relacaoConteinerTransitoSimplificado.dataEnvio as Date,
            usuario: relacaoConteinerTransitoSimplificado.usuario as string,
            status: relacaoConteinerTransitoSimplificado.statusRecepcao as string
          });
          this.blockSending = true;
        }


        if(!relacaoConteinerTransitoSimplificado.pesagem)
        {
          this.toastService.error("Contêiner não possui o registro de TC_PESAGEM");
          this.blockSending = true;

        }
        else
        {
          if(!this.conteiner.checkIfSealNumbersAreEqual())
          {
            this.toastService.error("Existe(m) divergência(s) entre o(s) Lacre(s) do N4 x CCT!");
            this.blockSending = true;

          }
        }


      },
      error: (err: ErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        err?.errors?.forEach(error => {
          if (error.length) {
            this.toastService.error(error);
          }
        });
      }
    });
  }

  public clean() {
    this.spinner.show('limpar');
    setTimeout(() => {
      this.spinner.hide('limpar');
    }, 1500);
    this.conteiner.clean();
    this.transporte.clean();
    this.dados.clean();
  }

  sendDataToCCT() {
    if (this.blockSending || this.formOut.invalid) {
      return;
    }
    this.setData();
    this.spinner.show();
    this.cont.sendDataToCCTForRecepcaoTransitoSimplificado(this.conteinerEnvioCCT)
      .subscribe({
        next: (response) => {
          response.message?.forEach(
            (message) => {
              if (message && message.length) {
                this.toastService.success(message);
              }

            }
          )

          this.dados.setConteinerDataFromResivedConteiner(response.data as DadosEnvioTransporte);
          this.blockSending = true;
          this.spinner.hide();
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          (err?.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              if (error && error.length) {
                this.toastService.error(error);
              }
            }
          );
        }
      }
      );
    }

  save(): void{
    if (this.blockSending || this.formOut.invalid) {
      return;
    }
    this.setData();
    this.spinner.show('carregando');
    this.cont.saveDataToCCTForRecepcaoTransitoSimplificado(this.conteinerEnvioCCT)
      .subscribe({
        next: (response) => {
          response.message?.forEach(
            (message) => {
              if (message && message.length) {
                this.toastService.success(message);
              }
            }
          )
          // this.dados.setConteinerDataFromResivedConteiner(response.data as DadosEnvioTransporte);
          this.conteiner.pesquisarConteiner();
          this.blockSending = false;
          this.spinner.hide('carregando');
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide('carregando');
          console.log(err);
          (err?.error as ErrorResponse)?.errors?.forEach(
            (error) => {
              if (error && error.length) {
                this.toastService.error(error);
              }
            }
          );
        }
      });
  }

  private setData(): void{
    this.conteinerEnvioCCT = {
      numeroConteiner : this.conteiner.form.value.conteiner,
      peso: this.conteiner.form.value.pesoApurado,
      tara: this.conteiner.form.value.tara,
      lacresN4: this.conteiner.lacresN4,
      lacresCCT: this.conteiner.lacresCCT,
      cpfCondutor: this.transporte.form.value.cpfCondutor,
      nomeCondutor: this.transporte.form.value.nome,
      placaCavalo: this.transporte.form.value.placaCavalo,
      placaReboque1: this.transporte.form.value.placaReboque1,
      placaReboque2: this.transporte.form.value.placaReboque2,
      placaReboque3: this.transporte.form.value.placaReboque3,
      identificador: this.identificador.value,
    }
  }
}
