import { VerificacaoDocumentosService } from './../../../shared/utils/verificacao-documentos.service';
import { MaskPipe } from 'ngx-mask';
import { Canal } from 'src/app/models/Canal';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DocumentoParteEXP } from 'src/app/models/SolicitacaoLiberacaoExportacao/DocumentoParteEXP';
import { Mascara } from 'src/app/models/Mascara';
import { Campo } from 'src/app/models/Campo';
import { DefaultMaskPattern } from 'src/app/shared/utils/constantes-documentos';

@Component({
  selector: 'tr[app-linha-grid-arvore-partes-exportacao]',
  templateUrl: './linha-grid-arvore-partes-exportacao.component.html',
  styleUrls: ['./linha-grid-arvore-partes-exportacao.component.scss']
})
export class LinhaGridArvorePartesExportacaoComponent implements AfterViewInit {

  public numeroDocumentoParte!: UntypedFormControl;
  public canalDocumentoParte!: UntypedFormControl;
  public situacaoAduaneiraParte!: UntypedFormControl;
  public conteiner!: UntypedFormControl;
  public parteCadastrada!: UntypedFormControl;
  public quantidadePartes!: UntypedFormControl;
  public formDocumentoParte!: UntypedFormGroup;
  @Input() canais!: Canal[];
  @Input() documentoParte!: DocumentoParteEXP;
  @Input() mascara!: Mascara | undefined;
  @Input() camposFormulario!: Campo[];
  @Input() visualizar?: boolean = false;
  @Input() shouldBlockPartQuantityEditionByDocumentType?: boolean;
  @Output() onSendArvoreParte: EventEmitter<DocumentoParteEXP> = new EventEmitter<DocumentoParteEXP>();
  @Output() onFirstEdit: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private maskPipe: MaskPipe,
    private verificacaoDocumentosService: VerificacaoDocumentosService
  ) { }

  public ngAfterViewInit(): void {
    this.validation();
    this.sendArvoreParte();
    if(this.visualizar){
      this.disableAllFormFields();
    }
  }

  private validation(): void{
    this.numeroDocumentoParte = new UntypedFormControl(
      this.mascara?.modelo?.length ? this.maskPipe.transform(this.documentoParte.numeroDocumentoParte, [this.mascara?.modelo, DefaultMaskPattern.DEFAULT_MASK_CHARS]) : this.documentoParte.numeroDocumentoParte,
      Validators.compose([this.canDocumentNumberBeEmptyBecauseOfType.bind(this)])
    );
    this.canalDocumentoParte = new UntypedFormControl(this.canais?.find(canal => canal.nome === this.documentoParte?.canalDocumentoParte)?.nome ?? (this.documentoParte?.canalDocumentoParte ? this.documentoParte?.canalDocumentoParte : ''), Validators.compose([Validators.required]));
    this.situacaoAduaneiraParte = new UntypedFormControl(this.documentoParte.situacaoAduaneiraParte, Validators.compose([Validators.required]));
    this.conteiner = new UntypedFormControl(this.documentoParte.conteiner, Validators.compose([Validators.required]));
    this.quantidadePartes = new UntypedFormControl(this.documentoParte.quantidadePartes, Validators.compose([Validators.required]));
    this.parteCadastrada = new UntypedFormControl(!!this.documentoParte.parteCadastrada, Validators.compose([Validators.required]));
    this.formDocumentoParte = this.fb.group({
      numeroDocumentoParte: this.numeroDocumentoParte,
      canalDocumentoParte: this.canalDocumentoParte,
      situacaoAduaneiraParte: this.situacaoAduaneiraParte,
      conteiner: this.conteiner,
      quantidadePartes: this.quantidadePartes,
      parteCadastrada: this.parteCadastrada
    });
    if(!!this.parteCadastrada?.value){
      this.numeroDocumentoParte.disable();
      this.canalDocumentoParte.disable();
    }
    this.changeDetector.detectChanges();
    this.formDocumentoParte.valueChanges.subscribe(
      () => {
        this.sendArvoreParte();
      }
    );
    this.sendArvoreParte();
    this.setSettingsfromFields();
  }

  public sendArvoreParte(): void{
    this.onSendArvoreParte.emit(this.getDocumentoParteFromFormFields());
    this.changeDetector.detectChanges();
  }

  public get isDocumentoParteValid(): boolean{
    return !this.formDocumentoParte?.invalid;
  }

  public getDocumentoParteFromFormFields(): DocumentoParteEXP{
    let documentoParte = this.formDocumentoParte?.getRawValue() as DocumentoParteEXP;
    documentoParte.numeroDocumentoParte = this.verificacaoDocumentosService.eraseMask(documentoParte.numeroDocumentoParte);
    return documentoParte;
  }

  public setSettingsfromFields(): void{
    if(this.visualizar){
      this.disableAllFormFields();
      return;
    }
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      this.disableAllFormFields();
      return;
    }
    if(!this.camposFormulario?.length){
      this.enableAllFormFields();
      return;
    }
    for(let control in this.formDocumentoParte.controls){
      let fieldIndex = this.camposFormulario.findIndex(
        (field) => field.nome == control
      );
      if(fieldIndex >= 0){
        if(this.camposFormulario[fieldIndex].obrigatorio){
          this.formDocumentoParte.get(control)?.addValidators(Validators.required);
        }
        else{
          this.formDocumentoParte.get(control)?.removeValidators(Validators.required);
        }
        if(this.camposFormulario[fieldIndex].leitura && !!this.formDocumentoParte.get(control)?.value){
          this.formDocumentoParte.get(control)?.disable();
        }
        else{
          this.formDocumentoParte.get(control)?.enable();
        }
        if(!!this.camposFormulario[fieldIndex]?.bloqueado){
          this.formDocumentoParte.get(control)?.disable();
        }
        if(!this.formDocumentoParte.get(control)?.value && this.camposFormulario[fieldIndex].obrigatorio){
          this.formDocumentoParte.get(control)?.enable();
        }
      }
      else{
        this.formDocumentoParte.get(control)?.enable();
        this.formDocumentoParte.get(control)?.removeValidators(Validators.required);
      }
    }
    if(!!this.parteCadastrada?.value){
      this.numeroDocumentoParte.disable();
      this.canalDocumentoParte.disable();
    }
    this.changeDetector.detectChanges();
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.formDocumentoParte.controls){
      this.formDocumentoParte.get(field)?.enable();
      this.formDocumentoParte.get(field)?.removeValidators(Validators.required);
      this.changeDetector.detectChanges();
    }
  }

  public disableAllFormFields(): void{
    this.formDocumentoParte.disable({emitEvent: false});
    this.changeDetector.detectChanges();
  }

  private canDocumentNumberBeEmptyBecauseOfType(control: AbstractControl): {invalid: boolean} | null{
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      return null;
    }
    if(!control?.value?.length){
      return {invalid: true};
    }
    return null;
  }

}
