import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/toast.service';
import { RelacaoConteinerTransitoSimplificado } from 'src/app/models/RecepcaoDueContingencia/ConteinerTransitoSimplificado/RelacaoConteinerTransitoSimplificado';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { RecepcaoConteinerComponent } from 'src/app/pages/recepcao-due-contigencia/recepcao-conteiner/recepcao-conteiner.component';
import { ConteinerTransitoSimplificadoService } from 'src/app/services/conteiner-transito-simplificado.service';

@Component({
  selector: 'app-conteiner',
  templateUrl: './conteiner.component.html',
  styleUrls: ['./conteiner.component.scss']
})
export class ConteinerComponent implements OnInit {
  public form!: UntypedFormGroup;
  public isCollapsed = false;
  public maskForClientDocument: string = '0*';
  public lacresN4: {id: number, numero: number}[] = [];
  public lacresCCT: {id: number, numero: number}[] = [];


  @Output() onSearchForInvoice: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private cont: ConteinerTransitoSimplificadoService,
    private fb: UntypedFormBuilder,
    private toastService:ToastService
  ) {
  }

  ngOnInit() {
    this.validation();
  }

  public validation(): void {
    this.form = this.fb.group({
      conteiner: ['', [Validators.required]],
      pesoApurado: [''],
      tara: ['']

    })
  }

  public pesquisarConteiner(): void {

    this.onSearchForInvoice.emit(this.form.value.conteiner);

    // this.cont.getConteiners().subscribe({
    //   next: (success) => {

    //     let relacaoConteiner: RelacaoConteinerTransitoSimplificado = success.data

    //     this.form.get("pesoApurado")?.setValue(relacaoConteiner.pesoApurado)
    //     this.form.get("tara")?.setValue(relacaoConteiner.tara)

    //   },
    //   error: (err: ErrorResponse) =>{
    //     console.log(err);
    //     err?.errors?.forEach(error => {
    //       if(error.length){
    //         this.toastService.error(error);
    //       }
    //     });
    //   }
    // });
  }

  public setConteinerDataFromResivedConteiner(conteiners: RelacaoConteinerTransitoSimplificado) {

    this.form.get("pesoApurado")?.setValue(conteiners.peso)
    this.form.get("tara")?.setValue(conteiners.tara)
    this.lacresN4 = conteiners.lacresN4 ?? [];
    this.lacresCCT = conteiners.lacresCCT ?? [];
  }

  public checkIfSealNumbersAreEqual(): boolean{
    let numbersLacresN4: number[] = this.lacresN4.map(lacre => lacre.numero);
    let numbersLacresCCT: number[] = this.lacresCCT.map(lacre => lacre.numero);

    if(numbersLacresN4.length != numbersLacresCCT.length){
      return false;
    }
    return numbersLacresN4.every(
      (lacre) => numbersLacresCCT.includes(lacre)
    ) && numbersLacresCCT.every(
      (lacre) => numbersLacresN4.includes(lacre)
    );
  }
  public clean(){
    this.form.reset();
    this.lacresN4 = [];
    this.lacresCCT = [];

  }
}
