import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConteineresLiberadosComponent } from 'src/app/components/relatorios/relatorio-conteineres-liberados/conteineres-liberados/conteineres-liberados.component';
import { Permissoes } from 'src/app/contants/permissoes';
import { Telas } from 'src/app/contants/telas';
import { AuthRoles } from 'src/app/models/authRoles';

@Component({
  selector: 'app-relatorio-conteineres-liberados',
  templateUrl: './relatorio-conteineres-liberados.component.html',
  styleUrls: ['./relatorio-conteineres-liberados.component.scss']
})
export class RelatorioConteineresLiberadosComponent implements OnInit {
  public title: string = 'Relatório de Liberação - Contêineres Liberados por Período';
  public subtitle: string = '';
  public formOut!: UntypedFormGroup;

  @ViewChild(ConteineresLiberadosComponent) private conteineresLiberadosComponent!: ConteineresLiberadosComponent;
  public readonly visualizacao = new AuthRoles(Telas.relatorioConteineresLiberados, Permissoes.visualizacao)

  constructor(
    private spinner: NgxSpinnerService,
    ) { }

  public ngOnInit(): void {
  }

  async imprimirPdf() {
    await this.conteineresLiberadosComponent.downloadPDF(this.title);
  }

  async salvarExcel() {
    await this.conteineresLiberadosComponent.downloadExcel(this.title);
  }

  public isContainerListNotEmpty(): boolean{
    return !!this.conteineresLiberadosComponent?.isContainerListNotEmpty();
  }

  public clean() {
    this.conteineresLiberadosComponent?.clearFields();
  }

}
