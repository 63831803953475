import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { AuthRoles } from '../models/authRoles';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Directive({
    selector: '[usuarioInterno]'
})
export class UsuarioInternoDirective implements OnInit {
    constructor(private el: ElementRef, private authService: AuthenticationService) { }

    public ngOnInit(): void{
      if(!this.authService.ehUsuarioInterno) {
        this.el.nativeElement.style.display = 'none';
      }
    }
}
