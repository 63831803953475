import { NotaFiscalNFE } from './../../../../models/RecepcaoDueContingencia/RecepcaoNotaFiscal/NotaFiscalNFE';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-nota-fiscal-nfe',
  templateUrl: './nota-fiscal-nfe.component.html',
  styleUrls: ['./nota-fiscal-nfe.component.scss']
})
export class NotaFiscalNfeComponent implements OnInit {
  public form!: UntypedFormGroup;
  public isCollapsed = false;
  public notaFiscalInvalida = false;
  public conteierInvalido = false;

  @Output() onSearchForInvoice: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() onValidaNotaFiscal:  EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnInit() {
    this.validation();
  }

  private validation(): void{
    this.form = this.fb.group({
      chaveNFE: ['', [Validators.required, Validators.minLength(44),Validators.pattern("^[0-9]*$"),]],
      numeroConteiner: ['', [Validators.required]]
    });
   // this.form.get("chaveNFE")?.valueChanges.subscribe(() => {this.validateNotaFiscal()});
  }

  public validarCaracteres(event: any): void{
    console.log(event.target.value)
    event.target.value = event.target.value.replace(/\D/g, '');
  }

  public validateNotaFiscal(): void{
    if(this.form.get("chaveNFE")?.valid)
    {
      this.onValidaNotaFiscal.emit(this.form.get("chaveNFE")?.value);
    }
  }

  public searchInvoice(): void{
    let notaFiscal: NotaFiscalNFE = {
      chaveNFE: this.form.value.chaveNFE,
      numeroConteiner: this.form.value.numeroConteiner
    };
    this.onSearchForInvoice.emit({'notaFiscal': notaFiscal});
  }

  public clear(): void{
    this.form.reset();
  }

  public setNotaFiscalValida(): void{
    this.notaFiscalInvalida = false
  }

  public setNotaFiscalInvalida(): void{
    this.notaFiscalInvalida = true
  }

  public setConteierValido(): void{
    this.conteierInvalido = false
  }

  publicsetConteierInvalida(): void{
    this.conteierInvalido = true
  }
}
