<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Relação de Contêiner <span class="text-danger" *ngIf="!areAllContainersValid()">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <div class="p-3 d-flex action-button-group row" [hidden]="isInsideModal">
            <div class="col-4">
                <button class="action-primary-button" [disabled]="blockAddition || doesSolicitationHaveIntegration || relacaoConteinerReadonly" (click)="addContainerInCaseOfNoIntegration()">
              Incluir contêiner
            </button>
            </div>
        </div>
        <div class="table-container">
            <table class="grid-table">
                <thead>
                    <tr>
                        <th><input class="form-chek-input" type="checkbox" value="" id="incluir" [checked]="areAllItemsSelected" (click)="switchSelectAll()" [disabled]="visualizar || (relacaoConteinerReadonly && !arvoreParteReadonly)"></th>
                        <th>Contêiner</th>
                        <th>Contêiner Transload</th>
                        <th>Parte?</th>
                        <th>Quantidade de Partes</th>
                        <th *ngIf="!shouldBlockPartQuantityEditionByDocumentType">Quebra de Lote</th>
                        <th>...</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let relacaoConteiner of containerRelationList; let indexRelacaoConteiner = index" app-linha-grid-relacao-conteiner-importacao
                      [relacaoConteiner]="relacaoConteiner" #conteiner [camposFormulario]="camposFormulario"
                      (onEditFields)="updateContainerInfo($event)" (onManualSelection)="areAllItemsSelected = false"
                      [doesSolicitationHaveIntegration]="doesSolicitationHaveIntegration" (onEraseLine)="sendConteinerToErase(indexRelacaoConteiner)"
                      [visualizar]="visualizar || relacaoConteinerReadonly" [isParteBlocked]="arvoreParteReadonly"
                      (onManualContainerNumberEdition)="sendContainerToSearchShipAndTrip($event, indexRelacaoConteiner)"
                      [shouldBlockPartQuantityEditionByDocumentType]="shouldBlockPartQuantityEditionByDocumentType"
                      [blockAddition]="blockAddition"
                    >
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
