import { DocumentMaskEnum, DocumentRegex } from './constantes-documentos';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VerificacaoDocumentosService {
  private readonly maskSpecialCharacters: string[] = ['.',' ','-', '/'];
  private readonly maskDefaultCharacters: string[] = ['0', '9', 'A', 'S'];
  public readonly charsToBlockFromNameField: string[] = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
    '!',
    '@',
    '#',
    '$',
    '%',
    '¨',
    '&',
    '*',
    '(',
    ')',
    '_',
    '=',
    '+',
    '|',
    ';',
    ',',
    '"',
    '^',
    '~',
    '´',
    '`',
    '§',
    '?',
    '/',
    '\\',
    ':',
    '¹',
    '²',
    '³',
    '£',
    '¢',
    '¬',
    '{',
    '}',
    '[',
    ']',
    'ª',
    'º',
    '°',
  ];

  public eraseMask(document: string): string {
    if(!document?.length){
      return '';
    }
    if (document) {
      for (let maskChar of this.maskSpecialCharacters) {
        if (maskChar) {
          document = document.split(maskChar).join('');
        }
      }
      return document;
    }
    return '';
  }

  public setMaskForPhoneNumber(phoneNumber: string): string{

    let clearNumber: string = this.eraseMask(phoneNumber) ?? '';

    if(clearNumber.length == 10){

      return `(${clearNumber.substring(0,2)}) ${clearNumber.substring(2,6)}-${clearNumber.substring(6,10)}`;

    }

    if(clearNumber.length == 11){

      return `(${clearNumber.substring(0,2)}) ${clearNumber.substring(2,3)} ${clearNumber.substring(3,7)}-${clearNumber.substring(7,11)}`;

    }

    return `(${clearNumber.substring(0,2)}) ${clearNumber.substring(2,6)}-${clearNumber.substring(6,10)}`;

  }

  public checkCPFOrCNPJ(controlValue: any, docType: string): string | null {
    if (!controlValue) {
      return null;
    }
    let docNumber = controlValue as string;
    if (!docNumber.length) {
      return null;
    }
    if (
      this.eraseMask(docNumber).length === 11 &&
      docType == ClientDocTypeEnum.FISICA
    ) {
      return this.calculateValidCPF(docNumber);
    } else if (
      this.eraseMask(docNumber).length === 14 &&
      docType == ClientDocTypeEnum.JURIDICA
    ) {
      return this.calculateValidCNPJ(docNumber);
    } else {
      return 'O nº do CNPJ/CPF informado é inválido. Favor verificar!';
    }
  }

  public calculateValidCPF(CPFNum: string): string | null{
    if(!DocumentRegex.CPF_REGEX.test(this.setMaskForCPF(this.eraseMask(CPFNum)))){
      return 'O formato do CPF informado é inválido. Favor verificar!';
    }
    let firstVerificationDigitCPF: string = '';
    let secondVerificationDigitCPF: string = '';
    let sum: number = 0;
    for (let firstCheck: number = 0; firstCheck < 9; firstCheck++) {
      sum += parseInt(this.eraseMask(CPFNum)[firstCheck]) * (firstCheck + 1);
    }
    firstVerificationDigitCPF = sum % 11 == 10 ? '0' : (sum % 11).toString();
    if (firstVerificationDigitCPF != this.eraseMask(CPFNum)[9]) {
      return 'O nº do CPF informado é inválido. Favor verificar!';
    }
    sum = 0;
    for (let firstCheck: number = 0; firstCheck < 10; firstCheck++) {
      sum += parseInt(this.eraseMask(CPFNum)[firstCheck]) * firstCheck;
    }
    secondVerificationDigitCPF = sum % 11 == 10 ? '0' : (sum % 11).toString();
    if (secondVerificationDigitCPF != this.eraseMask(CPFNum)[10]) {
      return 'O nº do CPF informado é inválido. Favor verificar!';
    }
    let numerosSequenciais = [
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
      '00000000000'
    ]
    if(numerosSequenciais.includes(this.eraseMask(CPFNum))){
      return 'O nº do CPF informado é inválido. Favor verificar!';
    }
    return null;
  }

  public calculateValidCNPJ(CNPJNum: string): string | null{
    if(!DocumentRegex.CNPJ_REGEX.test(this.setMaskForCNPJ(this.eraseMask(CNPJNum)))){
      return 'O formato do CNPJ informado é inválido. Favor verificar!';
    }
    let firstVerificationDigitCNPJ: string = '';
    let secondVerificationDigitCNPJ: string = '';
    let sum: number = 0;
    let valuesForCheckingCNPJ: number[] = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    for (
      let firstCheck: number = 0;
      firstCheck < valuesForCheckingCNPJ.length;
      firstCheck++
    ) {
      sum +=
        parseInt(this.eraseMask(CNPJNum)[firstCheck]) *
        valuesForCheckingCNPJ[firstCheck];
    }
    firstVerificationDigitCNPJ =
      sum % 11 < 2 ? '0' : (11 - (sum % 11)).toString();
    if (firstVerificationDigitCNPJ != this.eraseMask(CNPJNum)[12]) {
      return 'O nº do CNPJ informado é inválido. Favor verificar!';
    }
    valuesForCheckingCNPJ = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    sum = 0;
    for (
      let secondCheck: number = 0;
      secondCheck < valuesForCheckingCNPJ.length;
      secondCheck++
    ) {
      sum +=
        parseInt(this.eraseMask(CNPJNum)[secondCheck]) *
        valuesForCheckingCNPJ[secondCheck];
    }
    secondVerificationDigitCNPJ =
      sum % 11 < 2 ? '0' : (11 - (sum % 11)).toString();
    if (secondVerificationDigitCNPJ != this.eraseMask(CNPJNum)[13]) {
      return 'O nº do CNPJ informado é inválido. Favor verificar!';
    }
    return null;
  }

  public getDocMask(docType: string): string {
    let mask = '';
    switch (docType) {
      case ClientDocTypeEnum.FISICA:
        mask = DocumentMaskEnum.CPF_MASK;
        break;
      case ClientDocTypeEnum.JURIDICA:
        mask = DocumentMaskEnum.CNPJ_MASK;
        break;
    }
    return mask;
  }

  public setMaskForCPF(CPFNumber: string): string {
    CPFNumber = this.eraseMask(CPFNumber ?? '');
    return (
      CPFNumber[0] +
      CPFNumber[1] +
      CPFNumber[2] +
      '.' +
      CPFNumber[3] +
      CPFNumber[4] +
      CPFNumber[5] +
      '.' +
      CPFNumber[6] +
      CPFNumber[7] +
      CPFNumber[8] +
      '-' +
      CPFNumber[9] +
      CPFNumber[10]
    );
  }

  public setMaskForCNPJ(CNPJNumber: string): string {
    CNPJNumber = this.eraseMask(CNPJNumber ?? '');
    return (
      CNPJNumber[0] +
      CNPJNumber[1] +
      '.' +
      CNPJNumber[2] +
      CNPJNumber[3] +
      CNPJNumber[4] +
      '.' +
      CNPJNumber[5] +
      CNPJNumber[6] +
      CNPJNumber[7] +
      '/' +
      CNPJNumber[8] +
      CNPJNumber[9] +
      CNPJNumber[10] +
      CNPJNumber[11] +
      '-' +
      CNPJNumber[12] +
      CNPJNumber[13]
    );
  }

  public eraseCharsFromNameField(field: string): string {
    if (field) {
      this.charsToBlockFromNameField.forEach((char) => {
        field = field.split(char).join('');
      });
      return field;
    }
    return '';
  }

  public checkValidDI(DIDocument: string): string | null{
    if(!DIDocument || !DIDocument.length){
      return null;
    }
    // if(!DocumentRegex.DI_REGEX.test(this.setMaskForDI(this.eraseMask(DIDocument)))){
    //   return 'Documento inválido!'
    // }
    return null;
  }

  public setMaskForDI(DINumber: string): string{
    return (
      DINumber[0] +
      DINumber[1] +
      '/' +
      DINumber[2] +
      DINumber[3] +
      DINumber[4] +
      DINumber[5] +
      DINumber[6] +
      DINumber[7] +
      DINumber[8] +
      '-' +
      DINumber[9]
    );
  }

  public checkValidDUE(DUEDocument: string): string | null{
    if(!DUEDocument || !DUEDocument.length){
      return null;
    }
    if(!DocumentRegex.DUE_REGEX.test(DUEDocument)){
      console.log(DUEDocument)
      return 'Documento inválido!'
    }
    return null;
  }

  public setMaskForDUE(DUENumber: string): string{
    return (
      DUENumber[0] +
      DUENumber[1] +
      'BR' +
      DUENumber[2] +
      DUENumber[3] +
      DUENumber[4] +
      DUENumber[5] +
      DUENumber[6] +
      DUENumber[7] +
      DUENumber[8] +
      DUENumber[9] +
      DUENumber[10]
    );
  }

  public getSpecialCharsFromMask(mask: string): string[]{
    let specialChars: string = mask;
    for(let maskDefault of this.maskDefaultCharacters){
      specialChars = specialChars.split(maskDefault).join('');
    }
    return specialChars.split('');
  }

  public setCurrencyFormat(value: any): number{
    if(typeof(value) === 'number'){
      return value;
    }
    if(typeof(value) === 'string'){
      if(value.includes('.') && value.includes(',')){
        if((value.lastIndexOf(',') > value.lastIndexOf('.')) && value.split(',').pop()?.length == 2){
          return parseFloat(value.split('.').join('').split(',').join('.'));
        }
        if((value.lastIndexOf('.') > value.lastIndexOf(',')) && value.split('.').pop()?.length == 2){
          return parseFloat(value.split(',').join(''));
        }
      }
      if(value.includes('.') || value.includes(',')){
        parseFloat(value.split('.').join('').split(',').join(''))/100
      }
      return parseFloat(value)/100;
    }
    return value;
  }
}

export enum ClientDocTypeEnum {
  FISICA = 'fisica',
  JURIDICA = 'juridica',
}
