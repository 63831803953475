import { StatusBloqueioEnum } from './../../models/StatusBloqueioEnum';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RelacaoConteinerTransitoSimplificado } from 'src/app/models/RecepcaoDueContingencia/ConteinerTransitoSimplificado/RelacaoConteinerTransitoSimplificado';
import { DocumentCustomPattern, DocumentMaskEnum } from 'src/app/shared/utils/constantes-documentos';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { DadosEnvioTransporte } from 'src/app/models/RecepcaoDocumentoTransporte/DadosEnvioTransporte';

@Component({
  selector: 'app-dados-envio',
  templateUrl: './dados-envio.component.html',
  styleUrls: ['./dados-envio.component.scss']
})
export class DadosEnvioComponent implements OnInit {
  public form!: UntypedFormGroup;
  public isCollapsed = false;
  public maskForClientDocument: string = '0*';
  public maskForNames: string = DocumentMaskEnum.NAME_MASK;
  public patternForNames = DocumentCustomPattern.NAME_PATTERN;

  @Output() onSearchForInvoice: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private fb: UntypedFormBuilder,
    private verificacaoDocumentosService: VerificacaoDocumentosService
  ) {
  }

  ngOnInit() {
    this.validation();
  }

  public validation(): void {
    this.form = this.fb.group({
      dataSolicitacao: [''],
      usuario: [''],
      status: ['']

    })
  }

  public setConteinerDataFromResivedConteiner(conteiners: DadosEnvioTransporte | undefined): void {
    if (!conteiners) {
      return
    }
    this.form.get("dataSolicitacao")?.setValue(new Date(conteiners.dataEnvio).toLocaleDateString('pt-br'))
    this.form.get("usuario")?.setValue(conteiners.usuario)
    this.form.get("status")?.setValue(conteiners.status)
  }

  public clean() {
    this.form.reset();
  }
}
