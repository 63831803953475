import { MaskPipe } from 'ngx-mask';
import { ToastService } from 'src/app/services/toast.service';
import { ErrorResponse } from '../../../models/HttpResponses/ErrorResponse';
import { SuccessResponse } from '../../../models/HttpResponses/SuccessResponse';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { DocumentosService } from 'src/app/services/documentos.service';
import { TipoDocumentoSolicitacao } from '../../../models/TipoDocumentoSolicitacao';
import {
  Component,
  AfterContentInit,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime, Observable } from 'rxjs';
import { DocumentoCabotagem } from 'src/app/models/DocumentoCabotagem';
import { DefaultMaskPattern, DocumentMaskEnum, DocumentSpecialCharactersForMask } from 'src/app/shared/utils/constantes-documentos';
import { Mascara } from 'src/app/models/Mascara';
import { Campo } from 'src/app/models/Campo';


@Component({
  selector: 'app-documento-cabotagem',
  templateUrl: './documento-cabotagem.component.html',
  styleUrls: ['./documento-cabotagem.component.scss'],
})
export class DocumentoCabotagemComponent implements OnInit, AfterContentInit {
  public form!: UntypedFormGroup;
  public isCollapsed = false;
  public read: string = '';
  private readonly fieldsNotToBlock: string[] = ['tipoDocumento', 'numeroDocumento'];
  public maskForChaveNotaFiscal: string = DocumentMaskEnum.CHAVE_NOTA_FISCAL_MASK;

  @Input() isInsideModal!: boolean;
  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() camposFormulario!: Campo[];
  @Input() visualizar?: boolean = false;
  @Output() dataFormOut = new EventEmitter<{}>();
  @Output() btnValid = new EventEmitter<boolean>();
  @Output() onDocumentLoad: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDocumentTypesLoaded: EventEmitter<void> = new EventEmitter<void>();
  @Output() onChangeDocumentType: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSendDocument: EventEmitter<DocumentoCabotagem> = new EventEmitter<DocumentoCabotagem>();
  @Output() onSendMask: EventEmitter<Mascara> = new EventEmitter<Mascara>();

  @ViewChild('numDoc') search!: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private documentosService: DocumentosService,
    private changeDetector: ChangeDetectorRef,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private toastService:ToastService,
    private maskPipe: MaskPipe
  ) {}

  public ngAfterContentInit(): void {
    this.onDocumentLoad.emit();
  }

  public isDisable: boolean = true;
  public maskDoc!: string;
  public specialCharsForMask!: string[];
  public tipoDocumento!: TipoDocumentoSolicitacao[];

  filtrarMascara(valor: string) {
    this.form.get('numeroDocumento')?.setValue('');
    this.setMaskForDocumentType(valor);
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.maskDoc = '';
    this.validation();
    this.changeDetector.detectChanges();
  }

  public setDocumentTypes(documentsForSelection: Observable<SuccessResponse>): void{
    this.tipoDocumento = [];
    this.changeDetector.detectChanges();
    documentsForSelection.subscribe({
      next: (event) => {
        this.tipoDocumento = event.data as TipoDocumentoSolicitacao[];
        this.changeDetector.detectChanges();
        this.onDocumentTypesLoaded.emit();
        this.changeDetector.detectChanges();
      },
      error: (err: ErrorResponse) => {
        console.log(err);
        err?.errors?.forEach(
          (error) => {
            this.toastService.error(error);
          }
        );
      }
    });
  }


  public validation(): void {
    this.form = this.fb.group({
      id: new UntypedFormControl('', Validators.required),
      tipoDocumento: new UntypedFormControl('', Validators.required),
      numeroDocumento: new UntypedFormControl('', Validators.required),
      numeroCE:  new UntypedFormControl(''),
      chaveNotaFiscal:  new UntypedFormControl(''),
    }, {
      validators: [this.validateIfNumeroCEIsPresentForCTE.bind(this)]
    });
    this.form.valueChanges
      .subscribe(
        () => {
          if(this.form.valid){
            this.sendDocumentData();
          }
        }
      );
    this.changeDetector.detectChanges();
  }

  public getData() {
    if(this.isInsideModal || !this.form.valid){
      return;
    }
    let documento = this.getCabotageDocumentFromForm();
    if(!documento.numeroDocumento || !documento.tipoDocumento){
      return;
    }
    this.sendDocumentData();
    if(!this.form.invalid){
      this.onSendDocument.emit(documento);
      this.btnValid.emit(true);
    }
  }

  public getIdDoc(tipoDocumento: string){
    var dados = this.tipoDocumento.filter((e) => e.nome == tipoDocumento)
    return dados[0].id;
  }

  public ngOnChanges(changes: SimpleChanges) {}

  public checkValidDocumentForm() {
    return !!this.form.value.numeroDocumento
      && !!this.form.value.tipoDocumento
      && this.form.value.numeroDocumento.length >= this.maskDoc.length
      && this.form.valid;
  }

  public clean() {
    this.form.reset();
    this.validation();
    this.search.nativeElement.focus();
  }

  public setFormValuesFromDocument(document: DocumentoCabotagem | undefined): void{
    if(!document){
      return;
    }
    this.setMaskForDocumentType(document.tipoDocumento ?? this.form.value.tipoDocumento);
    document.numeroDocumento =
      this.maskDoc?.length ?
      this.maskPipe.transform((document.numeroDocumento ?? ''), [this.maskDoc, DefaultMaskPattern.DEFAULT_MASK_CHARS]) :
      document.numeroDocumento;
    this.form.patchValue(
      {
        id: document?.id ? document.id : this.form.get('id')?.value,
        tipoDocumento: document?.tipoDocumento ? document.tipoDocumento : this.form.get('tipoDocumento')?.value,
        numeroDocumento: document?.numeroDocumento ? document.numeroDocumento : this.form.get('numeroDocumento')?.value,
        numeroCE: document?.numeroCE ? document.numeroCE : this.form.get('numeroCE')?.value,
        chaveNotaFiscal: document?.chaveNotaFiscal ? document.chaveNotaFiscal : this.form.get('chaveNotaFiscal')?.value
      },
      {emitEvent: false}
    );
  }

  public changeDocumentType(document: string): void{
    this.filtrarMascara(document);
    this.form.get('id')?.setValue(
      this.tipoDocumento.find(tipoDoc => tipoDoc.nome == document)?.id
    )
    this.onChangeDocumentType.emit(document);
    this.changeDetector.detectChanges();
    this.switchChaveNotaFiscalEnabled();
    this.switchNumeroCEEnabled();
  }

  private sendDocumentData(): void{
    this.dataFormOut.emit({'documento': this.getCabotageDocumentFromForm()});
  }

  public getCabotageDocumentFromForm(): DocumentoCabotagem{
    let documento = this.form.getRawValue() as DocumentoCabotagem;
    if(!documento?.id){
      documento.id = this.tipoDocumento?.find(tipoDocumento => tipoDocumento?.nome == documento.tipoDocumento)?.id
    }
    return documento;
  }

  public isDocumentValid(): boolean{
    return !this.form?.invalid;
  }

  private setMaskForDocumentType(documentType: string): void{
    this.maskDoc = this.tipoDocumento?.find(
      (document) => document.nome == documentType
    )?.mascara || '';
    this.specialCharsForMask = this.verificacaoDocumentosService.getSpecialCharsFromMask(this.maskDoc);
    this.changeDetector.detectChanges();
    this.onSendMask.emit({
      modelo: this.maskDoc,
      caracteresEspeciais: this.specialCharsForMask
    });
  }

  public setFormValuesFromCTEXML(xml: Partial<DocumentoCabotagem>): void{
    this.setMaskForDocumentType('CTE');
    this.form.patchValue({
      ...xml,
      tipoDocumento: "CTE",
      id: this.tipoDocumento?.find(documento => documento.nome == 'CTE')?.id
    }, {emitEvent: false});
    this.switchChaveNotaFiscalEnabled();
    this.switchNumeroCEEnabled();
  }

  public setFormValuesFromNFXML(xml: any): void{
    this.setMaskForDocumentType('NF');
    this.switchChaveNotaFiscalEnabled();
    this.switchNumeroCEEnabled();
    this.form.patchValue({
      tipoDocumento: "NF",
      numeroDocumento: xml.nfeProc.NFe.infNFe.ide.nNF._text,
      chaveNotaFiscal: xml.nfeProc.protNFe.infProt.chNFe._text,
      id: this.tipoDocumento?.find(documento => documento.nome == 'NF')?.id,
      numeroCE: ''
    }, {emitEvent: false});
  }

  public get isNumeroCEValid(): boolean{
    return !this.form.get('numeroCE')?.invalid;
  }

  public setSettingsfromFields(): void{
    if(!this.camposFormulario?.length){
      this.enableAllFormFields();
      return;
    }
    for(let control in this.form.controls){
      let fieldIndex = this.camposFormulario.findIndex(
        (field) => field.nome == control
      );
      if(fieldIndex >= 0){
        if(this.camposFormulario[fieldIndex].obrigatorio){
          this.form.get(control)?.addValidators(Validators.required);
        }
        else{
          this.form.get(control)?.removeValidators(Validators.required);
        }
        if(this.doesSolicitationHaveIntegration){
          if(this.camposFormulario[fieldIndex].leitura && !!this.form.get(control)?.value){
            if(!this.fieldsNotToBlock.includes(control)){
              this.form.get(control)?.disable();
            }
          }
          else{
            this.form.get(control)?.enable();
          }
        }
        else{
          if(this.camposFormulario[fieldIndex].leitura){
            if(!this.fieldsNotToBlock.includes(control)){
              this.form.get(control)?.disable();
            }
          }
          else{
            this.form.get(control)?.enable();
          }
        }
        if(!!this.camposFormulario[fieldIndex]?.bloqueado && !this.fieldsNotToBlock.includes(control)){
          this.form.get(control)?.disable();
        }
        if(!this.form.get(control)?.value && this.camposFormulario[fieldIndex].obrigatorio){
          this.form.get(control)?.enable();
        }
      }
      else{
        this.form.get(control)?.enable();
        this.form.get(control)?.removeValidators(Validators.required);
      }
    }
    this.switchChaveNotaFiscalEnabled();
    this.switchNumeroCEEnabled();
    this.changeDetector.detectChanges();
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.form.controls){
      this.form.get(field)?.enable();
      this.form.get(field)?.removeValidators(Validators.required);
      this.changeDetector.detectChanges();
    }
    this.switchChaveNotaFiscalEnabled();
    this.switchNumeroCEEnabled();
  }

  private switchNumeroCEEnabled(): void{
    if(this.form?.value?.tipoDocumento == 'CTE'){
      this.form?.get('numeroCE')?.enable();
    }
    else{
      this.form?.get('numeroCE')?.disable();
    }
    this.form?.updateValueAndValidity();
    this.changeDetector.detectChanges();
  }

  private switchChaveNotaFiscalEnabled(): void{
    if(this.form?.value?.tipoDocumento == 'NF'){
      this.form?.get('chaveNotaFiscal')?.enable();
    }
    else{
      this.form?.get('chaveNotaFiscal')?.disable();
      this.form?.get('chaveNotaFiscal')?.reset('');
    }
    this.form?.updateValueAndValidity();
    this.changeDetector.detectChanges();
  }

  private validateIfNumeroCEIsPresentForCTE(group: UntypedFormGroup): {invalidNumeroCE: boolean} | null{
    if(!group){
      return null;
    }
    let tipoDocumento = group.get('tipoDocumento')?.value ?? '';
    if(!tipoDocumento?.length){
      return null;
    }
    if(tipoDocumento !== 'CTE'){
      return null;
    }
    let numeroCE = group.get('numeroCE')?.value ?? '';
    if(!numeroCE?.length){
      return {invalidNumeroCE: true};
    }
    return null;
  }

  public setFormValuesForModal(documento: DocumentoCabotagem | undefined): void{
    this.form?.patchValue(documento ?? {}, {emitEvent: false});
    this.form.disable({emitEvent: false});
  }
}
