import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DocumentoParteLiberacaoDocumental } from 'src/app/models/LiberacaoDocumental/DocumentoParteLiberacaoDocumental';
import { PesquisaExpansaoDocumentoParte } from 'src/app/models/LiberacaoDocumental/PesquisaExpansaoDocumentoParte';

import { Mascara } from '../../../../models/Mascara';
import {
  LinhaParteLiberacaoDocumentalComponent,
} from '../linha-parte-liberacao-documental/linha-parte-liberacao-documental.component';

@Component({
  selector: 'app-arvore-partes-liberacao-documental',
  templateUrl: './arvore-partes-liberacao-documental.component.html',
  styleUrls: ['./arvore-partes-liberacao-documental.component.scss']
})
export class ArvorePartesLiberacaoDocumentalComponent implements OnInit, AfterContentInit {

  protected isCollapsed: boolean = false;
  protected listaDocumentosParte: DocumentoParteLiberacaoDocumental[] = [];
  @Input() isExpDocument!: boolean;
  @Input() mascara!: Mascara;
  @Output() onArvorePartesLoad: EventEmitter<void> = new EventEmitter<void>();
  @Output() onExpandPartDocument: EventEmitter<PesquisaExpansaoDocumentoParte> = new EventEmitter<PesquisaExpansaoDocumentoParte>();
  @ViewChildren(LinhaParteLiberacaoDocumentalComponent) gridDocumentosParte!: QueryList<LinhaParteLiberacaoDocumentalComponent>;

  constructor() { }

  public ngOnInit(): void {
  }

  public ngAfterContentInit(): void {
    this.onArvorePartesLoad.emit();
  }

  public setFormvaluesFromReceivedPartDocuments(partDocuments: DocumentoParteLiberacaoDocumental[]): void{
    this.listaDocumentosParte = [...partDocuments];
  }

  public get areAllPartDocumentsValid(): boolean{
    if(!this.gridDocumentosParte?.length){
      return true;
    }
    return !this.gridDocumentosParte.some((documentoParte) => !documentoParte.isPartDocumentValid)
  }

  public getDocumentosParteFromFormFields(): DocumentoParteLiberacaoDocumental[]{
    return this.gridDocumentosParte?.map((documentoParte) => documentoParte?.getPartDocumentFromFields()) ?? []
  }

  protected expandPartDocument(documentoParte: PesquisaExpansaoDocumentoParte): void{
    this.onExpandPartDocument.emit(documentoParte);
  }

}
