<ng-template #modal>
    <div class="modal-content">
        <div class="modal-header">
            <span class="modal-title" id="exampleModalLabel">{{rpaApi.idProcesso}} - Detalhes</span>
            <button type="button" class="btn close btn-close" data-bs-dismiss="modal" aria-label="close" (click)="closeModal()">&times;</button>
        </div>
        <div class="modal-body">
            <div class="table-multiheader-container">
                <table class="table-multiheader">
                    <thead>
                        <tr>
                            <th>Request Id</th>
                            <th>Data/Hora Início</th>
                            <th>Status</th>
                            <th>Mensagem Erro</th>
                            <th>Ver JSON</th>
                            <th>Evidências</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="rpaApi.eventoRequest">
                            <td class="detail-title text-nowrap"><span>{{rpaApi.eventoRequest.id}}</span></td>
                            <td>{{rpaApi.eventoRequest.dataEnvio | date : 'dd/MM/yyyy HH:mm'}}</td>
                            <td>{{rpaApi.eventoRequest.status}}</td>
                            <td>{{rpaApi.eventoRequest.mensagemErro}}</td>
                            <td class="json-details"><span (click)="displayJSON(rpaApi.eventoRequest.conteudo)">Ver detalhes</span></td>
                            <td></td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th>Response Id</th>
                            <th>Data/Hora Início</th>
                            <th>Status</th>
                            <th>Mensagem Erro</th>
                            <th>Ver JSON</th>
                            <th>Evidências</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let response of rpaApi.eventoResponse">
                            <td class="detail-title text-nowrap"><span>{{response.id}}</span></td>
                            <td>{{response.dataEnvio | date : 'dd/MM/yyyy HH:mm'}}</td>
                            <td>{{response.status}}</td>
                            <td>{{response.mensagemErro}}</td>
                            <td class="json-details"><span (click)="displayJSON(response.conteudo)">Ver detalhes</span></td>
                            <td class="evidence-download">
                                <img src="assets/images/SaveDoc.png" (click)="downloadEvidence(response.evidencias)">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-4"></div>
                <div class="col-4 action-button-group">
                    <button class="action-primary-button" (click)="closeModal()">Fechar</button>
                </div>
                <div class="col-4"></div>
            </div>
        </div>
    </div>
</ng-template>

<app-exibir-json></app-exibir-json>
