<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Cliente <span class="text-danger" *ngIf="!isClientValid()">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row">
                    <div class="col">
                        <label>Tipo de Cliente: <span id="tipoCliente-required-label" class="invisible">*</span></label>
                        <div class="row">
                            <div class="col-3">
                                <div class="box box-fisica">
                                    <div class="form-check">
                                        <input formControlName="tipoCliente" type="radio" id="radio1" name="tipoCliente" [checked]="form.get('tipoCliente')?.value == 'fisica'" value="fisica" [attr.disabled]="visualizar ? '': null">
                                        <label class="form-check-label" for="radio1">&nbsp; Fisica</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="box box-juridica">
                                    <div class="form-check">
                                        <input formControlName="tipoCliente" type="radio" id="radio2" name="tipoCliente" [checked]="form.get('tipoCliente')?.value == 'juridica'" value="juridica" [attr.disabled]="visualizar ? '': null">
                                        <label class="form-check-label" for="radio2">&nbsp; Juridica</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <label>Nome do Cliente: <span id="nomeCliente-required-label" class="invisible">*</span></label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Digite aqui..." formControlName="nomeCliente" [ngClass]="{'border border-danger': form.get('nomeCliente')?.touched && form.get('nomeCliente')?.errors}" (change)="removeExtremitySpacesFromClientName();onEndEditClient.emit()"
                                maxlength="100" [mask]="'N*'" [patterns]="namePattern" [attr.disabled]="visualizar ? '': null">
                        </div>
                        <p class="text-danger" *ngIf="form.get('nomeCliente')?.touched && form.get('nomeCliente')?.errors && !form.get('nomeCliente')?.value" [hidden]="visualizar == true">
                            Nome do cliente deve ser preenchido!</p>
                        <p class="text-danger" *ngIf="form.get('nomeCliente')?.touched && form.get('nomeCliente')?.errors && form.get('nomeCliente')?.value && form.get('nomeCliente')?.value.length < 3" [hidden]="visualizar == true">
                            Mínimo de 3 caracteres!</p>
                        <p class="text-danger" *ngIf="form.get('nomeCliente')?.touched && form.get('nomeCliente')?.errors && form.get('nomeCliente')?.value && form.get('nomeCliente')?.value.length > 100" [hidden]="visualizar == true">
                            Máximo de 100 caracteres!</p>
                    </div>
                    <div class="col-4">
                        <label>CPF/CNPJ: <span id="cpfCnpj-required-label" class="invisible">*</span></label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" [ngClass]="{'border border-danger': form.get('cpfCnpj')?.touched && form.get('cpfCnpj')?.errors}" placeholder="Digite aqui..." formControlName="cpfCnpj" [mask]="'00.000.000/0000-00||000.000.000-00'" (change)="setDocTypeByCharCount();onEndEditClient.emit()"
                                [attr.disabled]="visualizar ? '': null">
                        </div>
                        <p class="text-danger" *ngIf="form.get('cpfCnpj')?.touched && form.get('cpfCnpj')?.errors?.['invalidDocument']" [hidden]="visualizar == true">
                            {{form.get('cpfCnpj')?.errors?.['invalidDocument']}}</p>
                        <p class="text-danger" *ngIf="form.get('cpfCnpj')?.touched && form.get('cpfCnpj')?.errors?.['required']" [hidden]="visualizar == true">
                            Documento do cliente deve ser preenchido!
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>