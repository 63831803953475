import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl } from '@angular/forms';
import { ConteinerEstimativaCalculo } from './../../../../models/EstimativaCalculo/CadastroDocumento/ConteinerEstimativaCalculo';
import { Component, Input, OnInit, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-selecao-conteineres-divergentes',
  templateUrl: './selecao-conteineres-divergentes.component.html',
  styleUrls: ['./selecao-conteineres-divergentes.component.scss']
})
export class SelecaoConteineresDivergentesComponent implements OnInit {

  @ViewChild('modalSelecaoConteineresDivergentes') private modalSelecaoConteineresDivergentes!: TemplateRef<any>;
  @Output() public onConfirmSelection: EventEmitter<void> = new EventEmitter<void>();
  public selectedContainers!: string[];
  public containers!: string[];

  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef
  ) { }

  public ngOnInit(): void {
    this.selectedContainers = [];
  }

  public getSelectedContainers(): string[]{
    return this.containers.filter(
      (container) => this.selectedContainers?.includes(container)
    );
  }

  public getUnselectedContainers(): string[]{
    return this.containers.filter(
      (container) => !this.selectedContainers?.includes(container)
    );
  }

  public openModalForContainerDivergency(containersToConfirm: string[]): void{
    this.containers = [...containersToConfirm];
    this.modalRef = this.modalService.show(this.modalSelecaoConteineresDivergentes, {
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
  }

  public closeModal(): void{
    this.modalService.hide();
  }

  public confirmSelection(): void{
    this.onConfirmSelection.emit();
  }

  public switchSelected(container: string): void{
    if(this.selectedContainers.includes(container)){
      this.selectedContainers.splice(this.selectedContainers.indexOf(container), 1);
      return;
    }
    this.selectedContainers.push(container);
  }

}
