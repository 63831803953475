import { HttpErrorResponse } from '@angular/common/http';
import { DocumentoParteIMP } from 'src/app/models/SolicitacaoLiberacaoIMP/DocumentoParteIMP';
import { DocumentosService } from 'src/app/services/documentos.service';
import { IntegracaoSiscomex } from '../../models/IntegracaoSignalR/IntegracaoSiscomex';
import { SolicitacaoLiberacaoImpService } from '../../services/solicitacao-liberacao-imp.service';
import { ExtrairDadosSiscomexComponent } from './../../components/modais/extrair-dados-siscomex/extrair-dados-siscomex.component';
import { IntegracaoDocumentoParteComponent } from './../../components/modais/integracao-documento-parte/integracao-documento-parte.component';
import { Documento } from './../../models/Documento';
import { RetornoStatusDocumentoParteDTO } from './../../models/IntegracaoSignalR/RetornoStatusDocumentoParteDTO';
import { Mascara } from './../../models/Mascara';
import { CadastroDocumentoParteIMPDTO } from './../../models/SolicitacaoLiberacaoIMP/CadastroDocumentoParteIMPDTO';
import { PageIdEnum } from './../../shared/utils/app-constants';
import { TimeConstants } from './../../shared/utils/time-constants';

import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArvoreDePartesComponent } from 'src/app/components/solicitacao-liberacao-importacao/arvore-de-partes/arvore-de-partes.component';
import { DadosComplementaresComponent } from 'src/app/components/solicitacao-liberacao-importacao/dados-complementares/dados-complementares.component';
import { RelacaoConteinerImportacaoComponent } from 'src/app/components/solicitacao-liberacao-importacao/relacao-conteiner-importacao/relacao-conteiner-importacao.component';
import { Permissoes } from 'src/app/contants/permissoes';
import { Telas } from 'src/app/contants/telas';
import { AuthRoles } from 'src/app/models/authRoles';
import { Campo } from 'src/app/models/Campo';
import { Canal } from 'src/app/models/Canal';
import { Cliente } from 'src/app/models/Cliente';
import { ArquivoDocumentoAnexado } from 'src/app/models/DocumentosAnexados/ArquivoDocumentoAnexado';
import { ErrorResponse } from 'src/app/models/HttpResponses/ErrorResponse';
import { Modalidade } from 'src/app/models/Modalidade';
import { ModalidadeDTA } from 'src/app/models/ModalidadeDTA';
import { Moeda } from 'src/app/models/Moeda';
import { DadosNavioVigemDTO } from 'src/app/models/SolicitacaoLiberacaoCabotagem/DadosNavioVigemDTO';
import { ConteinerIMP } from 'src/app/models/SolicitacaoLiberacaoIMP/ConteinerIMP';
import { SolicitacaoLiberacaoIMP } from 'src/app/models/SolicitacaoLiberacaoIMP/SolicitacaoLiberacaoIMP';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ToastService } from 'src/app/services/toast.service';
import { ClienteComponent } from 'src/app/shared/components/cliente/cliente.component';
import { DocumentoComponent } from 'src/app/shared/components/documento/documento.component';
import { DocumentosAnexadosComponent } from 'src/app/shared/components/DocumentosAnexados/documentos-anexados/documentos-anexados.component';
import { ModalOKComponent } from 'src/app/shared/components/modais/modal-ok/modal-ok.component';
import { documentTypesToBlockPartQuantityImp } from 'src/app/shared/utils/document-types-to-block-part-quantity';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';

@Component({
  selector: 'app-solicitacao-liberacao-importacao',
  templateUrl: './solicitacao-liberacao-importacao.component.html',
  styleUrls: ['./solicitacao-liberacao-importacao.component.scss'],
})

export class SolicitacaoLiberacaoImportacaoComponent implements OnInit, AfterViewInit {
  private pageId: number = PageIdEnum.SOLICITACAO_LIBERACAO_IMPORTACAO;
  public title: string = 'Solicitação de Liberação de Importação';
  public subtitle: string =
    'Adicione abaixo os dados necessários para o documento de solicitação.';
  //public formIn!: FormGroup;
  public formOut!: UntypedFormGroup;
  public modalRef!: BsModalRef;
  public isSearchDone: boolean = false;
  public camposFormularios: Campo[] = [];
  public isSolicitationReceived: boolean = false;
  public doesSolicitationHaveIntegration: boolean = true;
  public textoSolicitacaoExistente!: string;
  public blockSending: boolean = true;
  public isPartDocumentSaved: boolean = false;
  public documentTypeSelected: string = '';
  public isRequesting: boolean = false;

  private readonly documentTypesToBlockPartQuantityEdition: string[] = [...documentTypesToBlockPartQuantityImp];


  //@Output() dataFormIn = new EventEmitter<FormGroup>();
  @ViewChild(DocumentoComponent) documento!: DocumentoComponent;
  @ViewChild(ClienteComponent) cliente!: ClienteComponent;
  @ViewChild(DadosComplementaresComponent) dados!: DadosComplementaresComponent;
  @ViewChild(RelacaoConteinerImportacaoComponent) relacao!: RelacaoConteinerImportacaoComponent;
  @ViewChild(ArvoreDePartesComponent) arvore!: ArvoreDePartesComponent;
  @ViewChild(DocumentosAnexadosComponent) documentosAnexosLiberacao!: DocumentosAnexadosComponent;
  @ViewChild(ExtrairDadosSiscomexComponent) modalExtrairDadosSiscomex!: ExtrairDadosSiscomexComponent;
  @ViewChild(IntegracaoDocumentoParteComponent) modalIntegracaoDocumentoParte!: IntegracaoDocumentoParteComponent;
  @ViewChild('modalAlertaErrosIntegracao') modalAlertaErrosIntegracao!: TemplateRef<any>;
  @ViewChild('modalSolicitacaoPesquisadaExistente') modalSolicitacaoPesquisadaExistente!: ModalOKComponent;

  private signalRConnection: HubConnection = new signalR.HubConnectionBuilder()
  .withUrl(`${environment.apiUrlForSolicitacaoLiberacaoImpHub}/solicitacaoIMPHub`)
  .withAutomaticReconnect()
  .configureLogging(signalR.LogLevel.Debug)
  .build();

  
  private timeoutForSignalRConnection!: any;
  public integrationErrors: string[] = [];

  private arvorePartesSalva: DocumentoParteIMP[] = [];
  private numerosConteineresSalvos: string[] = [];
  public maskForArvorePartes!: Mascara;

  protected moedas: Moeda[] = [];
  protected canais: Canal[] = [];
  protected modalidades: Modalidade[] = [];
  protected modalidadesDTA: ModalidadeDTA[] = [];

  public get isSalvarDocumentoParteEnabled(): boolean{
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      return false;
    }
    return !this.isRequesting &&
      !!this.relacao?.getSelectedContainerRelationFromForm()?.length &&
      !!this.arvore?.documentosPartes?.length &&
      this.arvore?.areAllDocumentoParteLinesValid() &&
      !this.arvore?.arePartDocumentsSameAsStored;
  }

  public readonly visualizacao = new AuthRoles(Telas.solicitacaoLiberacaoImportacao, Permissoes.visualizacao)
  public readonly salvarRascunho = new AuthRoles(Telas.solicitacaoLiberacaoImportacao, Permissoes.salvarRascunho)
  public readonly concluir = new AuthRoles(Telas.solicitacaoLiberacaoImportacao, Permissoes.concluir)
  public readonly salvarDocParte = new AuthRoles(Telas.solicitacaoLiberacaoImportacao, Permissoes.salvarDocParte)
  public readonly cancelar = new AuthRoles(Telas.solicitacaoLiberacaoImportacao, Permissoes.cancelar)
  constructor(
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private solicitacaoLiberacaoImpService: SolicitacaoLiberacaoImpService,
    public datepipe: DatePipe,
    private documentosService: DocumentosService,
    private changeDetector: ChangeDetectorRef,
    private toastService:ToastService
  ) {}

  public ngOnInit(): void{
    this.validation();
    this.getSelectFieldsForFormComponents();
  }

  public ngAfterViewInit(): void {
    this.getDocuments();
  }

  public validation(): void {
    this.formOut = this.fb.group({
      id: [null],
      documento: [null, [Validators.required]],
      cliente: [null],
      dadosComplementares: [null],
      relacaoConteineres: [null],
      arvorePartes: [null],
      documentosAnexosLiberacao: [null]
    });
  }

  public searchSolicitationByButton(documento: Documento): void{
    this.clearForms(false);
    this.searchSolicitation(documento);
  }

  private getTelefoneUsuarioExterno() {
    this.solicitacaoLiberacaoImpService.getTelefoneUsuarioExterno() .subscribe({
      next: (result) => {
        console.log(result.data);
        this.dados.setTelefoneContatoEmail(result.data);
        this.changeDetector.detectChanges();
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      }
    });
  }

  //Traz o tipodocumento e o numero para enviar ao endpoit
  //recebe os dados da integração em emit para os componentes filhos
  public searchSolicitation(documento: Documento) {

    if(!documento){
      return;
    }

    this.dados?.setDocumentType(documento.tipoDocumento);
    this.blockSending = false;
    this.doesSolicitationHaveIntegration = false;
    this.isRequesting = true;
    this.changeDetector.detectChanges();
    this.spinner.show();
    this.closeSignalRConnection();
    const correlation_id = uuid.v4();
    this.solicitacaoLiberacaoImpService.getImportLiberationSolicitationDataByDocument(documento, correlation_id)
    .subscribe({
      next: (result) => {
          this.isSearchDone = true;
          console.log(result)
          //this.clearForms(false);
          let solicitation: SolicitacaoLiberacaoIMP = result.data as SolicitacaoLiberacaoIMP;

          this.doesSolicitationHaveIntegration = !!solicitation?.statusIntegracao?.length;
          this.changeDetector.detectChanges();
          if(solicitation){
            this.setSolicitationValuesToForms(solicitation);
          }
          else{
            this.documentosAnexosLiberacao.setAttachedDocumentTypes(documento?.id!, [], this.solicitacaoLiberacaoImpService.getAttachmentDocumentsForImpLiberationByDocumentType());
          }
          //this.documentosAnexosLiberacao.(documento, this.documentosService.getAttachmentDocumentsForImpLiberationByDocumentType());
          //this.documento.setFormValuesFromDocument(documento);
          //this.allDataForm({'documento': documento});
          this.spinner.hide();

          this.setFieldSettings();
          this.getTelefoneUsuarioExterno();
          this.isRequesting = false;
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          if(err.status === 404){
            let error = err.error as string;
            console.log(error);
            if(typeof(error) === 'string'){
              this.textoSolicitacaoExistente = error;
              this.modalSolicitacaoPesquisadaExistente.openModalForOk();
            }
            else{
              this.isSearchDone = true;
              let integracoes: IntegracaoSiscomex[] = (error as ErrorResponse)?.data;
              if(integracoes?.length){
                this.doesSolicitationHaveIntegration = true;
                this.changeDetector.detectChanges();
                if(!!integracoes[0]?.solicitacaoLiberacaoImpDTO){
                  this.setSolicitationValuesToForms(integracoes[0].solicitacaoLiberacaoImpDTO);
                  this.isRequesting = false;
                }
                else{
                  this.openSiscomexDataModal(integracoes);
                }
              }
              else{
                this.doesSolicitationHaveIntegration = false;
                this.changeDetector.detectChanges();
                this.isRequesting = false;
              }
              this.documentosAnexosLiberacao.setAttachedDocumentTypes(documento?.id!, [], this.solicitacaoLiberacaoImpService.getAttachmentDocumentsForImpLiberationByDocumentType());
              this.setFieldSettings();
            }
          }
          else{
            this.isSearchDone = true;
            console.log(err);
            let errorMessage = (err?.error as ErrorResponse);
            if(errorMessage?.errors?.length){
              errorMessage?.errors?.forEach(
                (error) => {
                  if(error?.length){
                    this.toastService.error(error);
                  }
                }
              );
            }
            this.isRequesting = false;
          }

        this.setFieldSettings();
        },
        complete: () => {
        }
      });

      this.getFormSettingsByDocumentType(this.documento?.getDocumentFromFormFields()?.tipoDocumento);
      this.getTelefoneUsuarioExterno();
  }

  public confirmCreateSolicitationFromExistingDocument() {
    this.spinner.show();
    this.isRequesting = true;
    let documento = this.documento?.getDocumentFromFormFields();
    if(!documento){
      return;
    }
    this.closeSignalRConnection();
    const correlation_id = uuid.v4();
    this.solicitacaoLiberacaoImpService.confirmCreateImportLiberationSolicitationDataByDocument(documento, correlation_id)
    .subscribe({
      next: (result) => {
          this.isSearchDone = true;
          console.log(result)
          //this.clearForms(false);
          let solicitation: SolicitacaoLiberacaoIMP = result.data as SolicitacaoLiberacaoIMP;
          this.doesSolicitationHaveIntegration = !!solicitation?.statusIntegracao?.length;
          this.changeDetector.detectChanges();
          this.getTelefoneUsuarioExterno();
          if(solicitation){
            this.setSolicitationValuesToForms(solicitation);
          }
          else{
            this.documentosAnexosLiberacao.setAttachedDocumentTypes(documento?.id!, [], this.solicitacaoLiberacaoImpService.getAttachmentDocumentsForImpLiberationByDocumentType());
          }
          //this.documentosAnexosLiberacao.(documento, this.documentosService.getAttachmentDocumentsForImpLiberationByDocumentType());
          //this.documento.setFormValuesFromDocument(documento);
          //this.allDataForm({'documento': documento});
          this.spinner.hide();

          this.setFieldSettings();
          this.isRequesting = false;
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          if(err.status === 404){
            this.isSearchDone = true;
            let integracoes: IntegracaoSiscomex[] = (err.error as ErrorResponse)?.data;
            this.getTelefoneUsuarioExterno();
            if(integracoes?.length){
              this.doesSolicitationHaveIntegration = true;
              this.changeDetector.detectChanges();
              if(!!integracoes[0]?.solicitacaoLiberacaoImpDTO){
                this.setSolicitationValuesToForms(integracoes[0].solicitacaoLiberacaoImpDTO);
                this.isRequesting = false;
              }
              else{
                this.openSiscomexDataModal(integracoes);
              }
            }
            else{
              this.doesSolicitationHaveIntegration = false;
              this.changeDetector.detectChanges();
              this.isRequesting = false;
            }
            this.setFieldSettings();
            this.documentosAnexosLiberacao.setAttachedDocumentTypes(documento?.id!, [], this.solicitacaoLiberacaoImpService.getAttachmentDocumentsForImpLiberationByDocumentType());
            this.isRequesting = false;
          }
          else{
            this.isSearchDone = true;
            console.log(err);
            let errorMessage = (err?.error as ErrorResponse);
            if(errorMessage?.errors?.length){
              errorMessage?.errors?.forEach(
                (error) => {
                  if(error?.length){
                    this.toastService.error(error);
                  }
                }
              );
            }
            this.isRequesting = false;
          }

        this.setFieldSettings();
        },
        complete: () => {
        }
      });
      this.getFormSettingsByDocumentType(this.documento?.getDocumentFromFormFields()?.tipoDocumento);
      this.getTelefoneUsuarioExterno();
  }

  private setSolicitationValuesToForms(solicitation: SolicitacaoLiberacaoIMP): void{
    this.isSearchDone = true;
    this.changeDetector.detectChanges();
    this.formOut.get('id')?.setValue(solicitation.id);
    this.relacao.setFormValuesFromReceivedContainerRelationList(solicitation.relacaoConteineres);
    this.cliente.setFormValuesFromReceivedClient(solicitation.cliente, true);
    this.dados.setFormValuesFromReceivedComplementaryData(solicitation.dadosComplementares);
    this.dados.getComplementaryDataFromFormFields()
    this.documentosAnexosLiberacao.setAttachedDocumentTypes(solicitation.documento?.id!, solicitation.documentosAnexosLiberacao!, this.solicitacaoLiberacaoImpService.getAttachmentDocumentsForImpLiberationByDocumentType());
    this.arvore.setArvorePartesValuesFromContainers(solicitation.relacaoConteineres);
    this.isSolicitationReceived = true;
    this.setFieldSettings();
  }

  // public allDataForm(event: any) {
  //   if (event != undefined) {
  //     this.formOut.patchValue(event);
  //     this.modelSolicitacao = {
  //       id: this.formOut.value.id,
  //       documento: this.formOut.value.documento,
  //       cliente: this.formOut.value.cliente,
  //       dadosComplementares: this.formOut.value.dadosComplementares,
  //       relacaoConteineres: [this.formOut.value.relacaoConteineres],
  //       arvoreDePartes: [this.formOut.value.arvorePartes]
  //       //documentosAnexosLiberacao: [this.formOut.value.documentosAnexosLiberacao]
  //     };
  //     if(event.arvorePartes && this.arvore){
  //       this.arvorePartesSalva = [...this.arvore?.getArvorePartesFromForm()];
  //       this.numerosConteineresSalvos = [...this.relacao.selectedContainerNumbers];
  //     }
  //   }
  // }

  public closeModal() {
    this.modalService.hide();
  }

  public saveDraft() {
    if(!this.isSaveDraftButtonEnabled){
      return;
    }
    console.log(this.getSolicitationFromForms());
    this.spinner.show();
    this.isRequesting = true;
    this.solicitacaoLiberacaoImpService.saveDraft(this.getSolicitationFromForms())
    .subscribe(
      {
        next: result => {
          result.message.forEach(
            (message) => {
              this.toastService.success(message);
            }
          );
          let solicitationId = result.data.id;
          this.saveAttachedFiles(solicitationId);
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
          this.isRequesting = false;
        },
        complete: () => this.spinner.hide()
      }
      );
  }

  public save() {
    this.closeSignalRConnection();
    let solicitation: SolicitacaoLiberacaoIMP = this.getSolicitationFromForms();
    console.log(solicitation)
    this.spinner.show();
    this.isRequesting = true;
    this.solicitacaoLiberacaoImpService.saveImportLiberationSolicitation(solicitation)
      .subscribe({
        next: (msg) => {
          console.log(msg)
          let solicitationId: number = msg.data.id;
          console.log(msg)
          this.saveAttachedFiles(solicitationId);
          msg?.message?.forEach(
            (message) => {
              this.toastService.success(message);
            }
          );
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          let aggregateId = (errorMessage?.errors ?? []).find(error => uuid.validate(error));
          if(aggregateId?.length){
            this.setIntegrationForDTCDocument(aggregateId);
          }
          else{
            console.log(err);
            errorMessage?.errors?.forEach(
              (error) => {
                this.toastService.error(error);
              }
            );
          }
          this.isRequesting = false;
        }
        else{
          console.log(err);
          this.isRequesting = false;
        }
      }
    });
  }

  public clean() {
    this.spinner.show();
    setTimeout(() => {
      this.cliente?.clean();
      this.documento?.clean();
      this.dados?.clean();
      this.relacao?.clean();
      this.arvore?.clean();
      this.documentosAnexosLiberacao?.clearFields();
      this.spinner.hide();
    }, 1500);
  }

  public ConverteDataXML(data: string): any {
    let dataFinal = data.substring(0,4)+'/'+data.substring(4,6)+'/'+data.substring(6,8);
    return this.datepipe.transform(dataFinal, "dd-MM-yyyy");
  }

  //Recebe os dados xml convertidos em JSON
  public getXmlData(event: any): void{
    if(!event.ListaDeclaracoes){
      this.toastService.error("Arquivo XML é inválido. Favor verificar!");
      return;
    }
    this.clearForms(true);
    this.cliente.setFormValuesFromDIXML(event);
    this.documento?.setFormValuesFromDIXML(event);
    this.dados.setFormValuesFromDIXML(event);
    this.documentosAnexosLiberacao?.setAttachedDocumentTypes(this.documento?.getDocumentFromFormFields()?.id!, [], this.solicitacaoLiberacaoImpService.getAttachmentDocumentsForImpLiberationByDocumentType());
    this.getIntegrationsFromDIXML();
  }

  private getIntegrationsFromDIXML(): void{
    let correlationId: string = uuid.v4();
    this.spinner.show();
    this.blockSending = false;
    this.isRequesting = true;
    this.dados?.setDocumentType('DI');
    this.documentosAnexosLiberacao.setAttachedDocumentTypes(this.documento?.getDocumentFromFormFields()?.id!, [], this.solicitacaoLiberacaoImpService.getAttachmentDocumentsForImpLiberationByDocumentType());
    this.solicitacaoLiberacaoImpService.getIntegrationsFromDIXML(this.getSolicitationFromForms(), correlationId)
      .subscribe({
        next: (result) => {
          this.isSearchDone = true;
          this.spinner.hide();
          let solicitation: SolicitacaoLiberacaoIMP = result.data as SolicitacaoLiberacaoIMP;
          this.doesSolicitationHaveIntegration = !!solicitation?.statusIntegracao?.length;
          this.changeDetector.detectChanges();
          this.getTelefoneUsuarioExterno();
          if(solicitation){
            this.setSolicitationValuesToForms(solicitation);
          }
          this.setFieldSettings();
          this.isRequesting = false;
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
          if(err.status == 404){
            let {error} = err;
            if(typeof(error) === 'string'){
              this.textoSolicitacaoExistente = error;
              this.modalSolicitacaoPesquisadaExistente.openModalForOk();
            }
            else{
              this.isSearchDone = true;
              let integrations: IntegracaoSiscomex[] = (error as ErrorResponse)?.data as IntegracaoSiscomex[];
              if(integrations?.length){
                this.doesSolicitationHaveIntegration = true;
                this.changeDetector.detectChanges();
                if(!!integrations[0]?.solicitacaoLiberacaoImpDTO){
                  this.setSolicitationValuesToForms(integrations[0].solicitacaoLiberacaoImpDTO);
                  this.isRequesting = false;
                }
                else{
                  this.openSiscomexDataModal(integrations);
                }
              }
              else{
                this.doesSolicitationHaveIntegration = false;
                this.changeDetector.detectChanges();
                this.isRequesting = false;
              }
              this.setFieldSettings();
            }
          }
          else{
            this.isSearchDone = true;
          }
        this.setFieldSettings();
        },
        complete: () => {
        }
      });
      this.getFormSettingsByDocumentType(this.documento?.getDocumentFromFormFields()?.tipoDocumento);
      this.getTelefoneUsuarioExterno();
  }

  public confirmCreateSolicitationFromExistingXml(): void{
    let correlationId: string = uuid.v4();
    this.spinner.show();
    this.documentosAnexosLiberacao.setAttachedDocumentTypes(this.documento?.getDocumentFromFormFields()?.id!, [], this.solicitacaoLiberacaoImpService.getAttachmentDocumentsForImpLiberationByDocumentType());
    this.solicitacaoLiberacaoImpService.confirmCreateSolicitationFromDIXML(this.getSolicitationFromForms(), correlationId)
      .subscribe({
        next: (result) => {
          this.isSearchDone = true;
          this.spinner.hide();
          let solicitation: SolicitacaoLiberacaoIMP = result.data as SolicitacaoLiberacaoIMP;
          this.doesSolicitationHaveIntegration = !!solicitation?.statusIntegracao?.length;
          this.getTelefoneUsuarioExterno();
          this.changeDetector.detectChanges();
          if(solicitation){
            this.setSolicitationValuesToForms(solicitation);
          }
        this.setFieldSettings();
        this.isRequesting = false;
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
          if(err.status == 404){
            this.isSearchDone = true;
            let integrations: IntegracaoSiscomex[] = (err?.error as ErrorResponse)?.data as IntegracaoSiscomex[];
            this.getTelefoneUsuarioExterno();
            if(integrations?.length){
              this.doesSolicitationHaveIntegration = true;
              this.changeDetector.detectChanges();
              if(!!integrations[0]?.solicitacaoLiberacaoImpDTO){
                this.setSolicitationValuesToForms(integrations[0].solicitacaoLiberacaoImpDTO);
                this.isRequesting = false;
              }
              else{
                this.openSiscomexDataModal(integrations);
              }
            }
            else{
              this.doesSolicitationHaveIntegration = false;
              this.changeDetector.detectChanges();
              this.isRequesting = false;
            }
            this.setFieldSettings();
            this.isRequesting = false;
          }
          else{
            this.isSearchDone = true;
          }
        this.setFieldSettings();
        },
        complete: () => {
        }
      });
      this.getFormSettingsByDocumentType(this.documento?.getDocumentFromFormFields()?.tipoDocumento);
      this.getTelefoneUsuarioExterno();
  }

  private clearForms(clearDocument: boolean): void{
    this.cliente?.clean();
    this.dados?.clean();
    this.relacao?.clean();
    this.arvore?.clean();
    this.documentosAnexosLiberacao?.clearFields();
    this.isPartDocumentSaved = false;
    if(clearDocument){
      this.documento?.clean();
      this.camposFormularios = [];
      this.setFieldSettings();
      this.formOut?.reset();
    }
    this.changeDetector.detectChanges();
  }

  public hideForms(): void{
    this.isSearchDone = false;
  }

  public changeDocumentType(documentType: string): void{
    this.documentTypeSelected = documentType
    this.hideForms();
    this.clearForms(false);
    this.changeDetector.detectChanges();
  }

  public getFormSettingsByDocumentType(documentType: string): void{
    this.camposFormularios = [];
    this.documentosService.getFormFieldsByDocumentTypeInSolicitacaoLiberacaoImp(documentType)
    .subscribe({
      next: (event) => {
        this.camposFormularios = event.data as Campo[];
        this.changeDetector.detectChanges();
        this.setFieldSettings();
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
        this.changeDetector.detectChanges();
      }
    });
  }

  private setFieldSettings(): void{
    this.changeDetector.detectChanges();
    this.documento?.setSettingsfromFields();
    this.cliente?.setSettingsfromFields();
    this.dados?.setSettingsfromFields();
    this.relacao?.setSettingsfromFields();
    this.arvore?.setSettingsfromFields();
    this.changeDetector.detectChanges();
  }

  public cancelSolicitation(): void{
    this.spinner.show();
    let solicitation: SolicitacaoLiberacaoIMP = this.getSolicitationFromForms();
    if(solicitation.id){
        this.isRequesting = true;
        this.solicitacaoLiberacaoImpService.cancelImportLiberationSolicitationBySolicitationId(solicitation?.id)
          .subscribe(
            {
              next: (result) => {
                this.spinner.hide();
                result?.message?.forEach(
                  (msg) => {
                    if(msg?.length){
                      this.toastService.success(msg);
                    }
                  }
                );
                this.resetPage();
                this.isRequesting = false;
              },
              error: (err: HttpErrorResponse) => {
                this.spinner.hide();
                console.log(err);
                let errorMessage = (err?.error as ErrorResponse);
                if(errorMessage?.errors?.length){
                  errorMessage?.errors?.forEach(
                    (error) => {
                      if(error?.length){
                        this.toastService.error(error);
                      }
                    }
                  );
                }
                this.isRequesting = false;
              }
            }
          );
      }
  }

  protected resetPage(): void{
    this.clearForms(true);
    this.isSolicitationReceived = false;
    this.isSearchDone = false;
    this.isRequesting = false;
    this.blockSending = true;
    this.changeDetector.detectChanges();
  }

  public checkIfAllFormsAreValid(): boolean{
    //this.changeDetector.detectChanges();
    return this.documento?.isDocumentValid() &&
      this.cliente?.isClientValid() &&
      this.dados?.areComplementaryDataValid() &&
      this.relacao?.areAllContainersValid() &&
      this.arvore?.areAllDocumentoParteLinesValid() &&
      this.documentosAnexosLiberacao?.areAllFilesValid
  }

  public getDocuments(): void{
    this.documento.setDocumentTypes(this.documentosService.GetDocumentsForSolicitacaoLiberacaoImp());
  }

  private openSiscomexDataModal(integracoes: IntegracaoSiscomex[]): void{
    clearTimeout(this.timeoutForSignalRConnection);
    console.log('CorrelationId das integração:')
    console.log(integracoes[0].correlationId)
    this.setSignalRConnectionForSiscomexIntegration(integracoes);
  }

  private setSignalRConnectionForSiscomexIntegration(integracoes: IntegracaoSiscomex[]): void{
    this.integrationErrors = [];
    this.signalRConnection.on('IntegracaoDTO', (integracao: IntegracaoSiscomex) => {
      this.modalExtrairDadosSiscomex.concludeIntegration(integracao);
    });
    this.signalRConnection.on('SolicitacaoPreenchida', (solicitacao: SolicitacaoLiberacaoIMP) => {
      console.log('Todas as integrações foram concluidas!')
      console.log(solicitacao)
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      if(!this.integrationErrors.length){
        this.setSolicitationValuesToForms(solicitacao);
      }
      else{
        this.resetPage();
      }
      this.searchNomesDespachantes();
    });
    this.signalRConnection.on('DivergenciaConteineres', (resposta: string) => {
      this.integrationErrors = [];
      this.integrationErrors.push(resposta);
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      this.blockSending = true;
    });
    this.signalRConnection.on('MensagemEventoErro', (resposta: string) => {
      this.integrationErrors = [];
      this.integrationErrors.push(resposta);
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      this.resetPage();
    });
    this.signalRConnection.start()
      .then(
        () => {
          this.signalRConnection.invoke('CriarConexaoSolicitacaoIMP', integracoes[0].correlationId)
            .then(() => {
              this.modalExtrairDadosSiscomex.openModal(integracoes);
            })
            .catch((err) => {
              this.closeSignalRConnection();
              console.log(err)
            });
        }
      )
      .catch(
        (err) => {
          this.closeSignalRConnection();
          console.log(err);
        }
      );
    this.timeoutForSignalRConnection = setTimeout(() => {
      this.closeSignalRConnection();
      if(this.modalExtrairDadosSiscomex.areAllIntegrationsDone){
        this.modalExtrairDadosSiscomex.closeModal();
        return;
      }
      this.modalExtrairDadosSiscomex.clearIntegrationDisplay();
    }, TimeConstants.TIMEOUT_FOR_SISCOMEX_INTEGRATION);
  }

  public closeSignalRConnection(): void{
    this.signalRConnection.stop();
    clearTimeout(this.timeoutForSignalRConnection);
  }

  public displayIntegrationErrors(): void{
    if(this.integrationErrors?.length){
      this.modalRef = this.modalService.show(this.modalAlertaErrosIntegracao, { class: 'modal-lg' });
      this.modalRef.onHidden?.subscribe(
        () => {
          this.integrationErrors = [];
        }
      );
    }
  }

  public setMaskFromDocumentSelected(mask: Mascara): void{
    this.maskForArvorePartes = mask;
  }

  public saveArvorePartes(): void{
    if(!this.arvore && !this.relacao){
      return;
    }
    let arvoreParteForm: DocumentoParteIMP[] = this.arvore?.getArvorePartesFromForm();
    if(arvoreParteForm){
      this.arvorePartesSalva = arvoreParteForm;
    }
    let numerosConteineresForm = this.relacao.selectedContainerNumbers;
    if(numerosConteineresForm){
      this.numerosConteineresSalvos = numerosConteineresForm ?? [];
    }
  }

  public updateArvorePartes(): void{
    if(!this.relacao){
      return;
    }
    let containers = this.relacao.getSelectedContainerRelationFromForm();
    if(!containers?.length){
      this.arvore?.clean();
      return;
    }
    if(!containers.find(container => container.parte && container.quantidadePartes)){
      this.arvore?.clean();
      return;
    }
    let documentosPartesGrid = this.arvore?.getArvorePartesFromForm() ?? [];
    let arvorePartes: DocumentoParteIMP[] = [];
    containers.forEach(
      (conteiner) => {
        let partesConteiner = documentosPartesGrid.filter(parte => parte.conteiner == conteiner.conteiner) ?? [];
        let qtdPartes: number = conteiner?.quantidadePartes ?? 0;
        if(partesConteiner.length < (qtdPartes - 1)){
          while(partesConteiner.length < (qtdPartes - 1)){
            partesConteiner.push({
              conteiner: conteiner.conteiner,
              numeroDocumentoParte: '',
              canalDocumentoParte: undefined,
              parteCadastrada: false,
              numeroCE: ''
            });
          }
        }
        if(partesConteiner.length > (qtdPartes - 1)){
          while(partesConteiner.length > (qtdPartes - 1) && partesConteiner.length > 0){
            partesConteiner.pop();
          }
        }
        arvorePartes.push(...partesConteiner);
      }
    );
    this.arvore.setFormValuesFromArvorePartes(arvorePartes);
  }

  public savePartDocument(): void{
    if(!this.isSalvarDocumentoParteEnabled){
      return;
    }
    this.spinner.show();
    this.isRequesting = true;
    this.closeSignalRConnection();
    const correlationId = uuid.v4();
    this.solicitacaoLiberacaoImpService.savePartDocument(this.setSolicitationForSavingPartDocument(), correlationId)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          let integrations: RetornoStatusDocumentoParteDTO[] = result.data as RetornoStatusDocumentoParteDTO[];
          if(integrations && integrations.length){
            this.modalIntegracaoDocumentoParte.openModal(integrations);
            this.setSignalRConnectionForPartDocument(correlationId);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.spinner.hide();
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
          this.isRequesting = false;
        }
      });
  }

  private setSolicitationForSavingPartDocument(): CadastroDocumentoParteIMPDTO{
    let solicitation: SolicitacaoLiberacaoIMP = this.getSolicitationFromForms();
    let cadastroDocumentoParte: CadastroDocumentoParteIMPDTO = {
      numeroDocumentoSolicitacaoOriginal: solicitation.documento?.numeroDocumento as string,
      tipoDocumentoSolicitacaoOriginal: solicitation.documento?.tipoDocumento as string,
      conteineres:
        this.relacao.getSelectedContainerRelationFromForm().filter(
          (conteiner) => conteiner.parte
        ) ?? []
    };
    let arvorePartes: DocumentoParteIMP[] = this.arvore.getArvorePartesFromForm() ?? [];
    cadastroDocumentoParte.conteineres.forEach(
      (conteiner) => {
        conteiner.documentos = arvorePartes.filter(
          (documentoParte) => documentoParte.conteiner == conteiner.conteiner
        ) ?? [];
      }
    );
    return cadastroDocumentoParte;
  }

  private setSignalRConnectionForPartDocument(correlationId: string): void{
    this.integrationErrors = [];
    this.signalRConnection.on('StatusDocumentoParteDTO', (integration: RetornoStatusDocumentoParteDTO) => {
      if(this.modalIntegracaoDocumentoParte.areAllIntegrationsConcluded()){
        let integrations: RetornoStatusDocumentoParteDTO[] =  this.modalIntegracaoDocumentoParte.getIntegrations();
        this.erasePartDocumentsFromArvorePartesOnIntegrationFail(integrations);
        this.isPartDocumentSaved = true;
      }
      this.modalIntegracaoDocumentoParte.updateIntegrationStatus(integration);
      this.alertPartDocumentIntegrationResult(integration);
      if(this.modalIntegracaoDocumentoParte.areAllIntegrationsConcluded()){
        this.closeSignalRConnection();
        let integrations: RetornoStatusDocumentoParteDTO[] =  this.modalIntegracaoDocumentoParte.getIntegrations();
        this.erasePartDocumentsFromArvorePartesOnIntegrationFail(integrations);
        this.isPartDocumentSaved = true;
      }
    });
    this.signalRConnection.on('DivergenciaConteineres', (resposta: string) => {
      this.integrationErrors = [];
      this.integrationErrors.push(resposta);
      this.closeSignalRConnection();
      this.modalIntegracaoDocumentoParte.closeModal();
      this.displayIntegrationErrors();
      this.blockSending = true;
    });
    this.signalRConnection.on('MensagemEventoErro', (resposta: string) => {
      this.integrationErrors = [];
      this.integrationErrors.push(resposta);
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      this.resetPage();
    });
    this.signalRConnection.start()
      .then(() => {
        this.signalRConnection.invoke('CriarConexaoSolicitacaoIMP', correlationId)
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      this.closeSignalRConnection();
      if(!this.modalIntegracaoDocumentoParte.areAllIntegrationsConcluded()){
        this.modalIntegracaoDocumentoParte.setIntegrationsAsTimedOut();
      }
    }, TimeConstants.TIMEOUT_FOR_SISCOMEX_INTEGRATION);
  }

  private erasePartDocumentsFromArvorePartesOnIntegrationFail(integrations: RetornoStatusDocumentoParteDTO[]): void{
    for(let index: number = 0; index < integrations.length; index++){
      if(!integrations[index].sucesso && integrations[index].concluido){
        this.arvore?.erasePartDocumentByFailedIntegrationNumber(integrations[index].numeroDocumento);
      }
    }
  }

  private alertPartDocumentIntegrationResult(integration: RetornoStatusDocumentoParteDTO): void{
    if(integration.sucesso){
      this.toastService.success(integration.descricao);
      return;
    }
    this.toastService.error(integration.descricao);
  }

  public searchClient(cliente: Cliente): void{
    this.solicitacaoLiberacaoImpService.getCliente(cliente)
      .subscribe({
        next: (result) => {
          this.cliente?.setSearchedClientData(result.data as Cliente);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public getSolicitationFromForms(): SolicitacaoLiberacaoIMP{
    let solicitation: SolicitacaoLiberacaoIMP = {};
    solicitation.id = this.formOut.value.id;
    solicitation.documento = this.documento?.getDocumentFromFormFields();
    solicitation.cliente = this.cliente?.getClientFromForm();
    solicitation.dadosComplementares = this.dados?.getComplementaryDataFromFormFields();
    solicitation.relacaoConteineres = this.relacao?.getContainerRelationFromForm();
    solicitation.arvoreDePartes = this.arvore?.getArvorePartesFromForm();
    solicitation.documentosAnexosLiberacao = this.documentosAnexosLiberacao.getAllDocumentsWithAttachedFiles();
    return solicitation;
  }

  private saveAttachedFiles(solicitationId: number): void{
    var documentos = this.documentosAnexosLiberacao?.getAllDocumentsWithAttachedFiles();
    if(!documentos || !documentos.length){
      this.spinner.hide();
      this.resetPage();
      this.isRequesting = false;
      return;
    }
    this.solicitacaoLiberacaoImpService.arquivoUploadSolicitacaoLiberacaoImp(documentos, solicitationId).subscribe(
    {
      next: (msg) => {
        this.spinner.hide();
        console.log(msg)
        msg?.message?.forEach(
          (message) => {
            this.toastService.success(message);
          }
        );
        this.resetPage();
        this.isRequesting = false;
      },
      error: (err: HttpErrorResponse) =>{
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
        this.isRequesting = false;
      },
      complete:() =>{
        this.spinner.hide();
      }
    }
  );
  }

  public downloadFileToDisplay(arquivo: {arquivoDocumentoAnexado: ArquivoDocumentoAnexado, index: number}): void{
    this.documentosAnexosLiberacao?.downloadFileToDisplay(this.solicitacaoLiberacaoImpService.downloadFileForAttachedDocumentGrid(arquivo.arquivoDocumentoAnexado), arquivo.index);
  }

  public downloadFileToSave(arquivo: {arquivoDocumentoAnexado: ArquivoDocumentoAnexado, index: number}): void{
    this.documentosAnexosLiberacao?.downloadFileToSave(this.solicitacaoLiberacaoImpService.downloadFileForAttachedDocumentGrid(arquivo.arquivoDocumentoAnexado), arquivo.index);
  }

  public get isPartDocumentAbsentOrSaved(): boolean{
    if(this.shouldBlockPartQuantityEditionByDocumentType){
      return true;
    }
    if(!this.arvore?.getArvorePartesFromForm()?.length){
      return true;
    }
    if(this.arvore?.arePartDocumentsSameAsStored){
      return true;
    }
    return this.isPartDocumentSaved;
  }

  public get isConcludeButtonEnabled(): boolean{
    return this.isSearchDone &&
      !this.blockSending &&
      !this.isRequesting &&
      this.checkIfAllFormsAreValid() &&
      this.isPartDocumentAbsentOrSaved;
  }

  public get isSaveDraftButtonEnabled(): boolean{
    if(this.isRequesting){
      return false;
    }
    if(this.arvore?.storedPartDocuments){
      if(this.arvore?.arePartDocumentsSameAsStored){
        return true && this.relacao?.areAllContainersValid();
      }
      else{
        return this.isPartDocumentAbsentOrSaved && this.relacao?.areAllContainersValid();
      }
    }
    else{
      return this.isPartDocumentAbsentOrSaved && this.relacao?.areAllContainersValid();
    }
  }

  private setIntegrationForDTCDocument(correlationId: string): void{
    let integration: IntegracaoSiscomex = {
      correlationId: correlationId,
      concluido: false,
      nomeEvento: 'ConsultaBloqueioRequestedEvent',
      descricao: 'Extraindo dados do documento na Consulta Bloqueio CE',
      integracaoPucomex: false
    };
    this.modalExtrairDadosSiscomex.openModal([integration]);
    this.integrationErrors = [];
    this.signalRConnection.on('IntegracaoDTO', (integracao: IntegracaoSiscomex) => {
      this.modalExtrairDadosSiscomex.concludeIntegration(integracao);
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      this.saveIntegratedDTC();
    });
    this.signalRConnection.on('MensagemEventoErro', (resposta: string) => {
      this.integrationErrors = [];
      this.integrationErrors.push(resposta);
      this.closeSignalRConnection();
      this.modalExtrairDadosSiscomex.closeModal();
      this.resetPage();
    });
    this.signalRConnection.start()
      .then(
        () => {
          this.signalRConnection.invoke('CriarConexaoSolicitacaoIMP', correlationId)
            .catch((err) => {
              this.closeSignalRConnection();
              console.log(err)
            });
        }
      )
      .catch(
        (err) => {
          this.closeSignalRConnection();
          console.log(err);
        }
      );
    this.timeoutForSignalRConnection = setTimeout(() => {
      this.closeSignalRConnection();
      if(this.modalExtrairDadosSiscomex.areAllIntegrationsDone){
        this.modalExtrairDadosSiscomex.closeModal();
        return;
      }
      this.modalExtrairDadosSiscomex.clearIntegrationDisplay();
    }, TimeConstants.TIMEOUT_FOR_SISCOMEX_INTEGRATION);
  }

  private saveIntegratedDTC() {
    let solicitation: SolicitacaoLiberacaoIMP = this.getSolicitationFromForms();
    console.log(solicitation)
    this.spinner.show();
    this.solicitacaoLiberacaoImpService.saveImportLiberationSolicitationFromIntegratedDTC(solicitation)
      .subscribe({
        next: (msg) => {
          console.log(msg)
          let solicitationId: number = msg.data.id;
          msg?.message?.forEach(
            (message) => {
              this.toastService.success(message);
            }
          );
          this.saveAttachedFiles(solicitationId);
      },
      error: (err: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(err);
        let errorMessage = (err?.error as ErrorResponse);
        if(errorMessage?.errors?.length){
          errorMessage?.errors?.forEach(
            (error) => {
              if(error?.length){
                this.toastService.error(error);
              }
            }
          );
        }
      }
    });
  }

  public eraseConteiner (conteiner: ConteinerIMP): void{
    const {id} = this.getSolicitationFromForms()
    if (!id || !conteiner.conteiner) return this.relacao.eraseConteinerFromGrid(conteiner);
      this.spinner.show();
      this.solicitacaoLiberacaoImpService.postCancelarSolicitacao(this.getSolicitationFromForms(), conteiner)
        .subscribe({
          next: (result) => {
            this.relacao.eraseConteinerFromGrid(conteiner);
            result?.message?.forEach(
              (message) => {
                this.toastService.success(message);
              }
            );
            this.spinner.hide();
          },
          error: (err: HttpErrorResponse) => {
            this.spinner.hide();
            console.log(err);
            let errorMessage = (err?.error as ErrorResponse);
            if(errorMessage?.errors?.length){
              errorMessage?.errors?.forEach(
                (error) => {
                  if(error?.length){
                    this.toastService.error(error);
                  }
                }
              );
            }
          }
        });
  }

  public searchNomesDespachantes(): void{
    this.solicitacaoLiberacaoImpService.searchNomesDespachantes(this.getSolicitationFromForms())
      .subscribe({
        next: (result) => {
          this.dados?.setNomesDespachantes(result.data);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public searchShipAndTripByContainerNumber(containerNumber: string): void{
    if(!containerNumber?.length){
      return;
    }
    this.solicitacaoLiberacaoImpService.searchShipAndTripByContainerNumber(containerNumber)
      .subscribe({
        next: (result) => {
          this.dados?.setShipAndTripFromSearchByContainerNumber(result.data as DadosNavioVigemDTO);
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          let errorMessage = (err?.error as ErrorResponse);
          if(errorMessage?.errors?.length){
            errorMessage?.errors?.forEach(
              (error) => {
                if(error?.length){
                  this.toastService.error(error);
                }
              }
            );
          }
        }
      });
  }

  public get shouldBlockPartQuantityEditionByDocumentType(): boolean{
    return this.documentTypesToBlockPartQuantityEdition.includes(this.documentTypeSelected ?? '');
  }

  private getSelectFieldsForFormComponents(): void{
    //Combobox canal
    this.documentosService.getCanais().subscribe({
      next: (canais) =>{
        this.canais = canais;
        this.changeDetector.detectChanges();
      }
    });
    //Combobox moeda
    this.documentosService.getMoedas().subscribe({
      next: (moedas) =>{
        this.moedas = moedas;
        this.changeDetector.detectChanges();
      }
    });
    //Combobox modalidade
   this.documentosService.getModalidades().subscribe({
    next: (modalidade) => {
      this.modalidades = modalidade;
      this.changeDetector.detectChanges();
    }
    });
    //Combobox modalidadeDTA
    this.documentosService.getModalidadesDTA().subscribe({
      next: (modalidadesDTA) => {
        this.modalidadesDTA = modalidadesDTA;
        this.changeDetector.detectChanges();
      }
    });
  }
}

