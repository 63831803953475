import { MaskPipe } from 'ngx-mask';
import { TipoVeiculoDocumentoTransporte } from 'src/app/models/TipoVeiculoDocumentoTransporte';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { DocumentosService } from '../../../../services/documentos.service';
import { Component, OnInit, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DocumentCustomPattern } from 'src/app/shared/utils/constantes-documentos';
import { DadosEntregaDocumentoTransporteN4DTO } from 'src/app/models/EntregaDueContingencia/EntregaDocumentoTransporte/ConteinerEntregaDocumentoTransporte';
import { RelacaoConteinerN4DocumentoTransporte } from 'src/app/models/documento-transporte/LacreConteinerRecepcaoDocumentoTransporte';
import { LinhaGridRelacaoConteinerN4DocumentoTransporteComponent } from 'src/app/shared/components/documento-transporte/linha-grid-relacao-conteiner-n4-documento-transporte/linha-grid-relacao-conteiner-n4-documento-transporte.component';

@Component({
  selector: 'app-dados-documento-transporte-n4-edt',
  templateUrl: './dados-documento-transporte-n4-edt.component.html',
  styleUrls: ['./dados-documento-transporte-n4-edt.component.scss']
})
export class DadosDocumentoTransporteN4EDTComponent implements OnInit {

  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;
  public tipoVeiculo!: UntypedFormControl;
  public namePattern = DocumentCustomPattern.NAME_PATTERN;
  public relacaoConteinerLacresN4Display!: RelacaoConteinerN4DocumentoTransporte[];
  public vehicleTypes!: TipoVeiculoDocumentoTransporte[];
  public quantidadeReboques: number = 0;
  @ViewChildren(LinhaGridRelacaoConteinerN4DocumentoTransporteComponent) gridConteineres!: QueryList<LinhaGridRelacaoConteinerN4DocumentoTransporteComponent>;

  constructor(
    private fb: UntypedFormBuilder,
    private documentosService: DocumentosService,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private maskPipe: MaskPipe,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.relacaoConteinerLacresN4Display = [];
    this.validation();
    this.getVehicleTypes();
  }

  private validation(): void{
    this.form = this.fb.group({
      cpfCondutor: ['', [this.validateCpfOrCnpj.bind(this)]],
      nomeCondutor: [''],
      cpfCnpjTransportador: ['', [this.validateCpfOrCnpj.bind(this)]],
      placaCavalo: [''],
      placaReboque1: [''],
      placaReboque2: [''],
      placaReboque3: [''],
      relacaoConteinerLacresN4: [null],
      lacresN4: [null],
      pesagem: [''],
      recepcaoRealizadaComSucesso: [''],
      statusEntrega: [''],
      dataEnvio: [''],
      usuario: [''],
      statusRecepcao: [''],
      entregaRealizadaComSucesso: [''],
      numeroConteiner: ['']
    });
    this.tipoVeiculo = new UntypedFormControl();
    this.tipoVeiculo?.valueChanges.subscribe(
      (tipoVeiculo) => {
        this.changeQuantidadeReboques(tipoVeiculo);
      }
    );
  }

  private getVehicleTypes(): void{
    this.vehicleTypes = [];
    this.documentosService.getVehicleTypesForDocumentoEntregaTransporte()
      .subscribe({
        next: (vehicletypes) => {
          this.vehicleTypes = [...vehicletypes];
          this.changeDetector.detectChanges();
        },
        error: (err) => {
          console.log(err);
        }
      });
  }

  private changeQuantidadeReboques(tipoVeiculoDocumentoTransporte?: TipoVeiculoDocumentoTransporte): void{
    this.quantidadeReboques = tipoVeiculoDocumentoTransporte?.quantidadeReboque ?? 0;
    for(let plate: number = 1; plate <= 3; plate++){
      if(plate > this.quantidadeReboques){
        this.form.get(`placaReboque${plate}`)?.setValue('');
      }
    }
  }

  public getDocumentoN4FromForm(): DadosEntregaDocumentoTransporteN4DTO{
    let documentoN4: DadosEntregaDocumentoTransporteN4DTO = this.form.getRawValue();
    documentoN4.relacaoConteinerLacresN4 = this.getContainers();
    return documentoN4;
  }

  public clear(): void{
    this.form.reset();
    this.relacaoConteinerLacresN4Display = [];
  }

  public setFormValuesFromReceivedN4TransportDocumentData(dadosDocumentoTransporteN4: DadosEntregaDocumentoTransporteN4DTO | undefined | null): void{
    if(!dadosDocumentoTransporteN4){
      return;
    }
    this.tipoVeiculo?.setValue(
      this.vehicleTypes.find(veiculo => veiculo.quantidadeReboque == (
        (dadosDocumentoTransporteN4.placaReboque1?.length ? 1 : 0) +
        (dadosDocumentoTransporteN4.placaReboque2?.length ? 1 : 0) +
        (dadosDocumentoTransporteN4.placaReboque3?.length ? 1 : 0)
      ))
    );
    this.form.patchValue(dadosDocumentoTransporteN4);
    if (dadosDocumentoTransporteN4.dataEnvio) {
      this.form.get('dataEnvio')?.setValue(new Date(dadosDocumentoTransporteN4.dataEnvio))
    }
    this.form.get('cpfCondutor')?.setValue(this.maskPipe.transform(dadosDocumentoTransporteN4?.cpfCondutor!, '000.000.000-00'));
    this.form.get('cpfCnpjTransportador')?.setValue(this.maskPipe.transform(dadosDocumentoTransporteN4?.cpfCnpjTransportador!, '00.000.000/0000-00||000.000.000-00'));
    // this.form.get('cpfCondutor')?.setValue(this.maskPipe.transform(dadosDocumentoTransporteN4?.cpfCondutor!, '000.000.000-00'));
    // this.form.get('nomeCondutor')?.setValue(dadosDocumentoTransporteN4.nomeCondutor);
    // this.form.get('cpfCnpjTransportador')?.setValue(this.maskPipe.transform(dadosDocumentoTransporteN4?.cpfCondutor!, '00.000.000/0000-00||000.000.000-00'));
    // this.form.get('placaCavalo')?.setValue(dadosDocumentoTransporteN4.placaCavalo);
    // this.form.get('placaReboque1')?.setValue(dadosDocumentoTransporteN4.placaReboque1);
    // this.form.get('placaReboque2')?.setValue(dadosDocumentoTransporteN4.placaReboque2);
    // this.form.get('placaReboque3')?.setValue(dadosDocumentoTransporteN4.placaReboque3);
    // this.form.get('relacaoConteinerLacresN4')?.setValue(dadosDocumentoTransporteN4.relacaoConteinerLacresN4);
    this.relacaoConteinerLacresN4Display = dadosDocumentoTransporteN4.relacaoConteinerLacresN4 ? dadosDocumentoTransporteN4.relacaoConteinerLacresN4 : [];
    this.form.markAllAsTouched();
    this.changeDetector.detectChanges();
  }

  public validateCpfOrCnpj(control: AbstractControl): { invalidDocument: string } | null {
    let controlValue: string = control?.value;
    if (!controlValue?.length) {
      return null;
    }
    let error: string | null;
    let clearDocument: string = this.verificacaoDocumentosService.eraseMask(controlValue);
    if (clearDocument.length == 11) {
      error = this.verificacaoDocumentosService.calculateValidCPF(clearDocument);
    }
    else if (clearDocument.length == 14) {
      error = this.verificacaoDocumentosService.calculateValidCNPJ(clearDocument);
    }
    else {
      error = 'O nº do CNPJ/CPF informado é inválido. Favor verificar!';
    }
    if (error?.length) {
      return { invalidDocument: error };
    }
    return null;
  }

  public getContainers(): RelacaoConteinerN4DocumentoTransporte[] {
    return this.gridConteineres?.map(
      (container) => container.getContainer()
    ) ?? [];
  }

  public areAllContainersValid(): boolean {
    if (!this.gridConteineres?.length) {
      return true;
    }
    return this.gridConteineres?.map(
      (container) => container.isContainerValid()
    )?.every(line => line);
  }

  public isFormN4Valid(): boolean{
    return !this.form.invalid && this.areAllContainersValid();
  }
}
