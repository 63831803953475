import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { MaskPipe } from 'ngx-mask';
import { DocumentoEntrega } from './../../../models/DocumentoEntrega';
import { DocumentCustomPattern } from './../../../shared/utils/constantes-documentos';
import { DocumentosService } from 'src/app/services/documentos.service';
import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Canal } from 'src/app/models/Canal';
import { Moeda } from 'src/app/models/Moeda';
import { Modalidade } from 'src/app/models/Modalidade';
import { ModalidadeDTA } from 'src/app/models/ModalidadeDTA';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { debounceTime } from 'rxjs';
import { DadosComplementares } from 'src/app/models/SolicitacaoLiberacaoIMP/DadosComplementaresIMP';
import { Campo } from 'src/app/models/Campo';
import { SanitizerHtmlService } from 'src/app/shared/utils/sanitizerHtml.service';
import { DatePipe } from '@angular/common';
import { NomeDespachante } from 'src/app/models/LiberacaoDocumental/NomeDespachante';
import { DadosNavioVigemDTO } from 'src/app/models/SolicitacaoLiberacaoCabotagem/DadosNavioVigemDTO';

@Component({
  selector: 'app-dados-complementares',
  templateUrl: './dados-complementares.component.html',
  styleUrls: ['./dados-complementares.component.scss']
})
export class DadosComplementaresComponent implements OnInit, AfterViewInit, AfterContentInit {
  colorTheme = 'theme-dark-blue';
  bsConfig?: Partial<BsDatepickerConfig>;
  isCollapsed = false;
  locale = 'pt-br';
  public form!: UntypedFormGroup;
  @Input() public Canais: Canal[] = [];
  @Input() public Moedas: Moeda[] = [];
  @Input() public Modalidades: Modalidade[] = [];
  @Input() public ModalidadesDTA: ModalidadeDTA[] = [];
  public DocumentosEntrega: DocumentoEntrega[] = [];
  public namePattern = DocumentCustomPattern.NAME_PATTERN;
  public areEntregaSiscomexAndDocumentoEntregaBlocked: boolean = false;
  public DadosComplementares!: DadosComplementares;
  public DadosComplementaresOut!: {};
  public isUsuarioInterno: boolean = false;
  public nomesDespachantes: NomeDespachante[] = [];

  // private readonly documentTypesForNumeroCEBlocking: string[] = ['DTA', 'DTC'];
  private readonly fieldsToBlockWhenDocumentIsDI: string[] = ['modalidadeDta'];
  private readonly fieldsToBlockWhenDocumentIsPRC: string[] = ['modalidadeDta', 'diUnica'];
  private readonly fieldsToBlockWhenDocumentIsNotGUIA: string[] = ['leilao', 'lote'];
  private tipoDocumento: string | undefined = '';

  public readonly maxlengthForTextareas: number = 300;

  @Input() doesSolicitationHaveIntegration!: boolean;
  @Input() camposFormulario!: Campo[];
  @Input() visualizar?: boolean = false;
  @Output() dataFormOut = new EventEmitter<{}>();
  @Output() onComplementaryDataLoad: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private localeService: BsLocaleService,
    private verificacaoDocumentosService: VerificacaoDocumentosService,
    private documentosService: DocumentosService,
    private changeDetector: ChangeDetectorRef,
    private sanitizerHtml: SanitizerHtmlService,
    public datepipe: DatePipe,
    private maskPipe: MaskPipe,
    private authenticationService: AuthenticationService
  ) {
    this.valoresCombobox();
   }
   public ngAfterContentInit(): void {
    this.onComplementaryDataLoad.emit();
  }

  public ngOnInit(): void {
    this.isUsuarioInterno = this.authenticationService.ehUsuarioInterno
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.valoresCombobox();
    this.validation();
  }

   public ngAfterViewInit(): void {
    this.setSettingsfromFields();
  }

   public valoresCombobox(){
    //Combobox documentoEntrega
    this.DocumentosEntrega = this.documentosService.getDeliveryDocuments();
    this.onComplementaryDataLoad.emit();
   }

  public checkDocValid(control: AbstractControl): {invalidDocument: string} | null{
    if(!control.value){
      return null;
    }
    let error: string | null= this.verificacaoDocumentosService.calculateValidCPF(control.value);
    if(!error){
      return null;
    }
    return {invalidDocument: error}
  }

  public validation(): void {

    this.form = this.fb.group({
      navio: new UntypedFormControl('',Validators.compose([
        Validators.minLength(3),
        Validators.maxLength(50)
      ])),
      viagem: new UntypedFormControl(null,Validators.compose([
        Validators.minLength(3),
        Validators.maxLength(50)
      ])),
      valorCIF: new UntypedFormControl(null,Validators.compose([
        Validators.maxLength(50)
      ])),
      moeda: new UntypedFormControl(this.Moedas[0],Validators.compose([])),
      valorMercadoria: new UntypedFormControl(null,Validators.compose([
        Validators.maxLength(50)
      ])),
      dataRegistro: new UntypedFormControl(null,Validators.compose([
        Validators.minLength(3),
        Validators.maxLength(10)
      ])),
      dataDesembaraco: new UntypedFormControl(null,Validators.compose([
        Validators.minLength(3),
        Validators.maxLength(10)
      ])),
      canal: new UntypedFormControl(this.Canais[0],Validators.compose([
      ])),
      diUnica: new UntypedFormControl(false,Validators.compose([
      ])),
      descargaDireta: new UntypedFormControl(false,Validators.compose([
      ])),
      modalidade: new UntypedFormControl(this.Modalidades[0],Validators.compose([
      ])),
      modalidadeDta: new UntypedFormControl(this.ModalidadesDTA[0],Validators.compose([
      ])),
      entregaSiscomex: new UntypedFormControl(null,
        Validators.compose([this.areEntregaSiscomexAndDocumentoEntregaRequired.bind(this)])
      ),
      documentoEntrega: new UntypedFormControl(this.DocumentosEntrega[0].nome,
        Validators.compose([this.areEntregaSiscomexAndDocumentoEntregaRequired.bind(this)])
      ),
      numeroCE: new UntypedFormControl(null,Validators.compose([
        Validators.maxLength(15)
      ])),
      cpfRecebedor: new UntypedFormControl(null,Validators.compose([
        Validators.minLength(11),
        this.checkDocValid.bind(this)
      ])),
      leilao: new UntypedFormControl(null,Validators.compose([
        Validators.minLength(3),
        Validators.maxLength(50),
        this.ValidaLeilao.bind(this)
      ])),
      lote: new UntypedFormControl(null,Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(3)
      ])),
      nomeDespachante: new UntypedFormControl(null,Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(100)
      ])),
      telefoneContato: new UntypedFormControl(null,Validators.compose([
        Validators.minLength(1),
        Validators.maxLength(50)
      ])),
      emailContato: new UntypedFormControl(null,Validators.compose([
        Validators.email,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])),
      comentario: new UntypedFormControl(null,Validators.compose([
        Validators.minLength(3),
        Validators.maxLength(this.maxlengthForTextareas)
      ])),
      situacaoDespacho: new UntypedFormControl(null,Validators.compose([
        Validators.minLength(3),
        Validators.maxLength(this.maxlengthForTextareas)
      ])),
    });
    this.form.valueChanges
      .subscribe({
        next: () => {
          this.checkIfEntregaSiscomexAndDocumentoEntregaBlocked();
          this.sendComplementaryData();
        }
      });

      this.form.get('telefoneContato')?.valueChanges.subscribe({
        next: (value) => {
          console.log('telefone:   '+value)
        }
      })
  }

  public ValidaLeilao(control: AbstractControl) : {leilaoInvalido: string} | null{
    if(!control){
      return null;
    }
    let leilaoNumber = control.value as string;
    let onlyDigitLeilaoNumber = leilaoNumber;
    if(!leilaoNumber || !leilaoNumber.length){return null;}
    onlyDigitLeilaoNumber = onlyDigitLeilaoNumber.split('/').join('');

    if(onlyDigitLeilaoNumber.length === 17 ){

      let onlyYear = leilaoNumber.split('/')[2]
      let yearNow = (new Date()).getFullYear()

      if((+onlyYear-2)===(yearNow-2)){
        return null
      }else{
        return {leilaoInvalido: ''}
      }
    }
    return {leilaoInvalido: ''};
  }

  public removeExtremitySpacesFromText(controlName: string): void{
    this.form.get(controlName)?.setValue((this.form.get(controlName)?.value as string).trim());
  }

  public sendComplementaryData(): void {
    this.DadosComplementares = {
      navio: this.sanitizerHtml.sanitizer(this.form.value.navio),
      viagem: this.sanitizerHtml.sanitizer(this.form.value.viagem),
      valorCIF: this.form.value.valorCIF,
      moeda: this.Moedas.find(moeda => moeda.id === this.form.value.moeda)!,
      valorMercadoria: this.form.value.valorMercadoria,
      dataRegistro: this.form.value.dataRegistro,
      dataDesembaraco: this.form.value.dataDesembaraco,
      cpfRecebedor: this.form.value.cpfRecebedor,
      canal: this.Canais.find(canal => canal.id === this.form.value.canal)!,
      numeroCE: this.sanitizerHtml.sanitizer(this.form.value.numeroCE),
      diUnica: this.form.value.diUnica,
      descargaDireta: this.form.value.descargaDireta,
      modalidade: this.Modalidades.find(modalidade => modalidade.id === this.form.value.modalidade)!,
      comentario: this.sanitizerHtml.sanitizer(this.form.value.comentario),
      entregaSiscomex: this.form.value.entregaSiscomex,
      documentoEntrega: this.form.value.documentoEntrega,
      situacaoDespacho:this.sanitizerHtml.sanitizer(this.form.value.situacaoDespacho),
      modalidadeDta: this.ModalidadesDTA.find(modalidadeDta => modalidadeDta.id === this.form.value.modalidadeDta)!,
      leilao: this.form.value.leilao,
      lote: this.form.value.lote,
      nomeDespachante: this.sanitizerHtml.sanitizer(this.form.value.nomeDespachante),
      emailContato: this.sanitizerHtml.sanitizer(this.form.value.emailContato),
      telefoneContato: this.form.value.telefoneContato,
    }
    this.DadosComplementaresOut = {"dadosComplementares" : this.DadosComplementares}
    this.dataFormOut.emit(this.DadosComplementaresOut);
  }

  public clean() {
    this.form.reset();
  }

  public setFormValuesFromReceivedComplementaryData(complementaryData: DadosComplementares | undefined): void{
    if(!complementaryData){
      return;
    }
    if(!this.form){
      return;
    }
    this.form.get('navio')?.setValue(complementaryData?.navio ? complementaryData?.navio : this.form.value.navio);
    this.form.get('viagem')?.setValue(complementaryData?.viagem ? complementaryData?.viagem : this.form.value.viagem);
    this.form.get('valorCIF')?.setValue(complementaryData?.valorCIF ? this.verificacaoDocumentosService.setCurrencyFormat(complementaryData?.valorCIF): this.form.value.valorCIF);
    if(complementaryData.moeda){
      this.form.get('moeda')?.setValue(complementaryData?.moeda?.id)
    }
    this.form.get('valorMercadoria')?.setValue(complementaryData?.valorMercadoria ? complementaryData?.valorMercadoria : this.form.value.valorMercadoria);
    if(complementaryData?.dataRegistro){
      this.form.get('dataRegistro')?.setValue(new Date(complementaryData?.dataRegistro ? complementaryData?.dataRegistro : this.form.value.dataRegistro));
    }
    if(complementaryData?.dataDesembaraco){
      this.form.get('dataDesembaraco')?.setValue(new Date(complementaryData?.dataDesembaraco ? complementaryData?.dataDesembaraco : this.form.value.dataDesembaraco));
    }
    if(complementaryData.canal){
      this.form.get('canal')?.setValue(complementaryData?.canal?.id)
    }
    this.form.get('diUnica')?.setValue(complementaryData?.diUnica ? complementaryData?.diUnica : this.form.value.diUnica);
    this.form.get('descargaDireta')?.setValue(complementaryData?.descargaDireta ? complementaryData?.descargaDireta : this.form.value.descargaDireta);
    if(complementaryData.modalidade){
      this.form.get('modalidade')?.setValue(complementaryData?.modalidade?.id)
    }
    if(complementaryData.modalidadeDta && complementaryData.modalidadeDta?.id){
      this.form.get('modalidadeDta')?.setValue(complementaryData?.modalidadeDta?.id)
    }
    this.form.get('numeroCE')?.setValue(complementaryData?.numeroCE ? complementaryData?.numeroCE : this.form.value.numeroCE);
    if(complementaryData.cpfRecebedor){
      this.form.get('cpfRecebedor')?.setValue(
        this.maskPipe.transform(complementaryData?.cpfRecebedor, '000.000.000-00')
        );
    }
    this.form.get('leilao')?.setValue(complementaryData?.leilao ? complementaryData?.leilao : this.form.value.leilao);
    this.form.get('lote')?.setValue(complementaryData?.lote ? complementaryData?.lote : this.form.value.lote);
    this.form.get('nomeDespachante')?.setValue(complementaryData?.nomeDespachante ? complementaryData?.nomeDespachante : this.form.value.nomeDespachante);
    var despachante: NomeDespachante []=[{nomeDespachante: this.form.get('nomeDespachante')?.value ?? ''}]
    this.setNomesDespachantes(despachante);
    this.form.get('telefoneContato')?.setValue(complementaryData?.telefoneContato ? this.maskPipe.transform(complementaryData?.telefoneContato ?? '', '(00) 0 0000-0000||(00) 0000-0000') : this.form.value.telefoneContato);
    this.form.get('emailContato')?.setValue(complementaryData?.emailContato ? complementaryData?.emailContato : this.form.value.emailContato);
    this.form.get('comentario')?.setValue(complementaryData?.comentario ? complementaryData?.comentario : this.form.value.comentario);
    this.form.get('situacaoDespacho')?.setValue(complementaryData?.situacaoDespacho ? complementaryData?.situacaoDespacho : this.form.value.situacaoDespacho);
    this.form.markAllAsTouched();
    this.changeDetector.detectChanges();
  }

  public getComplementaryDataFromFormFields(): DadosComplementares{
    let dadosComplementares: DadosComplementares = this.form.getRawValue();
    if(this.form.get('moeda')?.value){
    let moeda: Moeda = {id: this.form.get('moeda')?.value}
    dadosComplementares.moeda = moeda;
    }
    if(this.form.get('modalidade')?.value){
    let modalidade: Modalidade = {id: this.form.get('modalidade')?.value ? this.form.get('modalidade')?.value : 0, nome: '', descricao:''}
    dadosComplementares.modalidade = modalidade;
    }
    if(this.form.get('canal')?.value){
      let canal: Canal = {id: this.form.get('canal')?.value, nome: '', descricao:''}
      dadosComplementares.canal = canal;
    }
    if(this.form.get('modalidadeDta')?.value){
      let modalidadeDta: ModalidadeDTA = {id: this.form.get('modalidadeDta')?.value ? this.form.get('modalidadeDta')?.value : 0, nome: '', descricao:''}
      dadosComplementares.modalidadeDta = modalidadeDta;
    }
    if(this.nomesDespachantes.length){
      dadosComplementares.nomeDespachante = this.nomesDespachantes[0].nomeDespachante;
    }
    return dadosComplementares;
  }

  public areComplementaryDataValid(): boolean{
    return !this.form?.invalid;
  }

  public setFormValuesFromDIXML(xml: any): void{
    this.form.patchValue({
      navio: xml.ListaDeclaracoes.declaracaoImportacao.viaTransporteNomeVeiculo._text,
      moeda: this.Moedas.find(moeda => moeda.nome == 'Real'),
      valorCIF: parseFloat(xml.ListaDeclaracoes.declaracaoImportacao.localDescargaTotalReais._text)/100,
      dataRegistro: this.ConverteDataXML(xml.ListaDeclaracoes.declaracaoImportacao.dataRegistro._text),
      dataDesembaraco: this.ConverteDataXML(xml.ListaDeclaracoes.declaracaoImportacao.dataDesembaraco._text),
      numeroCE: xml.ListaDeclaracoes.declaracaoImportacao.conhecimentoCargaIdMaster._text,
      cpfRecebedor: this.maskPipe.transform(
        xml.ListaDeclaracoes.declaracaoImportacao.importadorCpfRepresentanteLegal._text ?? '', '000.000.000-00'
      ),
      nomeDespachante: xml.ListaDeclaracoes.declaracaoImportacao.importadorNomeRepresentanteLegal._text,
      telefoneContato: this.maskPipe.transform(xml.ListaDeclaracoes.declaracaoImportacao.importadorNumeroTelefone._text ?? '', '(00) 0 0000-0000||(00) 0000-0000'),
      situacaoDespacho: xml.ListaDeclaracoes.declaracaoImportacao.situacaoEntregaCarga._text,
      modalidade: this.Modalidades.find(
        modalidade => modalidade.nome == xml.ListaDeclaracoes.declaracaoImportacao.modalidadeDespachoNome._text
      )
    }, {emitEvent: false});
    //this.form.get('telefoneContato')?.setValue(xml.ListaDeclaracoes.declaracaoImportacao.importadorNumeroTelefone._text);
    this.form.markAllAsTouched();
    this.changeDetector.detectChanges();
  }

  public ConverteDataXML(data: string): any {
    return new Date(
      parseInt(data.substring(0,4)),
      (parseInt(data.substring(4,6)) - 1),
      parseInt(data.substring(6,8)),
      0,
      0,
      0
    );
  }

  public setSettingsfromFields(): void{
    if(!this.form){
      return;
    }
    if(!this.camposFormulario?.length){
      if(this.visualizar){
        this.form.disable();
        this.changeDetector.detectChanges();
      }
      else{
        this.enableAllFormFields();
      this.enableOrBlockFixedFields();
      }
      return;
    }
    for(let control in this.form.controls){
      let fieldIndex = this.camposFormulario.findIndex(
        (field) => field.nome == control
      );
      if(fieldIndex >= 0){
        if(this.camposFormulario[fieldIndex].obrigatorio){
          this.form.get(control)?.addValidators(Validators.required);
        }
        else{
          this.form.get(control)?.removeValidators(Validators.required);
        }
        if(this.doesSolicitationHaveIntegration){
          if(this.camposFormulario[fieldIndex].leitura && !!this.form.get(control)?.value){
            this.form.get(control)?.disable();
          }
          else{
            this.form.get(control)?.enable();
          }
        }
        else{
          if(this.camposFormulario[fieldIndex].leitura){
            this.form.get(control)?.disable();
          }
          else{
            this.form.get(control)?.enable();
          }
        }
        if(!!this.camposFormulario[fieldIndex]?.bloqueado){
          this.form.get(control)?.disable();
        }
        if(!this.form.get(control)?.value && this.camposFormulario[fieldIndex].obrigatorio){
          this.form.get(control)?.enable();
        }
      }
      else{
        this.form.get(control)?.enable();
        this.form.get(control)?.removeValidators(Validators.required);
      }
      this.changeDetector.detectChanges();
      this.showFieldAsRequired(control);
    }
    this.enableOrBlockFixedFields();
    this.changeDetector.detectChanges();
  }

  public enableAllFormFields(): void{
    this.changeDetector.detectChanges();
    for(let field in this.form.controls){
      this.form.get(field)?.enable();
      this.form.get(field)?.removeValidators(Validators.required);
      this.showFieldAsRequired(field);
      this.changeDetector.detectChanges();
    }
  }

  // public switchDisableForNumeroCE(tipoDocumento: string): void{
  //   if(this.documentTypesForNumeroCEBlocking.map(
  //     tipoDocumento => tipoDocumento.toLowerCase()
  //   ).includes((tipoDocumento ?? '')?.toLowerCase())){
  //     this.form?.get('numeroCE')?.disable();
  //   }
  //   else{
  //     if(this.camposFormulario?.find(campo => campo.nome == 'numeroCE')?.leitura && !!this.form?.get('numeroCE')?.value){
  //       this.form?.get('numeroCE')?.disable();
  //     }
  //     else{
  //       this.form?.get('numeroCE')?.enable();
  //     }
  //   }
  // }

  private checkIfEntregaSiscomexAndDocumentoEntregaBlocked(): void{
    // let usuarioExterno = (!this.authenticationService.ehUsuarioInterno);
    // console.log(this.authenticationService.ehUsuarioInterno)
    // console.log(usuarioExterno);
    // console.log(this.isUsuarioInterno)
    if(this.isUsuarioInterno){
      this.areEntregaSiscomexAndDocumentoEntregaBlocked = false;
      // console.log(this.areEntregaSiscomexAndDocumentoEntregaBlocked)
      return;
    }
    let tipoDI = ((this.tipoDocumento ?? '') == 'DI');
    let modalidadeEntregaAntecipada = ((this.form?.get('modalidade')?.value as Modalidade)?.descricao?.toUpperCase()?.trim() == 'Entrega Antecipada'.toUpperCase());
    if(tipoDI && modalidadeEntregaAntecipada){
      this.areEntregaSiscomexAndDocumentoEntregaBlocked = false;
      return;
    }
    this.areEntregaSiscomexAndDocumentoEntregaBlocked = true;
  }

  public setDocumentType(documentType: string): void{
    this.tipoDocumento = documentType ?? '';
    this.checkIfEntregaSiscomexAndDocumentoEntregaBlocked();
  }

  private areEntregaSiscomexAndDocumentoEntregaRequired(control: AbstractControl): {requiredFields: string} | null{
    let tipoDI = ((this.tipoDocumento ?? '') == 'DI');
    if(!tipoDI){
      return null;
    }
    let modalidadeEntregaAntecipada = ((this.form?.get('modalidade')?.value as Modalidade)?.descricao?.toUpperCase()?.trim() == 'Entrega Antecipada'.toUpperCase());
    if(!modalidadeEntregaAntecipada){
      return null;
    }
    let usuarioInterno = (this.authenticationService.ehUsuarioInterno);
    if(!usuarioInterno){
      return null;
    }
    let controlValue = control?.value;
    if(tipoDI && modalidadeEntregaAntecipada && usuarioInterno){
      if(!!controlValue){
        return null;
      }
      else{
        return {requiredFields: 'Campos obrigatórios!'};
      }
    }
    return null;
  }

  private setCpfRecebedorAsRequiredWhenDocumentIsDSIm(): void{
    if(!this.isUsuarioInterno && this.tipoDocumento == 'DSIm'){
      this.form?.get('cpfRecebedor')?.addValidators(Validators.required);
    }
  }

  private blockFieldsWhenDocumentIsDI(): void{
    if(this.tipoDocumento?.toUpperCase() == 'DI'){
      this.fieldsToBlockWhenDocumentIsDI.forEach(
        (field) => {
          this.form.get(field)?.disable();
        }
      );
    }
    this.changeDetector.detectChanges();
  }

  private blockFieldsWhenDocumentIsPRC(): void{
    if(this.tipoDocumento?.toUpperCase() == 'PRC'){
      this.fieldsToBlockWhenDocumentIsPRC.forEach(
        (field) => {
          this.form.get(field)?.disable();
        }
      );
    }
    this.changeDetector.detectChanges();
  }

  private enableLeilaoAndLoteWhenDocumentIsGUIA(): void{
    if(this.tipoDocumento?.toUpperCase() == 'GUIA'){
      this.fieldsToBlockWhenDocumentIsNotGUIA.forEach(
        (field) => {
          this.form.get(field)?.enable();
        }
      );
    }
    else{
      this.fieldsToBlockWhenDocumentIsNotGUIA.forEach(
        (field) => {
          this.form.get(field)?.disable();
        }
      );
    }
    this.changeDetector.detectChanges();
  }

  private enableOrBlockFixedFields(): void{
    this.blockFieldsWhenDocumentIsDI();
    this.blockFieldsWhenDocumentIsPRC();
    this.enableLeilaoAndLoteWhenDocumentIsGUIA();
    this.setCpfRecebedorAsRequiredWhenDocumentIsDSIm();
  }

  private showFieldAsRequired(field: string): void{
    if(!!this.form.get(field)?.hasValidator(Validators.required)){
      document.getElementById(`${field}-required-label`)?.classList?.remove('invisible');
      document.getElementById(`${field}-required-label`)?.classList?.add('visible');
    }
    else{
      document.getElementById(`${field}-required-label`)?.classList?.remove('visible');
      document.getElementById(`${field}-required-label`)?.classList?.add('invisible');
    }
    this.changeDetector.detectChanges();
  }

  public setNomesDespachantes(nomesDespachantes: NomeDespachante[]): void{
    this.nomesDespachantes = nomesDespachantes ?? [];
    this.changeDetector.detectChanges();
  }

  public setShipAndTripFromSearchByContainerNumber(shipAndTrip: DadosNavioVigemDTO): void{
    this.form?.patchValue({
      navio: shipAndTrip?.navio ? shipAndTrip.navio : this.form?.value?.navio,
      viagem: shipAndTrip?.viagem ? shipAndTrip.viagem : this.form?.value?.viagem
    });
  }

  public setTelefoneContatoEmail(telefoneContato: string = "") {
    console.log(telefoneContato);
    let telefoneFormatado = this.maskPipe.transform(telefoneContato, '(00) 0 0000-0000')
   this.form?.patchValue({
    telefoneContato: !!telefoneContato ? telefoneFormatado : this.form?.value?.telefoneContato,
    emailContato: this.authenticationService.userEmail != null ? this.authenticationService.userEmail : this.form?.value?.emailContato,
   });

   this.changeDetector.detectChanges();
  }
}
