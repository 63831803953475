<div class="card mt-4">
    <div class="card-header p-3">
        <div class="d-flex justify-content-between">
            <span>Dados Complementares <span class="text-danger" *ngIf="!areComplementaryDataValid">*</span></span>
            <i (click)="isCollapsed = !isCollapsed" class="{{isCollapsed ? 'fa fa-chevron-down' : 'fa fa-minus'}}" data-bs-target="#cardCollapse" aria-controls="cardCollapse" aria-expanded="false" aria-label="Toggle navigation"></i>
        </div>
    </div>
    <div [collapse]="isCollapsed" [isAnimated]="true" id="cardCollapse">
        <form [formGroup]="form">
            <div class="p-3">
                <div class="row">
                    <div class="col">
                        <label for="">Navio: <span id="navio-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="navio" placeholder="Digite aqui..." (change)="removeSpacesFromExtremities('navio')" [attr.disabled]="visualizar ? '': null">
                    </div>
                    <div class="col">
                        <label for="">Viagem: <span id="viagem-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="viagem" placeholder="Digite aqui..." (change)="removeSpacesFromExtremities('viagem')" [attr.disabled]="visualizar ? '': null">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3">
                        <label for="">Valor FOB: <span id="valorFob-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="valorFob" currencyMask [options]="{prefix: '', align:'left', thousands: '.', decimal: ','}" [attr.disabled]="visualizar ? '': null">
                    </div>
                    <div class="col-3">
                        <label>Moeda: <span id="moeda-required-label" class="invisible">*</span></label>
                        <select class="custom-select" formControlName="moeda" [attr.disabled]="visualizar ? '': null">
                        <option [ngValue]="null" selected>Selecione</option>
                        <option *ngFor="let moeda of Moedas" [value]="moeda.id">{{ moeda.nome }}</option>
                      </select>
                    </div>

                    <div class="col">
                        <label for="">Valor da Mercadoria: <span id="valorMercadoria-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="valorMercadoria" currencyMask [options]="{prefix: '', align:'left', thousands: '.', decimal: ','}" [attr.disabled]="visualizar ? '': null">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3">
                        <label for="">Data de Registro: <span id="dataRegistro-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="dataRegistro" bsDatepicker #dp="bsDatepicker" class="form-control" aria-describedby="basic-addon2" [bsConfig]="bsConfig" placeholder="dd/mm/aaaa" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [attr.disabled]="visualizar ? '': null">
                    </div>
                    <div class="col-3">
                        <label for="">Data Desembaraco: <span id="dataDesembaraco-required-label" class="invisible">*</span></label>
                        <input type="text" formControlName="dataDesembaraco" bsDatepicker #dp="bsDatepicker" class="form-control" aria-describedby="basic-addon2" [bsConfig]="bsConfig" placeholder="dd/mm/aaaa" style="background-image: url('assets/images/calendar.png'); background-repeat: no-repeat; background-position: right center; background-position-x: 90%; "
                            [attr.disabled]="visualizar ? '': null">
                    </div>
                    <div class="col">
                        <label for="">Situacao Aduaneira: <span id="situacaoAduaneira-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="situacaoAduaneira" placeholder="Digite aqui..." (change)="removeSpacesFromExtremities('situacaoAduaneira')" [attr.disabled]="visualizar ? '': null">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3">
                        <label for="">Unidade de Despacho: <span id="unidadeDespacho-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="unidadeDespacho" placeholder="Digite aqui..." [attr.disabled]="visualizar ? '': null">
                    </div>
                    <div class="col-3">
                        <label for="">Unidade de Embarque: <span id="unidadeEmbarque-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="unidadeEmbarque" placeholder="Digite aqui..." [attr.disabled]="visualizar ? '': null">
                    </div>
                    <div class="col">
                        <label>Canal: <span id="canal-required-label" class="invisible">*</span></label>
                        <select formControlName="canal" class="custom-select" id="canal" [attr.disabled]="visualizar ? '': null">
                        <option [ngValue]="null" selected>Selecione</option>
                        <option *ngFor="let canal of Canais" [value]="canal.id">{{ canal.nome }}</option>
                      </select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3">
                        <label for="">Recinto: <span id="recinto-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="recinto" placeholder="Digite aqui..." [attr.disabled]="visualizar ? '': null">
                    </div>
                    <div class="col-3">
                        <div class="row">
                            <div class="col">
                                <label>Embarque Direto? <span id="embarqueDireto-required-label" class="invisible">*</span></label><br>
                                <div class="form-check">
                                    <input formControlName="embarqueDireto" class="form-chek-input" type="checkbox" [attr.disabled]="visualizar ? '': null">
                                    <label class="form-check-label" for="defaultIncluir">&nbsp;Sim</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <label for="">E-mail: <span id="emailContato-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="emailContato" placeholder="Digite aqui..." [ngClass]="{'border border-danger': form.get('emailContato')?.invalid && !form.get('emailContato')?.pristine}" [attr.disabled]="visualizar ? '': null">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-6">
                        <label for="">Telefone: <span id="telefoneContato-required-label" class="invisible">*</span></label>
                        <input type="text" class="form-control" formControlName="telefoneContato" placeholder="Digite aqui..." [mask]="'(00) 0 0000-0000'" [ngClass]="{'border border-danger': form.get('telefoneContato')?.invalid && !form.get('telefoneContato')?.pristine}" [attr.disabled]="visualizar ? '': null">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col d-flex">
                        <p class="required-fields-paragraph">Campos marcados com * são de preenchimento obrigatório</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
