import { DocumentCustomPattern } from '../../../shared/utils/constantes-documentos';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Campo } from 'src/app/models/Campo';
import { debounceTime } from 'rxjs';
import { VerificacaoDocumentosService } from 'src/app/shared/utils/verificacao-documentos.service';
import { DadosEnvioTransporte } from 'src/app/models/RecepcaoDocumentoTransporte/DadosEnvioTransporte';
@Component({
  selector: 'app-dados-envio-transporte',
  templateUrl: './dados-envio-transporte.component.html',
  styleUrls: ['./dados-envio-transporte.component.scss']
})
export class DadosEnvioTransporteComponent implements OnInit, AfterViewInit {
  public dataRegistroValue?: Date;
  public dataDesembaracoValue?: Date;
  public isCollapsed: boolean = false;
  public form!: UntypedFormGroup;
  private colorTheme = 'theme-dark-blue';
  public bsConfig?: Partial<BsDatepickerConfig>;
  public namePattern = DocumentCustomPattern.NAME_PATTERN;
  public dadosenviotransporte!: DadosEnvioTransporte;
  public locale = 'pt-br';


  @Input() camposformulario!: Campo[];
  @Output() dataFormOut: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() onFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private localeService: BsLocaleService,
  ) {
   }

  public ngOnInit(): void {
    this.localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    this.validation();
    }

  public ngAfterViewInit(): void {
    if(!this.camposformulario || !this.camposformulario.length){
      return;
    }
    this.camposformulario.forEach(
      (campo) => {
        if(campo.obrigatorio){
          this.form.get(campo.nome)?.addValidators(Validators.required);
        }
      }
    );
  }


  private validation(): void{
    this.form = this.fb.group({
      dataEnvio: [''],
      usuario: [''],
      statusRecepcao: [''],
    });
  }

  public removeSpacesFromExtremities(inputName: string): void{
    this.form.get(inputName)?.setValue((this.form.get(inputName)?.value as string).trim());
  }

  public sendComplementaryData(): void {
    this.dadosenviotransporte = {
      dataEnvio: this.form.value.dataEnvio,
      usuario: this.form.value.usuario,
      status: this.form.value.statusRecepcao,
    }
    this.dataFormOut.emit({'dadosenviotransporte': this.dadosenviotransporte});
    this.onFormValid.emit(this.form.valid);
  }

  public setFormValuesFromReceivedComplementaryData(complementaryData: DadosEnvioTransporte | undefined): void{
    if(!complementaryData){
      return;
    }
    this.form.get('dataEnvio')?.setValue(new Date(complementaryData.dataEnvio).toLocaleDateString("pt-br"));
    this.form.get('usuario')?.setValue(complementaryData?.usuario);
    this.form.get('statusRecepcao')?.setValue(complementaryData?.status);
    this.changeDetector.detectChanges();
  }

  public checkIfFieldIsRequired(fieldName: string): boolean{
    return !!this.camposformulario?.find(
      campo => campo.nome == fieldName
    )?.obrigatorio;
  }

  public checkIfFieldIsReadonly(fieldName: string): boolean{
    return !!this.camposformulario?.find(
      campo => campo.nome == fieldName
    )?.leitura;
  }

  public clean(){
    this.form.reset();
  }


}
